import { AlertMessage } from "components";
import ClinicAddressDD from "components/dropdowns/clinicAddressDD";
import PatientAddressDD from "components/dropdowns/patientAddressDD";
import RushOrderDD from "components/dropdowns/rushOrderDD";
import ShippingMethodDD from "components/dropdowns/shippingMethodDD";
import StateDD from "components/dropdowns/stateDD";
import ValidAddressModal from "components/validAddressModal";
import { some, unset } from "lodash";
import { Component } from "react";
import { Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import Cookies from "universal-cookie";
import { VPI_ADDRESS } from "utils/StatusConstant";
import axiosConfig, { errorResponse } from "utils/axiosConfig";

class AddShippingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      singlePatientId: this.props.selectedPatients[0],
      isColdShip: some(this.props.selectedProducts, { coldShipped: "1" }),
      modalOpen: false,
      rushOrder: false,
      newAddress: "",
      newAddressObj: {},
      rushOrderMethod: "",
      patientData: "",
      error: "",
      preferredShippingInfo: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zipcode: "",
        method: "",
      },
      toggleRushButton: 0,
      addressVerification: false,
      fedexAddress: "",
      showValidateModal: false,
      shippingAddress: {},
      shipToClinic: true,
      toggleShipTo: 0,
      rushOrderInfo: {
        name: "24-hour Turn Around",
        percentOfOrder: 15,
        value: 70,
      },
      setShipOptional: false,
      editBool:this.props.editFlow || window?.location?.pathname.includes("edit-prescription")
    };
  }

  componentDidMount() {
    let address = "";
    let method = this.props.shippingMethod
      ? this.props.shippingMethod
      : this.props.defaultShippingInfo
      ? this.props.defaultShippingInfo.shippingMethod
      : this.state.preferredShippingInfo.method;

    if (method.toLowerCase().includes("pick")) {
      this.setState({
        setShipOptional: true,
      });
    }
    if (this.props.isBulkOrder || this.state.shipToClinic) {
      
      address = !this.props.defaultShippingInfo ? "" : this.props.defaultShippingInfo.shippingAddress;
      this.props.updateShippingCost(address, method);
    } else {
      
      address = this.props.defaultShippingInfo
        ? this.props.defaultShippingInfo.shippingAddress
        : this.state.preferredShippingInfo;
      this.props.updateShippingCost(address, method);
    }

    if (this.props.defaultShippingInfo) {
      this.setState({ rushOrder: this.props.defaultShippingInfo.isRushOrder, shipToClinic: this.props.shipToClinic });
    }
    if (!this.props.isBulkOrder) {
      this.getAllPatientDetails();
    }
  }
  componentWillReceiveProps(nextProps) {
    const { step: stepProp, selectedPatients: selectedPatientsProp } = nextProps;
    const { selectedPatients } = this.props;
    if (stepProp === 2 && selectedPatientsProp.length > 0 && selectedPatients.length !== selectedPatientsProp.length) {
      setTimeout(() => {
        this.getAllPatientDetails();
      }, 100);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.shipToClinic !== this.props.shipToClinic) {
      this.setState({
        shipToClinic: this.props.shipToClinic,
      });
    }

    // if (prevProps.selectedProducts !== this.props.selectedProducts) {
    //   this.setState({
    //     isColdShip: some(this.props.selectedProducts, { coldShipped: "1" }),
    //   });
    //   if (some(this.props.selectedProducts, { coldShipped: "1" })) {
    //     this.setRushOrder(true);
    //     this.setState({ rushOrder: true });
    //     this.setState({ toggleRushButton: this.state.toggleRushButton + 1 });
    //   }
    // }
  }

  ///
  setShipOptional(bool) {
    this.setState({
      setShipOptional: bool,
    });
  }

  ///

  setSelectedAddress(address) {
    
    console.log('Step Name: ',this.state.step)
    this.props.setShippingAddress(address, false);
    this.props.updateShippingCost(address);
    var shippingInfo = {
      ...this.props.defaultShippingInfo,
      shippingAddress: address,
    };
    this.props.updateDefaultShippingOnChange?.(shippingInfo);
    this.setState({
      newAddress: "",
      newAddressObj: "",
    });
  }

  setRushMethod(method) {
    this.props.setRushOrder(true, method);
  }

  setRushOrder(check) {
    this.props.setRushOrder(check, check ? this.state.rushOrderInfo?.name : "");
  }

  setShippingMethod(method) {
    this.props.setShippingMethod(method);
  }

  /*Modal Validate Address */

  onClickNotValidate() {
    this.closeModal();
    this.setState({
      addressVerification: true,
    });
  }
  onClickValidate() {
    this.setState({
      newAddressObj: {
        addressLine1: this.state.fedexAddress.addressLine1,
        addressLine2: this.state.fedexAddress.addressLine2 !== "" ? this.state.fedexAddress.addressLine2 : "-",
        city: this.state.fedexAddress.city,
        zipcode: this.state.fedexAddress.zipcode,
        state: this.state.fedexAddress.state,
      },
      shippingAddress: {
        addressLine1: this.state.fedexAddress.addressLine1,
        addressLine2: this.state.fedexAddress.addressLine2 !== "" ? this.state.fedexAddress.addressLine2 : "-",
        city: this.state.fedexAddress.city,
        zipcode: this.state.fedexAddress.zipcode,
        state: this.state.fedexAddress.state,
      },
      addressVerification: true,
    });
    this.closeModal();
  }

  closeModal() {
    this.setState({ showValidateModal: false });
  }
  /***/

  async addNewAddress() {
    var serialize = require("form-serialize");
    var form = document.getElementById("addNewAddressForm");
    var obj = serialize(form, { hash: true });
    var address = {};
    if (
      this.state.addressVerification &&
      this.state.shippingAddress.addressLine1 === obj.addressLine1 &&
      this.state.shippingAddress.addressLine2 === obj.addressLine2 &&
      this.state.shippingAddress.city === obj.city &&
      this.state.shippingAddress.zipcode === obj.zipcode &&
      this.state.shippingAddress.state.toLowerCase() === obj.state.toLowerCase()
    ) {
      document.getElementsByName("shippingAddress")[0].value = "";
      var address = {
        addressLine1: obj.addressLine1,
        addressLine2: obj.addressLine2,
        state: obj.state,
        city: obj.city,
        zipcode: obj.zipcode,
      };
      this.setState({
        step: 4,
        modalOpen: false,
        newAddressObj: address,
        newAddress: obj.addressLine1 + ", " + obj.city + ", " + obj.state + ", " + obj.zipcode,
      });
      
      this.props.updateShippingCost(
        address,
        this.props.defaultShippingInfo
          ? this.props.defaultShippingInfo.shippingMethod
          : this.state.preferredShippingInfo.method,
      );
      
      this.props.setShippingAddress(address, true);
    } else {
      var fedexShippingAddress;
      this.setState({
        showValidateModal: true,
        shippingAddress: {
          addressLine1: obj.addressLine1,
          addressLine2: obj.addressLine2 !== "" ? obj.addressLine2 : "-",
          city: obj.city,
          zipcode: obj.zipcode,
          state: obj.state,
        },
        showSpinner: true,
      });
      const isUSPS = this.props.shippingMethod?.toLowerCase().includes("usps");
      let body = {
        address: {
          streetLines: {
            addressLine1: obj.addressLine1,
            addressLine2: obj.addressLine2 ? obj.addressLine2 : "-",
          },
          city: obj.city,
          state: obj.state,
          postalCode: obj.zipcode,
        },
      };
      if (isUSPS) {
        body = { ...body.address, ...body.address.streetLines };
        unset(body, "streetLines");
      }
      await axiosConfig
        .post(isUSPS ? "/portal/usps/validateaddress" : "/portal/validateAddress", body)
        .then(async response => {
          var fedexShippingAddr = {
            addressLine1: response.data.addressLine1,
            addressLine2:
              response.data.addressLine2 || response.data.addressLine2 !== "" ? response.data.addressLine2 : "-",
            city: response.data.city,
            state: response.data.state,
            zipcode: response.data.postalCode,
          };
          this.setState({
            fedexAddress: fedexShippingAddr,
            showSpinner: false,
            error: "",
          });
        })
        .catch(error => {
          this.setState({
            showValidateModal: false,
            error: "An error occurred while trying to Validate Address. Please Try again",
            showSpinner: false,
          });
        });
    }
  }
  async getAllPatientDetails() {
    if (!this.props.selectedPatients.length) {
      return;
    }
    await axiosConfig
      .post(
        "patients/getPatientByPatientId",
        {
          patientId: this.props.selectedPatients[0],
          userId: new Cookies().get("user_id"),
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        //SHIP TO
        let editBool = this.props.editFlow || window?.location?.pathname.includes("edit-prescription");
        this.props.shipToClinicFunc(editBool ? this.props.shipToClinic : response.data.isShipToClinic);
        await this.setState({
          patientData: response.data,
          shipToClinic: editBool ? this.props.shipToClinic : response.data.isShipToClinic,
          preferredShippingInfo: {
            addressLine1: response.data.shippingAddressLine1,
            addressLine2: response.data.shippingAddressLine2,
            city: response.data.shippingCity,
            state: response.data.shippingState,
            zipcode: response.data.shippingZipcode,
            method: response.data.shippingPreference,
          },
        });

        var shipAddr = {
          addressLine1: response.data.shippingAddressLine1,
          addressLine2: response.data.shippingAddressLine2,
          city: response.data.shippingCity,
          state: response.data.shippingState,
          zipcode: response.data.shippingZipcode,
        };
        //this.props.updateShippingCost(shipAddr, response.data.shippingPreference);
        if (editBool === false && this.props.isBulkOrder===false) {
          let addr = {
            addressLine1: response.data.shippingAddressLine1,
            addressLine2: response.data.shippingAddressLine2,
            city: response.data.shippingCity,
            state: response.data.shippingState,
            zipcode: response.data.shippingZipcode,
          };
        }
      })
      .catch(error => {
        this.setState({
          error: "An error occurred while trying to fetch patient information. " + error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }
  render() {
    return (
      <div className={`m-sm-auto m-0 flexJCAC flex-column`}>
        {/* {some(this.props.selectedProducts, { coldShipped: "1" }) ? (
          <Row className="mb-4 w-75">
            {" "}
            <p style={{ color: "white", background: "#E8B24C", padding: "10px" }}>
              {" "}
              <i className="fa fa-exclamation-circle"></i> This order has a cold shipping product product which can only
              be handled with Priority Orders.
            </p>
          </Row>
        ) : null} */}
        {this.state.setShipOptional === false ? (
          <>
            {this.props.isBulkOrder ? (
              <Row className="mb-4 w-80">
                <p className="yellowButton">
                  {" "}
                  <i className="fas fa-exclamation-circle mx-1" />
                  Please advise: “Ship to clinic” and/or “Pick Up” are the only options for bulk orders.
                </p>{" "}
              </Row>
            ) : null}
            <Row className="mb-4 w-75">
              {!this.props.isBulkOrder ? (
                <div>
                  <input
                    onChange={e => {
                      this.setState({
                        shipToClinic: e.target.checked,
                        toggleShipTo: this.state.toggleShipTo + 1,
                      });
                      this.props.shipToClinicFunc(e.target.checked);
                    }}
                    name="shipToClinic"
                    className="form-check-input"
                    type="checkbox"
                    id="shipToClinic"
                    checked={this.state.shipToClinic}
                  />
                  <label className="form-check-label mx-2" htmlFor="shipToClinic">
                    Ship To Clinic
                  </label>
                </div>
              ) : null}
            </Row>
            <Row className="mb-4 w-75">
              {this.props.isBulkOrder || this.state.shipToClinic ? (
                <ClinicAddressDD
                  toggleShipTo={this.state.toggleShipTo}
                  updateShippingCost={this.props.updateShippingCost}
                  defaultValue={!this.props.defaultShippingInfo ? "" : this.props.defaultShippingInfo.shippingAddress}
                  locationID={this.props.clinicLocationId}
                  setSelectedAddress={this.setSelectedAddress.bind(this)}
                  name="shippingAddress"
                  required={this.props.step === 4 && !this.state.newAddress ? true : false}
                  step={this.props.step}
                  editBool={this.state.editBool}
                  shipToClinic={this.state.shipToClinic}
                  isBulkOrder={this.props.isBulkOrder}
                />
              ) : (
                <PatientAddressDD
                  toggleShipTo={this.state.toggleShipTo}
                  updateShippingCost={this.props.updateShippingCost}
                  editBool={this.state.editBool}
                  defaultValue={
                    this.state.editBool?
                    this.props.defaultShippingInfo
                      ? this.props.defaultShippingInfo.shippingAddress
                      : this.state.preferredShippingInfo
                      :
                      this.state.preferredShippingInfo
                  }
                  setSelectedAddress={this.setSelectedAddress.bind(this)}
                  name="shippingAddress"
                  patientID={this.props.selectedPatients[0]}
                  required={this.props.step === 4 && !this.state.newAddress ? true : false}
                  shipToClinic={this.state.shipToClinic}
                  step={this.props.step}
                  isBulkOrder={this.props.isBulkOrder}
                />
              )}
              {this.state.newAddress ? (
                <div className="text-start mt-4">
                  <p className="semiBold medText">New Shipping Address: </p>
                  <p className="mx-2">{this.state.newAddress}</p>
                </div>
              ) : null}
              <Row className="text-center w-100">
                <Col>
                  <button
                    className="pinkButton my-3"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ modalOpen: true });
                    }}>
                    {this.state.newAddress ? "Change" : "Add"} new Address
                  </button>
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <Row className="mb-4 w-75">
            <p className="mx-1 mb-1">{VPI_ADDRESS.name}</p>
            <p className="form-control mx-3 mb-1 px-1" style={{ width: "470px" }}>
              {VPI_ADDRESS.address}
            </p>
          </Row>
        )}

        <Row className="mb-4 w-75">
          <Col md={12}>
            {this.props.step === 4 ? (
              <ShippingMethodDD
                isColdShip={this.props.isColdShip}
                isBulkOrder={this.props.isBulkOrder}
                toggleRushButton={this.state.toggleRushButton}
                rushOrder={this.state.rushOrder}
                setShippingMethod={this.props.setShippingMethod}
                defaultValue={
                  this.props.shippingMethod
                    ? this.props.shippingMethod
                    : this.props.defaultShippingInfo
                    ? this.props.defaultShippingInfo.shippingMethod
                    : this.state.preferredShippingInfo.method
                }
                title="Shipping Method"
                name="shippingMethod"
                required={this.props.step === 4 ? true : false}
                setShipOptional={this.setShipOptional.bind(this)}
                modalView={this.props.modalView}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <div className="mt-4 pt-2">
              <input
                onChange={e => {
                  if (e.target.checked) {
                    for (let i = 0; i < document.getElementsByName("shippingMethod")[0].options.length; i++) {
                      if (
                        document.getElementsByName("shippingMethod")[0].options[i].innerText ===
                        "USPS 1-Day (PO BOX ONLY)"
                      ) {
                        document.getElementsByName("shippingMethod")[0].selectedIndex = i;

                        this.props.setShippingMethod("USPS 1-Day (PO BOX ONLY)");
                      }
                    }
                  }
                }}
                name="POBox"
                style={{ display: "none" }}
                className="form-check-input"
                type="checkbox"
                id="POBox"
                defaultChecked={this.props.defaultShippingInfo ? this.props.defaultShippingInfo.isPoBox : ""}
              />
              <label style={{ display: "none" }} className="form-check-label mx-2" htmlFor="POBox">
                PO Box
              </label>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 w-75">
          <FormGroup>
            <Label htmlFor="orderNotes" className="form-label">
              Order Notes
            </Label>
            <Input
              defaultValue={this.props.defaultShippingInfo ? this.props.defaultShippingInfo.orderNotes : ""}
              name="orderNotes"
              className="form-control"
              placeholder=""
              type="textarea"
            />
          </FormGroup>
        </Row>
        <Row className="mb-4 w-75">
          <Col md={6}>
            <div className="pt-2">
              <input
                onChange={e => {
                  this.props.setSignatureRequired?.(e.target.checked);
                }}
                name="signatureRequired"
                className="form-check-input"
                type="checkbox"
                id="signatureRequired"
                defaultChecked={
                  this.props.defaultShippingInfo ? this.props.defaultShippingInfo.isSignatureRequired : ""
                }
              />
              <label className="form-check-label mx-2" htmlFor="signatureRequired">
                Signature Required
              </label>
            </div>
          </Col>
        </Row>
        {/* <Row>
          <CreditBalance
            resetCreditApplied={this.props.resetCreditApplied.bind(this)}
            credit={this.props.credit}
            setCreditUsed={this.props.setCreditUsed.bind(this)}
            region="rxJourney"
            clinicId={this.props.clinic.id}
          />
        </Row> */}
        <Row className="mb-4 w-75">
          <Col md={6}>
            <div className="pt-2">
              {/* {this.state.isColdShip ? (
                <input
                  name="isRushOrder"
                  className="form-check-input"
                  type="checkbox"
                  id="isRushOrder"
                  disabled={this.state.isColdShip ? true : false}
                  checked={true}
                />
              ) : (
                <input
                  onChange={(e) => {
                    this.setRushOrder(e.target.checked);
                    this.setState({ rushOrder: e.target.checked });
                    this.setState({ toggleRushButton: this.state.toggleRushButton + 1 });
                  }}
                  name="isRushOrder"
                  className="form-check-input"
                  type="checkbox"
                  id="isRushOrder"
                  disabled={this.state.isColdShip ? true : false}
                  defaultChecked={
                    this.state.isColdShip === true
                      ? true
                      : this.props.defaultShippingInfo
                      ? this.props.defaultShippingInfo?.isRushOrder
                      : ""
                  }
                />
              )} */}
              <input
                onChange={e => {
                  this.setRushOrder(e.target.checked);
                  this.setState({ rushOrder: e.target.checked });
                  this.setState({ toggleRushButton: this.state.toggleRushButton + 1 });
                }}
                name="isRushOrder"
                className="form-check-input"
                type="checkbox"
                id="isRushOrder"
                defaultChecked={this.props.defaultShippingInfo ? this.props.defaultShippingInfo.isRushOrder : ""}
              />

              <label className="form-check-label mx-2" htmlFor="isRushOrder">
                Priority Order Fee
              </label>
            </div>
          </Col>
        </Row>
        <Row className="mb-4 w-75">
          {this.state.rushOrder ? (
            <RushOrderDD
              toggleRushButton={this.state.toggleRushButton}
              setRushOrderMethod={this.setRushMethod.bind(this)}
              defaultValue={this.props.defaultShippingInfo ? this.props.defaultShippingInfo.rushOrderMethod : ""}
              name="rushOrderMethod"
              required={this.props.step === 4 ? true : false}
            />
          ) : null}
        </Row>
        <Modal isOpen={this.state.modalOpen} scrollable={true} size="md" centered={true} zIndex={1300}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add New Address</h5>
            <button
              type="button"
              onClick={() => this.setState({ modalOpen: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AlertMessage msg={this.state.error} isError={true} />
            <form
              id="addNewAddressForm"
              className="form-horizontal px-sm-4 px-0"
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                this.addNewAddress(e);
              }}>
              <Row>
                <div className="mb-4">
                  <Label htmlFor="addressLine1" className="form-label">
                    Address line 1 <span className="requiredStar">*</span>
                  </Label>
                  <input
                    maxLength={50}
                    name="addressLine1"
                    value={this.state.newAddressObj.addressLine1}
                    onChange={e =>
                      this.setState({
                        newAddressObj: {
                          addressLine1: e.target.value,
                          addressLine2: this.state.newAddressObj.addressLine2,
                          zipcode: this.state.newAddressObj.zipcode,
                          state: this.state.newAddressObj.state,
                          city: this.state.newAddressObj.city,
                        },
                      })
                    }
                    className="form-control"
                    type="textarea"
                    required
                  />
                </div>
                <div className="mb-4">
                  <Label htmlFor="addressLine2" className="form-label">
                    Address line 2
                  </Label>
                  <input
                    maxLength={50}
                    name="addressLine2"
                    value={this.state.newAddressObj.addressLine2}
                    onChange={e =>
                      this.setState({
                        newAddressObj: {
                          addressLine1: this.state.newAddressObj.addressLine1,
                          addressLine2: e.target.value,
                          zipcode: this.state.newAddressObj.zipcode,
                          state: this.state.newAddressObj.state,
                          city: this.state.newAddressObj.city,
                        },
                      })
                    }
                    className="form-control"
                    type="textarea"
                  />
                </div>
                <div className="mb-4">
                  <Label htmlFor="city" className="form-label">
                    City <span className="requiredStar">*</span>
                  </Label>
                  <input
                    name="city"
                    value={this.state.newAddressObj.city}
                    onChange={e =>
                      this.setState({
                        newAddressObj: {
                          addressLine1: this.state.newAddressObj.addressLine1,
                          addressLine2: this.state.newAddressObj.addressLine2,
                          zipcode: this.state.newAddressObj.zipcode,
                          state: this.state.newAddressObj.state,
                          city: e.target.value,
                        },
                      })
                    }
                    className="form-control"
                    type="text"
                    required
                  />
                </div>
                <StateDD name="state" defaultValue={this.state.newAddressObj.state} required={true} />
                <div className="mb-4">
                  <Label htmlFor="zipcode" className="form-label">
                    Zip code <span className="requiredStar">*</span>
                  </Label>
                  <input
                    name="zipcode"
                    value={this.state.newAddressObj.zipcode}
                    onChange={e =>
                      this.setState({
                        newAddressObj: {
                          addressLine1: this.state.newAddressObj.addressLine1,
                          addressLine2: this.state.newAddressObj.addressLine2,
                          zipcode: e.target.value,
                          state: this.state.newAddressObj.state,
                          city: this.state.newAddressObj.city,
                        },
                      })
                    }
                    className="form-control"
                    required
                  />
                </div>
              </Row>
              <Row className="flexJCAC">
                <button className="pinkButton" type="submit">
                  {this.state.newAddress ? "Update" : "Add"} Address
                </button>
              </Row>
            </form>
          </div>
        </Modal>
        <ValidAddressModal
          isUSPS={this.props.shippingMethod?.toLowerCase().includes("usps")}
          showValidAddressModal={this.state.showValidateModal}
          fedexShippingAddress={this.state.fedexAddress}
          clinicAddress={this.state.shippingAddress}
          onClickNotValidate={this.onClickNotValidate.bind(this)}
          onClickValidate={this.onClickValidate.bind(this)}
          closeModal={this.closeModal.bind(this)}
        />
      </div>
    );
  }
}

export default AddShippingInfo;
