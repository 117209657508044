import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from 'reactstrap';
import LoggedOutNavbar from '../../components/loggedOutNavbar';
import Lottie from '../../components/lottie';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';
import Spinner from '../../components/spinner';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
const VerifyPatientEmail = (props) => {
  const [updatedPassword, setupdatedPassword] = useState(false);
  const [error, setError] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);

  const [isVerified, setVerification] = useState(false);

  useEffect(() => {
    setShowSpinner(true);
    axiosConfig
      .post('/accounts/verify-patient-email', {
        token: props.match.params.token,
      })
      .then(async (response) => {
        // console.log(response.data);
        await setVerification(true);
        await setShowSpinner(false);
      })
      .catch(async (error) => {
        await setVerification(false);
        await setError(error.response.data.message);
        await setShowSpinner(false);
        await errorResponse(error);
      });
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Verify Patient | VPI Compounding</title>
      </MetaTags>

      {showSpinner ? (
        <Spinner />
      ) : (
        <div className='account-pages p-sm-3 p-1 pt-5 mt-5'>
          <Container>
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='overflow-hidden'>
                  <CardBody className='pt-0'>
                    {isVerified ? (
                      <div className='m-sm-5 m-4 p-sm-5 p-4 text-center'>
                        <Lottie name='success-check' />
                        <p className='my-4'>Email Verified Successfully!</p>
                        <p>You may close this window now.</p>
                      </div>
                    ) : (
                      <div className='m-sm-5 m-4 p-sm-5 p-4 text-center'>
                        <Lottie name='sad-lottie' />
                        <p className='my-4'>{error}</p>
                        <p>The token is invalid or expired.</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </div>
  );
};

export default VerifyPatientEmail;
