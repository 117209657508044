/* eslint-disable react-hooks/exhaustive-deps */
import { DialogContext } from "context";
import { isUndefined, set, startsWith } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { useChangeRxStatusMutation } from "rtk";
import { RX_STATUS, RX_STATUS_PEND } from "screens/admin/Prescriptions/constants";
import { userRoleSelector } from "selectors";
import { USER_ROLES } from "utils";

function PrescriptionStatusDD({
  presId,
  rowId,
  rxStatus,
  patientId,
  bulkPresId,
  setCustomError,
  readOnly = false,
  paymentStatus,
  pageName,
  customError = "",
}) {
  const selectRef = useRef();
  const userRole = useSelector(userRoleSelector);
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [rxStatusMutate, { isLoading: rxLoading, error: rxError }] = useChangeRxStatusMutation();

  const errorMessage = useMemo(
    () => rxError?.data?.message || customError || "",
    [rxError?.data?.message, customError]
  );

  useEffect(() => {
    if (errorMessage) {
      setCustomError?.(errorMessage);
      resetStatus();
    }
  }, [errorMessage]);

  const mutateAction = useCallback(
    (isToSendNotification = undefined) => {
      const apiParam = {
        _id: rowId,
        bulkPresId,
        prescriptionId: presId,
        rxStatus: selectRef.current.value,
        patientId,
        pageName: pageName,
      };
      if (!isUndefined(isToSendNotification)) {
        set(apiParam, "isToSendNotification", isToSendNotification);
      }
      rxStatusMutate(apiParam);
    },
    [bulkPresId, pageName, patientId, presId, rowId, rxStatusMutate]
  );

  const dialogYesAction = useCallback(() => {
    closeDialog();
    mutateAction(true);
  }, [closeDialog, mutateAction]);

  const dialogNoAction = useCallback(() => {
    closeDialog();
    mutateAction(false);
  }, [closeDialog, mutateAction]);

  const resetStatus = useCallback(() => {
    selectRef.current.value = rxStatus;
  }, [rxStatus]);

  const updateStatus = useCallback(
    (e) => {
      const isHoldStatus = startsWith(selectRef.current.value, "HOLD");
      if (isHoldStatus) {
        showDialog({
          question: `You've changed the status to a "HOLD". Are you sure you want to notify the clinic?`,
          actionText: "Yes",
          cancelText: "No",
          onClickYes: dialogYesAction,
          onClickNo: dialogNoAction,
          closeModal: resetStatus,
        });
      } else {
        mutateAction();
      }
    },
    [showDialog, dialogYesAction, dialogNoAction, resetStatus, mutateAction]
  );

  const loading = useMemo(() => {
    if (rxLoading) {
      return (
        <Spinner size="sm" color="primary" style={{ marginLeft: "0.5rem" }}>
          ...
        </Spinner>
      );
    }
  }, [rxLoading]);

  const RX_OPTION = useMemo(() => {
    if (pageName === "AdminPendingPrescription") {
      return RX_STATUS_PEND;
    } else if (paymentStatus !== "Approved" && paymentStatus !== "Net 30 Approved" && paymentStatus !== "Net  Approved") {
      return RX_STATUS;
    } else {
      return RX_STATUS.filter((val) => val !== "CANCELLED");
    }
  }, [pageName, paymentStatus]);

  if (
    !readOnly &&
    (userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.PHARMACIST || userRole === USER_ROLES.RX_TECH)
  ) {
    return (
      <>
        <select
          ref={selectRef}
          onChange={updateStatus}
          style={{ maxWidth: "180px", borderColor: errorMessage ? "red" : "" }}
        >
          {RX_OPTION.map((value, i) => (
            <option key={`${presId}_${rowId}_${value}`} value={value} selected={rxStatus === value}>
              {value}
            </option>
          ))}
        </select>
        {loading}
      </>
    );
  }
  return rxStatus;
}

export default React.memo(PrescriptionStatusDD);
