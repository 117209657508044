import { DialogContext } from "context";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useSetPrimaryClinicLocationMutation } from "rtk";

function useClinicLocationAction({ listingName, listingMutation }) {
  const clinicLocationIdRef = useRef();
  const { showDialog, closeDialog } = useContext(DialogContext);

  const [mutateAction, { data: actionData, error: actionError, isLoading: actionLoading, reset: resetMutation }] =
    listingMutation();

  const [
    setPrimaryLocation,
    {
      data: primaryLocationData,
      error: setPrimaryError,
      isLoading: setPrimaryFetching,
      reset: setPrimaryMutationReset,
    },
  ] = useSetPrimaryClinicLocationMutation();

  const successMessage = useMemo(
    () => actionData?.message || primaryLocationData?.message || "",
    [actionData?.message, primaryLocationData?.message],
  );
  const mutateLoading = useMemo(() => actionLoading || setPrimaryFetching, [actionLoading, setPrimaryFetching]);
  const mutateError = useMemo(
    () => actionError?.data?.message || setPrimaryError?.data?.message || "",
    [actionError?.data?.message, setPrimaryError?.data?.message],
  );

  const deleteClinicLocationAction = useCallback(() => {
    closeDialog();
    resetMutation();
    mutateAction({
      clinicLocationId: clinicLocationIdRef.current ?? "",
      pageName: listingName,
    });
  }, [closeDialog, listingName, mutateAction, resetMutation]);

  const toggleConfirmationModal = useCallback(
    (id = "") => {
      clinicLocationIdRef.current = id;
      if (id) {
        showDialog({
          question: "Are you sure you want to delete this clinic location?",
          actionText: "Yes",
          cancelText: "No",
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: deleteClinicLocationAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [closeDialog, deleteClinicLocationAction, showDialog],
  );

  const setPrimaryClinicLocation = useCallback(
    (clinicId, clinicLocationId) => {
      setPrimaryMutationReset();
      setPrimaryLocation({ pageName: listingName, clinicId, clinicLocationId });
    },
    [listingName, setPrimaryLocation, setPrimaryMutationReset],
  );
  return {
    mutateError,
    mutateLoading,
    successMessage,
    clinicLocationIdRef,
    deleteClinicLocationAction,
    toggleConfirmationModal,
    setPrimaryClinicLocation,
  };
}

export default useClinicLocationAction;
