import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Cookies from 'universal-cookie';
import axiosConfig from '../../utils/axiosConfig';
import moment from 'moment';

import { encrypt, decrypt } from 'cryptofunc';
const PatientCreditCardDD = (props) => {
  const [cards, setCards] = useState([]);
  const [cardsList, setCardsList] = useState([]);

  useEffect(() => {
    let defaultValue = props.defaultValue ? props.defaultValue : {};

    axiosConfig
      .post(
        'patients/getPatientCreditCardsByPatientId',
        {
          patientId: props.patientID,
          userId: new Cookies().get('user_id'),
        },
        {
          headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
        }
      )
      .then(async (response) => {
        var cardsValues = [];
        var cardsArray = [];
        /*Decryption*/
        var encryptedArray = response.data.encryptedCreditCards;
        var decryptedArr = [];
        encryptedArray.forEach((card) => {
          decryptedArr.push(decrypt(card));
        });

        await decryptedArr.forEach((card) => {
          cardsValues.push(
            <option key={card._id} selected={moment(defaultValue.cardExpirationDate.replace(/\s/g, '')).format('DD-MM-YYYY') === moment(card.cardExpirationDate.replace(/\s/g, '')).format('DD-MM-YYYY') && defaultValue.creditCardNumber === card.creditCardNumber} value={card._id}>
              {card.cardHolderName + ' - card ending in ' + card.creditCardNumber.substring(card.creditCardNumber.length - 4, card.creditCardNumber.length)}
            </option>
          );

          cardsArray.push({
            _id: card._id,
            creditCardNumber: card.creditCardNumber,
            cardHolderName: card.cardHolderName,
            cardExpirationDate: card.cardExpirationDate.replace(/\s/g, ''),
            cardHolderAddressLine1: card.cardHolderAddressLine1,
            cardHolderAddressLine2: card.cardHolderAddressLine2,
            cardHolderState: card.cardHolderState,
            cardHolderCity: card.cardHolderCity,
            cardHolderZipcode: card.cardHolderZipcode,
            isCardDefault: card.isCardDefault,
          });
        });

        setCards(cardsValues);
        setCardsList(cardsArray);
      })
      .catch(() => {});
  }, [props.defaultValue, props.patientID]);

  return (
    <FormGroup>
      <Label className='normalText' for={props.name}>
        Credit Card {props.required ? <span className='requiredStar'>*</span> : ''}
      </Label>
      <p className='dropdownArrow'>
        <i className='fas fa-angle-down'></i>
      </p>
      <Input
        type='select'
        name={props.name}
        required={props.required}
        onChange={(e) => {
          props.setCreditCard(cardsList[cardsList.findIndex((x) => x._id === e.target.value)], 'patient');
        }}
      >
        <option value='' disabled selected>
          {cards.length ? 'Select' : 'Loading Credit Cards ...'}
        </option>
        {cards}
      </Input>
    </FormGroup>
  );
};

export default PatientCreditCardDD;
