import { useEffect, useMemo, useCallback, useRef, useState } from "react";
import { uniq, compact } from "lodash";
import moment from "moment";
import Cookies from "universal-cookie";

import axiosConfig, { errorResponse } from "utils/axiosConfig";
import { CREDIT_BALANCE } from "./constants";
import { getCreditRequested, getCredit, getCreditUsed } from "utils/constants";

const useCreditBalance = props => {
  const [balance, setBalance] = useState(props.credit);
  const [newBalance, setNewBalance] = useState("");
  const [reason, setReason] = useState("");
  const [balanceModal, setBalanceModal] = useState(false);
  const [balanceViewModal, setBalanceViewModal] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showUpdate, setShowUpdate] = useState(false);
  const [remainingCredit, setRemainingCredit] = useState("");
  const [creditToBeUsed, setCreditToBeUsed] = useState("");
  const [showSave, setShowSave] = useState(false);
  const [totalCredit, setTotalCredit] = useState(0);
  const editBool = window?.location?.pathname.includes("edit-prescription");

  useEffect(() => {
    setBalance(props.credit);
    setShowSave(props.creditRequested && props.creditRequested != 0 ? true : false);
    if (props.creditRequested && props.creditRequested != 0) {
      setCreditToBeUsed(props.creditRequested);
    } else {
      if (editBool === false) setRemainingCredit(props.totalCredit);
    }
  }, [props.credit, props.totalCredit, props.creditRequested]);

  useEffect(() => {
    setTotalCredit(props.totalCredit);
    //setRemainingCredit(props.totalCredit);
  }, [props.totalCredit]);

  useEffect(() => {
    if (props.deleteCartCredit) {
      deleteCredit();
    }
  }, [props.deleteCartCredit]);
  const deleteCredit = () => {
    if (editBool && props.count === 1) {
      props.incrementIt();
      let totalCr =
        parseFloat(totalCredit ? totalCredit : props.totalCredit ? props.totalCredit : 0) + parseFloat(creditToBeUsed);
      setTotalCredit(totalCr);
      setCreditToBeUsed(0);
      setRemainingCredit(totalCr);
      props.setCreditRequested(0);
      setShowSave(false);
      props.setDeleteCartCredit(false);
    } else {
      setCreditToBeUsed(0);
      setRemainingCredit(totalCredit ? totalCredit : props.totalCredit.toFixed(2));
      //setRemainingCredit(props.totalCredit.toFixed(2));
      if (props.region === "journey") props.setCreditRequested(0);
      else {
        props.setCreditRequested({ clinicLocationId: props.clinicLocationId, creditReq: 0, saved: false });
      }
      setShowSave(false);
    }
  };

  const setCreditBalance = () => {
    setShowSpinner(true);
    let balanceAmount = balance;
    if (props.totalAmount) {
    }
    axiosConfig
      .post(
        "clinics/setClinicLocationCredit",
        {
          clinicLocationId: props.clinicLocationId,
          credit: newBalance,
          reason: reason,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        setSuccess(response?.data?.message);
        setShowSpinner(false);
        setAddBalanceModal(false);
        props.reload();
      })
      .catch(error => {
        setError("An error occurred while trying to fetch product families. " + error?.response?.data?.message);
        setBalance(props.credit);
        setShowSpinner(false);
      });
  };

  const closeModal = () => {
    if ((success === "" || error) && props.reload) {
      setBalance(props.credit);
      props.reload();
    }
    localStorage.removeItem("totalAmount");
    setError("");
    //setSuccess("");
    setBalanceModal(false);
    setShowSpinner(false);
    setShowUpdate(false);
  };
  const closeModal2 = () => {
    setError("");
    //setSuccess("");
    setBalanceViewModal(false);
    setShowSpinner(false);
    setShowUpdate(false);
  };
  const closeModal3 = () => {
    setError("");
    //setSuccess("");
    setAddBalanceModal(false);
    setShowSpinner(false);
    setShowUpdate(false);
  };

  return {
    balance,
    setBalance,
    newBalance,
    setNewBalance,
    reason,
    setReason,
    balanceModal,
    setBalanceModal,
    balanceViewModal,
    setBalanceViewModal,
    addBalanceModal,
    setAddBalanceModal,
    showSpinner,
    setShowSpinner,
    error,
    setError,
    setCreditBalance,
    closeModal,
    closeModal2,
    closeModal3,
    success,
    showUpdate,
    setShowUpdate,
    setSuccess,
    remainingCredit,
    setRemainingCredit,
    creditToBeUsed,
    setCreditToBeUsed,
    useMemo,
    useCallback,
    showSave,
    setShowSave,
    totalCredit,
    setTotalCredit,
    deleteCredit,
  };
};
export default useCreditBalance;
