import React from "react";
import { PRESCRIPTION_BUTTONS, PRESCRIPTION_TYPES } from "../constants";
import { Row } from "reactstrap";
import { Link } from "react-router-dom";

const RenderPrescriptionButtons = (props) => {
  const { type = PRESCRIPTION_TYPES.pending } = props;
  return (
    <Row className={`px-3 my-4 justify-content-center`}>
      {Object.values(PRESCRIPTION_BUTTONS).map((item) => (
        <Link
          key={item.label}
          to={item.url}
          className={`${type === item.type ? "blueButton" : "whiteButton"} mx-2 text-center`}
          style={{ width: 180 }}
        >
          {item.label}
        </Link>
      ))}
    </Row>
  );
};
export default React.memo(RenderPrescriptionButtons);
