import { startsWith, unset } from "lodash";
import { FedexApi } from "../../rtkApi";
import { API_METHODS, END_POINTS } from "../../rtkConstant";

const FedexQuery = FedexApi.injectEndpoints({
  endpoints: builder => ({
    shippingMethod: builder.query({
      query: () => END_POINTS.SHIPPING_METHOD,
      transformResponse: response => {
        const { others, ...restMethods } = response?.shippingMethods ?? { others: [] };
        const pickup = others.filter(e => e === "PickUp");
        const usps = others.filter(e => startsWith(e.toLowerCase(), "usps"));
        return { ...restMethods, pickup, usps };
      },
    }),
    validateAddress: builder.query({
      keepUnusedDataFor: 0,
      query: ({ isUSPS = false, ...body }) => {
        let organizedBody = body;
        if (isUSPS) {
          organizedBody = { ...body.address, ...body.address.streetLines };
          unset(organizedBody, "streetLines");
        }
        return {
          url: isUSPS ? END_POINTS.USPS_VALIDATE_ADDRESS : END_POINTS.VALIDATE_ADDRESS,
          method: API_METHODS.POST,
          body: organizedBody,
        };
      },
    }),
  }),
});

export const { useLazyValidateAddressQuery, useShippingMethodQuery } = FedexQuery;
