import { set } from "lodash";
import { USER_ROLES } from "../../../utils";
import { PatientApi } from "../../rtkApi";
import {
  API_METHODS,
  CACHE_TAGS,
  EXPORT_END_POINT,
  PATIENT_END_POINT,
  downloadFile,
  providesList,
} from "../../rtkConstant";

const PatientQuery = PatientApi.injectEndpoints({
  endpoints: builder => ({
    getPatientIncompletePrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, patientId: user?.userData?.id, limit: apiArgs?.limit || 10 };
            const response = await baseQuery({
              url: PATIENT_END_POINT.PATIENT_PRESCRIPTION_HISTORY,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getPatientArchivedPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, patientId: user?.userData?.id, limit: apiArgs?.limit || 10 };
            const response = await baseQuery({
              url: PATIENT_END_POINT.PATIENT_ARCHIVED_HISTORY,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    exportClinicPatients: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { clinicId: user?.userData?.clinicId ?? "", userId: user?.userData?.id };
            const response = await baseQuery({
              url: EXPORT_END_POINT.CLINIC_PATIENT_REPORT,
              method: API_METHODS.POST,
              body: organizedBody,
              cache: "no-cache",
              responseHandler: response => downloadFile(response, "Patient List.csv"),
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    getPatientsList: builder.query({
      queryFn: async ({ clinicId = "", ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20, userId: user?.userData?.id };
            let url = PATIENT_END_POINT.GET_ALL_PATIENTS;
            if (clinicId) {
              url = PATIENT_END_POINT.CLINIC_PATIENT;
              const isClinicSide = user?.userData?.role?.toLowerCase().includes("clinic");
              const isSuperuser = user?.userData?.role === USER_ROLES.CLINIC_SUPER_USER|| user?.userData?.isSuperUserSameAsProvider;
              delete organizedBody.clinicLocationId;
              set(organizedBody, "clinicId", user?.userData?.clinicId ?? clinicId);
              if (isClinicSide && !isSuperuser) {
                delete organizedBody.clinicId;
                url = PATIENT_END_POINT.CLINIC_LOCATION_PATIENT;
                set(organizedBody, "clinicLocationIds", user?.userData?.clinicLocationIds);
              }
            }
            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: result => [{ type: CACHE_TAGS.PATIENT }, ...providesList(result?.patients, CACHE_TAGS.PATIENT)],
    }),
    getPatientsDetail: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { patientId: apiArgs?.patientId ?? "", userId: user?.userData?.id };
            const response = await baseQuery({
              url: PATIENT_END_POINT.GET_PATIENT_ID,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: result => [{ type: CACHE_TAGS.PATIENT, id: result?.id }],
    }),
    getPatientAddress: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { patientId: apiArgs?.patientId ?? "", userId: user?.userData?.id };
            const response = await baseQuery({
              url: PATIENT_END_POINT.PATIENT_ADDRESS_BY_ID,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          console.log({ error });
        }
      },
    }),
    getPatientPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              patientId: apiArgs?.patientId || user?.userData?.id,
              limit: apiArgs?.limit || 10,
            };
            const response = await baseQuery({
              url: apiArgs?.patientId
                ? PATIENT_END_POINT.PATIENT_PRESCRIPTIONS_CLINIC
                : PATIENT_END_POINT.PATIENT_PRESCRIPTIONS_ID,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: result => [
        { type: CACHE_TAGS.PATIENT },
        { type: CACHE_TAGS.PATIENT_PRESCRIPTION },
        ...providesList(result?.prescriptions, CACHE_TAGS.PATIENT),
      ],
    }),
  }),
});

export const {
  useLazyGetPatientPrescriptionQuery,
  useLazyExportClinicPatientsQuery,
  useLazyGetPatientsListQuery,
  useGetPatientsDetailQuery,
  useGetPatientAddressQuery,
  useLazyGetPatientArchivedPrescriptionQuery,
  useLazyGetPatientIncompletePrescriptionQuery,
} = PatientQuery;
