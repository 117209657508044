import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { cometChatApiKey, cometChatAppId, cometChatRegion } from "utils";

const CometChatApi = createApi({
  reducerPath: "CometChat",
  baseQuery: fetchBaseQuery({
    baseUrl: `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users`,
    prepareHeaders: headers => {
      headers.set("apiKey", cometChatApiKey);
      return headers;
    },
  }),
  endpoints: () => ({}),
});

export default CometChatApi;
