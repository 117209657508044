import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useGetClinicLocationDetailQuery, useLazyValidateAddressQuery } from "rtk";
import { userInfoSelector } from "selectors";

function useClinicLocationApi() {
  const { pathname, state: { clinicId: clinicParam = "" } = {} } = useLocation();
  const { id: locationId = "" } = useParams();
  const userInfo = useSelector(userInfoSelector);
  const isClinicLocationEdit = useMemo(() => pathname.includes("edit-location"), [pathname]);

  const {
    data: clinicLocationData,
    isFetching: clinicLocationFetching,
    error: clinicLocationError,
  } = useGetClinicLocationDetailQuery({ locationId: locationId }, { skip: !locationId });

  const [validateShippingAddress, { error: validateAddressError, isFetching: validateAddressFetching }] =
    useLazyValidateAddressQuery();

  const filterClinicId = useMemo(
    () => clinicParam || clinicLocationData?.clinicId || userInfo?.clinicId || "",
    [clinicParam, clinicLocationData?.clinicId, userInfo?.clinicId],
  );

  const apiLoading = useMemo(
    () => clinicLocationFetching || validateAddressFetching,
    [clinicLocationFetching, validateAddressFetching],
  );

  const apiError = useMemo(
    () => clinicLocationError?.data?.message || validateAddressError?.data?.message || "",
    [clinicLocationError?.data?.message, validateAddressError?.data?.message],
  );

  const getEditApiParam = useCallback(() => {
    const apiParam = { clinicId: filterClinicId || "" };
    if (clinicLocationData?.id) {
      apiParam["clinicLocationId"] = clinicLocationData?.id;
    }
    return apiParam;
  }, [clinicLocationData?.id, filterClinicId]);

  return {
    apiError,
    apiLoading,
    clinicLocationId: locationId,
    filterClinicId,
    clinicLocationData,
    isClinicLocationEdit,
    getEditApiParam,
    validateShippingAddress,
  };
}

export default useClinicLocationApi;
