import {
  addUserToCometChat,
  chunkingFriends,
  deleteUserFromCometChat,
  getAdminList,
  getClinicStaffList,
} from "utils/CometChatFunctions";
import { ClinicApi } from "../../rtkApi";
import { ADMIN_END_POINTS, API_METHODS, CACHE_TAGS, END_POINTS, PAGE_TO_ENDPOINT } from "../../rtkConstant";

const ClinicsMutation = ClinicApi.injectEndpoints({
  endpoints: builder => ({
    deleteClinic: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.DELETE_CLINIC,
              method: API_METHODS.DELETE,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", clinicId = "" }, { queryFulfilled, dispatch, getState }) {
        try {
          
            
            // Comet Chat Flow
            // Get the staff before deleting the clinic
            const staffList = await getClinicStaffList(clinicId);

            await queryFulfilled;

            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = ClinicApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.CLINIC, id: clinicId },
            ]);

            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }

            dispatch(
              ClinicApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache.originalArgs, listData => {
                return {
                  ...listData,
                  clinicListing: listData.clinicListing?.filter(record => record?.id !== clinicId),
                };
              }),
            );
            // Delete the staff from comet chat too
            staffList.forEach(id => {
              deleteUserFromCometChat(id);
            });
          
        } catch {}
      },
    }),
    disApproveClinic: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { userId: user?.userData?.id };
            const response = await baseQuery({
              url: `${ADMIN_END_POINTS.DISAPPROVE_CLINICS}/${apiArgs.clinicId}`,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", clinicId = "" }, { queryFulfilled, getState, dispatch }) {
        try {
          if (pageName) {
            await queryFulfilled;
            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = ClinicApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.CLINIC, id: clinicId },
            ]);

            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }
            dispatch(
              ClinicApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache?.originalArgs, listData => {
                return {
                  ...listData,
                  clinicListing: listData.clinicListing?.filter(record => record?.id !== clinicId),
                };
              }),
            );
          }
        } catch {}
      },
    }),
    approveClinic: builder.mutation({
      queryFn: async ({ providerName, superUserName, pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              applicationId: apiArgs?.clinicId,
              bAAForm: apiArgs?.bAAForm,
              bAAFormPDFUrl: apiArgs?.bAAFormPDFUrl,
            };
            const response = await baseQuery({
              url: `${ADMIN_END_POINTS.APPROVE_CLINICS}`,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      invalidatesTags: () => [CACHE_TAGS.APPROVED_CLINICS],
      async onQueryStarted(
        { providerName = "", superUserName = "", pageName = "", clinicId = "" },
        { queryFulfilled, getState, dispatch },
      ) {
        try {
          if (pageName) {
            const { data } = await queryFulfilled;
            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = ClinicApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.CLINIC, id: clinicId },
            ]);

            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }

            dispatch(
              ClinicApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache?.originalArgs, listData => {
                return {
                  ...listData,
                  clinicListing: listData.clinicListing?.filter(record => record?.id !== clinicId),
                };
              }),
            );
            // Comet Chat Flows
            if (data && providerName && superUserName) {
              await addUserToCometChat(superUserName, data.clinicSuperUserId, data.clinicName, "ClinicSuperUser");
              await addUserToCometChat(providerName, data.providerId, data.clinicName, "ClinicProvider");
              const getAdmin = await getAdminList();
              getAdmin.push(data.providerId);
              chunkingFriends(data.clinicSuperUserId, getAdmin);
            }
          }
        } catch {}
      },
    }),
    addClinic: builder.mutation({
      query: apiArgs => ({
        url: ADMIN_END_POINTS.ADD_CLINIC,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
      invalidatesTags: () => [CACHE_TAGS.APPROVED_CLINICS],
    }),
    registerClinic: builder.mutation({
      query: apiArgs => ({
        url: END_POINTS.REGISTER_CLINIC,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
  }),
});

export const {
  useAddClinicMutation,
  useRegisterClinicMutation,
  useDeleteClinicMutation,
  useApproveClinicMutation,
  useDisApproveClinicMutation,
} = ClinicsMutation;
