import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import React, { useMemo } from "react";
import { NESTED_CLINIC_PATIENT_SUMMARY_COLUMNS, columnHelper } from "utils";
import NestedRowCheckbox from "./NestedRowCheckbox";

function PatientSummaryNestedTable({ originalRow = {}, allowRefill = false, rowSelection, setRowSelection }) {
  const CHECKBOX_CELL = useMemo(() => {
    if (allowRefill) {
      return columnHelper.accessor("checkbox", {
        header: "",
        id: "checkbox",
        headerStyle: { minWidth: 70, paddingLeft: 0, paddingRight: 0 },
        cell: ({ row }) => <NestedRowCheckbox rowId={row.original?._id} tableRow={row} />,
      });
    }
  }, [allowRefill]);

  const reactTable = useReactTable({
    data: originalRow?.products,
    columns: [CHECKBOX_CELL, ...NESTED_CLINIC_PATIENT_SUMMARY_COLUMNS].filter(Boolean),
    manualPagination: true,
    getRowId: row => row._id,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: row => (row.original?.refills ?? 0) > 0,
    state: {
      rowSelection,
    },
  });
  return (
    <Box sx={{ margin: 1 }}>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                    <Typography variant="tableHeader" noWrap>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map((row, index) => (
              <TableRow key={`${row.id}_${index}`}>
                {row.getVisibleCells?.().map(cell => {
                  const isProductColumn = cell.column.columnDef.id === "Product";
                  return (
                    <TableCell
                      key={cell.id}
                      // width should be same as we mention in Table Columns
                      style={isProductColumn ? { overflow: "auto", maxWidth: 300 } : cell.column.columnDef.cellStyle}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default React.memo(PatientSummaryNestedTable);
