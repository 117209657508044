import React from "react";

import { CircularProgressbar } from "react-circular-progressbar";
import useMedicationItem from "./useMedicationItem.hook";

const MedicationItem = (props) => {
  const {
    medication = {},
    collapsibleRow = -1,
    updateCollapsibleState,
    index = 0,
    chartStartDate,
    scrollRightPanel,
    showRefillModal,
  } = props;
  const {
    isActive,
    productName,
    inProgress,
    progress,
    medicationId,
    refills,
    onClickHandler,
    onToggleClickHandler,
    onRefillClickHandler,
  } = useMedicationItem(props);
  return (
    <div
      className={`medication-item ${isActive ? "is-active" : ""}`}
      onClick={onClickHandler}
    >
      <div className="product-title">
        <span className="name">
          <i
            className={`bx ${
              isActive ? "bx-chevron-down" : "bx-chevron-right"
            }`}
          />
          <span>{productName}</span>
        </span>
        {inProgress && (
          <span className="medication-progress">
            <CircularProgressbar value={progress} text={`${progress}%`} />
          </span>
        )}
      </div>
      <div className="medication-details">
        <ToggleHistoryButton
          id={medicationId}
          refills={refills}
          onClick={onToggleClickHandler}
          showRefillModal={onRefillClickHandler}
        />
      </div>
    </div>
  );
};
export default MedicationItem;

const ToggleHistoryButton = ({ id, onClick, refills = 0, showRefillModal }) => {
  return (
    <>
      {/* // <Tooltip target={`medication-list-toggle-btn-${id}`} title="Hello fucker"> */}
      <button
        id={`medication-list-toggle-btn-${id}`}
        className="btn btn-primary"
        onClick={onClick}
      >
        Toggle History
      </button>
      {!!refills && (
        <button
          id={`medication-list-toggle-btn-${id}`}
          className="btn btn-primary"
          style={{ marginLeft: 10 }}
          onClick={showRefillModal}
        >
          Refill
        </button>
      )}
    </>
    // </Tooltip>
  );
};
