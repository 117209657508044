import React, { useContext, useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import VPICompoundingLogo from "../assets/vpi-images/vpi-logo-white.png";
import "../assets/vpi-styles/loggedInNavbar.css";
import Cookies from "universal-cookie";
import { PortalContext } from "context";
import { startIntercom } from "utils/intercom";
export default function PatientNavbar() {
  const cookies = new Cookies();
  const { portal } = useContext(PortalContext);
  var username = cookies.get("user_firstName") + " " + cookies.get("user_lastName");
  const appId = process.env.REACT_APP_COMET_CHAT_APP_ID;
  const environment = appId.includes("0cc03") ? "-DEV" : appId.includes("9272e") ? "-UAT" : "";

  // useEffect(() => {
  //   startIntercom(username, cookies.get("email"));
  // }, []);

  return (
    <Navbar collapseOnSelect expand="md" variant="dark" className="navbar">
      {portal !== "young" && (
        <Navbar.Brand id="navbarBrand" href="/" className="navbarBrand">
          <img src={VPICompoundingLogo} alt="logo" className="navLogo" />
        </Navbar.Brand>
      )}
      <Navbar.Toggle aria-controls="navbar-content" className="navbarToggle">
        <i className="navbarToggleIcon fa fa-fw fa-bars" />
      </Navbar.Toggle>
      <Navbar.Collapse id="navbar-content" className="navbarCollapse">
        <Nav className="ml-auto navbarContent">
          {/* TODO: Add links */}
          {/* HOME */}
          <LinkContainer
            className="navLink"
            to="/"
            activeClassName={window.location.pathname === "/" ? "activeLink" : ""}>
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          {/* CLINICS */}
          {/* PRESCRIPTIONS */}
          <NavDropdown
            title={
              <p className="m-0">
                Prescriptions <i className="mx-1 fas fa-angle-down"></i>
              </p>
            }
            id="nav-dropdown"
            className="navDD navLink">
            <LinkContainer className="navLink navDDLink" to="/prescription-history" activeClassName="activeLinkDark">
              <Nav.Link>Order Status</Nav.Link>
            </LinkContainer>
            <LinkContainer className="navLink navDDLink" to="/prescription" activeClassName="activeLinkDark">
              <Nav.Link>Prescription History</Nav.Link>
            </LinkContainer>
          </NavDropdown>
          <NavDropdown
            title={
              <span className="m-0">
                Education <i className="mx-1 fas fa-angle-down"></i>
              </span>
            }
            id="nav-dropdown"
            className="navDD navLink">
            <LinkContainer className="navLink navDDLink" to="/education/otc" activeClassName="activeLinkDark">
              <Nav.Link>OTC</Nav.Link>
            </LinkContainer>

            <LinkContainer className="navLink navDDLink" to="/education/aas" activeClassName="activeLinkDark">
              <Nav.Link>AAS</Nav.Link>
            </LinkContainer>

            <LinkContainer className="navLink navDDLink" to="/education/sarms" activeClassName="activeLinkDark">
              <Nav.Link>SARMS</Nav.Link>
            </LinkContainer>
            <LinkContainer className="navLink navDDLink" to="/education/ancillariess" activeClassName="activeLinkDark">
              <Nav.Link>Ancillaries</Nav.Link>
            </LinkContainer>

            <LinkContainer className="navLink navDDLink" to="/education/peptides" activeClassName="activeLinkDark">
              <Nav.Link>Peptides</Nav.Link>
            </LinkContainer>
            <LinkContainer className="navLink navDDLink" to="/education/rxcompounding" activeClassName="activeLinkDark">
              <Nav.Link>RXCompounding</Nav.Link>
            </LinkContainer>
            <LinkContainer
              className="navLink navDDLink"
              to="/education/legalcompliance"
              activeClassName="activeLinkDark">
              <Nav.Link>LegalCompliance</Nav.Link>
            </LinkContainer>
          </NavDropdown>
          <NavDropdown
            title={
              <p className="m-0">
                Tools <i className="mx-1 fas fa-angle-down"></i>
              </p>
            }
            id="nav-dropdown"
            className="navDD navLink">
            <LinkContainer
              className="navLink navDDLink"
              to="/tools/Analytical-Testing"
              activeClassName="activeLinkDark">
              <Nav.Link>Analytical Testing</Nav.Link>
            </LinkContainer>
            <LinkContainer className="navLink navDDLink" to="/tools/Dose-Calculators" activeClassName="activeLinkDark">
              <Nav.Link>Dose Calculators</Nav.Link>
            </LinkContainer>

            <LinkContainer
              className="navLink navDDLink"
              to="/tools/reconstitution-calculator"
              activeClassName="activeLinkDark">
              <Nav.Link>Reconstitution Calculator</Nav.Link>
            </LinkContainer>
            <LinkContainer className="navLink navDDLink" to="/tools/Half-Life-Plotter" activeClassName="activeLinkDark">
              <Nav.Link>Half Life Plotter</Nav.Link>
            </LinkContainer>
          </NavDropdown>

          <NavDropdown
            title={
              <p className="m-0">
                <i className="navUserCircle fas fa-user-circle"></i> {username}{" "}
                <i className="mx-1 fas fa-angle-down"></i>
              </p>
            }
            id="nav-dropdown"
            className="navDD navLink profileDD">
            <LinkContainer className="navLink navDDLink" to="/profile" activeClassName="activeLinkDark">
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
            <LinkContainer className="navLink navDDLink" to="/edit-profile" activeClassName="activeLinkDark">
              <Nav.Link>Edit Info</Nav.Link>
            </LinkContainer>

            <NavDropdown.Divider />

            <LinkContainer className="navLink navDDLink" to="/logout" activeClassName="activeLink">
              <Nav.Link>
                <i className="mx-1 logoutIcon fas fa-sign-out-alt"></i> Logout
              </Nav.Link>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
