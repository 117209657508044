import { includes, map, startsWith } from "lodash";
import { dateFormatterMountainTime } from "utils/constants";
import { AdminApi, ClinicApi } from "../../rtkApi";
import {
  ACTION_TO_ENDPOINT,
  ADMIN_END_POINTS,
  API_METHODS,
  CACHE_TAGS,
  PAGE_TO_ENDPOINT,
  RX_STATUS_COMPLETED,
  UPDATE_END_POINTS,
} from "../../rtkConstant";

const AdminPrescriptionMutation = AdminApi.injectEndpoints({
  endpoints: builder => ({
    changeShippingAddress: builder.mutation({
      // this mutation is used for changing the shipping address after order was completed.
      query: body => ({
        url: UPDATE_END_POINTS.COMPLETED_ORDER_SHIPPING_UPDATE,
        method: API_METHODS.POST,
        body: body,
      }),
      invalidatesTags: (result, error, { prescriptionId = "" }) => [
        { type: CACHE_TAGS.PRESCRIPTION, id: prescriptionId },
        { type: CACHE_TAGS.RX_WORKFLOW, id: prescriptionId },
      ],
      async onQueryStarted({ prescriptionId = "" }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(ClinicApi.util.invalidateTags([{ type: CACHE_TAGS.PRESCRIPTION, id: prescriptionId }]));
      },
    }),
    changeRxStatus: builder.mutation({
      query: ({ bulkPresId, pageName, ...body }) => ({
        url: ADMIN_END_POINTS.SET_PRESCRIPTION_STATUS,
        method: API_METHODS.POST,
        body: body,
      }),
      async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
        try {
          if (apiArgs.pageName) {
            let invalidateRxWorkflow = false;
            const { data } = await queryFulfilled;
            const removedRow = apiArgs?.isToReceivedAll || includes(RX_STATUS_COMPLETED, apiArgs?.rxStatus);
            if (RX_STATUS_COMPLETED[1] === apiArgs?.rxStatus) {
              invalidateRxWorkflow = true;
            }
            if (removedRow) {
              dispatch(AdminApi.util.invalidateTags([CACHE_TAGS.ARCHIVED]));
            }
            const listingEndpoint = PAGE_TO_ENDPOINT[apiArgs.pageName];
            let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.bulkPresId },
            ]);
            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }

            dispatch(
              //cache update
              AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
                return {
                  ...listData,
                  prescriptions: listData.prescriptions
                    ?.map(record => {
                      if (record.id === apiArgs?.bulkPresId) {
                        if (record?.subPrescriptionList?.length) {
                          let isRemovedSubPrescriptionCount = 0;
                          const subPrescriptionList = record.subPrescriptionList.map(subPrescription => {
                            if (subPrescription.id === apiArgs.prescriptionId) {
                              let subPrescriptionProductRemovedCount = 0;
                              const subPrescriptionProduct = subPrescription.products.map(product => {
                                if (removedRow && includes(RX_STATUS_COMPLETED, product?.rxStatus)) {
                                  subPrescriptionProductRemovedCount += 1;
                                } else if (RX_STATUS_COMPLETED[1] === product?.rxStatus) {
                                  invalidateRxWorkflow = true;
                                }
                                if (apiArgs?.isToReceivedAll || product._id === apiArgs?._id) {
                                  if (removedRow) {
                                    subPrescriptionProductRemovedCount += 1;
                                  }
                                  return { ...product, rxStatus: apiArgs?.rxStatus };
                                }
                                return product;
                              });
                              let subPrescriptionPatientProductRemovedCount = 0;
                              const subPrescriptionPatientProduct = subPrescription.patientsProducts.map(
                                patientProduct => {
                                  if (
                                    apiArgs?.isToReceivedAll ||
                                    (removedRow &&
                                      includes(RX_STATUS_COMPLETED, patientProduct?.products?.[0]?.rxStatus))
                                  ) {
                                    subPrescriptionPatientProductRemovedCount += 1;
                                  }
                                  if (RX_STATUS_COMPLETED[1] === patientProduct?.products?.[0]?.rxStatus) {
                                    invalidateRxWorkflow = true;
                                  }
                                  if (apiArgs?.isToReceivedAll || patientProduct.patientId === apiArgs.patientId) {
                                    if (removedRow) {
                                      subPrescriptionPatientProductRemovedCount += 1;
                                    }
                                    return {
                                      ...patientProduct,
                                      products: [{ ...patientProduct?.products?.[0], rxStatus: apiArgs?.rxStatus }],
                                    };
                                  }
                                  return patientProduct;
                                },
                              );
                              if (
                                removedRow &&
                                (subPrescriptionProductRemovedCount === subPrescriptionProduct?.length ||
                                  subPrescriptionPatientProductRemovedCount === subPrescriptionPatientProduct?.length)
                              ) {
                                isRemovedSubPrescriptionCount += 1;
                              }
                              return {
                                ...subPrescription,
                                products: subPrescriptionProduct,
                                patientsProducts: subPrescriptionPatientProduct,
                              };
                            } else if (removedRow) {
                              let subPrescriptionProductRemovedCount = 0;
                              // let
                              subPrescription.products?.forEach(product => {
                                if (apiArgs?.isToReceivedAll || includes(RX_STATUS_COMPLETED, product?.rxStatus)) {
                                  subPrescriptionProductRemovedCount += 1;
                                }
                              });
                              let subPrescriptionPatientProductRemovedCount = 0;
                              subPrescription?.patientsProducts?.forEach(patientProduct => {
                                if (
                                  apiArgs?.isToReceivedAll ||
                                  includes(RX_STATUS_COMPLETED, patientProduct?.products?.[0]?.rxStatus)
                                ) {
                                  subPrescriptionPatientProductRemovedCount += 1;
                                }
                              });
                              if (
                                removedRow &&
                                (subPrescriptionProductRemovedCount === subPrescription?.products?.length ||
                                  subPrescriptionPatientProductRemovedCount ===
                                    subPrescription?.patientsProducts?.length)
                              ) {
                                isRemovedSubPrescriptionCount += 1;
                              }
                            }

                            return subPrescription;
                          });
                          if (removedRow && isRemovedSubPrescriptionCount === subPrescriptionList?.length) {
                            return undefined;
                          }
                          return {
                            ...record,
                            subPrescriptionList: subPrescriptionList,
                          };
                        } else if (record.patientIds?.length > 1 && record?.patientsProducts?.length) {
                          let patientProductRemovedCount = 0;
                          const patientProduct = record.patientsProducts.map(patientProduct => {
                            if (
                              apiArgs?.isToReceivedAll ||
                              (removedRow && includes(RX_STATUS_COMPLETED, patientProduct?.products?.[0]?.rxStatus))
                            ) {
                              patientProductRemovedCount += 1;
                              if (RX_STATUS_COMPLETED[1] === patientProduct?.products?.[0]?.rxStatus) {
                                invalidateRxWorkflow = true;
                              }
                            }
                            if (patientProduct.patientId === apiArgs.patientId) {
                              if (removedRow) {
                                patientProductRemovedCount += 1;
                              }
                              return {
                                ...patientProduct,
                                products: [{ ...patientProduct?.products?.[0], rxStatus: apiArgs?.rxStatus }],
                              };
                            }
                            return patientProduct;
                          });

                          if (removedRow && patientProductRemovedCount === patientProduct.length) {
                            return undefined;
                          }
                          return {
                            ...record,
                            products: record.products
                              .map(product => {
                                if (product._id === apiArgs?._id) {
                                  return { ...product, rxStatus: apiArgs?.rxStatus };
                                }
                                return product;
                              })
                              .filter(Boolean),
                            patientsProducts: patientProduct,
                          };
                        } else {
                          let productRemovedCount = 0;
                          const products = record.products.map(product => {
                            if (removedRow && includes(RX_STATUS_COMPLETED, product.rxStatus)) {
                              productRemovedCount += 1;
                            } else if (RX_STATUS_COMPLETED[1] === product?.rxStatus) {
                              invalidateRxWorkflow = true;
                            }
                            if (apiArgs?.isToReceivedAll || product._id === apiArgs?._id) {
                              if (removedRow) {
                                productRemovedCount += 1;
                              }
                              return { ...product, rxStatus: data?.rxStatus };
                            }
                            return product;
                          });
                          if (removedRow && productRemovedCount === record.products.length) {
                            return undefined;
                          }
                          return {
                            ...record,
                            products: products,
                          };
                        }
                      }
                      return record;
                    })
                    .filter(Boolean),
                };
              }),
            );

            if (invalidateRxWorkflow) {
              dispatch(AdminApi.util.invalidateTags([CACHE_TAGS.RX_WORKFLOW]));
            }
          }
        } catch (e) {
          console.log({ e });
        }
      },
    }),
    completeOrder: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_PRESCRIPTION_COMPLETE,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      invalidatesTags: (result, error, { prescriptionIds = [] }) =>
        prescriptionIds.map(id => ({ type: CACHE_TAGS.PRESCRIPTION, id })),
      // async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
      //   try {
      //     if (apiArgs.prescriptionIds?.length > 0) {
      //       const { data } = await queryFulfilled;
      //       if (data) {
      //         if (apiArgs.prescriptionIds?.length > 1) {
      //           dispatch(
      //             AdminApi.util.invalidateTags(
      //               map(apiArgs.prescriptionIds, id => ({ type: CACHE_TAGS.PRESCRIPTION, id })),
      //             ),
      //           );
      //         } else {
      //           const organizedPrescription = {
      //             ...(data?.prescription ?? {}),
      //             date: dateFormatterMountainTime(data?.prescription?.prescriptionDate),
      //             status: data?.prescription?.prescriptionStatus === "Disapproved" ? "No" : "Yes",
      //             shipTo:
      //               data?.prescription?.shippingInfo?.shippingMethod === "PickUp"
      //                 ? data?.prescription?.shippingInfo?.shippingMethod
      //                 : `${data?.prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${data?.prescription?.shippingInfo?.shippingAddress?.addressLine2} ${data?.prescription?.shippingInfo?.shippingAddress?.city}, ${data?.prescription?.shippingInfo?.shippingAddress?.state}, ${data?.prescription?.shippingInfo?.shippingAddress?.zipcode}`,
      //           };
      //           const listingEndpoint = PAGE_TO_ENDPOINT.AdminRxWorkflowPrescription;
      //           let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
      //             { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescriptionIds[0] },
      //           ]);
      //           if (queryCache.length > 1) {
      //             queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
      //           } else {
      //             queryCache = queryCache[0];
      //           }
      //           //update the Rx Workflow cache instead of re-calling api
      //           dispatch(
      //             AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => ({
      //               ...listData,
      //               prescriptions: listData.prescriptions?.map(record => {
      //                 if (apiArgs.prescriptionIds[0] === record?.id) {
      //                   return organizedPrescription;
      //                 }
      //                 return record;
      //               }),
      //             })),
      //           );
      //         }
      //       }
      //     }
      //   } catch {}
      // },
    }),
    adminAcknowledgePrescription: builder.mutation({
      queryFn: async ({ pageName = "", action = "", ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ACTION_TO_ENDPOINT[action],
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
        try {
          if (apiArgs.pageName) {
            await queryFulfilled;
            if (apiArgs.action === "Approve") {
              dispatch(AdminApi.util.invalidateTags([CACHE_TAGS.RX_WORKFLOW]));
            } else {
              dispatch(AdminApi.util.invalidateTags([CACHE_TAGS.ARCHIVED]));
            }
            const listingEndpoint = PAGE_TO_ENDPOINT[apiArgs.pageName];
            let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescriptionId },
            ]);
            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }
            dispatch(
              AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
                if (apiArgs.action === "Approve") {
                  return {
                    ...listData,
                    prescriptions: listData.prescriptions
                      ?.map(record => {
                        if (record.id === apiArgs?.prescriptionId) {
                          if (record?.patientIds?.length > 1) {
                            if (record?.patientsProducts?.length) {
                              const patientsProducts = record.patientsProducts.filter(patientProduct =>
                                startsWith(patientProduct?.products?.[0]?.rxStatus?.toLowerCase(), "custom"),
                              );
                              const rxPadProducts = record.rxPadProducts?.filter(padProduct =>
                                startsWith(padProduct.rxStatus?.toLowerCase(), "custom"),
                              );
                              if (!Boolean(patientsProducts?.length) && !Boolean(rxPadProducts?.length)) {
                                return undefined;
                              }
                              return {
                                ...record,
                                rxPadProducts,
                                patientsProducts,
                              };
                            }
                          } else {
                            const products = record.products.filter(product =>
                              startsWith(product?.rxStatus?.toLowerCase(), "custom"),
                            );
                            if (!Boolean(products.length)) {
                              return undefined;
                            }
                            return {
                              ...record,
                              products: products,
                            };
                          }
                        }
                        return record;
                      })
                      .filter(Boolean),
                  };
                } else {
                  return {
                    ...listData,
                    prescriptions: listData.prescriptions?.filter(record => record?.id !== apiArgs?.prescriptionId),
                  };
                }
              }),
            );
          }
        } catch {}
      },
    }),
    adminPrescriptionNote: builder.mutation({
      query: ({ pageName = "", ...body }) => ({
        url: ADMIN_END_POINTS.ADMIN_PRESCRIPTION_MESSAGE,
        method: API_METHODS.POST,
        body,
      }),
      async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          const listingEndpoint = PAGE_TO_ENDPOINT[apiArgs.pageName];
          let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
            { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescriptionId },
          ]);
          if (queryCache.length > 1) {
            queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
          } else {
            queryCache = queryCache[0];
          }

          dispatch(
            AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
              return {
                ...listData,
                prescriptions: listData.prescriptions?.map(record => {
                  if (record.id === apiArgs?.prescriptionId) {
                    return { ...record, message: data?.updatedMessage };
                  }
                  return record;
                }),
              };
            }),
          );
        } catch {}
      },
    }),
    adminUpdateRxOrderInput: builder.mutation({
      query: body => ({
        url: ADMIN_END_POINTS.ADMIN_SET_RX,
        method: API_METHODS.POST,
        body,
      }),
      async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          const listingEndpoint = PAGE_TO_ENDPOINT.AdminRxWorkflowPrescription;
          let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
            { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescriptionIds?.[0] },
          ]);
          if (queryCache.length > 1) {
            queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
          } else {
            queryCache = queryCache[0];
          }

          dispatch(
            AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
              return {
                ...listData,
                prescriptions: listData.prescriptions?.map(record => {
                  if (record.id === apiArgs?.prescriptionIds?.[0]) {
                    return { ...record, rxOrderInput: data?.rxOrderInput };
                  }
                  return record;
                }),
              };
            }),
          );
        } catch {}
      },
    }),
    adminUpdateRxNumber: builder.mutation({
      query: ({ bulkPresId, pageName, ...body }) => ({
        url: ADMIN_END_POINTS.ADMIN_PRESCRIPTION_RX_NUMBER,
        method: API_METHODS.POST,
        body,
      }),
      async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          const listingEndpoint = PAGE_TO_ENDPOINT.AdminRxWorkflowPrescription;
          let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
            { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.bulkPresId },
          ]);
          if (queryCache.length > 1) {
            queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
          } else {
            queryCache = queryCache[0];
          }

          dispatch(
            AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
              return {
                ...listData,
                prescriptions: listData.prescriptions?.map(record => {
                  if (record.id === apiArgs?.bulkPresId) {
                    if (record?.subPrescriptionList?.length) {
                      return {
                        ...record,
                        subPrescriptionList: record.subPrescriptionList.map(subPrescription => {
                          if (subPrescription.id === apiArgs.prescriptionId) {
                            return {
                              ...subPrescription,
                              products: subPrescription.products.map(product => {
                                if (product._id === apiArgs?._id) {
                                  return { ...product, rxNumber: data?.rxNumber };
                                }
                                return product;
                              }),
                              patientsProducts: subPrescription.patientsProducts.map(patientProduct => {
                                if (patientProduct.patientId === apiArgs.patientId) {
                                  return {
                                    ...patientProduct,
                                    products: [{ ...patientProduct?.products?.[0], rxNumber: data?.rxNumber }],
                                  };
                                }
                                return patientProduct;
                              }),
                            };
                          }
                          return subPrescription;
                        }),
                      };
                    } else if (record.patientIds?.length > 1 && record?.patientsProducts?.length) {
                      return {
                        ...record,
                        products: record.products.map(product => {
                          if (product._id === apiArgs?._id) {
                            return { ...product, rxNumber: data?.rxNumber };
                          }
                          return product;
                        }),
                        patientsProducts: record.patientsProducts.map(patientProduct => {
                          if (patientProduct.patientId === apiArgs.patientId) {
                            return {
                              ...patientProduct,
                              products: [{ ...patientProduct?.products?.[0], rxNumber: data?.rxNumber }],
                            };
                          }
                          return patientProduct;
                        }),
                      };
                    }
                    return {
                      ...record,
                      products: record.products.map(product => {
                        if (product._id === apiArgs?._id) {
                          return { ...product, rxNumber: data?.rxNumber };
                        }
                        return product;
                      }),
                    };
                  }
                  return record;
                }),
              };
            }),
          );
          dispatch(ClinicApi.util.invalidateTags([{ type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescriptionId }]));
        } catch {}
      },
    }),
    replacementOrder: builder.mutation({
      query: body => ({
        url: ADMIN_END_POINTS.REPLACEMENT_ORDER,
        method: API_METHODS.POST,
        body: body,
      }),
      invalidatesTags: [CACHE_TAGS.RX_WORKFLOW],
    }),
    cancelOrder: builder.mutation({
      query: body => ({
        url: ADMIN_END_POINTS.CANCEL_ORDER,
        method: API_METHODS.POST,
        body: body,
      }),
      invalidatesTags: [CACHE_TAGS.RX_WORKFLOW],
    }),
  }),
});

export const {
  useCompleteOrderMutation,
  useChangeRxStatusMutation,
  useCancelOrderMutation,
  useReplacementOrderMutation,
  useAdminUpdateRxNumberMutation,
  useChangeShippingAddressMutation,
  useAdminAcknowledgePrescriptionMutation,
  useAdminPrescriptionNoteMutation,
  useAdminUpdateRxOrderInputMutation,
} = AdminPrescriptionMutation;
