import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { AlertMessage, ListingSearch } from "components";
import NavBar from "components/NavBar";
import Spinner from "components/spinner";
import { includes } from "lodash";
import React, { useCallback, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDeleteStaffMutation, useLazyGetStaffListQuery } from "rtk";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ADMIN_ALLOWED_CLINIC_ACTIONS, CLINIC_STAFF_COLUMNS, ROWS_LIMIT, columnHelper } from "utils";
import { useStaffAction, useStaffListing } from "./Hook";

function StaffListing() {
  const history = useHistory();
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);
  const {
    clinicId,
    rowLimit,
    tablePage,
    showSpinner,
    errorMessage,
    totalRecords,
    isClinicSide,
    searchKeyword,
    staffListing,
    setSorting,
    applyFilter,
    clearFilter,
    refetchList,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
  } = useStaffListing({
    listingQuery: useLazyGetStaffListQuery,
  });

  const { mutateError, mutateLoading, successMessage, toggleConfirmationModal, resendAutoGenRequest } = useStaffAction({
    listingName: "StaffListing",
    listingMutation: useDeleteStaffMutation,
  });

  const combineLoading = useMemo(() => showSpinner || mutateLoading, [mutateLoading, showSpinner]);
  const combinedErrorMessage = useMemo(() => errorMessage || mutateError || "", [errorMessage, mutateError]);

  const actonButtonCell = useCallback(
    rowData => {
      return (
        <Box component={"span"} className="d-flex justify-content-start">
          <Link
            to={
              isClinicSide
                ? "/staff-profile/" + rowData.row.original.id
                : "/staff-profile/" + clinicId + "/" + rowData.row.original.id
            }
            className="pinkButton">
            Profile
          </Link>
          <Tooltip title="Edit">
            <IconButton
              onClick={() =>
                history.push({
                  pathname: `/edit-staff/${rowData.row.original.id}`,
                  state: { clinicId },
                })
              }
              style={{ width: 30, height: 30, marginLeft: 10 }}>
              <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
            </IconButton>
          </Tooltip>

          {(includes(ADMIN_ALLOWED_CLINIC_ACTIONS, userRole) || userInfo?.isSuperUserSameAsProvider) && rowData.row.original.id !== userInfo?.id && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => toggleConfirmationModal(rowData.row.original.id)}
                style={{ width: 30, height: 30 }}>
                <DeleteIcon color="error" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
    [isClinicSide, clinicId, userRole, userInfo?.id, history, toggleConfirmationModal],
  );

  const columnAction = useMemo(
    () => [
      columnHelper.accessor("Pass", {
        header: "Auto-Gen Password",
        headerStyle: { textAlign: "center" },
        cell: rowData => {
          return (
            <Tooltip title="Resend">
              <IconButton
                onClick={() => resendAutoGenRequest(rowData.row.original.id)}
                style={{ width: 30, height: 30 }}>
                <ForwardToInboxIcon color="primary" style={{ width: 25, height: 25 }} />
              </IconButton>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        headerStyle: { minWidth: "180px", textAlign: "center" },
        cell: actonButtonCell,
      }),
    ],
    [actonButtonCell, resendAutoGenRequest],
  );

  const clinicPatientTableHeader = useMemo(
    () => [...CLINIC_STAFF_COLUMNS, ...columnAction].filter(Boolean),
    [columnAction],
  );

  const reactTable = useReactTable({
    data: staffListing,
    columns: clinicPatientTableHeader,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: row => row.id,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
  });

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>CLINIC STAFF | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>

          <h4 className="p-3 text-muted">CLINIC STAFF</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {combineLoading && <Spinner />}
                  <AlertMessage msg={combinedErrorMessage || successMessage} isError={combinedErrorMessage} />
                  <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item>
                      <ListingSearch
                        searchKeyword={searchKeyword}
                        applySearch={applyFilter}
                        clearFilter={clearFilter}
                        updateKeyword={updateKeyword}
                      />
                    </Grid>
                    <Grid item>
                      <Link
                        to={{
                          pathname: "/add-staff",
                          state: {
                            clinicId: clinicId,
                          },
                        }}
                        className="pinkButton">
                        Add Staff
                      </Link>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }} className="my-3">
                    <Tooltip title="Refresh">
                      <Button variant="contained" onClick={refetchList}>
                        <RefreshIcon style={{ width: 20, height: 20 }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <TableContainer style={{ maxHeight: "70vh" }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        {reactTable.getHeaderGroups().map(headerGroup => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                              return (
                                <TableCell
                                  key={header.id}
                                  style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                                  <Typography variant="tableHeader" noWrap>
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(header.column.columnDef.header, header.getContext())}
                                  </Typography>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {reactTable.getRowModel().rows.map(row => (
                          <TableRow key={row.id} sx={{ "& > *": { borderBottom: "unset" } }}>
                            {row?.getVisibleCells()?.map(cell => {
                              return (
                                <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {staffListing === 0 && !combineLoading ? (
                      <p className="w-100 text-center m-5">No staff data found!</p>
                    ) : null}
                  </TableContainer>
                  <TablePagination
                    component="div"
                    showFirstButton
                    showLastButton
                    page={tablePage}
                    count={totalRecords}
                    rowsPerPage={rowLimit}
                    rowsPerPageOptions={ROWS_LIMIT}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={changeRowLimit}
                    style={{ alignItems: "center", marginBottom: 0 }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(StaffListing);
