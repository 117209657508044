//Dashboard screens
import Dashboard from "./screens/admin/Dashboard/dashboard";
import Messages from "screens/admin/Dashboard/messages";
import { ClinicsList } from "screens/admin/Clinics";
import AddAdmin from "screens/admin/Dashboard/addAdmin";
import AdminsList from "screens/admin/Admins/list";

import AddClinic from "./screens/admin/Clinics/AddClinic.jsx";
import { InvoiceList } from "./screens/common/Invoices/InvoiceList";
import { InvoiceDetail } from "./screens/common/Invoices/InvoiceDetail";
import EditClinic from "./screens/admin/Clinic/edit";
import { ClinicProfile } from "./screens/common/Clinic";
import AdminProfile from "screens/admin/Admins/adminProfile";
import Profile from "screens/admin/Admins/profile";
import EditAdmin from "screens/admin/Admins/edit";
import { EditPrescription } from "./screens/common";
import { PrescriptionsSection, AdminPendingPrescription } from "./screens/admin/Prescriptions";
import { ProductListing as Products } from "./screens/admin/Products/index";
import { ClinicProductList as ClinicProducts } from "./screens/admin/Products/ClinicProductList";
import States from "screens/admin/States/states";
const Admin = {
  Dashboard,
  Messages,
  AddAdmin,
  ClinicsList,
  AdminsList,
  AdminPendingPrescription,
  EditPrescription,
  InvoiceList,
  InvoiceDetail,
  AddClinic,
  ClinicProfile,
  EditClinic,
  AdminProfile,
  Profile,
  EditAdmin,
  PrescriptionsSection,
  Products,
  ClinicProducts,
  States,
};

export default Admin;
