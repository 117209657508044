import { ClinicLocationApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, CLINIC_LOCATION_END_POINT, providesList } from "../../rtkConstant";

const ClinicLocationQuery = ClinicLocationApi.injectEndpoints({
  endpoints: builder => ({
    getClinicLocations: builder.query({
      queryFn: async ({ clinicId = "", ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              clinicId: clinicId || user?.userData?.clinicId,
              limit: 20,
              currentPage: 1,
              ...apiArgs,
            };
            const response = await baseQuery({
              url: CLINIC_LOCATION_END_POINT.CLINIC_LOCATIONS,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: result => providesList(result?.clinicLocations, CACHE_TAGS.CLINIC_LOCATION),
    }),

    getClinicLocationDetail: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const location = apiArgs?.locationId || user?.userData?.clinicLocationId;
            const response = await baseQuery({
              url: `${CLINIC_LOCATION_END_POINT.CLINIC_LOCATION_ID_DETAIL}/${location}`,
              method: API_METHODS.GET,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          console.log({ error });
        }
      },
      providesTags: result => providesList([{ ...result }], CACHE_TAGS.CLINIC_LOCATION),
    }),

    getClinicLocationAddress: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          
          if (user?.userData) {
            const location = apiArgs?.locationId || user?.userData?.clinicLocationId;
            const response = await baseQuery({
              url: `${CLINIC_LOCATION_END_POINT.CLINIC_LOCATION_ADDRESS}/${location}`,
              method: API_METHODS.GET,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          console.log({ error });
        }
      },
    }),
  }),
});

export const {
  useGetClinicLocationDetailQuery,
  useGetClinicLocationsQuery,
  useLazyGetClinicLocationsQuery,
  useLazyGetClinicLocationAddressQuery,
  useGetClinicLocationAddressQuery,
} = ClinicLocationQuery;
