import { set, unset } from "lodash";
import { ClinicApi } from "../../rtkApi";
import {
  ADMIN_END_POINTS,
  API_METHODS,
  CACHE_TAGS,
  EXPORT_END_POINT,
  downloadFile,
  providesList,
} from "../../rtkConstant";

const ClinicsQuery = ClinicApi.injectEndpoints({
  endpoints: builder => ({
    getApprovedClinicList: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.APPROVED_CLINICS,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            set(response, ["data", "clinicListing"], response?.data.clinics);
            unset(response, ["data", "clinics"]);
            return response;
          }
        } catch {}
      },
      providesTags: result => [
        { type: CACHE_TAGS.APPROVED_CLINICS },
        ...providesList(result?.clinicListing, CACHE_TAGS.CLINIC),
      ],
    }),

    getPendingClinicList: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.PENDING_CLINICS,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            set(response, ["data", "clinicListing"], response?.data.applications);
            unset(response, ["data", "applications"]);
            return response;
          }
        } catch (e) {
          console.log({ e });
        }
      },
      providesTags: result => providesList(result?.clinicListing, CACHE_TAGS.CLINIC),
    }),

    getExportClinicsList: builder.query({
      query: () => ({
        url: EXPORT_END_POINT.CLINICS_EXPORT,
        method: API_METHODS.GET,
        responseHandler: response => downloadFile(response, "vpi_clinics.csv"),
      }),
    }),
  }),
});

export const { useLazyGetApprovedClinicListQuery, useLazyGetPendingClinicListQuery, useLazyGetExportClinicsListQuery } =
  ClinicsQuery;
