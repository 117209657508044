import classnames from "classnames";
import Spinner from "components/spinner";
import React, { useEffect, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  CardText,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
} from "reactstrap";
import { useClinicDashboardCountQuery, useLazyMostDueInvoiceQuery } from "rtk";
import { userRoleSelector, userInfoSelector } from "selectors";
import { USER_ROLES } from "utils";
import "../../../assets/vpi-styles/dashboard.css";
import ClinicNavbar from "../../../components/ClinicNavbar";
import { BarChart } from "./BarChart";
import { UnPaidInvoicesWidget } from "components/PaymentGrid/PaymentGrid";
import { NOTES } from "components/PaymentGrid/PaymentGridConstants";

function Dashboard() {
  const [activeTab, setActiveTab] = useState(1);
  const userRole = useSelector(userRoleSelector);
  const { isFetching: isFetchCount, data, isError, error } = useClinicDashboardCountQuery();
  const [fetchClinicMostInvoiceDue, { isFetching: isCMID_Fetching, isError: isCMID_Error, error: CMID_Error }] =
    useLazyMostDueInvoiceQuery();
  const [oldInvoiceDue, setOldInvoiceDue] = useState("");
  const userInfo = useSelector(userInfoSelector);

  const showLoading = useMemo(() => isFetchCount || isCMID_Fetching, [isFetchCount, isCMID_Fetching]);
  function toggle(tab) {
    setActiveTab(prev => {
      if (prev !== tab) {
        return tab;
      } else {
        return prev;
      }
    });
  }

  useEffect(async () => {
    let arg = {
      payload: { clinicId: userInfo.clinicId },
    };
    const response = await fetchClinicMostInvoiceDue(arg, false);
    if (response?.data)
      setOldInvoiceDue({
        days: response?.data?.days ? response?.data?.days : "",
        invoiceNumber: response?.data?.invoiceNumber ? response?.data?.invoiceNumber : "",
        unPaidInvoices: response?.data?.unPaidInvoices ? response?.data?.unPaidInvoices : 0,
      });
  }, []);

  return (
    <React.Fragment>
      <ClinicNavbar />
      <div className="page-content">
        {activeTab === 1 ? (
          <MetaTags>
            <title>Dashboard | VPI Compounding</title>
          </MetaTags>
        ) : null}
        <Container fluid>
          {showLoading ? <Spinner /> : null}
          {isError ? (
            <UncontrolledAlert color="danger" className="text-center">
              {`An error occurred while trying to fetch details:  ${error}`}
            </UncontrolledAlert>
          ) : null}
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === 1 })}
                onClick={() => {
                  toggle(1);
                }}>
                Home
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === 2 })}
                onClick={() => {
                  toggle(2);
                }}>
                Resources
              </NavLink>
            </NavItem>
          </Nav>
          <Row>
            <TabContent activeTab={activeTab} className="p-sm-3 py-3 px-0 text-muted">
              <TabPane tabId={1}>
                <Row className="p-1">
                  <Col sm={12} md={12} lg={12}>
                    <Row className="dashboardCard flexCards m-0">
                      <Link to="/add-patient" className="dashboardCardContent text-muted">
                        <i className="fas fa-user-friends pinkText"></i>
                        <div>
                          <p >Create New Patient</p>
                        </div>
                      </Link>
                      <Link to="/product-search" className="dashboardCardContent text-muted">
                        <i className="fas fa-plus-circle greenText"></i>
                        <div>
                          <p >Create New RX</p>
                        </div>
                      </Link>
                      <Link   to={{ pathname: "/pending-prescriptions", state: "1" }} className="dashboardCardContent text-muted">
                        <i className="far fa-clock yellowText"></i>
                        <div>
                          <p >Order On Hold</p>
                          {/* <p className="number">{data?.prescriptionCounts.pendingPrescriptions || "-"}</p> */}
                        </div>
                      </Link>
                      <Link
                        to={{ pathname: "/pending-prescriptions", state: "2" }}
                        className="dashboardCardContent text-muted">
                        <i className="far fa-clock yellowText" />
                        <div>
                          <p >Provider Signature Needed</p>
                          {/* <p className="number">{data?.prescriptionCounts.providerPendingPrescriptions || "-"}</p> */}
                        </div>
                      </Link>

                    </Row>
                    <Row className="dashboardCard m-0">
                      <div className="dashboardCardContent largeCard" style={{ overflow: "hidden", height: "600px" }}>
                        {userRole === USER_ROLES.CLINIC_SUPER_USER || userInfo?.isSuperUserSameAsProvider ? (
                          <Link
                            to={{ pathname: "/invoices", state: { invoiceType: "UNPAID INVOICES" } }}
                            style={{ width: "100%" }}>
                            <UnPaidInvoicesWidget oldInvoiceDue={oldInvoiceDue} NOTES={NOTES} md={12} lg={12} />
                          </Link>
                        ) : null}
                        <p>Medication Statuses</p>
                        <BarChart rxData={data?.prescriptionCounts.rxStatusCounts} hide={activeTab !== 1} />
                      </div>
                    </Row>
                    {/* <Row className="m-2">
                        <Col sm={6} className="dashboardCard justifySpace p-0">
                          <div className="dashboardCardContent justifySpace">
                            <p>Expert Advice</p>
                            <button className="blueButton">View All</button>
                          </div>
                        </Col>
                        <Col sm={6} className="dashboardCard p-0">
                          <div className="dashboardCardContent justifySpace">
                            <p>Request Refill</p>
                            <button className="blueButton">View All</button>
                          </div>
                        </Col>
                      </Row> */}
                  </Col>
                  {/* <Col sm={12} md={12} lg={4} className="dashboardCard flexColumn px-4">
                      <div className="dashboardCardContent largeCard">
                        <p>Search patient</p>
                        <div className="mt-3 w-100 text-center flex-nowrap">
                          <input ref={e => this.patientFirstName = e} className="searchInput" type="text" placeholder="First name" />
                          <input ref={e => this.patientLastName = e} className="searchInput" type="text" placeholder="Last name" />
                          <i className="fas fa-search searchIcon" onClick={() => { this.searchPatient() }}></i>
                        </div>
                        <div className="userSearchResults w-100 p-3">
                          {this.state.patientSearchResults}
                        </div>
                      </div>
                      <div className="dashboardCardContent largeCard">
                        <p>Staff</p>
                        <div className="w-100 p-3">
                          {this.state.staffList}
                        </div>
                      </div>
                    </Col> */}
                </Row>
              </TabPane>
              {/* <TabPane tabId='2'>
                  <Row className='whiteButton'>
                    <Col md='2'>
                      <div className='productNav mt-1' id='accordion'>
                        <div style={{ paddingVertical: '20px', background: '#FBFDFE', border: 'none', width: '100%' }}>
                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'otc',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              OTC
                            </Link>
                          </div>
                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'aas',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              AAS
                            </Link>
                          </div>
                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'sarms',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              SARMS
                            </Link>
                          </div>
                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'peptides',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              Peptides
                            </Link>
                          </div>

                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'ancillaries',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              Ancillaries
                            </Link>
                          </div>
                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'rx',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              RX Compounding
                            </Link>
                          </div>
                          <div className='mt-2 d-grid'>
                            <Link
                              onClick={() => {
                                this.setState({
                                  currentEducationPage: 'legalcompliance',
                                });
                              }}
                              className='productNavLinkText p-2'
                            >
                              Legal Compliance
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md='10' className='mt-4'>
                      {this.state.currentEducationPage == 'otc' && <OTC />}
                      {this.state.currentEducationPage == 'aas' && <AAS />}
                      {this.state.currentEducationPage == 'sarms' && <SARMS />}
                      {this.state.currentEducationPage == 'ancillaries' && <Ancillaries />}
                      {this.state.currentEducationPage == 'peptides' && <Peptides />}
                      {this.state.currentEducationPage == 'rx' && <RXCompounding />}
                      {this.state.currentEducationPage == 'legalcompliance' && <LegalCompliance />}
                    </Col>
                  </Row>
                </TabPane> */}
              <TabPane tabId={2}>
                <Row>
                  <Col sm="12">
                    <CardText className="m-5 text-center">No data found</CardText>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(Dashboard);
