import fedex from "assets/images/shippingMethods/fedex.png";
import pickup from "assets/images/shippingMethods/pickup.png";
import ups from "assets/images/shippingMethods/ups.png";
import usps from "assets/images/shippingMethods/usps.png";

const SHIPPING_METHOD = [
  {
    id: 0,
    label: <img src={fedex} width="60" height="40" alt="Fedex" />,
    value: "fedex",
  },
  {
    id: 1,
    label: <img src={ups} width="50" height="40" alt="UPS" />,
    value: "ups",
  },
  {
    id: 2,
    label: <img src={usps} width="60" height="40" alt="USPS" />,
    value: "usps",
  },
  {
    id: 3,
    label: <img src={pickup} width="60" height="40" alt="Pickup" />,
    value: "pickup",
  },
];

const COMPLETE_SHIPPING_METHOD = [
  ...SHIPPING_METHOD,
  {
    id: 4,
    label: "Custom",
    value: "customRate",
  },
];

export { COMPLETE_SHIPPING_METHOD, SHIPPING_METHOD };
