import React, { useEffect, useState, useMemo } from "react";
import { Modal, Row, Input, Label, Spinner, Col, Alert } from "reactstrap";
import useGoogleAuthModal from "./useGoogleAuthModal";
import Lottie from "components/lottie";
const GoogleAuthModal = (props) => {
  const {
    googleAuthInfo,
    errorMessage,
    showError,
    showLoading,
    enable2FA,
    closeScan,
    setCloseScan,
    secretKey,
    setSecretKey,
    otpToken,
    setOtpToken,
    dataURL,
    complete,
    showModal,
    action,
    closeAuth: closeModal,
    enableMutateError,
    fetchGoogleKey,
    isError,
    completeAction,
    resetGoogle2FAlink,
    resetLinkError,
  } = useGoogleAuthModal(props);
  const resetLinkForm = useMemo(() => {
    if (action === "disable") {
      return (
        <form className="my-3">
          <h2>Disable Your PIN</h2>
          <Row className="flexJCAC mt-5">
            {" "}
            <input
              className="text-center form-control w-50"
              placeholder="ENTER PIN"
              value={otpToken}
              onChange={(e) => {
                setOtpToken(e.target.value);
              }}
              required
            />
            <button
              type="submit"
              className="blueButton mx-2 "
              onClick={(e) => {
                e.preventDefault();
                resetGoogle2FAlink({ otpToken: parseInt(otpToken) });
              }}
            >
              {"SUBMIT"}
            </button>
          </Row>
        </form>
      );
    }
  }, [action, otpToken]);

  return (
    <>
      <Modal
        isOpen={showModal}
        size={closeScan ? "sm" : "md"}
        centered={true}
        className={!closeScan ? "mediumHeightmodal" : "smallHeightmodal"}
      >
        <button
          type="button"
          onClick={() => {
            closeModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body flexJCAC flex-column text-center">
          {showLoading ? <Spinner /> : null}
          {isError ? (
            <Alert color="danger" className="text-center">
              {action == "enable" ? errorMessage : resetLinkError?.data?.message}
            </Alert>
          ) : null}
          {completeAction ? (
            <>
              {action === "enable" ? (
                <div>
                  {" "}
                  <Lottie name="success-check" />
                  <p className="my-1">{"2FA Enabled Successfully! "}</p> <p>{"Re-Login in 5 seconds."}</p>
                </div>
              ) : (
                <div>
                  {" "}
                  <p className="my-1">
                    {"Reset link has been send to your email. Please click on it to proceed."}
                  </p>{" "}
                </div>
              )}
            </>
          ) : (
            <div>
              {action === "enable" ? (
                <>
                  <div>
                    {closeScan ? (
                      <button
                        className="blueButton"
                        onClick={() => {
                          fetchGoogleKey();
                          setCloseScan(false);
                        }}
                      >
                        <i class="fas fa-qrcode mx-2"></i>Generate QR Code
                      </button>
                    ) : (
                      <div>
                        {googleAuthInfo?.dataURL ? (
                          <img src={googleAuthInfo.dataURL} style={{ width: "100px", height: "100px" }} />
                        ) : null}{" "}
                        <p>Please Open Google Authenticator and Scan the QR code.</p>
                        <p>Enter the latest PIN</p>
                        <form className="my-3">
                          <Row className="flexJCAC mt-5">
                            {" "}
                            <input
                              disabled={closeScan}
                              className="text-center form-control w-50"
                              placeholder="ENTER PIN"
                              value={otpToken}
                              onChange={(e) => setOtpToken(e.target.value)}
                              required
                            />
                            <button
                              type="submit"
                              disabled={closeScan}
                              className="blueButton mx-2 "
                              onClick={(e) => {
                                e.preventDefault();
                                enable2FA({ otpToken: parseInt(otpToken), otpSecretKey: secretKey });
                              }}
                            >
                              {"SUBMIT"}
                            </button>
                          </Row>
                        </form>
                      </div>
                    )}{" "}
                  </div>
                </>
              ) : (
                resetLinkForm
              )}
            </div>
          )}
        </div>
        {}
      </Modal>
    </>
  );
};

export default GoogleAuthModal;
