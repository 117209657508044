import AddCardIcon from "@mui/icons-material/AddCard";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Chip, Divider, FormControlLabel, Grid, Radio, Tooltip } from "@mui/material";
import { ClinicLocationApiStateContext, ClinicLocationStateContext } from "context";
import { decrypt, encrypt } from "cryptofunc";
import { map } from "lodash";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { formatCardNumber } from "react-payment-inputs/lib/utils/formatter";
import CreditCardRow from "screens/common/Patients/PatientInfo/CreditCardRow";

const cardFieldFormat = {
  cardType: "",
  cardHolderCity: "",
  cardHolderName: "",
  creditCardNumber: "",
  cardExpirationDate: "",
  cardHolderState: "",
  cardHolderZipcode: "",
  cardHolderAddressLine1: "",
  cardHolderAddressLine2: "",
  cardHolderContactNumber: "",
};

const ClinicLocationCard = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const { isClinicLocationEdit } = useContext(ClinicLocationStateContext);
  const { clinicLocationData } = useContext(ClinicLocationApiStateContext);
  const { control, handleSubmit, setValue, getValues, trigger } = useForm({
    defaultValues: {
      creditCards: [{ ...cardFieldFormat }],
      default_card_index: 0,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "creditCards",
  });

  const defaultAddressWatch = useWatch({ control, name: "default_card_index" });

  const isEmptyCardList = useMemo(() => fields.length < 1, [fields.length]);
  const radioBtn = useCallback(index => () => setValue("default_card_index", index), []);

  useEffect(() => {
    if (isClinicLocationEdit && clinicLocationData?.id) {
      let default_card_index = 0;
      const cardDetails = map(clinicLocationData?.encryptedCreditCards, (encryptedCard, index) => {
        const { isCardDefault, ...card } = decrypt(encryptedCard);
        if (isCardDefault) {
          default_card_index = index;
        }
        return { ...card, creditCardNumber: formatCardNumber(card.creditCardNumber) };
      });

      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = clinicLocationData?.[field] ?? "";
        if (field === "creditCards") {
          fieldValue = cardDetails;
        } else if (field === "default_card_index") {
          fieldValue = default_card_index;
        }
        setValue(field, fieldValue);
      }

      submitAction(getValues(), null, false);
    }
  }, [isClinicLocationEdit, clinicLocationData?.id]);

  const removeAddress = useCallback(
    index => () => {
      const cardIndex = getValues("default_card_index");
      const cardLength = getValues("creditCards").length - 1;
      if (cardLength) {
        remove(index);
        if (index === cardIndex && cardLength <= index) {
          setValue("default_card_index", index - 1);
        }
      }
    },
    [],
  );
  const appendAddress = useCallback(e => {
    e.preventDefault?.();
    append({
      ...cardFieldFormat,
    });
    setValue("default_card_index", getValues("creditCards").length - 1);
  }, []);

  const submitAction = useCallback(
    ({ default_card_index, creditCards = [], ...data }, progressBarIndex = null, allowNavigation = true) => {
      const organizedCards = map(creditCards ?? [], (card, index) =>
        encrypt({
          ...card,
          creditCardNumber: card?.creditCardNumber?.replace(/\s/g, ""),
          isCardDefault: index === default_card_index,
        }),
      );
      const info = { ...data, encryptedCreditCards: organizedCards };

      formData?.({ info }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const addressDivider = useCallback(
    index => {
      const isLastCard = !(getValues("creditCards").length - 1);
      return (
        <Divider className="mb-3">
          <Tooltip title="Remove the card below">
            <Chip
              color="secondary"
              label={`Credit Card ${index + 1}`}
              deleteIcon={<CancelIcon style={{ color: "#f44336" }} />}
              onClick={removeAddress(index)}
              onDelete={isLastCard ? null : removeAddress(index)}
            />
          </Tooltip>
        </Divider>
      );
    },
    [removeAddress],
  );

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        {/* Credit Cards */}
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {index > 0 && addressDivider(index)}
            <CreditCardRow
              mandatory={true}
              additionalInfo={true}
              name={`creditCards.${index}`}
              control={control}
              trigger={trigger}
            />
            <Grid item xs={12}>
              <FormControlLabel
                control={<Radio style={{ fontSize: 20 }} />}
                label="Default Credit Card"
                value={index}
                checked={index === defaultAddressWatch}
                onChange={radioBtn(index)}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid container justifyContent={"center"}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className="pinkButton"
          onClick={appendAddress}
          startIcon={<AddCardIcon />}>
          {`Add ${isEmptyCardList ? "Credit" : "Another"} Card`}
        </Button>
      </Grid>
    </Box>
  );
});

export default React.memo(ClinicLocationCard);
