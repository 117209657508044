/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { decrypt } from "cryptofunc";
import { filter, includes } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useLazyGetPendingClinicListQuery } from "rtk/rtkEndpoints/Clinics";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ADMIN_ALLOWED_CLINIC_ACTIONS } from "../utils";
import BAAFormModal from "./Modals/BAAFormModal";
export default function ReviewModel({
  showReviewModal = false,
  clinicId = "",
  error = "",
  apiParam = {},
  closeModal,
  approveAction,
  disApproveAction,
}) {
  const [trigger, { rowData }] = useLazyGetPendingClinicListQuery({
    selectFromResult: ({ data }) => ({
      rowData: filter(data?.clinicListing, { id: clinicId })?.[0],
    }),
  });
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);
  const creditCardInfo = useMemo(() => decrypt(rowData?.encryptedCreditCard), [rowData?.encryptedCreditCard]);
  const allowedAcknowledge = useMemo(() => includes(ADMIN_ALLOWED_CLINIC_ACTIONS, userRole) || userInfo?.isSuperUserSameAsProvider, [userRole]);

  // State for company info
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    signatureUrl: "",
    name: "",
    title: "",
    date: "",
  });

  console.log('COMAPNY URL: ',companyInfo)
  // State for VPI info
  const [vpiInfo, setVpiInfo] = useState({
    signatureUrl: "",
    name: "",
    title: "",
    date: "",
  });

  const [showBaaForm, setShowBaaForm] = useState(false);
  const [baaFormPDFUrl, setBaaFormPDFUrl] = useState('');

  useEffect(() => {
    if (clinicId) {
      trigger(apiParam, true);
    }
    let baaFormObj=rowData?.bAAForms[0];
    setCompanyInfo(baaFormObj?.companyInfo);
    console.log('BAA FORM COMPANY INFO: ',baaFormObj?.companyInfo?.companySignatureBase64String )
    setCompanyInfo(prevState => ({ ...prevState, signatureUrl: baaFormObj?.companyInfo?.companySignatureBase64String }))
    setVpiInfo(baaFormObj?.vpiInfo);
    console.log(baaFormObj)
  }, [clinicId,rowData]);

  const approveIt = useCallback(
    (e,baaFormPdf,cInfo,vInfo) => {
      e.preventDefault();
      approveAction?.(
        rowData?.providerFirstName + " " + rowData?.providerLastName,
        rowData?.officeContactFirstName + " " + rowData?.officeContactLastName,
        baaFormPdf,
        cInfo,
        vInfo
      );
    },
    [ baaFormPDFUrl,
      approveAction,
      rowData?.officeContactFirstName,
      rowData?.officeContactLastName,
      rowData?.providerFirstName,
      rowData?.providerLastName,
    ],
  );

  const disapproveIt = useCallback(
    e => {
      e.preventDefault();
      disApproveAction?.();
    },
    [disApproveAction],
  );

  const closeBaaModal = () => {
    setShowBaaForm(false);
  };

  return (
    <Dialog open={Boolean(showReviewModal)} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      <DialogTitle variant="h3">
        {"Clinic Information"}
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box className="mt-1">
          <table>
            <tr>
              <td style={{ verticalAlign: "top", padding: "10px" }}>
                <Row>
                  {" "}
                  <strong>
                    <p>Business Name</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.businessName}</p>
                  <strong>
                    {" "}
                    <p>Address Line 1</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.addressLine1} </p>
                  <strong>
                    {" "}
                    <p>Address Line 2</p>
                  </strong>{" "}
                  <p>{rowData?.addressLine2}-</p>
                  <strong>
                    {" "}
                    <p>City</p>
                  </strong>
                  <p>{rowData?.city}</p>
                  <strong>
                    {" "}
                    <p>State</p>
                  </strong>{" "}
                  <p>{rowData?.state}</p>
                  <strong>
                    {" "}
                    <p>Zip</p>
                  </strong>{" "}
                  <p>{rowData?.zipcode}</p>
                  <strong>
                    {" "}
                    <p>Office Phone</p>
                  </strong>{" "}
                  <p>{rowData?.officePhone}</p>
                  <strong>
                    <p>Office Email</p>
                  </strong>{" "}
                  <p>{rowData?.email}</p>
                  <strong>
                    <p>Office Fax</p>
                  </strong>{" "}
                  <p>{rowData?.fax ?? "-"}</p>
                  <strong>{rowData?.referredBy && <p>Referred By</p>} </strong>
                  {rowData?.referredBy && <p style={{ textTransform: "capitalize" }}>{rowData?.referredBy}</p>}
                  <strong>
                    <p>Prescriber is Super User </p>
                  </strong>{" "}
                  <p>{rowData?.isSuperUserSameAsProvider ? "Yes" : "No"}</p>
                  <hr></hr>
                  <strong>
                    <p>Prescriber Credentials </p>
                  </strong>{" "}
                  <p>{rowData?.providerCredentials ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Prescriber Name</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.providerFirstName + " " + rowData?.providerLastName} </p>
                  <strong>
                    {" "}
                    <p>Prescriber Phone</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.providerPhone} </p>
                  <strong>
                    {" "}
                    <p>Prescriber Email</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.providerEmail} </p>
                  <strong>
                    <p>Prescriber NPI</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.providerNpi ?? "-"}</p>
                  <strong>
                    <p>Prescriber State License #</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.providerMedicalLicenseNumber ?? "-"}</p>
                  <strong>
                    <p>Licensing State</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.providerLicensingState ?? "-"}</p>
                  <strong>
                    <p>State License Expiration Date</p>{" "}
                  </strong>{" "}
                  <p>
                    {rowData?.providerStateLicenseExpirationDate
                      ? moment(rowData?.providerStateLicenseExpirationDate).format("MM/DD/YYYY")
                      : "-"}
                  </p>{" "}
                  <strong>
                    <p>Copy of State License</p>
                  </strong>{" "}
                  <p>
                    <a href={rowData?.providerStateLicenseUrl} target="_blank" rel="noopener noreferrer">
                      State License
                    </a>
                  </p>
                  <strong>
                    <p>DEA License Expiration Date</p>{" "}
                  </strong>{" "}
                  <p>
                    {" "}
                    {rowData?.providerDeaLicenseExpirationDate
                      ? moment(rowData?.providerDeaLicenseExpirationDate).format("MM/DD/YYYY")
                      : "-"}
                  </p>
                  <strong>
                    <p>Prescriber DEA #</p>
                  </strong>{" "}
                  <p>{rowData?.providerDea ?? "-"}</p>
                  <strong>
                    <p>Copy of DEA License</p>
                  </strong>{" "}
                  <p>
                    <a href={rowData?.providerDeaLicenseUrl} target="_blank" rel="noopener noreferrer">
                      DEA License
                    </a>
                  </p>{" "}
                  <strong>
                    <p>Verbal Orders</p>
                  </strong>
                  <p>{rowData?.verbalOrders ?? "-"}</p>
                  <p>
                    <strong>Prescriber Signature</strong>
                  </p>
                  <p>
                    <img src={rowData?.providerSignatureImageUrl} alt="Signature" width={110} />
                  </p>
                </Row>
              </td>
              <td style={{ verticalAlign: "top", padding: "10px" }}>
                <Row>
                  <strong>
                    <p>Clinic Representative for all Customer Care Needs</p>
                  </strong>{" "}
                  <p>{rowData?.officeContactFirstName + " " + rowData?.officeContactLastName}</p>
                  <strong>
                    {" "}
                    <p>Contact Phone</p>
                  </strong>
                  <p>{rowData?.officeContactPhone ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Contact Email</p>
                  </strong>
                  <p>{rowData?.officeContactEmail}</p>
                  <strong>
                    <p>Preferred Billing Method</p>{" "}
                  </strong>{" "}
                  <p>{rowData?.preferredBillingMethod ?? "-"} </p>
                  <strong>
                    <p>Partial Shipment Available As Needed</p>
                  </strong>
                  <p>{rowData?.partialShipmentsAvailableasNeeded ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Refill Communication</p>
                  </strong>{" "}
                  <p>{rowData?.prescriptionRefillCommunication ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Refill Management</p>
                  </strong>{" "}
                  <p>{rowData?.refillManagement ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Company Logo</p>
                  </strong>{" "}
                  <p>{rowData?.companyLogoImageUrl ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Clinic Website URL</p>
                  </strong>{" "}
                  <p>{rowData?.clinicWebsiteURL ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>How did you hear about VPI Compounding?</p>
                  </strong>{" "}
                  <p>{rowData?.howDidYouHearAboutVPICompounding ?? "-"}</p>
                  <strong>
                    {" "}
                    <p>Other..</p>
                  </strong>{" "}
                  <p>{rowData?.other ?? "-"}</p>
                  <hr></hr>
                  <strong>
                    <p>Card Holder Name</p>
                  </strong>{" "}
                  <p>
                    {(creditCardInfo?.cardHolderFirstName ?? "") + " " + (creditCardInfo?.cardHolderLastName ?? "")}
                  </p>
                  <strong>
                    {" "}
                    <p>Credit Card #</p>
                  </strong>
                  <p>{`**** **** **** ${creditCardInfo?.creditCardNumber?.substring(
                    creditCardInfo?.creditCardNumber.length - 4,
                  )}`}</p>
                  <strong>
                    {" "}
                    <p>Expiry Date</p>
                  </strong>
                  <p>{`${creditCardInfo?.cardExpirationDate}`}</p>
                  <p>
                    <strong>Clinic Representative for Billing/Invoicing </strong>
                  </p>
                  <p>
                    {(rowData?.billingRepresentativeFirstName ?? "") +
                      " " +
                      (rowData?.billingRepresentativeLastName ?? "")}
                  </p>
                  <strong>
                    {" "}
                    <p>Card Holder Address</p>
                  </strong>{" "}
                  <p>
                    {creditCardInfo?.cardHolderAddressLine1}
                    <br />
                    {creditCardInfo?.cardHolderAddressLine2}
                  </p>
                  <strong>
                    {" "}
                    <p>Card Holder City</p>{" "}
                  </strong>
                  <p>{creditCardInfo?.cardHolderCity ?? "-"} </p>
                  <strong>
                    {" "}
                    <p>Card Holder State</p>{" "}
                  </strong>{" "}
                  <p>{creditCardInfo?.cardHolderState ?? "-"} </p>
                  <strong>
                    {" "}
                    <p>Card Holder Zipcode</p>{" "}
                  </strong>{" "}
                  <p>{creditCardInfo?.cardHolderZipcode ?? "-"}</p>
                  <p>
                    <strong>Email for Receipt </strong>
                  </p>
                  <p>{rowData?.billingRepresentativeEmail ?? "-"}</p>
                  <p>
                    <strong>Billing Representative Phone</strong>
                  </p>
                  <p>{rowData?.billingRepresentativePhone ?? "-"}</p>
                  <p>
                    <strong>Signature</strong>
                  </p>
                  <p>
                    <img src={rowData?.billingRepresentativeSignatureUrl} alt="Signature" width={110} />
                  </p>
                  {/* <p>
                    <strong>Sign BAA</strong>
                  </p>
                  <p>
                    <button
                      className="btn btn-primary"
                      style={{ backgroundColor: "red" }}
                      onClick={() => {
                        setShowBaaForm(true);
                      }}>
                      Sign
                    </button>
                  </p> */}
                </Row>
              </td>
            </tr>
          </table>
        </Box>
        <BAAFormModal
          vpiInfo={vpiInfo}
          setVpiInfo={setVpiInfo}
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          companyInfoRequired={true}
          vpiInfoRequired={true}
          setBaaFormPDFUrl={setBaaFormPDFUrl}
          closeModal={closeBaaModal}
          isCompanyInfoReadOnly={true}
          showBaaForm={showBaaForm}
          approveIt={approveIt}
          disapproveIt={disapproveIt}
        />
      </DialogContent>
      <DialogActions className="justify-content-center">
        <Box>
          {allowedAcknowledge ? (
            <Col className="flexJCAC pt-2">
              <Link to="/clinics" onClick={disapproveIt} className="pinkButton text-center  m-2 w-100">
                Disapprove
              </Link>
              <button onClick={()=>setShowBaaForm(true)} className="redButton text-center  m-2 w-100">
                Sign BAA
              </button>
            </Col>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
