import React, { useEffect, useState, useMemo } from "react";
import { Modal, Row, Input, Label, Spinner, Col, Alert } from "reactstrap";
import useGoogleAuthModal from "./useGoogleAuthModal";
import Lottie from "components/lottie";
import GoogleAuthModal from "./GoogleAuthModal";
const GoogleAuthToggle = (props) => {
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authActionType, setAuthActionType] = useState(false);
  const [enable2FA, setEnable2FA] = useState("");
  const actionGoogleAuth = (action) => {
    setAuthActionType(action);
    setShowAuthModal(true);
  };

  useEffect(() => {
    setEnable2FA(props.enable2FA);
  }, [props.enable2FA]);

  const closeAuthModal = (mess = "") => {
    if (mess) {
      setEnable2FA(mess);
    }
    setShowAuthModal(false);
  };

  return (
    <>
      <div>
        <label className="switch mb-0">
          <input
            type="checkbox"
            id={"enable-2fa"}
            checked={enable2FA}
            onChange={(e) => {
              if (e.target.checked) {
                actionGoogleAuth("enable");
              } else {
                actionGoogleAuth("disable");
              }
            }}
          />
          <span className="slider round"></span>
        </label>
        <GoogleAuthModal showModal={showAuthModal} action={authActionType} closeModal={closeAuthModal} />
      </div>
    </>
  );
};

export default GoogleAuthToggle;
