import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Alert } from "reactstrap";
import ClinicNavbar from "../../../components/ClinicNavbar";
import { Link } from "react-router-dom";
import Spinner from "../../../components/spinner";
import moment from "moment";
import { GoogleAuthToggle } from "components/GoogleAuthModal";
import { useClinicStaff } from "../Hook";
import { userInfoSelector } from "../../../selectors";
import { useSelector } from "react-redux";
export default function UserProfile(props) {
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [clinicLocation, setClinicLocation] = useState("");
  const { fetchStaff, user, showLoading, showError, errorMessage } = useClinicStaff(props);
  const userInfo = useSelector(userInfoSelector);
  useEffect(() => {
    fetchStaff({ staffId: userInfo.id });
  }, []);

  return (
    <React.Fragment>
      <ClinicNavbar />
      <div className="page-content">
        <MetaTags>
          <title>User Profile | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">MY PROFILE</h4>
          {showLoading ? (
            <Spinner />
          ) : (
            <>
              {showError ? (
                <Alert color="danger" className="text-center">
                  {errorMessage}
                </Alert>
              ) : null}
              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div
                      className="bg-primary bg-soft text-center py-5"
                      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      {!user?.profileImageUrl && (
                        <h1>
                          <i className="fas fa-user text-dark"></i>
                        </h1>
                      )}
                      {user?.profileImageUrl && (
                        <img className="staffProfileImgCircle mb-4" src={user?.profileImageUrl} alt="" />
                      )}
                      <h2 className="text-dark mt-1">{user?.firstName + " " + user?.lastName}</h2>
                      <p>{user?.staffType}</p>
                      <div className="flexJCAC">
                        {user?.isVerified ? (
                          <p className="text-white greenText px-4 py-1">Verified</p>
                        ) : (
                          <p className="text-white redText px-4 py-1">Not Verified</p>
                        )}
                      </div>
                      {user?.role !== "AdminSuperUser" ? (
                        <Link to="/edit-profile" className="pinkButton my-5 mt-3">
                          Edit Profile
                        </Link>
                      ) : null}
                    </div>
                  </Card>
                </Col>
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <CardTitle>Personal Information</CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>First Name</p>
                            <p>{user?.firstName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Middle Name</p>
                            <p>{user?.middleName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Last Name</p>
                            <p>{user?.lastName}</p>
                          </div>
                          {user?.role !== "AdminSuperUser" ? (
                            <>
                              <div className="InfoRow">
                                <p>Date of Birth</p>
                                <p>{moment(user?.dateOfBirth).format("MM/DD/YYYY")}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Sex</p>
                                <p>{user?.sex}</p>
                              </div>
                            </>
                          ) : null}
                        </Col>
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Role</p>
                            <p>{user?.role}</p>
                          </div>
                          {user?.role !== "AdminSuperUser" ? (
                            <>
                              <div className="InfoRow">
                                <p>NPI</p>
                                <p>{user?.npi}</p>
                              </div>
                              <div className="InfoRow">
                                <p>DEA</p>
                                <p>{user?.dea}</p>
                              </div>
                              <div className="InfoRow">
                                <p>State License #</p>
                                <p>{user?.medicalLicenseNumber}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Medical License Status</p>
                                <p>{user?.medicalLicenseStatus}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Clinic Location</p>
                                <p>{user?.clinicLocationName?.join(",")}</p>
                              </div>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {user?.role !== "AdminSuperUser" ? (
                    <>
                      <Card>
                        <CardBody>
                          <CardTitle>Contact Information</CardTitle>
                          <Row className="my-4 px-3">
                            <Col lg={12} xl={6}>
                              <div className="InfoRow">
                                <p>Email</p>
                                <p>{user?.email}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Phone Number</p>
                                <p>{user?.phoneNumber}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Mobile Number</p>
                                <p>{user?.mobileNumber}</p>
                              </div>
                            </Col>
                            <Col lg={12} xl={6}>
                              <div className="InfoRow">
                                <p>Address Line 1</p>
                                <p>{user?.addressLine1}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Address Line 2</p>
                                <p>{user?.addressLine2}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Country</p>
                                <p>{user?.country}</p>
                              </div>
                              <div className="InfoRow">
                                <p>City</p>
                                <p>{user?.city}</p>
                              </div>
                              <div className="InfoRow">
                                <p>State</p>
                                <p>{user?.state}</p>
                              </div>
                              <div className="InfoRow">
                                <p>Zip Code</p>
                                <p>{user?.zipcode}</p>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : null}
                  {user?.role === "ClinicProvider" || user?.role === "ClinicSuperUser" ? (
                    <Card>
                      <CardBody>
                        <CardTitle>Portal Information</CardTitle>
                        <Row className="my-4 px-3">
                          <Col lg={12} xl={6}>
                            <div className="InfoRow">
                              <p>Role</p>
                              <p>{user?.role}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Should Access Only Own Patients</p>
                              <p>{user?.shouldAccessOnlyOwnPatients ? "Yes" : "No"}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Verified</p>
                              <p>{user?.isVerified ? "Yes" : "No"}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Give Portal Access</p>
                              <p>{user?.isToGivePortalAccess ? "Yes" : "No"}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Auto Approve Order</p>
                              <p>{user?.isToAutoApproveOrder ? "Yes" : "No"}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Hippa Compliance</p>
                              <p>{user?.isHippaCompliance ? "Yes" : "No"}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Two-Factor Authentication</p>

                              {user.id === userInfo.id ? (
                                <GoogleAuthToggle enable2FA={user?.google_2f_enabled} />
                              ) : (
                                <p className={user?.google_2f_enabled ? "greenText" : "redText"}>
                                  {user?.google_2f_enabled ? "Enabled" : "Disabled"}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col lg={12} xl={6}>
                            <div className="InfoRow">
                              <p>State License #</p>
                              <p>{user?.medicalLicenseNumber}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Medical License Status</p>
                              <p>{user?.medicalLicenseStatus ? user?.medicalLicenseStatus : "-"}</p>
                            </div>
                            {user?.role === "ClinicProvider" && (
                              <>
                                <div className="InfoRow">
                                  <p>Copy of State License</p>
                                  <a href={user?.stateLicenseUrl} rel="noreferrer" target="_blank">
                                    State License
                                  </a>
                                </div>
                                <div className="InfoRow">
                                  <p>Copy of DEA License</p>
                                  <a href={user?.deaLicenseUrl} rel="noreferrer" target="_blank">
                                    DEA License
                                  </a>
                                </div>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}
