import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Grid } from "@mui/material";
import { CartDrawer } from "components";
import NavBar from "components/NavBar";
import { DialogContext } from "context";
import { encrypt } from "cryptofunc";
import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
import { ProductCartAction } from "rtk";
import NavigationSteps from "screens/common/EditPrescription/NavigationSteps";
import AddBillingInfo from "screens/common/add-billing-info";
import AddShippingInfo from "screens/common/add-shipping-info";
import CartContainer from "screens/common/cartContainer";
import SelectProducts from "screens/common/select-products";
import VerifyProviderAndLocation from "screens/common/verify-provider-and-location";
import Cookies from "universal-cookie";
import { USER_ROLES, cookies, matchAddressDea } from "utils";
import { RUSH_ORDER_INFO } from "utils/StatusConstant";
import { COOKIES_NAME } from "utils/cookieConstant";
import Lottie from "../../../components/lottie";
import Spinner from "../../../components/spinner";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";

class NewPrescription extends Component {
  static contextType = DialogContext;

  constructor(props) {
    super(props);
    this.cartRef = React.createRef();

    this.state = {
      step: 2,
      complete: false,
      isBulkOrder: false,
      shippingCost: "",
      selectedPatients: [],
      selectedProducts: [],
      businessName: "",
      providerId: "",
      emptyCart: true,
      disableNext: true,
      signatureRequired: false,
      savePrescription: false,
      shippingAddress: {},
      billingInfo: {},
      billTo: "clinic",
      creditCard: {},
      showSpinner: false,
      prescription: {},
      isNewAddressUsed: false,
      selectedPatientsData: [],
      isNewCardUsed: false,
      rushOrder: false,
      rushOrderMethod: "",
      shippingMethod: "",
      role: "",
      preferredProvider: {},
      selectedProvider: {},
      isNet30: false,
      shipToClinic: true,
      showEditModal: false,
      selectedProductToEdit: "",
      rushOrderCost: 0,
      clinic: "",
      credit: 0,
      creditUsed: false,
      creditRequested: 0,
      creditApplied: 0,
      totalAmount: 0,
      subTotal: 0,
      usedCredit: 0,
      clinicId: "",
      physicianId: "",
      clinicLocationAddress:"",
    };
    //Implemented Credit Balance at Prescription Flow at Local Storage
  }
  setSubTotal(s) {
    this.setState({
      subTotal: s,
    });
  }
  setCreditRequested(am) {
    this.setState({
      creditRequested: am,
    });
  }
  setRushOrderCost(cost) {
    this.setState({ rushOrderCost: cost });
  }

  setSignatureRequired(check) {
    this.setState({ signatureRequired: check });
  }

  setRushOrder(check, method = "") {
    this.setState({
      rushOrder: check,
      rushOrderMethod: method,
    });
  }

  async setShippingAddress(address, newAddressCheck) {
    
    await this.setState({
      shippingAddress: address,
      isNewAddressUsed: newAddressCheck,
    });

    this.updateShippingCost(address, this.state.shippingMethod);
  }

  setShippingMethod(shippingMethod) {
    this.setState({ shippingMethod: shippingMethod });

    this.updateShippingCost(this.state.shippingAddress, shippingMethod);
  }

  setCreditCard(card, newCardCheck, billTo) {
    this.setState({
      billTo: billTo,
      creditCard: card,
      isNewCardUsed: newCardCheck,
    });
  }

  editProduct(product) {
    this.setState({
      showEditModal: true,
      selectedProductToEdit: product,
    });
  }

  async isEditModal(bool) {
    await this.setState({
      showEditModal: bool,
    });
  }
  useCredit(bool) {
    this.setState({
      creditUsed: bool,
    });
  }

  componentWillMount() {
    let clinicId = cookies.get(COOKIES_NAME.CLINIC_ID);
    this.setState({ role: new Cookies().get("user_role") });
    if (this.props.location.state !== undefined) {
      clinicId = this.props.location.state.clinicId || clinicId;
      this.setState({
        clinicId,
        isBulkOrder: this.props.location?.state?.isBulkOrder,
        selectedPatients: this.props.location?.state?.selectedPatients ?? [],
        clinicLocationId: this.props.location?.state?.selectedPatients?.[0]?.clinicLocationId,
        selectedProducts: this.props.location?.state?.selectedProducts ?? [],
        emptyCart: this.props.location?.state?.selectedProducts?.length > 0 ? false : true,
        disableNext: this.props.location?.state?.selectedProducts?.length > 0 ? false : true,
      });
    }

    axiosConfig
      .get("clinics/getClinicByClinicId/" + clinicId, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async response => {
        this.setState({
          isNet30: response.data.paymentTermDays > 0,
          clinic: response.data,
          clinicId: response.data.id,
          businessName: response.data.businessName,
        });
        //Credit Added
      })
      .catch(error => {
        errorResponse(error);
      });
  }

  goBack() {
    this.setState({error:'',disableNext: false,})
    if (this.state.step === 2) {
      this.props.history.push("/patients");
    }
    if (this.state.step === 3) {
      this.props.dispatch(ProductCartAction.removeLastProductSummary());
    }

    this.setState({ step: this.state.step - 1 });
  }

  async updateSelectedProducts(selectedProducts) {
    await this.setState({
      selectedProducts: selectedProducts,
      emptyCart: selectedProducts.length > 0 ? false : true,
      disableNext: selectedProducts.length > 0 ? false : true,
    });
  }

  updateSelectedPatients(selectedPatients) {
    this.setState({
      selectedProvider: {},
      selectedPatients: selectedPatients,
      clinicLocationId: selectedPatients?.[0]?.clinicLocationId,
      disableNext: selectedPatients.length > 0 ? false : true,
    });
    if (selectedPatients.length === 1) {
      this.setState({
        selectedProvider: { id: selectedPatients?.[0].physicianId },
      });
    }
  }

  async removeProduct(product) {
    var filteredArray = this.state.selectedProducts.filter((e, i) => {
      return (
        e.productId?.toString() !== product?.productId?.toString() &&
        e.name?.toString() !== product?.productName?.toString()
      );
    });

    await this.setState({
      selectedProducts: filteredArray,
      disableNext: filteredArray.length > 0 ? false : true,
      emptyCart: filteredArray.length > 0 ? false : true,
    });
  }

  getPrescriptionObject() {
    var serialize = require("form-serialize");
    var form = document.getElementById("newPrescriptionForm");
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });
    var rxPadProd = [];
    var storedProducts = [];
    this.state.selectedProducts.forEach(prod => {
      if (prod?.productType === "custom") {
        rxPadProd.push({
          name: prod.name,
          quantity: prod.quantity,
          family: prod.family,
          strength: prod.strength,
          refills: prod.refills,
          sig: prod.sig,
          reasonForCompoundedMedication: prod.reasonForCompoundedMedication,
        });
      } else {
        storedProducts.push(prod);
      }
    });

    var rushMethodElement = document.getElementById("rushOrderMethod");

    var prescription = {
      userId: new Cookies().get("user_id"),
      clinicId: this.state.clinicId,
      clinicLocationId: this.state.clinicLocationId,
      providerId: obj.providerId,
      patientIds: this.state.selectedPatients.map(patient => patient.id),
      products: storedProducts,
      rxPadProducts: rxPadProd,
      //Credit
      creditRequested: parseFloat(this.state.creditRequested),
      shippingInfo: {
        //SHIP TO
        shipTo: this.state.isBulkOrder ? "clinic" : this.state.shipToClinic ? "clinic" : "patient",
        isNewAddressUsed: this.state.isNewAddressUsed,
        shippingAddress: this.state.shippingAddress,
        shippingMethod: this.state.shippingMethod,
        isPoBox: obj.POBox === "on" ? true : false,
        orderNotes: obj.orderNotes,
        isSignatureRequired: obj.signatureRequired === "on" ? true : false,
        isRushOrder: obj.isRushOrder === "on" ? true : false,
        rushOrderMethod: RUSH_ORDER_INFO.name,
        rushOrderCost: this.state.rushOrderCost,
      },

      encryptedBillingInfo: encrypt({
        creditCardOwner: this.state.billTo,
        isNewCardUsed: this.state.isNewCardUsed,
        creditCard: this.state.creditCard,
      }),
    };
    return prescription;
  }

  handleValidSubmit(e) {
    e.preventDefault();
    if (this.state.step === 1) {
      this.setState({
        step: 2,
        disableNext: this.state.selectedProducts.length > 0 ? false : true,
      });
    } else if (this.state.step === 2) {
      // Save the product summary in cart
      this.props.dispatch(
        ProductCartAction.addNewProductSummary({
          clinicId: this.state.clinicId,
          clinicLocationId: this.state.clinicLocationId,
          product: this.state.selectedProducts,
          patient: this.state.selectedPatients,
        }),
      );
      this.setState({ step: 3 });
    } else if (this.state.step === 3) {
      const { showDialog, closeDialog } = this.context;
      const isControlledSubstance = this.state.selectedProducts.some(product => product.controlledSubstance === "1");
      const hasProviderDetail =
        this.state.selectedProvider?.deaInfo?.filter(a => a.clinicLocationId === this.state.clinicLocationId)[0]?.dea &&
        this.state.selectedProvider?.deaInfo?.filter(a => a.clinicLocationId === this.state.clinicLocationId)[0]
          ?.deaLicenseUrl &&
        this.state.selectedProvider?.deaInfo?.filter(a => a.clinicLocationId === this.state.clinicLocationId)[0]
          ?.providerDeaLicenseExpirationDate;
      if (isControlledSubstance && !hasProviderDetail) {
        showDialog({
          question:
            "Provider doesn't have DEA credentials on file. Please edit provider profile or select alternative provider.",
          cancelText: "OK",
          onClickNo: closeDialog,
        });
        this.setState({ step: 3 });
        return;
      } else {
        
        this.setState({showSpinner:true})
        var cLocationId = this.state.clinicLocationId;
        axiosConfig
          .get("clinicLocations/getClinicLocationAddressByClinicLocationId/" + cLocationId, {
            headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
          })
          .then(async response => {
            var clinicLocationAddress = {
              addressLine1: response.data.addressLine1,
              addressLine2: response.data.addressLine2,
              city: response.data.city,
              state: response.data.state,
              zipcode: response.data.zipcode,
            };
            this.setState({showSpinner:false,clinicLocationAddress:clinicLocationAddress})
            if (this.state.isBulkOrder===true) {
              this.setState({ step: 4 });
            } else {
              const isAddressMatch = matchAddressDea(
                this.state.selectedProvider,
                clinicLocationAddress,
                this.state.clinicLocationId,
              );
              if (!isAddressMatch && isControlledSubstance) {
                showDialog({
                  question:
                    "This prescription order must be shipped directly to the patient due to the selected provider's DEA information.",
                  actionText: "OK",
                  onClickNo: closeDialog,
                  onClickYes: () => {
                    this.setState({ step: 4 });
                    closeDialog()
                  },
                });
              } else {
                this.setState({ step: 4 });
              }
            }
          })
          .catch(error => {
            errorResponse(error);
          });
      }
    } else if (this.state.step === 4) {
      const { showDialog, closeDialog } = this.context;
      const isControlledSubstance = this.state.selectedProducts.some(product => product.controlledSubstance === "1");
      const hasProviderDetail =
        this.state.selectedProvider?.deaInfo?.filter(a => a.clinicLocationId === this.state.clinicLocationId)[0]?.dea &&
        this.state.selectedProvider?.deaInfo?.filter(a => a.clinicLocationId === this.state.clinicLocationId)[0]
          ?.deaLicenseUrl;

      //Uncomment below to Work

      //Address for DEA controlled Substance
      const isAddressMatch = matchAddressDea(
        this.state.selectedProvider,
        this.state.clinicLocationAddress,
        this.state.clinicLocationId,
      );

      if (!isAddressMatch && isControlledSubstance && this.state.shipToClinic) {
        if (this.state.isBulkOrder) {
          showDialog({
            question: "DEA Address is not matching with the selected Clinic Address.",
            cancelText: "OK",
            onClickNo: closeDialog,
          });
        } else {
          showDialog({
            question: "Update your provider profile to allow clinic shipments for controlled substances.",
            cancelText: "OK",
            onClickNo: closeDialog,
          });
        }

        return;
      } else {
        this.setState({ step: 5 });
      }
    } else if (this.state.step === 5) {
      this.setState({ showSpinner: true });
      this.savePrescription(true);
    }
  }

  savePrescription(reviewCheck = false) {
    this.setState({ showSpinner: true });
    // 
    axiosConfig
      .post("clinic/rxOrdering/saveNewPrescription", this.getPrescriptionObject(), {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        this.setState({
          showSpinner: false,
          error: "",
        });

        if (reviewCheck) {
          this.props.history.push({
            pathname: !this.state.role.toLowerCase().includes("clinic")
              ? "/review-prescription/" + this.state.clinicId + "/" + response.data.prescriptionId
              : "/review-prescription/" + response.data.prescriptionId,
            state: {
              shipTo: this.state.shipToClinic,
              side: "newRx",
            },
          });
        } else {
          this.setState({
            savePrescription: true,
            complete: true,
          });
        }
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to save new prescription. " + error?.response?.data?.message,
        });

        errorResponse(error);
      });
  }

  //SHIP TO
  shipToClinicFunc(val) {
    this.setState({
      shipToClinic: val,
    });
  }

  updateShippingCost(address = "", method = "") {
    
    if (
      (address?.addressLine1 || this.state.shippingAddress?.addressLine1) &&
      (method || this.state.shippingMethod) &&
      this.state.selectedProducts &&
      this.state.selectedProducts.length > 0 &&
      this.state.step === 4
    ) {
      this.state.selectedProducts?.forEach(prod => {
        if (prod.unitPrice !== 0) {
          let body = {
            recipientAddress: {
              streetLines: {
                addressLine1: address?.addressLine1 ? address?.addressLine1 : this.state.shippingAddress.addressLine1,
                addressLine2: address?.addressLine2 ? address?.addressLine2 : this.state.shippingAddress.addressLine2,
              },
              city: address?.city ? address?.city : this.state.shippingAddress.city,
              state: address?.state ? address?.state : this.state.shippingAddress.state,
              postalCode: address?.zipcode ? address?.zipcode : this.state.shippingAddress.zipcode,
              residential: method === "GROUND_HOME_DELIVERY" ? true : false,
            },
            shippingMethod: method ? method : this.state.shippingMethod,
          };
          if (
            localStorage.getItem("rxJourney") === "new" &&
            body?.recipientAddress?.streetLines?.addressLine1 != "" &&
            localStorage.getItem("shipAddress1")?.toLowerCase() ===
              body?.recipientAddress?.streetLines?.addressLine1?.toLowerCase() &&
            localStorage.getItem("shipMethod")?.toLowerCase() === body?.shippingMethod?.toLowerCase()
          ) {
          } else {
            if (
              body?.recipientAddress?.streetLines?.addressLine1 &&
              body?.recipientAddress?.streetLines?.addressLine1 != ""
            ) {
              localStorage.setItem("rxJourney", "new");
              localStorage.setItem("shipAddress1", body?.recipientAddress?.streetLines?.addressLine1);
              localStorage.setItem("shipMethod", body.shippingMethod);
              this.setState({
                showSpinner: true,
              });
              axiosConfig
                .post("portal/getShippingRate", body, {
                  headers: {
                    Authorization: "Bearer " + new Cookies().get("user_token"),
                  },
                })
                .then(async response => {
                  this.setState({
                    shippingCost: response.data.shippingCost,
                    showSpinner: false,
                    error: "",
                  });
                })
                .catch(error => {
                  this.setState({
                    showSpinner: false,
                    error: error?.response?.data?.message,
                  });

                  errorResponse(error);
                });
            } else {
              this.setState({
                showSpinner: false,
                error: "Shipping Address Line 1 is invalid",
              });
            }
          }
        }
      });
    }
  }
  //Set Credit
  setCreditForLocation(locId) {
    this.setState({
      clinicLocationId: locId,
      credit: this.state.clinic?.clinicLocations?.filter(v => v.id === locId)[0]?.credit,
    });
  }

  setProvider(providerObj) {
    this.setState({
      selectedProvider: providerObj,
    });
  }

  resetCreditApplied() {
    this.setState({
      creditApplied: 0,
    });
  }

  updateDefaultShippingOnChange(ad) {
    this.setState({
      defaultShippingInfo: ad,
    });
  }

  async getCartProduct(product, patient) {
    await this.updateSelectedProducts(product);
    await this.updateSelectedPatients(patient);
  }

  clickBack(){
    
    this.setState({error:'',disableNext: false})
    this.props.history.goBack()
  }

  render() {
    // console.log({ selectedProvider: this.state.selectedProvider });
    return (
      <Grid container>
        <Grid item xs={12} lg={10} style={{ position: "relative" }}>
          <NavBar />
          <div className="page-content">
            <MetaTags>
              <title>New Prescription | VPI Compounding</title>
            </MetaTags>
            <Container fluid>
              {this.state.showSpinner ? <Spinner /> : null}
              <Button color="info" onClick={() =>this.clickBack() } startIcon={<ArrowBackIcon />}>
                Back
              </Button>
              <h4 className="p-3 text-muted">NEW PRESCRIPTION</h4>
              <Row>
                <Col xs="12">
                  <Card>
                    {!this.state.selectedPatients ? (
                      <CardBody>
                        <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                          <Lottie name="sad-lottie" />
                          <p className="mb-5">
                            You have not selected any patients yet! Please select some patients to order a new
                            prescription!
                          </p>
                          <Link to="/patients" className="pinkButton">
                            Patients List
                          </Link>
                        </div>
                      </CardBody>
                    ) : (
                      <CardBody>
                        {this.state.complete ? (
                          <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
                            <Lottie name="success-check" />
                            <p className="mb-5">
                              {this.state.savePrescription
                                ? "Your prescription has been saved successfully!"
                                : "Your order for a new prescription has been placed. You will be notified once it gets approved!"}
                            </p>
                            <Link to={{ pathname: "/pending-prescriptions", state: "2" }} className="pinkButton">
                              {this.state.savePrescription ? "Saved Prescriptions" : "Pending Prescriptions"}
                            </Link>
                          </div>
                        ) : (
                          <>
                            <NavigationSteps currentStep={this.state.step} />
                            <CardBody className="pt-0">
                              <div className="p-2">
                                {this.state.emptyCart && this.state.step > 2 ? (
                                  <Alert color="danger" className="text-center">
                                    Cart is Empty! Please select some products to continue!
                                  </Alert>
                                ) : null}
                                <form
                                  id="newPrescriptionForm"
                                  className="form-horizontal px-md-4 px-0"
                                  onSubmit={e => this.handleValidSubmit(e)}>
                                  <div className="mb-5 px-md-5 px-0">
                                    <Row className="d-flex justify-content-around">
                                      <Col
                                        className={`${
                                          this.state.step === 1
                                            ? "col-sm-12 newPrescriptionFormStep1"
                                            : "col-lg-9 newPrescriptionForm"
                                        } col-12`}>
                                        <div className={`mt-4 ${this.state.step === 2 ? "d-block" : "d-none"}`}>
                                          <SelectProducts
                                            clinicLocationId={this.state.clinicLocationId}
                                            clinicId={this.state.clinicId}
                                            selectedProductToEdit={this.state.selectedProductToEdit}
                                            isEditModal={this.isEditModal.bind(this)}
                                            showEditModal={this.state.showEditModal}
                                            isBulkOrder={this.state.isBulkOrder}
                                            selectedProducts={this.state.selectedProducts}
                                            updateSelectedProducts={this.updateSelectedProducts.bind(this)}
                                            selectedPatients={this.state.selectedPatients?.map(patient => patient.id)}
                                          />
                                        </div>
                                        <div
                                          className={`flexJCAC mx-sm-5 mx-0 mt-4 ${
                                            this.state.step === 3 ? "d-block" : "d-none"
                                          }`}>
                                          <VerifyProviderAndLocation
                                            step={this.state.step}
                                            setCreditForLocation={this.setCreditForLocation.bind(this)}
                                            setProvider={this.setProvider.bind(this)}
                                            businessName={this.state.businessName}
                                            defaultClinicId={
                                              this.state.preferredProvider.clinicId
                                                ? this.state.preferredProvider.clinicId
                                                : this.state.clinicId
                                            }
                                            defaultLocationId={
                                              this.state.selectedPatients.length === 1 && this.state.clinicLocationId
                                                ? this.state.clinicLocationId
                                                : null
                                            }
                                            defaultProviderId={
                                              this.state.selectedProvider?.id
                                                ? this.state.selectedProvider?.id
                                                : this.state.selectedPatients?.length === 1
                                                ? this.state.selectedPatients?.[0]?.physicianId
                                                : null
                                            }
                                            defaultProviderNPI={
                                              this.state.preferredProvider.providerNpi
                                                ? this.state.preferredProvider.providerNpi
                                                : null
                                            }
                                            defaultProviderDEA={this.state.preferredProvider?.deaInfo}
                                          />
                                        </div>
                                        <div
                                          className={`flexJCAC mx-sm-5 mx-0 mt-4 ${
                                            this.state.step === 4 ? "d-block" : "d-none"
                                          }`}>
                                          {this.state.error ? (
                                            <Alert color="danger" className="text-center">
                                              {this.state.error}
                                            </Alert>
                                          ) : null}
                                          <AddShippingInfo
                                            clinicLocationId={this.state.clinicLocationId}
                                            shipToClinicFunc={this.shipToClinicFunc.bind(this)}
                                            shipToClinic={this.state.isBulkOrder ? true : this.state.shipToClinic}
                                            updateShippingCost={this.updateShippingCost.bind(this)}
                                            updateDefaultShippingOnChange={this.updateDefaultShippingOnChange.bind(
                                              this,
                                            )}
                                            setShippingMethod={this.setShippingMethod.bind(this)}
                                            setShippingAddress={this.setShippingAddress.bind(this)}
                                            selectedPatients={this.state.selectedPatients?.map(patient => patient.id)}
                                            setRushOrder={this.setRushOrder.bind(this)}
                                            setSignatureRequired={this.setSignatureRequired.bind(this)}
                                            shippingMethod={this.state.shippingMethod}
                                            isBulkOrder={this.state.isBulkOrder}
                                            step={this.state.step}
                                            resetCreditApplied={this.resetCreditApplied.bind(this)}
                                            credit={this.state.credit}
                                            setCreditUsed={this.useCredit.bind(this)}
                                            clinic={this.state.clinic}
                                            isNet30={this.state.isNet30}
                                            billTo={this.state.billTo}
                                            setCreditCard={this.setCreditCard.bind(this)}
                                            selectedProducts={this.state.selectedProducts}
                                          />
                                        </div>
                                        <div
                                          className={`flexJCAC mx-sm-5 mx-0 mt-4 ${
                                            this.state.step === 5 ? "d-block" : "d-none"
                                          }`}>
                                          {this.state.error ? (
                                            <Alert color="danger" className="text-center">
                                              {this.state.error}
                                            </Alert>
                                          ) : null}
                                          <AddBillingInfo
                                            setCreditRequested={this.setCreditRequested.bind(this)}
                                            resetCreditApplied={this.resetCreditApplied.bind(this)}
                                            credit={this.state.credit}
                                            creditRequested={this.state.creditRequested}
                                            setCreditUsed={this.useCredit.bind(this)}
                                            clinic={this.state.clinic}
                                            isNet30={this.state.isNet30}
                                            clinicLocationId={this.state.clinicLocationId}
                                            billTo={this.state.billTo}
                                            setCreditCard={this.setCreditCard.bind(this)}
                                            selectedPatients={this.state.selectedPatients?.map(patient => patient.id)}
                                            isBulkOrder={this.state.isBulkOrder}
                                            subtotal={this.state.subTotal}
                                            step={this.state.step}
                                          />
                                        </div>
                                      </Col>
                                      {this.state.step > 1 ? (
                                        <div className="col-lg-3 col-12">
                                          <CartContainer
                                            setCreditRequested={this.setCreditRequested.bind(this)}
                                            creditRequested={this.state.creditRequested}
                                            credit={this.state.credit}
                                            creditUsed={this.state.creditUsed}
                                            creditApplied={this.state.creditApplied}
                                            setRushOrderCost={this.setRushOrderCost.bind(this)}
                                            editProduct={this.editProduct.bind(this)}
                                            showEditModal={this.state.showEditModal}
                                            shippingCost={this.state.shippingCost ? this.state.shippingCost : ""}
                                            shippingMethod={this.state.shippingMethod}
                                            rushOrder={this.state.rushOrder}
                                            rushOrderMethod={this.state.rushOrderMethod}
                                            selectedPatients={this.state.selectedPatients}
                                            selectedProducts={this.state.selectedProducts}
                                            emptyCart={this.state.emptyCart}
                                            removeProduct={this.removeProduct.bind(this)}
                                            setSubTotal={this.setSubTotal.bind(this)}
                                          />
                                        </div>
                                      ) : null}
                                    </Row>
                                  </div>
                                  <div
                                    className="p-4 flexJCAC"
                                    style={{
                                      position: "fixed",
                                      bottom: 0,
                                      left: 0,
                                      right: 0,
                                      background: "white",
                                      textAlign: "center",
                                      zIndex: 3,
                                    }}>
                                    {this.state.step === 1 ? null : (
                                      <button
                                        className="lightButton mx-2 pointer"
                                        style={{ width: "200px" }}
                                        onClick={e => {
                                          e.preventDefault();
                                          this.goBack();
                                        }}>
                                        Back
                                      </button>
                                    )}
                                    {this.state.step === 5 ? (
                                      <>
                                        {
                                          <button
                                            style={{ width: "200px" }}
                                            className="yellowButton"
                                            onClick={e => {
                                              e.preventDefault();
                                              this.savePrescription();
                                            }}>
                                            Save Prescription
                                          </button>
                                        }

                                        <button
                                          style={{ width: "200px" }}
                                          className="blueButton mx-2"
                                          type="submit"
                                          disabled={this.state.role === USER_ROLES.CLINIC_SUPPORT}>
                                          Review & Submit
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        style={{ width: "200px" }}
                                        disabled={this.state.disableNext || this.state.error}
                                        className="blueButton"
                                        type="submit">
                                        Next
                                      </button>
                                    )}
                                  </div>
                                </form>
                              </div>
                            </CardBody>
                          </>
                        )}
                      </CardBody>
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Grid>
        <Grid
          item
          xs={0}
          lg={2}
          sx={{
            display: { xs: "none", lg: "flex" },
          }}>
          <CartDrawer
            ref={this.cartRef}
            drawerVariant={"persistent"}
            getCartProduct={this.getCartProduct.bind(this)}
            hideSelect={this.state.step > 2}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(null, mapDispatchToProps)(NewPrescription);
