import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { trim } from "lodash";
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import MaskedInput from "./MaskedInput";

const defaultMuiFieldProps = { label: "", fullWidth: true, type: "text", shrink: false, isMasked: false };

// type => HTML input type

const OutlineInputField = forwardRef(
  ({ isRequired = false, error = "", inputField, MuiFieldProps = defaultMuiFieldProps, MuiInputProps }, ref) => {
    const {
      label = "",
      fullWidth = true,
      type = "text",
      size = "medium",
      isPassword = false,
      shrink,
      isMasked = false,
      ...restMUIFieldProps
    } = MuiFieldProps;
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), []);
    const OutlineInputComponent = useMemo(() => (isMasked ? MaskedInput : undefined), [isMasked]);

    const endAdornment = useMemo(() => {
      if (isPassword) {
        return (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              style={{ width: 25, height: 25 }}
            >
              {showPassword ? (
                <VisibilityOff style={{ width: 20, height: 20 }} />
              ) : (
                <Visibility style={{ width: 20, height: 20 }} />
              )}
            </IconButton>
          </InputAdornment>
        );
      }
    }, [handleClickShowPassword, isPassword, showPassword]);

    return (
      <FormControl
        error={Boolean(trim(error))}
        fullWidth={fullWidth}
        disabled={restMUIFieldProps?.disabled}
        size={size}
      >
        <InputLabel shrink={shrink} htmlFor={label}>
          {label}
          {isRequired && <span className="requiredStar"> *</span>}
        </InputLabel>
        <OutlinedInput
          {...inputField}
          inputRef={ref}
          notched={shrink}
          type={showPassword ? "text" : type}
          fullWidth={fullWidth}
          inputProps={{
            ...MuiInputProps,
          }}
          label={`${label} ${isRequired ? "*" : ""}`}
          endAdornment={endAdornment}
          {...restMUIFieldProps}
          inputComponent={OutlineInputComponent}
        />
        <FormHelperText variant="outlined" style={{ fontSize: 10 }}>
          {error}
        </FormHelperText>
      </FormControl>
    );
  }
);

export default React.memo(OutlineInputField);
