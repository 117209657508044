/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import { FormCheckBox, FormOutlineField } from "components";
import { GoogleAuthToggle } from "components/GoogleAuthModal";
import { StaffApiStateContext, StaffStateContext } from "context";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { userInfoSelector } from "selectors";

const StaffPortalInfo = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const userInfo = useSelector(userInfoSelector);
  const { isStaffEdit } = useContext(StaffStateContext);
  const { staffData } = useContext(StaffApiStateContext);
  const { control, handleSubmit, getValues, setValue, reset, trigger } = useForm({
    defaultValues: {
      isToGivePortalAccess: true,
    },
  });

  const ownUser = useMemo(() => staffData?.id === userInfo?.id, [userInfo?.id, staffData?.id]);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (isStaffEdit && staffData?.id) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = staffData?.[field] ?? "";
        setValue(field, fieldValue);
      }
      submitAction(getValues(), null, false);
    }
  }, [isStaffEdit, staffData]);

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const checkBoxBtn = (name, index) => {
    setValue(name, index);
  };

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12}>
          <FormCheckBox
            control={control}
            checkBoxBtn={checkBoxBtn}
            name="isToGivePortalAccess"
            MuiFieldProps={{ label: "Portal Access" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="password"
            control={control}
            MuiFieldProps={{ isPassword: true, label: "Password", type: "password", autoComplete: "new-password" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="h6"> Two Factor Authentication</Typography>
          {ownUser ? (
            <GoogleAuthToggle enable2FA={staffData?.google_2f_enabled} />
          ) : (
            <Typography className={staffData?.google_2f_enable ? "greenText" : "redText"}>Disabled</Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
});

export default React.memo(StaffPortalInfo);
