import MetaTags from "react-meta-tags";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container, Alert } from "reactstrap";
import LoggedOutNavbar from "../../components/loggedOutNavbar";
import Lottie from "../../components/lottie";
import Spinner from "../../components/spinner";
import ValidAddressModal from "components/validAddressModal";
import { ClinicFormInfo, NavigationSteps } from "components/AddClinicComponents";
import BottomNavigationSteps from "components/AddClinicComponents/BottomNavigationSteps";
import useAddClinicHook from "screens/common/Clinic/AddClinicHook/useAddClinicHook";
const RegisterClinic = props => {
  //
  const {
    standardClinicAddress,
    onlyOneUser,
    setOnlyOneUser,
    clinicAddress,
    setClinicAddress,
    fedexAddress,
    showValidateModal,
    signatureModal,
    setSignatureModal,
    signatureImageUrl,
    isVerified,
    step,
    setStep,
    showLoading,
    showError,
    errorMessage,
    upload,
    onChange,
    onClickNotValidate,
    onClickValidate,
    onClickSave,
    closeSignatureModal,
    handleValidSubmit,
    closeModal,
    complete,
    billingSignModal,
    setBillingSignModal,
    billingRepSignature,
    setDeaAddressInfo,
    deaAddressInfo,
    companyInfo,
    setCompanyInfo,
    vpiInfo,
    setVpiInfo,
    setBaaFormPDFUrl,
  } = useAddClinicHook(props);

  useEffect(() => {}, []);

  const [agreementText, setAgreementText] = useState("");

  // State for company info

  return (
    <div>
      <MetaTags>
        <title>Register Clinic | VPI Compounding</title>
      </MetaTags>
      <LoggedOutNavbar />
      {showLoading ? <Spinner /> : null}
      <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
        <Container>
          <ValidAddressModal
            showValidAddressModal={showValidateModal}
            fedexShippingAddress={fedexAddress}
            clinicAddress={standardClinicAddress}
            onClickNotValidate={onClickNotValidate}
            onClickValidate={onClickValidate}
            closeModal={closeModal}
          />
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={8}>
              <Card className="overflow-hidden">
                {complete ? (
                  <div className="m-5 p-5 text-center">
                    <Lottie name="success-check" />
                    <p className="my-5">Thank you for registering a new clinic!</p>
                    <p>
                      Your request has been submitted for an approval. You will be contacted when your clinic gets
                      approved.
                    </p>
                  </div>
                ) : (
                  <>
                    <div className="p-4 text-center">
                      <h3 className="text-primary m-4 pb-5 text-dark">Register Clinic</h3>
                      <NavigationSteps step={step} />
                    </div>
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <form id="registerForm" className="form-horizontal px-4" onSubmit={e => handleValidSubmit(e)}>
                          {showError ? (
                            <Alert color="danger" className="text-center">
                              {errorMessage}
                            </Alert>
                          ) : null}
                          <ClinicFormInfo
                            billingSignModal={billingSignModal}
                            setBillingSignModal={setBillingSignModal}
                            step={step}
                            onChange={onChange}
                            clinicAddress={clinicAddress}
                            onlyOneUser={onlyOneUser}
                            upload={upload}
                            setClinicAddress={setClinicAddress}
                            setOnlyOneUser={setOnlyOneUser}
                            signatureImageUrl={signatureImageUrl}
                            signatureModal={signatureModal}
                            closeSignatureModal={closeSignatureModal}
                            onClickSave={onClickSave}
                            setSignatureModal={setSignatureModal}
                            billingRepSignature={billingRepSignature}
                            deaAddressInfo={deaAddressInfo}
                            setDeaAddressInfo={setDeaAddressInfo}
                            vpiInfo={vpiInfo}
                            setVpiInfo={setVpiInfo}
                            companyInfo={companyInfo}
                            setCompanyInfo={setCompanyInfo}
                            vpiInfoRequired={false}
                            companyInfoRequired={true}
                            adminSide={false}
                            setBaaFormPDFUrl={setBaaFormPDFUrl}
                          />
                          <BottomNavigationSteps
                            actionText={"Register"}
                            step={step}
                            setStep={setStep}
                            isVerified={isVerified}
                          />
                        </form>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RegisterClinic;
