import { configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import {
  AdminApi,
  AuthApi,
  ClinicApi,
  DashboardApi,
  FedexApi,
  GoogleAuthApi,
  InvoiceApi,
  PatientApi,
  StaffApi,
  UploadsApi,
  ClinicLocationApi,
} from "../rtkApi";
import REDUX_PERSIST from "./ReduxPersist";
import RootReducer from "./RootReducer";

function CreateStore() {
  const middleware = [
    AuthApi.middleware,
    DashboardApi.middleware,
    ClinicApi.middleware,
    AdminApi.middleware,
    InvoiceApi.middleware,
    PatientApi.middleware,
    StaffApi.middleware,
    UploadsApi.middleware,
    FedexApi.middleware,
    GoogleAuthApi.middleware,
    ClinicLocationApi.middleware,
  ];

  const persistedReducer = persistReducer(REDUX_PERSIST.storeConfig, RootReducer);

  const reduxStore = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middleware),
    enhancers: {},
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: {},
  });

  const persistor = persistStore(reduxStore);

  return { reduxStore, persistor };
}
export default CreateStore;
