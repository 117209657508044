import EditNoteIcon from "@mui/icons-material/EditNote";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Button,
  Box,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { InvoiceDetailModal, ListingSearch, MuiTableCollapse, OrderViewModal, PrescriptionNoteModal } from "components";
import { DialogContext } from "context";
import React, { useCallback, useContext, useMemo } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import {
  useLazyAdminArchivedPrescriptionQuery,
  useLazyExportArchivedPrescriptionQuery,
  useReplacementOrderMutation,
  useCancelOrderMutation
} from "rtk";
import { ADMIN_ARCHIVE_COLUMNS, LISTING_NAME, PRESCRIPTION_FILTER_OPTIONS, ROWS_LIMIT, columnHelper } from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import { useAdminPrescriptionListing } from "../Hook";
import AlertMessage from "../components/alert_message";
import Cookies from "universal-cookie";
function AdminArchivedPrescription() {
  const {
    toDate,
    fromDate,
    updateFilterValue,
    rowLimit,
    invoiceId,
    tablePage,
    showLoading,
    totalRecords,
    errorMessage,
    openNoteModal,
    searchKeyword,
    selectedFilter,
    disableFilter,
    prescriptions,
    filterRxStatus,
    prescriptionId,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setCustomError,
    handleChangePage,
    toggleNoteModal,
    updateFilterType,
    toggleDetailModal,
    toggleInvoiceModal,
    updateFilterRxStatus,
    exportArchivedPrescription,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyAdminArchivedPrescriptionQuery,
  });
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [replaceOrderMutate, { error: replaceOrderError, isLoading: replaceOrderLoading, data: replaceOrderData }] =
    useReplacementOrderMutation();
  const [cancelOrderMutate, { error: cancelOrderError, isLoading: cancelOrderLoading, data: cancelOrderData }] =
    useCancelOrderMutation();
  const [fetchReport, { error: reportError, isFetching: reportFetching }] = useLazyExportArchivedPrescriptionQuery();

  const showSpinner = useMemo(
    () => showLoading || reportFetching || replaceOrderLoading || cancelOrderLoading,
    [showLoading, reportFetching, replaceOrderLoading, cancelOrderLoading],
  );
  const successMessage = useMemo(() => replaceOrderData?.message || cancelOrderData?.message || "", [replaceOrderData?.message, cancelOrderData?.message]);
  const combinedErrorMessage = useMemo(
    () =>
      errorMessage || reportError?.data?.message || replaceOrderError?.data?.message || cancelOrderError?.data?.message || "",
    [errorMessage, replaceOrderError?.data?.message, cancelOrderError?.data?.message, reportError?.data?.message],
  );

  // const exportPrescriptionList = useCallback(
  //   (e, isMasterExport = false) => {
  //     e.preventDefault();
  //     fetchReport({ isMasterExport });
  //   },
  //   [fetchReport]
  // );
  // console.log("from date ad:",fromDate);
  // console.log("to date ad:",toDate);
  const replacementOrder = useCallback(
    (prescriptionId = "") =>
      () => {
        if (prescriptionId) {
          replaceOrderMutate({ prescriptionId });
          closeDialog();
        }
      },
    [closeDialog, replaceOrderMutate],
  );
  const cancelOrder = useCallback(
    (prescriptionId = "") =>
      () => {
        if (prescriptionId) {
          const userId = new Cookies().get("user_id");
          cancelOrderMutate({ userId, prescriptionId });
          closeDialog();
        }
      },
    [closeDialog, cancelOrderMutate],
  );

  const ADMIN_PRESCRIPTION_ACTION = useMemo(
    () =>
      columnHelper.accessor("id", {
        header: "Action",
        headerStyle: { minWidth: "140px", width: "150px", textAlign: "center", paddingLeft: 0, paddingRight: 0 },
        cell: props => {
          const prescriptionStatus = props.row.original.prescriptionStatus;
          const isReadyToPack = prescriptionStatus !== "Cancelled";
          // console.log("prescription status :", prescriptionStatus, isReadyToPack);
          return (
            <Box component={"span"}>
            <Tooltip title="Re Create">
              <IconButton
                onClick={() => {
                  showDialog({
                    question: "Are you sure you want to recreate a replacement order?",
                    actionText: "Yes",
                    cancelText: "No",
                    onClickYes: replacementOrder(props.getValue()),
                    onClickNo: closeDialog,
                    closeModal: closeDialog,
                  });
                }}
                style={{ width: 30, height: 30 }}>
                <RestorePageIcon color="warning" style={{ fontSize: "28px" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                onClick={() => {
                  showDialog({
                    question: "Are you sure you want to cancel prescription order?",
                    actionText: "Yes",
                    cancelText: "No",
                    onClickYes: cancelOrder(props.getValue()),
                    onClickNo: closeDialog,
                    closeModal: closeDialog,
                  });
                }}
                style={{ width: 30, height: 30 }}
                disabled={!isReadyToPack}>
                {isReadyToPack ? (
                  <CancelOutlinedIcon style={{ fontSize: "28px", color: "red" }} />
                ) : (
                  <CancelOutlinedIcon style={{ fontSize: "28px", color: "grey" }} />
                )}
              </IconButton>
            </Tooltip>
          </Box>
          );
        },
      }),
    [closeDialog, replacementOrder, showDialog],
  );

  const ADMIN_START_COLUMN = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Date",
        headerStyle: { minWidth: "105px", width: "105px", textAlign: "center" },
      }),
      columnHelper.accessor("rxOrder", {
        header: "Rx order",
        headerStyle: { minWidth: "50px", textAlign: "center", paddingLeft: 0, paddingRight: 0 },
        cell: props => (
          <Tooltip title="View">
            <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
              <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
            </IconButton>
          </Tooltip>
        ),
      }),
      columnHelper.accessor("invoiceNumber", {
        header: "Invoice #",
        headerStyle: { minWidth: "50px", paddingRight: 0 },
        cell: props => (
          <ButtonBase
            onClick={() => toggleInvoiceModal(props.row.original.id)}
            style={{ textAlign: "left" }}
            className="medText">
            {props.getValue()}
          </ButtonBase>
        ),
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        headerStyle: { width: 150, paddingRight: "8px", paddingLeft: "8px" },
        cell: props => (
          <IconButton
            onClick={() =>
              toggleNoteModal(
                props.row.original.id,
                props.row.original.message,
                props.row.original.createdByName,
                props.row.original.created,
              )
            }
            style={{ width: 40, height: 40 }}>
            <EditNoteIcon style={{ fontSize: "30" }} className="medText" />
          </IconButton>
        ),
      }),
    ],
    [setCustomError, toggleDetailModal, toggleInvoiceModal],
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [...ADMIN_START_COLUMN, ...ADMIN_ARCHIVE_COLUMNS, ADMIN_PRESCRIPTION_ACTION],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {showSpinner && <Spinner />}
      <Row className="my-3 px-3">
        <h5>Filter Prescriptions</h5>
        <Col xs={12} sm={12} lg={3} md={3}>
          <ListingSearch
            searchKeyword={searchKeyword}
            selectedFilter={selectedFilter}
            applySearch={applyFilter}
            clearFilter={clearFilter}
            updateKeyword={updateKeyword}
            updateFilterType={updateFilterType}
            filterOptions={PRESCRIPTION_FILTER_OPTIONS}
          />
        </Col>
        <Col xs={12} sm={12} lg={7} md={7} style={{ marginTop: "-5px" }}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div style={{ flexDirection: "column", marginTop: "3px", marginLeft: "-15px", marginRight: "7px" }}>
              <p className="m-0">From Date</p>
              <div className="mb-3 row">
                <div>
                  <input
                    value={fromDate}
                    className="form-control"
                    type="date"
                    onChange={e => {
                      updateFilterValue("fromDate", e.target.value);
                    }}
                  />
                </div>
              </div>{" "}
            </div>{" "}
            <div style={{ flexDirection: "column", marginRight: "10px", marginTop: "3px" }}>
              <p className="m-0">To Date</p>
              <div className="mb-3 row">
                <div>
                  <input
                    value={toDate}
                    className="form-control"
                    type="date"
                    onChange={e => {
                      updateFilterValue("toDate", e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>{" "}
            <div className="mt-4 row">
              <span>
                <RXStatusDD
                  loc={"archive"}
                  filterRX={true}
                  clearStatus={disableFilter}
                  updatedFilterRxStatusValue={updateFilterRxStatus}
                />
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginLeft: "10px", marginTop: "5px" }}>
              <button disabled={disableFilter} className="yellowButton" onClick={applyFilter}>
                Filter
              </button>
              {!disableFilter && (
                <i
                  disabled={disableFilter}
                  className="fas fa-times redText"
                  style={{ fontSize: 24, marginLeft: 18 }}
                  onClick={clearFilter}
                />
              )}
            </div>
          </div>
        </Col>
        {/* <Col sm={12} md={3} lg={2} className="mt-1"> */}
        {/* <button disabled={disableFilter} className="yellowButton" onClick={applyFilter}>
            Filter
          </button>
          {!disableFilter && (
            <i
              disabled={disableFilter}
              className="fas fa-times redText"
              style={{ fontSize: 24, marginLeft: 18 }}
              onClick={clearFilter}
            />
          )} */}
        {/* </Col> */}
        <Col className="text-end " lg={2} style={{ marginTop: "13px" }}>
          <DropdownButton
            as={ButtonGroup}
            title="Export CSV"
            id="export-dropdown"
            style={{ marginLeft: "30px", minWidth: "10rem" }}>
            <Dropdown.Item
              eventKey="1"
              onClick={e => {
                e.preventDefault();
                exportArchivedPrescription(false);
              }}>
              Normal
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={e => {
                e.preventDefault();
                exportArchivedPrescription(true);
              }}>
              Master
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      {successMessage ? <AlertMessage msg={successMessage} /> : <AlertMessage msg={combinedErrorMessage} isError={true} />}
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                <TableCell />
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 100 }}>
                      <Typography variant="tableHeader" noWrap>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => {
              return (
                <MuiTableCollapse
                  key={row.id}
                  row={row}
                  pageName={LISTING_NAME.ADMIN_ARCHIVED_RX}
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                  readOnly={true}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />

      <OrderViewModal prescriptionId={prescriptionId} handleClose={toggleDetailModal} />
      <InvoiceDetailModal invoiceId={invoiceId} handleClose={toggleInvoiceModal} />
      <PrescriptionNoteModal
        modalData={openNoteModal}
        pageName={LISTING_NAME.ADMIN_RX_WORKFLOW}
        handleClose={toggleNoteModal}
      />
    </>
  );
}

export default React.memo(AdminArchivedPrescription);
