import { Col, Row } from "reactstrap";
import ActionModal from "../actionModal";
import Spinner from "../spinner";
import { EXPORTS, NOTES, PAY_AMOUNT, TOTAL_BALANCE } from "./PaymentGridConstants";

/*Hook*/
import usePaymentGrid from "./usePaymentGrid";

/*Style CSS*/
import { Typography } from "@mui/material";
import "./index.scss";

const PaymentGrid = props => {
  const {
    showPayNow,
    hasSelectedRows,
    selectedInvoicesNames,
    //
    showSpinner,
    paymentType,
    setPaymentType,
    instructions,
    setInstructions,
    error,
    payNow,
    closeModal,
    confirmModal,
    setConfirmModal,
    setShowSpinner,
    clinicSide,
    paymentButton,
    oldInvoiceDue,
    getBalanceDue,
    balanceDue,
    setPaymentButton,
    viewPaymentHistory,
    viewPaidInvoices,
    viewCurrentStatement,
    showError,
    showLoading,
    errorMessage,
  } = usePaymentGrid(props);

  return (
    <div>
      <Row>
        <Col sm={12} md={3} lg={3}>
          <div class="payment-grid-box total-balance-box">
            <p className="invoice-balance-text">{TOTAL_BALANCE.title}</p>
            <div class="pay-full-box text-center">
              <Row>
                <Col md={9} sm={8} lg={3}>
                  <p className="mt-2">{"$" + balanceDue}</p>
                </Col>
                <Col md={3} sm={4} lg={9}>
                  {" "}
                  <button
                    class="btn btn-primary pay-balance-btn"
                    disabled={balanceDue === 0 || balanceDue === "0" ? true : false}
                    onClick={() => {
                      props.setTotalBalanceDue(balanceDue);
                      setPaymentType("full");
                      if (clinicSide) {
                        props.setPayFullBalance(true);
                        props.setConfirmCreditModal(true);
                        setInstructions(NOTES.PAY_FULL_CLINIC);
                        props.setPaymentInstructions(NOTES.PAY_FULL_CLINIC);
                      } else {
                        setConfirmModal(true);
                        setInstructions(NOTES.PAY_FULL_ADMIN);
                      }
                    }}>
                    {TOTAL_BALANCE.buttonText}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <UnPaidInvoicesWidget oldInvoiceDue={oldInvoiceDue}  NOTES={NOTES} sm={12} md={3} lg={3}  />



        <Col sm={12} md={3} lg={3}>
          <div class="payment-grid-box pay-invoice-box blueText">
            <Typography noWrap className="blueText mb-2" style={{ fontSize: "10px" }}>
              {hasSelectedRows ? selectedInvoicesNames?.join(" | ") : PAY_AMOUNT?.noSelection}
            </Typography>
            <Row>
              <Col md={4} sm={9} lg={4}>
                <p className="mt-2"> {"$" + props.selectedTotalAmount}</p>
              </Col>
              <Col md={8} sm={3} lg={8}>
                {" "}
                <button
                  className="blueButton"
                  disabled={!(hasSelectedRows && showPayNow)}
                  onClick={() => {
                    setPaymentType("selected");
                    props.setConfirmCreditModal(true);
                    setInstructions(NOTES.PAY_AMOUNT_ADMIN);
                    // if (clinicSide) {
                    //   setPaymentType("selected");
                    //   props.setConfirmCreditModal(true);
                    //   setInstructions(NOTES.PAY_AMOUNT_ADMIN);
                    // } else {
                    //   setPaymentType("selected");
                    //   setConfirmModal(true);
                    //   setInstructions(NOTES.PAY_AMOUNT_ADMIN);
                    // }
                    //setConfirmModal(true);
                  }}>
                  {PAY_AMOUNT.buttonText}
                </button>
              </Col>
            </Row>
          </div>
        </Col>

        <Col sm={12} md={3} lg={3}>
          <div class="payment-grid-box export-box">
               <ul style={{fontSize:'10px'}}>
               <p className="m-0 p-0" style={{fontSize:'14px'}}>Reports</p>
      
              <li>
                <u className="blueText pointer" onClick={viewPaymentHistory}>
                  {EXPORTS.paymentHistoryText}
                </u>
              </li>
              <li>
                <u className="blueText pointer" onClick={viewPaidInvoices}>
                  {EXPORTS.paidInvoicesText}
                </u>
              </li>
              <li>
                <u className="blueText pointer" onClick={viewCurrentStatement}>
                  {EXPORTS.currentStatementText}
                </u>
              </li>
            </ul>
            {error ? (
              <center>
                <p style={{ color: "red" }}>{error}</p>
              </center>
            ) : null}
          </div>
        </Col>
        {showSpinner ? <Spinner /> : null}
      </Row>
      <ActionModal
        showModal={confirmModal}
        closeModal={closeModal}
        onClickNo={closeModal}
        actionText={"Yes"}
        onClickYes={payNow}
        question={"Are you sure?"}
        instructions={instructions}
      />
    </div>
  );
};
export default PaymentGrid;

export const UnPaidInvoicesWidget = ({sm=12,md=3,lg=3,oldInvoiceDue,NOTES}) => {
  return (
    <Col sm={sm} md={md} lg={lg}>
      <div class="payment-grid-box invoice-due-box text-center">
        <h6>NUMBER OF UNPAID INVOICE(S)</h6>
        <h1>
          <strong>{oldInvoiceDue.unPaidInvoices}</strong>
        </h1>
        <p className="redText" style={{ fontSize: "10px" }}>
          {NOTES.NET_30_AUTO}
        </p>
        {/* <h6>{oldInvoiceDue?.invoiceNumber ? "Oldest Outstanding Invoice [" + oldInvoiceDue?.invoiceNumber + "] due in " + oldInvoiceDue?.days + " day(s)" : INVOICE_DUE.dummyText}</h6> */}
      </div>
    </Col>
  );
};
