import { createContext } from "react";

const PatientApiStateContext = createContext({
  patientData: null,
  clinicFetching: false,
  physicianFetching: false,
  physicianData: [],
  clinicLocationOption: [],
});

const PatientApiActionContext = createContext({
  fetchPhysician: () => null,
  getEditApiParam: () => null,
  clinicAddressRefetch: () => null,
  findShippingMethod: () => null,
  getShippingMethodOption: () => null,
  validateShippingAddress: () => null,
});

const PatientStateContext = createContext({
  profilePic: "",
  mutateError: "",
  showSpinner: false,
  isPatientEdit: false,
  imageUploading: false,
  mutateLoading: false,
  patientInsertSuccessfully: false,
});
const PatientActionContext = createContext({
  profileImageUploading: () => null,
  removeProfileImage: () => null,
});

const PatientFormSateContext = createContext({
  clinicLocationId: "",
  patientDefaultAddress: "",
  formRef: { current: [] },
  activeStep: 0,
  completedStep: { 0: true },
});

const PatientFormActionContext = createContext({
  goBack: () => null,
  goNext: () => null,
  formData: () => null,
  progressBarAction: () => null,
});

export {
  PatientActionContext,
  PatientApiActionContext,
  PatientApiStateContext,
  PatientStateContext,
  PatientFormSateContext,
  PatientFormActionContext,
};
