import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "reactstrap";

const AlertMessage = props => {
  const { msg = "", isError = false, onClose } = props;
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (msg) {
      setSuccess(Boolean(msg));
    }
  }, [msg]);

  const toggleAlert = useCallback(() => {
    setSuccess(false);
    onClose?.();
  }, []);

  if (!success) {
    return null;
  }

  return (
    <Alert color={isError ? "danger" : "success"} className="text-center" isOpen={success} toggle={toggleAlert}>
      {msg}
    </Alert>
  );
};
export default AlertMessage;
