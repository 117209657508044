import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Button, Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import { keys } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { PatientSummaryNestedTable } from "../MuiNestedTable";

function PatientSummaryTableCollapse({ row, pageName = "", allowRefill = false, refillAction, setCustomError }) {
  const [open, setOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const selectedProducts = useMemo(() => keys(rowSelection), [rowSelection]);

  const handleRefillPrescription = useCallback(
    e => {
      e.preventDefault();
      refillAction?.(selectedProducts, row.original);
      setRowSelection({});
    },
    [refillAction, row.original, selectedProducts],
  );

  const leftIcon = useMemo(() => {
    return (
      <TableCell style={{ paddingRight: 0, paddingLeft: 0 }}>
        <IconButton onClick={() => setOpen(!open)} style={{ width: 30, height: 30 }}>
          {open ? (
            <KeyboardArrowDownIcon style={{ width: 20, height: 20 }} />
          ) : (
            <KeyboardArrowRightIcon style={{ width: 20, height: 20 }} />
          )}
        </IconButton>
      </TableCell>
    );
  }, [open]);

  const collapseView = useMemo(() => {
    return (
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottom: open ? undefined : "none" }}
          colSpan={row.getVisibleCells().length + 1} // 1 cell for showing arrow icon
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {open && (
              <PatientSummaryNestedTable
                pageName={pageName}
                allowRefill={allowRefill}
                originalRow={row.original}
                setCustomError={setCustomError}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    );
  }, [allowRefill, open, pageName, row, rowSelection, setCustomError]);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {leftIcon}
        {row?.getVisibleCells()?.map(cell => {
          if (allowRefill && cell.column.columnDef?.id === "actions") {
            const disableCheckbox = selectedProducts?.length > 0;
            return (
              <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  disabled={!disableCheckbox}
                  onClick={handleRefillPrescription}>
                  Refill
                </Button>
              </TableCell>
            );
          }
          return (
            <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
      {collapseView}
    </React.Fragment>
  );
}

export default React.memo(PatientSummaryTableCollapse);
