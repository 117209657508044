import React from 'react';
import MedicationBar from './medicationBar';


const MedicationHistory = ({
  medications = [],
  datesList = [],
  collapsibleRow = -1,
  containerWidth = "100%",
}) => {
  return (
    <div
      className="medications-history-container"
      style={{ width: containerWidth }}
    >
      {medications.map((cMedication, index) => (
        <MedicationHistoryItem
          history={cMedication.history}
          datesList={datesList}
          isActive={index === collapsibleRow}
        />
      ))}
    </div>
  );
};

export default MedicationHistory

const MedicationHistoryItem = ({
  history = [],
  datesList = [],
  isActive = false,
}) => {
  return (
    <div className={`medication-history-item ${isActive ? "is-active" : ""}`}>
      {history.map((cHistory) => (
        <MedicationBar historyItem={cHistory} datesList={datesList} />
      ))}
    </div>
  );
};


