import { useEffect, useMemo, useRef, useState } from "react";
import { uniq, compact } from "lodash";
import moment from "moment";
import Cookies from "universal-cookie";

import axiosConfig, { errorResponse } from "utils/axiosConfig";

const useGanttChart = () => {
  const [medications, setMedications] = useState([]);
  const [collapsibleRow, setCollapseRow] = useState(-1);
  const [containerWidth, setContainerWidth] = useState("100%");
  const [fetching, setFetching] = useState(false);
  const hasMedications = !!medications.length && !fetching;
  const rightPanelRef = useRef();
  const hasScrolledToCurrentMonth = useRef(false);
  const chartDatesCollection = medications.flatMap((cMedication) => {
    return cMedication?.history;
  });
  const chartStartDatesCollection = chartDatesCollection.flatMap(
    (cHistory) => cHistory?.startDate
  );
  const chartEndDatesCollection = chartDatesCollection.flatMap(
    (cHistory) => cHistory?.endDate
  );
  const medicationStartDates = useMemo(
    () => sortDates(compact(chartStartDatesCollection)),
    [chartStartDatesCollection]
  );
  const medicationEndDates = useMemo(
    () => sortDates(compact(chartEndDatesCollection), true),
    [chartEndDatesCollection]
  );
  const chartStartDate = moment(medicationStartDates).subtract(8, "days");
  const chartEndDate = moment(medicationEndDates).add(8, "days");
  const datesList = useMemo(
    () => enumerateDatesRange(chartStartDate.format(), chartEndDate.format()),
    [chartEndDate, chartStartDate]
  );

  const scrollRightPanel = (x = 0) => {
    rightPanelRef.current.scroll(x, 0);
  };

  useEffect(() => {
    if (!!rightPanelRef.current) {
      setContainerWidth(
        !!rightPanelRef?.current?.scrollWidth
          ? `${rightPanelRef?.current?.scrollWidth}px`
          : "100%"
      );
    }
    // update scroll width on update medications to auto-rerender
  }, [rightPanelRef.current, medications]);

  useEffect(() => {
    if (
      medications.length > 0 &&
      !hasScrolledToCurrentMonth.current &&
      moment(chartEndDate).startOf("day").isSameOrAfter(moment().startOf("day"))
    ) {
      const isCompleteMonth = moment(chartStartDate)
        .startOf("day")
        .isBefore(moment().startOf("month"));
      let difference = 0;
      if (isCompleteMonth) {
        difference = moment(chartStartDate).diff(
          moment().startOf("month"),
          "days"
        );
      }
      const left = Math.abs(difference) * 25;
      rightPanelRef.current?.scrollBy(left, 0);
      hasScrolledToCurrentMonth.current = true;
    }
  }, [medications, chartEndDate, chartStartDate]);

  useEffect(() => {
    setFetching(true);
    fetchMedications({
      fetchingHandler: setFetching,
      dataHandler: setMedications,
    });
  }, []);
  return {
    groupedDates: groupDatesByMonth(datesList),
    datesList,
    collapsibleRow,
    rightPanelRef,
    containerWidth,
    hasMedications,
    medications,
    chartStartDate: chartStartDate.toISOString(),
    scrollRightPanel,
    setCollapseRow,
  };
};
export default useGanttChart;

const sortDates = (dates = [], last = false) => {
  if (!dates.length) {
    return null;
  }
  const sortedDates = dates.sort((a, b) => {
    return moment(a).diff(b);
  });
  return last ? sortedDates[sortedDates.length - 1] : sortedDates[0];
};

const enumerateDatesRange = (startDate, endDate) => {
  const momentStartDate = moment(startDate).subtract(1, "day").startOf("day");
  const momentEndDate = moment(endDate).add(1, "day").startOf("day");
  let dates = [];
  while (momentStartDate.add(1, "days").diff(momentEndDate) < 0) {
    dates.push(momentStartDate.format());
  }
  return dates;
};

const groupDatesByMonth = (dates = []) => {
  const tempMonths = [];
  dates.forEach((cDate) => {
    const month = moment(cDate).format("MMM YYYY");
    tempMonths.push(month);
  });
  const uniqMonths = uniq(tempMonths);
  const groupedDates = [];
  uniqMonths.forEach((cMonth) => {
    groupedDates.push({
      id: cMonth,
      label: cMonth,
      dates: [],
    });
  });
  dates.forEach((cDate) => {
    const month = moment(cDate).format("MMM YYYY");
    const monthIndex = groupedDates.findIndex((cGDate) => cGDate.id === month);
    if (monthIndex > -1) {
      groupedDates[monthIndex].dates.push(cDate);
    }
  });
  return groupedDates;
};

const fetchMedications = ({ fetchingHandler, dataHandler }) => {
  fetchingHandler(true);
  axiosConfig
    .post(
      "patientPortal/getMedicationList",
      {
        clinicId: new Cookies().get("clinic_id"),
        userId: new Cookies().get("user_id"),
      },
      {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      }
    )
    .then(async (response) => {
      fetchingHandler(false);
      const { medications = [] } = response.data || {};
      dataHandler(medications);
    })
    .catch((error) => {
      fetchingHandler(false);

      errorResponse(error);
    });
};
