import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Container, Row, Alert } from "reactstrap";
import Spinner from "../spinner";

import familyPhotos from "../../assets/images/familyPhotos";
import VPICompoundingLogo from "../../assets/vpi-images/vpi logo for products.jpg";
import categoryPhotos from "../../assets/images/categoryPhotos";
import ProductVariantDD from "components/dropdowns/productVariantDD";

/*Hook*/
import useProductCatalogue from "./index.hook";

/*Style CSS*/
import "./index.scss";

const ProductCatalogue = (props) => {
  const {
    isInjectable,
    setIsInjectable,
    clinic,
    setClinic,
    complete,
    setComplete,
    showSpinner,
    setShowSpinner,
    error,
    setError,
    families,
    setFamilies,
    products,
    setProducts,
    categories,
    setCategories,
    isFamily,
    setIsFamily,
    isProduct,
    setIsProduct,
    isCategory,
    setIsCategory,
    selectedFamily,
    setSelectedFamily,
    noteAccessories,
    selectedCategory,
    setSelectedCategory,
    showProduct,
    setShowProduct,
    pageNo,
    setPageNo,
    sizePerPage,
    setSizePerPage,
    pagination,
    setPagination,
    alpha,
    setAlpha,
    keyword,
    setKeyword,
    catProducts,
    setCatProducts,
    subCat2Products,
    setSubCat2Products,
    subcategory2List,
    setSubcategory2List,
    selectedProductVariant,
    setSelectedProductVariant,
    activeSubCat2List,
    setActiveSubCat2List,

    resetActiveLinks,
    handlePageChange,
    renderCategory,
    onChangeVariant,
    renderProduct,
    alphabets,
  } = useProductCatalogue(props);

  const renderNow = () => {
    if (isFamily && !isProduct && !isCategory && families && !showProduct) {
      //setShowSpinner(false);

      let col = [];
      let obj = [
        <Row key="BreadCrump">
          <Col>
            <div style={{ fontSize: "12px" }}>
              <span
                className="darkText breadCrumb pointer"
                onClick={() => {
                  props.clearViewCategoryProducts();
                  setShowSpinner(true);
                  setIsFamily(true);
                  setIsCategory(false);
                  setIsProduct(false);
                  setShowSpinner(false);
                }}
              >
                {" "}
                Product Categories
              </span>
            </div>
          </Col>
          <Col></Col>
        </Row>,
      ];

      //setShowSpinner(false);
      families.forEach((fam, index) => {
        var photoCheck = familyPhotos.filter((obj) => obj.name?.toUpperCase() === fam.name?.toUpperCase())[0];
        var photoImg = familyPhotos.filter((obj) => obj.name?.toUpperCase() === fam.name?.toUpperCase())[0]?.photo;

        if (!photoCheck) {
          photoImg = VPICompoundingLogo;
        }

        col.push(
          <Col id={"fam" + index} md={3}>
            <div
              className="pointer"
              onClick={() => {
                setIsFamily(false);
                setIsCategory(true);
                setIsProduct(false);
                renderCategory(index);
              }}
            >
              <Card
                className="text-center"
                style={{
                  fontSize: "10px",
                  border: "1px solid #C1C1C1",
                  width: "110px",
                  height: "100px",
                  padding: "0px !important",
                }}
              >
                <center>
                  <img className="mt-1" src={photoImg} width="100" height="50"></img>
                </center>{" "}
                <div className="p-1 darkText">{fam.name?.toUpperCase()}</div>
              </Card>
            </div>
          </Col>
        );
        if (col.length === 4) {
          //console.log('Check', col.length);
          obj.push(
            <Row key={"col4" + index} className="mt-2">
              {col}
            </Row>
          );
          col = [];
        }
        if (index === families.length - 1) {
          obj.push(<Row key={"family" + index} >{col}</Row>);
        }
      });
      return obj;
    } else if (!isFamily && !isProduct && isCategory && categories && families && !showProduct) {
      let col = [];
      var obj = [];

      //setShowSpinner(false);
      col = [];
      obj = [
        <Row key="Medical">
          {noteAccessories && selectedFamily?.toUpperCase() === "MEDICAL ACCESSORIES" ? (
            <p className="yellowText">Please choose Medical Accessories</p>
          ) : null}
          <Col>
            <div style={{ fontSize: "12px" }}>
              <span
                className="darkText breadCrumb pointer"
                onClick={() => {
                  props.clearPrevSelectedFamStyle(
                    selectedFamily,
                    families.findIndex((x) => x.name === selectedFamily)
                  );
                  props.clearViewCategoryProducts();
                  setShowSpinner(true);
                  setIsFamily(true);
                  setIsCategory(false);
                  setIsProduct(false);
                  setShowSpinner(false);
                }}
              >
                {" "}
                Product Categories
              </span>
              &gt;
              <span
                className="yellowText breadCrumb pointer"
                onClick={() => {
                  props.makeSelectedFamStyle(
                    selectedFamily,
                    families.findIndex((x) => x.name === selectedFamily)
                  );
                  props.clearViewCategoryProducts();
                  setShowSpinner(true);
                  setIsFamily(false);
                  setIsCategory(true);
                  setIsProduct(false);
                  setShowSpinner(false);
                }}
              >
                {" "}
                {selectedFamily}
              </span>
            </div>
          </Col>
          <Col></Col>
        </Row>,
      ];

      categories.forEach((cat, index) => {
        col.push(
          <Col md={3} id={"category" + index}>
            <span
              className="pointer"
              onClick={() => {
                setIsFamily(false);
                setIsCategory(false);
                setIsProduct(true);
                setSelectedCategory(cat);
                renderProduct(selectedFamily, cat, "", "", 10, 1);
              }}
            >
              <Card
                className="text-center"
                style={{
                  fontSize: "10px",
                  border: "1px solid #C1C1C1",
                  width: "110px",
                  height: "130px",
                  padding: "0px !important",
                }}
              >
                <center>
                  <img
                    className="mt-1"
                    src={categoryPhotos[index] ? categoryPhotos[index] : VPICompoundingLogo}
                    width="100"
                    height="50"
                  ></img>
                </center>{" "}
                <div className="p-1 darkText">{cat}</div>
              </Card>
            </span>
          </Col>
        );
      });

      obj.push(
        <Row key={"categories"} className="mt-2">
          {col}
        </Row>
      );
      return obj;
    } else if ((!isFamily && isProduct && !isCategory && products) || showProduct) {
      let rows = [
        <Row key={"else"}>
          <Col md={3}>
            <div style={{ fontSize: "12px" }}>
              <span
                className="darkText breadCrumb pointer"
                onClick={() => {
                  if (selectedFamily) {
                    props.clearPrevSelectedFamStyle(
                      selectedFamily,
                      families.findIndex((x) => x.name === selectedFamily)
                    );
                  }
                  props.clearSelectedCategory();
                  props.clearViewCategoryProducts();
                  setShowSpinner(true);
                  setIsFamily(true);
                  setIsCategory(false);
                  setIsProduct(false);
                  setShowSpinner(false);
                }}
              >
                {" "}
                Product Categories
              </span>
              {selectedFamily ? ">" : null}
              <span
                className="darkText breadCrumb pointer"
                onClick={() => {
                  props.makeSelectedFamStyle(
                    selectedFamily,
                    families.findIndex((x) => x.name === selectedFamily)
                  );
                  props.clearSelectedCategory();
                  props.clearViewCategoryProducts();
                  setShowSpinner(true);
                  setIsFamily(false);
                  renderCategory(families.findIndex((x) => x.name === selectedFamily));
                  setIsCategory(true);
                  setIsProduct(false);
                  setShowSpinner(false);
                }}
              >
                {" "}
                {selectedFamily}
              </span>
              {selectedCategory ? ">" : null}
              <span
                className="yellowText breadCrumb pointer"
                onClick={() => {
                  setShowSpinner(true);
                  setIsFamily(false);
                  setIsCategory(false);
                  setIsProduct(true);
                  setShowSpinner(false);
                  setProducts(catProducts);
                  resetActiveLinks();
                }}
              >
                {" "}
                {selectedCategory}
              </span>
            </div>
          </Col>
          <Col>
            <div style={{ fontSize: "10px" }}>
              {alphabets.map((alphabet, i) => (
                <span
                  key={"alpha" + i}
                  className="darkText breadCrumb pointer"
                  onClick={() => {
                    setAlpha(alphabet);
                    setSelectedCategory("");
                    setSelectedFamily("");
                    renderProduct("", "", "", alphabet, 10, 1);
                  }}
                >
                  {alphabet}
                  {" | "}
                </span>
              ))}
            </div>
          </Col>
        </Row>,
        ,
      ];

      if (products) {
        if (showProduct) {
          setShowProduct(false);
        }
        rows.push(<p/>);
        subcategory2List.forEach((subcat2, i) => {
          rows.push(
            <span
              key={"sub" + i}
              style={{ width: "100px" }}
              className={
                activeSubCat2List.filter((item) => item.activeLink === true)[0]?.subCat2_item?.toUpperCase() ==
                subcat2?.toUpperCase()
                  ? "m-2 selectedButton"
                  : " m-2 productFilterButton "
              }
              onClick={() => {
                let updatedActiveList = [];
                let vals = subcategory2List.filter((item) => item !== subcat2);

                vals.forEach((element) => {
                  updatedActiveList.push({
                    subCat2_item: element,
                    activeLink: false,
                  });
                });
                updatedActiveList.push({
                  subCat2_item: subcat2,
                  activeLink: true,
                });

                setActiveSubCat2List(updatedActiveList);

                setProducts(catProducts.filter((pro) => pro.subCategory2_item === subcat2));
              }}
            >
              {subcat2}
            </span>
          );
        });

        products?.forEach((prod, index) => {
          prod?.commonNames.forEach((commonProduct, j) => {
            rows.push(
              <Row className="mt-2" id={"product" + index} >
                <Card className="text" style={{ border: "1px solid #C1C1C1", padding: "0px !important" }}>
                  <CardBody>
                    <Row>
                      <Col md={3} lg={3}>
                        <img
                          src="https://i.pinimg.com/564x/84/a8/7e/84a87eb13637e32d5fd67ca7fc2cf2b4.jpg"
                          width="100"
                          height="100"
                        ></img>
                      </Col>
                      <Col>
                        <Row className="text-left mt fs-5 ">
                          <Col>{commonProduct?.commonName}</Col>
                        </Row>
                        <Row className="text-left mt" style={{ fontSize: "8px" }}>
                          <Col md={12}>
                            {props.keyword === "" && alpha === "" ? selectedFamily + ">" : commonProduct.commonName + ">"}
                            {props.keyword === "" && alpha === "" ? selectedCategory : commonProduct.subCategory1}
                            {props.keyword === "" && alpha === ""
                              ? prod?.subCategory2_item
                                ? ">" + prod?.subCategory2_item
                                : null
                              : commonProduct.subCategory2
                              ? ">" + commonProduct.subCategory2
                              : ""}
                          </Col>
                        </Row>

                        <Row className="text-left mt-2">
                          <Col md={7}>
                            {" "}
                            {props.spinner ? (
                              <Spinner />
                            ) : (
                              <ProductVariantDD
                                selectedProductVariant={selectedProductVariant}
                                products={products}
                                commonName={commonProduct?.commonName}
                                setShowProduct={setShowProduct}
                                setSelectedProductVariant={onChangeVariant}
                                name="variant"
                                productVariants={commonProduct?.products}
                              />
                            )}
                          </Col>
                          <Col className="mt-1">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                props.addProductPopup(selectedProductVariant);
                                setSelectedProductVariant("s");
                              }}
                              className="addToCartButton pointer"
                            >
                              ADD TO CART
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Row>
            );
          });

          if (products.length - 1 === index) {
          }
        });
        return rows;
      }
    }
  };

  return <>{showSpinner ? <Spinner /> : renderNow()}</>;
};
export default ProductCatalogue;
