import moment from "moment";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  useAddStaffMutation,
  useEditProfileMutation,
  useEditStaffMutation,
  useLazyGetStaffQuery,
  useUploadFileMutation,
} from "rtk";
import { base64toFile } from "utils";
import { jwtTokensSelector, userInfoSelector, userRoleSelector } from "../../../selectors";

function useClinicStaff(props) {
  const history = useHistory();
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const authToken = useSelector(jwtTokensSelector);
  const [fetchStaff, { data: user, error: staffFetchError, isError: isStaffFetchError, isFetching: isStaffFetching }] =
    useLazyGetStaffQuery();
  const [uploadMutate, { error: uploadError, isError: isUploadError, isLoading: isUploadLoading }] =
    useUploadFileMutation();
  const [
    editStaffMutate,
    { error: updateStaffError, isError: isUpdateStaffError, isLoading: isUpdateStaffLoading, isSuccess: editComplete },
  ] = useEditStaffMutation();
  const [
    addStaffMutate,
    { error: addStaffError, isError: isAddStaffError, isLoading: isAddStaffLoading, isSuccess: addComplete },
  ] = useAddStaffMutation();
  const [
    editProfileMutate,
    {
      error: editProfileError,
      isError: isEditProfileError,
      isLoading: isEditLoading,
      isSuccess: isEditProfileComplete,
    },
  ] = useEditProfileMutation();

  //Defining Errors and Loading

  const clinicId = useMemo(
    () => userInfo?.clinicId || props?.match?.params?.clinicId,
    [userInfo.clinicId, props?.match?.params?.clinicId],
  );

  const editStaffUrl = useCallback(
    e => {
      e?.preventDefault();
      history.push({
        pathname: `/edit-staff/${props?.match?.params.id}`,
        state: { clinicId: props?.match?.params?.clinicId },
      });
    },
    [userInfo.clinicId, props?.match?.params?.clinicId],
  );

  //Defining Memos for Errors, Loading

  const complete = useMemo(
    () => editComplete || addComplete || isEditProfileComplete,
    [editComplete, addComplete, isEditProfileComplete],
  );
  //Defining Errors and Loading
  const errorMessage = useMemo(
    () =>
      addStaffError?.data?.message ||
      editProfileError?.data?.message ||
      staffFetchError?.data?.message ||
      uploadError?.data?.message ||
      updateStaffError?.data?.message,
    [addStaffError, editProfileError, staffFetchError, uploadError, updateStaffError],
  );
  const showError = useMemo(
    () => isAddStaffError || isEditProfileError || isStaffFetchError || isUploadError || isUpdateStaffError,
    [isAddStaffError, isEditProfileError, isStaffFetchError, isUploadError, isUpdateStaffError],
  );
  const showLoading = useMemo(
    () => isAddStaffLoading || isEditLoading || isStaffFetching || isUploadLoading || isUpdateStaffLoading,
    [isAddStaffLoading, isEditLoading, isStaffFetching, isUploadLoading, isUpdateStaffLoading],
  );

  const updateProfile = (obj = "", id = "", profileImageUrl = "", signatureImageUrl = "") => {
    let userObj = {
      staffId: id,
      staff: {
        firstName: obj.firstName,
        middleName: obj.middleName,
        lastName: obj.lastName,
        sex: obj.sex,
        dateOfBirth: moment(obj.dateOfBirth, "YYYY-MM-DD").format("MM/DD/YYYY"),
        email: obj.email,
        mobileNumber: obj.mobileNumber,
        phoneNumber: obj.phoneNumber,
        addressLine1: obj.addressLine1,
        addressLine2: obj.addressLine2,
        state: obj.state,
        city: obj.city,
        zipcode: obj.zipcode,
        country: obj.country,
        profileImageUrl: profileImageUrl,
        signatureImageUrl: signatureImageUrl ? signatureImageUrl : "",
      },
    };
    return userObj;
  };

  async function uploadFile(e = "", signPad = "") {
    var file = "";
    const formData = new FormData();
    if (signPad) {
      file = base64toFile(signPad);
      formData.append("file", file);
    } else {
      file = e.target.files[0];
      formData.append("file", file);
    }

    let res = await uploadMutate({
      headerInfo: {
        Authorization: "Bearer " + authToken,
        "Content-Type": "multipart/form-data;",
      },
      payload: formData,
    });

    if (res?.data) return res.data.accessUrl;
    else return null;
  }

  return {
    updateProfile,
    base64toFile,
    uploadFile,
    fetchStaff,
    editStaffMutate,
    addStaffMutate,
    user,
    complete,
    errorMessage,
    showError,
    showLoading,
    editProfileMutate,
    isStaffFetching,
    clinicId,
    userInfo,
    userRole,
    editStaffUrl,
  };
}

export default useClinicStaff;
