import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";

const RushOrderDD = (props) => {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue);
  const [rushOrders, setRushOrders] = useState([]);
  const RUSH_ORDER_INFO = {
    name: "24-hour Turn Around",
    percentOfOrder: 15,
    value: 70,
  };
  useEffect(() => {
    let values = [];
    values.push(
      <option
        selected={true}
        key={RUSH_ORDER_INFO.name}
        value={RUSH_ORDER_INFO.value + "/" + RUSH_ORDER_INFO.percentOfOrder}
      >
        {RUSH_ORDER_INFO.name}
      </option>
    );
    setRushOrders(values);
    props.setRushOrderMethod(RUSH_ORDER_INFO.name);
  }, [props.toggleRushButton]);

  return (
    <FormGroup className="dropdownContainer" key={defaultValue}>
      {/* <Input id={props.name} type="select" style={{ backgroundColor: "white" }} name={props.name} required={props.required} defaultValue={""} disabled={true}>
        {rushOrders}
      </Input> */}
      <p>
        <strong>Please Note:</strong> Selecting this fee moves this order to the top of the list for your clinic's
        orders. Expected turnaround time is 2-3 business days.{" "}
      </p>
    </FormGroup>
  );
};

export default RushOrderDD;
