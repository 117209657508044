import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

const DisplayModal = (props) => {
  /**
   * Handling the modal state
   */
  const toggle = () => {
    props.closeModal();
  };

  useEffect(() => {});

  return (
    <React.Fragment className='mt-4'>
      {props.showModal ? (
        <Modal isOpen={props.showModal} scrollable={true} size='xl' className='mt-4' centered>
          <ModalBody>
            <Row size='lg'>
              <Row className='mb-4'>
                <Col align='center' md='4'>
                  <h3>{props.namesdata[0]}</h3>
                </Col>
                <Col align='center' md='4'>
                  <h3>{props.namesdata[1]}</h3>
                </Col>
                <Col align='center' md='4'>
                  <h3>{props.namesdata[2]}</h3>
                </Col>
              </Row>

              {/* new method start */}
              <Row>
                <Col md='4'>
                  <h3>{props.displayModalData[0][0].title}</h3>
                  <p>{props.displayModalData[0][0].text}</p>
                </Col>
                <Col md='4'>
                  <h3>{props.displayModalData[1][0].title}</h3>
                  <p>{props.displayModalData[1][0].text}</p>
                </Col>
                <Col md='4'>
                  <h3>{props.displayModalData[2][0].title}</h3>
                  <p>{props.displayModalData[2][0].text}</p>
                </Col>
              </Row>

              {/* new method end */}
            </Row>

            <Row>
              <div align='center'>
                <Col>
                  <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                    Close
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default DisplayModal;
