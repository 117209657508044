/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { SignaturePad } from "components/SignaturePad";
import { DialogContext } from "context";
import { some } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AdminNavbar from "../../../components/AdminNavbar";
import ClinicNavbar from "../../../components/ClinicNavbar";
import Lottie from "../../../components/lottie";
import Spinner from "../../../components/spinner";
import {
  useAdminAcknowledgePrescriptionMutation,
  useChangeRxStatusMutation,
  useClinicUploadRxSignatureMutation,
  useLazyGetClinicReviewPrescriptionQuery,
  useLazyGetDefaultCreditCardQuery,
  useSaveNewPrescriptionMutation,
  useSubmitClinicPrescriptionMutation,
} from "../../../rtk";
import { userInfoSelector, userRoleSelector } from "../../../selectors";
import { LISTING_NAME, USER_ROLES, base64toFile } from "../../../utils";
import { USERS_EDIT_PRESCRIPTION } from "../../../utils/RolePermission";
import { dateFormatterMountainTime } from "../../../utils/constants";
import AlertMessage from "../../clinic/Prescriptions/components/alert_message";
import ReviewInfo from "./ReviewInfo";

function ReviewPrescription(props) {
  const acknowledgeRef = useRef();
  const history = useHistory();
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [signature, setSignature] = useState(null);
  const [signatureFileUrl, setSignatureFileUrl] = useState("");
  const [signatureModal, setSignatureModal] = useState(false);
  const [otpToken, setOtpToken] = useState("");
  const { state: { isReview = false, flow = "", side = "", isRefillPrescription = "" } = {} } = useLocation();
  const { id: prescriptionId = "", clinicId = "" } = useParams();
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const isClinic = userRole?.toLowerCase().includes("clinic");
  const reorder = window?.location?.pathname.includes("reorder");
  const [getDefaultCard, { isFetching: isCardFetching, error: cardError, data: cardData }] =
    useLazyGetDefaultCreditCardQuery();
  const [fetchList, { isFetching: reviewFetching, error: reviewError, data: prescriptionData }] =
    useLazyGetClinicReviewPrescriptionQuery();
  const [rxStatusMutate, { isLoading: rxLoading, error: rxError }] = useChangeRxStatusMutation();
  const [uploadMutate, { data: uploadedData, isLoading: isUploading, error: uploadError }] =
    useClinicUploadRxSignatureMutation();
  const [rxSubmitMutate, { isLoading: submitLoading, error: submitError, isSuccess: isSubmitSuccess }] =
    useSubmitClinicPrescriptionMutation();
  const [rxSaveMutate, { isLoading: saveLoading, error: saveError, isSuccess: isSaveSuccess }] =
    useSaveNewPrescriptionMutation();
  const [acknowledgeMutate, { isSuccess: acknowledgeSuccess, isLoading: acknowledgeLoading, error: acknowledgeError }] =
    useAdminAcknowledgePrescriptionMutation();
  const showClinicView = useMemo(() => clinicId || isClinic, [clinicId, isClinic]);
  const shippingCost = useMemo(() => prescriptionData?.shippingCost, [prescriptionData?.shippingCost]);
  const showLoading = useMemo(
    () => isCardFetching || reviewFetching || rxLoading || isUploading || submitLoading || acknowledgeLoading,
    [acknowledgeLoading, reviewFetching, isUploading, rxLoading, submitLoading, isCardFetching],
  );
  const errorMessage = useMemo(
    () =>
      reviewError?.data?.message ||
      rxError?.data?.message ||
      uploadError?.data?.message ||
      submitError?.data?.message ||
      acknowledgeError?.data?.message,
    cardError?.data.message,
    [
      acknowledgeError?.data?.message,
      reviewError?.data?.message,
      rxError?.data?.message,
      submitError?.data?.message,
      uploadError?.data?.message,
      cardError?.data.message,
    ],
  );
  const isAllowedEditingPrescription = useMemo(
    () => some(USERS_EDIT_PRESCRIPTION, role => role === userRole),
    [userRole],
  );
  useEffect(async () => {
    setSignatureFileUrl(prescriptionData?.signatureFileUrl);
  }, [prescriptionData?.signatureFileUrl]);

  useEffect(async () => {
    if (!reviewFetching) {
      fetchList(
        {
          isReview,
          prescriptionId,
        },
        false,
      );
      // getDefaultCard({ prescriptionId: prescriptionId }, true);
    }
  }, []);

  useEffect(() => {
    if (uploadedData?.accessUrl) {
      setSignatureFileUrl(uploadedData?.accessUrl);
    }
  }, [uploadedData?.accessUrl]);

  useEffect(() => {
    if (acknowledgeSuccess) {
      toggleConfirmationModal();
      history.push("/pending-prescriptions");
    }
  }, [acknowledgeSuccess]);

  const uploadSignature = useCallback(
    (e, person = "", signPad = "") => {
      if (e) e.preventDefault();
      let file = "";
      const formData = new FormData();
      if (signPad) {
        file = base64toFile(signPad);
        formData.append("file", file);
      } else {
        file = e.target.files[0];
        formData.append("file", file);
      }
      uploadMutate(formData);
    },
    [uploadMutate],
  );

  const handleChange = e => {
    setSignature(e.target.value);
  };

  const onClickSave = (person = "signatureImageUrl", sig) => {
    uploadSignature("", person, sig);
  };

  const closeSignatureModal = () => {
    setSignatureModal(false);
  };

  const submitRX = useCallback(
    e => {
      e.preventDefault();
      let prescriptionData1 = {
        ...prescriptionData,
        prescriptionId: prescriptionData?.id,
        otpToken: parseInt(otpToken),
        shippingInfo: {
          ...prescriptionData.shippingInfo,
          rushOrderCost: prescriptionData?.shippingInfo.rushOrderCost,
          shipTo:
            prescriptionData?.shippingInfo?.shipTo ?? (prescriptionData?.patientIds?.length > 1 ? "clinic" : "patient"),
        },
        signatureFileUrl: signatureFileUrl,
      };
      if (!userInfo?.google_2f_enabled || !showPIN) {
        delete prescriptionData1.otpToken;
      }
      if (isRefillPrescription) {
        prescriptionData1.isRefillRequest = true;
        prescriptionData1.refillFromPrescriptionId = isRefillPrescription;
      }
      rxSubmitMutate({
        isReview,
        prescription: prescriptionData1,
        flow: flow,
        side: side,
      });
    },
    [prescriptionData, isReview, rxSubmitMutate, signatureFileUrl, otpToken, isRefillPrescription],
  );

  const prescriptionAcknowledgeAction = useCallback(() => {
    acknowledgeMutate({ action: acknowledgeRef.current, prescriptionId, pageName: "AdminPendingPrescription" });
  }, [prescriptionId]);

  const toggleConfirmationModal = useCallback(
    (action = "") => {
      acknowledgeRef.current = action;
      if (action) {
        showDialog({
          question: `Are you sure you want to ${action?.toLowerCase()} this prescription?`,
          actionText: action,
          onClickNo: closeDialog,
          closeModal: toggleConfirmationModal,
          onClickYes: prescriptionAcknowledgeAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [prescriptionAcknowledgeAction],
  );

  const navBar = useMemo(() => {
    if (isClinic) {
      return <ClinicNavbar />;
    } else {
      return <AdminNavbar />;
    }
  }, [isClinic]);

  async function saveOrderRX(prescriptionData = "") {
    let res = await rxSaveMutate({ prescription: prescriptionData });
    if (res?.data) props.history.push(`/edit-reorder-prescription/${res?.data?.prescriptionId}`);
  }

  const editPrescriptionLink = useMemo(
    () =>
      isAllowedEditingPrescription ? (
        !isSubmitSuccess ? (
          <Link
            onClick={() => {
              if (reorder) {
                saveOrderRX(prescriptionData);
              }
            }}
            to={{
              pathname: reorder ? `` : `/edit-prescription/${prescriptionId}`,
              state: {
                shippingCost1: shippingCost,
                isReview: true,
                paramClinicId: clinicId,
                prescriptionStatus: prescriptionData?.prescriptionStatus,
                side: side,
                isRefillPrescription,
              },
            }}
            className="pinkButton">
            Edit
          </Link>
        ) : null
      ) : null,
    [
      isAllowedEditingPrescription,
      isReview,
      prescriptionId,
      shippingCost,
      isSubmitSuccess,
      clinicId,
      isRefillPrescription,
    ],
  );

  const prescriptionLoadingMessage = useMemo(() => {
    return (
      <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
        <p className="my-5">Fetching Prescription Data!</p>
      </div>
    );
  }, []);

  const prescriptionSubmittedMessage = useMemo(() => {
    let innerMessage = (
      <p className="mb-5">
        Your order for a new prescription has been placed. You will be notified once it gets approved!
      </p>
    );
    if (showClinicView) {
      innerMessage = (
        <>
          {prescriptionData?.rxPadProducts?.length > 0 && prescriptionData?.products?.length > 0 ? (
            <p className="mb">
              For RX Pad, A team member will be in contact to discuss pricing. Or you may reach us at 720-389-7597
            </p>
          ) : null}
          {prescriptionData?.rxPadProducts.length > 0 && prescriptionData?.products?.length > 0 ? (
            <p className="mb-5">
              Your order for a new prescription has been placed. You will be notified once it gets approved!
            </p>
          ) : null}
          {prescriptionData?.rxPadProducts?.length > 0 && prescriptionData?.products?.length < 1 ? (
            <p className="mb-5">
              A team member will be in contact to discuss pricing. Or you may reach us at 720-389-7597
            </p>
          ) : null}
          {prescriptionData?.rxPadProducts?.length < 1 && prescriptionData?.products?.length > 0 ? (
            <p className="mb-5">
              Your order for a new prescription has been placed. You will be notified once it gets approved!
            </p>
          ) : null}
        </>
      );
    }
    return (
      <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
        <Lottie name="success-check" />
        {innerMessage}
        <Link
          to={!isClinic ? "/pending-prescriptions" : { pathname: "/prescription-history", state: { activeTab: 1 } }}
          className="pinkButton">
          Pending Prescriptions
        </Link>
      </div>
    );
  }, [prescriptionData?.products?.length, prescriptionData?.rxPadProducts.length, showClinicView]);

  const showPIN = useMemo(() => {
    const condition = side === "incompleteRx" || !isClinic || side === "newRx" || side === "productSearch";
    const reorder = window?.location?.pathname.includes("reorder-prescription");

    if (condition || reorder) return true;
    else return false;
  }, [isClinic, side]);

  const reviewAction = useMemo(() => {
    if (showClinicView) {
      return (
        <>
          <div className="my-4 mx-1">
            <p className="bold medText">
              Signature<span className="requiredStar">*</span>
            </p>{" "}
            <br></br>
            <small>
              <i>(Provide signature using the signature pad.)</i>
            </small>{" "}
            <br></br>
            <div className="flexJCAC justify-content-start">
              {signatureFileUrl && (
                <>
                  <img alt="provider_signature" src={signatureFileUrl} width={180} />
                  <p className="mx-3 mb-0">OR </p>
                </>
              )}
              <span
                className="pointer form-control mt-1 mx-2"
                style={{ width: "250px" }}
                onClick={() => setSignatureModal(true)}>
                <i class="fas fa-pen mx-2"></i>Signature Pad
              </span>
              {signature && <img src={signature} alt="signature" />}
            </div>
            {userInfo?.google_2f_enabled && showPIN ? (
              <div>
                <p className="bold medText mt-3">
                  PIN Code<span className="requiredStar">*</span>
                </p>
                <input
                  type="text"
                  style={{ width: "250px" }}
                  className="form-control mt-1"
                  placeholder="Enter PIN"
                  value={otpToken}
                  onChange={e => {
                    setOtpToken(e.target.value);
                  }}></input>
              </div>
            ) : null}
          </div>
          <div className="w-100 text-center my-5">
            <button
              className="blueButton"
              onClick={submitRX}
              disabled={!(signatureFileUrl || prescriptionData?.signatureFileUrl)}>
              {side === LISTING_NAME.CLINIC_PENDING_APPROVAL ? "Resubmit " : "Submit "}Prescription
            </button>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <div className="my-1 mx-1">
            <p className="bold medText">Signature</p>
            <div className="flexJCAC justify-content-start">
              {signatureFileUrl && <img alt="provider_signature" src={signatureFileUrl} width={180} />}
            </div>
            <p>
              Signed by:{" "}
              {prescriptionData?.providerDetails?.firstName + " " + prescriptionData?.providerDetails?.lastName} on{" "}
              {dateFormatterMountainTime(prescriptionData?.prescriptionDate)}
            </p>
          </div>
          {userRole === USER_ROLES.ADMIN_SUPER_USER ||
          userRole === USER_ROLES.PHARMACIST ||
          userRole === USER_ROLES.RX_TECH ? (
            <div className="w-100 text-center my-2">
              <div>
                {" "}
                <button
                  style={{ width: "400px" }}
                  className="greenButton mt-2"
                  onClick={e => {
                    e.preventDefault();
                    toggleConfirmationModal("Approve");
                  }}
                  disabled={prescriptionData?.products?.reduce((sum, next) => sum && next.isOnHold, true)}>
                  Approve Prescription
                </button>
              </div>

              <div>
                <button
                  style={{ width: "400px" }}
                  className="redButton   mt-1"
                  onClick={e => {
                    e.preventDefault();
                    toggleConfirmationModal("Disapprove");
                  }}>
                  Disapprove Prescription
                </button>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }, [
    prescriptionData?.prescriptionDate,
    prescriptionData?.products,
    prescriptionData?.providerDetails?.firstName,
    prescriptionData?.providerDetails?.lastName,
    prescriptionData?.signatureFileUrl,
    showClinicView,
    isReview,
    signatureFileUrl,
    userRole,
    otpToken,
  ]);

  return (
    <React.Fragment>
      {navBar}
      <div className="page-content">
        <MetaTags>
          <title>Review Prescription | VPI Compounding</title>
        </MetaTags>
        <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
          Back
        </Button>
        {showLoading ? <Spinner /> : null}
        <Container fluid>
          <Row className="invoiceHeader">
            <h4 className="p-3 text-muted">REVIEW PRESCRIPTION</h4>
            <div className="d-flex">{editPrescriptionLink}</div>
          </Row>
          <SignaturePad
            actionText="Save"
            onClickSave={onClickSave}
            closeModal={closeSignatureModal}
            showModal={signatureModal}
          />
          <Row>
            <AlertMessage msg={errorMessage} isError={true} />
            <Col lg="12">
              <Card className="p-3">
                {reviewFetching ? (
                  prescriptionLoadingMessage
                ) : (
                  <CardBody className="font-size-16">
                    {isSubmitSuccess ? (
                      prescriptionSubmittedMessage
                    ) : (
                      <>
                        <ReviewInfo
                          onChangeStatus={rxStatusMutate}
                          creditCardInfo={prescriptionData}
                          showClinicView={showClinicView}
                        />
                        {reviewAction}
                      </>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              {!isSubmitSuccess ? (
                <Link
                  to="#"
                  onClick={e => {
                    e.preventDefault();
                    history.goBack();
                  }}
                  className="pinkButton">
                  Back
                </Link>
              ) : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(ReviewPrescription);
