import { IconButton, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { AddNewAddress } from "components/AddNewAddress";
import ClinicAddressDD from "components/dropdowns/clinicAddressDD";
import PatientAddressDD from "components/dropdowns/patientAddressDD";
import React, { useCallback, useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { VPI_ADDRESS } from "utils/StatusConstant";

function CustomShippingAddress(props) {
  const {
    isPickup = false,
    defaultShipToClinic = false,
    isBulkOrder = false,
    setShippingAddress,
    shippingAddress = "",
    sameAddress,
    oldShippingAddress,
    setSameAddressState,
  } = props;

  const [shipToClinic, setShipToClinic] = useState(() => defaultShipToClinic);
  const [toggleShipTo, setToggleShipTo] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [addressOneLine, setAddressOneLine] = useState("");
  const [addressChanged, setAddressChanged] = useState(false);

  const setNewAddress = (e) => {
    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("changeAddressForm"), { hash: true });
    setAddressChanged(true);
    setShippingAddress({
      addressLine1: obj?.addressLine1,
      addressLine2: obj?.addressLine2,
      city: obj?.city,
      state: obj?.state,
      zipcode: obj?.zipcode,
    });
    setSameAddressState(true);
    setModalOpen(false);
  };
  useEffect(() => {
    if (shippingAddress?.addressLine1) {
      let a2 = "";
      if (shippingAddress?.addressLine2) {
        a2 = shippingAddress?.addressLine2;
      }
      setAddressOneLine(
        shippingAddress.addressLine1 +
          " " +
          a2 +
          ", " +
          shippingAddress?.city +
          ", " +
          shippingAddress?.state +
          ", " +
          shippingAddress?.zipcode
      );
    } else {
      setAddressOneLine("");
    }
  }, [shippingAddress]);

  const closeModal = (save = false) => {
    setModalOpen(false);
    if (!save) clearStates();
  };
  const clearStates = () => {
    if (sameAddress) {
      setShippingAddress(oldShippingAddress);
    } else {
      setShippingAddress("");
    }
    setSameAddressState(false);
    setAddressChanged(false);
  };

  const toggleShipToClinic = useCallback((e) => {
    setShipToClinic(e.target.checked);
    setToggleShipTo((prev) => prev + 1);
    // props.shipToClinicFunc(e.target.checked);
  }, []);

  if (isPickup && isBulkOrder) {
    return (
      <Row className="mb-4 text-start">
        <label className="m-0 blueText"> {VPI_ADDRESS.name}</label>
        <p className="shipping-input">{VPI_ADDRESS.address}</p>
      </Row>
    );
  } else if (isBulkOrder) {
    return (
      <>
        <Row className="mb-4 text-center">
          {addressChanged ? (
            <Row className="mb-1">
              <label className="m-0 blueText text-start"> Updated Address </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="shipping-input">{addressOneLine} </span>{" "}
                <span>
                  <Tooltip title="Undo Changed Address">
                    <IconButton onClick={() => clearStates()} style={{ width: 35, height: 35 }}>
                      <CancelIcon color="error" style={{ fontSize: "20px" }} />
                    </IconButton>
                  </Tooltip>
                </span>
              </div>{" "}
            </Row>
          ) : null}

          <Row className="text-center w-100">
            <Col>
              <button
                className="pinkButton my-3"
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpen(true);
                }}
              >
                Change Shipping Address
              </button>
            </Col>
          </Row>
        </Row>
        <AddNewAddress modalOpen={modalOpen} closeModal={closeModal} setNewAddress={setNewAddress} />
      </>
    );
  } else return <></>;
}

export default CustomShippingAddress;
