import classnames from "classnames";
import React, { useEffect, useState,useCallback } from "react";
import MetaTags from "react-meta-tags";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import ClinicNavbar from "../../../../components/ClinicNavbar";
import ClinicIncompletePrescription from "./ClinicIncompletePrescription";
import ClinicPendingApprovalPrescription from "./ClinicPendingApprovalPrescription";
import { ClinicHoldPrescription } from "..";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";


function ClinicPendingPrescription() {
  const location = useLocation();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("1");

  function toggle(tab) {
    setActiveTab(prev => {
      if (prev !== tab) {
        return tab;
      }
    });
  }
  useEffect(() => {
    if (location?.state === "2") {
      setActiveTab("2");
    }
  }, [location]);

  return (
    <React.Fragment>
      <ClinicNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Pending Prescriptions | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <h4 className="p-3 text-muted">PENDING PRESCRIPTIONS</h4>
          <Row>
            <Col xs="12">
              <Card className="p-3">
                <Nav tabs className="nav-tabs-custom nav-justified pt-2">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        toggle("1");
                      }}>
                      Order HOLD Queue
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                      }}>
                      Provider Signature Needed
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <ClinicHoldPrescription />
                    </TabPane>
                    <TabPane tabId="2">
                      <ClinicIncompletePrescription />
                    </TabPane>
                  </TabContent>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(ClinicPendingPrescription);
