import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { last, map } from "lodash";
import React, { useMemo } from "react";
import { ROWS_LIMIT, columnHelper, formatDaySupply } from "utils";
import { dateFormatterMountainTime } from "utils/constants";

function PatientPrescription({
  prescriptions,
  rowLimit,
  tablePage,
  refetchList,
  totalRecords,
  changeRowLimit,
  handleChangePage,
}) {
  const PATIENT_PRESCRIPTION_COLUMNS = useMemo(
    () => [
      columnHelper.accessor("prescriptionDate", {
        header: "Date",
        headerStyle: { minWidth: "105px", textAlign: "center", paddingRight: "8px", paddingLeft: "8px" },
      }),
      columnHelper.accessor("createdByName", {
        header: "Created By",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
      }),
      columnHelper.accessor("products", {
        id: "Product",
        header: "Product(s)",
        headerStyle: { minWidth: "180px", paddingRight: "8px", paddingLeft: "8px" },
      }),
      columnHelper.accessor("products", {
        header: "Info",
        id: "Info",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
      }),
      columnHelper.accessor("products", {
        header: "Sig",
        id: "Sig",
      }),

      columnHelper.accessor("products", {
        header: "Patient Diagnosis",
        id: "Diagnosis",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
      }),

      columnHelper.accessor("products", {
        header: "Compounded Medication Reason",
        id: "Reason",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
      }),
    ],
    [],
  );
  const reactTable = useReactTable({
    data: prescriptions,
    columns: PATIENT_PRESCRIPTION_COLUMNS,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Grid container justifyContent={"flex-end"}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Grid>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                      <Typography variant="tableHeader" noWrap>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => {
              const originalRow = row.original;
              const isMultiProduct = originalRow?.products?.length > 1;
              const cells = row?.getVisibleCells();
              const actionCell = last(cells);
              const showAction = actionCell.column.id === "actions";
              return map(originalRow?.products, (product, index) => (
                <TableRow>
                  {index === 0 && (
                    <>
                      <TableCell
                        rowSpan={originalRow?.products?.length ?? 1}
                        style={cells?.[0].column.columnDef.headerStyle}>
                        {dateFormatterMountainTime(originalRow?.prescriptionDate)}
                      </TableCell>
                      <TableCell
                        rowSpan={originalRow?.products?.length ?? 1}
                        style={cells?.[1].column.columnDef.headerStyle}>
                        {originalRow?.createdByName}
                      </TableCell>
                    </>
                  )}
                  <TableCell style={cells?.[2].column.columnDef.headerStyle}>
                    <p className="smallText bold m-0">
                      {isMultiProduct ? `${index + 1}: ` : ""}
                      <span className="normalText">{product?.name}</span>
                    </p>
                  </TableCell>
                  <TableCell style={cells?.[3].column.columnDef.headerStyle}>
                    <p className="m-0 text-nowrap">{`Quantity: ${product.quantity}`}</p>
                    <p className="m-0 text-nowrap">{`Day Supply: ${formatDaySupply(product?.daySupply)}`}</p>
                    <p className="m-0 text-nowrap">{`Refills: ${product.refills}`}</p>
                  </TableCell>
                  <TableCell style={cells?.[4].column.columnDef.headerStyle}>{product?.sig}</TableCell>
                  <TableCell style={cells?.[5].column.columnDef.headerStyle}>{product?.patientDiagnosis}</TableCell>
                  <TableCell style={cells?.[6].column.columnDef.headerStyle}>
                    {product?.reasonForCompoundedMedication}
                  </TableCell>
                  {index === 0 && showAction && (
                    <TableCell
                      rowSpan={originalRow?.products?.length ?? 1}
                      style={cells?.[7]?.column.columnDef.headerStyle}>
                      {flexRender(actionCell.column.columnDef.cell, actionCell.getContext())}
                    </TableCell>
                  )}
                </TableRow>
              ));
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
    </>
  );
}

export default React.memo(PatientPrescription);
