import axios from "axios";
import { AuthApi, AuthSliceActions } from "rtk";

import { cookies, logout, refreshTokenCookies } from "utils";
import { COOKIES_NAME } from "./cookieConstant";

const axiosApiInstance = axios.create({ baseURL: "https://api.vpicompounding.net" });

export const errorResponse = error => {
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
  } else if (error.request) {
    console.log(error.request);
  } else {
  }
};

export const authTokenHeader = { Authorization: "Bearer " + cookies?.get(COOKIES_NAME.USER_TOKEN) };

export const setUpInterceptor = store => {
  let isFetchingToken = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    failedQueue = [];
  };

  axiosApiInstance.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isFetchingToken) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axiosApiInstance(originalRequest);
            })
            .catch(err => {
              return Promise.reject(err);
            });
        }
        originalRequest._retry = true;
        isFetchingToken = true;
        return new Promise(function (resolve, reject) {
          axiosApiInstance
            .post("accounts/refresh-token", {
              token: cookies.get(COOKIES_NAME.REFRESH_TOKEN),
            })
            .then(response => {
              const { jwtToken, ...userData } = response?.data;
              refreshTokenCookies(jwtToken, userData?.refreshToken);
              axios.defaults.headers.common["Authorization"] = "Bearer " + jwtToken;
              originalRequest.headers["Authorization"] = "Bearer " + jwtToken;
              processQueue(null, jwtToken);
              resolve(axiosApiInstance(originalRequest));
              store.dispatch(AuthSliceActions.refreshToken({ jwtToken, userData }));
            })
            .catch(err => {
              processQueue(err, null);
              reject(err);
              logout();
              store.dispatch(AuthApi.endpoints.userLogout.initiate());
              window.location.replace("/login");
            })
            .then(() => {
              isFetchingToken = false;
            });
        });
      }
      return Promise.reject(error);
    },
  );
};

export default axiosApiInstance;
