const USER_ROLES = {
  CLINIC_SUPER_USER: "ClinicSuperUser",
  CLINIC_PROVIDER: "ClinicProvider",
  CLINIC_SUPPORT: "ClinicSupport",
  ADMIN_SUPER_USER: "AdminSuperUser",
  PHARMACIST: "AdminPharmacyTechUser",
  RX_TECH: "AdminVPIRepUser",
  SALES_REP: "AdminSalesTeamUser",
};

const ADMIN_ALLOWED_CLINIC_ACTIONS = [USER_ROLES.ADMIN_SUPER_USER, USER_ROLES.PHARMACIST, USER_ROLES.CLINIC_SUPER_USER];
const ALLOWED_ACTION_USERS = [USER_ROLES.ADMIN_SUPER_USER, USER_ROLES.PHARMACIST, USER_ROLES.RX_TECH];
const HIDE_PATIENT_LOCATION = [USER_ROLES.CLINIC_PROVIDER, USER_ROLES.CLINIC_SUPPORT];
const SHOW_INVOICE_TAB = [USER_ROLES.CLINIC_SUPER_USER, USER_ROLES.ADMIN_SUPER_USER];

const USERS_EDIT_PRESCRIPTION = [
  USER_ROLES.CLINIC_SUPER_USER,
  USER_ROLES.CLINIC_PROVIDER,
  USER_ROLES.CLINIC_SUPPORT,
  USER_ROLES.ADMIN_SUPER_USER,
  USER_ROLES.PHARMACIST,
];

export {
  USER_ROLES,
  SHOW_INVOICE_TAB,
  USERS_EDIT_PRESCRIPTION,
  ADMIN_ALLOWED_CLINIC_ACTIONS,
  ALLOWED_ACTION_USERS,
  HIDE_PATIENT_LOCATION,
};
