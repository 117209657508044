import { encrypt } from 'cryptofunc';
import moment from 'moment';
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Cookies from 'universal-cookie';

import PatientNavbar from '../../../components/patientNavbar';

import NotificationsDD from '../../../components/dropdowns/notificationsDD';
import Lottie from '../../../components/lottie';
import Spinner from '../../../components/spinner';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import PatientInfo1 from './patient-info-1';
import PatientInfo2 from './patient-info-2';
import PatientInfo3 from './patient-info-3';
import PatientInfo5 from './patient-info-5';

class EditPatient extends Component {
  constructor(props) {
    super(props);

    this.profileImageUpdated = React.createRef(false);
    this.driverLicenseUpdated = React.createRef(false);

    this.state = {
      step: 1,
      complete: false,
      showSpinner: true,
      error: '',
      patient: null,
      isToNotifyWhenOrderReceived: false,
      isToNotifyWhenOrderInProcess: false,
      isToNotifyWhenOrderReadyForPickup: false,
      isToNotifyWhenOrderOnHold: false,
      isToNotifyWhenOrderIsCompleted: false,

      profileImageURL: '',
      newProfileImageURL: '',
      driverLicenseURL: '',
      isEmployee: false,
      POBox: false,
      dateofBirth: '',
      defaultPatientAddress: {},
      email: '',
      dob: '',
      contactNumber: '',
      profileImageRemoteUrl: '',
      notificationMethod: '',
      notificationPreference: '',
      driverLicenseRemoteUrl: '',
      addressVerification: '',
      patientShippingAddress: {
        shippingAddressLine1: '',
        shippingAddressLine2: '',
        shippingCity: '',
        shippingZipcode: '',
        shippingState: '',
      },
      fedexShippingAddress: '',
      showValidateModal: false,
    };
  }

  setOrderInProcess(bool) {
    this.setState({
      isToNotifyWhenOrderInProcess: bool,
    });
  }
  setOrderShipped(bool) {
    this.setState({
      isToNotifyWhenOrderShipped: bool,
    });
  }

  setOrderOnHold(bool) {
    this.setState({
      isToNotifyWhenOrderOnHold: bool,
    });
  }

  setOrderCompleted(bool) {
    this.setState({
      isToNotifyWhenOrderIsCompleted: bool,
    });
  }
  setOrderReceived(bool) {
    this.setState({
      isToNotifyWhenOrderReceived: bool,
    });
  }

  setEmail(email) {
    this.setState({ email: email });
  }

  setContactNumber(number) {
    this.setState({ contactNumber: number });
  }

  setSSameAsDefaultPA() {
    var serialize = require('form-serialize');
    var form = document.getElementById('editPatientForm');
    var obj = serialize(form, { hash: true, empty: true });
    var index = parseInt(obj.default_patient_address);
    var DPA = {
      addressLine1: obj.addresses[index]["'addressLine1'"],
      addressLine2: obj.addresses[index]["'addressLine2'"],
      city: obj.addresses[index]["'city'"],
      state: obj.addresses[index]["'state'"],
      zipcode: obj.addresses[index]["'zipcode'"],
    };

    this.setState({ defaultPatientAddress: DPA });
  }
  /* Validation Shipping*/
  onClickNotValidate() {
    this.closeModal();
    this.setState({
      addressVerification: true,
    });
  }
  onClickValidate() {
    var serialize = require('form-serialize');
    var form = document.getElementById('editPatientForm');
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });

    this.setState({
      patientShippingAddress: {
        shippingAddressLine1: this.state.fedexShippingAddress.addressLine1,
        shippingAddressLine2: this.state.fedexShippingAddress.addressLine2,
        shippingCity: this.state.fedexShippingAddress.city,
        shippingZipcode: this.state.fedexShippingAddress.zipcode,
        shippingState: this.state.fedexShippingAddress.state,
      },
      addressVerification: true,
    });

    this.closeModal();
  }
  closeModal() {
    this.setState({ showValidateModal: false });
  }

  /** */

  async updatePatient() {
    const driverLicenseURL = this.state.driverLicenseRemoteUrl;
    const profileImageURL = this.state.newProfileImageURL;

    this.setState({ error: '' });
    var serialize = require('form-serialize');
    var form = document.getElementById('editPatientForm');

    var obj = serialize(form, {
      hash: true,
      empty: true,
    });

    this.setState({ showSpinner: true });

    await obj?.addresses?.forEach((address, index) => {
      obj.addresses[index] = {
        addressLine1: obj.addresses[index]["'addressLine1'"],
        addressLine2: obj.addresses[index]["'addressLine2'"],
        city: obj.addresses[index]["'city'"],
        state: obj.addresses[index]["'state'"],
        zipcode: obj.addresses[index]["'zipcode'"],
        isDefault: index === parseInt(obj.default_patient_address) ? true : false,
      };
    });

    if (this.state.error === '') {
      var encryptedArr = [];

      axiosConfig
        .post(
          'patientPortal/editPatientProfileInfo',
          {
            patient: {
              firstName: obj.firstName,
              middleName: obj.middleName,
              lastName: obj.lastName,
              guardianName: obj.guardianName,
              currentOccupation: obj.currentOccupation,
              ssn: obj.ssn,
              dateOfBirth: moment(obj.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY'),
              martialStatus: obj.martialStatus,
              genderIdentity: obj.genderIdentity,
              sex: obj.sex,
              sexualOrientation: obj.sexualOrientation,
              ethnicity: obj.ethnicity,
              race: obj.race,
              weight: obj.weight,
              height: obj.height,

              patientImageUrl: profileImageURL ? profileImageURL : this.state.patient.patientImageUrl,

              isToNotifyWhenOrderReceived: this.state.isToNotifyWhenOrderReceived,
              isToNotifyWhenOrderOnHold: this.state.isToNotifyWhenOrderOnHold,
              isToNotifyWhenOrderInProcess: this.state.isToNotifyWhenOrderInProcess,

              isToNotifyWhenOrderIsCompleted: this.state.isToNotifyWhenOrderIsCompleted,

              email: obj.email,
              cellPhone: obj.cellPhone,
              phoneNumber: obj.phoneNumber,
              addresses: obj.addresses,
              shippingAddressLine1: obj.shippingAddressLine1,
              shippingAddressLine2: obj.shippingAddressLine2,
              shippingCity: obj.shippingCity,
              shippingState: obj.shippingState,
              shippingZipcode: obj.shippingZipcode,
              shippingPreference: obj.shippingPreference,
              isPoBox: this.state.POBox,
              preferredName: obj.preferredFirstName + ' ' + obj.preferredLastName,
              preferredFirstName: obj.preferredFirstName,
              preferredLastName: obj.preferredLastName,
              preferredContactMethod: obj.preferredContactMethod,
              preferredLanguage: obj.preferredLanguage,
              notificationPreferences: obj.notificationPreference,
              notificationEmail: obj.notificationEmail,
              notificationText: obj.notificationText,
              preferredEmergencyContact: obj.preferredEmergencyContact,
              invoiceFrequency: obj.invoiceFrequency,

              password: obj.password ? obj.password : '',
            },
          },
          {
            headers: {
              Authorization: 'Bearer ' + new Cookies().get('user_token'),
            },
          }
        )
        .then(async (response) => {
          this.setState({
            showSpinner: false,
            complete: true,
          });
        })
        .catch((error) => {
          this.setState({
            showSpinner: false,
            error: 'An error occurred while trying to update patient information. ' + error?.response?.data?.message,
          });
          errorResponse(error);
        });
    }
  }

  apiErrorrResponse(error) {
    this.setState({
      error: error,
      showSpinner: false,
    });

    errorResponse(error);
  }

  async handleValidSubmit(e) {
    e.preventDefault();

    this.setState({ error: '' });
    var serialize = require('form-serialize');
    var form = document.getElementById('editPatientForm');
    var obj = serialize(form, {
      hash: true,
      empty: true,
    });

    if (this.state.step === 1) {
      this.setState({ step: 2 });

      var formData = new FormData();
      var driverLicenseURL = '';
      var updatedProfileImageURL = '';
      var uploadImageHeader = {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
          'Content-Type': 'multipart/form-data;',
        },
      };

      if (this.state.driverLicenseURL && this.driverLicenseUpdated.current) {
        formData.append('file', this.state.driverLicenseURL);

        await axiosConfig
          .post('uploads/uploadFile', formData, uploadImageHeader)
          .then(async (response) => {
            driverLicenseURL = response.data.accessUrl;
            this.setState({ driverLicenseRemoteUrl: driverLicenseURL });
            this.driverLicenseUpdated.current = false;

            if (this.state.newProfileImageURL && this.profileImageUpdated.current) {
              formData = new FormData();

              formData.append('file', this.state.newProfileImageURL);

              await axiosConfig
                .post('uploads/uploadFile', formData, uploadImageHeader)
                .then(async (response) => {
                  updatedProfileImageURL = response.data.accessUrl;
                  this.setState({
                    profileImageRemoteUrl: updatedProfileImageURL,
                  });
                  this.profileImageUpdated.current = false;
                })
                .catch((error) => {
                  this.apiErrorrResponse('An error occurred while trying to upload profile image. ' + error?.response?.data?.message);
                });
            }
          })
          .catch((error) => {
            this.apiErrorrResponse('An error occurred while trying to upload driver license. ' + error?.response?.data?.message);
          });
      } else if (this.state.newProfileImageURL && this.profileImageUpdated.current) {
        formData = new FormData();
        await formData.append('file', this.state.newProfileImageURL);

        await axiosConfig
          .post('uploads/patientUploadFile', formData, uploadImageHeader)
          .then(async (response) => {
            updatedProfileImageURL = response.data.accessUrl;

            this.profileImageUpdated.current = false;
            this.setState({ profileImageRemoteUrl: updatedProfileImageURL });
          })
          .catch((error) => {
            this.apiErrorrResponse('An error occurred while trying to upload profile image. ' + error?.response?.data?.message);
          });
      }
    } else if (this.state.step === 2) {
      this.setState({ step: 3 });
    } else if (this.state.step === 3) {
      if (this.state.shipToClinic || (this.state.addressVerification && this.state.patientShippingAddress.shippingAddressLine1 === obj.shippingAddressLine1 && this.state.patientShippingAddress.shippingAddressLine2 === obj.shippingAddressLine2 && this.state.patientShippingAddress.shippingCity === obj.shippingCity && this.state.patientShippingAddress.shippingZipcode === obj.shippingZipcode && this.state.patientShippingAddress.shippingState.toLowerCase() === obj.shippingState.toLowerCase())) {
        this.setState({ step: 4 });
      } else {
        var fedexShippingAddress;

        this.setState({
          showValidateModal: true,
          patientShippingAddress: {
            shippingAddressLine1: obj.shippingAddressLine1,
            shippingAddressLine2: obj.shippingAddressLine2,
            shippingCity: obj.shippingCity,
            shippingZipcode: obj.shippingZipcode,
            shippingState: obj.shippingState,
          },
          showSpinner: true,
        });

        var body = {
          address: {
            streetLines: {
              addressLine1: obj.shippingAddressLine1,
              addressLine2: obj.shippingAddressLine2 ? obj.shippingAddressLine2 : '-',
            },

            city: obj.shippingCity,
            state: obj.shippingState,
            postalCode: obj.shippingZipcode,
          },
        };

        await axiosConfig
          .post('/portal/validateAddress', body)
          .then(async (response) => {
            var fedexShippingAddr = {
              addressLine1: response.data.addressLine1,
              addressLine2: response.data.addressLine2 || response.data.addressLine2 !== '' ? response.data.addressLine2 : '-',
              city: response.data.city,
              state: response.data.state,
              zipcode: response.data.postalCode,
            };
            this.setState({
              fedexShippingAddress: fedexShippingAddr,
              showSpinner: false,
              error: '',
            });
          })
          .catch((error) => {
            this.setState({
              showValidateModal: false,
              error: 'An error occurred while trying to trying to Validate Address. Please Try again',
              showSpinner: false,
            });
          });
      }
    } else if (this.state.step === 4) {
      this.updatePatient();
    }
  }

  setShowSpinner(check) {
    this.setState({ showSpinner: check });
  }

  setError(error) {
    this.setState({ error: error });
  }

  setProfileImageURL(URL) {
    this.setState({ newProfileImageURL: URL });
  }

  setDriverLicenseURL(URL) {
    this.setState({ driverLicenseURL: URL });
  }

  isEmployee(check) {
    this.setState({ isEmployee: check });
  }

  setPOBox(check) {
    this.setState({ POBox: check });
  }
  setProfileImageUpdated = () => {
    this.profileImageUpdated.current = true;
    this.setState({ profileImageRemoteUrl: '' });
  };

  setDriverLicenseUpdated = () => {
    this.driverLicenseUpdated.current = true;
    this.setState({ driverLicenseRemoteUrl: '' });
  };

  componentWillMount() {
    axiosConfig
      .get(
        'patientPortal/getPatientProfileInfo',

        {
          headers: {
            Authorization: 'Bearer ' + new Cookies().get('user_token'),
          },
        }
      )
      .then(async (response) => {
        var dob1 = response.data.dateOfBirth.split('/');
        dob1 = [dob1[2], dob1[0], dob1[1]].join('-');

        await this.setState({
          showSpinner: false,
          patient: response.data,
          patientShippingAddress: {
            shippingAddressLine1: response.data.shippingAddressLine1,
            shippingAddressLine2: response.data.shippingAddressLine2,
            shippingCity: response.data.shippingCity,
            shippingZipcode: response.data.shippingZipcode,
            shippingState: response.data.shippingState,
          },
          isToNotifyWhenOrderReceived: response.data.isToNotifyWhenOrderReceived,
          isToNotifyWhenOrderOnHold: response.data.isToNotifyWhenOrderOnHold,
          isToNotifyWhenOrderInProcess: response.data.isToNotifyWhenOrderInProcess,

          isToNotifyWhenOrderIsCompleted: response.data.isToNotifyWhenOrderIsCompleted,
          notificationMethod: response.data.notificationMethod,
          notificationPreferences: response.data.notificationPreferences,

          dob: dob1,
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: 'An error occurred while trying to fetch patient information. ' + error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <PatientNavbar />
        <div className='page-content'>
          <MetaTags>
            <title>Edit Patient | VPI Compounding</title>
          </MetaTags>
          <Container fluid>
            <h4 className='p-3 text-muted'>EDIT PATIENT INFORMATION</h4>
            <Row>
              <Col xs='12'>
                <Card>
                  <CardBody>
                    {this.state.complete ? (
                      <div className='m-sm-5 m-4 p-sm-5 p-4 text-center'>
                        <Lottie name='success-check' />
                        <p className='mb-5'>Thank you for updating your patient's profile. All updated information will be recorded for any prescription orders in queue!</p>
                        <Link to='/profile' className='pinkButton'>
                          Profile
                        </Link>
                      </div>
                    ) : (
                      <>
                        <div className={`w-100 text-center my-4 stepsPillsContainer`}>
                          <div className='flexJCAC'>
                            <div className='rounded-pill' style={{ width: '200px' }}>
                              <div className='activeProgressNumberCircle'>
                                <p>1</p>
                              </div>
                              <p className='m-0'>Personal</p>
                              <p>Information</p>
                            </div>
                            <div className='rounded-pill' style={{ width: '200px' }}>
                              <div className={this.state.step >= 2 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
                                <p>2</p>
                              </div>
                              <p className='m-0'>Contact</p>
                              <p>Information</p>
                            </div>
                            <div className='rounded-pill' style={{ width: '200px' }}>
                              <div className={this.state.step >= 3 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
                                <p>3</p>
                              </div>
                              <p className='m-0'>Shipping</p>
                              <p>Information</p>
                            </div>
                          </div>
                          <div className='flexJCAC'>
                            <div className='rounded-pill' style={{ width: '200px' }}>
                              <div className={this.state.step >= 4 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
                                <p>4</p>
                              </div>
                              <p className='m-0'>Preferred</p>
                              <p>Information</p>
                            </div>
                          </div>
                        </div>
                        <CardBody className='pt-0'>
                          <div className='p-2'>
                            <form id='editPatientForm' className='stepsForm form-horizontal px-sm-4 px-0' onSubmit={(e) => this.handleValidSubmit(e)}>
                              {this.state.showSpinner ? <Spinner /> : null}
                              {this.state.error ? (
                                <Alert color='danger' className='text-center'>
                                  {this.state.error}
                                </Alert>
                              ) : null}
                              <div className='mb-5 px-lg-5 px-sm-5 px-0'>
                                {this.state.patient ? (
                                  <>
                                    <PatientInfo1 dob={this.state.dob} step={this.state.step} dateOfBirth={this.state.dob} patient={this.state.patient} setProfileImageURL={this.setProfileImageURL.bind(this)} setProfileImageUpdated={this.setProfileImageUpdated} />
                                    <PatientInfo2 step={this.state.step} patient={this.state.patient} />
                                    <PatientInfo3 step={this.state.step} fedexShippingAddress={this.state.fedexShippingAddress} closeModal={this.closeModal.bind(this)} onClickNotValidate={this.onClickNotValidate.bind(this)} onClickValidate={this.onClickValidate.bind(this)} showValidateModal={this.state.showValidateModal} defaultPatientAddress={this.state.defaultPatientAddress} setSSameAsDefaultPA={this.setSSameAsDefaultPA.bind(this)} patient={this.state.patient} patientShippingAddress={this.state.patientShippingAddress} setPOBox={this.setPOBox.bind(this)} />{' '}
                                    <PatientInfo5
                                      notificationMethod={this.state.notificationMethod}
                                      notificationPreferences={this.state.notificationPreferences}
                                      isToNotifyWhenOrderReceived={this.state.isToNotifyWhenOrderReceived}
                                      isToNotifyWhenOrderOnHold={this.state.isToNotifyWhenOrderOnHold}
                                      isToNotifyWhenOrderInProcess={this.state.isToNotifyWhenOrderInProcess}
                                      isToNotifyWhenOrderIsCompleted={this.state.isToNotifyWhenOrderIsCompleted}
                                      setOrderInProcess={this.setOrderInProcess.bind(this)}
                                      setOrderOnHold={this.setOrderOnHold.bind(this)}
                                      setOrderCompleted={this.setOrderCompleted.bind(this)}
                                      setOrderReceived={this.setOrderReceived.bind(this)}
                                      step={this.state.step}
                                      patient={this.state.patient}
                                    />
                                  </>
                                ) : null}
                              </div>
                              <Row className='m-sm-5 m-0 text-center flexJCAC pt-3'>
                                {this.state.step === 1 ? null : (
                                  <button
                                    className='lightButton mt-4 mx-2'
                                    style={{ width: '40%' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({
                                        step: this.state.step - 1,
                                      });
                                    }}
                                  >
                                    Back
                                  </button>
                                )}
                                {this.state.step === 4 ? (
                                  <button className='blueButton mt-4 w-50' type='submit'>
                                    Update Patient
                                  </button>
                                ) : (
                                  <button className='blueButton mt-4 w-50' type='submit'>
                                    Next
                                  </button>
                                )}
                              </Row>
                            </form>
                          </div>
                        </CardBody>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default EditPatient;
