import React, { useState, useEffect, useMemo } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import { cookies } from "utils";
import { COOKIES_NAME } from "utils/cookieConstant";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Spinner from "components/spinner";

const ProviderLocationDD = props => {
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState("loading");
  const [showSpinner, setShowSpinner] = useState(false);
  const clinicId = useMemo(
    () => props.clinicId || cookies.get(COOKIES_NAME.CLINIC_ID),
    [props.clinicId, cookies.get(COOKIES_NAME.CLINIC_ID)],
  );
  useEffect(() => {
    if (clinicId || props?.clinicIdDetails || props?.defaultClinicId) {
      setShowSpinner(true);
      axiosConfig
        .post(
          "clinicLocations/clinicLocationsInClinic",
          {
            clinicId: clinicId || props?.clinicIdDetails || props?.defaultClinicId || "",
            keyword: "",
            limit: 20,
            currentPage: 1,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          },
        )
        .then(async response => {
          var locationList = [];
          if (props.defaultValue) {
            props.updateClinicLocation(props.defaultValue);
          }
          await response?.data?.clinicLocations?.forEach(location => {
            locationList.push(
              <option
                selected={props.defaultValue === location.id ? true : false}
                key={location.id}
                value={location.id}>
                {location.locationName}
              </option>,
            );
          });

          setLocations(locationList);
          setLoading(false);
          setResult("complete");
          setShowSpinner(false);
        })
        .catch(error => {
          if (error.response?.data?.message === "Clinic Location not found") {
            setResult("complete");
            setLoading(false);
          } else {
            setLoading(false);
            setResult("err");
            errorResponse(error);
          }
        });
    }
  }, [clinicId, props?.clinicIdDetails, props?.defaultClinicId, props.defaultValue]);

  return (
    <FormGroup key={props.defaultValue}>
      <Label className="normalText" for={props.name}>
        Clinic Location {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <p className="dropdownArrow">
            <i className="fas fa-angle-down"></i>
          </p>
          <Input
            type="select"
            name={props.name}
            required={props.required}
            onChange={e => {
              if (props.updateClinicLocation) {
                props.updateClinicLocation(e.target.value);
              }
            }}>
            <option value="" disabled selected>
              {locations?.length
                ? "Select"
                : loading
                ? "Loading clinic locations ..."
                : result === "complete"
                ? "No clinic locations found"
                : "Error fetching clinics"}
            </option>
            {locations}
          </Input>
        </>
      )}
    </FormGroup>
  );
};

export default ProviderLocationDD;
