/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Paper } from "@mui/material";
import { AlertMessage, PatientPrescriptionListing } from "components";
import PatientNavbar from "components/patientNavbar";
import Spinner from "components/spinner";
import React, { useCallback, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useLazyGetPatientPrescriptionQuery } from "rtk";

function PrescriptionHistory() {
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);

  const setPageError = useCallback((error = "") => {
    setError(error);
  }, []);

  const setPageLoading = useCallback((loading = false) => {
    setShowSpinner(loading);
  }, []);
  return (
    <React.Fragment>
      <PatientNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | VPI Compounding</title>
        </MetaTags>
        <Container maxWidth="false">
          <h4 className="p text-muted my-4">PRESCRIPTION HISTORY</h4>
          <Paper className="p-3" elevation={1}>
            {showSpinner && <Spinner />}
            <AlertMessage msg={error} isError={true} />
            <PatientPrescriptionListing
              setPageError={setPageError}
              setPageLoading={setPageLoading}
              listingQuery={useLazyGetPatientPrescriptionQuery}
            />
          </Paper>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(PrescriptionHistory);
