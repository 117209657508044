/* eslint-disable react-hooks/exhaustive-deps */
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse } from "components";
import { filter, findIndex } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Col, Row } from "reactstrap";
import AlertMessage from "screens/clinic/Prescriptions/components/alert_message";
import { PRESCRIPTION_PATIENT_COLUMNS, ROWS_LIMIT, columnHelper } from "utils";
import Spinner from "../../../components/spinner";
import { useLazyGetPatientsListQuery } from "../../../rtk";
import HeaderInvoiceCheckBox from "../Invoices/InvoiceList/HeaderInvoiceCheckBox";
import InvoiceCheckBox from "../Invoices/InvoiceList/InvoiceCheckBox";
import { usePatientListing } from "../Patients/Hook";

const PrescriptionPatient = ({
  isBulkOrder = false,
  editPrescription = false,
  selectedPatients = [],
  updateSelectedPatients,
  clinicId: propsClinicId,
  setShippingAddress,
  selectedProducts,
}) => {
  const {
    sorting,
    rowLimit,
    tablePage,
    showSpinner,
    rowSelection,
    errorMessage,
    totalRecords,
    patientListing,
    searchKeyword,
    selectedPatient,
    setSorting,
    applyFilter,
    clearFilter,
    refetchList,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    handleChangePage,
  } = usePatientListing({
    propsClinicId,
    propsRowLimit: ROWS_LIMIT[0],
    listingQuery: useLazyGetPatientsListQuery,
  });

  useEffect(() => {
    if (selectedPatient.length < 1 && selectedPatients.length > 0) {
      setRowSelection(selectedPatients.reduce((prev, curr) => ({ ...prev, [curr]: true }), {}));
    }
  }, [selectedPatients]);

  const organizedMessage = useMemo(() => {
    if (errorMessage) {
      return errorMessage;
    } else if (
      isBulkOrder === false &&
      selectedPatients.length > 1 &&
      selectedProducts.length > 1 &&
      editPrescription
    ) {
      return "This is a Single Patient order with multiple products, You can not select more than one patient";
    }
  }, [editPrescription, errorMessage, isBulkOrder, selectedPatients.length, selectedProducts.length]);

  const renderHeaderCell = useCallback(header => {
    const columnSortDirection = header.column.getIsSorted();
    const isCheckBoxCell = header.id === "checkbox";

    const cellView = (
      <Typography variant="tableHeader" noWrap>
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </Typography>
    );
    let headerCellView = cellView;
    if (!isCheckBoxCell) {
      headerCellView = (
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() => header.column.toggleSorting(columnSortDirection !== "desc")}>
          {cellView}
        </TableSortLabel>
      );
    }

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={header.column.getIsSorted()}>
        {headerCellView}
      </TableCell>
    );
  }, []);

  const onRowSelection = useCallback(
    (rowData, isSelected) => {
      // Update the Parent Component State
      updateSelectedPatients(prev => (isSelected ? filter(prev, x => x.id !== rowData.id) : [...prev, rowData]));
    },
    [updateSelectedPatients],
  );

  const onAllRowSelection = useCallback(
    isSelected => {
      // Update the Parent Component State
      updateSelectedPatients(prev => {
        let nextState = prev;
        if (isSelected) {
          nextState = filter(nextState, x => findIndex(patientListing, patient => patient.id === x.id) === -1);
        } else {
          nextState = [...prev, ...patientListing];
        }
        return nextState;
      });
    },
    [patientListing, updateSelectedPatients],
  );

  const checkBox = useMemo(
    () =>
      columnHelper.accessor("checkbox", {
        headerStyle: { width: 40, paddingLeft: 0, paddingRight: 0 },
        enableSorting: false,
        header: ({ table }) => (
          <HeaderInvoiceCheckBox tableRow={table} onAllRowSelection={onAllRowSelection} isSelectableAll />
        ),
        cell: ({ row }) => <InvoiceCheckBox rowData={row.original} tableRow={row} onRowSelection={onRowSelection} />,
      }),
    [onAllRowSelection, onRowSelection],
  );

  const clinicPatientTableHeader = useMemo(() => [checkBox, ...PRESCRIPTION_PATIENT_COLUMNS], [checkBox]);

  const reactTable = useReactTable({
    data: patientListing,
    columns: clinicPatientTableHeader,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: row => row.id,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: Boolean(patientListing.length),
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
      rowSelection,
    },
  });

  return (
    <React.Fragment>
      {showSpinner && <Spinner />}
      <AlertMessage msg={organizedMessage} isError={organizedMessage} />
      <ListingSearch
        searchKeyword={searchKeyword}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
      />
      <Row>
        <Col xl="12">
          <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }}>
            <Tooltip title="Refresh">
              <Button variant="contained" onClick={refetchList}>
                <RefreshIcon style={{ width: 20, height: 20 }} />
              </Button>
            </Tooltip>
          </Grid>
          <TableContainer style={{ maxHeight: "60vh" }}>
            <Table size="small" stickyHeader>
              <TableHead>
                {reactTable.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => renderHeaderCell(header))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {reactTable.getRowModel().rows.map(row => (
                  <MuiTableCollapse
                    key={row.id}
                    showNested={false}
                    readOnly={true}
                    row={row}
                    showColoredRows={false}
                    showMarkAllBtn={!row.getCanSelect()}
                    isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
                  />
                ))}
              </TableBody>
            </Table>
            {patientListing === 0 && !showSpinner ? (
              <p className="w-100 text-center m-5">No patients data found!</p>
            ) : null}
          </TableContainer>
          <TablePagination
            component="div"
            showFirstButton
            showLastButton
            page={tablePage}
            count={totalRecords}
            rowsPerPage={rowLimit}
            rowsPerPageOptions={ROWS_LIMIT}
            onPageChange={handleChangePage}
            onRowsPerPageChange={changeRowLimit}
            style={{ alignItems: "center", marginBottom: 0 }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default React.memo(PrescriptionPatient);
