/* eslint-disable react-hooks/exhaustive-deps */
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { AlertMessage, FormCheckBox, FormOutlineField, FormRadioField, FormSelectField } from "components";
import { AddNewAddress } from "components/AddNewAddress";
import ValidAddressModal from "components/validAddressModal";
import { find, isNaN, isNull, startsWith, toNumber } from "lodash";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useLazyValidateAddressQuery } from "rtk";
import { COMPLETE_SHIPPING_METHOD, FEDEX_PACKAGES, PACKAGE_BOXES, UPS_PACKAGES } from "utils";
import useShippingApi from "./Hook/useShippingApi";

const ChangeShippingInfo = forwardRef((props, ref) => {
  const {
    isBulkOrder = false,
    shipToClinic = false,
    clinicLocationId = "",
    selectedPatients = [],
    shippingMethod,
    defaultShippingInfo,
    setShippingAddress,
    updateShippingCost,
    setShippingMethod,
    setCustomShippingRate,
    isCustomShippingLabel,
  } = props;
  const [customError, setCustomError] = useState("");
  const [typedAddress, setTypedAddress] = useState(null);
  const [addressModal, setAddressModal] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(null);
  const [fedexShippingAddress, setFedexShippingAddress] = useState(null);
  const [validateShippingAddress] = useLazyValidateAddressQuery();

  const {
    apiLoading,
    clinicAddressOptions,
    patientAddressOptions,
    getShippingMethodOption,
    findShippingMethod,
    getPatientAddressObj,
    getClinicAddressObj,
  } = useShippingApi({
    isBulkOrder,
    clinicLocationId,
    selectedPatients,
    isCustomShippingLabel,
  });
  

  const { control, getValues, setValue, trigger } = useForm({
    defaultValues: {
      shipToClinic: shipToClinic,
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
    },
  });

  const showValidatedAddress = useMemo(() => fedexShippingAddress !== null, [fedexShippingAddress]);
  const isShipToClinic = useWatch({ control, name: "shipToClinic" });
  const watchShippingMethod = useWatch({ control, name: "shippingMethod" });
  const watchShippingPreference = useWatch({ control, name: "shippingPreference" });
  const watchCustomShippingCost = useWatch({ control, name: "customShippingCost" });
  const watchShippingPackage = useWatch({ control, name: "shippingPackage" });
  const watchPackageType = useWatch({ control, name: "packagingType" });

  const showPackagesBox = useMemo(
    () => watchShippingPackage === "YOUR_PACKAGING" || watchShippingPackage === "UNKNOWN",
    [watchShippingPackage],
  );

  const isCustomBox = useMemo(() => watchPackageType === "customSize", [watchPackageType]);
  const isCustomRate = useMemo(() => watchShippingMethod === "customRate", [watchShippingMethod]);
  const shippingPackages = useMemo(() => {
    if (watchShippingPreference) {
      if (watchShippingMethod === "fedex") {
        return FEDEX_PACKAGES;
      } else if (watchShippingMethod === "ups") {
        return UPS_PACKAGES;
      }
    }
  }, [watchShippingMethod, watchShippingPreference]);

  const shippingMethodOption = useMemo(
    () => getShippingMethodOption(watchShippingMethod),
    [getShippingMethodOption, watchShippingMethod],
  );

  const getShippingRate = useCallback(() => {
    const shippingMethod = getValues("shippingMethod");
    const shippingPreferenceMethod = getValues("shippingPreference");
    // Get shipping Rate
    if (shippingMethod === "customRate") {
      setShippingMethod("CustomRate");
    } else if (shippingPreferenceMethod) {
      setShippingMethod(shippingPreferenceMethod);
    }
  }, [getValues, setShippingMethod]);

  const setPackageDimension = useCallback(() => {
    const shippingBoxType = getValues("packagingType");
    if (shippingBoxType !== "customSize") {
      const inch = toNumber(shippingBoxType?.[0] ?? "");
      if (!isNaN(inch)) {
        setValue("length", inch);
        setValue("width", inch);
        setValue("height", inch);
      }
    } else {
      setValue("length", getValues("length"));
      setValue("width", getValues("width"));
      setValue("height", getValues("height"));
    }
  }, []);

  useEffect(() => {
    setValue("shipToClinic", shipToClinic);
    setValue("shippingMethod", findShippingMethod(shippingMethod));
    setValue("shippingPreference", shippingMethod);
  }, [findShippingMethod, setValue, shipToClinic, shippingMethod]);

  useEffect(() => {
    if (shippingMethodOption?.length > 0 && !watchShippingPreference) {
      const preferenceValue = shippingMethodOption?.[0]?.value;
      setValue("shippingPreference", preferenceValue);
      if (!(startsWith(preferenceValue?.toLowerCase(), "ups") || startsWith(preferenceValue?.toLowerCase(), "fedex"))) {
        getShippingRate();
      }
    }
  }, [shippingMethodOption, watchShippingPreference]);

  useEffect(() => {
    if (shippingPackages?.length && !watchShippingPackage) {
      setValue("shippingPackage", shippingPackages?.[0]?.value);
    }
  }, [shippingPackages, watchShippingPackage]);

  useEffect(() => {
    if (watchShippingPackage === "YOUR_PACKAGING" || watchShippingPackage === "UNKNOWN") {
      if (!watchPackageType) {
        setValue("packagingType", PACKAGE_BOXES?.[0]?.value);
        setPackageDimension();
        getShippingRate();
      }
    } else {
      setValue("packagingType", "");
      setPackageDimension();
    }
  }, [setPackageDimension, setValue, watchPackageType, watchShippingPackage]);

  useEffect(() => {
    if (isCustomRate) {
      setCustomShippingRate(watchCustomShippingCost);
    }
  }, [isCustomRate, setCustomShippingRate, watchCustomShippingCost]);

  useEffect(() => {
    if (!apiLoading) {
      let shippingAddress = "";
      // 
      if (defaultShippingInfo?.shipTo === "patient") {
        const getPatientAddress = find(patientAddressOptions, add =>
          startsWith(add.label, defaultShippingInfo?.shippingAddress?.addressLine1),
        );
        shippingAddress = getPatientAddress?.value;
      } else {
        const getClinicAddress = find(clinicAddressOptions, add =>
          startsWith(add.label, defaultShippingInfo?.shippingAddress?.addressLine1),
        );
        shippingAddress = getClinicAddress?.value;
      }
      //this setTimeout is mandatory to show the accurate box size when opening modal 2nd time
      setTimeout(() => {
        setValue("shippingAddress", shippingAddress);
        setValue("customShippingCost", defaultShippingInfo?.customRate);
        setValue("weight", defaultShippingInfo?.weight);
        setValue("packagingType", "customSize");
        setValue("length", defaultShippingInfo?.dimensions?.length);
        setValue("width", defaultShippingInfo?.dimensions?.width);
        setValue("height", defaultShippingInfo?.dimensions?.height);
        getShippingRate();
      }, 100);
    }
  }, [
    apiLoading,
    clinicAddressOptions,
    defaultShippingInfo?.customRate,
    defaultShippingInfo?.dimensions?.height,
    defaultShippingInfo?.dimensions?.length,
    defaultShippingInfo?.dimensions?.width,
    defaultShippingInfo?.shipTo,
    defaultShippingInfo?.shippingAddress?.addressLine1,
    defaultShippingInfo?.weight,
    patientAddressOptions,
    setValue,
  ]);

  console.log("patient address",patientAddressOptions);
  console.log("shipping address",clinicAddressOptions);

  const setPackageType = useCallback(() => {
    setPackageDimension();
    getShippingRate();
  }, [getShippingRate, setPackageDimension]);

  const getShippingInfo = useCallback(() => {
    const shippingValues = getValues();
    return {
      weight: shippingValues?.weight ?? 0,
      shippingMethod: shippingValues?.shippingPreference,
      packagingType: shippingValues?.shippingPackage,
      dimensions: {
        length: shippingValues.length,
        width: shippingValues.width,
        height: shippingValues.height,
      },
    };
  }, [getValues]);

  const getShippingData = useCallback(async () => {
    const formValidated = await trigger();
    if (formValidated) {
      const shippingValues = getValues();
      const apiParam = {
        shipTo: isBulkOrder || isShipToClinic ? "clinic" : "patient",
        weight: shippingValues?.weight ?? 0,
        shippingMethod: shippingValues?.shippingPreference,
        packagingType: shippingValues?.shippingPackage,
        dimensions: {
          length: shippingValues.length,
          width: shippingValues.width,
          height: shippingValues.height,
        },
      };
      return apiParam;
    } else {
      return null;
    }
  }, [getValues, isBulkOrder, isShipToClinic, trigger]);

  useImperativeHandle(ref, () => ({
    getShippingData: getShippingData,
    getShippingInfo: getShippingInfo,
  }));

  const resetShippingPreference = useCallback(() => {
    setValue("shippingPreference", "");
    setValue("shippingPackage", "");
    setValue("packagingType", "");
    setValue("length", 0);
    setValue("width", 0);
    setValue("height", 0);
  }, []);

  const setSelectedAddress = useCallback(
    (address, isNewAddress = false) => {
      setShippingAddress(address, isNewAddress);
      
      updateShippingCost(address);
    },
    [setShippingAddress, updateShippingCost],
  );

  const setNewShippingAddress = useCallback(
    (patientAddress = {}) => {
      setAddNewAddress(patientAddress);
      setSelectedAddress(
        {
          addressLine1: patientAddress?.addressLine1,
          addressLine2: patientAddress?.addressLine2,
          city: patientAddress?.city,
          state: patientAddress?.state,
          zipcode: patientAddress?.zipcode,
        },
        true,
      );
    },
    [setSelectedAddress],
  );

  const setNewAddress = async e => {
    e.preventDefault();
    const serialize = require("form-serialize");
    const obj = serialize(document.getElementById("changeAddressForm"), { hash: true });
    setTypedAddress({
      addressLine1: obj?.addressLine1,
      addressLine2: obj?.addressLine2,
      city: obj?.city,
      state: obj?.state,
      zipcode: obj?.zipcode,
    });
    const body = {
      isUSPS: watchShippingMethod === "usps",
      address: {
        streetLines: {
          addressLine1: obj?.addressLine1 ?? "",
          addressLine2: obj?.addressLine2 ?? "-",
        },
        city: obj?.city,
        state: obj?.state,
        postalCode: obj?.zipcode,
      },
    };
    try {
      const fedexShipping = await validateShippingAddress(body, true).unwrap();
      setFedexShippingAddress({ ...fedexShipping, zipcode: fedexShipping.postalCode });
      setAddressModal(false);
    } catch (e) {
      setCustomError(e.data?.message);
      setAddressModal(false);
    }
  };

  const closeValidateAddress = useCallback(() => {
    setFedexShippingAddress(null);
    setNewShippingAddress(typedAddress);
  }, [setNewShippingAddress, typedAddress]);

  const onClickValidate = useCallback(() => {
    setNewShippingAddress(fedexShippingAddress);
    setFedexShippingAddress(null);
  }, [fedexShippingAddress]);

  const refetchAddress = useCallback(() => {
    setAddNewAddress(null);
    setFedexShippingAddress(null);
    let address = {};
    if (getValues("shipToClinic")) {
      address = getClinicAddressObj();
    } else {
      const getSelectedAddressID = getValues("shippingAddress");
      address = getPatientAddressObj(getSelectedAddressID);
    }
    setSelectedAddress(address);
  }, [getClinicAddressObj, getPatientAddressObj, setSelectedAddress]);

  const checkBoxBtn = useCallback(
    (name, index) => {
      if (index) {
        setValue("shippingAddress", clinicAddressOptions?.[0]?.value);
      } else {
        setValue("shippingAddress", patientAddressOptions?.[0]?.value);
      }
      setValue(name, index);
      refetchAddress();
    },
    [clinicAddressOptions, patientAddressOptions, refetchAddress],
  );

  const renderNewAddressView = useMemo(() => {
    if (isNull(addNewAddress)) {
      return (
        <Grid item xs={12} textAlign={"center"}>
          <Button
            color="primary"
            variant="contained"
            size="large"
            className="pinkButton"
            onClick={e => {
              e.preventDefault();
              setAddressModal(true);
            }}>
            Add New Address
          </Button>
        </Grid>
      );
    } else {
      const newAddressValue = `${addNewAddress.addressLine1 ?? "-"}, ${addNewAddress?.addressLine2 ?? "-"}, ${
        addNewAddress.city
      },${addNewAddress.state}, ${addNewAddress.zipcode}`;
      return (
        <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
          <Grid item xs={10}>
            <TextField
              id="outlined-basic"
              label="New Address"
              variant="outlined"
              size="small"
              fullWidth
              contentEditable={false}
              value={newAddressValue}
            />
          </Grid>
          <IconButton aria-label="delete" size="small" style={{ width: 25, height: 25 }} onClick={refetchAddress}>
            <CancelIcon color="error" style={{ width: 20, height: 20 }} />
          </IconButton>
        </Grid>
      );
    }
  }, [addNewAddress, refetchAddress]);
  console.log("patient address options in shipping",selectedPatients);
  return (
    <>
  <Grid container>
    <AlertMessage msg={customError} isError={true} />
          <Grid container item xs={12}>
        <Grid item xs={12}>
          {isCustomShippingLabel ? null :
          <FormCheckBox
            control={control}
            name="shipToClinic"
            checkBoxBtn={checkBoxBtn}
            MuiFieldProps={{ disabled: !isNull(addNewAddress), label: "Ship To Clinic" }}
          />
          }
        </Grid>
        <Grid item xs={12}>
          <FormSelectField
            name={"shippingAddress"}
            control={control}
            rules={{ required: "Address is required." }}
            dependentChange={refetchAddress}
            MuiFieldProps={{
              size: "small",
              disabled: !isNull(addNewAddress),
              label: isShipToClinic ? "Clinic Address" : "Patient Addresses",
              optionArray: isShipToClinic ? clinicAddressOptions : patientAddressOptions,
            }}
          />
        </Grid>
        {renderNewAddressView}
        <Grid item xs={12} my={2}>
          <Typography variant="h6">Shipping Method</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormRadioField
            control={control}
            name={"shippingMethod"}
            radioOptions={COMPLETE_SHIPPING_METHOD}
            dependentChange={resetShippingPreference}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          {isCustomRate ? (
            <FormOutlineField
              name={"customShippingCost"}
              control={control}
              rules={{ required: "Rate is required." }}
              MuiFieldProps={{ label: "Custom Rate", size: "small" }}
            />
          ) : (
            <>
              <FormSelectField
                name={"shippingPreference"}
                control={control}
                dependentChange={getShippingRate}
                MuiFieldProps={{ label: "Shipping Preference", size: "small", optionArray: shippingMethodOption }}
              />
              <Grid item xs={3}>
                <FormOutlineField
                  name={"weight"}
                  control={control}
                  rules={{ required: "Weight is required." }}
                  MuiFieldProps={{
                    size: "small",
                    label: "Weight",
                    type: "number",
                    endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                    onBlur: getShippingRate,
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
  </Grid>
  <AddNewAddress modalOpen={addressModal} closeModal={() => setAddressModal(false)} setNewAddress={setNewAddress} />
  <ValidAddressModal
    isUSPS={watchShippingMethod === "usps"}
    showValidAddressModal={showValidatedAddress}
    fedexShippingAddress={fedexShippingAddress}
    clinicAddress={typedAddress}
    onClickNotValidate={closeValidateAddress}
    onClickValidate={onClickValidate}
    closeModal={closeValidateAddress}
  />
</>
  );
});

export default React.memo(ChangeShippingInfo);
