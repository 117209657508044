export const PRESCRIPTION_TYPES = {
  pending: "pending",
  history: "history",
  archive: "archive",
};

export const CLINIC_TYPES = {
  APPROVED: "Approved Clinics",
  PENDING: "Pending Approval",
};

export const PRESCRIPTION_URL = {
  [PRESCRIPTION_TYPES.pending]: "/pending-prescriptions",
  [PRESCRIPTION_TYPES.history]: "/prescription-history",
  [PRESCRIPTION_TYPES.archive]: "/prescription-archive",
};

export const PRESCRIPTION_CARD_LABELS = {
  [PRESCRIPTION_TYPES.pending]: "PENDING PRESCRIPTIONS",
  [PRESCRIPTION_TYPES.history]: "RX WORKFLOW",
  [PRESCRIPTION_TYPES.archive]: "PRESCRIPTION ARCHIVE",
};

export const PRESCRIPTION_BUTTONS = {
  [PRESCRIPTION_TYPES.pending]: {
    type: PRESCRIPTION_TYPES.pending,
    url: PRESCRIPTION_URL[PRESCRIPTION_TYPES.pending],
    label: "Pending Approval",
  },
  [PRESCRIPTION_TYPES.history]: {
    type: PRESCRIPTION_TYPES.history,
    url: PRESCRIPTION_URL[PRESCRIPTION_TYPES.history],
    label: "RX Workflow",
  },
  [PRESCRIPTION_TYPES.archive]: {
    type: PRESCRIPTION_TYPES.archive,
    url: PRESCRIPTION_URL[PRESCRIPTION_TYPES.archive],
    label: "Received",
  },
};

export const PRESCRIPTION_META_LABELS = {
  [PRESCRIPTION_TYPES.pending]: {
    label: "Prescriptions | VPI Compounding",
  },
  [PRESCRIPTION_TYPES.history]: {
    label: "Prescriptions | RX Workflow",
  },
  [PRESCRIPTION_TYPES.archive]: {
    label: "Prescriptions Archive | RX Workflow",
  },
};

export const SAMPLE_DATA = [
  {
    id: 11,
    date: "09/02/2022",
    medications: [
      { id: 1, medicationName: "XYZ", medicationStatus: "HOLD-O" },
      {
        id: 2,
        medicationName: "ABC",
        medicationStatus: "NEW",
      },
    ],
  },
  {
    id: 22,
    date: "09/03/2022",
    medications: [
      { id: 3, medicationName: "xyz", medicationStatus: "HOLD-O" },
      {
        id: 4,
        medicationName: "abc",
        medicationStatus: "NEW",
      },
    ],
  },
];

export const RX_STATUS = [
  "NEW ORDER - RTR",
  "CUSTOM ORDER - RTR",
  "HOLD - C",
  "HOLD - M",
  "HOLD - PP",
  "HOLD - REFILL - REMAINING*",
  "HOLD - REFILL - REAUTH",
  "HOLD - FTS",
  "CHECK STOCK",
  "RTC",
  "TASKED",
  "LOT REWORK",
  "IN PROCESS",
  "IN BIN - RTD",
  "DISPENSED - RTV",
  "RX VERIFIED - RTP",
  "RX VERIFIED - STORAGE",
  "RX VERIFIED - FRIDGE",
  "HOLD - PD",
  "RTP",
  "PACK VERIFIED - RTS",
  "RECEIVED",
];
