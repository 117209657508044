import { values } from "lodash";
import React, { useCallback, useState } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import { useLazyGetExportClinicsListQuery } from "rtk/rtkEndpoints/Clinics/ClinicsQuery";
import { userRoleSelector } from "selectors";
import AdminNavbar from "../../../components/AdminNavbar";
import Spinner from "../../../components/spinner";
import { USER_ROLES } from "../../../utils";

import ClinicApprovedList from "./ClinicApprovedList";
import ClinicPendingList from "./ClinicPendingList";
import { CLINIC_TYPES } from "screens/clinic/Prescriptions/constants";
import { RenderListingTabButtons } from "components";

const ClinicsList = () => {
  const [clinicType, setClinicType] = useState(CLINIC_TYPES.APPROVED);
  const userRole = useSelector(userRoleSelector);

  const [fetchReport, { error: reportError, isFetching: reportFetching }] = useLazyGetExportClinicsListQuery();

  const exportClinicsList = useCallback(
    e => {
      e.preventDefault();
      fetchReport();
    },
    [fetchReport],
  );

  const tabAction = useCallback(item => {
    setClinicType(item);
  }, []);
  return (
    <React.Fragment>
      <AdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Clinics | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">CLINICS</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {reportFetching ? <Spinner /> : null}
                  {reportError ? (
                    <Alert color="danger" className="text-center">
                      {reportError?.data?.message}
                    </Alert>
                  ) : null}
                  <Row className={`px-3 my-4 justify-content-end`}>
                    {userRole === USER_ROLES.ADMIN_SUPER_USER ||
                    userRole === USER_ROLES.PHARMACIST ||
                    userRole === USER_ROLES.RX_TECH ? (
                      <Link to="/add-clinic" className="pinkButton">
                        Add Clinic
                      </Link>
                    ) : null}
                    {userRole !== USER_ROLES.RX_TECH && clinicType === CLINIC_TYPES.APPROVED && (
                      <span onClick={exportClinicsList} className="mx-1 pinkButton pointer">
                        Export Report
                      </span>
                    )}
                  </Row>
                  <Row>
                    <h2 className="px-3 my-4 justify-content-center row">{clinicType}</h2>
                  </Row>
                  <RenderListingTabButtons
                    selectedTab={clinicType}
                    buttonsList={values(CLINIC_TYPES)}
                    buttonAction={tabAction}
                  />
                  <TabContent activeTab={clinicType}>
                    <TabPane tabId={CLINIC_TYPES.APPROVED}>
                      <ClinicApprovedList />
                    </TabPane>
                    <TabPane tabId={CLINIC_TYPES.PENDING}>
                      <ClinicPendingList />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ClinicsList;
