import EditNoteIcon from "@mui/icons-material/EditNote";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton, Tooltip } from "@mui/material";
import { forEach, set } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyExportArchivedPrescriptionQuery } from "rtk";
import { userRoleSelector } from "selectors";
import { ROWS_LIMIT, USER_ROLES, columnHelper, formatPatientNames } from "utils";

function useAdminPrescriptionListing({ listingQuery }) {
  const [apiParam, setApiParam] = useState({});
  const [innerError, setInnerError] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [prescriptionId, setPrescriptionId] = useState("");
  const [isReviewModal, setIsReviewModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState("");
  const [openNoteModal, setOpenNoteModal] = useState({});
  const [openPackingSlipModal, setOpenPackingSlipModal] = useState("");
  const [rowLimit, setRowLimit] = useState(ROWS_LIMIT[1]);
  const userRole = useSelector(userRoleSelector);
  const [disableFilter, setDisableFilter] = useState(true);
  const [
    fetchList,
    { data: { pagination, prescriptions } = { pagination: {}, prescriptions: [] }, error, isFetching },
  ] = listingQuery();
  const [fetchReport, { error: reportError, isFetching: reportFetching }] = useLazyExportArchivedPrescriptionQuery();
  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() => rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const filterRxStatus = useMemo(() => apiParam?.rxStatus ?? "", [apiParam?.rxStatus]);
  const selectedFilter = useMemo(() => apiParam?.filter ?? "", [apiParam.filter]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const showLoading = useMemo(() => isFetching || reportFetching, [isFetching, reportFetching]);
  const errorMessage = useMemo(
    () => error?.data?.message || innerError || reportError?.data?.message || "",
    [reportError?.data?.message, innerError, error?.data?.message],
  );
  const toDate = useMemo(() => apiParam?.toDate ?? "", [apiParam.toDate]);
  const fromDate = useMemo(() => apiParam?.fromDate ?? "", [apiParam.fromDate]);

  useEffect(() => {
    if (!isFetching) {
      fetchList({ currentPage: 1, limit: rowLimit }, true);
    }
  }, []);

  const exportArchivedPrescription = useCallback(
    isMaster => {
      let body = {
        isMasterExport: isMaster,
      };
      if (isMaster) {
        body.fromDate = fromDate;
        body.toDate = toDate;
        fetchReport(body);
      } else {
        fetchReport(body);
      }
    },
    [fetchReport, fromDate, toDate],
  );

  const refetchList = useCallback(() => {
    const body = { currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    setApiParam(body);
    fetchList(body);
  }, [apiParam, fetchList, rowLimit, tablePage]);

  const setCustomError = useCallback(message => {
    setInnerError(message);
  }, []);

  const toggleInvoiceModal = useCallback((id = "") => {
    setInvoiceId(id);
  }, []);

  const toggleDetailModal = useCallback((id = "", isReview = false) => {
    setPrescriptionId(id);
    setIsReviewModal(isReview);
  }, []);

  const toggleUpdateModal = useCallback((id = "", showUpdateModal = "") => {
    setPrescriptionId(id);
    setOpenUpdateModal(showUpdateModal);
  }, []);

  const toggleNoteModal = useCallback((id = "", notes = "", createdBy = "", createdOn = "") => {
    setOpenNoteModal({
      prescriptionId: id,
      noteMessage: notes,
      createdBy: createdBy,
      createdOn: createdOn,
    });
  }, []);

  const togglePackingSlipModal = useCallback((id = "") => {
    setOpenPackingSlipModal(id);
  }, []);

  // const updateFilterValue = useCallback((keyword, value) => {
  //   setApiParam(prev => ({ ...prev, [keyword]: value }));
  //   setDisableFilter(!Boolean(value));
  // }, []);

  const updateFilterValue = useCallback((keyword, value) => {
    setApiParam(prev => ({ ...prev, [keyword]: value }));
    if (value) {
      setDisableFilter(false);
    }
  }, []);

  const updateFilterRxStatus = useCallback(rxValue => {
    updateFilterValue("rxStatus", rxValue);
  }, []);

  const clearFilter = useCallback(
    e => {
      e.preventDefault();
      setApiParam(prev => {
        fetchList({ currentPage: 1, limit: prev.limit }, true);
        return { currentPage: 1, limit: prev.limit };
      });
      setDisableFilter(true);
    },
    [fetchList],
  );

  // const clearFilter = useCallback(
  //   (e, keyName = "rxStatus") => {
  //     e.preventDefault();
  //     setApiParam(prev => {
  //       const newParams = prev;
  //       unset(newParams, keyName);
  //       fetchList({ ...newParams, currentPage: 1, limit: rowLimit });
  //       return newParams;
  //     });
  //     if (keyName === "rxStatus") {
  //       setDisableFilter(true);
  //     }
  //   },
  //   [fetchList, rowLimit],
  // );

  const updateFilterType = useCallback(e => {
    e.preventDefault();
    setApiParam(prev => ({ ...prev, filter: e.target.value }));
  }, []);

  const applyFilter = useCallback(
    e => {
      e.preventDefault();
      const body = { currentPage: 1, limit: rowLimit };
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, key === "filter" ? value.split(",") : value);
        }
      });
      fetchList(body);
    },
    [apiParam, fetchList, rowLimit],
  );
  const updateKeyword = useCallback(e => {
    e.preventDefault();
    setApiParam(prev => ({ ...prev, keyword: e.target.value }));
  }, []);

  const changeRowLimit = useCallback(
    e => {
      const size = Number(e.target.value);
      setRowLimit(size);
      const body = { ...apiParam, currentPage: 1, limit: size };
      if (!apiParam?.keyword) delete body.keyword;

      fetchList(body, true);
    },
    [apiParam, fetchList],
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, currentPage: newPage + 1, limit: rowLimit };
        if (!apiParam?.keyword) delete body.keyword;

        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam],
  );

  const ADMIN_PENDING_COLUMNS = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Date",
        headerStyle: { minWidth: "210px" },
      }),
      columnHelper.accessor("rxOrder", {
        cell: props => (
          <Tooltip title="View">
            <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
              <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
            </IconButton>
          </Tooltip>
        ),
        header: "Rx order",
        headerStyle: { minWidth: "50px", paddingLeft: 0, paddingRight: 0 },
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        headerStyle: { width: 150 },
        cell: props => (
          <IconButton
            onClick={() =>
              toggleNoteModal(
                props.row.original.id,
                props.row.original.message,
                props.row.original.createdByName,
                props.row.original.created,
              )
            }
            style={{ width: 40, height: 40 }}>
            <EditNoteIcon style={{ fontSize: "30" }} className="medText" />
          </IconButton>
        ),
      }),
      columnHelper.accessor("clinicLocationName", {
        header: "Business Entity",
      }),
      columnHelper.accessor("clinicLocationAddress", {
        header: "Location",
        headerStyle: { minWidth: 80 },
      }),
      columnHelper.accessor("patientName", {
        header: "Patient Name",
        headerStyle: { paddingLeft: 6, paddingRight: 6 },
        cell: ({ getValue, row }) => {
          const patientName = getValue();
          return (
            <Box component={"span"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              {patientName ? (
                <>
                  <Tooltip title={patientName}>
                    <span>{formatPatientNames(patientName)}</span>
                  </Tooltip>
                  <Tooltip title="Edit Patient">
                    <IconButton
                      style={{ width: 30, height: 30 }}
                      onClick={() => toggleUpdateModal(row.original.id, "Patient")}>
                      <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </Box>
          );
        },
      }),
      columnHelper.accessor("billTo", {
        header: "Bill to",
        headerStyle: { minWidth: 80 },
      }),
      columnHelper.accessor("shipTo", {
        header: "Delivery Method",
        headerStyle: { width: "250px", paddingLeft: 6, paddingRight: 6 },
        cell: ({ getValue, row }) => {
          return (
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <span>{getValue()}</span>
              <Tooltip title="Edit Shipment">
                <IconButton
                  style={{ width: 30, height: 30 }}
                  onClick={() => toggleUpdateModal(row.original.id, "Delivery Address")}>
                  <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      }),
    ],
    [toggleDetailModal, toggleNoteModal, toggleUpdateModal],
  );

  const ADMIN_RX_WORKFLOW_COLUMNS = [
    columnHelper.accessor("clinicLocationName", {
      header: "Business Entity",
      headerStyle: { width: "180px", paddingRight: "8px", paddingLeft: "8px" },
    }),
    columnHelper.accessor("createdByName", {
      header: "Created By",
      headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    }),
    columnHelper.accessor("patientName", {
      header: "Patient Name",
      headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
      cell: ({ getValue, row }) => {
        const isEditable = row.getCanSelect() && userRole !== USER_ROLES.SALES_REP && !row.original.isRecreated;
        const patientName = getValue();
        return (
          <Box component={"span"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {patientName ? (
              <Tooltip title={patientName}>
                <span>{formatPatientNames(patientName)}</span>
              </Tooltip>
            ) : null}
            {isEditable ? (
              <Tooltip title="Edit Patient">
                <IconButton
                  style={{ width: 30, height: 30 }}
                  onClick={() => toggleUpdateModal(row.original.id, "Patient")}>
                  <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    }),
    columnHelper.accessor("shipTo", {
      header: "Delivery Method",
      headerStyle: { width: "280px", paddingRight: "8px", paddingLeft: "8px" },
      cell: ({ getValue, row }) => {
        const isEditable =
          (row.getCanSelect() || row.original.prescriptionStatus === "Ready To Pack") &&
          userRole !== USER_ROLES.SALES_REP;
        return (
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <span>{getValue()}</span>
            {isEditable ? (
              <Tooltip title="Edit Shipment">
                <IconButton
                  style={{ width: 30, height: 30 }}
                  onClick={() => toggleUpdateModal(row.original.id, "Delivery Address")}>
                  <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    }),

    columnHelper.accessor("totalAmount", {
      header: "Billing Info",
      cell: props => {
        const totalAmount = props.getValue() || "-";
        const paymentStatus = props.row?.original?.paymentStatus || "-";
        const billTo = props.row?.original?.billTo || "-";
        return (
          <>
            <p className="smallText semiBold m-0">
              Total($): <span className="text-muted normalText">{totalAmount}</span>
            </p>
            <p className="smallText semiBold m-0">
              Bill: <span className="text-muted normalText">{billTo}</span>
            </p>
            <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
              Payment: <span className="text-muted normalText">{paymentStatus}</span>
            </p>
          </>
        );
      },
    }),

    columnHelper.accessor("trackingNumber", {
      header: "Shipping Info",
      headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
      cell: props => {
        const trackingNumber = props.getValue() || "-";
        const shippingLabel = props.row?.original?.shippingLabel || "-";
        return (
          <>
            <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
              Tracking: <span className="text-muted normalText">{trackingNumber}</span>
            </p>
            <p className="smallText semiBold m-0">
              Shipping:{" "}
              {shippingLabel == "-" ? (
                shippingLabel
              ) : (
                <a href={shippingLabel} target="_blank" rel="noreferrer" className="medText semiBold">
                  Label
                </a>
              )}
            </p>
          </>
        );
      },
    }),
  ];

  return {
    toDate,
    fromDate,
    userRole,
    rowLimit,
    tablePage,
    invoiceId,
    pagination,
    isReviewModal,
    showLoading,
    errorMessage,
    openNoteModal,
    selectedFilter,
    totalRecords,
    prescriptions,
    disableFilter,
    searchKeyword,
    prescriptionId,
    filterRxStatus,
    openUpdateModal,
    openPackingSlipModal,
    ADMIN_PENDING_COLUMNS,
    ADMIN_RX_WORKFLOW_COLUMNS,
    refetchList,
    setCustomError,
    applyFilter,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    toggleNoteModal,
    handleChangePage,
    updateFilterType,
    toggleDetailModal,
    updateFilterValue,
    toggleUpdateModal,
    toggleInvoiceModal,
    updateFilterRxStatus,
    togglePackingSlipModal,
    exportArchivedPrescription,
  };
}

export default useAdminPrescriptionListing;
