import { Grid } from "@mui/material";
import { FormCardField, FormCardNumber, FormOutlineField, FormSelectField } from "components";
import { some } from "lodash";
import React, { useEffect, useMemo } from "react";
import { useWatch } from "react-hook-form";
import { usePaymentInputs } from "react-payment-inputs";
import { USA_STATES } from "utils";

function CreditCardRow({ control, name, trigger, additionalInfo = false, mandatory = false }) {
  const { meta, getCardNumberProps, getExpiryDateProps, getCardImageProps } = usePaymentInputs();
  const watchCardField = useWatch({
    control,
    name: [
      `${name}.cardType`,
      `${name}.cardHolderCity`,
      `${name}.cardHolderName`,
      `${name}.creditCardNumber`,
      `${name}.cardExpirationDate`,
      `${name}.cardHolderState`,
      `${name}.cardHolderZipcode`,
      `${name}.cardHolderAddressLine1`,
      `${name}.cardHolderContactNumber`,
    ],
  });

  const isCardRequired = useMemo(() => mandatory || some(watchCardField, Boolean), [mandatory, watchCardField]);

  useEffect(() => {
    if (meta?.touchedInputs?.cardNumber && !meta?.erroredInputs?.cardNumber) {
      trigger(`${name}.creditCardNumber`);
    }
    if (meta?.touchedInputs?.expiryDate && !meta?.erroredInputs?.expiryDate) {
      trigger(`${name}.cardExpirationDate`);
    }
  }, [
    meta?.erroredInputs?.cardNumber,
    meta?.erroredInputs?.expiryDate,
    meta?.touchedInputs?.cardNumber,
    meta?.touchedInputs?.expiryDate,
    name,
    trigger,
  ]);

  const cardAdditionalInfo = useMemo(() => {
    if (additionalInfo) {
      return (
        <>
          <Grid item xs={12} sm={6}>
            <FormOutlineField
              name={`${name}.cardType`}
              control={control}
              rules={{ required: isCardRequired ? "Card type is required" : false }}
              MuiFieldProps={{ label: "Card Type" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormOutlineField
              name={`${name}.cardHolderContactNumber`}
              control={control}
              rules={{
                required: "Contact number is required.",
                pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" },
              }}
              MuiInputProps={{ format: "(###) ###-####" }}
              MuiFieldProps={{ label: "Card Holder Contact Number", placeholder: "(123) 456-7890", isMasked: true }}
            />
          </Grid>
        </>
      );
    }
  }, [additionalInfo, control, isCardRequired, name]);

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormOutlineField
          name={`${name}.cardHolderName`}
          control={control}
          rules={{ required: isCardRequired ? "Card holder name is required." : false }}
          MuiFieldProps={{ label: "Card Holder Name" }}
        />
      </Grid>
      <Grid container columnSpacing={2} item xs={12} sm={6}>
        <Grid item xs={6}>
          <FormCardNumber
            name={`${name}.creditCardNumber`}
            control={control}
            getCardField={getCardNumberProps}
            getCardImage={getCardImageProps}
            rules={{
              required: isCardRequired ? "Card number is required." : false,
              validate: () => (isCardRequired ? meta?.erroredInputs?.cardNumber ?? true : true),
            }}
            MuiFieldProps={{ label: "Credit card number" }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormCardField
            name={`${name}.cardExpirationDate`}
            control={control}
            getCardField={getExpiryDateProps}
            rules={{
              required: isCardRequired ? "Card expiry is required." : false,
              validate: () => (isCardRequired ? meta?.erroredInputs?.expiryDate ?? true : true),
            }}
            MuiFieldProps={{ label: "Card expiry" }}
          />
        </Grid>
      </Grid>
      {cardAdditionalInfo}
      <Grid item xs={12} sm={6}>
        <FormOutlineField
          name={`${name}.cardHolderAddressLine1`}
          control={control}
          rules={{ required: isCardRequired ? "Address line 1 is required." : false }}
          MuiFieldProps={{ label: "Address line 1", multiline: true, rows: 2 }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormOutlineField
          name={`${name}.cardHolderAddressLine2`}
          control={control}
          MuiFieldProps={{ label: "Address line 2", multiline: true, rows: 2 }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormOutlineField
          name={`${name}.cardHolderCity`}
          control={control}
          rules={{ required: isCardRequired ? "City is required." : false }}
          MuiFieldProps={{ label: "City" }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormSelectField
          name={`${name}.cardHolderState`}
          control={control}
          rules={{ required: isCardRequired ? "State is required." : false }}
          MuiFieldProps={{ label: "State", optionArray: USA_STATES }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormOutlineField
          name={`${name}.cardHolderZipcode`}
          control={control}
          rules={{
            required: isCardRequired ? "Zip Code is required." : false,
            pattern: { value: /^\d{5}(-\d{4})?$/, message: "Invalid Zip Code" },
          }}
          MuiFieldProps={{ label: "Zip Code", placeholder: "12345, 12345-1234" }}
        />
      </Grid>
    </>
  );
}

export default React.memo(CreditCardRow);
