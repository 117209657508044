import { CometChat } from "@cometchat-pro/chat";
import { forEach } from "lodash";
import Cookies from "universal-cookie";
import ClinicPermissions from "../permissions/clinic-permissions.json";
import { COOKIES_NAME } from "./cookieConstant";
import { shutDownIntercom } from "./intercom";
import axiosConfig, { errorResponse } from "./axiosConfig";
import axios from "axios";
const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;
//Code
async function addUserToCometChat(name, uid, clinicName, role) {
  const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users`;
  const bodyVal = {
    uid: uid,
    name: name,
    role: role.toLowerCase(),

    metadata: {
      businessName: clinicName,
      role: role,
    },
  };
  await axios
    .post(url, bodyVal, {
      headers: {
        apiKey: `${cometChatApiKey}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(async response => {
      return true;
    })
    .catch(error => {
      return false;
    });
}
async function getClinicStaffList(clinicId = "", sizePerPage = 20, pageNo = 1) {
  const staffArray = [];
  const body = {
    clinicId: new Cookies().get("clinic_id") || clinicId,
    keyword: "",
    limit: sizePerPage,
    currentPage: pageNo,
  };

  try {
    const response = await axiosConfig.post("staffs/getAllStaffInClinic", body, {
      headers: {
        Authorization: "Bearer " + new Cookies().get("user_token"),
      },
    });

    const totalPages = response.data.pagination.totalPages;

    // Use the existing response for the first page
    staffArray.push(...response.data.staff.map(staff => staff.id));

    // Fetch remaining pages concurrently, if any
    const promises = [];
    for (let i = 2; i <= totalPages; i++) {
      body.currentPage = i;
      promises.push(
        axiosConfig
          .post("staffs/getAllStaffInClinic", body, {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          })
          .then(staffResponse => {
            staffArray.push(...staffResponse.data.staff.map(staff => staff.id));
          }),
      );
    }

    await Promise.all(promises);
    return staffArray.length ? staffArray : [];
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function getAdminList(sizePerPage = 20, pageNo = 1) {
  var body = {
    limit: sizePerPage,
    currentPage: pageNo,
  };

  try {
    const response = await axiosConfig.post("admin/account/getAllAdminIds", body, {
      headers: {
        Authorization: "Bearer " + new Cookies().get("user_token"),
      },
    });

    const adminArray = response.data.admins.map(admin => admin.id);
    return adminArray;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function getAllStaffIdInAllClinics(sizePerPage = 20, pageNo = 1) {
  var body = {
    limit: sizePerPage,
    currentPage: pageNo,
  };

  try {
    const response = await axiosConfig.post("staffs/getAllStaffIdsInAllClinic", body, {
      headers: {
        Authorization: "Bearer " + new Cookies().get("user_token"),
      },
    });
    const staffArray = response.data.staff.map(admin => admin.id);
    return staffArray;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function chunkingFriends(id = "", adminList = [], staffList = []) {
  let adminArrayList = adminList;
  let staffArrayList = staffList;
  if (adminArrayList !== "Error" && staffArrayList != "Error") {
    staffArrayList.push(...adminArrayList);
    var friendsList = staffArrayList;
    var j = 0;
    var chunksListDivisor = parseInt(friendsList.length / 15);
    var withoutRemainderVal = chunksListDivisor * 15;
    var remainder = friendsList.length % 15;
    var k = 0;
    if (friendsList.length % 15 == 0) {
      for (i = 0; i < chunksListDivisor; i++) {
        var chunkFriendsArray = [];
        //Push 15 Length of Array
        for (j = 0; j < 15; j++) {
          chunkFriendsArray.push(friendsList[k]);
          k++;
        }
        await makeFriends(id, chunkFriendsArray);
      }
    } else {
      for (i = 0; i < chunksListDivisor; i++) {
        var chunkFriendsArray = [];
        //Push 15 Length of Array
        for (j = 0; j < 15; j++) {
          if (k < withoutRemainderVal) {
            chunkFriendsArray.push(friendsList[k]);
          }
          k++;
        }
        await makeFriends(id, chunkFriendsArray);
      }
      var chunkFriendsArray1 = [];
      for (var i = 0; i < remainder; i++) {
        chunkFriendsArray1.push(friendsList[k]);
        k++;
      }
      await makeFriends(id, chunkFriendsArray1);
    }
  }
}

async function makeFriends(uid, friendsArray) {
  const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}/friends`;
  const bodyVal = {
    accepted: friendsArray,
  };
  await axios
    .post(url, bodyVal, {
      headers: {
        apiKey: `${cometChatApiKey}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(async response => {
      return true;
    })
    .catch(error => {
      return false;
    });
}
async function updateUserToCometChat(name, uid, clinicName, role) {
  const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}`;
  const bodyVal = {
    uid: uid,
    name: name,
    role: role.toLowerCase(),

    metadata: {
      businessName: clinicName,
      role: role,
    },
  };
  await axios
    .put(url, bodyVal, {
      headers: {
        apiKey: cometChatApiKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then(async response => {
      return true;
    })
    .catch(error => {
      return false;
    });
}

async function deleteUserFromCometChat(uid) {
  const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}`;
  const bodyVal = {
    permanent: true,
  };

  await axios
    .delete(url, {
      headers: {
        apiKey: cometChatApiKey,
        "Content-Type": "application/json",
        Accept: "application/json",
      },

      bodyVal,
    })
    .then(async response => {
      return true;
    })
    .catch(error => {
      return false;
    });
}
export {
  cometChatAppId,
  cometChatRegion,
  cometChatApiKey,
  makeFriends,
  getAdminList,
  getClinicStaffList,
  addUserToCometChat,
  deleteUserFromCometChat,
  updateUserToCometChat,
  getAllStaffIdInAllClinics,
  chunkingFriends,
};
