import React, { Component } from "react";
import { Row } from "reactstrap";
import ProviderLocationDD from "components/dropdowns/providerLocationDD";
import LocationProvidersDD from "components/dropdowns/clinicLocationProvider";

class VerifyProviderAndLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      locationId: "",
      providerNPI: "",
      providerDEA: "",
    };
    localStorage.removeItem("rxJourney");
    localStorage.removeItem("shipAddress1");
    localStorage.removeItem("shipMethod");
  }

  async updateClinicLocation(id) {
    await this.setState({ locationId: id });
    this.props.setCreditForLocation(id);
  }

  updateProviderInfo(info) {
    this.setState({
      providerNPI: info.npi,
      providerDEA: info?.deaInfo?.filter(a => a.clinicLocationId === this.state.locationId)[0]?.dea,
    });
    this.props.setProvider?.(info);
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.defaultLocationId !== prevProps.defaultLocationId) {
  //     this.setState({
  //       locationId: this.props.defaultLocationId ? this.props.defaultLocationId : "",
  //       providerNPI: this.props.defaultProviderNPI ? this.props.defaultProviderNPI : "",
  //       providerDEA: this.props.defaultProviderDEA ? this.props.defaultProviderDEA : "",
  //     });
  //   }
  // }

  async componentDidMount() {
    await this.setState({
      locationId: this.props.defaultLocationId ? this.props.defaultLocationId : "",
      providerNPI: this.props.defaultProviderNPI ? this.props.defaultProviderNPI : "",
      providerDEA: this.props.defaultProviderDEA?.filter(a => a.clinicLocationId === this.props.defaultLocationId)[0]
        ?.dea,
    });
  }

  render() {
    return (
      <div className={`m-sm-auto m-0 flexJCAC flex-column`}>
        <Row className="w-75">
          <p>
            <span className="semiBold">Clinic Name</span>
            <span className="mx-3">{this.props.businessName}</span>
          </p>
        </Row>
        <Row className="mb-4 w-75">
          <ProviderLocationDD
            defaultClinicId={this.props.defaultClinicId ? this.props.defaultClinicId : ""}
            defaultValue={this.props.defaultLocationId ? this.props.defaultLocationId : ""}
            name="locationId"
            edit={this.props.edit}
            updateClinicLocation={this.updateClinicLocation.bind(this)}
            required={this.props.step === 3}
          />
        </Row>
        {this.state.locationId ? (
          <Row className="mb-4 w-75" key={this.state.locationId}>
            <LocationProvidersDD
              updateProviderInfo={this.updateProviderInfo.bind(this)}
              defaultValue={this.props.defaultProviderId ? this.props.defaultProviderId : ""}
              locationId={this.state.locationId}
              name="providerId"
              edit={this.props.edit}
              required={this.props.step === 3}
            />
          </Row>
        ) : (
          <p className="medText my-4">Please select a location</p>
        )}
        <Row className="w-75">
          <p>
            <span className="semiBold">NPI Number</span>
            <span className="mx-3">{this.state.providerNPI}</span>
          </p>
        </Row>
        <Row className="w-75">
          <p>
            <span className="semiBold">DEA Number</span>
            <span className="mx-3">{this.state.providerDEA}</span>
          </p>
        </Row>
      </div>
    );
  }
}

export default VerifyProviderAndLocation;
