const ROWS_LIMIT = [10, 20, 50, 100];
const ADMIN_APPROVED_LISTING = ["AdminRxWorkflowPrescription", "AdminArchivedPrescription"];
const LISTING_NAME = {
  CLINIC_PENDING_APPROVAL: "clinicPending",
  CLINIC_PENDING_NEEDED: "incompleteRx",
  ADMIN_PENDING_RX: "AdminPendingPrescription",
  ADMIN_RX_WORKFLOW: "AdminRxWorkflowPrescription",
  ADMIN_ARCHIVED_RX: "AdminArchivedPrescription",
  PATIENT_SUMMARY: "PatientPrescription",
};

const SUBMITTED_PRESCRIPTION_DIALOG = [
  LISTING_NAME.CLINIC_PENDING_APPROVAL,
  LISTING_NAME.CLINIC_PENDING_NEEDED,
  LISTING_NAME.PATIENT_SUMMARY,
];

const BUTTON_COLOR = {
  error: "error",
  success: "success",
};

export { SUBMITTED_PRESCRIPTION_DIALOG, BUTTON_COLOR, ROWS_LIMIT, ADMIN_APPROVED_LISTING, LISTING_NAME };
