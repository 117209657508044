import React, { useCallback } from "react";
import { useController } from "react-hook-form";
import OutlineSelectField from "./OutlineSelectField";

function FormSelectField({
  control,
  name,
  rules,
  dependentChange,
  MuiFieldProps: { fullWidth = true, label = "", ...restFieldProps },
}) {
  const {
    field: { ref, onChange, ...inputField },
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: "" });

  const onChangeAction = useCallback(
    (...arg) => {
      if (!restFieldProps?.disabled) {
        onChange(...arg);
        dependentChange?.();
      }
    },
    [dependentChange, onChange, restFieldProps?.disabled],
  );

  return (
    <OutlineSelectField
      ref={ref}
      error={error?.message || " "}
      inputField={{ ...inputField, onChange: onChangeAction }}
      isRequired={rules?.required}
      MuiFieldProps={{ fullWidth, label, ...restFieldProps }}
    />
  );
}

export default React.memo(FormSelectField);
