import { useState, useEffect } from "react";

const ReasonForMedicationDD = (props) => {
  const [selectedOption, setSelectedOption] = useState(props.defaultValue ? props.defaultValue : "");
  const [otherOption, setOtherOption] = useState("");
  const optionLabels = [
    "Hypo-allergenic Formulation, Sensitivity",
    "Modified-Release, Decrease side-effects",
    "Excipient Ingredient Sensitivity",
    "Inactive Ingredient Sensitivity",
    "Higher Concentration, Decrease post-injection pain",
    "Alternate administration route",
    "Microdosing",
    "Sustained Released",
    "Unavailable Commercially",
  ];

  useEffect(() => {
    if (optionLabels.find((v) => v === props.defaultValue)) {
      setSelectedOption(props.defaultValue);
    } else if (props.defaultValue) {
      setSelectedOption("other");
      setOtherOption(props.defaultValue);
    } else {
      setSelectedOption("");
    }
  }, [props.defaultValue]);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (value === "other") {
      setSelectedOption("other");
    } else {
      props.updateReasonForCompoundedMedication(value);
      setSelectedOption(value);
    }
  };

  const handleOtherInputChange = (event) => {
    props.updateReasonForCompoundedMedication(event.target.value);
    setOtherOption(event.target.value);
  };

  return (
    <div>
      <select
        id="dropdown"
        name={props.name}
        className="form-control"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        <option value="" disabled selected={props.defaultValue === ""}>
          Select
        </option>
        <option value="Hypo-allergenic Formulation, Sensitivity">Hypo-allergenic Formulation, Sensitivity</option>
        <option value="Modified-Release, Decrease side-effects">Modified-Release, Decrease side-effects</option>
        <option value="Excipient Ingredient Sensitivity">Excipient Ingredient Sensitivity</option>
        <option value="Inactive Ingredient Sensitivity">Inactive Ingredient Sensitivity</option>
        <option value="Higher Concentration, Decrease post-injection pain">
          Higher Concentration, Decrease post-injection pain
        </option>
        <option value="Alternate administration route">Alternate administration route</option>
        <option value="Microdosing">Microdosing</option>
        <option value="Sustained Released">Sustained Released</option>
        <option value="Unavailable Commercially">Unavailable Commercially</option>
        <option value="other">Other</option>
      </select>
      {selectedOption === "other" && (
        <div>
          <label htmlFor="other-input"></label>
          <input
            id="other-input"
            name={props.name}
            className="form-control"
            type="text"
            value={otherOption}
            onChange={handleOtherInputChange}
          />
        </div>
      )}
    </div>
  );
};
export default ReasonForMedicationDD;
