/* eslint-disable react-hooks/exhaustive-deps */
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import {
  ListingSearch,
  MuiTableCollapse,
  OrderViewModal,
  PrescriptionNoteModal,
  PrescriptionUpdateModal,
} from "components";
import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useAdminAcknowledgePrescriptionMutation, useLazyGetAdminPendingPrescriptionQuery } from "rtk";
import { LISTING_NAME, PRESCRIPTION_FILTER_OPTIONS, ROWS_LIMIT, columnHelper } from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import { useAdminPrescriptionAction, useAdminPrescriptionListing } from "../Hook";
import AlertMessage from "../components/alert_message";

const AdminPendingPrescription = () => {
  const {
    rowLimit,
    tablePage,
    showLoading,
    totalRecords,
    errorMessage,
    isReviewModal,
    selectedFilter,
    searchKeyword,
    disableFilter,
    filterRxStatus,
    prescriptionId,
    openNoteModal,
    openUpdateModal,
    prescriptions,
    ADMIN_PENDING_COLUMNS,
    setCustomError,
    toggleNoteModal,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
    updateFilterType,
    toggleDetailModal,
    toggleUpdateModal,
    updateFilterRxStatus,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyGetAdminPendingPrescriptionQuery,
  });

  const { mutateError, mutateData, mutateLoading, toggleConfirmationModal } = useAdminPrescriptionAction({
    listingName: "AdminPendingPrescription",
    listingMutation: useAdminAcknowledgePrescriptionMutation,
  });

  const showSpinner = useMemo(() => showLoading || mutateLoading, [mutateLoading, showLoading]);
  const successMessage = useMemo(() => mutateData?.message ?? "", [mutateData?.message]);
  const combinedErrorMessage = useMemo(
    () => errorMessage || mutateError?.data?.message || "",
    [errorMessage, mutateError?.data?.message],
  );

  const ADMIN_PRESCRIPTION_ACTION = useMemo(
    () =>
      columnHelper.accessor("actions", {
        header: "Actions",
        headerStyle: { minWidth: "140px", width: "150px", textAlign: "center", paddingLeft: 0, paddingRight: 0 },
        cell: props => {
          const prescriptionId = props.row.original.id;
          const disableAction = !props.row.original?.signatureFileUrl;

          return (
            <Box component={"span"}>
              <Tooltip title="Review">
                <IconButton onClick={() => toggleDetailModal(prescriptionId, true)} style={{ width: 35, height: 35 }}>
                  <FileOpenIcon color="warning" style={{ fontSize: "28px" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Approve">
                <IconButton
                  color="success"
                  disabled={disableAction}
                  onClick={() => toggleConfirmationModal(prescriptionId, "Approve")}
                  style={{ width: 35, height: 35 }}>
                  <CheckCircleIcon style={{ fontSize: "28px" }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Disapprove">
                <IconButton
                  color="error"
                  disabled={disableAction}
                  onClick={() => toggleConfirmationModal(prescriptionId, "Disapprove")}
                  style={{ width: 35, height: 35 }}>
                  <CancelIcon style={{ fontSize: "28px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      }),
    [toggleConfirmationModal],
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [...ADMIN_PENDING_COLUMNS, ADMIN_PRESCRIPTION_ACTION],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <div className="noPrint">
        {showSpinner && <Spinner />}
        <Row className="my-3 px-3 ">
          <h5>Filter Prescriptions</h5>
          <Col xs={6} sm={5} lg={2}>
            <div className="mb-3">
              <RXStatusDD
                loc={"pending"}
                filterRX={true}
                clearStatus={disableFilter}
                updatedFilterRxStatusValue={updateFilterRxStatus}
              />
            </div>
          </Col>
          <Col sm={12} md={3} lg={3} className="m-0">
            <button disabled={disableFilter} className="yellowButton" onClick={applyFilter}>
              Filter
            </button>
            {!disableFilter && (
              <i
                disabled={disableFilter}
                className="fas fa-times redText"
                style={{ fontSize: 24, marginLeft: 18 }}
                onClick={clearFilter}
              />
            )}
          </Col>
        </Row>
        <ListingSearch
          searchKeyword={searchKeyword}
          selectedFilter={selectedFilter}
          applySearch={applyFilter}
          clearFilter={clearFilter}
          updateKeyword={updateKeyword}
          updateFilterType={updateFilterType}
          filterOptions={PRESCRIPTION_FILTER_OPTIONS}
        />

        <AlertMessage msg={successMessage} />
        <AlertMessage msg={combinedErrorMessage} isError={true} />
        <Col className="text-end" style={{ marginRight: 15 }}>
          <Tooltip title="Refresh">
            <Button variant="contained" onClick={refetchList}>
              <RefreshIcon style={{ width: 20, height: 20 }} />
            </Button>
          </Tooltip>
        </Col>
        <TableContainer style={{ maxHeight: "70vh" }}>
          <Table size="small" stickyHeader>
            <TableHead>
              {reactTable.getHeaderGroups().map(headerGroup => (
                <TableRow key={headerGroup.id}>
                  <TableCell />
                  {headerGroup.headers.map(header => {
                    return (
                      <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                        <Typography variant="tableHeader" noWrap>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {reactTable.getRowModel().rows.map(row => (
                <MuiTableCollapse
                  key={row.id}
                  row={row}
                  nestedEditable={true}
                  toggleUpdateModal={toggleUpdateModal}
                  pageName={LISTING_NAME.ADMIN_PENDING_RX}
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          showFirstButton
          showLastButton
          page={tablePage}
          count={totalRecords}
          rowsPerPage={rowLimit}
          rowsPerPageOptions={ROWS_LIMIT}
          onPageChange={handleChangePage}
          onRowsPerPageChange={changeRowLimit}
          style={{ alignItems: "center", marginBottom: 0 }}
        />
      </div>
      <OrderViewModal
        showAcknowledge
        // Important to hide the Detail view on editing
        prescriptionId={openUpdateModal || openNoteModal?.createdBy ? "" : prescriptionId}
        isReview={isReviewModal}
        handleClose={toggleDetailModal}
      />
      <PrescriptionUpdateModal
        modalTitle={openUpdateModal}
        prescriptionId={prescriptionId}
        handleClose={toggleUpdateModal}
      />
      <PrescriptionNoteModal
        modalData={openNoteModal}
        pageName={LISTING_NAME.ADMIN_PENDING_RX}
        handleClose={toggleNoteModal}
      />
    </>
  );
};

export default React.memo(AdminPendingPrescription);
