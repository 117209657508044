import React from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Col, Container, Row, UncontrolledAlert } from "reactstrap";
import { useAdminDashboardCountQuery } from "rtk";
import "../../../assets/vpi-styles/dashboard.css";
import AdminNavbar from "../../../components/AdminNavbar";
import Spinner from "../../../components/spinner";

function Dashboard() {
  const { isFetching, data, isError, error } = useAdminDashboardCountQuery();

  return (
    <React.Fragment>
      <AdminNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          {isFetching ? <Spinner /> : null}
          {isError ? (
            <UncontrolledAlert color="danger" className="text-center">
              {`An error occurred while trying to fetch details:  ${error}`}
            </UncontrolledAlert>
          ) : null}
          <Row className="p-1">
            <Col sm={12} md={12} lg={12}>
              <Row className="dashboardCardAdmin m-0">
                <Link to="/pending-prescriptions" className="dashboardCardContentAdmin text-muted">
                  <i className="fas fa-clock yellowText"></i>
                  <div>
                    <p>Pending Prescriptions</p>
                    <p className="number">{data?.pendingPrescriptions || "-"}</p>
                  </div>
                </Link>
                <Link to="/prescription-history" className="dashboardCardContentAdmin text-muted">
                  <i className="fas fa-history pinkText"></i>
                  <div>
                    <p>Total Prescriptions</p>
                    <p className="number">{data?.totalPrescriptions || "-"}</p>
                  </div>
                </Link>
                <Link to="/clinics" className="dashboardCardContentAdmin text-muted">
                  <i className="fas fa-building blueText"></i>
                  <div>
                    <p>Total Clinics</p>
                    <p className="number">{data?.totalClinics || "-"}</p>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/clinics",
                    state: {
                      defaultPendingButton: "blueButton",
                    },
                  }}
                  className="dashboardCardContentAdmin text-muted"
                >
                  <i className="fas fa-clinic-medical yellowText"></i>
                  <div>
                    <p>Clinics awaiting Approval</p>
                    <p className="number">{data?.clinicAwaitingApprovals || "-"}</p>
                  </div>
                </Link>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
