import { Checkbox, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userRoleSelector } from "selectors";
import { USER_ROLES } from "utils";

function PrescriptionCheckBox({
  rowData: { id, shippingInfo, shippingCost, isRecreated = false } = {},
  prescriptionAction,
  tableRow,
}) {
  const history = useHistory();
  const userRole = useSelector(userRoleSelector);
  const isSelected = tableRow.getIsSelected();
  const renderColumn = [];

  if (tableRow.getCanSelect()) {
    renderColumn.push(
      <Checkbox
        key={`box-${id}`}
        color="primary"
        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
        checked={isSelected}
        onChange={(e) => {
          e.stopPropagation();
          tableRow.getToggleSelectedHandler()(e);
          prescriptionAction(shippingInfo, shippingCost);
        }}
      />
    );
    if (userRole !== USER_ROLES.SALES_REP && !isRecreated) {
      renderColumn.push(
        <Tooltip title="Edit" key={`tooltip-${id}`}>
          <IconButton
            onClick={() =>
              history.push({
                pathname: "/edit-prescription/" + id,
                state: { fromRxWorkFlow: true, side: "rxWorkFlow" },
              })
            }
            style={{ width: 30, height: 30 }}
          >
            <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
          </IconButton>
        </Tooltip>
      );
    }
  }

  return <React.Fragment> {renderColumn}</React.Fragment>;
}

export default PrescriptionCheckBox;
