import moment from 'moment';
import { useRef } from 'react';

import { getDaysPassed, isPrescriptionInProgress } from './utils';
import { formatDaySupply } from 'utils';

const useMedicationItem = ({ medication = {}, collapsibleRow = -1, updateCollapsibleState, index = 0, chartStartDate, scrollRightPanel, showRefillModal }) => {
  const { productName = '', family = '', id: medicationId } = medication || {};

  const history = medication.history;
  const sortedHistory = history.sort((a, b) => {
    return moment(a.startDate).diff(b.startDate);
  });
  const isActive = collapsibleRow === index;
  const lastPrescription = sortedHistory[sortedHistory.length - 1];
  const inProgress = isPrescriptionInProgress(lastPrescription.startDate, lastPrescription.endDate);
  const refills = lastPrescription?.refills;
  let progress = 0;
  if (inProgress) {
    const daysPassed = getDaysPassed(lastPrescription.startDate);
    progress = Math.trunc((daysPassed / formatDaySupply(lastPrescription?.daySupply,'i')) * 100);
  }
  const startDates = medication?.history.flatMap((cHistory) => cHistory.startDate);
  const currentToggleDate = useRef({
    date: !!startDates.length ? startDates[0] : new Date().toISOString(),
    index: 0,
  });
  const onClickHandler = () => {
    updateCollapsibleState(index);
  };
  const onToggleClickHandler = (e) => {
    e.stopPropagation();
    const isEndReached = currentToggleDate.current.index === medication?.history.length - 1;
    if (isEndReached) {
      currentToggleDate.current.date = startDates[0];
      currentToggleDate.current.index = 0;
      const difference = Math.abs(moment(chartStartDate).diff(moment(currentToggleDate.current.date), 'days')) - 2;
      scrollRightPanel(difference * 25);
      return;
    }
    currentToggleDate.current.date = startDates[currentToggleDate.current.index + 1];
    currentToggleDate.current.index = currentToggleDate.current.index + 1;
    const diff = Math.abs(moment(chartStartDate).diff(moment(currentToggleDate.current.date), 'days')) - 2;
    scrollRightPanel(diff * 25);
  };

  const onRefillClickHandler = (e) => {
    e.stopPropagation();
    showRefillModal(medicationId, productName, family);
  };

  return {
    isActive,
    productName,
    inProgress,
    progress,
    medicationId,
    refills,
    onClickHandler,
    onToggleClickHandler,
    onRefillClickHandler,
  };
};
export default useMedicationItem;
