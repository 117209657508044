import { ClinicLocationProvider } from "context";
import React from "react";
import InsertClinicLocation from "./InsertClinicLocation";

function ManageClinicLocation() {
  return (
    <ClinicLocationProvider>
      <InsertClinicLocation />
    </ClinicLocationProvider>
  );
}

export default React.memo(ManageClinicLocation);
