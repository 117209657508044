import React, { useCallback } from "react";
import { PatternFormat } from "react-number-format";

const MaskedInput = React.forwardRef(function MaskedInputField(props, ref) {
  const { onChange, mask = "_", ...other } = props;

  const onValueChange = useCallback((valueObj) => {
    onChange(valueObj?.value);
  }, []);

  return <PatternFormat {...other} getInputRef={ref} mask={mask} onValueChange={onValueChange} />;
});

export default React.memo(MaskedInput);
