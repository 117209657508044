import React from "react";
import { Col, Row } from "reactstrap";
import { useGetRecentProductsQuery } from "../../rtk";

function RecentlyOrdered(props) {
  const { data } = useGetRecentProductsQuery();

  return (
    <div>
      <Row>
        {data?.map((product, i) => (
          <Col key={product?.id} md={2} xs={2} s={2} lg={2}>
            <Row>
              <p style={{ fontSize: "10px", textAlign: "center" }}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    props.closeModal();

                    setTimeout(() => {
                      props.addProductPopup(product);
                    }, 200);
                  }}
                  style={{
                    fontSize: "8px",
                    width: "95%",
                    borderRadius: "20px",
                    textAlign: "center",
                    backgroundColor: "#2C4B76",
                    color: "white",
                    padding: "3px",
                  }}
                  className="pointer"
                >
                  ADD TO CART
                </button>
              </p>
              <img
                src="https://i.pinimg.com/564x/84/a8/7e/84a87eb13637e32d5fd67ca7fc2cf2b4.jpg"
                width="20"
                height="70"
              ></img>
            </Row>
            <Row>
              <p style={{ fontSize: "9px", textAlign: "center" }}>{product?.name}</p>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default React.memo(RecentlyOrdered);
