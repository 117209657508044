/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { LISTING_NAME } from "utils";
const RXStatusDD = (props) => {
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    var rxStatuses = [];
    if (props.loc === "workflow") {
      rxStatuses = [
        "NEW ORDER - RTR",
        "CUSTOM ORDER - RTR",
        "HOLD - C",
        "HOLD - M",
        "HOLD - PP",
        "HOLD - REFILL - REMAINING*",
        "HOLD - REFILL - REAUTH",
        "HOLD - FTS",
        "CHECK STOCK",
        "RTC",
        "TASKED",
        "LOT REWORK",
        "IN PROCESS",
        "IN BIN - RTD",
        "DISPENSED - RTV",
        "RX VERIFIED - RTP",
        "RX VERIFIED - STORAGE",
        "RX VERIFIED - FRIDGE",
        "HOLD - PD",
        "RTP",
        "PACK VERIFIED - RTS",
        "RECEIVED",
      ];
    } else if (props.loc === "archive") {
      rxStatuses = [
        "NEW ORDER - RTR",
        "CUSTOM ORDER - RTR",
        "HOLD - C",
        "HOLD - M",
        "HOLD - PP",
        "HOLD - REFILL - REMAINING*",
        "HOLD - REFILL - REAUTH",
        "HOLD - FTS",
        "CHECK STOCK",
        "CANCELLED",
        "RECEIVED",
      ];
    } else if (props.loc === "pending") {
      rxStatuses = [
        "NEW ORDER - RTR",
        "CUSTOM ORDER - RTR",
        "HOLD - C",
        "HOLD - M",
        "HOLD - PP",
        "HOLD - REFILL - REMAINING*",
        "HOLD - REFILL - REAUTH",
        "HOLD - FTS",
        "CHECK STOCK",
      ];
    } else if (props.loc === LISTING_NAME.CLINIC_PENDING_APPROVAL) {
      rxStatuses = ["Pending Approval", "Order On Hold", "Order In Process", "Order Complete"];
    }
    var rxStatusList = [];
    rxStatuses.forEach((status, i) => {
      rxStatusList.push(<option key={status + i}>{status}</option>);
    });
    setSelectedStatus(props.defaultValue);
    setStatuses(rxStatusList);
  }, []);

  useEffect(() => {
    if (props.defaultValue && props.filterRX) {
      setSelectedStatus(props.defaultValue);
    }
    //setStatuses(rxStatusList);
  }, [props.defaultValue, props.filterRX]);

  useEffect(() => {
    if (props.clearStatus) {
      setSelectedStatus("");
    }
  }, [props.clearStatus]);

  function handleChange(e) {
    if (props.filterRX) {
      props.enableFilterButton?.();
      props.updatedFilterRxStatusValue(e.target.value);
    }
    if (!props.filterRX) {
      props.changeRxStatus?.(e.target.value, props.presId);
    }
    setSelectedStatus(e.target.value);
  }

  return (
    <select
      onChange={handleChange}
      value={selectedStatus ? selectedStatus : ""}
      style={{
        borderRadius: "5px",
        borderColor: "#CED4DA",
        width: "120px",
        padding: props.padding ? props.padding : "5px",
      }}
      id="selectedRxStatus"
      type="select"
      name="selectedRxStatus"
    >
      <option style={{ color: "#495057" }} className="form-control" value="" disabled selected>
        RX Status
      </option>
      {statuses}
    </select>
  );
};

export default RXStatusDD;
