import { dateFormatterMountainTime } from "utils/constants";
import { AdminApi } from "../../rtkApi";
import {
  ADMIN_END_POINTS,
  API_METHODS,
  CACHE_TAGS,
  EXPORT_END_POINT,
  downloadFile,
  providesList,
} from "../../rtkConstant";

const AdminPrescriptionQuery = AdminApi.injectEndpoints({
  endpoints: (builder) => ({
    exportRxWorkflowPrescription: builder.query({
      keepUnusedDataFor: 0.1,
      query: (body) => ({
        url: EXPORT_END_POINT.ADMIN_RX_WORKFLOW_REPORT,
        method: API_METHODS.POST,
        body,
        cache: "no-cache",
        responseHandler: (response) =>
          downloadFile(response, `VPI_RxWorkflow(${body.isMasterExport ? "Master" : "Normal"}).csv`),
      }),
    }),
    exportArchivedPrescription: builder.query({
      keepUnusedDataFor: 0.1,
      query: (body) => ({
        url: EXPORT_END_POINT.ADMIN_ARCHIVED_REPORT,
        method: API_METHODS.POST,
        body,
        cache: "no-cache",
        responseHandler: (response) =>
          downloadFile(response, `VPI_Received(${body.isMasterExport ? "Master" : "Normal"}).csv`),
      }),
    }),
    getAdminPendingPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_PENDING_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result) => providesList(result?.prescriptions),
    }),
    adminRxWorkflowPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_HISTORY_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              status: prescription.prescriptionStatus === "Disapproved" ? "No" : "Yes",
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result, error, arg) => [...providesList(result?.prescriptions), CACHE_TAGS.RX_WORKFLOW],
    }),
    adminArchivedPrescription: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 10, userId: user?.userData?.id };
            const response = await baseQuery({
              url: ADMIN_END_POINTS.ADMIN_ARCHIVED_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            response.data["prescriptions"] = response?.data?.prescriptions?.map((prescription) => ({
              ...prescription,
              date: dateFormatterMountainTime(prescription.prescriptionDate),
              status: prescription.prescriptionStatus === "Disapproved" ? "No" : "Yes",
              shipTo:
                prescription?.shippingInfo?.shippingMethod === "PickUp"
                  ? prescription?.shippingInfo?.shippingMethod
                  : `${prescription?.shippingInfo?.shippingAddress?.addressLine1}, ${prescription?.shippingInfo?.shippingAddress?.addressLine2} ${prescription?.shippingInfo?.shippingAddress?.city}, ${prescription?.shippingInfo?.shippingAddress?.state}, ${prescription?.shippingInfo?.shippingAddress?.zipcode}`,
            }));
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: (result, error, arg) => [...providesList(result?.prescriptions), CACHE_TAGS.ARCHIVED],
    }),
  }),
});
export const {
  useLazyExportArchivedPrescriptionQuery,
  useLazyAdminArchivedPrescriptionQuery,
  useLazyExportRxWorkflowPrescriptionQuery,
  useLazyGetAdminPendingPrescriptionQuery,
  useLazyAdminRxWorkflowPrescriptionQuery,
} = AdminPrescriptionQuery;
