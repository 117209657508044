/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { useAdminUpdateRxOrderInputMutation } from "rtk";
import { userRoleSelector } from "selectors";
import { USER_ROLES } from "utils";

function PrescriptionOrderInput({ id, rxOrderInput = false, setCustomError, patientId = "" }) {
  const userRole = useSelector(userRoleSelector);
  const switchRef = useRef(null);
  const [updateOrderInput, { error: orderInputError, isLoading: orderInputLoading, reset: resetMutate }] =
    useAdminUpdateRxOrderInputMutation();

  useEffect(() => {
    if (orderInputError?.data?.message) {
      setCustomError?.(orderInputError?.data?.message);
      switchRef.current.checked = rxOrderInput;
    } else {
      setCustomError?.("");
    }
  }, [orderInputError?.data?.message, rxOrderInput, setCustomError]);

  const updateRxInput = useCallback(
    e => {
      e.stopPropagation();
      const apiParam = { prescriptionId: id, rxOrderInput: e.target.checked };
      if (patientId) {
        apiParam["patientId"] = patientId;
      }
      resetMutate();
      updateOrderInput(apiParam);
    },
    [id, patientId, updateOrderInput],
  );

  const loading = useMemo(() => {
    if (orderInputLoading) {
      return (
        <Spinner size="sm" color="primary" style={{ marginLeft: "0.5rem" }}>
          ...
        </Spinner>
      );
    } else {
      return <span style={{ width: "1.5rem" }} />;
    }
  }, [orderInputLoading]);

  const switchProps = useMemo(() => {
    const props = {
      type: "checkbox",
      id: "rx-order-input-" + id,
      defaultChecked: rxOrderInput,
    };
    if (
      userRole === USER_ROLES.ADMIN_SUPER_USER ||
      userRole === USER_ROLES.PHARMACIST ||
      userRole === USER_ROLES.RX_TECH
    ) {
      props["onChange"] = updateRxInput;
    }
    return props;
  }, [id, rxOrderInput, updateRxInput, userRole]);

  return (
    <div className="d-flex align-items-center">
      <label className="switch mb-0">
        <input ref={switchRef} {...switchProps} />
        <span className="slider round"></span>
      </label>
      {loading}
    </div>
  );
}
export default React.memo(PrescriptionOrderInput);
