import React, { useState } from "react";
import MedicationItem from "./medicationItem";

const MedicationList = ({
  medications = [],
  updateCollapsibleRow,
  chartStartDate,
  scrollRightPanel,
  showRefillModal
}) => {
  const [collapsibleRow, setCollapseRow] = useState(-1);
  const updateCollapsibleState = (index) => {
    if (index === collapsibleRow) {
      setCollapseRow(-1);
      updateCollapsibleRow(-1);
    } else {
      setCollapseRow(index);
      updateCollapsibleRow(index);
    }
  };

  return (
    <div className="medication-list">
      {medications.map((cMedication, index) => (
        <MedicationItem
          medication={cMedication}
          index={index}
          collapsibleRow={collapsibleRow}
          chartStartDate={chartStartDate}
          updateCollapsibleState={updateCollapsibleState}
          scrollRightPanel={scrollRightPanel}
          showRefillModal={showRefillModal}
        />
      ))}
    </div>
  );
};

export default MedicationList;
