import axiosConfig, { errorResponse } from './utils/axiosConfig';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;

const AddStaffListToChat = (props) => {
  const adminList = useRef([]);
  const staffList = useRef([]);
  const [clinicStaff, setClinicStaff] = useState([]);
  const [error, setError] = useState('');
  const [clinicList, setClinicList] = useState([]);

  const [currentClinic, setCurrentClinic] = useState('');

  useEffect(() => {
    getClinics();
  }, []);

  function handleValidSubmit(e) {
    oldStaffToChat(clinicStaff, adminList.current, staffList.current);
  }

  async function oldStaffToChat(staffs, listOfAdmins, listOfStaff) {
    var friendsList = listOfAdmins;
    var friendsList1 = listOfStaff;
    //friendsList.push(...friendsList1);
    for (var j = 0; j < friendsList1.length; j++) {
      friendsList.push(friendsList1[j]);
    }

    for (var i = 0; i < staffs.length; i++) {
      await addUserToCometChat(staffs[i].firstName + ' ' + staffs[i].lastName, staffs[i].id, currentClinic.name, staffs[i].role);
      await addFriendsByChunking(friendsList, staffs[i]);
    }
  }
  async function addFriendsByChunking(friendsList, admin) {
    if (friendsList.length <= 15) {
      await makeFriends(admin.id, friendsList);
    } else {
      var j = 0;
      var chunksListDivisor = parseInt(friendsList.length / 15);
      var withoutRemainderVal = chunksListDivisor * 15;
      var remainder = friendsList.length % 15;
      var k = 0;
      if (friendsList.length % 15 == 0) {
        for (i = 0; i < chunksListDivisor; i++) {
          var chunkFriendsArray = [];
          for (j = 0; j < 15; j++) {
            chunkFriendsArray.push(friendsList[k]);
            k++;
          }
          await makeFriends(admin.id, chunkFriendsArray);
        }
      } else {
        for (i = 0; i < chunksListDivisor; i++) {
          var chunkFriendsArray = [];
          //Push 15 Length of Array
          for (j = 0; j < 15; j++) {
            if (k < withoutRemainderVal) {
              chunkFriendsArray.push(friendsList[k]);
            }

            k++;
          }

          await makeFriends(admin.id, chunkFriendsArray);
        }

        var chunkFriendsArray1 = [];

        for (var i = 0; i < remainder; i++) {
          chunkFriendsArray1.push(friendsList[k]);
        }

        await makeFriends(admin.id, chunkFriendsArray1);
      }
    }
  }

  // COMETCHAT ADD ADMIN COMPLETE APIS FUNCTIONS
  async function addUserToCometChat(name, uid, clinicName, role) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users`;
    const bodyVal = {
      uid: uid,
      name: name,
      role: role.toLowerCase(),

      metadata: {
        businessName: clinicName,
        role: role,
      },
    };
    await axios
      .post(url, bodyVal, {
        headers: {
          apiKey: cometChatApiKey,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(async (response) => {})
      .catch((error) => {
        //setError(error.response.data.message);
        //setShowSpinner(false);
      });
  }

  function getClinics() {
    var obj = {
      limit: 20,
      currentPage: 1,
    };

    axiosConfig
      .post('clinics/getClinics', obj, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        var data1 = [];
        for (var i = 0; i < response.data.pagination.totalPages; i++) {
          obj = {
            limit: 20,
            currentPage: i + 1,
          };
          await axiosConfig
            .post('clinics/getClinics', obj, {
              headers: {
                Authorization: 'Bearer ' + new Cookies().get('user_token'),
              },
            })
            .then(async (response) => {
              await response.data.clinics.forEach((clinic, i) => {
                data1.push({
                  id: clinic.id,
                  name: clinic.businessName,
                  index: i,
                });
              });
            })
            .catch((error) => {
              errorResponse(error);
            });
        }
        await setClinicList(data1);
        await setCurrentClinic(data1[0]);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }
  async function getStaffList(cId, limit = 10, pageNo = 1) {
    var staffArray = [];
    var staffData = [];
    var body = {
      clinicId: cId,
      limit: limit,
      currentPage: pageNo,
    };
    await axiosConfig
      .post('staffs/getAllStaffInClinic', body, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        /*Loop*/
        for (var i = 0; i < response.data.pagination.totalPages; i++) {
          body = {
            clinicId: cId,
            limit: 20,
            currentPage: i + 1,
          };
          await axiosConfig
            .post('staffs/getAllStaffInClinic', body, {
              headers: {
                Authorization: 'Bearer ' + new Cookies().get('user_token'),
              },
            })
            .then(async (response) => {
              await response.data.staff.forEach((staff) => {
                staffArray.push(staff.id);
                staffData.push(staff);
              });
            })
            .catch((error) => {
              //setError('An error occurred while trying to fetch admins list!');
              errorResponse(error);
            });
        }
        /*End Loop*/
        staffList.current = staffArray;

        setClinicStaff(staffData);
      })
      .catch((error) => {
        //setError('An error occurred while trying to fetch clinic locations!');
        //setShowSpinner(false);
        errorResponse(error);
      });
  }
  async function getAdminList(sizePerPage = 10, pageNo = 1) {
    var body = {
      limit: sizePerPage,
      currentPage: pageNo,
    };

    var adminArray = [];
    var adminFullData = [];
    await axiosConfig
      .post('admin/account/getAllAdminIds', body, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        /*Loop*/

        for (var i = 0; i < response.data.pagination.totalPages; i++) {
          body = {
            limit: 10,
            currentPage: i + 1,
          };
          await axiosConfig
            .post('admin/account/getAllAdminIds', body, {
              headers: {
                Authorization: 'Bearer ' + new Cookies().get('user_token'),
              },
            })
            .then(async (response) => {
              await response.data.admins.forEach((admin) => {
                adminArray.push(admin.id);
                adminFullData.push(admin);
              });
            })
            .catch((error) => {
              errorResponse(error);
            });
        }

        adminList.current = adminArray;

        /*End Loop*/
      })
      .catch((error) => {
        //setError('An error occurred while trying to fetch admins list!');
        errorResponse(error);
      });
  }
  function getClinicData(val) {
    setCurrentClinic({
      id: clinicList[val].id,
      name: clinicList[val].name,
      index: val + 1,
    });
  }

  async function makeFriends(uid, friendsArray) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}/friends`;
    const bodyVal = {
      accepted: friendsArray,
    };
    await axios
      .post(url, bodyVal, {
        headers: {
          apiKey: cometChatApiKey,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
      .then(async (response) => {})
      .catch((error) => {
        //setError(error.response.data.message);
      });
  }
  return (
    <div>
      <p>
        Clinic Name: {currentClinic ? currentClinic.name : 'No Data'} | Clinic Id:{currentClinic ? currentClinic.id : 'No Data'}
      </p>

      <button
        id='clinicData'
        style={{ cursor: 'pointer' }}
        onClick={(e) => {
          if (currentClinic) {
            getAdminList();
            return getClinicData(currentClinic.index);
          }
        }}
      >
        Get Clinic Data
      </button>

      {currentClinic ? (
        <>
          <button
            id='getClinicStaff'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              return getStaffList(currentClinic.id);
            }}
          >
            {currentClinic.name} Staff Data
          </button>
        </>
      ) : null}

      <button
        id='addToChat'
        onClick={() => {
          return handleValidSubmit();
        }}
      >
        Add Clinic Staff to CometChat
      </button>
    </div>
  );
};

export default AddStaffListToChat;
