import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { AlertMessage, ListingSearch } from "components";
import NavBar from "components/NavBar";
import Spinner from "components/spinner";
import React, { useCallback, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useDeleteClinicLocationMutation, useLazyGetClinicLocationsQuery } from "rtk";
import { userRoleSelector } from "selectors";
import { CLINIC_LOCATION_COLUMNS, ROWS_LIMIT, USER_ROLES, columnHelper } from "utils";
import { useClinicLocationAction, useClinicLocationListing } from "./Hook";

function ClinicLocationList() {
  const history = useHistory();
  const userRole = useSelector(userRoleSelector);
  const {
    clinicId,
    rowLimit,
    tablePage,
    showSpinner,
    errorMessage,
    totalRecords,
    isClinicSide,
    clinicLocationListing,
    searchKeyword,
    setSorting,
    applyFilter,
    clearFilter,
    refetchList,
    updateKeyword,
    changeRowLimit,
    handleChangePage,
  } = useClinicLocationListing({
    listingName: "ClinicLocationListing",
    listingQuery: useLazyGetClinicLocationsQuery,
  });

  const { mutateError, mutateLoading, successMessage, toggleConfirmationModal, setPrimaryClinicLocation } =
    useClinicLocationAction({
      listingName: "ClinicLocationListing",
      listingMutation: useDeleteClinicLocationMutation,
    });

  const combineLoading = useMemo(() => showSpinner || mutateLoading, [mutateLoading, showSpinner]);
  const combinedErrorMessage = useMemo(() => errorMessage || mutateError || "", [errorMessage, mutateError]);

  const actonButtonCell = useCallback(
    rowData => {
      return (
        <Box component={"span"} className="d-flex justify-content-center">
          <Link
            to={{
              pathname: "/clinic-location/" + rowData.row.original.id,
              state: { clinicId },
            }}
            className="pinkButton">
            Profile
          </Link>

          {userRole !== USER_ROLES.RX_TECH && !rowData.row.original.isPrimaryLocation ? (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => toggleConfirmationModal(rowData.row.original.id)}
                style={{ width: 30, height: 30 }}>
                <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <div style={{ width: 30,height:1}} />
          )}
        </Box>
      );
    },
    [clinicId, userRole, toggleConfirmationModal],
  );

  const columnAction = useMemo(
    () => [
      columnHelper.accessor("isPrimaryLocation", {
        header: "Primary",
        headerStyle: { textAlign: "center" },
        cell: ({ row }) => {
          return (
            <Radio
              value={row.original.id}
              style={{ fontSize: 20 }}
              checked={row.original.isPrimaryLocation}
              onChange={event => setPrimaryClinicLocation(clinicId ? clinicId : isClinicSide, event.target.value)}
            />
          );
        },
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        headerStyle: { textAlign: "center" },
        enableSorting: false,
        cell: actonButtonCell,
      }),
    ],
    [actonButtonCell, clinicId, setPrimaryClinicLocation],
  );

  const clinicLocationTableHeader = useMemo(
    () => [...CLINIC_LOCATION_COLUMNS, ...columnAction].filter(Boolean),
    [columnAction],
  );

  const reactTable = useReactTable({
    data: clinicLocationListing,
    columns: clinicLocationTableHeader,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: row => row.id,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
  });

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Practice Locations | VPI Compounding</title>
        </MetaTags>
        <Container disableGutters maxWidth="false" className="px-1">
          <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <h4 className="p-3 text-muted" style={{ textTransform: "capitalize" }}>
            PRACTICE LOCATIONS
          </h4>
          <Grid item />
          {combineLoading && <Spinner />}
          <AlertMessage msg={combinedErrorMessage || successMessage} isError={combinedErrorMessage} />
          <Paper className="p-3 mt-2 mx-1" elevation={1}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
              <Grid item>
                <ListingSearch
                  searchKeyword={searchKeyword}
                  applySearch={applyFilter}
                  clearFilter={clearFilter}
                  updateKeyword={updateKeyword}
                />
              </Grid>
              <Grid item>
                <Link
                  to={{
                    pathname: "/add-location",
                    state: {
                      clinicId: clinicId,
                    },
                  }}
                  className="pinkButton">
                  Add Location
                </Link>
              </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }} className="my-3">
              <Tooltip title="Refresh">
                <Button variant="contained" onClick={refetchList}>
                  <RefreshIcon style={{ width: 20, height: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            <TableContainer style={{ maxHeight: "70vh" }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  {reactTable.getHeaderGroups().map(headerGroup => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                            <Typography variant="tableHeader" noWrap>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {reactTable.getRowModel().rows.map(row => (
                    <TableRow key={row.id} sx={{ "& > *": { borderBottom: "unset" } }}>
                      {row?.getVisibleCells()?.map(cell => {
                        return (
                          <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {clinicLocationListing?.length === 0 && !showSpinner ? (
                <p className="w-100 text-center m-5">No clinic location found!</p>
              ) : null}
            </TableContainer>
            <TablePagination
              component="div"
              showFirstButton
              showLastButton
              page={tablePage}
              count={totalRecords}
              rowsPerPage={rowLimit}
              rowsPerPageOptions={ROWS_LIMIT}
              onPageChange={handleChangePage}
              onRowsPerPageChange={changeRowLimit}
              style={{ alignItems: "center", marginBottom: 0 }}
            />
          </Paper>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(ClinicLocationList);
