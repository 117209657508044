import { Button, Card, CardActions, CardContent, Container, Grid, Typography } from "@mui/material";
import { AlertMessage, FormStepper } from "components";
import NavBar from "components/NavBar";
import MyLottie from "components/lottie";
import Spinner from "components/spinner";
import { ClinicLocationFormActionContext, ClinicLocationFormSateContext, ClinicLocationStateContext } from "context";
import React, { useCallback, useContext, useMemo } from "react";
import { MetaTags } from "react-meta-tags";
import { useHistory } from "react-router-dom";
import { TabContent, TabPane } from "reactstrap";
import { CLINIC_LOCATION_STEPPER, STEPPER_FORMS } from "utils";
import {
  ClinicLocationCard,
  ClinicLocationContact,
  ClinicLocationNotification,
  ClinicLocationPracticeInfo,
} from "../ClinicLocationInfo";

function InsertClinicLocation() {
  const {
    mutateError,
    showSpinner,
    isClinicLocationEdit,
    clinicLocationInsertSuccessfully = false,
  } = useContext(ClinicLocationStateContext);
  const history = useHistory();
  const { formRef, completedStep, activeStep } = useContext(ClinicLocationFormSateContext);
  const { goNext, goBack, formData, progressBarAction } = useContext(ClinicLocationFormActionContext);

  const goBrowserBack = useCallback(e => {
    e?.preventDefault();
    history.goBack();
  }, []);

  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);
  const buttonText = useMemo(() => {
    let text = "Next";
    if (isLastForm) {
      text = "Add Clinic Location";
      if (isClinicLocationEdit) {
        text = "Update Clinic Location";
      }
    }
    return text;
  }, [isLastForm, isClinicLocationEdit]);

  const backButtonView = useMemo(() => {
    if (activeStep > 0) {
      return (
        <Button
          variant="outlined"
          size="large"
          className="muiLightButton w-25  mx-2"
          style={{ borderColor: "red", borderWidth: 1 }}
          onClick={goBack}>
          Back
        </Button>
      );
    }
  }, [activeStep, goBack]);

  const successMessage = useMemo(() => {
    if (clinicLocationInsertSuccessfully) {
      return (
        <Card elevation={2} className="pb-5">
          <CardContent className="justify-content-center">
            <MyLottie name="success-check" />
            <Typography variant="h6" align="center">
              Your clinic location information has been updated!
            </Typography>
          </CardContent>
          <CardActions className="justify-content-center">
            <Button variant="contained" onClick={goBrowserBack} className="pinkButton">
              Clinic Location
            </Button>
          </CardActions>
        </Card>
      );
    }
  }, [goBrowserBack, clinicLocationInsertSuccessfully]);

  return (
    <>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>{`${isClinicLocationEdit ? "Edit Clinic Location" : "Add Clinic Location"} | VPI Compounding`}</title>
        </MetaTags>
        <Container maxWidth={false} disableGutters>
          <h4 className="p-3 text-muted">{isClinicLocationEdit ? "EDIT CLINIC LOCATION" : "ADD CLINIC LOCATION"}</h4>
          {successMessage}
          <Card elevation={1} hidden={clinicLocationInsertSuccessfully}>
            <Spinner showSpinner={showSpinner} />
            <CardContent>
              <AlertMessage msg={mutateError} isError={mutateError} />
              <FormStepper
                stepper={CLINIC_LOCATION_STEPPER}
                completedStep={completedStep}
                activeStep={activeStep}
                clickAction={progressBarAction}
              />
              <Grid container justifyContent={"center"}>
                <Grid item xs={10}>
                  <TabContent activeTab={activeStep}>
                    <TabPane tabId={0}>
                      <ClinicLocationPracticeInfo
                        ref={el => (formRef.current[0] = el)}
                        formId={STEPPER_FORMS.PERSONAL_INFO}
                        formData={formData}
                      />
                    </TabPane>
                    <TabPane tabId={1}>
                      <ClinicLocationCard
                        ref={el => (formRef.current[1] = el)}
                        formId={STEPPER_FORMS.CREDIT_INFO}
                        formData={formData}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <ClinicLocationNotification
                        ref={el => (formRef.current[2] = el)}
                        formId={STEPPER_FORMS.NOTIFICATION_INFO}
                        formData={formData}
                      />
                    </TabPane>
                  </TabContent>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className="justify-content-center my-5">
              {backButtonView}
              <Button color="secondary" variant="contained" size="large" className="w-25 mx-2" onClick={goNext}>
                {buttonText}
              </Button>
            </CardActions>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default React.memo(InsertClinicLocation);
