import React from "react";

function NavigationSteps({ currentStep = 1, side = "" }) {
  return (
    <div className={`w-100 text-center my-4 stepsPillsContainer`}>
      <div className="flexJCAC">
        <div className="rounded-pill" style={{ width: "200px" }}>
          <div className="activeProgressNumberCircle">
            <p>1</p>
          </div>
          <p className="m-0"> {side === "product-search" ? "Search" : "Add"} </p>
          <p> {side === "product-search" ? "Products" : "Patients"}</p>
        </div>
        <div className="rounded-pill" style={{ width: "200px" }}>
          <div className={currentStep >= 2 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
            <p>2</p>
          </div>
          <p className="m-0"> {side === "product-search" ? "Add" : "Search"} </p>
          <p> {side === "product-search" ? "Patients" : "Products"}</p>
        </div>
        <div className="rounded-pill" style={{ width: "200px" }}>
          <div className={currentStep >= 3 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
            <p>3</p>
          </div>
          <p className="m-0">Verify Provider</p>
          <p>& Location</p>
        </div>
        <div className="rounded-pill" style={{ width: "200px" }}>
          <div className={currentStep >= 4 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
            <p>4</p>
          </div>
          <p className="m-0">Add Shipping</p>
          <p>Information</p>
        </div>
        <div className="rounded-pill" style={{ width: "200px" }}>
          <div className={currentStep >= 5 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
            <p>5</p>
          </div>
          <p className="m-0">Add Billing</p>
          <p>Information</p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(NavigationSteps);
