import { FormControl, FormHelperText, InputLabel, OutlinedInput } from "@mui/material";
import { AutoCompleteStyled } from "components/StyledComponent";
import { trim } from "lodash";
import React, { useCallback } from "react";
import { useController } from "react-hook-form";

const defaultMuiFieldProps = { label: "", fullWidth: true, type: "text", shrink: false, isMasked: false };

function FromAutoComplete({ control, name, rules, dependentChange, MuiFieldProps = defaultMuiFieldProps }) {
  const {
    label = "",
    fullWidth = true,
    disabled = false,
    optionArray = [],
    size = "medium",
    ...restFieldProps
  } = MuiFieldProps;
  const {
    field: { ref, onChange, ...inputField },
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: "" });

  const onChangeAction = useCallback(
    (event, newValue) => {
      if (!disabled) {
        onChange(newValue?.value);
        dependentChange?.();
      }
    },
    [dependentChange, onChange, disabled],
  );

  return (
    <AutoCompleteStyled
      ref={ref}
      {...inputField}
      blurOnSelect
      disableClearable
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      options={optionArray}
      onChange={onChangeAction}
      renderInput={({ InputProps, inputProps, ...params }) => (
        <FormControl {...params} error={Boolean(trim(error))}>
          <InputLabel shrink={restFieldProps?.shrink} htmlFor={label}>
            {label}
            {rules?.required && <span className="requiredStar"> *</span>}
          </InputLabel>
          <OutlinedInput
            {...InputProps}
            notched={restFieldProps?.shrink}
            fullWidth={fullWidth}
            inputProps={{
              ...inputProps,
            }}
            label={`${label} ${rules?.required ? "*" : ""}`}
          />
          <FormHelperText variant="outlined" style={{ fontSize: 10 }}>
            {error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

export default React.memo(FromAutoComplete);
