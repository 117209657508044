import { Checkbox } from "@mui/material";
import { useCallback, useMemo } from "react";

function InvoiceCheckBox({ rowData = {}, tableRow, onRowSelection }) {
  const isSelectable = useMemo(() => tableRow.getCanSelect(), [tableRow]);
  const isSelected = tableRow.getIsSelected();

  const onChange = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSelectable) {
        tableRow.getToggleSelectedHandler()(e);
      }
      onRowSelection?.(rowData, isSelected);
    },
    [isSelectable, isSelected, onRowSelection, rowData, tableRow]
  );

  return (
    <Checkbox
      key={`box-${rowData?.id}`}
      color="primary"
      disabled={!isSelectable}
      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
      checked={isSelected}
      onChange={onChange}
    />
  );
}

export default InvoiceCheckBox;
