import { set } from "lodash";
import { STEPPER_FORMS } from "utils";
import { ClinicLocationApi } from "../../rtkApi";
import {
  API_METHODS,
  CACHE_TAGS,
  CLINIC_LOCATION_END_POINT,
  CLINIC_LOCATION_FORM_TO_ENDPOINT,
  PAGE_TO_ENDPOINT,
} from "../../rtkConstant";
import { UploadsApiEndpoints } from "../Uploads";

const ClinicLocationMutation = ClinicLocationApi.injectEndpoints({
  endpoints: builder => ({
    deleteClinicLocation: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: CLINIC_LOCATION_END_POINT.DELETE_CLINIC_LOCATION,
              method: API_METHODS.DELETE,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", clinicLocationId = "" }, { queryFulfilled, dispatch, getState }) {
        try {
          if (pageName) {
            await queryFulfilled;

            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = ClinicLocationApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.CLINIC_LOCATION, id: clinicLocationId },
            ]);

            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }

            dispatch(
              ClinicLocationApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache.originalArgs, listData => {
                return {
                  ...listData,
                  clinicLocations: listData.clinicLocations?.filter(record => record?.id !== clinicLocationId),
                };
              }),
            );
          }
        } catch {}
      },
    }),

    setPrimaryClinicLocation: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: CLINIC_LOCATION_END_POINT.CLINIC_PRIMARY_LOCATION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", clinicLocationId = "" }, { queryFulfilled, dispatch, getState }) {
        try {
          if (pageName) {
            await queryFulfilled;

            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = ClinicLocationApi.util.selectInvalidatedBy(getState(), [
              { type: CACHE_TAGS.CLINIC_LOCATION, id: clinicLocationId },
            ]);

            if (queryCache.length > 1) {
              queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
            } else {
              queryCache = queryCache[0];
            }

            dispatch(
              ClinicLocationApi.util.updateQueryData(PAGE_TO_ENDPOINT[pageName], queryCache.originalArgs, listData => {
                return {
                  ...listData,
                  clinicLocations: listData?.clinicLocations?.map(clinicLocation => ({
                    ...clinicLocation,
                    isPrimaryLocation: clinicLocationId === clinicLocation?.id,
                  })),
                };
              }),
            );
          }
        } catch {}
      },
    }),

    insertClinicLocation: builder.mutation({
      queryFn: async (apiArgs, { dispatch, getState }, _, baseQuery) => {
        try {
          const user = getState().AuthSlice || {};
          if (user?.userData) {
            const clinicId = apiArgs?.clinicId || user?.userData?.clinicId;
            const organizedBody = {
              ...apiArgs,
              clinicId,
              userId: user?.userData?.id,
            };
            const url = apiArgs?.clinicLocationId
              ? CLINIC_LOCATION_END_POINT.EDIT_CLINIC_LOCATION
              : CLINIC_LOCATION_END_POINT.ADD_CLINIC_LOCATION;

            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          return {
            error: {
              data: { message: error?.data?.message ?? "Unknown error occur" },
            },
          };
        }
      },
      async onQueryStarted(apiArgs, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          if (apiArgs?.clinicLocationId) {
            dispatch(
              ClinicLocationApi.util.invalidateTags([
                { type: CACHE_TAGS.CLINIC_LOCATION, id: apiArgs?.clinicLocationId },
              ]),
            );
          } else {
            dispatch(ClinicLocationApi.util.invalidateTags([CACHE_TAGS.CLINIC_LOCATION]));
          }
        } catch {}
      },
    }),

    updateClinicLocation: builder.mutation({
      queryFn: async ({ formType, ...apiArgs }, { dispatch, getState }, _, baseQuery) => {
        try {
          const user = getState().AuthSlice || {};
          if (user?.userData) {
            const clinicId = apiArgs?.clinicId || user?.userData?.clinicId;
            const organizedBody = {
              ...apiArgs,
              clinicId,
              userId: user?.userData?.id,
            };
            const url = CLINIC_LOCATION_FORM_TO_ENDPOINT[formType];

            //Uploading File
            if (
              formType === STEPPER_FORMS.ADDRESS_INFO &&
              organizedBody.clinicLocation?.companyLogoImageUrl instanceof File
            ) {
              const logoFormData = new FormData();
              logoFormData.append("file", organizedBody.clinicLocation?.companyLogoImageUrl);
              const fileUploadedResult = await dispatch(UploadsApiEndpoints.uploadFile.initiate(logoFormData)).unwrap();
              if (fileUploadedResult?.accessUrl) {
                set(organizedBody, ["clinicLocation", "companyLogoImageUrl"], fileUploadedResult?.accessUrl);
              }
            }

            const response = await baseQuery({
              url: url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          return {
            error: {
              data: { message: error?.data?.message ?? "Unknown error occur" },
            },
          };
        }
      },
      invalidatesTags: (result, error, { clinicLocationId }) => [
        { type: CACHE_TAGS.CLINIC_LOCATION, id: clinicLocationId },
      ],
    }),
  }),
});

export const {
  useInsertClinicLocationMutation,
  useUpdateClinicLocationMutation,
  useSetPrimaryClinicLocationMutation,
  useDeleteClinicLocationMutation,
} = ClinicLocationMutation;
