import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import { set } from "lodash";
import React, { useMemo, useState } from "react";
import { LISTING_NAME } from "utils";
import { MuiNestedTable } from "../MuiNestedTable";

function MuiTableCollapse({
  row,
  pageName = "",
  showNested = true,
  readOnly = false,
  showColoredRows = true,
  nestedEditable = false,
  showMarkAllBtn = false,
  filterRxStatus = "",
  setCustomError,
  toggleUpdateModal,
}) {
  const [open, setOpen] = useState(false);

  const rowStyle = useMemo(() => {
    const style = {};
    if (showColoredRows) {
      if (pageName !== LISTING_NAME.ADMIN_ARCHIVED_RX && row.original?.shippingInfo?.isRushOrder) {
        set(style, "backgroundColor", "#e4bebc");
      }
      if (row.original.isRecreated) {
        set(style, "backgroundColor", "#d5eaf2");
        if (row.original?.shippingInfo?.isRushOrder) {
          set(style, "backgroundColor", "#e4bebc");
        }
      }
    }
    return style;
  }, [row.original.isRecreated, row.original?.shippingInfo?.isRushOrder, showColoredRows]);

  const nestedRowStyle = useMemo(() => {
    const style = {};
    if (showColoredRows) {
      if (pageName !== LISTING_NAME.ADMIN_ARCHIVED_RX && row.original?.shippingInfo?.isRushOrder) {
        set(style, "backgroundColor", "#f3e1e0");
      }
      if (row.original.isRecreated) {
        set(style, "backgroundColor", "#e8f4f8");
        if (row.original?.shippingInfo?.isRushOrder) {
          set(style, "backgroundColor", "#f3e1e0");
        }
      }
    }
    return style;
  }, [row.original.isRecreated, row.original?.shippingInfo?.isRushOrder, showColoredRows]);

  const leftIcon = useMemo(() => {
    if (showNested) {
      return (
        <TableCell style={{ paddingRight: 0, paddingLeft: 0 }}>
          <IconButton onClick={() => setOpen(!open)} style={{ width: 30, height: 30 }}>
            {open ? (
              <KeyboardArrowDownIcon style={{ width: 20, height: 20 }} />
            ) : (
              <KeyboardArrowRightIcon style={{ width: 20, height: 20 }} />
            )}
          </IconButton>
        </TableCell>
      );
    }
  }, [open, showNested]);

  const collapseView = useMemo(() => {
    if (showNested) {
      return (
        <TableRow style={nestedRowStyle}>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0, borderBottom: open ? undefined : "none" }}
            colSpan={row.getVisibleCells().length + 1} // 1 cell for showing arrow icon
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {open && (
                <MuiNestedTable
                  readOnly={readOnly}
                  pageName={pageName}
                  originalRow={row.original}
                  toggleUpdateModal={toggleUpdateModal}
                  nestedEditable={nestedEditable}
                  showMarkAllBtn={showMarkAllBtn}
                  filterRxStatus={filterRxStatus}
                  setCustomError={setCustomError}
                />
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      );
    }
  }, [
    showNested,
    nestedRowStyle,
    open,
    row,
    readOnly,
    pageName,
    toggleUpdateModal,
    nestedEditable,
    showMarkAllBtn,
    filterRxStatus,
    setCustomError,
  ]);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} style={rowStyle}>
        {leftIcon}
        {row?.getVisibleCells()?.map(cell => {
          return (
            <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        })}
      </TableRow>
      {collapseView}
    </React.Fragment>
  );
}

export default React.memo(MuiTableCollapse);
