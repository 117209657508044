/* eslint-disable react-hooks/exhaustive-deps */
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { MuiTableCollapse } from "components";
import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import { usePatientPrescriptionListing } from "screens/patient/Hooks";
import { PATIENT_PRESCRIPTION_COLUMNS, ROWS_LIMIT } from "utils";

function PatientPrescriptionListing({ setPageError, setPageLoading, listingQuery }) {
  const {
    toDate,
    fromDate,
    rowLimit,
    tablePage,
    searchKeyword,
    showLoading,
    disableFilter,
    errorMessage,
    prescriptions,
    totalRecords,
    refetchList,
    handleChangePage,
    applyFilter,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    updateFilterValue,
  } = usePatientPrescriptionListing({ listingQuery });

  const reactTable = useReactTable({
    data: prescriptions,
    columns: PATIENT_PRESCRIPTION_COLUMNS,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    setPageLoading(showLoading);
    setPageError(errorMessage);
  }, [errorMessage, setPageError, setPageLoading, showLoading]);

  return (
    <>
      <Row className="my-4 px-sm-3 px-1">
        <h5>Search or Filter</h5>
        <Col xs={12} sm={20} lg={2}>
          <div className="tableSearch d-flex">
            <i className="bx bx-search-alt search-icon flex align-self-center mt-4" />
            <input
              value={searchKeyword}
              placeholder="Search table"
              className="borderless mx-2 mt-4"
              onChange={updateKeyword}
              onKeyDown={e => {
                if (e.key === "Enter") {
                  applyFilter(e);
                }
              }}
            />
          </div>
        </Col>
        <Col xs={6} sm={5} lg={2}>
          <p className="m-0 mt-1">From Date</p>
          <div className="mb-3 row">
            <div>
              <input
                value={fromDate}
                className="form-control"
                type="date"
                onChange={e => {
                  updateFilterValue("fromDate", e.target.value);
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={6} sm={5} lg={2}>
          <p className="m-0 mt-1">To Date</p>
          <div className="mb-3 row">
            <div>
              <input
                value={toDate}
                className="form-control"
                type="date"
                onChange={e => {
                  updateFilterValue("toDate", e.target.value);
                }}
              />
            </div>
          </div>
        </Col>
        <Col xs={4} sm={2} lg={2}>
          <button disabled={disableFilter} className="yellowButton w-200 mt-4" onClick={applyFilter}>
            Search
          </button>
        </Col>
        {!disableFilter && (
          <Col xs={12} sm={1}>
            <p
              disabled={disableFilter}
              className="blueText mt-4"
              style={{
                fontSize: 14,
                cursor: "pointer",
                paddingTop: 8,
              }}
              onClick={clearFilter}>
              Clear
            </p>
          </Col>
        )}
      </Row>
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                      <Typography variant="tableHeader" noWrap>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {prescriptions?.length > 0 ? (
              reactTable
                .getRowModel()
                .rows.map(row => <MuiTableCollapse key={row.id} row={row} showNested={false} showColoredRows={false} />)
            ) : (
              <TableRow>
                <TableCell colSpan={10}>
                  <Typography variant="h6" color={"GrayText"} align="center" style={{ fontSize: 14 }}>
                    No Data Found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
    </>
  );
}

export default React.memo(PatientPrescriptionListing);
