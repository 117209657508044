import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { IsLoggedSelector, userRoleSelector,userInfoSelector } from "selectors";
import { USER_ROLES, getPathPermission, isLoggedIn } from "../utils";

const PrivateRoute = ({
  clinicComponent: ClinicComponent,
  adminComponent: AdminComponent,
  patientComponent: PatientComponent,
  ...rest
}) => {
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);
  let permission = true;
  let Component = null;
  const loginSelector = useSelector(IsLoggedSelector);
  const cookieLogin = isLoggedIn();

  const isLogin = useMemo(() => loginSelector && cookieLogin, [cookieLogin, loginSelector]);

  if (userRole) {
    if (userRole?.toString() === "undefined") {
      Component = PatientComponent;
    } else {
      Component = userRole?.startsWith("Clinic") ? ClinicComponent : AdminComponent;
    }
  } else if (isLogin) {
    Component = PatientComponent;
  }

  if (userRole?.toString() !== USER_ROLES.CLINIC_SUPER_USER && !userInfo?.isSuperUserSameAsProvider ) {
    permission = getPathPermission(rest.path.split("/")[1], userRole);
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? permission ? <Component {...props} /> : <Redirect to="/access-denied" /> : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
