import { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";

const ClinicLocationProviderDD = props => {
  const [physicians, setPhysicians] = useState([]);
  const [physiciansData, setPhysiciansData] = useState([]);
  const [providerValue, setProviderValue] = useState(() => props.defaultValue);
  const [message, setMessage] = useState("Loading clinic location providers ...");

  const propsRef = JSON.stringify(props ?? {});

  useEffect(() => {
    setMessage("Loading Providers Data ...");

    const $props = propsRef ? JSON.parse(propsRef) : {};

    axiosConfig
      .get("staffs/getAllProvidersByClinicLocationId/" + $props.locationId, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async response => {
        var physiciansList = [];
        var physiciansDataList = [];

        if (response.data.length === 0) {
          setMessage("No providers found!");
          setProviderValue("");
        } else {
          setProviderValue(props.defaultValue);
          await response.data.forEach(physician => {
            physiciansList.push(
              <option selected={$props.defaultValue === physician.id} key={physician.id} value={physician.id}>
                {physician.firstName + " " + physician.lastName}
              </option>,
            );

            physiciansDataList.push({ ...physician });
            if (props.defaultValue === physician.id && props.updateProviderInfo && props.required) {
              props.updateProviderInfo(physician);
            }
          });

          setPhysicians(physiciansList);
          setPhysiciansData(physiciansDataList);
        }
      })
      .catch(error => {
        errorResponse(error);
      });
  }, [propsRef]);

  return (
    <FormGroup key={props}>
      <Label className="normalText" for={props.name}>
        Provider {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        name={props.name}
        value={providerValue}
        required={props.required}
        onChange={e => {
          setProviderValue(e.target.value);
          props.updateProviderInfo
            ? props.updateProviderInfo(physiciansData.find(p => p.id === e.target.value))
            : console.log("");
        }}>
        <option value="" disabled selected>
          {physicians.length ? "Select" : message}
        </option>
        {physicians}
      </Input>
    </FormGroup>
  );
};

export default ClinicLocationProviderDD;
