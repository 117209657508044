const { createTheme } = require("@mui/material");

const muiTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: "0.8125rem",
    allVariants: {
      color: "#495057",
    },
    tableHeader: {
      color: "#495057",
      fontWeight: 600,
      fontSize: 12,
    },
  },
  palette: {
    secondary: {
      main: "rgb(44, 75, 118) ",
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        fullWidth: {
          borderColor: "#495057",
          width: "100%",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottomWidth: 1,
          },
        },
      },
    },
  },
});

export default muiTheme;
