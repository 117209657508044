import MetaTags from "react-meta-tags";
import React, { useState } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from "reactstrap";
import LoggedOutNavbar from "../../components/loggedOutNavbar";
import Lottie from "../../components/lottie";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Spinner from "../../components/spinner";
import { useLocation } from "react-router-dom";

const ForgetPassword = props => {
  const loc = useLocation();

  const [email, setEmail] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState("");

  function handleValidSubmit(e) {
    e.preventDefault();

    setShowSpinner(true);

    axiosConfig
      .post(loc.state?.isPatient ? "accounts/forgot-password-patient" : "accounts/forgot-password", {
        email: email,
      })
      .then(async response => {
        setShowSpinner(false);

        setSent(true);
      })
      .catch(error => {
        setShowSpinner(false);

        setError("An error occurred while recovering your email address. Please try again in a while!");

        errorResponse(error);
      });
  }

  return (
    <div>
      <MetaTags>
        <title>Forgot Password | VPI Compounding</title>
      </MetaTags>
      {loc.state.hideHeader ? null : <LoggedOutNavbar />}
      {showSpinner ? <Spinner /> : null}
      <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="p-4 text-center">
                  <h3 className="text-primary mt-5 mb-0 text-dark">Forgot Password</h3>
                  {sent ? (
                    <div>
                      <Lottie name="success-check" />
                      <p className="mx-4 my-0">
                        An email containing recovery instructions has been sent to {email} to recover your password.
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="mt-5 mx-4">To recover your password, please confirm your email address</p>
                    </div>
                  )}
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <form className="form-horizontal px-sm-5 px-1" onSubmit={e => handleValidSubmit(e)}>
                      {error.length > 0 ? <Alert color="danger">{error}</Alert> : null}
                      <div className="mb-5 px-4">
                        {sent ? null : (
                          <FormGroup>
                            <Label htmlFor="email" className="form-label">
                              Email <span className="requiredStar">*</span>
                            </Label>
                            <Input
                              name="email"
                              onChange={e => {
                                setError("");
                                setEmail(e.target.value);
                              }}
                              className="form-control"
                              type="email"
                              placeholder=""
                              required
                            />
                          </FormGroup>
                        )}
                      </div>
                      {sent ? (
                        <Row className="m-4">
                          <button
                            className="btn btn-outline-dark"
                            onClick={() => {
                              props.history.goBack();
                            }}>
                            Back to Login
                          </button>
                        </Row>
                      ) : (
                        <Row className="m-5">
                          <button className="btn btn-primary darkBg mb-3" type="submit">
                            Recover Password
                          </button>
                          <button
                            className="btn btn-outline-dark"
                            onClick={() => {
                              props.history.goBack();
                            }}>
                            Back
                          </button>
                        </Row>
                      )}
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ForgetPassword;
