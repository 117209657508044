import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";

const EthnicityDD = (props) => {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue);

  useEffect(() => {
    setDefaultValue(props.defaultValue ? props.defaultValue : "");
  }, [props]);

  return (
    <FormGroup key={defaultValue}>
      <Label className="normalText" for={props.name}>
        Ethnicity{" "}
        {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        name={props.name}
        required={props.required}
        defaultValue={defaultValue}
      >
        <option value="" disabled>
          Select
        </option>
        <option value="Hispanic or Latino">Hispanic or Latino</option>
      </Input>
    </FormGroup>
  );
};

export default EthnicityDD;
