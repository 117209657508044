import { InvoiceApi } from "../../rtkApi";
import { API_METHODS, END_POINTS } from "../../rtkConstant";
import { USER_ROLES } from "../../../utils";
import { set } from "lodash";
import { dateFormatterMountainTime } from "utils/constants";
const InvoiceListQuery = InvoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceList: builder.query({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const clinicSide = user?.userData?.role?.toLowerCase().includes("clinic");
            const organizedBody = { ...apiArgs, limit: apiArgs?.limit || 20, userId: user?.userData?.id,paymentStatus:apiArgs?.paymentStatus?apiArgs?.paymentStatus:[]};
            let url;
            if (clinicSide) {
              const isSuperuser = user?.userData?.role === USER_ROLES.CLINIC_SUPER_USER || user?.userData?.isSuperUserSameAsProvider;
              if (isSuperuser) {
                set(organizedBody, "clinicId", user?.userData?.clinicId);
              } else {
                set(organizedBody, "clinicLocationId", user?.userData?.clinicLocationId);
              }
              url = END_POINTS.CLINIC_INVOICE_LIST + `${isSuperuser ? "" : "Location"}`;
            } else {
              url = END_POINTS.ADMIN_INVOICE_LIST;
            }
            let response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            if (response.data) {
              response.data.prescriptions = response?.data?.prescriptions?.map((prescription) => {
                prescription.prescriptionDate = dateFormatterMountainTime(prescription.prescriptionDate);
                prescription.paidDate = prescription.paidDate ? dateFormatterMountainTime(prescription.paidDate) : "";
                return prescription;
              });
            }
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
  }),
});
export const { useLazyGetInvoiceListQuery } = InvoiceListQuery;
