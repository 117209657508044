import { set, unset } from "lodash";
import { useCallback, useMemo } from "react";
import { useUpdateClinicLocationMutation, useUploadFileMutation } from "rtk";
import { STEPPER_FORMS, base64toFile } from "utils";

function useClinicLocationProfileProvider(getEditApiParam) {
  const [uploadFileMutation, { error: uploadError, isLoading: uploadLoading }] = useUploadFileMutation();
  const [
    ClinicLocationMutate,
    {
      data: clinicLocationMutateData,
      isSuccess: clinicLocationInsertSuccessfully,
      error: clinicLocationError,
      isLoading: clinicLocationLoading,
      reset: resetMutation,
    },
  ] = useUpdateClinicLocationMutation();

  const fileUploading = useMemo(() => uploadLoading, [uploadLoading]);
  const mutateLoading = useMemo(() => clinicLocationLoading, [clinicLocationLoading]);
  const mutateError = useMemo(
    () => uploadError?.data?.message || clinicLocationError?.data?.message || "",
    [clinicLocationError?.data?.message, uploadError?.data?.message],
  );
  const mutateSuccessMessage = useMemo(
    () => clinicLocationMutateData?.message || "",
    [clinicLocationMutateData?.message],
  );

  const uploadFile = useCallback(
    async (e, signPad = "") => {
      e?.preventDefault?.();
      if (!uploadLoading) {
        let file = "";
        if (signPad) {
          file = base64toFile(signPad);
        } else {
          file = e.target.files[0];
        }
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await uploadFileMutation(formData).unwrap();
          return uploadResponse?.accessUrl;
        }
      }
    },
    [uploadFileMutation, uploadLoading],
  );

  const formData = useCallback(
    (data, formType = "", progressBarIndex = null, allowNavigation = true) => {
      if (allowNavigation) {
        resetMutation();
        const editApiParam = getEditApiParam();
        const organizedParam = {
          formType,
          ...editApiParam,
          clinicLocation: data?.info,
        };
        if (formType === STEPPER_FORMS.PERSONAL_INFO) {
          set(organizedParam, "staffIds", data?.info?.staffIds);
          delete organizedParam?.clinicLocation?.staffIds;
        } else if (formType === STEPPER_FORMS.CREDIT_INFO) {
          unset(organizedParam, "clinicLocation");
          set(organizedParam, "encryptedCreditCards", data?.info?.encryptedCreditCards);
        }
        ClinicLocationMutate(organizedParam);
      }
    },
    [ClinicLocationMutate, getEditApiParam, resetMutation],
  );

  return {
    mutateError,
    mutateLoading,
    fileUploading,
    mutateSuccessMessage,
    clinicLocationInsertSuccessfully,
    formData,
    uploadFile,
  };
}

export default useClinicLocationProfileProvider;
