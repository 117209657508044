/* eslint-disable react-hooks/exhaustive-deps */
import CancelPresentationRoundedIcon from "@mui/icons-material/CancelPresentationRounded";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse, OrderViewModal, PrescriptionUpdateModal } from "components";
import Spinner from "components/spinner";
import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { LISTING_NAME, ROWS_LIMIT, columnHelper } from "utils";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import { useCancelClinicPrescriptionMutation, useLazyGetClinicSubmittedPrescriptionQuery } from "../../../../rtk";
import { useClinicPrescriptionAction, useClinicPrescriptionListing } from "../../Hook";
import AlertMessage from "../components/alert_message";

function ClinicPendingApprovalPrescription({ filter }) {
  const {
    rowLimit,
    tablePage,
    invoiceId,
    disableFilter,
    searchKeyword,
    prescriptionId,
    showLoading,
    errorMessage,
    filterRxStatus,
    prescriptions,
    totalRecords,
    openUpdateModal,
    INCOMPLETE_TABLE_HEADER,
    refetchList,
    toggleDetailModal,
    handleChangePage,
    toggleUpdateModal,
    applyFilter,
    clearFilter,
    updateKeyword,
    updateFilterRxStatus,
    changeRowLimit,
  } = useClinicPrescriptionListing({
    listingQuery: useLazyGetClinicSubmittedPrescriptionQuery,
  });

  const { mutateLoading, mutateData, mutateError, toggleConfirmationModal } = useClinicPrescriptionAction({
    listingMutation: useCancelClinicPrescriptionMutation,
  });

  const showSpinner = useMemo(() => showLoading || mutateLoading, [mutateLoading, showLoading]);
  const successMessage = useMemo(() => mutateData?.message ?? "", [mutateData?.message]);
  const combinedErrorMessage = useMemo(
    () => errorMessage || mutateError?.data?.message || "",
    [errorMessage, mutateError?.data?.message],
  );

  const clinicPatientActions = useMemo(
    () =>
      columnHelper.accessor("actions", {
        header: "Actions",
        headerStyle: { minWidth: "100px", width: "100px", textAlign: "center" },
        cell: props => (
          <>
            {!["Approved", "Ready To Pack", "Received", "Cancelled"].includes(
              props.row.original.prescriptionStatus,
            ) && (
              <>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => toggleDetailModal(props.row.original.id)}
                    style={{ width: 30, height: 30 }}>
                    <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    onClick={() => toggleConfirmationModal(props.row.original.id)}
                    style={{ width: 30, height: 30 }}>
                    <CancelPresentationRoundedIcon color="error" style={{ width: 20, height: 20 }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </>
        ),
      }),
    [],
  );

  const clinicPatientTableHeader = useMemo(
    () => [...INCOMPLETE_TABLE_HEADER, clinicPatientActions],
    [clinicPatientActions],
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: clinicPatientTableHeader,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      {showSpinner && <Spinner />}
      <AlertMessage msg={successMessage} />
      <AlertMessage msg={combinedErrorMessage} isError={true} />
      <Row className="my-3 px-3">
        <h5>Filter Prescriptions</h5>
        <Col xs={6} sm={5} lg={2}>
          <div className="mb-3">
            <RXStatusDD
              loc={LISTING_NAME.CLINIC_PENDING_APPROVAL}
              filterRX={true}
              clearStatus={disableFilter}
              defaultValue={disableFilter ? "" : filter}
              updatedFilterRxStatusValue={updateFilterRxStatus}
            />
          </div>
        </Col>
        <Col sm={12} md={3} lg={3} className="m-0">
          <button disabled={disableFilter} className="yellowButton" onClick={applyFilter}>
            Filter
          </button>
          {!disableFilter && (
            <i
              disabled={disableFilter}
              className="fas fa-times redText"
              style={{ fontSize: 24, marginLeft: 18 }}
              onClick={clearFilter}
            />
          )}
        </Col>
        <Col sm={12} md={3} lg={3} className="text-end" />
      </Row>
      <ListingSearch
        searchKeyword={searchKeyword}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
      />
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                <TableCell />
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                      <Typography variant="tableHeader" noWrap>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => (
              <MuiTableCollapse
                key={row.id}
                row={row}
                showColoredRows={false}
                filterRxStatus={filterRxStatus}
                nestedEditable={true}
                toggleUpdateModal={toggleUpdateModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
      <OrderViewModal
        isReview
        listingName={LISTING_NAME.CLINIC_PENDING_APPROVAL}
        // Important to hide the Detail view on editing
        prescriptionId={openUpdateModal ? "" : prescriptionId}
        handleClose={toggleDetailModal}
      />
      <PrescriptionUpdateModal
        listingName={LISTING_NAME.CLINIC_PENDING_APPROVAL}
        modalTitle={openUpdateModal}
        prescriptionId={invoiceId}
        handleClose={toggleUpdateModal}
      />
    </>
  );
}

export default React.memo(ClinicPendingApprovalPrescription);
