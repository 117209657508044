import MetaTags from 'react-meta-tags';
import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from 'reactstrap';
import LoggedOutNavbar from '../../components/loggedOutNavbar';
import Lottie from '../../components/lottie';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';
import Spinner from '../../components/spinner';

const RecoverPatientPassword = (props) => {
  const [updatedPassword, setupdatedPassword] = useState(false);
  const [error, setError] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  function handleValidSubmit(e) {
    e.preventDefault();

    var serialize = require('form-serialize');
    var obj = serialize(document.getElementById('RecoverPasswordForm'), { hash: true });

    if (obj.password !== obj.confirmPassword) {
      setError('Passwords do not match, Please try again!');
    } else {
      setShowSpinner(true);

      axiosConfig
        .post('accounts/reset-password-patient', {
          token: props.match.params.token,
          password: obj.password,
          confirmPassword: obj.confirmPassword,
        })
        .then(async (response) => {
          setupdatedPassword(true);

          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);

          setError(error.response.data.message);

          errorResponse(error);
        });
    }
  }

  return (
    <div>
      <MetaTags>
        <title>Reset Password | VPI Compounding</title>
      </MetaTags>
      <LoggedOutNavbar />
      {showSpinner ? <Spinner /> : null}
      <div className='account-pages p-sm-3 p-1 pt-5 mt-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='overflow-hidden'>
                <div className='p-4 text-center'>
                  <h3 className='text-primary m-4 mb-0 text-dark'>Reset Password</h3>
                  {updatedPassword ? (
                    <div>
                      <Lottie name='success-check' />
                      <p className='mt-5 mx-sm-4 mx-2'>Your new password has been set successfully</p>
                      <p className='mt-2 mx-4'>Please login to continue.</p>
                    </div>
                  ) : (
                    <div>
                      <p className='mt-5 mx-4'>Please set your new password below.</p>
                    </div>
                  )}
                </div>
                <CardBody className='pt-0'>
                  <div className='p-2'>
                    <form id='RecoverPasswordForm' className='form-horizontal px-sm-5 px-2' onSubmit={(e) => handleValidSubmit(e)}>
                      {error.length > 0 ? (
                        <Alert color='danger' className='text-center mb-4'>
                          {error}
                        </Alert>
                      ) : null}
                      {updatedPassword ? null : (
                        <div className='mb-5 px-4'>
                          <div className='mt-3'>
                            <FormGroup>
                              <Label htmlFor='password' className='form-label'>
                                New Password <span className='requiredStar'>*</span>
                              </Label>
                              <Input name='password' className='form-control' type='password' placeholder='' required />
                            </FormGroup>
                          </div>
                          <div className='mt-3'>
                            <FormGroup>
                              <Label htmlFor='confirmPassword' className='form-label'>
                                Confirm Password <span className='requiredStar'>*</span>
                              </Label>
                              <Input name='confirmPassword' className='form-control' type='password' placeholder='' required />
                            </FormGroup>
                          </div>
                        </div>
                      )}
                      {updatedPassword ? (
                        <Row className='m-4'>
                          <button
                            className='btn btn-primary darkBg'
                            onClick={() => {
                              props.history.push('/login');
                            }}
                          >
                            Go to Login
                          </button>
                        </Row>
                      ) : (
                        <Row className='m-5'>
                          <button className='btn btn-primary darkBg mt-5' type='submit'>
                            Change Password
                          </button>
                          <button
                            className='btn btn-outline-dark mt-2'
                            onClick={() => {
                              props.history.push('/login');
                            }}
                          >
                            Back to login
                          </button>
                        </Row>
                      )}
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default RecoverPatientPassword;
