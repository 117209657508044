import { CartDrawer } from "components";
import React, { useCallback, useMemo, useRef } from "react";
import { CartContext } from "./CartContext";

function CartProvider({ children }) {
  const cartRef = useRef(null);

  const toggleCartDrawer = useCallback(showObj => {
    cartRef?.current?.toggleCartDrawer?.(showObj);
  }, []);

  const getCartDrawerState = useCallback(() => cartRef?.current?.getDrawerState?.(), []);

  const cartContextValue = useMemo(
    () => ({
      getCartDrawerState,
      toggleCartDrawer,
    }),
    [getCartDrawerState, toggleCartDrawer],
  );

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
      <CartDrawer ref={cartRef} />
    </CartContext.Provider>
  );
}

export default React.memo(CartProvider);
