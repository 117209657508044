import React, { useMemo } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../dropdowns/stateDD";
import ReCAPTCHA from "react-google-recaptcha";
import { SignaturePad } from "components/SignaturePad";
import CreditCardInput from "react-credit-card-input";
import { encrypt, decrypt } from "cryptofunc";
import "./index.scss";

const StepTwo = ({
  step,
  onlyOneUser,
  clinicAddress,
  onChange,
  setOnlyOneUser,
  upload,
  setClinicAddress,
  signatureImageUrl,
  signatureModal,
  closeSignatureModal,
  onClickSave,
  setSignatureModal,
  adminSide = false,
  billingRepSignature,
  billingSignModal,
  setBillingSignModal,
}) => {
  const addClinic = window?.location?.pathname.includes("add-clinic");

  return (
    <>
      <center>
        {" "}
        <div className="my-4 p-2" style={{ backgroundColor: "#5B8ABA", color: "white" }}>
          <h5 style={{ color: "white", margin: "0px", padding: "0px" }}>Clinic Authorized Personnel & Preferences</h5>
        </div>
      </center>

      <Row>
        <Col md={12} className="mb-1">
          <FormGroup>
            <Label className="form-label">Clinic Representative for all Customer Care Needs</Label>{" "}
            <span className="requiredStar">*</span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Input
              name="officeContactFirstName"
              className="form-control"
              placeholder="First name"
              type="text"
              required={step === 2 ? true : false}
            />{" "}
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Input
              name="officeContactLastName"
              className="form-control"
              placeholder="Last name"
              type="text"
              required={step === 2 ? true : false}
            />{" "}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="officeContactPhone" className="form-label">
              Contact Phone <span className="requiredStar">*</span>
            </Label>
            <Input
              name="officeContactPhone"
              className="form-control"
              placeholder="Format: 1234567890"
              type="text"
              required={step === 2 ? true : false}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="officeContactEmail" className="form-label">
              Contact Email <span className="requiredStar">*</span>
            </Label>
            <Input
              name="officeContactEmail"
              className="form-control"
              placeholder="Email"
              type="email"
              required={step === 2 ? true : false}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="preferredBillingMethod" className="form-label">
              Preferred Billing Method <span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input
              name="preferredBillingMethod"
              className="form-control"
              type="select"
              required={step === 2 ? true : false}>
              <option value="Bill Clinic for all orders">Bill Clinic for all orders</option>
              <option value="Bill Patient for all orders">Bill Patient for all orders</option>
            </Input>
            <p className="metaData">
              If "Bill Patient for all orders" is selected, VPI will contact your patient up to 5 times prior to
              consulting with your clinic on next steps. If "Bill Clinic for all orders" is selected, our billing team
              will send bi-weekly invoices for all completed invoices.
            </p>
          </FormGroup>
        </Col>
        {/* <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="preferredDeliveryMethod" className="form-label">
              Preferred Delivery Method <span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input
              name="preferredDeliveryMethod"
              className="form-control"
              type="select"
              required={step === 2 ? true : false}
            >
              <option value="Mail Order to Patient">Mail Order to Patient</option>
              <option value="Mail Order to Clinic">Mail Order to Clinic</option>
              <option value="Clinic Pick-Up">Clinic Pick-Up</option>
              <option value="Patient Pick-Up">Patient Pick-Up</option>
              <option value="Delivery Method will be indicated per order">
                Delivery Method will be indicated per order
              </option>
            </Input>
            <p className="metaData">
              Please note: If "Delivery Method will be indicated per order" is selected, please indicate the delivery
              method on each prescription order. If no indication is communicated, all orders will be defaulted to ship
              to the patient directly
            </p>
          </FormGroup>
        </Col> */}
      </Row>

      <Row>
        {/* <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="mailOrderShippingAvailability" className="form-label">
              Mail Order Shipping Availability <span className="requiredStar">*</span>
            </Label>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="radio" value="Any Day (including weekends)" name="mailOrderShippingAvailability" />
              <span className="mx-1">Any Day (including weekends)</span>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="radio" value="Monday - Friday" name="mailOrderShippingAvailability" />
              <span className="mx-1">Monday - Friday</span>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                value="Check with patient if weekend delivery is available"
                name="mailOrderShippingAvailability"
              />
              <span className="mx-1">Check with patient if weekend delivery is available</span>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="radio"
                value="other"
                name="mailOrderShippingAvailability"
                onChange={(e) => {
                  if (e.target.checked) {
                    document.getElementById("otherMailOrderText").style.display = "block";
                  } else {
                    document.getElementById("otherMailOrderText").style.display = "none";
                  }
                }}
              />
              <span className="mx-1">Other: </span>
            </div>
            <input type="text" className="form-control" id="otherMailOrderText" style={{ display: "none" }} />

            <p className="metaData">
              If a shipping preference above is selected, please provide a suggested timeline of shipping availability.
              If "Mail Order to Clinic" is the preferred delivery method, please provide the days you'd like to receive
              orders on in the "Other" section.{" "}
            </p>
          </FormGroup>
        </Col> */}
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="partialShipmentsAvailableasNeeded" className="form-label">
              Partial Shipments Available as Needed <span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input
              name="partialShipmentsAvailableasNeeded"
              className="form-control"
              type="select"
              required={step === 2 ? true : false}>
              <option value="Ship order when all medications are complete">
                Ship order when all medications are complete
              </option>
              <option value="Ship partial order if needed">Ship partial order if needed</option>
              <option value="Check with clinic for partial shipment authorization">
                Check with clinic for partial shipment authorization
              </option>
            </Input>
            <p className="metaData">
              If a prescription order contains 2, or more medications and one of the prescriptions is unavailable due to
              reasons such as a sudden material shortage, refill too soon, compounding delay, etc., please provide your
              guidance on how to proceed with the prescription order.
            </p>
          </FormGroup>
        </Col>
      </Row>

      {/* <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="mailOrderShippingAvailability" className="form-label">
              Rx Ordering Method <span className="requiredStar">*</span>
            </Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" value="Pharmacy Management Provider Portal" />
              <span className="mx-1">Pharmacy Management Provider Portal</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" value="EMR or Personal Ordering System" />
              <span className="mx-1">EMR or Personal Ordering System</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input type="checkbox" value="other" />
              <span className="mx-1">Other: </span>
            </div>
            <input type="text" value="" className="form-control" />
            <p className="metaData">What is your preferred ordering method? </p>
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="preferredMedicalSupplyManagement" className="form-label">
              Preferred Medical Supply Management<span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input
              name="preferredMedicalSupplyManagement"
              className="form-control"
              type="select"
              required={step === 2 ? true : false}
            >
              <option value="Dispense All Medical Supplies Needed for Order(s)">
                Dispense All Medical Supplies Needed for Order(s)
              </option>
              <option value="No Medical Supplies Needed">No Medical Supplies Needed</option>
              <option value="Clinic will Indicate per Order(s)">Clinic will Indicate per Order(s)</option>
            </Input>
            <p className="metaData">
              If VPI is anticipating scripts from your EMR or Personal Ordering System, will your clinic provide the
              medical supplies need by note or per Rx? Or, does your clinic prefer VPI to dispense all medical supplies
              needed for the Rx order?{" "}
            </p>
          </FormGroup>
        </Col>
      </Row> */}

      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="prescriptionRefillCommunication" className="form-label">
              Prescription Refill Communication<span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input
              name="prescriptionRefillCommunication"
              className="form-control"
              type="select"
              required={step === 2 ? true : false}>
              <option value="Our clinic will notify VPI when to fill - patient's can't authorize refills">
                Our clinic will notify VPI when to fill - patient's can't authorize refills
              </option>
              <option value="Contact patient when refills are due. Confirm authorization with clinic prior to filling">
                Contact patient when refills are due. Confirm authorization with clinic prior to filling
              </option>
              <option value="No communication is needed to clinic or patient">
                No communication is needed to clinic or patient
              </option>
            </Input>
            <p className="metaData">
              VPI wants to ensure that if a patient requests a refill we're communicating with your team properly. Let
              us know how you'd like to manage your refills if refills are provided on the script
            </p>
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="refillManagement" className="form-label">
              Refill Management<span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input name="refillManagement" className="form-control" type="select" required={step === 2 ? true : false}>
              <option value="No report needed">No report needed</option>
              <option value="Opt In for Monthly Refill Report">Opt In for Monthly Refill Report</option>
            </Input>
            <p className="metaData">
              Would you like VPI to send you a monthly refill report? Please note: If no answer is selected, VPI will
              default to no monthly report
            </p>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="companyLogoBase64String" className="form-label">
              Company Logo
            </Label>
            <Input
              name="companyLogoBase64String"
              onChange={e => {
                upload(e, "companyLogo");
              }}
              className="form-control"
              type="file"
              accept="image/*, .pdf"
            />
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="clinicWebsiteURL" className="form-label">
              Clinic Website URL
            </Label>
            <Input name="clinicWebsiteURL" className="form-control" type="text"></Input>
            <p className="metaData">Help us get to know you and your clinic!</p>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="howDidYouHearAboutVPICompounding" className="form-label">
              How did you hear about VPI Compounding?
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input name="howDidYouHearAboutVPICompounding" className="form-control" type="select">
              <option value="Referral from existing clinic">Referral from existing clinic</option>
              <option value="Website">Website</option>
              <option value="Search Engine (Google, Bing, Yahoo, etc.)">
                Search Engine (Google, Bing, Yahoo, etc.)
              </option>
              <option value="Social Media">Social Media</option>
              <option value="From a patient">From a patient</option>
              <option value="Go Wellness">Go Wellness</option>
              <option value="Regenerative Practices Of America">Regenerative Practices Of America</option>
              <option value="EverSpan">EverSpan</option>
              <option value="Other">Other</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="other" className="form-label">
              Other..
            </Label>
            <Input name="other" className="form-control" type="text"></Input>
            <p className="metaData">Is there anything else VPI needs to know to help serve your team's needs?</p>
          </FormGroup>
        </Col>
      </Row>

      {/* CREDIT CARD AUTHORIZATION*/}
      <center>
        {" "}
        <div className="my-4 p-2" style={{ backgroundColor: "#5B8ABA", color: "white" }}>
          <h5 style={{ color: "white", margin: "0px", padding: "0px" }}>Credit Card Authorization</h5>
        </div>
      </center>
      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="cardHolderFirstName" className="form-label">
              Card Holder First Name
            </Label>
            <span className="requiredStar">*</span>
            <Input
              name="cardHolderFirstName"
              className="form-control"
              type="text"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="cardHolderLastName" className="form-label">
              Card Holder Last Name
            </Label>{" "}
            <span className="requiredStar">*</span>
            <Input
              name="cardHolderLastName"
              className="form-control"
              type="text"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <div className="mb-4">
            <Label htmlFor="cardExpirationDate" className="form-label">
              Card Number
            </Label>
            <span className="requiredStar">*</span>
            <br></br>
            <CreditCardInput
              fieldClassName="form-control"
              cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                <input
                  {...props}
                  name={`cvv`}
                  style={{ display: "none" }}
                  onChange={handleCardCVCChange(e => console.log("cvc change"))}
                />
              )}
              cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                <input
                  {...props}
                  name={`cardExpirationDate`}
                  onChange={handleCardExpiryChange(e => console.log("expiry change"))}
                  inputClassName="form-control"
                  required={step === 2 ? true : false}
                />
              )}
              cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                <input
                  {...props}
                  name={`creditCardNumber`}
                  onChange={handleCardNumberChange(e => console.log("number change"))}
                  inputClassName="form-control"
                  required={step === 2 ? true : false}
                />
              )}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="mb-1">
          <FormGroup>
            <Label className="form-label">
              Clinic Representative for Billing/Invoicing <span className="requiredStar">*</span>
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Input
              name="billingRepresentativeFirstName"
              className="form-control"
              placeholder="First Name"
              type="text"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Input
              name="billingRepresentativeLastName"
              className="form-control"
              type="text"
              placeholder="Last Name"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="cardHolderAddressLine1" className="form-label">
              Address line 1
            </Label>{" "}
            <span className="requiredStar">*</span>
            <Input
              name="cardHolderAddressLine1"
              className="form-control"
              type="textarea"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="cardHolderAddressLine2" className="form-label">
              Address line 2
            </Label>
            <Input name="cardHolderAddressLine2" className="form-control" type="textarea" />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="cardHolderCity" className="form-label">
              City
            </Label>{" "}
            <span className="requiredStar">*</span>
            <Input name="cardHolderCity" className="form-control" type="text" required={step === 2 ? true : false} />
          </div>
        </Col>
        <Col md={4}>
          <StateDD name="cardHolderState" required={step === 2 ? true : false} />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="cardHolderZipcode" className="form-label">
              Zip code
            </Label>{" "}
            <span className="requiredStar">*</span>
            <Input name="cardHolderZipcode" className="form-control" required={step === 2 ? true : false} />
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="billingRepresentativeEmail" className="form-label">
              Email for Receipt
            </Label>{" "}
            <span className="requiredStar">*</span>
            <Input
              name="billingRepresentativeEmail"
              className="form-control"
              type="email"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="billingRepresentativePhone" className="form-label">
              Contact Phone
            </Label>{" "}
            <span className="requiredStar">*</span>
            <Input
              name="billingRepresentativePhone"
              className="form-control"
              placeholder="Format: 2124567890"
              type="text"
              required={step === 2 ? true : false}
            />
          </div>
        </Col>
      </Row> */}
      <Row>
        {!addClinic ? (
          <Row>
            <div>
              {billingRepSignature ? (
                <img
                  style={{ width: "150px", height: "auto" }}
                  className="billingRepSign mb-1"
                  src={billingRepSignature}
                  alt=""
                />
              ) : null}
              <div className="mb-4">
                <Label htmlFor="billingRepresentativeSignatureBase64String" className="form-label">
                  Signature
                </Label>{" "}
                <span className="requiredStar">*</span>
                <Row>
                  {/* <Col md={4}>
                  {" "}
                  <Input
                    name="billingRepresentativeSignatureBase64String"
                    onChange={(e) => {
                      upload(e, "signatureImageUrl", "");
                    }}
                    className="form-control"
                    type="file"
                    id="signatureImageUrl"
                    required={step === 2 ? true : false}
                  />
                </Col> */}
                  <Col md={12}>
                    <span className="pointer form-control" onClick={() => setBillingSignModal(true)}>
                      <i class="fas fa-pen mx-2"></i>Signature Pad
                    </span>
                  </Col>
                </Row>
              </div>
              <SignaturePad
                actionText="Save"
                person="billingRepSign"
                onClickSave={onClickSave}
                closeModal={closeSignatureModal}
                showModal={billingSignModal}
              />
            </div>
          </Row>
        ) : null}
      </Row>
    </>
  );
};

export default StepTwo;
