import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import VPICompoundingLogo from "assets/vpi-images/vpi-logo.png";
import classNames from "classnames";
import { AlertMessage } from "components";
import { usePrintPage } from "components/Hooks";
import Spinner from "components/spinner";
import { decrypt } from "cryptofunc";
import { filter, flatten, map } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useGetInvoiceDetailQuery, useLazyGetInvoiceDetailQuery } from "rtk";
import { dateFormatterMountainTime } from "utils/constants";

function InvoiceDetailModal({ invoiceId = "", handleClose }) {
  const [fetchInvoices, { data: invoiceData, isFetching, error }] = useLazyGetInvoiceDetailQuery({ invoiceId });
  const { componentRef, printLoading, printModal } = usePrintPage(invoiceData?.invoiceNumber);
  const disableActions = useMemo(() => isFetching || printLoading, [isFetching, printLoading]);
  const isBulkInvoice = useMemo(
    () => invoiceData?.subPrescriptions?.length > 0,
    [invoiceData?.subPrescriptions?.length],
  );
  const decryptedBillingInfo = useMemo(
    () => (invoiceData?.encryptedBillingInfo ? decrypt(invoiceData?.encryptedBillingInfo) : ""),
    [invoiceData?.encryptedBillingInfo],
  );

  useEffect(() => {
    fetchInvoices({ invoiceId })
  }, [Boolean(invoiceId)]);

  const closeModal = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const renderMainInvoiceInfo = useCallback(
    (showClinicInfo = false) => {
      if (showClinicInfo) {
        return (
          <>
            {invoiceData?.clinicLocationDetails.locationName}
            <br />
            {invoiceData?.clinicLocationDetails.addressLine1 + ","}
            <br />
            {invoiceData?.clinicLocationDetails.addressLine2}
            <br />
            {`${invoiceData?.clinicLocationDetails.city}, ${invoiceData?.clinicLocationDetails.state}`},
            <br />
            {invoiceData?.clinicLocationDetails.zipcode}
          </>
        );
      } else {
        return (
          <>
            <strong>Bulk Invoice: </strong> Yes
          </>
        );
      }
    },
    [
      invoiceData?.clinicLocationDetails.addressLine1,
      invoiceData?.clinicLocationDetails.addressLine2,
      invoiceData?.clinicLocationDetails.city,
      invoiceData?.clinicLocationDetails.locationName,
      invoiceData?.clinicLocationDetails.state,
      invoiceData?.clinicLocationDetails.zipcode,
    ],
  );

  const renderPatientNames = useCallback((invoicePatients = []) => {
    const patientNames = invoicePatients?.map(patient => (
      <ListItem key={patient.id}>
        <ListItemText
          className="my-0"
          style={{ display: "list-item" }}
          primary={`${patient.firstName} ${patient.lastName}`}
        />
      </ListItem>
    ));
    return (
      <>
        <strong>{invoicePatients?.length > 1 ? "Patients:" : "Patient:"}</strong>
        <List
          sx={{
            listStyle: "decimal",
          }}
          dense={true}>
          {patientNames}
        </List>
      </>
    );
  }, []);

  const renderBillingAddress = useMemo(() => {
    return (
      <>
        {decryptedBillingInfo?.creditCard?.cardHolderAddressLine1}
        <br />
        {decryptedBillingInfo?.creditCard?.cardHolderAddressLine2}
        {decryptedBillingInfo?.creditCard?.cardHolderAddressLine2 && <br />}
        {decryptedBillingInfo?.creditCard?.cardHolderCity}, {decryptedBillingInfo?.creditCard?.cardHolderState}
        <br />
        {decryptedBillingInfo?.creditCard?.cardHolderZipcode}
      </>
    );
  }, [
    decryptedBillingInfo?.creditCard?.cardHolderAddressLine1,
    decryptedBillingInfo?.creditCard?.cardHolderAddressLine2,
    decryptedBillingInfo?.creditCard?.cardHolderCity,
    decryptedBillingInfo?.creditCard?.cardHolderState,
    decryptedBillingInfo?.creditCard?.cardHolderZipcode,
  ]);

  const renderDeliveryInfo = useMemo(() => {
    if (invoiceData?.shippingInfo?.shippingMethod.toLowerCase().includes("pickup")) {
      return invoiceData?.shippingInfo?.shippingMethod;
    }
    return (
      <>
        <i>Ship To:</i> {invoiceData?.shippingInfo?.shipTo}
        <br />
        {invoiceData?.shippingInfo?.shippingAddress?.addressLine1}
        <br />
        {invoiceData?.shippingInfo?.shippingAddress?.addressLine2 ?? "-"}
        <br />
        {invoiceData?.shippingInfo?.shippingAddress?.city}, {invoiceData?.shippingInfo?.shippingAddress?.state}
        <br />
        {invoiceData?.shippingInfo?.shippingAddress?.zipcode}
        <br />
        <br />
      </>
    );
  }, [
    invoiceData?.shippingInfo?.shipTo,
    invoiceData?.shippingInfo?.shippingAddress?.addressLine1,
    invoiceData?.shippingInfo?.shippingAddress?.addressLine2,
    invoiceData?.shippingInfo?.shippingAddress?.city,
    invoiceData?.shippingInfo?.shippingAddress?.state,
    invoiceData?.shippingInfo?.shippingAddress?.zipcode,
    invoiceData?.shippingInfo?.shippingMethod,
  ]);

  const renderColumnItem = useCallback(
    (title = "", value = "", rowCol = 4, rightCol = 4, leftCol = 8) => (
      <Grid container item xs={rowCol}>
        <Grid item xs={rightCol}>
          <strong>{`${title}:`} </strong>
        </Grid>
        <Grid item xs={leftCol}>
          {value}
        </Grid>
      </Grid>
    ),
    [],
  );

  const renderInvoiceInfo = useCallback(
    invoiceDetail => {
      const patientList = invoiceDetail?.patients ?? invoiceDetail?.patientsDetail ?? [];
      const patientNames = patientList
        .map((a) => a?.firstName + ' ' + a?.lastName)
        .join(', ');

      return (
        <>
          <Grid container spacing={1}>
            <Grid container item>
              {renderColumnItem("Clinic Name", invoiceData?.clinicName)}
              {renderColumnItem("Bill To", decryptedBillingInfo?.creditCard?.cardHolderName)}
              {renderColumnItem("Invoice Number", invoiceDetail?.invoiceNumber)}
            </Grid>
            <Grid container item>
              {renderColumnItem("Clinic Address", renderMainInvoiceInfo(true))}
              {renderColumnItem(
                "Credit Card #",
                `**** **** **** ${decryptedBillingInfo?.creditCard?.creditCardNumber?.substring(
                  decryptedBillingInfo?.creditCard?.creditCardNumber.length - 4,
                )}`,
              )}
              {renderColumnItem("Invoice Creation", dateFormatterMountainTime(invoiceDetail?.prescriptionDate))}
            </Grid>
            <Grid container item>
              {renderColumnItem(
                "Prescriber Name",
                `${invoiceDetail?.providerDetails?.firstName ?? ""} ${invoiceDetail?.providerDetails?.lastName ?? ""}`,
              )}
              {renderColumnItem("Expiration Date", decryptedBillingInfo?.creditCard?.cardExpirationDate)}
              {renderColumnItem("Invoice Complete", dateFormatterMountainTime(invoiceData?.orderDate))}
            </Grid>
            <Grid container item>
              {renderColumnItem("Patient Name",patientNames)}
              {renderColumnItem("Billing Address", renderBillingAddress)}
            </Grid>
          </Grid>
          <Divider variant="fullWidth" className="my-3" />
        </>
      );
    },
    [
      decryptedBillingInfo?.creditCard?.cardExpirationDate,
      decryptedBillingInfo?.creditCard?.cardHolderName,
      decryptedBillingInfo?.creditCard?.creditCardNumber,
      invoiceData?.clinicName,
      renderBillingAddress,
      renderColumnItem,
      renderMainInvoiceInfo,
    ],
  );

  const bulkInvoiceAmount = useMemo(() => {
    if (isBulkInvoice) {
      return (
        <Grid container item xs={4} alignSelf={"flex-start"}>
          {renderColumnItem("Shipping Cost", `$${(invoiceData?.shippingCost || 0)?.toFixed(2)}`, 12, 4, 0)}
          {renderColumnItem("Total", `$${invoiceData?.totalAmount?.toFixed(2)}`, 12, 4, 0)}
        </Grid>
      );
    }
  }, [invoiceData?.shippingCost, invoiceData?.totalAmount, isBulkInvoice, renderColumnItem]);

  const renderProductDetails = useCallback(
    (invoiceDetail = {}) => {
      // patients => for single invoice ,   patientsDetail => for bulk invoice
      const patientList = invoiceDetail?.patients ?? invoiceDetail?.patientsDetail ?? [];
      const isMultiPatient = patientList?.length > 1;
      const renderInvoiceProducts = patientList.map((patient, patientIndex) => {
        const patientName = (patient?.firstName ?? "-") + " " + (patient?.lastName ?? "");
        const filterInvoiceProducts = filter(invoiceDetail?.patientsProducts, { patientId: patient.id });
        let patientsProduct =
          filterInvoiceProducts.length > 0 ? filterInvoiceProducts?.[0]?.products : invoiceData?.products;
        return patientsProduct?.map((product, _) => {
          let actualProduct = product;
          if (filterInvoiceProducts?.length > 0) {
            actualProduct = filter(invoiceDetail?.products, { productId: product?.productId })?.[0];
          }
          const isMedicalAccessory =
            product?.family?.toUpperCase() === "MEDICAL ACCESSORIES" ||
            actualProduct?.family?.toUpperCase() === "MEDICAL ACCESSORIES" ||
            product?.subCategory1?.toUpperCase() === "MEDICAL ACCESSORIES" ||
            actualProduct?.subCategory1?.toUpperCase() === "MEDICAL ACCESSORIES";

          const rxNumberPatient = !isMedicalAccessory ? product?.rxNumber || actualProduct?.rxNumber : "MA";
          return (
            <TableRow key={`${patient?.id}-${actualProduct?._id}`}>
              {isMultiPatient ? (
                <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>{patientName}</TableCell>
              ) : null}
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>{actualProduct?.name}</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>{rxNumberPatient ?? ""}</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>{actualProduct?.quantity ?? ""}</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>{actualProduct?.unitPrice}</TableCell>
              <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>
                {actualProduct?.discountedPrice}
              </TableCell>
              <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>
                {parseFloat(actualProduct?.discountedPrice * actualProduct?.quantity).toFixed(2)}
              </TableCell>
            </TableRow>
          );
        });
      });
      return flatten(renderInvoiceProducts);
    },
    [invoiceData?.products],
  );

  const renderInvoiceBillingInfo = useCallback(
    invoiceDetail => {
      return (
        <>
          <Grid container className="mt-3" rowSpacing={1}>
            {Boolean(invoiceDetail?.creditRequested) && (
              <>
                <Grid item xs={3} md={2} />
                <Grid item xs={3} md={2} className="text-start" />
                <Grid item md={4} className="d-none d-md-block" />

                <Grid item xs={3} md={2} className="text-end">
                  Credit Used
                </Grid>
                <Grid item xs={3} md={2} className="text-end">
                  <strong>$ {parseFloat(invoiceDetail?.creditRequested).toFixed(2)}</strong>
                </Grid>
              </>
            )}

            <Grid item xs={3} md={2}>
              Priority Order
            </Grid>
            <Grid item xs={3} md={2} className="text-start">
              <strong>{invoiceDetail?.shippingInfo?.isRushOrder ? "Yes" : "No"}</strong>
            </Grid>
            <Grid item md={4} className="d-none d-md-block" />

            <Grid item xs={3} md={2} className="text-end">
              Sub Total
            </Grid>
            <Grid item xs={3} md={2} className="text-end">
              <strong>
                ${" "}
                {isBulkInvoice
                  ? parseFloat(invoiceDetail?.subTotal).toFixed(2)
                  : parseFloat(
                    invoiceDetail?.totalAmount -
                    (invoiceDetail?.shippingCost ?? 0) -
                    (invoiceDetail?.shippingInfo?.rushOrderCost ?? 0),
                  ).toFixed(2)}
              </strong>
            </Grid>
          </Grid>
          {!isBulkInvoice && (
            <Grid container className="my-2">
              <Grid item xs={3} md={2}>
                Shipping Method
              </Grid>
              <Grid item xs={3} md={2} className="text-start">
                <strong>{invoiceDetail?.shippingInfo?.shippingMethod}</strong>
              </Grid>
              <Grid item md={4} className="d-none d-md-block" />

              <Grid item xs={3} md={2} className="text-end">
                Shipping
              </Grid>
              <Grid item xs={3} md={2} className="text-end">
                <strong>${invoiceDetail?.shippingCost ?? "0"}</strong>
              </Grid>
            </Grid>
          )}
          {invoiceDetail?.shippingInfo?.isRushOrder ? (
            <>
              <Grid container>
                <Grid item xs={3} md={2}>
                  Signature Required
                </Grid>
                <Grid item xs={3} md={2} className="text-start">
                  <strong>{invoiceDetail?.shippingInfo?.isSignatureRequired ? "Yes" : "No"}</strong>
                </Grid>
                <Grid item md={4} className="d-none d-md-block" />
                <Grid item xs={3} md={2} className="text-end">
                  Priority Order Fee
                </Grid>
                <Grid item xs={3} md={2} className="text-end">
                  <strong>$ {invoiceDetail?.shippingInfo?.rushOrderCost}</strong>
                </Grid>
              </Grid>
              {!isBulkInvoice && (
                <Grid container className="mt-2" justifyContent={"flex-end"}>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>Total</strong>
                  </Grid>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>$ {parseFloat(invoiceDetail?.totalAmount).toFixed(2)}</strong>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <Grid container>
              <Grid item xs={3} md={2}>
                Signature Required
              </Grid>
              <Grid item xs={3} md={2} className="text-start">
                <strong>{invoiceDetail?.shippingInfo?.isSignatureRequired ? "Yes" : "No"}</strong>
              </Grid>
              {!isBulkInvoice && (
                <>
                  <Grid item md={4} className="d-none d-md-block" />
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>Total</strong>
                  </Grid>
                  <Grid item xs={3} md={2} className="text-end">
                    <strong>$ {parseFloat(invoiceDetail?.totalAmount).toFixed(2)}</strong>
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {invoiceDetail?.signatureFileUrl && (
            <div className="my-5 mx-1">
              <p className="bold">Signature</p>
              <div className="flexJCAC justify-content-start">
                <img src={invoiceDetail?.signatureFileUrl} width={180} alt="Signature" />
              </div>
              <p>
                Signed by: {invoiceDetail?.providerDetails?.firstName + " " + invoiceDetail?.providerDetails?.lastName}
              </p>
            </div>
          )}
        </>
      );
    },
    [isBulkInvoice],
  );

  const renderInvoiceTable = useCallback(
    invoiceTableData => {
      const isMultiPatient = invoiceTableData?.patientIds?.length > 1;
      return (
        <>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {isMultiPatient ? (
                      <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Patient </TableCell>
                    ) : null}
                    <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Medication </TableCell>
                    <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>RX #</TableCell>
                    <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>QTY</TableCell>
                    <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Unit Price($)</TableCell>
                    <TableCell style={{ paddingRight: "4px", paddingLeft: "4px" }}>Discounted Price($)</TableCell>
                    <TableCell style={{ paddingRight: "0px", paddingLeft: "4px" }}>Total Price($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderProductDetails(invoiceTableData)}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {renderInvoiceBillingInfo(invoiceTableData)}
        </>
      );
    },
    [renderInvoiceBillingInfo, renderProductDetails],
  );

  const renderInvoiceView = useCallback(
    invoiceDetail => (
      <div className="page-break">
        <Card sx={{ boxShadow: 5 }} className={classNames({ "my-3": isBulkInvoice })}>
          <CardContent>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <img src={VPICompoundingLogo} alt="logo" height="40" />
                <h4 className="float-end font-size-16 mt-3">Invoice for {invoiceData?.clinicName}</h4>
              </Grid>
              <Divider variant="fullWidth" className="my-3" />
              {renderInvoiceInfo(invoiceDetail)}
              <Grid container item>
                {renderColumnItem("Delivery Method", renderDeliveryInfo, 8, 2, 0)}
                {bulkInvoiceAmount}
              </Grid>
              {renderColumnItem("Tracking Number", invoiceData?.trackingNumber || "-", 8, 2, 0)}
              {renderColumnItem("Order Notes", invoiceDetail?.shippingInfo?.orderNotes || "-", 8, 2, 0)}
              <Divider variant="fullWidth" className="my-3" />
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  <strong> Prescription Order</strong>
                </Typography>
              </Grid>
              <Divider variant="fullWidth" className="my-1" />
              {renderInvoiceTable(invoiceDetail)}
            </Grid>
            <Divider variant="fullWidth" className="my-3" />
          </CardContent>
        </Card>
      </div>
    ),
    [
      bulkInvoiceAmount,
      invoiceData?.clinicName,
      invoiceData?.trackingNumber,
      isBulkInvoice,
      renderColumnItem,
      renderDeliveryInfo,
      renderInvoiceInfo,
      renderInvoiceTable,
    ],
  );

  const renderInvoice = useMemo(() => {
    if (isBulkInvoice) {
      return map(invoiceData?.subPrescriptionList, subPrescription => (
        <React.Fragment key={subPrescription.id}>{renderInvoiceView(subPrescription)}</React.Fragment>
      ));
    }
    return renderInvoiceView(invoiceData);
  }, [invoiceData, isBulkInvoice, renderInvoiceView]);

  return (
    <Dialog open={Boolean(invoiceId)} onClose={closeModal} scroll="paper" fullWidth maxWidth="xl">
      {printLoading && <Spinner />}
      <DialogTitle variant="h3">
        Invoice Detail
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isFetching ? (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className="mt-1 no-shadow" ref={componentRef}>
            <AlertMessage isError msg={error?.data?.message} />
            {renderInvoice}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={disableActions} variant="contained" className="pinkButton" onClick={printModal}>
          Print
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default React.memo(InvoiceDetailModal);
