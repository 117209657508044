import { ClinicLocationProfileProvider } from "context";
import React from "react";
import ClinicLocationProfile from "./profile";

function ClinicLocationWrapper() {
  return (
    <ClinicLocationProfileProvider>
      <ClinicLocationProfile />
    </ClinicLocationProfileProvider>
  );
}

export default React.memo(ClinicLocationWrapper);
