import moment from "moment";
import { columnHelper } from "./PrescriptionColumns";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { dateFormatterMountainTime } from "utils/constants";
import { formatDaySupply } from "utils/HelperFunctions";

const ADMIN_PATIENT_COLUMNS = [
  columnHelper.accessor("firstName", {
    header: "Patient Name",
    cell: ({ row }) => `${row.original?.firstName ?? ""} ${row.original?.lastName ?? ""}`,
  }),
  columnHelper.accessor("clinicName", {
    header: "Clinic",
  }),
  columnHelper.accessor("clinicLocationName", {
    header: "Clinic Location",
  }),
  columnHelper.accessor("dateOfBirth", {
    header: "Patient info",
    cell: props => {
      const DOB = props.getValue() || "-";
      const email = props.row?.original?.email || "-";
      const gender = props.row?.original?.sex || "-";
      const cellPhone = props.row?.original?.cellPhone || "-";
      return (
        <>
          <p className="smallText semiBold m-0">
            DOB: <span className="text-muted normalText">{DOB}</span>
          </p>
          <p className="smallText semiBold m-0">
            Gender: <span className="text-muted normalText">{gender}</span>
          </p>
          <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
            Email: <span className="text-muted normalText">{email}</span>
          </p>
          <p className="smallText semiBold m-0">
            Phone: <span className="text-muted normalText">{cellPhone}</span>
          </p>
        </>
      );
    },
  }),
  columnHelper.accessor("id", {
    header: "Prescription History",
    enableSorting: false,
    cell: props => {
      const id = props.getValue();
      return (
        <Link
          to={{
            pathname: "/patient-profile/" + id,
            state: {
              activeTab: "1",
            },
          }}>
          View
        </Link>
      );
    },
  }),
  columnHelper.accessor("lastOrderDate", {
    header: "Last order info",
    cell: props => {
      const lastDate = props.getValue();
      const lasOrderStatus = props.row?.original?.lastOrderStatus || "-";
      return (
        <>
          <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
            Date:{" "}
            <span className="text-muted normalText">{lastDate ? moment(lastDate).format("MM/DD/YYYY") : "-"}</span>
          </p>
          <p className="smallText semiBold m-0">
            Status:{" "}
            <span
              className={classNames("text-muted normalText", {
                yellowText: lasOrderStatus === "Pending",
                redText: lasOrderStatus === "Cancelled" || lasOrderStatus === "Rejected",
                greenText: lasOrderStatus === "Approved",
              })}>
              {lasOrderStatus}
            </span>
          </p>
        </>
      );
    },
  }),
];

const PRESCRIPTION_PATIENT_COLUMNS = [
  columnHelper.accessor("firstName", {
    header: "Patient Name",
    cell: ({ row }) => `${row.original?.firstName ?? ""} ${row.original?.lastName ?? ""}`,
  }),
  columnHelper.accessor("dateOfBirth", {
    header: "Patient info",
    cell: props => {
      const DOB = props.getValue() || "-";
      const email = props.row?.original?.email || "-";
      const gender = props.row?.original?.sex || "-";
      const cellPhone = props.row?.original?.cellPhone || "-";
      return (
        <>
          <p className="smallText semiBold m-0">
            DOB: <span className="text-muted normalText">{DOB}</span>
          </p>
          <p className="smallText semiBold m-0">
            Gender: <span className="text-muted normalText">{gender}</span>
          </p>
          <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
            Email: <span className="text-muted normalText">{email}</span>
          </p>
          <p className="smallText semiBold m-0">
            Phone: <span className="text-muted normalText">{cellPhone}</span>
          </p>
        </>
      );
    },
  }),
];

const CLINIC_PATIENT_SUMMARY_COLUMNS = [
  columnHelper.accessor("prescriptionDate", {
    header: "Date",
    headerStyle: { width: 230 },
    cell: props => dateFormatterMountainTime(props.getValue()),
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
    headerStyle: { width: 260, textAlign: "center" },
  }),
];
const NESTED_CLINIC_PATIENT_SUMMARY_COLUMNS = [
  columnHelper.accessor("name", {
    id: "Product",
    header: "Product(s)",
    headerStyle: { width: "300px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("products", {
    header: "Info",
    id: "Info",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: ({ row }) => {
      const quantity = row?.original?.quantity || "-";
      const daySupply = row?.original?.daySupply ? row?.original?.daySupply.toFixed(1) : "-";
      const refills = row?.original?.refills || "-";
      return (
        <>
          <p className="m-0 text-nowrap">{`Quantity: ${quantity}`}</p>
          <p className="m-0 text-nowrap">{`Day Supply: ${formatDaySupply(daySupply)}`}</p>
          <p className="m-0 text-nowrap">{`Refills: ${refills}`}</p>
        </>
      );
    },
  }),
  columnHelper.accessor("sig", {
    header: "Sig",
    id: "Sig",
  }),

  columnHelper.accessor("patientDiagnosis", {
    header: "Patient Diagnosis",
    id: "Diagnosis",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),

  columnHelper.accessor("reasonForCompoundedMedication", {
    header: "Compounded Medication Reason",
    id: "Reason",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),
];

export {
  CLINIC_PATIENT_SUMMARY_COLUMNS,
  ADMIN_PATIENT_COLUMNS,
  PRESCRIPTION_PATIENT_COLUMNS,
  NESTED_CLINIC_PATIENT_SUMMARY_COLUMNS,
};
