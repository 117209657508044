import { StaffProvider } from "context";
import React from "react";
import InsertStaff from "./InsertStaff";

function ManageStaff() {
  return (
    <StaffProvider>
      <InsertStaff />
    </StaffProvider>
  );
}

export default React.memo(ManageStaff);
