import moment from "moment";

export const isPrescriptionInProgress = (startDate, endDate) => {
  return (
    moment(startDate).startOf("day").isSameOrBefore(moment().startOf("day")) &&
    moment(endDate).startOf("day").isAfter(moment().startOf("day"))
  );
};


export const getDaysPassed = (startDate) =>
  moment().startOf("day").diff(moment(startDate).startOf("day"), "days");

  