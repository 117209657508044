/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, List, ListItem, ListItemText } from "@mui/material";
import { FormFileField, FormOutlineField, FormSelectField } from "components";
import { ClinicLocationApiStateContext, ClinicLocationStateContext } from "context";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  DISCOVERY_MEDIUM,
  PARTIAL_SHIPMENT_OPTIONS,
  PREFERRED_BILLING_METHODS,
  REFILL_COMMUNICATION,
  REFILL_MANAGEMENT,
  getFileNames,
} from "utils";

const ClinicLocationContact = forwardRef(({ formId, formData, additionalInfo = false }, parentRef) => {
  const formRef = useRef(null);
  const { control, handleSubmit, getValues, setValue, trigger } = useForm();
  const { isClinicLocationEdit } = useContext(ClinicLocationStateContext);
  const { clinicLocationData } = useContext(ClinicLocationApiStateContext);

  const watchCompanyLogo = useWatch({ control, name: "companyLogoImageUrl" });

  useEffect(() => {
    if (isClinicLocationEdit && clinicLocationData) {
      const formFields = getValues();
      for (const field in formFields) {
        let fieldValue = clinicLocationData?.[field] ?? "";
        setValue(field, fieldValue);
      }
      submitAction(getValues(), null, false);
    }
  }, [isClinicLocationEdit, clinicLocationData]);

  const submitAction = useCallback(
    (data, progressBarIndex = null, allowNavigation = true) => {
      formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const getFileListItems = useCallback((fileArray, docName) => {
    const hrefArray = getValues(docName);
    return fileArray.map(({ name, removeAble }, index) => (
      <ListItem key={`lab-${name}`} disablePadding disableGutters>
        <ListItemText
          role={!removeAble ? "button" : undefined}
          primaryTypographyProps={{ color: "grey", noWrap: true }}
          style={{ marginRight: 20 }}
          onClick={e => {
            e.preventDefault();
            if (!removeAble) {
              const newWindow = window.open(hrefArray[index], "_blank", "noopener,noreferrer");
              if (newWindow) newWindow.opener = null;
            }
          }}
          primary={name}
        />
      </ListItem>
    ));
  }, []);

  const stateLicenseDocList = useMemo(() => {
    if (watchCompanyLogo) {
      const fileNames = getFileNames([watchCompanyLogo]);
      return getFileListItems(fileNames, "companyLogoImageUrl");
    }
  }, [getFileListItems, watchCompanyLogo]);

  const additionalFields = useMemo(() => {
    if (additionalInfo) {
      return (
        <>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name={"preferredBillingMethod"}
              control={control}
              rules={{ required: "Preferred Billing Method is required" }}
              MuiFieldProps={{ label: "Preferred Billing Method", optionArray: PREFERRED_BILLING_METHODS }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name={"partialShipmentsAvailableasNeeded"}
              control={control}
              rules={{ required: "Partial Shipment is required" }}
              MuiFieldProps={{ label: "Partial Shipments Available as Needed", optionArray: PARTIAL_SHIPMENT_OPTIONS }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name={"prescriptionRefillCommunication"}
              control={control}
              rules={{ required: "Refill Communication is required" }}
              MuiFieldProps={{ label: "Prescription Refill Communication", optionArray: REFILL_COMMUNICATION }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name={"refillManagement"}
              control={control}
              rules={{ required: "Refill Management is required" }}
              MuiFieldProps={{ label: "Refill Management", optionArray: REFILL_MANAGEMENT }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              name={"howDidYouHearAboutVPICompounding"}
              control={control}
              MuiFieldProps={{ label: "How did you hear about VPI Compounding?", optionArray: DISCOVERY_MEDIUM }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormOutlineField name="other" control={control} MuiFieldProps={{ label: "Other" }} />
          </Grid>
          <Grid item xs={12} sm={6} style={{ paddingTop: 0 }}>
            <FormFileField
              name={"companyLogoImageUrl"}
              control={control}
              MuiInputProps={{ accept: ".jpg, .png, .jpeg, .pdf" }}
              MuiFieldProps={{ label: "Company Logo" }}
            />
            <List dense={true}>{stateLicenseDocList}</List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormOutlineField
              name="clinicWebsiteURL"
              control={control}
              rules={{ pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" } }}
              MuiFieldProps={{ label: "Clinic Website URL" }}
            />
          </Grid>
        </>
      );
    }
  }, [additionalInfo, control, stateLicenseDocList]);

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container columnSpacing={3} rowSpacing={1}>
        {/* <Grid item xs={12} sm={6}>
          <FormOutlineField
            name="officeContactName"
            control={control}
            rules={{ required: "Office contact name is required." }}
            MuiFieldProps={{ label: "Office Contact Name" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormOutlineField
            name="officeContactPosition"
            control={control}
            rules={{ required: "Contact position is required." }}
            MuiFieldProps={{ label: "Contact Position" }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormOutlineField
            name="officeContactPhone"
            control={control}
            rules={{
              required: "Contact number is required.",
              pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" },
            }}
            MuiInputProps={{ format: "(###) ###-####" }}
            MuiFieldProps={{ label: "Contact Number", placeholder: "(123) 456-7890", isMasked: true }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <FormOutlineField
            name="officeContactEmail"
            control={control}
            rules={{ required: "Email address is required." }}
            MuiFieldProps={{ label: "Email Address" }}
          />
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <FormOutlineField
            name="officeContactFax"
            control={control}
            rules={{ pattern: { value: /^(\d{10})?$/, message: "Invalid Fax Number" } }}
            MuiInputProps={{ format: "(###) ###-####" }}
            MuiFieldProps={{ label: "Fax Number", placeholder: "(123) 456-7890", isMasked: true }}
          />
        </Grid>
        {additionalFields}
      </Grid>
    </Box>
  );
});

export default React.memo(ClinicLocationContact);
