import fam1 from "./Allergy.jpg";
import fam2 from "./Analgesic Photo.jpg";
import fam3 from "./Anesthetic.jpg";
import fam4 from "./Antacid.jpg";
import fam5 from "./Anti Inflammatory.jpg";
import fam6 from "./Parasitic.jpg";
import fam7 from "./Antibiotic.jpg";
import fam8 from "./Anti Fungal.jpg";
import fam9 from "./Hypertensive.jpg";
import fam10 from "./Anxiolytic.jpg";
import fam11 from "./BAC.jpg";
import fam12 from "./Dermatology.jpg";
import fam13 from "./HRT.jpg";
import fam14 from "./Hair Restore.jpg";
import fam15 from "./Hair Restore Treatment.jpg";
import fam16 from "./IM Therapy.jpg";
import fam17 from "./IV Therapy.jpg";
import fam18 from "./SQ Therapy.jpg";
import fam19 from "./Medical Accessory.jpg";
import fam20 from "./Nutritional Support.jpg";
import fam21 from "./Opthalmic.jpg";
import fam22 from "./Oral Therapy.jpg";
import fam23 from "./Peptide Therapy.jpg";
import fam24 from "./Recovery.jpg";
import fam25 from "./Rush.jpg";
import fam26 from "./IM Therapy.jpg";
import fam27 from "./IV Therapy.jpg";
import fam28 from "./Sexual Health.jpg";
import fam29 from "./IV Therapy.jpg";
import fam30 from "./IV Therapy.jpg";
import fam31 from "./Sexual Health.jpg";
import fam32 from "./injectable.jpg";
import fam33 from "./Weightloss.jpg";
import fam34 from "./Sleep Aid.jpg";
import fam35 from "./Opthalmic.jpg";
import fam36 from "./thyroid.jpg";

import fam37 from "./hormone.jpg";
import fam38 from "./injectable.jpg";
import fam39 from "./other.jpg";
import fam40 from "./pain.jpg";
import fam41 from "./sexual.jpg";
import fam42 from "./weight.jpg";
import fam43 from "./wellness.jpg";

const FamPhotos = [
  { name: "Allergy", photo: fam1 },
  { name: "Analgesic", photo: fam2 },
  { name: "Anesthetic", photo: fam3 },
  { name: "Antacid", photo: fam4 },
  { name: "Anti-Inflammatory", photo: fam5 },
  { name: "Anti-Parasitic", photo: fam6 },
  { name: "Antibiotic", photo: fam7 },
  { name: "Anti-Fungal", photo: fam8 },
  { name: "AntiHypertensive", photo: fam9 },
  { name: "Anxiolytic", photo: fam10 },
  { name: "BAC Water", photo: fam11 },
  { name: "Dermatology", photo: fam12 },
  { name: "HRT", photo: fam13 },
  { name: "Hair Restore", photo: fam14 },
  { name: "Hair Restore Treatment", photo: fam15 },
  { name: "Oral Therapy", photo: fam16 },

  { name: "IV Therapy", photo: fam17 },
  { name: "SQ/IM Therapy", photo: fam18 },
  { name: "Medical Accessories", photo: fam19 },
  { name: "Nutritional Support", photo: fam20 },
  { name: "Opthalmic", photo: fam21 },
  { name: "Oral Therapy", photo: fam22 },
  { name: "Peptide Therapy", photo: fam23 },
  { name: "Recovery", photo: fam24 },
  { name: "Rush", photo: fam25 },

  { name: "Sexual Health", photo: fam28 },
  { name: "Injectable", photo: fam38 },
  { name: "Weight Loss Therapy", photo: fam33 },

  { name: "Sleep Aid", photo: fam34 },
  { name: "Ophthalmic", photo: fam35 },
  { name: "Thyroid", photo: fam36 },

  { name: "Hormone Restoration", photo: fam37 },
  { name: "Injectables", photo: fam38 },
  { name: "Other", photo: fam39 },
  { name: "Pain Management", photo: fam40 },
  { name: "Sexual Well-Being", photo: fam41 },
  { name: "Weight Management", photo: fam42 },
  { name: "Wellness", photo: fam43 },
];
export default FamPhotos;
