import { Grid, Step, StepButton, StepLabel, Stepper, useTheme } from "@mui/material";
import { CustomStepConnector, CustomStepIcon } from "components/StyledComponent";
import React, { useCallback } from "react";
import PropTypes from "prop-types";

function FormStepper({ stepper, activeStep, completedStep, clickAction }) {
  const { typography } = useTheme();

  const handleStep = useCallback(
    step => () => {
      clickAction(step, activeStep);
    },
    [activeStep, clickAction],
  );

  return (
    <Grid container justifyContent={"center"} marginY={5}>
      <Grid item xs={12} sm={10} md={8}>
        <Stepper nonLinear alternativeLabel activeStep={activeStep} connector={<CustomStepConnector />}>
          {stepper.map((label, index) => {
            const disabled = !completedStep[index];
            return (
              <Step
                key={label}
                // last
                completed={completedStep[index]}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: typography.allVariants.color,
                  },
                  "& .MuiStepLabel-root .Mui-active div": {
                    backgroundColor: "rgba(63, 108, 166,0.9)",
                  },
                }}>
                <StepButton disabled={disabled} onClick={handleStep(index)}>
                  <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
    </Grid>
  );
}

// Types of Component Props
FormStepper.propTypes = {
  stepper: PropTypes.arrayOf(PropTypes.string).isRequired,
  completedStep: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  clickAction: PropTypes.func.isRequired,
};

// Creating default props
FormStepper.defaultProps = {
  stepper: [],
  completedStep: { 0: true },
  activeStep: 0,
  clickAction: () => null,
};

export default React.memo(FormStepper);
