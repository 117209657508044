import { createContext } from "react";

const StaffApiStateContext = createContext({
  staffData: null,
  clinicFetching: false,
  clinicLocationOption: [],
});

const StaffStateContext = createContext({
  profilePic: "",
  mutateError: "",
  showSpinner: false,
  isStaffEdit: false,
  isEditProfile: false,
  fileUploading: false,
  mutateLoading: false,
  profileImageUploading: false,
  staffInsertSuccessfully: false,
});
const StaffActionContext = createContext({
  removeProfileImage: () => null,
  uploadFile: fieldName => () => null,
});

const StaffFormSateContext = createContext({
  formRef: { current: [] },
  activeStep: 0,
  completedStep: { 0: true },
});

const StaffFormActionContext = createContext({
  goBack: () => null,
  goNext: () => null,
  formData: () => null,
  progressBarAction: () => null,
});

export { StaffActionContext, StaffApiStateContext, StaffStateContext, StaffFormSateContext, StaffFormActionContext };
