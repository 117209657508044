import { AdminApi } from "../../rtkApi";
import { ADMIN_END_POINTS, API_METHODS, CACHE_TAGS, END_POINTS, PAGE_TO_ENDPOINT } from "../../rtkConstant";

const AdminProductsMutation = AdminApi.injectEndpoints({
  endpoints: builder => ({
    adminUpdateDiscountPrice: builder.mutation({
      query: body => ({
        url: ADMIN_END_POINTS.UPDATE_DISCOUNT_PRICE,
        method: API_METHODS.POST,
        body,
      }),
      async onQueryStarted(apiArgs, { queryFulfilled, getState, dispatch }) {
        const { data } = await queryFulfilled;
        const listingEndpoint = PAGE_TO_ENDPOINT.ClinicProducts;
        let queryCache = AdminApi.util.selectInvalidatedBy(getState(), [
          { type: CACHE_TAGS.PRODUCT, id: apiArgs?.productId },
        ]);
        if (queryCache.length > 1) {
          queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
        } else {
          queryCache = queryCache[0];
        }
        dispatch(
          AdminApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => ({
            ...listData,
            products: listData?.products?.map(product => {
              if (product.id === apiArgs.productId) {
                return { ...product, discountedPrice: apiArgs?.discountedPrice, is_price_modified: true };
              }
              return product;
            }),
          })),
        );
      },
    }),
    changePricingGroupByClinic: builder.mutation({
      query: body => ({
        url: END_POINTS.CHANGE_PRICING_GROUP,
        method: API_METHODS.POST,
        body,
      }),
    }),
  }),
});
export const { useAdminUpdateDiscountPriceMutation, useChangePricingGroupByClinicMutation } = AdminProductsMutation;
