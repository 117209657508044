import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const GoogleAuthApi = createApi({
  reducerPath: "GoogleAuthApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [CACHE_TAGS.GOOGLE_AUTH],
});

export default GoogleAuthApi;
