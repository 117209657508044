import React, { useMemo } from "react";
import { Link } from "react-router-dom";

function PrescriptionOrderButton({ showView = false, selectedPatients = [], clinicId = null, clinicLocationId = "" }) {
  const showButton = useMemo(() => Boolean(selectedPatients.length), [selectedPatients.length]);
  if (!showView || !clinicId) {
    return null;
  }

  return showButton ? (
    <Link
      to={{
        pathname: `/new-prescription/${clinicId}`,
        state: {
          clinicId: clinicId,
          selectedPatients,
          isBulkOrder: selectedPatients.length > 1,
          clinicLocationId: clinicLocationId,
        },
      }}
      className={`d-flex pinkButton mr-2`}
    >
      Order new prescription
    </Link>
  ) : (
    <div className="d-flex align-items-center">
      <p className="yellowText " style={{ height: "fit-content" }}>
        Select one or more patients below to start new order
      </p>
    </div>
  );
}

export default React.memo(PrescriptionOrderButton);
