import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Alert } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ClinicNavbar from "../../../components/ClinicNavbar";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import { USER_ROLES } from "../../../utils";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import PrimaryModal from "../../../components/primaryModal";
import PaginationComponent from "react-reactstrap-pagination";
import axios from "axios";
import NavBar from "components/NavBar";
import AlertMessage from "../Prescriptions/components/alert_message";

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;

class StaffList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSpinner: true,
      error: "",
      disableFilter: true,
      keyword: "",
      confirmDeleteModal: false,
      deleteStaffId: "",
      page: 1,
      sizePerPage: 10,
      data: [],
      success: "",
      tableHeaders: [
        { text: "id", dataField: "id", sort: true, hidden: true },
        { text: "Staff Name", dataField: "name", sort: true },
        { text: "Clinic Location", dataField: "location", sort: true },
        { text: "Staff Type", dataField: "staff_type", sort: true },
        { text: "Email", dataField: "email", sort: true },
        { text: "Mobile Number", dataField: "phone_number", sort: true },
        {
          text: "Status",
          dataField: "verified",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <span className={cellContent === "Verified" ? "text-success" : "text-danger"}>{cellContent}</span>
            </>
          ),
        },
        {
          text: "Auto-Gen Password",
          dataField: "autogen",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              <span
                className="blueText"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => this.resendAutoGenPass(row.id)}>
                Resend
              </span>
            </>
          ),
        },
        {
          text: "Actions",
          dataField: "actions",
          headerStyle: (colum, colIndex) => {
            return { width: "200px", textAlign: "center" };
          },
          formatter: (cellContent, row) => (
            <>
              <Link
                to={
                  new Cookies().get("user_role")?.toLowerCase().includes("clinic")
                    ? "/staff-profile/" + row.id
                    : "/staff-profile/" + this.props.match.params.clinicId + "/" + row.id
                }
                className="pinkButton">
                Profile
              </Link>
              <Link
                to={
                  new Cookies().get("user_role")?.toLowerCase().includes("clinic")
                    ? "/edit-staff/" + row.id
                    : "/edit-staff/" + this.props.match.params.clinicId + "/" + row.id
                }>
                <i className="fas fa-edit medText mx-3"></i>
              </Link>
              {(new Cookies().get("user_role") === USER_ROLES.CLINIC_SUPER_USER ||
                new Cookies().get("isSuperUserSameAsProvider") === true) &&
              row.id !== new Cookies().get("user_id") ? (
                <Link
                  to="#"
                  onClick={() => {
                    this.setState({
                      deleteStaffId: row.id,
                      confirmDeleteModal: true,
                    });
                  }}>
                  <i className="fas fa-trash redText"></i>
                </Link>
              ) : null}
            </>
          ),
        },
      ],
    };
  }
  resendAutoGenPass(id) {
    this.setState({
      showSpinner: true,
    });

    axiosConfig
      .get("staffs/resendAutoGenerated/" + id, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        this.setState({
          success: "Auto Generated Password resent to staff's email",
          showSpinner: false,
        });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "Unable to resend the auto generated password " + error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }
  deleteStaff() {
    this.setState({
      showSpinner: true,
      confirmDeleteModal: false,
    });

    axiosConfig
      .delete("staffs/deleteStaffByStaffId", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
        data: {
          staffId: this.state.deleteStaffId,
          userId: new Cookies().get("user_id"),
        },
      })
      .then(async response => {
        this.deleteUserFromCometChat(this.state.deleteStaffId);
        await this.setState({
          showSpinner: false,
          error: "",
          data: this.state.data.filter(row => row.id !== this.state.deleteStaffId),
          deleteStaffId: "",
        });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to delete the staff. " + error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }
  closeConfirmDeleteModal() {
    this.setState({ confirmDeleteModal: false });
  }
  filterChanged(v = "") {
    this.setState({ disableFilter: v.length === 0 ? true : false });
  }

  clearFilter() {
    this.getAllStaff();
  }
  clearSearch(keyword) {
    if (keyword === "") {
      this.filterChanged(keyword);
      this.getAllStaff();
    }
  }
  /*Delete Cometchat USER*/
  async deleteUserFromCometChat(uid) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}`;
    const bodyVal = {
      permanent: true,
    };

    await axios
      .delete(url, {
        headers: {
          apiKey: cometChatApiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },

        data: bodyVal,
      })
      .then(async response => {})
      .catch(error => {});
  }

  /**/

  handlePageChange = pageNo => {
    const { sizePerPage = 10, keyword = "" } = this.state;
    this.setState({ page: pageNo });

    this.getAllStaff(keyword, sizePerPage, pageNo);
  };

  getAllStaff(keyword = "", limit = 10, pageNo = 1) {
    this.setState({
      page: pageNo,
      sizePerPage: limit,
      keyword,
      showSpinner: true,
    });

    var body = {};
    let role = new Cookies().get("user_role");
    let isSuperUserSameAsProvider = new Cookies().get("isSuperUserSameAsProvider");
    let condition = true;
    let clinicSide = role.toLowerCase().includes("clinic");
    if (!clinicSide) {
      body = {
        clinicId: this.props.match.params.clinicId,
        keyword: this.state.keyword,
        limit: this.state.sizePerPage,
        currentPage: pageNo,
      };
    } else {
      condition = role === USER_ROLES.CLINIC_SUPER_USER || isSuperUserSameAsProvider;
      if (condition) {
        body = {
          clinicId: new Cookies().get("clinic_id"),
          keyword: this.state.keyword,
          limit: this.state.sizePerPage,
          currentPage: pageNo,
        };
      } else {
        body = {
          clinicLocationId: new Cookies().get("clinic_location_ids"),
          keyword: this.state.keyword,
          limit: this.state.sizePerPage,
          currentPage: pageNo,
        };
      }
    }

    if (!keyword) delete body.keyword;

    axiosConfig
      .post(
        keyword
          ? condition
            ? "staffs/getAllStaffInClinic"
            : "staffs/getAllStaffInClinicLocation"
          : condition
          ? "staffs/getAllStaffInClinic"
          : "staffs/getAllStaffInClinicLocation",
        body,
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        //console.log(response.data);

        var dataList = [];

        await response.data.staff.forEach(s => {
          dataList.push({
            id: s.id,
            location: s.clinicLocationNames.join(","),
            name: s.firstName + " " + (s.lastName ? s.lastName : ""),
            staff_type: s.role,
            email: s.email,
            phone_number: s.phoneNumber,
            verified: s.isVerified ? "Verified" : "Not Verified",
          });
        });

        await this.setState({
          pagination: response.data.pagination,
          data: dataList,
          showSpinner: false,
        });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch clinic locations!",
        });
        errorResponse(error);
      });
  }

  componentDidMount() {
    this.getAllStaff();
  }

  render() {
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.data.length,
      custom: true,
    };
    const defaultSorted = [{ dataField: "id", order: "asc" }];
    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <NavBar />
        <div className="page-content">
          <MetaTags>
            <title>Clinic Staff | VPI Compounding</title>
          </MetaTags>
          <Container fluid>
            <h4 className="p-3 text-muted">CLINIC STAFF</h4>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    {this.state.showSpinner ? <Spinner /> : null}
                    {this.state.error ? (
                      <Alert color="danger" className="text-center">
                        {this.state.error}
                      </Alert>
                    ) : null}
                    {this.state.success ? <AlertMessage msg={this.state.success} /> : null}

                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.tableHeaders}
                      data={this.state.data}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField="id" columns={this.state.tableHeaders} data={this.state.data} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row
                                className={`px-3 my-4 ${
                                  this.state.showOrderButton ? "justify-content-between" : "justify-content-end"
                                }`}>
                                <Link
                                  to={
                                    new Cookies().get("user_role")?.toLowerCase().includes("clinic")
                                      ? "/add-staff/"
                                      : "/add-staff/" + this.props.match.params.clinicId
                                  }
                                  className="pinkButton">
                                  Add Staff
                                </Link>
                              </Row>
                              <Row>
                                <Col xs={6} sm={8} m={1} lg={2}>
                                  <div className="tableSearch d-flex">
                                    <i className="bx bx-search-alt search-icon flexJCAC" />
                                    <input
                                      className="borderless mx-2"
                                      onChange={e => {
                                        this.setState({
                                          keyword: e.target.value,
                                        });
                                        this.filterChanged(e.target.value);
                                        this.clearSearch(e.target.value);
                                      }}
                                      value={this.state.keyword}
                                      placeholder="Search table"
                                    />
                                  </div>
                                </Col>
                                <Col xs={4} sm={3} m={9} lg={1}>
                                  <button
                                    disabled={this.state.disableFilter}
                                    className="yellowButton w-60"
                                    onClick={() => {
                                      var key = this.state.keyword;
                                      var limit = this.state.sizePerPage;
                                      var pageNo = this.state.page;

                                      this.getAllStaff(key, limit, pageNo);
                                    }}>
                                    Search
                                  </button>
                                </Col>
                                {!this.state.disableFilter && (
                                  <Col xs={12} sm={1} lg={1}>
                                    <p
                                      disabled={this.state.disableFilter}
                                      className="blueText"
                                      style={{
                                        fontSize: 14,
                                        cursor: "pointer",
                                        paddingTop: 8,
                                        marginLeft: 10,
                                      }}
                                      onClick={() => {
                                        return this.clearFilter(), this.setState({ disableFilter: true });
                                      }}>
                                      Clear
                                    </p>
                                  </Col>
                                )}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={"table align-middle"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                    {this.state.data.length === 0 ? (
                                      <p className="w-100 text-center m-5">No staff data found!</p>
                                    ) : null}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-3 px-3">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationComponent
                                      size="sm"
                                      totalItems={this.state.sizePerPage * this.state.pagination?.totalPages || 0}
                                      pageSize={this.state.sizePerPage}
                                      onSelect={e => this.handlePageChange(e)}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <PrimaryModal
          question="Are you sure you want to delete this staff?"
          showModal={this.state.confirmDeleteModal}
          closeModal={this.closeConfirmDeleteModal.bind(this)}
          onClickYes={this.deleteStaff.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default StaffList;
