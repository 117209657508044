import { CometChat } from "@cometchat-pro/chat";
import { AuthApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, END_POINTS } from "../../rtkConstant";
import { login, logout } from "../../../utils";
import { startIntercom } from "../../../utils/intercom";

const AuthMutation = AuthApi.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const response = await baseQuery({
            url: END_POINTS.LOGIN,
            method: API_METHODS.POST,
            body: apiArgs,
          });
          if (response.data) {
            // Necessary for previous Cookie flow
            if (response.data.isResetPassword) {
              // Redirect to a new route
              window.location.href = "/reset-password/" + response.data?.id + "/" + response.data?.jwtToken;
            } else {
              login(
                response.data?.id,
                response.data?.email,
                response.data?.firstName,
                response.data?.lastName,
                response.data?.isVerified,
                response.data?.role,
                response.data?.jwtToken,
                response.data?.clinicId,
                response.data?.clinicLocationIds,
                response.data?.refreshToken
              );
              // Comet Chat Login Here
              if (!apiArgs.isPatientLogin) {
                CometChat.login(response.data?.id, process.env.REACT_APP_COMET_CHAT_AUTH_KEY).then(
                  () => {
                    // startIntercom(response.data?.firstName + " " + response.data?.lastName, response.data?.email);
                  },
                  (error) => {
                    console.log("CometChat Login failed with exception:");
                  }
                );
              }
            }
          }
          return response;
        } catch {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),
    userLogout: builder.mutation({
      queryFn(_, __, ___, ____) {
        return {
          data: {
            jwtToken: undefined,
            userData: undefined,
          },
        };
      },
      async onQueryStarted(____, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            // Necessary for previous Cookie flow
            dispatch(AuthApi.util.invalidateTags([CACHE_TAGS.USER]));
            logout();

            // Comet Chat Login Here
            CometChat.logout().then(
              () => {
                console.log("CometChat Logout completed successfully");
              },
              (error) => {
                console.log("CometChat Logout failed with exception:", { error });
              }
            );
          }
        } catch (_) {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),

    resetPassword: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          let queryData = {
            url: apiArgs.isAutoGen ? END_POINTS.RESET_AUTO_GEN : END_POINTS.RESET_PASSWORD,
            method: API_METHODS.POST,
            body: apiArgs.body,
          };

          if (apiArgs?.isAutoGen) {
            queryData = {
              ...queryData,
              headers: {
                Authorization: `Bearer ${apiArgs.token}`,
              },
            };
          }

          const response = await baseQuery(queryData);

          return response;
        } catch {}
      },
      invalidatesTags: [CACHE_TAGS.USER],
    }),
  }),
});

export const { useUserLoginMutation, useUserLogoutMutation, useResetPasswordMutation } = AuthMutation;
