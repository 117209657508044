import React, { useCallback } from "react";
import { useController } from "react-hook-form";
import FileUploadField from "./FileUploadField";

function FormFileField({
  control,
  name,
  rules,
  showError = true,
  appendFiles = false,
  MuiInputProps: { multiple = false, ...restMuiInputProps } = { multiple: false },
  MuiFieldProps: { label = "", ...restFieldProps },
}) {
  const {
    field: { ref, value: _Value, onChange: fileChange, ...inputField },
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: "" });

  const fileUploading = useCallback(
    (e) => {
      e.preventDefault();
      const files = multiple ? e.target.files : e.target.files[0];
      const finalFiles = appendFiles ? [..._Value, ...files] : files;
      fileChange(finalFiles);
      // reset file input for re-selection
      e.target.value = null;
    },
    [_Value, appendFiles, fileChange, multiple]
  );

  // don't pass the value to input type File. It will throw an error and don't show the name of file on UI.
  return (
    <FileUploadField
      ref={ref}
      error={!showError ? "" : error?.message || " "}
      inputField={{ ...inputField, multiple, onChange: fileUploading, ...restMuiInputProps }}
      isRequired={rules?.required}
      MuiFieldProps={{ label, ...restFieldProps }}
    />
  );
}

export default React.memo(FormFileField);
