export const TOTAL_BALANCE = {
  title: "Total Balance Due",
  buttonText: "PAY FULL BALANCE",
  dummyAmount: "$10000",
};

export const INVOICE_DUE = {
  title: "Total Balance Due",
  buttonText: "PAY FULL BALANCE",
  dummyText: "No Invoice Due",
};

export const PAY_AMOUNT = {
  noSelection: "No Invoice Selected",
  buttonText: "PAY AMOUNT",
  dummyTotalAmount: "$100",
  creditUseButtonText: "USE",
  creditBalanceText: "Credit Balance",
};

export const EXPORTS = {
  paymentHistoryText: "View Payment History/Receipts",
  paidInvoicesText: "View/Download Paid Invoices",
  currentStatementText: "View Current Statement/Aging",
  exportInvoiceText: "Export CSV",
};

export const NOTES = {
  // PAY_FULL_ADMIN: "Note: The default Credit card per clinic location will be charged (One bulk charge  will occur)",
  // PAY_FULL_CLINIC: "Payment will be charged on the above selected card",
  // PAY_AMOUNT_ADMIN:
  //   "Note: The default Credit card per clinic location will be charged (One bulk charge will occur for the selected invoices)",
  // PAY_AMOUNT_CLINIC:
  //   "Note: The default Credit card per clinic location will be charged (One bulk charge will occur for the selected invoices)",
  // UPDATE_CARD: "Payment will be charged on above selected card",
  // RX_WORKFLOW_PAYMENT: "Note: Credit card on invoice will be charged (Net 30 will use default Card on file)",
  // NET_30_AUTO: "Note: All automatic Net 30 payments will be charged to default card on file.",

  PAY_FULL_ADMIN: "",
  PAY_FULL_CLINIC: "",
  PAY_AMOUNT_ADMIN: "",
  PAY_AMOUNT_CLINIC: "",
  UPDATE_CARD: "",
  RX_WORKFLOW_PAYMENT: "",
  NET_30_AUTO: "",
};
