import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";

const StaffTypeDD = (props) => {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue);

  useEffect(() => {
    setDefaultValue(props.defaultValue ? props.defaultValue : "");
  }, [props]);

  const selectedType = (e) => {
    props.selectedType(e);
  };

  return (
    <FormGroup key={defaultValue}>
      <Label className="normalText" for={props.name}>
        Staff Type{" "}
        {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        onChange={(e) => selectedType(e.target.value)}
        name={props.name}
        required={props.required}
        defaultValue={defaultValue}
      >
        <option value="" disabled>
          Select
        </option>
        <option value="ClinicSuperUser">Clinic Super User</option>
        <option value="ClinicProvider">Clinic Provider</option>
        <option value="ClinicSupport">Clinic Support</option>
      </Input>
    </FormGroup>
  );
};

export default StaffTypeDD;
