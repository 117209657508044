/* eslint-disable react-hooks/exhaustive-deps */
import { DialogContext } from "context";
import { useCallback, useContext, useEffect, useRef } from "react";

function useClinicPrescriptionAction({ listingMutation }) {
  const prescriptionIdRef = useRef();
  const { showDialog, closeDialog } = useContext(DialogContext);

  const [
    mutateAction,
    {
      data: mutateData,
      error: mutateError,
      isError: isErrorMutate,
      isSuccess: mutateSuccess,
      isLoading: mutateLoading,
    },
  ] = listingMutation();

  useEffect(() => {
    if (mutateSuccess || isErrorMutate) {
      toggleConfirmationModal();
    }
  }, [mutateSuccess, isErrorMutate]);

  const prescriptionAction = useCallback(() => {
    mutateAction({ prescriptionId: prescriptionIdRef.current });
  }, []);

  const toggleConfirmationModal = useCallback(
    (id = "") => {
      prescriptionIdRef.current = id;
      if (id) {
        showDialog({
          question: `Are you sure you want to cancel this prescription?`,
          actionText: "Yes",
          cancelText: "No",
          onClickNo: closeDialog,
          closeModal: toggleConfirmationModal,
          onClickYes: prescriptionAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [prescriptionAction, showDialog],
  );

  return {
    mutateData,
    mutateError,
    mutateSuccess,
    mutateLoading,
    prescriptionAction,
    toggleConfirmationModal,
  };
}

export default useClinicPrescriptionAction;
