import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const ClinicApi = createApi({
  reducerPath: "ClinicLocationApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [CACHE_TAGS.CLINIC, CACHE_TAGS.CLINIC_LOCATION],
});

export default ClinicApi;
