import { Tooltip } from "@mui/material";
import classnames from "classnames";
import { AlertMessage, AutoCompleteSearch } from "components";
import MedicalAccessoriesDD from "components/dropdowns/medicalAccessoriesDD";
import ReasonForMedicationDD from "components/dropdowns/reasonForMedicationDD";
import { FavoriteList } from "components/favorite_list";
import { ProductCatalogue } from "components/product_catalog";
import { RecentlyOrdered } from "components/recently-ordered";
import Spinner from "components/spinner";
import { set } from "lodash";
import moment from "moment";
import { Component } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { Alert, Col, Collapse, FormGroup, Input, Label, Modal, Nav, NavItem, NavLink, Row } from "reactstrap";
import Cookies from "universal-cookie";
import { PRODUCT_FILER_OPTIONS, USER_ROLES, formatDaySupply, get_day_supply } from "utils";
import axiosConfig, { errorResponse } from "utils/axiosConfig";

const catIdArr = [];
class SelectProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendingFamIndexForCategory: "",
      viewCategoryProducts: false,
      newProductCategory: "",
      newProductCommonName: "",
      newProductSubCategory1: "",
      newProductSubCategory2: "",
      clickCatIndex: -1,
      clickFamIndex: -1,
      page: 1,
      sizePerPage: 10,
      keyword: "",
      selectedFamily: "",
      showRxModal: false,
      selectedCategory: "",
      showProduct: false,
      category: true,
      subCategory: false,
      product: false,
      showSpinner: true,
      options: [],
      families: [],
      isLoading: true,
      accordionIndex: -1,
      filteredFamilies: [],
      modalOpen: false,
      selectedProducts: [],
      productAutoRefill: false,
      selectedDiscountedPercentage: 0,
      selectedDiscountedPrice: 0,
      selectedUnitPrice: 0,
      selectedWeight: "-",
      selectedMsrp: "-",
      quantity: 1,
      activeTab: 1,
      customSigQuestion: 1,
      error: "",
      generatedSig: "",
      sigOptions: [],
      sigOptionIndex: 99,
      previousValues: {
        quantity: 1,
      },
      isEditProduct: false,
      favorite: true,
      isInjectable: false,
      selectedMedicalAccessory: "",
      selectedMedicalAccessoryQuantity: "",
      selectedProductFavStatus: false,
      changedStatus: false,
      reasonForCompoundedMedication: [],
      searchFilter: "commonName",
      searchKeyword: "",
    };
  }

  async changeFavoriteStatus(pId, n, status) {
    var body = {
      productId: pId,
      name: n,
      status: status,
    };
    await axiosConfig
      .post(
        "clinic/products/changeFavouriteStatus",

        body,
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        this.setState({ changedStatus: !this.changedStatus });
      })
      .catch(error => {
        errorResponse(error);
      });
  }

  setIsInjectable(bool) {
    this.setState({
      isInjectable: bool,
    });
  }
  async handleSearch() {
    await this.setState({
      keyword: this.state.searchKeyword,
    });
  }

  updateAccordion(index, fam) {
    this.setState({
      accordionIndex: this.state.accordionIndex === index ? -1 : index,
    });
  }

  async onClickSelectedMedicalAccessory(prod) {
    await this.setState({
      selectedMedicalAccessory: prod,
    });
  }
  clearViewCategoryProducts() {
    this.setState({
      keyword: "",
      viewCategoryProducts: false,
      clickFamIndex: -1,
      clickCatIndex: -1,
    });

    this.setState({ searchKeyword: "", searchFilter: "commonName" });
  }
  clearSendingFamIndexForCategory() {
    this.setState({
      sendingFamIndexForCategory: "",
    });
  }

  makeSelectedFamStyle(famName, index) {
    if (
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`)?.style &&
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`)?.style?.color
    ) {
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`).style.background = "#E8B24C";
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`).style.color = "white";
    }
  }

  clearPrevSelectedFamStyle(famName, index) {
    if (
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`)?.style?.background &&
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`)?.style?.color
    ) {
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`).style.background = "#FBFDFE";
      document.getElementById(`${famName?.toLowerCase()}-accordion-button-${index}`).style.color = "#2C4B76";
    }
  }

  filterProductsFamily(familyValue) {
    document.querySelectorAll(".accordion-button").forEach((e, i) => {
      if (e.innerText === familyValue) {
        document.getElementById(`${familyValue?.toLowerCase()}-accordion-button-${i}`).scrollIntoView();
        this.renderFamiliesAndCategories(i);
        this.setState({ accordionIndex: i });
      }
    });
  }

  showError() {
    this.setState({
      error: "You can not select more than one product for Bulk Orders!",
    });
  }

  async filterProducts(value) {
    if (value.length === 0) {
      this.setState({
        accordionIndex: -1,
        filteredFamilies: this.state.families,
      });
    } else {
      var filteredArray = [];

      this.state.families.forEach((element, index) => {
        if (element.name?.toLowerCase().search(value?.toLowerCase()) > -1) {
          filteredArray.push(element);
        }
      });
      this.setState({ filteredFamilies: filteredArray });
    }
  }

  toggleProductTab(tabIndex) {
    this.setState({ activeTab: tabIndex });
  }

  clearSearch = keyword => {
    if (!keyword) {
      this.setState({ keyword: "" });
    }
  };

  async showCustomProductModal() {
    if (this.props.isBulkOrder && this.props.selectedProducts.length > 0) {
      await this.setState({
        error: "You can not select more than one product for Bulk Orders!",

        showRxModal: false,
      });
    } else {
      await this.setState({
        error: "",
        showRxModal: true,
      });
    }
  }

  getSigInfo(id) {
    axiosConfig
      .get("products/getProductDetailsByProductId/" + id, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        this.setState({
          sigOptions: response.data.sigOptions,
        });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "Please select product variant before clicking it Add to Cart",
        });
        errorResponse(error);
      });
  }
  selectProduct(element) {
    this.setState({
      showSpinner: true,
    });
    if (this.props.isBulkOrder && this.props.selectedProducts.length > 0) {
      this.setState({
        error: "You can not select more than one product for Bulk Orders!",
        modalOpen: false,
        showRxModal: false,
        showSpinner: false,
      });
    } else {
      axiosConfig
        .get("products/getProductDetailsByProductId/" + element.id, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async response => {
          this.setState({
            coldShipped: response.data.coldShipped,
            medicalAccessories: response.data.medicalAccessories,
            controlledSubstance: response.data.controlledSubstance,
            newProductCategory: response.data.family,
            newProductCommonName: response.data.commonName,
            newProductSubCategory1: response.data.subCategory1,
            newProductSubCategory2: response.data.subCategory2,
            sigOptions: response.data.sigOptions,
            selectedProductFavStatus: response.data.isFavorite,
            showSpinner: false,
            dispenseType: response.data.dispenseType,
            isAvailable: response.data?.isAvailable ?? true,
            productSize: response.data?.productSize ?? this.state.productSize,
          });
        })
        .catch(error => {
          this.setState({
            showSpinner: false,
            error: "Please select product variant before clicking it Add to Cart",
          });
          errorResponse(error);
        });

      axiosConfig
        .post(
          "products/getProductDiscountByProductIds",
          {
            clinicId:
              new Cookies().get("user_role") !== USER_ROLES.ADMIN_SUPER_USER &&
              new Cookies().get("user_role") !== USER_ROLES.PHARMACIST &&
              new Cookies().get("user_role") !== USER_ROLES.RX_TECH
                ? new Cookies().get("clinic_id")
                : this.props.clinicId,
            productIds: [element.id],
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          },
        )
        .then(async response => {
          this.setState({
            modalOpen: true,
            error: "",
            selectedDiscountedPercentage: response.data[0]?.discountedPercentage,
            selectedDiscountedPrice: response.data[0]?.discountedPrice,
            selectedID: element.id,
            selectedName: element.name,
            selectedUnitPrice: response.data[0]?.unitPrice || element.unitPrice,
            selectedWeight: element.weight,
            selectedMsrp: element.msrp,
            showSpinner: false,
            generatedSig: "",
          });
        })
        .catch(error => {
          this.setState({
            showSpinner: false,
            error: "Please select product variant before clicking it Add to Cart",
          });
          errorResponse(error);
        });
      if (this.props.selectedPatients?.length === 1) {
        axiosConfig
          .post(
            "products/getCustomSigsByProductId",
            {
              clinicId:
                new Cookies().get("user_role") !== USER_ROLES.ADMIN_SUPER_USER
                  ? new Cookies().get("clinic_id")
                  : this.props.clinicId,
              clinicLocationId: this.props.clinicLocationId
                ? this.props.clinicLocationId
                : new Cookies().get("clinic_location_ids"),
              productId: element.id,
              name: element.name,
              providerId: new Cookies().get("user_id"),
              patientId: this.props.selectedPatients[0],
            },
            {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            },
          )
          .then(async response => {
            if (response.data.length > 0) {
              this.setState({
                previousValues: {
                  ...this.state.previousValues,
                  isCustomSig: true,
                  sig: response.data[response.data.length - 1],
                },
                generatedSig: response.data[response.data.length - 1],
                // TODO: select active tab
                // activeTab: prod.isCustomSig ? 1 : 2,
              });
            }
          })
          .catch(error => {
            errorResponse(error);
          });
      }
    }
  }
  async addNewProduct() {
    const serialize = require("form-serialize");
    const obj = serialize(document.getElementById("addProductForm"), {
      hash: true,
    });

    let isDuplicateMedicalAccessoryProduct = false;
    let isDuplicateProduct = false;
    const selectedProduct = {
      coldShipped: this.state.coldShipped,
      controlledSubstance: this.state.controlledSubstance,
      daySupply: formatDaySupply(obj.daySupply,'f'),
      discountedPercentage: this.state.selectedDiscountedPercentage,
      discountedPrice: this.state.selectedDiscountedPrice,
      isOnHold: false,
      isToAutoRefill: false,
      msrp: this.state.selectedMsrp,
      name: obj.productName,
      patientDiagnosis: obj.patientDiagnosis,
      productId: this.state.selectedID,
      quantity: parseInt(obj.quantity),
      reasonForCompoundedMedication: obj.reasonForCompoundedMedication
        ? typeof obj.reasonForCompoundedMedication != "string"
          ? obj.reasonForCompoundedMedication[1]
          : obj.reasonForCompoundedMedication
        : "",
      refills: obj.refills,
      sig: obj.sig ? obj.sig?.toUpperCase() : this.state.generatedSig?.toUpperCase(),
      sigOptionIndex: this.state.sigOptionIndex ? this.state.sigOptionIndex : 0,
      unitPrice: this.state.selectedUnitPrice,
      weight: this.state.selectedWeight,
      _id: obj.productId,
      family: this.state.newProductCategory,
      commonName: this.state.newProductCommonName,
      subCategory1: this.state.newProductSubCategory1,
      subCategory2: this.state.newProductSubCategory2,
      last_office_visit_timestamp: obj.last_office_visit_timestamp
        ? moment(obj.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
      sigOptions: this.state.sigOptions,
      displayedGeneratedSig: obj.sig ? obj.sig?.toUpperCase() : this.state.generatedSig?.toUpperCase(),
      productSize: this.state.productSize,
      isAvailable: this.state.isAvailable,
    };
    isDuplicateProduct =
      this.props?.selectedProducts?.filter(a => a?.productId === selectedProduct?.productId)?.length > 0;
    if (this.state?.selectedMedicalAccessory) {
      const {
        commonName,
        created,
        family,
        id,
        isActive,
        name,
        sigOptions,
        subCategory1,
        subCategory2,
        discountedPercentage,
        discountedPrice,
        unitPrice,
      } = this.state.selectedMedicalAccessory;

      var selectedMedAccess = {
        daySupply: formatDaySupply(obj.daySupply,'f'),
        discountedPercentage: discountedPercentage,
        discountedPrice: discountedPrice,
        isOnHold: false,
        isToAutoRefill: false,
        msrp: unitPrice,
        name: name,
        patientDiagnosis: obj.patientDiagnosis,
        productId: id,
        quantity: obj.selectedMedicalAccessoryQuantity,
        reasonForCompoundedMedication: obj.reasonForCompoundedMedication,
        refills: obj.refills,
        sig: obj.sig ? obj.sig?.toUpperCase() : this.state.generatedSig?.toUpperCase(),
        unitPrice: unitPrice,
        weight: this.state.selectedWeight,
        _id: id,

        family: family,

        commonName: commonName,
        subCategory1: subCategory1,
        subCategory2: subCategory2,
        last_office_visit_timestamp: obj.last_office_visit_timestamp
          ? moment(obj.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
          : "",
      };
      isDuplicateMedicalAccessoryProduct =
        this.props?.selectedProducts?.filter(a => a?.productId === selectedMedAccess?.productId)?.length > 0;

      if (!isDuplicateMedicalAccessoryProduct) {
        if (this.props?.isBulkOrder) {
          this.setState({
            error: "This is a bulk order, You cannot select medical accessories with the product",
          });
        } else {
          await this.props.updateSelectedProducts([...this.props.selectedProducts, selectedMedAccess]);
        }

        this.setState({
          selectedMedicalAccessory: "",
        });
      }
    }

    if (isDuplicateMedicalAccessoryProduct || isDuplicateProduct) {
      this.setState({
        error: "Product Already Added in the cart. Duplicate Products not Allowed.",
      });
    } else {
      await this.props?.updateSelectedProducts([...this.props?.selectedProducts, selectedProduct]);
      if (this.state.selectedName?.toLowerCase().includes("injectable")) {
        this.setState({
          isInjectable: true,
          modalOpen: false,
          previousValues: {},
        });
      } else {
        this.setState({
          isInjectable: false,
          modalOpen: false,
          previousValues: {},
        });
      }
    }
  }
  closeModal() {
    this.setState({ modalOpen: false });
    this.props.isEditModal(false);
  }
  async editProduct() {
    var serialize = require("form-serialize");

    var obj = serialize(document.getElementById("addProductForm"), {
      hash: true,
    });
    const {
      commonName,
      daySupply,
      discountedPercentage,
      discountedPrice,
      family,
      isCustomSig,
      isToAutoRefill,
      name,
      patientDiagnosis,
      productId,
      quantity,
      reasonForCompoundedMedication,
      refills,
      rxStatus,
      sig,
      subCategory1,
      subCategory2,
      sigOptionIndex,
      unitPrice,
      _id,
      sigOptions,
      displayedGeneratedSig,
      productSize,
    } = this.props.selectedProductToEdit;

    var selectedProduct = {
      daySupply:formatDaySupply(obj.daySupply,'f'),
      discountedPercentage: this.state.selectedDiscountedPercentage,
      discountedPrice: this.state.selectedDiscountedPrice,
      isOnHold: false,
      isToAutoRefill: false,
      msrp: this.state.selectedMsrp,
      name: obj.productName,
      patientDiagnosis: obj.patientDiagnosis,
      productId: productId,
      quantity: parseInt(obj.quantity),
      reasonForCompoundedMedication: obj.reasonForCompoundedMedication
        ? typeof obj.reasonForCompoundedMedication != "string"
          ? obj.reasonForCompoundedMedication[1]
          : obj.reasonForCompoundedMedication
        : "",
      refills: obj.refills,
      sig: obj.sig ? obj.sig : this.state.generatedSig?.toUpperCase(),
      sigOptionIndex: this.state.sigOptionIndex,
      unitPrice: this.state.selectedUnitPrice,
      weight: this.state.selectedWeight,
      _id: productId,
      family: family,
      commonName: commonName,
      subCategory1: subCategory1,
      subCategory2: subCategory2,
      last_office_visit_timestamp: obj.last_office_visit_timestamp
        ? moment(obj.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
      sigOptions: sigOptions,
      displayedGeneratedSig: obj.sig ? obj.sig?.toUpperCase() : displayedGeneratedSig?.toUpperCase(),
      productSize: productSize,
    };

    let withoutEditedProduct = this.props.selectedProducts.filter(prod => prod.name !== selectedProduct.name);
    /*
    if (this.state.selectedMedicalAccessory) {
      const { commonName, created, family, id, isActive, name, sigOptions, subCategory1, subCategory2, discountedPercentage, discountedPrice, unitPrice } = this.state.selectedMedicalAccessory;

      var selectedMedAccess = {
        daySupply: obj.daySupply,
        discountedPercentage: discountedPercentage,
        discountedPrice: discountedPrice,
        isOnHold: false,
        isToAutoRefill: false,
        msrp: unitPrice,
        name: name,
        patientDiagnosis: obj.patientDiagnosis,
        productId: this.state.selectedID,
        quantity: obj.selectedMedicalAccessoryQuantity,
        reasonForCompoundedMedication: obj.reasonForCompoundedMedication,
        refills: obj.refills,
        sig: obj.sig ? obj.sig : this.state.generatedSig,
        unitPrice: unitPrice,
        weight: this.state.selectedWeight,
        _id: id,

        family: family,

        commonName: commonName,
        subCategory1: subCategory1,
        subCategory2: subCategory2,
      };

      await withoutEditedProduct.push(selectedMedAccess);
    }
    */

    await this.props.updateSelectedProducts([...withoutEditedProduct, selectedProduct]);

    this.setState({
      modalOpen: false,
      previousValues: {},
      selectedMedicalAccessory: false,
    });
    this.props.isEditModal(false);
    /*
    if (this.state.selectedName.toLowerCase().includes('injectable')) {
      //console.log('YES');
      await this.setState({
        isInjectable: true,
        modalOpen: false,
        previousValues: {},
      });
    } else {
      //console.log('NO');
      await this.setState({
        isInjectable: false,
        modalOpen: false,
        previousValues: {},
      });
    }
    */
  }

  async addCustomProduct(e) {
    e.preventDefault();
    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("customProductForm"), {
      hash: true,
    });

    let randNu = Math.random() * 30;
    var selectedProduct = {
      productType: "custom",
      family: obj.categoryName,
      strength: obj.strength,
      daySupply: 1,
      discountedPercentage: 0,
      discountedPrice: 0,
      isOnHold: false,
      isToAutoRefill: false,
      msrp: 0,
      name: obj.productName,
      patientDiagnosis: " ",
      productId: "RX" + obj.categoryName + obj.productName + randNu.toString(),
      quantity: parseInt(obj.quantity),
      reasonForCompoundedMedication: obj.reasonForCompoundedMedication,
      last_office_visit_timestamp: obj.last_office_visit_timestamp
        ? moment(obj.last_office_visit_timestamp, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
      refills: obj.refills,
      sig: obj.sig,
      unitPrice: 0,
      weight: 0,
      _id: 1,
    };

    await this.props.updateSelectedProducts([...this.props.selectedProducts, selectedProduct]);

    await this.setState({ showRxModal: false });
  }
  componentDidUpdate(prevProps) {
    const {
      commonName,
      daySupply,
      discountedPercentage,
      discountedPrice,
      sigOptionIndex,
      family,
      isCustomSig,
      isToAutoRefill,
      name,
      patientDiagnosis,
      productId,
      quantity,
      reasonForCompoundedMedication,
      refills,
      rxStatus,
      sig,
      subCategory1,
      subCategory2,
      unitPrice,
      _id,
      sigOptions,
      productSize,
      isAvailable,
    } = this.props.selectedProductToEdit;

    if (prevProps.showEditModal != this.props.showEditModal) {
      if (this.props.selectedProductToEdit) {
        this.getSigInfo(productId);
        this.setState({
          selectedDiscountedPercentage: discountedPercentage,
          selectedDiscountedPrice: discountedPrice,
          selectedUnitPrice: unitPrice,
          previousValues: { ...this.state.previousValues, quantity: quantity, productSize: productSize },
          showSpinner: false,
          sigOptionIndex: sigOptionIndex,
          productSize: productSize,
          generatedSig: sigOptions?.[sigOptionIndex]?.description ?? sig,
          isAvailable: isAvailable ?? true,
        });
      }
    }
  }

  componentDidMount() {
    this.setState({
      showSpinner: true,
    });
    if (this.props.isBulkOrder && this.props.selectedProducts) {
      if (this.props.selectedProducts.length > 1) {
        this.setState({
          error: "This is a bulk order, You cannot select more than one product",
        });
      } else {
        this.setState({ error: "" });
      }
    }

    axiosConfig
      .get("products/getAllFamiliesAndCategories", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        var fami = [];
        // console.log('Check Pro and Fam', response.data);
        await response.data.forEach(value => {
          fami.push({
            name: value.family,
            categories: value.categories,
          });
        });

        fami = fami.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        await this.setState({ families: fami });
        //console.log('Family aes', this.state.families);
        let opt = [];
        this.state.families?.forEach((fam, i) => {
          opt.push(
            <option key={i} value={fam.family}>
              {fam.family}
            </option>,
          );
        });
        this.setState({ options: opt, showSpinner: false });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch product families. " + error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }

  renderFamiliesAndCategories(index) {
    if (this.state.families[index].categories.length > 0) {
      this.setState({ showSpinner: false });
      this.setState({ isLoading: false });
      return;
    }
    this.setState({ isLoading: true });
    axiosConfig
      .get("/getAllFamiliesAndCategories", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        var categories = [];
        await response.data.forEach(fam => {
          categories = fam.categories;
        });
        let obj = this.state.families;
        obj[index].categories = categories;
        await this.setState({ families: obj, isLoading: false });
        this.setState({ showSpinner: false });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch products. " + error?.response?.data?.message,
        });
        errorResponse(error);
      });
    // return (obj)
  }

  async openSpecCategoryProduct(fam, cat, famIndex, catIndex) {
    await this.setState({
      clickCatIndex: catIndex,
      clickFamIndex: famIndex,
      viewCategoryProducts: true,
    });
    if (catIdArr?.length > 0 && document?.getElementById(`${catIdArr[0]}`)?.style?.color) {
      document.getElementById(`${catIdArr[0]}`).style.color = "black";

      catIdArr.shift();
    }

    var famis = this.state.families;

    for (var i = 0; i < famis?.length; i++) {
      for (var j = 0; j < famis[i].categories.length; j++) {
        if (famis[i].categories[j] === cat && famis[i].name === fam) {
          document.getElementById(`${famis[i].name?.toLowerCase()}-accordion-button-${i}`).style.background = "#E8B24C";
          document.getElementById(`${famis[i].name?.toLowerCase()}-accordion-button-${i}`).style.color = "white";
          document.getElementById(`${famis[i].name}${i}${cat}${j}`).style.color = "#E8B24C";
          catIdArr.push(`${famis[i].name}${i}${cat}${j}`);
        } else if (famis[i].name != fam) {
          document.getElementById(`${famis[i].name?.toLowerCase()}-accordion-button-${i}`).style.background = "#FBFDFE";
          document.getElementById(`${famis[i].name?.toLowerCase()}-accordion-button-${i}`).style.color = "#2C4B76";
        }
      }
    }
  }

  clearSelectedCategory() {
    for (var i = 0; i < document.getElementsByClassName("navCategoryClass").length; i++) {
      if (document.getElementsByClassName("navCategoryClass")[i] === null) {
      } else {
        document.getElementsByClassName("navCategoryClass")[i].style.color = "black";
      }
    }

    this.setState({
      showProduct: false,
      viewCategoryProducts: false,
    });
  }
  renderFamilies() {
    let obj = [];

    this.state.families.forEach((fam, index) => {
      obj.push(
        <div
          onMouseOver={() => {
            if (index != this.state.clickFamIndex) {
              document.getElementsByClassName("productNavLinkText")[index].style.backgroundColor = "#2C4B76";
              document.getElementsByClassName("productNavLinkText")[index].style.color = "#FFF";
            }
            this.setState({ accordionIndex: index });
          }}
          onMouseOut={() => {
            if (index != this.state.clickFamIndex) {
              document.getElementsByClassName("productNavLinkText")[index].style.backgroundColor = "#FBFDFE";
              document.getElementsByClassName("productNavLinkText")[index].style.color = "#2C4B76";
            }

            this.setState({ accordionIndex: -1 });
          }}
          style={{ paddingVertical: "20px", fontSize: "12px", background: "#FBFDFE", border: "none", width: "100%" }}>
          <Link
            className="productNavLinkText p-2"
            onClick={() => {
              this.setState({
                sendingFamIndexForCategory: index,
                selectedFamily: fam.name,
              });
            }}
            id={`${fam.name?.toLowerCase()}-accordion-button-${index}`}
            type="button"
            style={{ cursor: "pointer", width: "100%" }}>
            <i className="fas fa-capsules p-1" />
            {fam.name?.toUpperCase()}
          </Link>

          {this.state.viewCategoryProducts && index === this.state.clickFamIndex ? (
            <Collapse
              id={`accordion-collapse-${index}-opened`}
              isOpen={
                this.state.viewCategoryProducts ||
                this.state.accordionIndex === index ||
                this.state.families.find(x => x === index)
              }
              className="accordion-collapse ">
              <div className="accordion-body-1 lightBlackText" style={{ fontSize: "12px" }}>
                {this.state.families[this.state.clickFamIndex].categories.map((category, i) => (
                  <div
                    id={`${fam.name}${index}${this.state.families[index].categories[i]}${i}`}
                    data-name={`${this.state.families[index].category}`}
                    onClick={e => {
                      this.setState({
                        showProduct: true,
                        selectedCategory: category,
                        selectedFamily: fam.name,
                        clickCatIndex: i,
                        clickFamIndex: index,
                      });
                    }}
                    className="product accordionProduct p-2 navCategoryClass">
                    <i className="fas fa-star p-2 " /> {category}
                  </div>
                ))}
              </div>
            </Collapse>
          ) : index != this.state.clickFamIndex ? (
            <Collapse
              id={`accordion-collapse-${index}`}
              isOpen={this.state.accordionIndex === index}
              className="accordion-collapse ">
              <div className="accordion-body-1 lightBlackText" style={{ fontSize: "12px" }}>
                {this.state.families[index].categories.map((category, i) => (
                  <div
                    id={`${fam.name}${index}${this.state.families[index].categories[i]}${i}`}
                    data-name={`${this.state.families[index].category}`}
                    onClick={e => {
                      this.setState({
                        showProduct: true,
                        selectedCategory: category,
                        selectedFamily: fam.name,
                        clickCatIndex: i,
                        clickFamIndex: index,
                      });
                    }}
                    className="product accordionProduct p-2 navCategoryClass">
                    <i className="fas fa-star p-2" /> {category}
                  </div>
                ))}
              </div>
            </Collapse>
          ) : null}
        </div>,
      );
    });
    obj.push(
      <div>
        <Row className="blackText text-center p-2" style={{ fontSize: "10px", fontWeight: "bold" }}>
          <p style={{ fontSize: "10px" }}>Cannot find what you're looking for?</p>
          <p>
            <button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                this.showCustomProductModal();
              }}
              className="yellowButton w-30"
              style={{ position: "relative" }}>
              RX PAD
            </button>
          </p>
        </Row>
      </div>,
    );
    return obj;
  }

  updateReasonForCompoundedMedication(val) {
    this.setState({
      previousValues: {
        ...this.state.previousValues,
        reasonForCompoundedMedication: val,
      },
    });
  }

  updateKeyword(e, keyword = "") {
    e?.preventDefault();
    this.setState({ searchKeyword: e?.target?.value ?? keyword });
  }

  updateFilter(e) {
    e.preventDefault();
    this.setState({ searchFilter: e.target.value, keyword: "", searchKeyword: "" });
  }

  clearFilter(e, keyName = "searchKeyword") {
    e.preventDefault();
    this.setState(prev => {
      const newState = prev;
      set(newState, keyName, "");
      if (keyName === "searchKeyword") {
        set(newState, "searchFilter", "commonName");
      }
      return newState;
    });
  }

  render() {
    const {
      commonName,
      sigOptionIndex,
      daySupply,
      discountedPercentage,
      discountedPrice,
      family,
      isCustomSig,
      isToAutoRefill,
      name,
      patientDiagnosis,
      productId,
      quantity,
      reasonForCompoundedMedication,
      refills,
      rxStatus,
      sig,
      subCategory1,
      subCategory2,
      unitPrice,
      _id,
      last_office_visit_timestamp,
      displayedGeneratedSig,
      productSize,
    } = this.props.selectedProductToEdit;

    const { quantity: prevQuantity, sig: prevSig } = this.state.previousValues;
    return (
      <Container>
        <Row style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
          <Col md={4}></Col>
          <Col md={8} lg={8}>
            {" "}
            {this.state.error ? (
              <Alert color="danger" className="text-center">
                {this.state.error ? this.state.error : null}
              </Alert>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={3} />
          <Col md={8}>
            <AutoCompleteSearch
              searchKeyword={this.state.searchKeyword}
              selectedFilter={this.state.searchFilter}
              applySearch={this.handleSearch.bind(this)}
              clearFilter={this.clearFilter.bind(this)}
              updateKeyword={this.updateKeyword.bind(this)}
              updateFilterType={this.updateFilter.bind(this)}
              filterOptions={PRODUCT_FILER_OPTIONS}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col md={3}>
            <div className="productNav mt-1" id="accordion">
              {this.renderFamilies()}
            </div>
          </Col>
          <Col>
            {this.state.showSpinner ? <Spinner /> : null}
            <Row>
              <ProductCatalogue
                setIsInjectable={this.setIsInjectable.bind(this)}
                isInjectable={this.state.isInjectable}
                clearSelectedCategory={this.clearSelectedCategory.bind(this)}
                makeSelectedFamStyle={this.makeSelectedFamStyle.bind(this)}
                clearPrevSelectedFamStyle={this.clearPrevSelectedFamStyle.bind(this)}
                clearSendingFamIndexForCategory={this.clearSendingFamIndexForCategory.bind(this)}
                sendingFamIndexForCategory={this.state.sendingFamIndexForCategory}
                clearViewCategoryProducts={this.clearViewCategoryProducts.bind(this)}
                openSpecCategoryProduct={this.openSpecCategoryProduct.bind(this)}
                limit={this.state.sizePerPage}
                currentPage={this.state.page}
                keyword={this.state.keyword}
                searchFilter={this.state.searchFilter}
                addProductPopup={this.selectProduct.bind(this)}
                selFamily={this.state.selectedFamily}
                selectedCategory={this.state.selectedCategory}
                showProduct={this.state.showProduct}
              />
            </Row>
            <Row>
              <div className="prescriptionRightInfo">
                <h5 className="medText my-3">Recently Ordered</h5>
                <hr></hr>

                <RecentlyOrdered
                  check={1}
                  addProductPopup={this.selectProduct.bind(this)}
                  closeModal={this.closeModal.bind(this)}
                />
              </div>
            </Row>

            <Row>
              <div className="prescriptionRightInfo my-4">
                <h5 className="medText my-3">Favorites</h5>
                <hr></hr>

                <FavoriteList
                  check={1}
                  modal={this.state.modalOpen}
                  editModal={this.props.showEditModal}
                  addProductPopup={this.selectProduct.bind(this)}
                  closeModal={this.closeModal.bind(this)}
                />
              </div>
            </Row>

            {(this.state.modalOpen || this.props.showEditModal) && (
              <Modal
                isOpen={this.state.modalOpen || this.props.showEditModal}
                scrollable={true}
                size="xl"
                centered={true}
                zIndex={1500}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0"> {this.props.showEditModal ? "Edit" : "Add"} Product to Cart</h5>
                  <button
                    type="button"
                    onClick={() => {
                      this.props.isEditModal(false);
                      this.setState({
                        modalOpen: false,
                        previousValues: {},
                        selectedMedicalAccessory: "",
                        selectedMedicalAccessoryQuantity: "",
                        error: "",
                      });
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    id="addProductForm"
                    className="form-horizontal px-sm-4 px-0"
                    onSubmit={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.showEditModal ? this.editProduct(e) : this.addNewProduct(e);
                    }}>
                    {!this.state.isAvailable && (
                      <AlertMessage
                        isError={true}
                        msg={
                          "This product is awaiting one or more active ingredients. VPI will ship the product when available again. Please advise."
                        }
                      />
                    )}
                    <Row>
                      <Col md={8} sm={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="productName" className="form-label">
                            Product Name
                          </Label>
                          <Input
                            name="productName"
                            className="form-control"
                            placeholder=""
                            value={this.props.showEditModal ? name : this.state.selectedName}
                            readOnly
                          />
                        </FormGroup>
                        <Row>
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Label htmlFor="quantity" className="form-label">
                                Unit Quantity <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="quantity"
                                id="quantity"
                                defaultValue={this.props.showEditModal ? quantity : "1"}
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={e => {
                                  this.setState({
                                    previousValues: {
                                      ...this.state.previousValues,
                                      quantity: e.target.value,
                                      daySupply: get_day_supply(
                                        this.state.productSize,
                                        this.state.generatedSig,
                                        e.target.value,
                                      ),
                                    },
                                  });
                                }}
                                required
                                min="1"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6} className="mb-4">
                            <Label htmlFor="dispenseType" className="form-label">
                              Dispense Type
                            </Label>
                            <p className="mt-2">{this.state.dispenseType ?? "-"}</p>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Tooltip title="Day Supply is auto calculated but it can be manually edited in case of failure.">
                                {" "}
                                <Label htmlFor="daySupply" className="form-label">
                                  Day Supply <span style={{ fontSize: "10px" }}></span>
                                </Label>{" "}
                                <Input
                                  defaultValue={this.props.showEditModal ? daySupply : ""}
                                  value={this.state.previousValues.daySupply}
                                  name="daySupply"
                                  className="form-control"
                                  placeholder=""
                                  type="number"
                                  min="1"
                                  onChange={e =>
                                    this.setState({
                                      previousValues: { ...this.state.previousValues, daySupply: e.target.value },
                                    })
                                  }
                                />{" "}
                              </Tooltip>
                            </FormGroup>
                          </Col>
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Label htmlFor="refills" className="form-label">
                                Refills <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                defaultValue={this.props.showEditModal ? refills : ""}
                                name="refills"
                                className="form-control"
                                placeholder=""
                                type="number"
                                required
                                min="0"
                                onChange={e =>
                                  this.setState({
                                    previousValues: { ...this.state.previousValues, refills: e.target.value },
                                  })
                                }
                              />
                            </FormGroup>
                            <br></br>
                            <div className="mt-1 pt-2">
                              <input
                                defaultChecked={this.props.showEditModal ? isToAutoRefill : false}
                                onChange={e => {
                                  this.setState({ ...this.state.previousValues, productAutoRefill: e.target.checked });
                                }}
                                name="autoRefill"
                                className="form-check-input"
                                type="checkbox"
                                id="autoRefill"
                              />
                              <label className="form-check-label mx-2" htmlFor="autoRefill">
                                Auto Refill
                              </label>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-4">
                          <FormGroup>
                            <Label htmlFor="last_office_visit_timestamp" className="form-label">
                              Last Office Visit{" "}
                              {this.state.controlledSubstance === "1" ? <span className="requiredStar">*</span> : null}
                            </Label>
                            <Input
                              defaultValue={
                                this.props.showEditModal ? moment(last_office_visit_timestamp).format("YYYY-MM-DD") : ""
                              }
                              name="last_office_visit_timestamp"
                              className="form-control"
                              placeholder=""
                              type="date"
                              style={{ textTransform: "uppercase" }}
                              required={this.state.controlledSubstance === "1"}
                              max={moment().format("YYYY-MM-DD")}
                            />
                          </FormGroup>
                        </Row>

                        <Row className="mb-4">
                          <FormGroup>
                            <Label htmlFor="reasonForCompoundedMedication" className="form-label">
                              Reason for compounded medication
                            </Label>
                            <br></br>
                            <ReasonForMedicationDD
                              updateReasonForCompoundedMedication={this.updateReasonForCompoundedMedication.bind(this)}
                              name={"reasonForCompoundedMedication"}
                              defaultValue={this.props.showEditModal ? reasonForCompoundedMedication : ""}
                            />
                          </FormGroup>
                        </Row>
                        <Row className="mb-4">
                          <FormGroup>
                            <Label htmlFor="patientDiagnosis" className="form-label">
                              Patient Diagnosis
                            </Label>
                            <Input
                              defaultValue={this.props.showEditModal ? patientDiagnosis?.toUpperCase() : ""}
                              name="patientDiagnosis"
                              className="form-control"
                              placeholder=""
                              style={{ textTransform: "uppercase" }}
                              type="textarea"
                              onChange={e =>
                                this.setState({
                                  previousValues: {
                                    ...this.state.previousValues,
                                    patientDiagnosis: e.target.value?.toUpperCase(),
                                  },
                                })
                              }
                            />
                          </FormGroup>
                        </Row>
                        <Row className="mb-4">
                          <p className="semiBold">
                            Written Instructions <span className="requiredStar">*</span>
                          </p>
                          <div className="px-4">
                            <Nav tabs className="nav-tabs-custom nav-justified">
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({ active: this.state.activeTab === 1 })}
                                  onClick={() => {
                                    this.toggleProductTab(1);
                                  }}>
                                  Recommended or Common Dosing
                                </NavLink>
                              </NavItem>
                              <NavItem>
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({ active: this.state.activeTab === 2 })}
                                  onClick={() => {
                                    this.toggleProductTab(2);
                                  }}>
                                  Custom Written Instructions
                                </NavLink>
                              </NavItem>
                            </Nav>
                          </div>
                        </Row>
                        <Row className="mb-4 px-4">
                          {this.state.activeTab === 2 ? (
                            <FormGroup>
                              <Input
                                defaultValue={this.props.showEditModal ? sig?.toUpperCase() : ""}
                                name="sig"
                                className="form-control"
                                placeholder=""
                                type="textarea"
                                style={{ textTransform: "uppercase" }}
                                required
                                onChange={e =>
                                  this.setState({
                                    previousValues: {
                                      ...this.state.previousValues,
                                      sig: e.target.value?.toUpperCase(),
                                      daySupply: get_day_supply(
                                        this.state.productSize,
                                        e.target.value?.toUpperCase(),
                                        prevQuantity,
                                      ),
                                    },
                                  })
                                }
                                value={
                                  this.state.previousValues.isCustomSig
                                    ? this.state.previousValues.sig?.toUpperCase()
                                    : null
                                }
                              />
                            </FormGroup>
                          ) : (
                            <>
                              <div className="my-4 shadowedDiv p-3 position-relative">
                                {(this.props.showEditModal
                                  ? this.state.sigOptions?.[0]?.description
                                  : this.state?.sigOptions[0]?.description) && family !== "Medical Accessories" ? (
                                  <>
                                    {this.state.customSigQuestion === 1 ? null : (
                                      <button
                                        onClick={e => {
                                          e.preventDefault();
                                          this.setState({ customSigQuestion: this.state.customSigQuestion - 1 });
                                        }}
                                        className="sigButtonBack">
                                        <i className="text-muted font-size-16 fas fa-angle-left"></i>
                                      </button>
                                    )}
                                    <p className="medText semiBold">Question {this.state.customSigQuestion}</p>
                                    <div className={this.state.customSigQuestion === 1 ? "d-block" : "d-none"}>
                                      <p>Dosing Recommendations</p>
                                      <div className="mt-4"></div>
                                      {this.state.sigOptions.map(
                                        (sig, i) =>
                                          sig.description && (
                                            <div className="form-check pt-1">
                                              <input
                                                className="form-check-input"
                                                onChange={e => {
                                                  this.setState({
                                                    generatedSig: sig.description?.toUpperCase(),
                                                    sigOptionIndex: i,
                                                    previousValues: {
                                                      ...this.state.previousValues,
                                                      daySupply: get_day_supply(
                                                        this.state.productSize,
                                                        sig.description?.toUpperCase(),
                                                        prevQuantity,
                                                      ),
                                                    },
                                                  });
                                                }}
                                                type="radio"
                                                name="Q1_option"
                                                id={`Q${i + 1}_option`}
                                                defaultChecked={
                                                  this.props.showEditModal
                                                    ? sigOptionIndex === i
                                                      ? true
                                                      : false
                                                    : false
                                                }
                                                value={`Option ${i + 1}`}
                                                required
                                              />
                                              <label className="form-check-label" htmlFor={`Q${i + 1}_option`}>
                                                <p>
                                                  <b>{sig.title}</b>
                                                </p>
                                                <p>{sig.description?.toUpperCase()}</p>
                                              </label>
                                            </div>
                                          ),
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <FormGroup>
                                    <p>No Sig Added. Please Add a Custom Written Instructions below. </p>
                                    <Input
                                      defaultValue={this.props.showEditModal ? sig?.toUpperCase() : ""}
                                      name="sig"
                                      className="form-control"
                                      placeholder=""
                                      type="textarea"
                                      style={{ textTransform: "uppercase" }}
                                      required
                                      onChange={e =>
                                        this.setState({
                                          previousValues: {
                                            ...this.state.previousValues,
                                            sig: e.target.value?.toUpperCase(),
                                          },
                                        })
                                      }
                                      value={
                                        this.state.previousValues.isCustomSig
                                          ? this.state.previousValues.sig?.toUpperCase()
                                          : null
                                      }
                                    />
                                  </FormGroup>
                                )}
                              </div>
                              <p className="medText semiBold mt-4">Generated Sig</p>
                              <p>
                                {this.props.showEditModal
                                  ? displayedGeneratedSig
                                  : this.state.generatedSig?.toUpperCase()}
                              </p>
                            </>
                          )}
                        </Row>
                      </Col>
                      <Col md={4} sm={12} className="p-3">
                        <div>
                          <p className="my-3">
                            <span
                              className="prescriptionRightInfo"
                              style={{ padding: "10px", paddingLeft: "20px", paddingRight: "20px", marginTop: "10px" }}>
                              <input
                                type="checkbox"
                                defaultChecked={this.state.selectedProductFavStatus}
                                className="pointer"
                                style={{ color: "#E8E8E8" }}
                                onClick={e =>
                                  this.changeFavoriteStatus(
                                    this.state.selectedID,
                                    this.state.selectedName,
                                    e.target.checked,
                                  )
                                }
                              />
                              <span>{"   "}Favorite</span>
                            </span>
                          </p>
                        </div>
                        <h5 className="medText my-3">Pricing Information</h5>
                        <div className="flexJCAC justify-content-between">
                          <p>Unit price</p>
                          <p>{Math.round((this.state.selectedUnitPrice + Number.EPSILON) * 100) / 100}</p>
                        </div>
                        <div className="flexJCAC justify-content-between">
                          <p>Discounted price</p>
                          <p>{this.state.selectedDiscountedPrice}</p>
                        </div>
                        <div className="flexJCAC justify-content-between">
                          <p>Unit Quantity</p>
                          <p>{this.state.previousValues.quantity}</p>
                        </div>
                        <hr />
                        <div className="flexJCAC justify-content-between semiBold">
                          <p>Total price</p>
                          <p>
                            {isNaN(this.state.selectedDiscountedPrice * this.state.previousValues.quantity)
                              ? "Error calculating values"
                              : Math.round(
                                  (this.state.selectedDiscountedPrice * this.state.previousValues.quantity +
                                    Number.EPSILON) *
                                    100,
                                ) / 100}
                          </p>
                        </div>
                        {!this.props.isBulkOrder && this.state.medicalAccessories === "1" ? (
                          this.state.newProductCategory !== "Medical Accessories" &&
                          family !== "Medical Accessories" &&
                          !this.props.showEditModal ? (
                            <Row>
                              <Col md={7}>
                                <MedicalAccessoriesDD
                                  clinicId={
                                    new Cookies().get("user_role") !== USER_ROLES.ADMIN_SUPER_USER &&
                                    new Cookies().get("user_role") !== USER_ROLES.PHARMACIST &&
                                    new Cookies().get("user_role") !== USER_ROLES.RX_TECH
                                      ? new Cookies().get("clinic_id")
                                      : this.props.clinicId
                                  }
                                  onClickSelectedMedicalAccessory={this.onClickSelectedMedicalAccessory.bind(this)}
                                  name="medicalAccessory"
                                />{" "}
                              </Col>
                              <Col>
                                <Label htmlFor="selectedMedicalAccessoryQuantity" className="form-label">
                                  Unit Quantity <span className="requiredStar">*</span>
                                </Label>
                                <Input
                                  name="selectedMedicalAccessoryQuantity"
                                  id="selectedMedicalAccessoryQuantity"
                                  defaultValue={1}
                                  className="form-control"
                                  placeholder=""
                                  type="number"
                                  onChange={e => {}}
                                  required
                                  min="1"
                                />
                              </Col>
                            </Row>
                          ) : null
                        ) : null}
                      </Col>
                    </Row>
                    {this.state.error ? <p className="yellowButton">{this.state.error}</p> : ""}

                    <Row className="flexJCAC">
                      <button className="pinkButton my-4" style={{ width: 200 }} type="submit">
                        {this.props.showEditModal ? "Update" : "Add"}
                      </button>
                    </Row>
                  </form>
                </div>
              </Modal>
            )}

            {this.state.showRxModal && (
              <Modal isOpen={this.state.showRxModal} scrollable={true} size="xl" centered={true}>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Add Product to Cart</h5>
                  <button
                    type="button"
                    onClick={() => this.setState({ showRxModal: false })}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    id="customProductForm"
                    className="form-horizontal px-sm-4 px-0"
                    onSubmit={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.addCustomProduct(e);
                    }}>
                    <Row>
                      <Col md={8} sm={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="productName" className="form-label">
                            Product Name<span className="requiredStar">*</span>
                          </Label>
                          <Input name="productName" className="form-control" required />
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Label htmlFor="categoryName" className="form-label">
                            Category
                          </Label>
                          <Input name="categoryName" className="form-control" />
                        </FormGroup>
                        <Row>
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Label htmlFor="quantity" className="form-label">
                                Unit Quantity <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="quantity"
                                id="quantity"
                                className="form-control"
                                placeholder=""
                                type="number"
                                onChange={e => {
                                  this.setState({ quantity: e.target.value });
                                }}
                                value={this.state.quantity}
                                required
                                min="1"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Label htmlFor="strength" className="form-label">
                                Strength <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="strength"
                                className="form-control"
                                placeholder=""
                                type="text"
                                required
                                min="1"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6} className="mb-4">
                            <FormGroup>
                              <Label htmlFor="refills" className="form-label">
                                Refills <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="refills"
                                className="form-control"
                                placeholder=""
                                type="number"
                                required
                                min="0"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <FormGroup>
                            <Label htmlFor="last_office_visit_timestamp" className="form-label">
                              Last Office Visit
                            </Label>
                            <Input
                              name="last_office_visit_timestamp"
                              className="form-control"
                              placeholder=""
                              type="date"
                              style={{ textTransform: "uppercase" }}
                              max={moment().format("YYYY-MM-DD")}
                            />
                          </FormGroup>
                        </Row>
                        <Row className="mb-4">
                          <Row className="mb-4">
                            <FormGroup>
                              <Label htmlFor="reasonForCompoundedMedication" className="form-label">
                                Reason for compounded medication
                              </Label>
                              <br></br>
                              <ReasonForMedicationDD
                                name={"reasonForCompoundedMedication"}
                                updateReasonForCompoundedMedication={this.updateReasonForCompoundedMedication.bind(
                                  this,
                                )}
                              />
                            </FormGroup>
                          </Row>
                        </Row>
                        <Row className="mb-4">
                          <p className="semiBold">
                            Written Instructions<span className="requiredStar">*</span>
                          </p>
                          <div className="px-4">
                            <FormGroup>
                              <Input
                                name="sig"
                                className="form-control"
                                style={{ textTransform: "uppercase" }}
                                placeholder=""
                                type="textarea"
                                required
                              />
                            </FormGroup>
                          </div>
                        </Row>
                      </Col>
                      <Col md={4} sm={12} className="p-3">
                        <h5 className="medText my-3">Pricing Information</h5>
                        <div className="flexJCAC justify-content-between">
                          <p>Unit price</p>
                          <p>0</p>
                        </div>
                        <div className="flexJCAC justify-content-between">
                          <p>Discounted Price</p>
                          <p>0</p>
                        </div>
                        <div className="flexJCAC justify-content-between">
                          <p>Unit Quantity</p>
                          <p>{this.state.quantity}</p>
                        </div>
                        <hr />
                        <div className="flexJCAC justify-content-between semiBold">
                          <p>Total Price</p>
                          <p>0</p>{" "}
                        </div>
                      </Col>
                    </Row>
                    <Row className="flexJCAC">
                      <button className="pinkButton my-4" style={{ width: 200 }} type="submit">
                        Add
                      </button>
                    </Row>
                  </form>
                </div>
              </Modal>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SelectProducts;
