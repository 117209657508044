import { find, findKey, includes, map,startsWith } from "lodash";
import { useCallback, useMemo } from "react";
import { useGetClinicLocationAddressQuery, useGetPatientAddressQuery, useShippingMethodQuery } from "rtk";
import { SHIP_METHODS } from "utils/StatusConstant";
function useShippingApi({ isBulkOrder = false, clinicLocationId = "",isCustomShippingLabel=false, selectedPatients = [] ,defaultShippingInfo={}}) {
  const {
    data: clinicAddressData,
    error: clinicAddressError,
    isFetching: clinicAddressFetching,
  } = useGetClinicLocationAddressQuery({ locationId: clinicLocationId }, {});
  const {
    data: patientAddressData,
    error: patientAddressError,
    isFetching: patientAddressFetching,
  } = useGetPatientAddressQuery(
    { patientId: selectedPatients[0] },
    {skip : defaultShippingInfo?.shipTo==='clinic'}
  );
  console.log("selected patient" ,selectedPatients)

  

  const { data: shippingMethods, error: shippingError, isFetching: shippingFetching } = useShippingMethodQuery();

  const apiLoading = useMemo(
    () => patientAddressFetching || clinicAddressFetching,
    [clinicAddressFetching, patientAddressFetching]
  );

  const apiError = useMemo(
    () => clinicAddressError?.data?.message || patientAddressError?.data?.message || shippingError?.data?.message || "",
    [clinicAddressError?.data?.message, patientAddressError?.data?.message, shippingError?.data?.message]
  );

  const findShippingMethod = useCallback(
    (methodName) => {
      if (methodName?.toLowerCase() === "customrate") {
        return "customRate";
      }
      return findKey(shippingMethods, (method) => includes(method, methodName));
    },
    [shippingMethods]
  );

  const getShippingMethodOption = useCallback(
    (method) => {
      const emptyOption = [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
      if (shippingFetching) {
        return [
          {
            value: "",
            label: "Loading",
            disabled: true,
          },
        ];
      } else if (shippingMethods?.shippingMethods?.length < 1) {
        return emptyOption;
      }
      const optionList = shippingMethods?.[method]?.map((shipping) => find(SHIP_METHODS, { value: shipping }));
      return optionList ?? emptyOption;
    },
    [shippingFetching, shippingMethods]
  );

  const getClinicAddressObj = useCallback(() => {
    return {
      addressLine1: clinicAddressData?.addressLine1,
      addressLine2: clinicAddressData?.addressLine2,
      city: clinicAddressData?.city,
      state: clinicAddressData?.state,
      zipcode: clinicAddressData?.zipcode,
    };
  }, [
    clinicAddressData?.addressLine1,
    clinicAddressData?.addressLine2,
    clinicAddressData?.city,
    clinicAddressData?.state,
    clinicAddressData?.zipcode,
  ]);

  const getPatientAddressObj = useCallback(
    (id = "") => {
      const patientAddress = find(patientAddressData?.addresses, { _id: id });
      return {
        addressLine1: patientAddress?.addressLine1,
        addressLine2: patientAddress?.addressLine2,
        city: patientAddress?.city,
        state: patientAddress?.state,
        zipcode: patientAddress?.zipcode,
      };
    },
    [patientAddressData?.addresses]
  );

  const clinicAddressOptions = useMemo(() => {
    if (clinicAddressFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    } else if (!clinicAddressData && !defaultShippingInfo?.shippingAddress?.addressLine1) {
      return [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
    }
    let clinicAddressDD=map([clinicAddressData], (clinicAddress) => ({
      value: clinicAddress?.id,
      label: `${clinicAddress.addressLine1 ?? "-"}, ${clinicAddress?.addressLine2 ?? "-"}, ${clinicAddress.city}, ${
        clinicAddress?.state ?? "-"
      }, ${clinicAddress.zipcode ?? "-"}`,
    }));

    if(defaultShippingInfo?.shipTo=='clinic'){
      const currentShippingAddress=defaultShippingInfo?.shippingAddress
      const getClinicAddress = find(clinicAddressDD, add =>
        startsWith(add.label, defaultShippingInfo?.shippingAddress?.addressLine1),
      );
      //If shippping address is deleted from patient or clinic then still show that.
      if(!getClinicAddress?.value){
        const notStoredClinicAddress={
          value: currentShippingAddress?._id??'default-clinic-address-not-found',
          label: `${currentShippingAddress.addressLine1 ?? "-"}, ${currentShippingAddress?.addressLine2 ?? "-"}, ${currentShippingAddress?.city}, ${
            currentShippingAddress?.state ?? "-"
          }, ${currentShippingAddress?.zipcode ?? "-"}`,
        }
        clinicAddressDD.push(notStoredClinicAddress)
      }
    }
    //
    return clinicAddressDD;
    
  }, [clinicAddressData, clinicAddressFetching]);

  const patientAddressOptions = useMemo(() => {
    if (patientAddressFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    } else if (!patientAddressData?.addresses && !defaultShippingInfo?.shippingAddress?.addressLine1) {
      return [
        {
          value: "",
          label: "No data found",
          disabled: true,
        },
      ];
    }
    let patientAddressesDD=map(patientAddressData?.addresses, (patientAddress) => ({
      value: patientAddress?._id,
      label: `${patientAddress.addressLine1 ?? "-"}, ${patientAddress?.addressLine2 ?? "-"}, ${patientAddress.city}, ${
        patientAddress?.state ?? "-"
      }, ${patientAddress.zipcode ?? "-"}`,
    }));
    //If shippping address is deleted from patient or clinic then still show that.
    if(defaultShippingInfo?.shipTo=='patient'){
      const currentShippingAddress=defaultShippingInfo?.shippingAddress
      const getPatientAddress = find(patientAddressesDD, add =>
        startsWith(add.label, defaultShippingInfo?.shippingAddress?.addressLine1),
      );
      if(!getPatientAddress?.value){
        const notStoredPatientAddress={
          value: currentShippingAddress?._id??'default-patient-address-not-found',
          label: `${currentShippingAddress.addressLine1 ?? "-"}, ${currentShippingAddress?.addressLine2 ?? "-"}, ${currentShippingAddress?.city}, ${
            currentShippingAddress?.state ?? "-"
          }, ${currentShippingAddress?.zipcode ?? "-"}`,
        }
        patientAddressesDD.push(notStoredPatientAddress)
      }
    }
    //
    return patientAddressesDD;
  }, [patientAddressData, patientAddressFetching]);
  console.log("patient address data",patientAddressData)
  console.log("patient address options",patientAddressOptions)

  return {
    apiLoading,
    apiError,
    clinicAddressOptions,
    patientAddressOptions,
    getPatientAddressObj,
    getClinicAddressObj,
    getShippingMethodOption,
    findShippingMethod,
  };
}

export default useShippingApi;
