import { PatientProvider } from "context";
import React from "react";
import InsertPatient from "./InsertPatient";

function ManagePatient() {
  return (
    <PatientProvider>
      <InsertPatient />
    </PatientProvider>
  );
}

export default React.memo(ManagePatient);
