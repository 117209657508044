import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

import axiosConfig, { errorResponse } from "../../utils/axiosConfig";

import "./index.scss";

const useProductCatalogue = props => {
  const [clinic, setClinic] = useState({});
  const [complete, setComplete] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState("");
  const [families, setFamilies] = useState("");
  const [products, setProducts] = useState("");
  const [categories, setCategories] = useState("");
  const [isFamily, setIsFamily] = useState(true);
  const [isProduct, setIsProduct] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showProduct, setShowProduct] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [pagination, setPagination] = useState("");
  const [alpha, setAlpha] = useState("");
  const [keyword, setKeyword] = useState("");

  const [catProducts, setCatProducts] = useState("");
  const [subCat2Products, setSubCat2Products] = useState("");
  const [subcategory2List, setSubcategory2List] = useState("");
  const [selectedProductVariant, setSelectedProductVariant] = useState("");
  const [activeSubCat2List, setActiveSubCat2List] = useState([]);
  const [isInjectable, setIsInjectable] = useState(false);
  const alphabets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const [noteAccessories, setNoteAccessories] = useState(false);

  useEffect(() => {
    setIsInjectable(props.isInjectable);
    if (props.isInjectable) {
      props.clearPrevSelectedFamStyle(
        selectedFamily,
        families.findIndex(x => x.name === selectedFamily),
      );
      setSelectedFamily("Medical Accessories");
      props.makeSelectedFamStyle(
        "Medical Accessories",
        families.findIndex(x => x.name?.toUpperCase() === "MEDICAL ACCESSORIES"),
      );

      props.clearViewCategoryProducts();

      setIsFamily(false);
      setIsCategory(true);
      setIsProduct(false);

      renderCategory(families.findIndex(x => x.name?.toUpperCase() === "MEDICAL ACCESSORIES"));
      setNoteAccessories(true);

      props.setIsInjectable(false);
    }
  }, [props.isInjectable]);

  useEffect(() => {
    setAlpha("");
    //setIsInjectable(props.isInjectable);
    setPageNo(1);
    setShowSpinner(true);
    getFamiliesAndCategories();
    if (!isInjectable) {
      if (props.showProduct) {
        setShowProduct(true);
        setShowSpinner(false);
        setSelectedCategory(props.selectedCategory);
        setSelectedFamily(props.selFamily);
        renderProduct(props.selFamily, props.selectedCategory, "", "", 10, 1);
        //setShowProduct(false);
      }

      if (props.keyword) {
        setShowProduct(true);
        setShowSpinner(false);
        setSelectedCategory("");
        setSelectedFamily("");
        renderProduct("", "", props.keyword, "", 10, 1);
        //setShowProduct(false);
      }

      if (!props.keyword && !props.selFamily && !props.selectedCategory) {
        setIsFamily(true);
        setIsProduct(false);
        setIsCategory(false);
      }

      if (props.sendingFamIndexForCategory) {
        setIsFamily(false);
        setIsProduct(false);
        setIsCategory(true);
        renderCategory(props.sendingFamIndexForCategory);
        props.clearSendingFamIndexForCategory();
      }
    }
  }, [props.selFamily, props.selectedCategory, props.keyword]);

  const getFamiliesAndCategories = () => {
    axiosConfig
      .get("products/getAllFamiliesAndCategories", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        var famil = [];
        await response.data.forEach(fam => {
          famil.push({
            name: fam.family,
            categories: fam.categories,
          });
        });
        setShowSpinner(false);
        famil = famil.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

        await setFamilies(famil);
      })
      .catch(error => {
        setError("An error occurred while trying to fetch product families. " + error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  const onChangeVariant = prod => {
    setSelectedProductVariant(prod);
  };

  const renderCategory = index => {
    props.makeSelectedFamStyle(families[index].name, index);
    props.clearViewCategoryProducts();

    setCategories(families[index].categories);
    setSelectedFamily(families[index].name);
  };

  async function handlePageChange(page) {
    await setPageNo(page);

    renderProduct(selectedFamily, selectedCategory, props.key, alpha, sizePerPage, page);
  }

  const renderProduct = (fam = "", cat = "", key = "", alpha = "", sizePerPage = 10, page = 1) => {
    var famIndex = -1;
    var catIndex = -1;

    for (var i = 0; i < families.length; i++) {
      if (families[i].name === fam) {
        famIndex = i;
        for (var j = 0; j < families[i].categories.length; j++) {
          if (families[i].categories[j] === cat) {
            catIndex = j;
          }
        }
      }
    }

    props.openSpecCategoryProduct(fam, cat, famIndex, catIndex);
    setIsProduct(true);
    setIsFamily(false);
    setIsCategory(false);
    setShowSpinner(true);

    let simpleAPI = key === "" && alpha === "";

    let body = {};

    if (key || alpha) {
      if (key) {
        body = {
          keyword: key,
          alphabet: "",
          filter: [props.searchFilter],
        };
      } else if (alpha) {
        body = {
          keyword: "",
          alphabet: alpha,
        };
      }
    } else {
      body = {
        category: fam,
        alphabet: alpha,
        subCategory1: cat,
      };
    }

    axiosConfig
      .post(simpleAPI ? "products/getProductsByCategory" : "products/searchProducts", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })

      .then(async response => {
        var prod = [];
        let catPro = [];
        let subCat2Pro = [];
        let subCat2List = [];

        if (key || alpha) {
          catPro = [{ subCategory2_item: "", commonNames: response.data }];
        } else {
          catPro = response.data;
        }

        subCat2Pro = catPro.filter(pro => pro.subCategory2_item !== "");
        subCat2List = [];
        subCat2Pro.forEach(pro => {
          subCat2List.push(pro.subCategory2_item);
        });

        setCatProducts(catPro);
        setSubCat2Products(subCat2Pro);
        setSubcategory2List(subCat2List);

        let activeList = [];

        subCat2List.map(val => {
          activeList.push({
            subCat2_item: val,
            activeLink: false,
          });
        });
        setActiveSubCat2List(activeList);

        /*
        await response.data.products.forEach((product) => {
          prod.push({
            id: product.id,
            userId: product.user,
            name: product.name,
            unitPrice: product.unitPrice,
            family: product.family,
            msrp: product.msrp,
            weight: product.weight,
            sig: product.sig,
            created: product.created,
          });
        });
        //prod = prod.sort();
        */
        await setPagination(response.data.pagination);
        await setShowSpinner(false);
        await setProducts(catPro);
      })
      .catch(error => {
        setError("An error occurred while trying to fetch product families. " + error?.response?.data?.message);
        setShowSpinner(false);
      });
  };

  const resetActiveLinks = () => {
    let updatedActiveList = [];
    let vals = subcategory2List;

    vals.forEach(element => {
      updatedActiveList.push({
        subCat2_item: element,
        activeLink: false,
      });
    });

    setActiveSubCat2List(updatedActiveList);
  };
  return {
    clinic,
    setClinic,
    complete,
    setComplete,
    showSpinner,
    setShowSpinner,
    error,
    setError,
    families,
    setFamilies,
    products,
    setProducts,
    categories,
    setCategories,
    isFamily,
    setIsFamily,
    isProduct,
    setIsProduct,
    isCategory,
    setIsCategory,
    selectedFamily,
    setSelectedFamily,
    selectedCategory,
    setSelectedCategory,
    showProduct,
    setShowProduct,
    pageNo,
    setPageNo,
    sizePerPage,
    setSizePerPage,
    pagination,
    setPagination,
    alpha,
    setAlpha,
    keyword,
    setKeyword,
    catProducts,
    setCatProducts,
    subCat2Products,
    setSubCat2Products,
    subcategory2List,
    setSubcategory2List,
    selectedProductVariant,
    setSelectedProductVariant,
    activeSubCat2List,
    setActiveSubCat2List,
    resetActiveLinks,
    handlePageChange,
    renderCategory,
    onChangeVariant,
    alphabets,
    renderProduct,
    isInjectable,
    setIsInjectable,
    noteAccessories,
  };
};
export default useProductCatalogue;
