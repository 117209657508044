import { columnHelper } from "./PrescriptionColumns";

const CLINIC_PRODUCT_GROUP_COLUMNS = [
  columnHelper.accessor("name", {
    header: "Product Name",
  }),
  columnHelper.accessor("commonName", {
    header: "Common Name",
  }),
  columnHelper.accessor("family", {
    header: "Category",
  }),
  columnHelper.accessor("subCategory1", {
    header: "Sub-Category 1",
  }),
  columnHelper.accessor("subCategory2", {
    header: "Sub-Category 2",
  }),
  columnHelper.accessor("unitPrice", {
    header: "Unit price",
  }),
];

const CLINIC_STAFF_COLUMNS = [
  columnHelper.accessor("firstName", {
    header: "Staff Name",
    cell: ({ row }) => (row.original?.firstName ?? "") + " " + (row.original?.lastName ?? ""),
  }),
  columnHelper.accessor("commonName", {
    header: "Clinic Location",
    cell: ({ row }) => row.original?.clinicLocationNames.join(", "),
  }),
  columnHelper.accessor("role", {
    header: "Staff Type",
  }),
  columnHelper.accessor("email", {
    header: "Email",
  }),
  columnHelper.accessor("phoneNumber", {
    header: "Mobile Number",
  }),
  columnHelper.accessor("isVerified", {
    header: "Status",
    cell: props => {
      const cellValue = props.getValue();
      return <span className={cellValue ? "greenText" : "redText"}>{cellValue ? "Verified" : "Not Verified"}</span>;
    },
  }),
];

const CLINIC_LOCATION_COLUMNS = [
  columnHelper.accessor("locationName", {
    header: "Location Name",
  }),
  columnHelper.accessor("city", {
    header: "City",
  }),
  columnHelper.accessor("state", {
    header: "State",
  }),
  columnHelper.accessor("officeContactPhone", {
    header: "Phone Number",
  }),
];

export { CLINIC_PRODUCT_GROUP_COLUMNS, CLINIC_STAFF_COLUMNS, CLINIC_LOCATION_COLUMNS };
