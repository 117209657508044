const FEDEX_PACKAGES = [
  { value: "YOUR_PACKAGING", label: "Your Packaging" },
  { value: "FEDEX_MEDIUM_BOX", label: "FedEx Medium Box" },
  { value: "FEDEX_SMALL_BOX", label: "FedEx Small Box" },
];

const UPS_PACKAGES = [
  { value: "UNKNOWN", label: "UNKNOWN" },
  { value: "UPS Letter", label: "UPS Letter" },
  { value: "Tube", label: "Tube" },
  { value: "Pak", label: "Pak" },
  { value: "Express Box", label: "Express Box" },
  { value: "25KG Box", label: "25KG Box" },
  { value: "10KG Box", label: "10KG Box" },
  { value: "Pallet", label: "Pallet" },
  // { value: "Small Express Box", label: "Small Express Box" },
  { value: "Medium Express Box", label: "Medium Express Box" },
  { value: "Large Express Box", label: "Large Express Box" },
];

const PACKAGE_BOXES = [
  { value: "6x6x6", label: "6x6x6" },
  { value: "8x8x8", label: "8x8x8" },
  { value: "customSize", label: "Custom Size" },
];

export { FEDEX_PACKAGES, UPS_PACKAGES, PACKAGE_BOXES };
