/* eslint-disable react-hooks/exhaustive-deps */
import { find, has, isNull } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInsertPatientMutation, useUploadPatientProfileMutation } from "rtk";
import { PATIENT_STEPPER, STEPPER_FORMS } from "utils";

function usePatientProvider(getEditApiParam, isPatientEdit) {
  const formRef = useRef([]);
  const inputData = useRef(null);
  const personalData = useRef(null);
  const addressData = useRef(null);
  const shippingData = useRef(null);
  const billingData = useRef(null);
  const [profilePic, setProfilePic] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [completedStep, setCompletedStep] = useState(() =>
    isPatientEdit ? { 0: true, 1: true, 2: true, 3: true } : { 0: true },
  );

  const [uploadFile, { data: uploadData, error: uploadError, isLoading: uploadLoading }] =
    useUploadPatientProfileMutation();

  const [patientMutate, { isSuccess: patientInsertSuccessfully, error: patientError, isLoading: patientLoading }] =
    useInsertPatientMutation();

  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);
  const mutateLoading = useMemo(() => patientLoading, [patientLoading]);
  const mutateError = useMemo(
    () => uploadError?.data?.message || patientError?.data?.message || "",
    [patientError?.data?.message, uploadError?.data?.message],
  );
  const patientDefaultAddress = useMemo(() => {
    if (addressData.current) {
      return find(addressData?.current.addresses, { isDefault: true });
    }
  }, [addressData.current]);

  const clinicLocationId = useMemo(() => personalData?.current?.clinicLocationId, [personalData?.current]);

  useEffect(() => {
    if (uploadData?.accessUrl) {
      setProfilePic(uploadData?.accessUrl);
    }
  }, [uploadData?.accessUrl]);

  const removeProfileImage = useCallback(e => {
    e.preventDefault();
    setProfilePic("");
  }, []);

  const profileImageUploading = useCallback(
    e => {
      e.preventDefault();
      if (!uploadLoading) {
        const file = e.target.files[0];
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          uploadFile(formData);
        }
      }
    },
    [uploadFile, uploadLoading],
  );

  const goBack = useCallback(e => {
    e?.preventDefault();
    setActiveStep(prev => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  }, []);

  const goNext = useCallback(
    e => {
      e.preventDefault();
      formRef.current[activeStep]?.submitForm?.();
    },
    [activeStep],
  );

  const progressBarAction = useCallback((pageIndex, presentPage) => {
    if (pageIndex < presentPage) {
      setActiveStep(pageIndex);
    } else {
      // WHen User wants to go Next Form
      // TODO: Logic to check the Validation for current form and move to next
      formRef?.current?.[presentPage]?.canNavigate?.(pageIndex);
    }
  }, []);

  const navigateForm = useCallback(
    (nextIndex = null) => {
      const nextPage = isNull(nextIndex) ? activeStep + 1 : nextIndex;
      if (nextPage <= PATIENT_STEPPER.length - 1) {
        setCompletedStep(prev => ({ ...prev, [nextPage]: true }));
        setActiveStep(nextPage);
      }
    },
    [activeStep],
  );

  const checkFormsValidation = useCallback(async () => {
    for (let i = 0; i < formRef.current.length - 1; i++) {
      const passed = await formRef?.current?.[i]?.checkFilledForm?.();
      if (!passed) {
        return { pageToFill: i };
      }
    }
  }, []);

  const addPatientMutate = useCallback(() => {
    const editApiParam = getEditApiParam();
    const {
      encryptedCreditCards = [],
      clinicLocationId = "",
      physicianId = "",
      ...restFormInput
    } = inputData.current ?? {};
    const organizedParam = {
      encryptedCreditCards,
      clinicLocationId,
      physicianId,
      ...editApiParam,
      patient: { patientImageUrl: profilePic, ...restFormInput },
    };

    patientMutate(organizedParam);
  }, [getEditApiParam, patientMutate, profilePic]);

  const checkAllFormValidation = useCallback(async () => {
    const formValidation = await checkFormsValidation();
    if (has(formValidation, "pageToFill")) {
      setActiveStep(formValidation.pageToFill);
    } else {
      addPatientMutate();
    }
  }, [addPatientMutate, checkFormsValidation]);

  const formData = useCallback(
    (data, formType = "", progressBarIndex = null, allowNavigation = true) => {
      inputData.current = null;
      switch (formType) {
        case STEPPER_FORMS.PERSONAL_INFO:
          personalData.current = { ...data.info };
          break;
        case STEPPER_FORMS.ADDRESS_INFO:
          addressData.current = { ...data.info };
          break;
        case STEPPER_FORMS.SHIPPING_INFO:
          shippingData.current = { ...data.info };
          break;
        case STEPPER_FORMS.BILLING_INFO:
          billingData.current = { ...data.info };
          break;
        default:
          personalData.current = null;
          addressData.current = null;
          billingData.current = null;
          shippingData.current = null;
          break;
      }

      inputData.current = {
        ...inputData.current,
        ...personalData.current,
        ...addressData.current,
        ...billingData.current,
        ...shippingData.current,
      };
      if (allowNavigation) {
        if (isLastForm) {
          checkAllFormValidation();
        } else {
          navigateForm(progressBarIndex);
        }
      }
    },
    [checkAllFormValidation, isLastForm, navigateForm],
  );

  return {
    formRef,
    activeStep,
    profilePic,
    mutateError,
    completedStep,
    mutateLoading,
    clinicLocationId,
    patientInsertSuccessfully,
    patientDefaultAddress,
    imageUploading: uploadLoading,
    formData,
    goBack,
    goNext,
    setProfilePic,
    progressBarAction,
    removeProfileImage,
    profileImageUploading,
  };
}

export default usePatientProvider;
