import React from "react";
import { Typography } from "@mui/material";
import { CustomStepIconStyled } from "./StyledComponent";
import CreateIcon from "@mui/icons-material/Create";

function CustomStepIcon(props) {
  const { icon, active, completed, className } = props;

  return (
    <CustomStepIconStyled ownerState={{ completed, active }} className={className}>
      {active ? (
        <CreateIcon style={{ width: 22, height: 22 }} />
      ) : (
        <Typography variant="button" color={"white"}>
          {icon}
        </Typography>
      )}
    </CustomStepIconStyled>
  );
}

export default React.memo(CustomStepIcon);
