export const COOKIES_NAME = {
  ROLE: "user_role",
  USER_ID: "user_id",
  EMAIL: "user_email",
  CLINIC_ID: "clinic_id",
  USER_TOKEN: "user_token",
  LAST_NAME: "user_lastName",
  IS_LOGGED: "user_logged_in",
  FIRST_NAME: "user_firstName",
  REFRESH_TOKEN: "refresh_token",
  IS_VERIFIED: "user_isVerified",
  CLINIC_LOCATION_IDS: "clinic_location_ids",
  IS_SUPER_USER_SAME_AS_PROVIDER:"isSuperUserSameAsProvider",
};
