/* eslint-disable react-hooks/exhaustive-deps */
import { has, isNull } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { useInsertClinicLocationMutation, useUploadFileMutation } from "rtk";
import { CLINIC_LOCATION_STEPPER, STEPPER_FORMS, base64toFile } from "utils";

function useClinicLocationProvider(getEditApiParam, isClinicLocationEdit) {
  const formRef = useRef([]);
  const inputData = useRef(null);
  const personalData = useRef(null);
  const creditData = useRef(null);
  const notificationData = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [completedStep, setCompletedStep] = useState(() =>
    isClinicLocationEdit ? { 0: true, 1: true, 2: true, 3: true } : { 0: true },
  );

  const [uploadFileMutation, { error: uploadError, isLoading: uploadLoading }] = useUploadFileMutation();
  const [
    ClinicLocationMutate,
    {
      isSuccess: clinicLocationInsertSuccessfully,
      error: clinicLocationError,
      isLoading: clinicLocationLoading,
      reset: resetMutation,
    },
  ] = useInsertClinicLocationMutation();

  const fileUploading = useMemo(() => uploadLoading, [uploadLoading]);
  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);
  const mutateLoading = useMemo(() => clinicLocationLoading, [clinicLocationLoading]);
  const mutateError = useMemo(
    () => uploadError?.data?.message || clinicLocationError?.data?.message || "",
    [clinicLocationError?.data?.message, uploadError?.data?.message],
  );

  const uploadFile = useCallback(
    async (e, signPad = "") => {
      e?.preventDefault?.();
      if (!uploadLoading) {
        let file = "";
        if (signPad) {
          file = base64toFile(signPad);
        } else {
          file = e.target.files[0];
        }
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          const uploadResponse = await uploadFileMutation(formData).unwrap();
          return uploadResponse?.accessUrl;
        }
      }
    },
    [uploadFileMutation, uploadLoading],
  );

  const goBack = useCallback(e => {
    e?.preventDefault();
    setActiveStep(prev => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  }, []);

  const goNext = useCallback(
    e => {
      e.preventDefault();
      formRef.current[activeStep]?.submitForm?.();
    },
    [activeStep],
  );

  const progressBarAction = useCallback((pageIndex, presentPage) => {
    if (pageIndex < presentPage) {
      setActiveStep(pageIndex);
    } else {
      // WHen User wants to go Next Form
      // TODO: Logic to check the Validation for current form and move to next
      formRef?.current?.[presentPage]?.canNavigate?.(pageIndex);
    }
  }, []);

  const navigateForm = useCallback(
    (nextIndex = null) => {
      const nextPage = isNull(nextIndex) ? activeStep + 1 : nextIndex;
      if (nextPage <= CLINIC_LOCATION_STEPPER.length - 1) {
        setCompletedStep(prev => ({ ...prev, [nextPage]: true }));
        setActiveStep(nextPage);
      }
    },
    [activeStep],
  );

  const checkFormsValidation = useCallback(async () => {
    for (let i = 0; i < formRef.current.length - 1; i++) {
      const passed = await formRef?.current?.[i]?.checkFilledForm?.();
      if (!passed) {
        return { pageToFill: i };
      }
    }
  }, []);

  const addClinicLocationMutate = useCallback(async () => {
    const editApiParam = getEditApiParam();
    resetMutation();
    const { encryptedCreditCards, staffIds, ...restFormInput } = inputData.current ?? {};
    const organizedParam = {
      ...editApiParam,
      staffIds,
      clinicLocation: { ...restFormInput },
      encryptedCreditCards: encryptedCreditCards,
    };
    ClinicLocationMutate(organizedParam);
  }, [getEditApiParam, ClinicLocationMutate]);

  const checkAllFormValidation = useCallback(async () => {
    const formValidation = await checkFormsValidation();
    if (has(formValidation, "pageToFill")) {
      setActiveStep(formValidation.pageToFill);
    } else {
      addClinicLocationMutate();
    }
  }, [addClinicLocationMutate, checkFormsValidation]);

  const formData = useCallback(
    (data, formType = "", progressBarIndex = null, allowNavigation = true) => {
      inputData.current = null;
      switch (formType) {
        case STEPPER_FORMS.PERSONAL_INFO:
          personalData.current = { ...data.info };
          break;
        case STEPPER_FORMS.CREDIT_INFO:
          creditData.current = { ...data.info };
          break;
        case STEPPER_FORMS.NOTIFICATION_INFO:
          notificationData.current = { ...data.info };
          break;
        default:
          personalData.current = null;
          creditData.current = null;
          notificationData.current = null;
          break;
      }

      inputData.current = {
        ...inputData.current,
        ...personalData.current,
        ...creditData.current,
        ...notificationData.current,
      };

      if (allowNavigation) {
        if (isLastForm) {
          checkAllFormValidation();
        } else {
          navigateForm(progressBarIndex);
        }
      }
    },
    [checkAllFormValidation, isLastForm, navigateForm],
  );

  return {
    formRef,
    activeStep,
    mutateError,
    completedStep,
    mutateLoading,
    fileUploading,
    clinicLocationInsertSuccessfully,
    formData,
    goBack,
    goNext,
    progressBarAction,
    uploadFile,
  };
}

export default useClinicLocationProvider;
