import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import { cookies, USER_ROLES } from "utils";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import Spinner from "components/spinner";
const PhysicianDD = (props) => {
  const [physicians, setPhysicians] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);
  useEffect(() => {
    const clinicCookie = cookies.get("clinic_id");
    const formData = {
      clinicId: clinicCookie || props?.clinicId || "",
      isPatientLogin: props.isPatLogin ? props.isPatLogin : false,
    };
    setShowSpinner(true);

    axiosConfig
      .post(
        "staffs/getAllPhysicianByClinicId/",
        formData,

        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        let physiciansList = [];
        let physicianDataList = response.data;
        physicianDataList.forEach((physician) => {
          if (
            physician.clinicLocationIds.includes(props.clinicLocationId) &&
            !userRole?.toLowerCase()?.includes("provider") &&
            !userRole?.toLowerCase()?.includes("support")
          ) {
            let filteredLocation = physician.clinicLocationIds.filter((a) => a === props.clinicLocationId)[0];
            physiciansList.push(
              <option key={physician.id} value={physician.id}>
                {physician.firstName + " " + physician.lastName}
              </option>
            );
          } else if (
            (userRole?.toLowerCase()?.includes("provider") || userRole?.toLowerCase()?.includes("support")) &&
            userInfo?.clinicLocationIds &&
            physician.clinicLocationIds.find((a) => userInfo.clinicLocationIds.includes(a))
          ) {
            physiciansList.push(
              <option key={physician.id} value={physician.id}>
                {physician.firstName + " " + physician.lastName}
              </option>
            );
          }
        });
        setPhysicians(physiciansList);
        setShowSpinner(false);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, [props.clinicLocationId]);

  return (
    <FormGroup key={props}>
      <Label className="normalText" for={props.name}>
        Provider {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>

      {showSpinner ? (
        <Spinner />
      ) : (
        <>
          <p className="dropdownArrow">
            <i className="fas fa-angle-down"></i>
          </p>{" "}
          <Input
            type="select"
            id={"physicianDDList"}
            name={props.name}
            required={props.required}
            onChange={(e) => {}}
            defaultValue={props.defaultValue ? props.defaultValue : ""}
          >
            <option value="" disabled>
              Select
            </option>
            {physicians}
          </Input>
        </>
      )}
    </FormGroup>
  );
};

export default PhysicianDD;
