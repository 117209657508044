const STEPPER_FORMS = {
  PORTAL_INFO: "PortalInfoForm",
  CREDIT_INFO: "PortalCreditForm",
  PERSONAL_INFO: "PersonalInfoForm",
  BILLING_INFO: "PortalBillingForm",
  ADDRESS_INFO: "PortalAddressesForm",
  SHIPPING_INFO: "PortalShippingForm",
  NOTIFICATION_INFO: "PortalNotificationForm",
};

const PATIENT_STEPPER = [
  "Personal Information",
  "Contact Information",
  "Shipping Information",
  "Billing & Preferences",
];
const STAFF_STEPPER = ["Personal Information", "Contact Information", "Portal Information"];
const CLINIC_LOCATION_STEPPER = ["Location Information", "Credit Card Information", "Notifications & Preferences"];

export { STEPPER_FORMS, PATIENT_STEPPER, STAFF_STEPPER, CLINIC_LOCATION_STEPPER };
