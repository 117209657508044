import React, { useState, useEffect } from "react"
import { FormGroup, Label, Input } from "reactstrap"

const RaceDD = (props) => {
	const [defaultValue, setDefaultValue] = useState(props.defaultValue);

	useEffect(() => {
    setDefaultValue((props.defaultValue) ? (props.defaultValue) : (''));
  }, [props]);
  
  return (
  	<FormGroup key={defaultValue}>
      <Label className="normalText" for={props.name}>Race {(props.required) ? (<span className="requiredStar">*</span>) : ('')}</Label>
      <p className="dropdownArrow"><i className="fas fa-angle-down"></i></p>
	  	<Input type="select" name={props.name} required={props.required} defaultValue={defaultValue}>
	      <option value="" disabled>Select</option>
	      <option value="American Indian or Alaska Native">American Indian or Alaska Native</option>
	      <option value="Asian">Asian</option>
	      <option value="Black or African American">Black or African American</option>
	      <option value="Hispanic or Latino">Hispanic or Latino</option>
	      <option value="Native Hawaiian or Other Pacific Islander">Native Hawaiian or Other Pacific Islander</option>
	      <option value="White">White</option>
	      <option value="Two or More Races">Two or More Races</option>
	    </Input>
    </FormGroup>
	)
}

export default RaceDD
