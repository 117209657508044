import React, { useEffect, useState, useRef } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Cookies from "universal-cookie";
const SubcategoryOneDD = props => {
  //TODO: Populate based on clinic products
  const { name, label, register, required, subcategory1DefaultVal } = props;

  const [fam, setFam] = useState("");
  const [families, setFamilies] = useState([]);

  // setFam(watch().family);

  useEffect(() => {
    axiosConfig
      .get("products/getAllSubcategoriesOne", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        var familyList = [];
        var sortedData = response.data.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));

        await sortedData.forEach((data, i) => {
          familyList.push(data);

          familyList.push(
            <option key={data + i} value={data}>
              {data}
            </option>,
          );
        });

        setFamilies(familyList);
      })
      .catch(error => {});
  }, []);
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label}
      </Label>
      <select
        name={name}
        value={subcategory1DefaultVal ? subcategory1DefaultVal : ""}
        {...register(name, { required: false })}
        style={{
          borderRadius: "5px",
          paddingBottom: "0px",
          width: "100%",
          height: "40px",
          borderColor: "#CDCDCD",
        }}>
        <option value="" disabled>
          {label}
        </option>
        {families}
      </select>
    </FormGroup>
  );
};

export default SubcategoryOneDD;
