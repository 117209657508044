import React from "react";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import AdminNavbar from "./AdminNavbar";
import ClinicNavbar from "./ClinicNavbar";

function NavBar() {
  const userRole = useSelector(userRoleSelector);
  return userRole?.toLowerCase().includes("clinic") ? <ClinicNavbar /> : <AdminNavbar />;
}
export default React.memo(NavBar);
