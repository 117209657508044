import React, { useMemo } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../dropdowns/stateDD";
import ReCAPTCHA from "react-google-recaptcha";
import { SignaturePad } from "components/SignaturePad";
import CreditCardInput from "react-credit-card-input";
import { encrypt, decrypt } from "cryptofunc";
import "./index.scss";
const StepOne = ({
  step,
  onlyOneUser,
  clinicAddress,
  onChange,
  setOnlyOneUser,
  upload,
  setClinicAddress,
  signatureImageUrl,
  signatureModal,
  closeSignatureModal,
  onClickSave,
  setSignatureModal,
  adminSide = false,
  deaAddressInfo,
  setDeaAddressInfo,
}) => {
  return (
    <div className={`${step === 1 ? "d-block" : "d-none"}`}>
      <FormGroup className="mb-4">
        <Label htmlFor="businessName" className="form-label">
          Business name <span className="requiredStar">*</span>
        </Label>
        <Input
          name="businessName"
          className="form-control"
          type="text"
          placeholder=""
          required={step === 1 ? true : false}
        />
      </FormGroup>

      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="addressLine1" className="form-label">
              Address Line 1 <span className="requiredStar">*</span>
            </Label>
            <Input
              maxLength={50}
              value={clinicAddress?.addressLine1}
              onChange={e =>
                setClinicAddress({
                  addressLine1: e.target.value,
                  addressLine2: clinicAddress?.addressLine2,
                  zipcode: clinicAddress?.zipcode,
                  state: clinicAddress?.state,
                  city: clinicAddress?.city,
                })
              }
              name="addressLine1"
              className="form-control"
              type="textarea"
              required={step === 1 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="addressLine2" className="form-label">
              Address Line 2
            </Label>
            <Input
              maxLength={50}
              value={clinicAddress?.addressLine2 !== "" ? clinicAddress?.addressLine2 : ""}
              onChange={e =>
                setClinicAddress({
                  addressLine1: clinicAddress?.addressLine1,
                  addressLine2: e.target.value !== "" ? e.target.value : "",
                  zipcode: clinicAddress?.zipcode,
                  state: clinicAddress?.state,
                  city: clinicAddress?.city,
                })
              }
              name="addressLine2"
              className="form-control"
              type="textarea"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="city" className="form-label">
              City <span className="requiredStar">*</span>
            </Label>
            <Input
              value={clinicAddress?.city}
              onChange={e =>
                setClinicAddress({
                  addressLine1: clinicAddress?.addressLine1,
                  addressLine2: clinicAddress?.addressLine2,
                  zipcode: clinicAddress?.zipcode,
                  state: clinicAddress?.state,
                  city: e.target.value,
                })
              }
              name="city"
              className="form-control"
              type="text"
              required={step === 1 ? true : false}
            />
          </div>
        </Col>
        <Col md={4}>
          <StateDD defaultValue={clinicAddress?.state} name="state" required={step === 1 ? true : false} />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="zipcode" className="form-label">
              Zip code <span className="requiredStar">*</span>
            </Label>

            <Input
              value={clinicAddress?.zipcode}
              onChange={e =>
                setClinicAddress({
                  addressLine1: clinicAddress?.addressLine1,
                  addressLine2: clinicAddress?.addressLine2,
                  zipcode: e.target.value,
                  state: clinicAddress?.state,
                  city: clinicAddress?.city,
                })
              }
              name="zipcode"
              className="form-control"
              required={step === 1 ? true : false}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="officePhone" className="form-label">
              Office Phone <span className="requiredStar">*</span>
            </Label>
            <Input
              name="officePhone"
              className="form-control"
              type="tel"
              pattern={step === 1 ? "[0-9]{3}[0-9]{3}[0-9]{4}" : null}
              placeholder="Format: 1234567890"
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="fax" className="form-label">
              Office Fax
            </Label>
            <Input
              name="fax"
              className="form-control"
              type="tel"
              pattern={step === 1 ? "[0-9]{3}[0-9]{3}[0-9]{4}" : null}
              placeholder="Format: 1234567890"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-4">
          <FormGroup>
            <Label htmlFor="email" className="form-label">
              Office Email <span className="requiredStar">*</span>
            </Label>
            <Input
              name="email"
              className="form-control"
              type="email"
              placeholder=""
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-4">
          <FormGroup>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Provider is Super User</span>{" "}
              <input
                checked={onlyOneUser}
                onChange={e => {
                  setOnlyOneUser(e.target.checked);
                }}
                name="isSuperUserSameAsProvider"
                type="checkbox"
                className="mx-2"
                placeholder=""
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="mb-4">
          <FormGroup>
            <Label htmlFor="providerCredentials" className="form-label">
              Prescriber Credentials <span className="requiredStar">*</span>
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input
              name="providerCredentials"
              className="form-control"
              placeholder="Last name"
              type="select"
              required={step === 1 ? true : false}>
              <option value="MD">MD</option>
              <option value="DO">DO</option>
              <option value="DDS">DDS</option>
              <option value="DVM">DVM</option>
              <option value="NP">NP</option>
              <option value="PA">PA</option>
              <option value="PA-C">PA-C</option>
              <option value="ARPN">ARPN</option>
              <option value="DPM">DPM</option>
              <option value="Other">Other</option>
            </Input>
          </FormGroup>
        </Col>
        {!onlyOneUser ? (
          <>
            <Col md={4} className="mb-4">
              <FormGroup>
                <Label htmlFor="providerFirstName" className="form-label">
                  Prescriber First Name <span className="requiredStar">*</span>
                </Label>
                <Input
                  name="providerFirstName"
                  className="form-control"
                  placeholder="First name"
                  type="text"
                  required={step === 1 ? true : false}
                />
              </FormGroup>
            </Col>
            <Col md={4} className="mb-4">
              <FormGroup>
                <Label htmlFor="providerLastName" className="form-label">
                  Prescriber Last Name <span className="requiredStar">*</span>
                </Label>
                <Input
                  name="providerLastName"
                  className="form-control"
                  placeholder="Last name"
                  type="text"
                  required={step === 1 ? true : false}
                />
              </FormGroup>
            </Col>{" "}
          </>
        ) : null}
      </Row>
      {!onlyOneUser ? (
        <Row>
          <Col md={6} className="mb-4">
            <FormGroup>
              <Label htmlFor="providerPhone" className="form-label">
                Prescriber Phone<span className="requiredStar">*</span>
              </Label>
              <Input
                name="providerPhone"
                className="form-control"
                type="tel"
                pattern={step === 1 ? "[0-9]{3}[0-9]{3}[0-9]{4}" : null}
                placeholder="Format: 1234567890"
                required={step === 1 ? true : false}
              />
            </FormGroup>
          </Col>

          <Col md={6} className="mb-4">
            <FormGroup>
              <Label htmlFor="providerEmail" className="form-label">
                Prescriber Email<span className="requiredStar">*</span>
              </Label>
              <Input name="providerEmail" className="form-control" type="email" required={step === 1 ? true : false} />
            </FormGroup>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={4} className="mb-4">
          <FormGroup>
            <Label htmlFor="providerNpi" className="form-label">
              Prescriber NPI <span className="requiredStar">*</span>
            </Label>
            <Input
              name="providerNpi"
              className="form-control"
              type="text"
              placeholder=""
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>{" "}
        <Col md={4} className="mb-4">
          <FormGroup>
            <StateDD name="providerLicensingState" className="form-control" required={step === 1 ? true : false} />
          </FormGroup>
        </Col>
        <Col md={4} className="mb-4">
          <FormGroup>
            <a target="_blank" href="https://npiregistry.cms.hhs.gov/" style={{ textDecoration: "underline" }}>
              Look up your NPI here
            </a>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="providerStateLicenseExpirationDate" className="form-label">
              Prescriber State License #<span className="requiredStar">*</span>
            </Label>
            <Input
              name="providerMedicalLicenseNumber"
              className="form-control"
              type="text"
              placeholder=""
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="providerStateLicenseExpirationDate" className="form-label">
              State License Expiration Date<span className="requiredStar">*</span>
            </Label>
            <Input
              name="providerStateLicenseExpirationDate"
              className="form-control"
              type="date"
              placeholder=""
              required={step === 1 ? true : false}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="providerDea" className="form-label">
              Prescriber DEA #
            </Label>{" "}
            <Input name="providerDea" className="form-control" type="text" placeholder="" />
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <FormGroup>
            <Label htmlFor="providerDeaLicenseExpirationDate" className="form-label">
              DEA License Expiration Date
            </Label>
            <Input name="providerDeaLicenseExpirationDate" className="form-control" type="date" placeholder="" />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <FormGroup>
            <div className="mb-4">
              <Label htmlFor="stateLicense" className="form-label">
                Copy of State License <span className="requiredStar">*</span>
              </Label>
              <Input
                name="stateLicense"
                onChange={e => {
                  upload(e, "state");
                }}
                className="form-control"
                type="file"
                id="stateLicense"
                accept="image/*, .pdf"
                required={step === 1 ? true : false}
              />
              <p className="redText">Only image or pdf files are allowed</p>
            </div>
          </FormGroup>
        </Col>
        <Col md={6} className="mb-4">
          <div className="mb-4">
            <Label htmlFor="deaLicense" className="form-label">
              Copy of DEA license
            </Label>
            <Input
              name="deaLicense"
              onChange={e => {
                upload(e, "dea");
              }}
              className="form-control"
              type="file"
              accept="image/*, .pdf"
              id="deaLicense"
            />
            <p className="redText">Only image or pdf files are allowed</p>
          </div>
        </Col>
      </Row>
      <Row>
        <h4>DEA Address</h4>
      </Row>
      <Row>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="dea_addressLine1" className="form-label">
              Address Line 1
            </Label>
            <Input
              maxLength={50}
              value={deaAddressInfo?.addressLine1}
              onChange={e =>
                setDeaAddressInfo({
                  addressLine1: e.target.value,
                  addressLine2: deaAddressInfo?.addressLine2,
                  zipcode: deaAddressInfo?.zipcode,
                  state: deaAddressInfo?.state,
                  city: deaAddressInfo?.city,
                })
              }
              name="dea_addressLine1"
              className="form-control"
              type="textarea"
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="dea_addressLine2" className="form-label">
              Address Line 2
            </Label>
            <Input
              maxLength={50}
              value={deaAddressInfo?.addressLine2 !== "" ? deaAddressInfo?.addressLine2 : ""}
              onChange={e =>
                setDeaAddressInfo({
                  addressLine1: deaAddressInfo?.addressLine1,
                  addressLine2: e.target.value !== "" ? e.target.value : "",
                  zipcode: deaAddressInfo?.zipcode,
                  state: deaAddressInfo?.state,
                  city: deaAddressInfo?.city,
                })
              }
              name="dea_addressLine2"
              className="form-control"
              type="textarea"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="dea_city" className="form-label">
              City
            </Label>
            <Input
              value={deaAddressInfo?.city}
              onChange={e =>
                setDeaAddressInfo({
                  addressLine1: deaAddressInfo?.addressLine1,
                  addressLine2: deaAddressInfo?.addressLine2,
                  zipcode: deaAddressInfo?.zipcode,
                  state: deaAddressInfo?.state,
                  city: e.target.value,
                })
              }
              name="dea_city"
              className="form-control"
              type="text"
            />
          </div>
        </Col>
        <Col md={4}>
          <StateDD
            defaultValue={deaAddressInfo?.state}
            name="dea_state"
            onChange={e =>
              setDeaAddressInfo({
                addressLine1: deaAddressInfo?.addressLine1,
                addressLine2: deaAddressInfo?.addressLine2,
                zipcode: deaAddressInfo?.zipcode,
                state: e.target.value,
                city: deaAddressInfo?.city,
              })
            }
          />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="dea_zipcode" className="form-label">
              Zip code
            </Label>

            <Input
              value={deaAddressInfo?.zipcode}
              onChange={e =>
                setDeaAddressInfo({
                  addressLine1: deaAddressInfo?.addressLine1,
                  addressLine2: deaAddressInfo?.addressLine2,
                  zipcode: e.target.value,
                  state: deaAddressInfo?.state,
                  city: deaAddressInfo?.city,
                })
              }
              name="dea_zipcode"
              className="form-control"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <div className="mb-4">
            <Label htmlFor="verbalOrders" className="form-label">
              Verbal Orders
            </Label>{" "}
            <p className="dropdownArrow">
              <i className="fas fa-angle-down"></i>
            </p>
            <Input name="verbalOrders" type="select">
              <option value="Prescribing doctor(s) ONLY">Prescribing doctor(s) ONLY</option>
              <option value="Any clinic medical staff">Any clinic medical staff</option>
              <option value="NO verbal orders can be placed outside of prescribing doctor(s)">
                NO verbal orders can be placed outside of prescribing doctor(s)
              </option>
            </Input>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="mb-4">
          <div className="mb-4">
            <Label htmlFor="signatureImageUrl" className="form-label">
              Provider's Signature
            </Label>
            <br></br>
            <small>
              <i>(Provide signature using the signature pad.)</i>
            </small>
            <br></br>
            {signatureImageUrl ? (
              <Row>
                <img
                  style={{ width: "150px", height: "auto" }}
                  className="signatureImageUrl mb-1"
                  src={signatureImageUrl}
                  alt=""
                />
              </Row>
            ) : null}{" "}
            <Row>
              <Col md={6}>
                <span className="pointer form-control" onClick={() => setSignatureModal(true)}>
                  <i class="fas fa-pen mx-2"></i>Signature Pad
                </span>
              </Col>
            </Row>
          </div>
          <SignaturePad
            actionText="Save"
            person="signatureImageUrl"
            onClickSave={onClickSave}
            closeModal={closeSignatureModal}
            showModal={signatureModal}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StepOne;
