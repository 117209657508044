import React, { useEffect, useState, useMemo } from "react";
import { USER_ROLES } from "utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
/*Style CSS*/
import "./index.scss";
import {
  useLazyGetBalanceDueByClinicIdQuery,
  useLazyGetBalanceDueQuery,
  useLazyMostDueInvoiceQuery,
  useLazyViewCurrentStatementByClinicIdQuery,
  useLazyViewCurrentStatementQuery,
  useLazyViewPaidInvoicesByClinicIdQuery,
  useLazyViewPaidInvoicesQuery,
  useLazyViewPaymentHistoryByClinicIdQuery,
  useLazyViewPaymentHistoryQuery,
} from "rtk";
import { first, some } from "lodash";

const usePaymentGrid = hookProps => {
  const { selectedInvoices = [], ...props } = hookProps;
  const [paymentButton, setPaymentButton] = useState("");
  const [balanceDue, setBalanceDue] = useState(0);
  const [oldInvoiceDue, setOldInvoiceDue] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);

  const clinicSide = userRole?.toLowerCase().includes("clinic");
  const condition = userRole === USER_ROLES.CLINIC_SUPER_USER || userInfo?.isSuperUserSameAsProvider;
  const [clinicId, setClinicId] = useState(userInfo?.clinicId);
  const [clinicLocationId, setClinicLocationId] = useState(userInfo?.clinicLocationId);
  const [instructions, setInstructions] = useState("");
  const [fetchAdminMostInvoiceDue, { isFetching: isAMID_Fetching, isError: isAMID_Error, error: AMID_Error }] =
    useLazyMostDueInvoiceQuery();
  const [fetchClinicMostInvoiceDue, { isFetching: isCMID_Fetching, isError: isCMID_Error, error: CMID_Error }] =
    useLazyMostDueInvoiceQuery();
  const [fetchAdminGetBalanceDue, { isFetching: isAGB_Fetching, isError: isAGB_Error, error: AGB_Error }] =
    useLazyGetBalanceDueQuery();
  const [fetchClinicGetBalanceDue, { isFetching: isCGB_Fetching, isError: isCGB_Error, error: CGB_Error }] =
    useLazyGetBalanceDueByClinicIdQuery();
  const [fetchClinicPaidInvoices, { isFetching: isCPI_Fetching, isError: isCPI_Error, error: CPI_Error }] =
    useLazyViewPaidInvoicesByClinicIdQuery();
  const [fetchAdminPaidInvoices, { isFetching: isAPI_Fetching, isError: isAPI_Error, error: API_Error }] =
    useLazyViewPaidInvoicesQuery();
  const [fetchAdminPaymentHistory, { isFetching: isAPH_Fetching, isError: isAPH_Error, error: APH_Error }] =
    useLazyViewPaymentHistoryQuery();
  const [fetchClinicPaymentHistory, { isFetching: isCPH_Fetching, isError: isCPH_Error, error: CPH_Error }] =
    useLazyViewPaymentHistoryByClinicIdQuery();
  const [fetchAdminCurrentStatement, { isFetching: isACS_Fetching, isError: isACS_Error, error: ACS_Error }] =
    useLazyViewCurrentStatementQuery();
  const [fetchClinicCurrentStatement, { isFetching: isCCS_Fetching, isError: isCCS_Error, error: CCS_Error }] =
    useLazyViewCurrentStatementByClinicIdQuery();

  const hasSelectedRows = useMemo(() => Boolean(selectedInvoices?.length), [selectedInvoices?.length]);
  const selectedInvoicesNames = useMemo(() => selectedInvoices.map(inv => inv.invoiceNumber), [selectedInvoices]);
  const showPayNow = useMemo(() => {
    let checkInvoice = Boolean(selectedInvoices?.length);
    if (selectedInvoices?.length) {
      const firstClinicId = first(selectedInvoices)?.clinicId;
      checkInvoice = !some(selectedInvoices, inv => inv.clinicId !== firstClinicId);
    }
    return checkInvoice;
  }, [selectedInvoices]);

  const showError = useMemo(
    () =>
      isAMID_Error ||
      isCMID_Error ||
      isAGB_Error ||
      isAGB_Error ||
      isCPI_Error ||
      isAPI_Error ||
      isAPH_Error ||
      isCPH_Error ||
      isCPH_Error ||
      isACS_Error ||
      isCCS_Error,
    [
      isAMID_Error,
      isCMID_Error,
      isAGB_Error,
      isCPI_Error,
      isAPI_Error,
      isAPH_Error,
      isCPH_Error,
      isACS_Error,
      isCCS_Error,
    ],
  );
  const showLoading = useMemo(
    () => isCPI_Fetching || isAPI_Fetching || isAPH_Fetching || isCPH_Fetching || isACS_Fetching || isCCS_Fetching,
    [isCPI_Fetching, isAPI_Fetching, isAPH_Fetching, isCPH_Fetching, isACS_Fetching, isCCS_Fetching],
  );
  const errorMessage = useMemo(
    () =>
      AGB_Error?.data?.message ||
      CGB_Error?.data?.message ||
      CPI_Error?.data?.message ||
      API_Error?.data?.message ||
      APH_Error?.data?.message ||
      CPH_Error?.data?.message ||
      ACS_Error?.data?.message ||
      CCS_Error?.data?.message,
    [
      AGB_Error?.data?.message,
      CGB_Error?.data?.message,
      CPI_Error?.data?.message,
      API_Error?.data?.message,
      APH_Error?.data?.message,
      CPH_Error?.data?.message,
      ACS_Error?.data?.message,
      CCS_Error?.data?.message,
    ],
  );

  useEffect(() => {
    mostInvoiceDue();
  }, [props.success, props.paymentStatus]);

  useEffect(() => {
    getBalanceDue();
  }, [props.success]);

  const getBalanceDue = async (fromDate = "", toDate = "", keyword = "", paymentStatus = "") => {
    let isClinic = clinicSide ? true : false;
    let body = isClinic
      ? {
          clinicId: isClinic ? clinicId : "",
          fromDate: fromDate && toDate ? moment(fromDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
          toDate: fromDate && toDate ? moment(toDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
          keyword: keyword,
          paymentStatus: paymentStatus,
        }
      : {
          fromDate: fromDate && toDate ? moment(fromDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
          toDate: fromDate && toDate ? moment(toDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
          keyword: keyword,
          paymentStatus: paymentStatus,
        };
    let arg = {
      payload: body,
    };
    let response = "";
    if (clinicSide) response = await fetchClinicGetBalanceDue(arg);
    else response = await fetchAdminGetBalanceDue(arg);
    setBalanceDue(response?.data?.totalBalanceDue);
  };

  const payNow = () => {
    if (paymentType === "selected") {
      props.payNow(selectedInvoices, false, false, "", "", "", "", "");
    } else if (paymentType === "full")
      props.payNow([], true, false, "", props.fromDate, props.toDate, props.paymentStatus, props.keyword);
    closeModal();
  };

  const closeModal = () => {
    setConfirmModal(false);
  };
  const mostInvoiceDue = async () => {
    let response = "";
    let arg = {
      payload: clinicSide ? { clinicId: clinicId } : {},
    };
    if (clinicSide) {
      response = await fetchClinicMostInvoiceDue(arg, false);
    } else {
      response = await fetchAdminMostInvoiceDue(arg, false);
    }
    if (response?.data)
      setOldInvoiceDue({
        days: response?.data?.days ? response?.data?.days : "",
        invoiceNumber: response?.data?.invoiceNumber ? response?.data?.invoiceNumber : "",
        unPaidInvoices: response?.data?.unPaidInvoices ? response?.data?.unPaidInvoices : 0,
      });
  };
  const viewPaymentHistory = async () => {
    let arg = {
      payload: clinicSide ? (condition ? { clinicId: clinicId } : { clinicLocationId: clinicLocationId }) : {},
      metaData: {
        clinicSuper: condition,
      },
    };
    let response = "";
    if (clinicSide) response = await fetchClinicPaymentHistory(arg);
    else response = await fetchAdminPaymentHistory(arg);

    if (response?.data) {
      var myblob = new Blob([response.data], {
        type: "text/plain",
      });
      let url = URL.createObjectURL(myblob);
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payment_history.csv");
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setShowSpinner(false);
    }
  };
  const viewPaidInvoices = async () => {
    let response = "";
    let arg = {
      payload: clinicSide
        ? condition
          ? {
              paymentStatus: "Approved",
              clinicId: clinicId,
            }
          : { paymentStatus: "Approved", clinicLocationId: clinicLocationId }
        : { paymentStatus: "Approved" },
      metaData: {
        clinicSuper: condition,
      },
    };

    if (clinicSide) response = await fetchClinicPaidInvoices(arg);
    else response = await fetchAdminPaidInvoices(arg);

    if (response?.data) {
      props.setExportSuccessMessage(response?.data?.msg);
      setShowSpinner(false);
    }
  };
  const viewCurrentStatement = async () => {
    let arg = {
      payload: clinicSide
        ? condition
          ? {
              clinicId: clinicId,
            }
          : { clinicLocationId: clinicLocationId }
        : {},
      metaData: {
        clinicSuper: condition,
        method: clinicSide ? "POST" : "GET",
      },
    };
    let response = "";
    if (clinicSide) response = await fetchClinicCurrentStatement(arg);
    else response = await fetchAdminCurrentStatement(arg);

    if (response?.data) {
      var myblob = new Blob([response.data], {
        type: "text/plain",
      });
      const url = URL.createObjectURL(myblob);
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "current_statement.csv");
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setShowSpinner(false);
    }
  };

  return {
    showPayNow,
    hasSelectedRows,
    selectedInvoicesNames,
    //
    showError,
    showLoading,
    errorMessage,
    payNow,
    closeModal,
    paymentType,
    setPaymentType,
    confirmModal,
    setConfirmModal,
    showSpinner,
    setShowSpinner,
    clinicSide,
    getBalanceDue,
    oldInvoiceDue,
    balanceDue,
    paymentButton,
    setPaymentButton,
    viewPaymentHistory,
    viewPaidInvoices,
    viewCurrentStatement,
    instructions,
    setInstructions,
    showError,
    showLoading,
    errorMessage,
  };
};
export default usePaymentGrid;
