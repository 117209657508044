import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import Lottie from "../../../components/lottie";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import AdminNavbar from "../../../components/AdminNavbar";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminRolesDD from "components/dropdowns/adminRoles";
import { USER_ROLES } from "utils";
import { userInfoSelector, userRoleSelector } from "selectors";
import { useSelector } from "react-redux";

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;

const EditAdmin = props => {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [admin, setAdmin] = useState({});
  const userRole = useSelector(userRoleSelector);
  useEffect(() => {
    setShowSpinner(true);

    axiosConfig
      .get("admin/account/getAdminByAdminId/" + props.match.params.id, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async response => {
        setAdmin(response.data);

        setShowSpinner(false);
      })
      .catch(error => {
        errorResponse(error);
        setShowSpinner(false);
      });
  }, []);

  async function handleValidSubmit(e) {
    e.preventDefault();
    setShowSpinner(true);

    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("registerForm"), { hash: true });
    await axiosConfig
      .post(
        "admin/account/editAdminByAdminId",
        {
          adminId: props.match.params.id,
          role: USER_ROLES.ADMIN_SUPER_USER === userRole ? obj.role : admin.role,
          firstName: obj.firstName,
          middleName: obj.middleName ? obj.middleName : "",
          lastName: obj.lastName,
          email: obj.email,
          password: obj.password,

          isToGivePortalAccess: obj.staff_access ? true : false,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        updateUserToCometChat(
          obj.firstName + " " + obj.lastName,
          props.match.params.id,
          "VPI",
          new Cookies().get("user_role"),
        );
        setShowSpinner(false);
        setError("");
        setComplete(true);
      })
      .catch(error => {
        setError("An error occurred while trying to update admin! " + error?.response?.data?.message);
        setShowSpinner(false);
        errorResponse(error);
      });
  }
  async function updateUserToCometChat(name, uid, clinicName, role) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}`;
    const bodyVal = {
      uid: uid,
      name: name,
      role: role.toLowerCase(),

      metadata: {
        businessName: clinicName,
        role: role,
      },
    };
    await axios
      .put(url, bodyVal, {
        headers: {
          apiKey: cometChatApiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(async response => {})
      .catch(error => {
        this.setState({
          error: "An error occurred while trying to fetch admins list!",
          spinner: false,
        });
      });
  }

  /**/
  return (
    <React.Fragment>
      <MetaTags>
        <title>Edit Admin | VPI Compounding</title>
      </MetaTags>
      <AdminNavbar />
      {showSpinner ? <Spinner /> : null}
      <div className="page-content">
        <Container fluid>
          <h4 className="p-3 text-muted">EDIT ADMIN</h4>
          {showSpinner ? <Spinner /> : null}
          <Row>
            <Col xs="12">
              <Card>
                <Container>
                  <CardBody>
                    {complete ? (
                      <div className="m-5 p-5 text-center">
                        <Lottie name="success-check" />
                        <p className="my-5">Admin profile has been updated</p>{" "}
                        <Link to="/admins" className="pinkButton">
                          View Admins
                        </Link>
                      </div>
                    ) : (
                      <>
                        <CardBody className="pt-0">
                          <div className="">
                            <form
                              id="registerForm"
                              autoComplete="off"
                              className="form-horizontal"
                              onSubmit={e => {
                                e.preventDefault();
                                if (password !== confirmPassword) {
                                  setError("Passwords do not match");
                                } else {
                                  setError("");
                                  handleValidSubmit(e);
                                }
                              }}>
                              {error ? (
                                <Alert color="danger" className="text-center">
                                  {error}
                                </Alert>
                              ) : null}
                              <div className="px-lg-5 px-sm-5 p-0">
                                <Row className="pt-5">
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="firstName" className="form-label">
                                        First name <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        defaultValue={admin.firstName}
                                        name="firstName"
                                        className="form-control"
                                        type="text"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="middleName" className="form-label">
                                        Middle name
                                      </Label>
                                      <Input
                                        defaultValue={admin.middleName}
                                        name="middleName"
                                        className="form-control"
                                        type="text"
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="lastName" className="form-label">
                                        Last name <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        defaultValue={admin.lastName}
                                        name="lastName"
                                        className="form-control"
                                        type="text"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="email" className="form-label">
                                        Email <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        defaultValue={admin.email}
                                        name="email"
                                        className="form-control"
                                        type="email"
                                        required={true}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="password" className="form-label">
                                        Password
                                      </Label>
                                      <Input
                                        name="password"
                                        className="form-control"
                                        type="password"
                                        placeholder=""
                                        autoComplete="new-password"
                                        onChange={e => setPassword(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="confirmPassword" className="form-label">
                                        Confirm Password
                                      </Label>
                                      <Input
                                        name="confirmPassword"
                                        className="form-control"
                                        type="password"
                                        placeholder="Retype your password"
                                        onChange={e => setConfirmPassword(e.target.value)}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6} className="mb-4">
                                    <div className="mt-4">
                                      <AdminRolesDD name="role" defaultValue={admin.role} />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <div className="mt-4">
                                    <input
                                      onChange={e => {}}
                                      name="staff_access"
                                      className="form-check-input"
                                      type="checkbox"
                                      id="portalAccess"
                                      defaultChecked={admin.isToGivePortalAccess}
                                    />
                                    <label className="form-check-label mx-2" htmlFor="portalAccess">
                                      Portal Access
                                    </label>
                                  </div>
                                </Row>
                              </div>
                              <Row className="my-5 text-center pt-3">
                                <button
                                  className="btn btn-primary darkBg mt-4 mx-auto"
                                  style={{ width: "40%" }}
                                  type="submit">
                                  Submit
                                </button>
                              </Row>
                            </form>
                            <Row className="text-center">
                              <Col>
                                <button onClick={() => props.history.goBack()} className="text-center pinkButton">
                                  Back
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </>
                    )}
                  </CardBody>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditAdmin;
