import React from 'react';
import Lottie from 'react-lottie';
import SuccessCheck from '../assets/vpi-lotties/success-check.json'
import Spinner from '../assets/vpi-lotties/spinner.json'
import NoChatSelected from '../assets/vpi-lotties/no-messages-selected.json'
import Error404 from '../assets/vpi-lotties/404.json'
import SadLottie from '../assets/vpi-lotties/sad-lottie.json'
import NoAccessLottie from '../assets/vpi-lotties/no-access.json'

export default function MyLottie(props) {
  var animationData = '';

  if (props.name === 'success-check') {
    animationData = SuccessCheck;
  } else if (props.name === 'no-chat-selected') {
    animationData = NoChatSelected;
  } else if (props.name === '404') {
    animationData = Error404;
  } else if (props.name === 'spinner') {
    animationData = Spinner;
  } else if (props.name === 'sad-lottie') {
    animationData = SadLottie;
  } else if (props.name === 'no-access') {
    animationData = NoAccessLottie;
  }

  const defaultLottieOptions = {
    loop: (props.loop) ? (props.loop) : (false),
    animationData: animationData
  }

  return (
    <div>
      <Lottie options={defaultLottieOptions} height={(props.height) ? (props.height) : (200)} width={(props.width) ? (props.width) : (200)} />
    </div>
  )
}
