import { Divider, List, ListItem, ListItemText } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Col } from "reactstrap";
import { formatDaySupply } from "utils";
import {
  getCreditUsed,
  getCredit,
  getTotalAmount,
  getSubTotal,
  getCreditRequested,
  getCreditApplied,
} from "utils/constants";
import { RUSH_ORDER_INFO } from "utils/StatusConstant";

function CartContainer({
  showProducts = true,
  showPatients = true,
  readOnly = false,
  selectedPatients = [],
  selectedProducts = [],
  ...props
}) {
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState([]);
  const [shipping, setShipping] = useState(0);
  const [rushCharges, setRushCharges] = useState(0);
  const [totalOfProducts, setTotalOfProducts] = useState(0.0);
  const rushElement = document.getElementById("rushOrderMethod");
  const shippingElement = document.getElementById("shippingMethod");
  const [creditRequested, setCreditRequested] = useState(0);
  const [creditUsed, setCreditUsed] = useState(props.creditUsed);
  const [creditApplied, setCreditApplied] = useState(props.creditApplied);
  const [subTotal, setSubTotal] = useState(0);
  const editBool = window?.location?.pathname.includes("edit-prescription");

  useEffect(() => {
    if (selectedProducts?.length) {
      props.getShippingRate?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProducts]);

  useEffect(() => {
    setShipping(parseFloat(props.shippingCost).toFixed(2));
    let shipCost = props.shippingCost ? parseFloat(props.shippingCost).toFixed(2) : 0;
    let sub = parseFloat(parseFloat(totalOfProducts) + (props.rushOrder ? parseFloat(rushCharges) : 0));

    /* Old Implementation */
    // if (props.creditApplied != 0) {
    //   sub = Math.abs(props.creditApplied - sub);
    // }
    /*  */
    setSubTotal(sub.toFixed(2));
    props.setSubTotal?.(sub.toFixed(2));
    let total = sub + parseFloat(shipCost);
    setTotalAmount(total.toFixed(2));

    /* Old Implementation */
    //Setting Credit
    // if (props.creditUsed && props.creditApplied == 0) {
    //   sub = Math.abs(sub - props.creditRequested);
    //   if (sub != 0) {
    //     let credit = parseFloat(props.credit).toFixed(2);
    //     let creditUsed = props.creditUsed;
    //     if (credit >= sub && creditUsed) {
    //       total = total - sub;
    //       setTotalAmount(parseFloat(total).toFixed(2));
    //       //props.setCreditRequested(parseFloat(sub).toFixed(2));
    //       setSubTotal(0);
    //       props.setSubTotal(0);
    //     } else if (credit < sub && creditUsed) {
    //       sub = parseFloat(parseFloat(parseFloat(sub).toFixed(2) - parseFloat(credit).toFixed(2))).toFixed(2);
    //       setSubTotal(sub);
    //       props.setSubTotal(sub);
    //       total = parseFloat(parseFloat(sub) + parseFloat(shipCost)).toFixed(2);
    //       setTotalAmount(total);
    //       //props.setCreditRequested(credit);
    //     }
    //   }
    // }
    /*  */

    //New Implementation
    if (props.creditRequested !== 0 && props.creditRequested) {
      sub = sub - props.creditRequested;
      setSubTotal(sub.toFixed(2));
      props.setSubTotal?.(sub.toFixed(2));
    }
    total = parseFloat(parseFloat(sub) + parseFloat(shipCost)).toFixed(2);
    setTotalAmount(total);

    //
  }, [
    props.creditRequested,
    props.creditUsed,
    props.creditApplied,
    totalOfProducts,
    props.shippingCost,
    rushCharges,
    shipping,
    props.rushOrder,
    shippingElement?.options[shippingElement.selectedIndex].value,
  ]);

  useEffect(
    () => {
      setTotalOfProducts(
        parseFloat(
          selectedProducts.reduce((tot, product) => tot + product.quantity * product.discountedPrice, 0) *
            (selectedPatients.length ? selectedPatients.length : 1)
        ).toFixed(2)
      );
    },
    [selectedPatients, shippingElement?.options[shippingElement.selectedIndex].value, totalPrice],
    props.shippingCost
  );

  useEffect(() => {
    if (props.rushOrder) {
      const rushOrderCharges = parseFloat(
        parseFloat(RUSH_ORDER_INFO.value) + (parseFloat(RUSH_ORDER_INFO.percentOfOrder) / 100) * totalOfProducts
      ).toFixed(2);
      setRushCharges(rushOrderCharges);
      props.setRushOrderCost(parseFloat(rushOrderCharges));
    } else {
      setRushCharges(0.0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.rushOrder, totalOfProducts]);

  useEffect(
    () => {
      let prodPrice = [];
      selectedProducts?.forEach((prod) => {
        prodPrice.push((prod.quantity * prod.discountedPrice).toFixed(2));
      });
      setTotalPrice(prodPrice);
    },
    [selectedProducts],
    totalPrice.length
  );

  const renderPatientNames = useMemo(() => {
    if (!showPatients) {
      return null;
    } else {
      const patientNames =
        selectedPatients.length < 1 ? (
          <ListItemText className="my-0" primary={"No Patient Selected"} primaryTypographyProps={{ color: "grey" }} />
        ) : (
          selectedPatients?.map((patient) => (
            <ListItem key={patient.id}>
              <ListItemText
                className="my-0"
                style={{ display: "list-item" }}
                primary={patient?.patientName || `${patient?.firstName} ${patient?.lastName}`}
                primaryTypographyProps={{
                  noWrap: true, // this will truncate the text wil ellipses in the end
                }}
              />
            </ListItem>
          ))
        );
      return (
        <>
          <strong className="semiBold">{`Patient ${selectedPatients?.length > 1 ? "Names" : "Name:"}`}</strong>
          <List
            sx={{
              listStyle: "decimal",
            }}
            dense={true}
          >
            {patientNames}
          </List>
        </>
      );
    }
  }, [selectedPatients, showPatients]);

  return (
    <div className={classNames({ "mt-4": showProducts, "mt-5 pt-4": props.isProductsJourney })}>
      {showProducts && <h5 className="medText">Selected Products</h5>}
      <p className={classNames({ "mt-5": showProducts })}>Total Selected Products: {selectedProducts.length}</p>
      <div className="prescriptionRightInfo ">
        {selectedProducts.length < 1 || props.emptyCart ? (
          <p className="p-5 text-muted">No products selected</p>
        ) : (
          <div className="p-3">
            {renderPatientNames}
            <Divider variant="fullWidth" className="mb-3" />
            <div className="text-center">
              <div className="d-flex justify-content-start mb-4">
                <div className="flexJCAC justify-content-between w-100">
                  <p className="m-0 semiBold">Product Name</p>
                  <p className="m-0 semiBold">Price ($)</p>
                </div>
              </div>
              {selectedProducts.map((product, index) => {
                return (
                  <div key={`product` + index} className={`d-flex justify-content-start align-items-start`}>
                    {showProducts && !readOnly && (
                      <>
                        {" "}
                        <button
                          className="btn blueText"
                          style={{ paddingLeft: 0 }}
                          onClick={(e) => {
                            e.preventDefault();

                            props.editProduct(product);
                          }}
                        >
                          <i className="text-muted fas fa-edit"></i>
                        </button>
                        <button
                          className="btn"
                          style={{ paddingLeft: 0 }}
                          onClick={(e) => {
                            e.preventDefault();
                            props.removeProduct(product);
                            props.setCreditRequested(0);
                            if (editBool) props.setDeleteCartCredit?.(true);
                          }}
                        >
                          <i className="text-muted fas fa-times-circle"></i>
                        </button>
                      </>
                    )}
                    <div className="flexJCAC justify-content-between w-100">
                      <p className="mt-1 px-1 text-start">
                        {product?.name?.toUpperCase()}
                        <div className="p-0 " style={{ fontSize: "10px" }}>
                          <span className="semiBold medText ">Unit Quantity: </span>
                          {product?.quantity}
                        </div>
                        <div className="p-0 " style={{ fontSize: "10px" }}>
                          <span className="semiBold medText">Day Supply:</span> {formatDaySupply(product?.daySupply,'fs')}
                        </div>
                        <div className="p-0 " style={{ fontSize: "10px" }}>
                          <span className="semiBold medText">Sig per Med:</span> {product?.sig?.toUpperCase()}
                        </div>
                      </p>
                      <p className="m-0 px-1 semiBold" style={{ whiteSpace: "nowrap" }}>
                        $ {totalPrice[index]}
                      </p>
                    </div>
                  </div>
                );
              })}
              <hr />
              <div className="mb-3 flexJCAC justify-content-between w-100">
                <p className="m-0 semiBold">Total Product Amount</p>
                <p className="m-0 semiBold">$ {totalOfProducts}</p>
              </div>
              {props.rushOrder ? (
                <div className="mb-3 flexJCAC justify-content-between w-100">
                  <p className="m-0 semiBold">Priority Order Fee</p>
                  <p className="m-0 semiBold">$ {rushCharges}</p>
                </div>
              ) : null}
              {props.creditRequested !== 0 && (
                <div className="mb-3 flexJCAC justify-content-between w-100">
                  <p className="m-0 semiBold">Credit Used</p>
                  <p className="m-0 semiBold">
                    {" "}
                    $ {props.creditRequested}
                    {!readOnly && (
                      <span>
                        <button
                          className="mx-2 btn"
                          style={{ paddingLeft: 0 }}
                          onClick={(e) => {
                            e.preventDefault();
                            props.setCreditRequested(0);
                            if (editBool) props.setDeleteCartCredit?.(true);
                          }}
                        >
                          <i className="text-muted fas fa-times-circle"></i>
                        </button>
                      </span>
                    )}
                  </p>
                </div>
              )}
              <div className="mb-3 flexJCAC justify-content-between w-100">
                <p className="m-0 semiBold">Sub Total</p>
                <p className="m-0 semiBold">$ {subTotal}</p>
              </div>

              <div className="mb-3 flexJCAC justify-content-between w-100">
                <p className="m-0 semiBold">Estimated Shipping</p>
                <p className="m-0 semiBold"> {props.shippingCost ? `$ ${props.shippingCost}` : "-"}</p>
              </div>
              <hr />
              <div className="mb-3 flexJCAC justify-content-between w-100">
                <p className="m-0 semiBold">Total Amount</p>
                <p className="m-0 semiBold">$ {totalAmount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CartContainer;
