import React, { useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { Card, CardBody, CardTitle, Col, Container, Row, Alert } from 'reactstrap';
import AdminNavbar from '../../../components/AdminNavbar';
import { Link } from 'react-router-dom';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Spinner from '../../../components/spinner';
import Cookies from 'universal-cookie';
import { USER_ROLES } from 'utils';
import moment from 'moment';
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
export default function AdminProfile(props) {
  const [error, setError] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  const [user, setUser] = useState({});
  const [clinicLocation, setClinicLocation] = useState('');
  const userRole = useSelector(userRoleSelector);
  useEffect(() => {
    axiosConfig
      .get('admin/account/getAdminByAdminId/' + props.match.params.id, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
        },
      })
      .then(async (response) => {
        for (var prop in response.data) {
          if (response.data[prop].length === 0) {
            if (response.data[prop] === response.data.profileImageUrl) {
              response.data[prop] = '';
            } else {
              response.data[prop] = '-';
            }
          }
        }

        await setUser(response.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        setError('An error occurred while trying to get profile information. ' + error?.response?.data?.message);
        setShowSpinner(false);
        errorResponse(error);
      });
  }, []);

  return (
    <React.Fragment>
      <AdminNavbar />
      <div className='page-content'>
        <MetaTags>
          <title>User Profile | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className='p-3 text-muted'>MY PROFILE</h4>
          {showSpinner ? (
            <Spinner />
          ) : (
            <Row>
              <Col xl='4'>
                <Card className='overflow-hidden'>
                  <div className='bg-primary bg-soft text-center py-5'>
                    {!user.profileImageUrl && (
                      <h1>
                        <i className='fas fa-user text-dark'></i>
                      </h1>
                    )}
                    {user.profileImageUrl && <img className='staffProfileImgCircle mb-4' src={user.profileImageUrl} alt='' />}
                    <h2 className='text-dark mt-1'>{user.firstName + ' ' + user.lastName}</h2>
                    <p>{user.staffType}</p>
                    <div className='flexJCAC'>{user.isVerified ? <p className='text-white greenText px-4 py-1'>Verified</p> : <p className='text-white redText px-4 py-1'>Not Verified</p>}</div>

                    {userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.PHARMACIST ? (
                      <Link to={`/edit-profile/` + props.match.params.id} className='pinkButton my-5 mt-3'>
                        Edit Profile
                      </Link>
                    ) : null}
                  </div>
                </Card>
              </Col>
              <Col xl='8'>
                <Card>
                  <CardBody>
                    <CardTitle>Personal Information</CardTitle>
                    <Row className='my-4 px-3'>
                      <Col lg={12} xl={6}>
                        <div className='InfoRow'>
                          <p>First Name</p>
                          <p>{user.firstName}</p>
                        </div>
                        {user.middleName && (
                          <div className='InfoRow'>
                            <p>Middle Name</p>
                            <p>{user.middleName}</p>
                          </div>
                        )}
                        <div className='InfoRow'>
                          <p>Last Name</p>
                          <p>{user.lastName}</p>
                        </div>
                        <div className='InfoRow'>
                          <p>Email</p>
                          <p>{user.email}</p>
                        </div>
                      </Col>
                      <Col lg={12} xl={6}>
                        <div className='InfoRow'>
                          <p>Role</p>
                          <p>{user.role === USER_ROLES.PHARMACIST ? 'Pharmacist' : user.role === USER_ROLES.ADMIN_SUPER_USER ? 'Super User' : user.role === USER_ROLES.RX_TECH ? 'RX Tech' : user.role === USER_ROLES.SALES_REP ? 'Sales Rep' : ''}</p>
                        </div>
                        <div className='InfoRow'>
                          <p>Created</p>
                          <p>{moment(user.created).format('MM/DD/YYYY')}</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          {error ? (
            <Alert color='danger' className='text-center'>
              {error}
            </Alert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
}
