/* eslint-disable react-hooks/exhaustive-deps */
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton } from "@mui/material";
import { AlertMessage } from "components";
import { useCallback, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { BUTTON_COLOR } from "utils";

const SignaturePad = ({ showModal = false, actionText, closeModal, onClickSave, person = "" }) => {
  const sigRef = useRef();
  const [error, setError] = useState("");

  const actionButtonColor = actionText === "Save" ? BUTTON_COLOR.success : BUTTON_COLOR.error;

  const saveDialog = useCallback(e => {
    const isEmptySig = sigRef.current.isEmpty();
    if (!isEmptySig) {
      onClickSave(person, sigRef.current.toDataURL());
      closeModal?.();
    } else {
      setError("Please sign before save.");
    }
  }, []);

  const clearSignature = useCallback(() => {
    sigRef.current.clear();
  }, []);

  const closeDialog = useCallback(() => {
    sigRef.current.clear();
    closeModal?.();
  }, []);

  return (
    <Dialog open={showModal}>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            color: theme => theme.palette.grey[500],
          }}>
          <CloseIcon style={{ fontSize: "20px" }} />
        </IconButton>
      </Box>
      <DialogContent>
        <AlertMessage isError msg={error} onClose={() => setError("")} />
        <div className={"signature-wrapper"}>
          <SignatureCanvas
            canvasProps={{
              className: "signature",
            }}
            ref={sigRef}
          />
        </div>
      </DialogContent>
      <Divider />
      <DialogActions className="justify-content-between">
        <Button variant="contained" color="info" style={{ marginRight: 5 }} onClick={clearSignature}>
          Clear
        </Button>
        <Box>
          <Button variant="contained" className={"mx-2"} color={actionButtonColor} onClick={saveDialog}>
            {actionText}
          </Button>
          <Button variant="outlined" className="muiLightButton mx-2" onClick={closeDialog}>
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SignaturePad;
