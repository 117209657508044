import React, { Component } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Cookies from 'universal-cookie';
import DriverLicenseStatusDD from '../../../components/dropdowns/driverLicenseStatusDD';
import EthnicityDD from '../../../components/dropdowns/ethnicityDD';
import MaritalStatusDD from '../../../components/dropdowns/maritalStatusDD';
import PhysicianDD from '../../../components/dropdowns/physicianDD';
import RaceDD from '../../../components/dropdowns/raceDD';
import SexDD from '../../../components/dropdowns/sexDD';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
class PatientInfo1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profileImageSrc: null,
    };
  }

  async uploadImage(e) {
    const image = e.target.files[0];
    var url = URL.createObjectURL(image);

    const formData = new FormData();

    formData.append('file', image);

    await axiosConfig
      .post('uploads/patientUploadFile', formData, {
        headers: {
          Authorization: 'Bearer ' + new Cookies().get('user_token'),
          'Content-Type': 'multipart/form-data;',
        },
      })
      .then(async (response) => {
        url = response.data.accessUrl;
      })
      .catch((error) => {
        errorResponse(error);
      });
    this.setState({
      profileImageSrc: <img className='staffProfileImgCircle' src={url} alt='' />,
    });
    this.props.setProfileImageUpdated();
    this.props.setProfileImageURL(url);
  }

  async uploadLicense(e) {
    this.props.setDriverLicenseUpdated();
    this.props.setDriverLicenseURL(e.target.files[0]);
  }

  async componentDidMount() {
    await this.setState({
      profileImageSrc: this.props.patient ? <img className='staffProfileImgCircle' src={this.props.patient.patientImageUrl ? this.props.patient.patientImageUrl : null} alt='' /> : null,
    });
  }

  render() {
    var patient = this.props.patient;
    return (
      <div className={`${this.props.step === 1 ? 'd-block' : 'd-none'}`}>
        <Row>
          <div className='mb-4 flexJCAC flex-column'>
            {this.state.profileImageSrc === null ? <img className='staffProfileImgCircle' src={null} alt='' /> : this.state.profileImageSrc}
            {this.state.profileImageSrc && (
              <i
                className='fas fa-trash redText'
                onClick={() => {
                  return this.props.setProfileImageURL(''), this.setState({ profileImageSrc: null });
                }}
              ></i>
            )}
            <button
              className='pinkButton my-4'
              onClick={(e) => {
                e.preventDefault();
                document.getElementById('profilePhoto').click();
              }}
            >
              Upload Image
            </button>
            <Input
              accept='.jpg, .png, .jpeg'
              onChange={(e) => {
                this.uploadImage(e);
              }}
              name='profile_photo'
              className='form-control d-none'
              type='file'
              id='profilePhoto'
            />
          </div>
        </Row>
        <Row>
          <Col md={4}>
            <div className='form-check mb-3'>
              <input
                defaultValue={patient ? patient.isEmployee : ''}
                onChange={(e) => {
                  this.props.isEmployee(e.target.checked);
                }}
                name='isEmployee'
                className='form-check-input'
                type='checkbox'
                id='isEmployee'
              />
              <label className='form-check-label mx-2' htmlFor='isEmployee'>
                Is Employee
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='firstName' className='form-label'>
                First name <span className='requiredStar'>*</span>
              </Label>
              <Input defaultValue={patient ? patient.firstName : ''} name='firstName' className='form-control' placeholder='' type='text' required={this.props.step === 1 ? true : false} />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='middleName' className='form-label'>
                Middle name
              </Label>
              <Input defaultValue={patient ? patient.middleName : ''} name='middleName' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='lastName' className='form-label'>
                Last name <span className='requiredStar'>*</span>
              </Label>
              <Input defaultValue={patient ? patient.lastName : ''} name='lastName' className='form-control' placeholder='' type='text' required={this.props.step === 1 ? true : false} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='guardianName' className='form-label'>
                Guardian's name
              </Label>
              <Input defaultValue={patient ? patient.guardianName : ''} name='guardianName' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='currentOccupation' className='form-label'>
                Occupation
              </Label>
              <Input defaultValue={patient ? patient.currentOccupation : ''} name='currentOccupation' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='ssn' className='form-label'>
                SSN
              </Label>
              <Input defaultValue={patient ? patient.ssn : ''} name='ssn' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='dateOfBirth' className='form-label'>
                Date of Birth <span className='requiredStar'>*</span>
              </Label>
              <input defaultValue={this.props.dateOfBirth ? this.props.dateOfBirth : ''} name='dateOfBirth' className='form-control' type='date' required={this.props.step === 1 ? true : false} />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <PhysicianDD isPatLogin={true} defaultValue={patient ? patient.physicianId : ''} name='physicianId' required={this.props.step === 1 ? true : false} />
          </Col>
          <Col md={4} className='mb-4'>
            <MaritalStatusDD defaultValue={patient ? patient.martialStatus : ''} name='martialStatus' required={false} />
          </Col>
        </Row>

        <Row>
          <Col md={4} className='mb-4'>
            <SexDD defaultValue={patient ? patient.sex : ''} name='sex' required={this.props.step === 1 ? true : false} />
          </Col>
          <Col md={4} className='mb-4'>
            <EthnicityDD defaultValue={patient ? patient.ethnicity : ''} name='ethnicity' required={false} />
          </Col>
          <Col md={4} className='mb-4'>
            <RaceDD defaultValue={patient ? patient.race : ''} name='race' required={false} />
          </Col>
        </Row>
        <Row>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='height' className='form-label'>
                Height
              </Label>
              <Input defaultValue={patient ? patient.height : ''} name='height' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='weight' className='form-label'>
                Weight
              </Label>
              <Input defaultValue={patient ? patient.weight : ''} name='weight' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='currentPlan' className='form-label'>
                Current Plan
              </Label>
              <Input defaultValue={patient ? patient.currentPlan : ''} name='currentPlan' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='driverLicenseId' className='form-label'>
                Driver License ID
              </Label>
              <Input defaultValue={patient ? patient.driverLicenseId : ''} name='driverLicenseId' className='form-control' placeholder='' type='text' />
            </FormGroup>
          </Col>
          <Col md={4} className='mb-4'>
            <DriverLicenseStatusDD defaultValue={patient ? patient.driverLicenseState : ''} name='driverLicenseState' required={false} />
          </Col>
          <Col md={4} className='mb-4'>
            <FormGroup>
              <Label htmlFor='driver_license' className='form-label'>
                Driver License
              </Label>
              <Input
                accept='.jpg, .png, .jpeg'
                onChange={(e) => {
                  this.uploadLicense(e);
                }}
                name='driver_license'
                className='form-control'
                type='file'
                id='driverLicense'
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PatientInfo1;
