import { DialogContext } from "context";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLazyExportClinicPatientsQuery, useUploadPatientFileMutation } from "rtk";

function usePatientAction({ listingName, listingMutation }) {
  const patientIdRef = useRef();
  const [patientId, setPatientId] = useState("");
  const { showDialog, closeDialog } = useContext(DialogContext);

  const [mutateAction, { data: actionData, error: actionError, isSuccess: actionSuccess, isLoading: actionLoading }] =
    listingMutation();

  const [fetchReport, { error: reportError, isFetching: reportFetching }] = useLazyExportClinicPatientsQuery();
  const [uploadFile, { data: uploadData, error: uploadError, isLoading: uploadLoading }] =
    useUploadPatientFileMutation();

  const successMessage = useMemo(
    () => actionData?.message || uploadData?.message || "",
    [actionData?.message, uploadData?.message],
  );
  const mutateLoading = useMemo(
    () => reportFetching || uploadLoading || actionLoading,
    [reportFetching, uploadLoading, actionLoading],
  );
  const mutateError = useMemo(
    () => actionError?.data?.message || reportError?.data?.message || uploadError?.data?.message || "",
    [actionError?.data?.message, reportError?.data?.message, uploadError?.data?.message],
  );

  useEffect(() => {
    if (actionSuccess) {
      setPatientId("");
    }
  }, [actionSuccess]);

  const exportPatientList = useCallback(
    e => {
      e.preventDefault();
      fetchReport();
    },
    [fetchReport],
  );

  const fileUploading = useCallback(
    e => {
      e.preventDefault();
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        uploadFile(formData);
      }
    },
    [uploadFile],
  );

  const patientAction = useCallback(() => {
    closeDialog();
    mutateAction({ patientId: patientIdRef.current ?? "", pageName: listingName });
  }, [closeDialog, listingName, mutateAction]);

  const toggleConfirmationModal = useCallback(
    (id = "") => {
      patientIdRef.current = id;
      if (id) {
        showDialog({
          question: "Are you sure you want to delete this patient?",
          actionText: "Yes",
          cancelText: "No",
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: patientAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [closeDialog, patientAction, showDialog],
  );

  return {
    patientId,
    mutateError,
    mutateLoading,
    successMessage,
    patientAction,
    fileUploading,
    exportPatientList,
    toggleConfirmationModal,
  };
}

export default usePatientAction;
