import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from "reactstrap";
import LoggedOutNavbar from "../../components/loggedOutNavbar";
import Lottie from "../../components/lottie";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Spinner from "../../components/spinner";

const cometChatAuthKey = process.env.REACT_APP_COMET_CHAT_AUTH_KEY;
const TwoFactorAuth = (props) => {
  const [message, setMessage] = useState("");
  const [successAuth, setSuccessAuth] = useState(false);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setMessage(props.authData.message);
  }, []);

  function handleValidSubmit(e) {
    e.preventDefault();
    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("OtpForm"), { hash: true });
    setShowSpinner(true);
    axiosConfig
      .post("accounts/validateOTP", {
        token: props.authData.token,
        otp: obj.otp,
      })
      .then(async (response) => {
        setSuccessAuth(true);
        setShowSpinner(false);
        setError("");
        props.confirmLogin(response.data);
      })
      .catch((error) => {
        setShowSpinner(false);
        setMessage("");
        setError(error.response.data.message);
        errorResponse(error);
      });
  }

  async function resendOTP() {
    setShowSpinner(true);
    await axiosConfig
      .post("accounts/resendOTP", {
        token: props.authData.token,
      })
      .then(async (response) => {
        setError("");
        setMessage(response.data.message);
        setShowSpinner(false);
      })
      .catch((error) => {
        setShowSpinner(false);
        setError(error.response.data.message);
        errorResponse(error);
      });
  }

  return (
    <div>
      <div className="account-pages p-sm-3 p-1 pt-5 mt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                {successAuth ? null : (
                  <div className="p-4 text-center">
                    <h3 className="text-primary m-4 mb-0 text-dark">Enter OTP</h3>
                  </div>
                )}
                {showSpinner ? <Spinner /> : null}
                <CardBody className="pt-0">
                  {successAuth ? null : message ? (
                    <Alert style={{ color: "green" }} className="text-center mb-4">
                      {message}
                    </Alert>
                  ) : null}

                  <div className="p-2">
                    <form id="OtpForm" className="form-horizontal px-sm-5 px-2" onSubmit={(e) => handleValidSubmit(e)}>
                      {error.length > 0 ? (
                        <Alert color="danger" className="text-center mb-4">
                          {error}
                        </Alert>
                      ) : null}
                      {successAuth ? (
                        <div className="m-1 p-1 text-center">
                          <Lottie name="success-check" />
                          <p>Login Successful!</p>
                        </div>
                      ) : (
                        <div className="mb-5 px-4">
                          <div className="mt-3">
                            <FormGroup>
                              <Label htmlFor="otp" className="form-label">
                                Enter OTP Here <span className="requiredStar">*</span>
                              </Label>
                              <Input name="otp" className="form-control" type="text" placeholder="" required />
                              <a
                                className="text-center blueText"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  resendOTP();
                                }}
                              >
                                Resend OTP
                              </a>
                            </FormGroup>
                          </div>
                        </div>
                      )}
                      {successAuth ? null : (
                        <Row className="m-1 px-4">
                          <button className="btn btn-primary darkBg mt-1" type="submit">
                            Submit
                          </button>
                        </Row>
                      )}
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default TwoFactorAuth;
