import { LISTING_NAME } from "utils";
import { AdminApi, ClinicApi, InvoiceApi, PatientApi } from "../../rtkApi";
import { ADMIN_END_POINTS, API_METHODS, CACHE_TAGS, END_POINTS } from "../../rtkConstant";
import { includes } from "lodash";

const ClinicPrescriptionMutation = ClinicApi.injectEndpoints({
  endpoints: builder => ({
    clinicUploadRxSignature: builder.mutation({
      query: fileData => ({
        url: END_POINTS.UPLOAD_RX_SIGNATURE,
        method: API_METHODS.POST,
        credentials: "include",
        body: fileData,
        Headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    cancelClinicPrescription: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: END_POINTS.CLINIC_PRESCRIPTION_CANCEL,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      //Change following flow with Entity Adapter  (remove the delete prescription from listing)
      // https://www.youtube.com/watch?v=4ex5EWM1TpQ&ab_channel=AdinnuBenedict
      async onQueryStarted({ prescriptionId }, { queryFulfilled, getState, dispatch }) {
        try {
          await queryFulfilled;
          const listingEndpoint = "getClinicSubmittedPrescription";
          let queryCache = ClinicApi.util.selectInvalidatedBy(getState(), [
            { type: CACHE_TAGS.PRESCRIPTION, id: prescriptionId },
          ]);
          if (queryCache.length > 1) {
            queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
          } else {
            queryCache = queryCache[0];
          }
          dispatch(
            ClinicApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
              return {
                ...listData,
                prescriptions: listData.prescriptions?.filter(record => record?.id !== prescriptionId),
              };
            }),
          );
        } catch {}
      },
    }),
    deleteClinicPrescription: builder.mutation({
      queryFn: async (apiArgs, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: END_POINTS.CLINIC_PRESCRIPTION_DELETE,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      //Change following flow with Entity Adapter  (remove the delete prescription from listing)
      // https://www.youtube.com/watch?v=4ex5EWM1TpQ&ab_channel=AdinnuBenedict
      async onQueryStarted({ prescriptionId }, { queryFulfilled, getState, dispatch }) {
        try {
          await queryFulfilled;
          const listingEndpoint = "getClinicIncompletePrescription";
          let queryCache = ClinicApi.util.selectInvalidatedBy(getState(), [
            { type: CACHE_TAGS.PRESCRIPTION, id: prescriptionId },
          ]);
          if (queryCache.length > 1) {
            queryCache = queryCache.find(query => query.endpointName === listingEndpoint);
          } else {
            queryCache = queryCache[0];
          }
          dispatch(
            ClinicApi.util.updateQueryData(listingEndpoint, queryCache.originalArgs, listData => {
              return {
                ...listData,
                prescriptions: listData.prescriptions?.filter(record => record?.id !== prescriptionId),
              };
            }),
          );
        } catch {}
      },
    }),
    editClinicPrescription: builder.mutation({
      queryFn: async (
        { isReview = false, reviewCheck = false, prescriptionStatus = "", side = "", ...prescription },
        { dispatch, getState },
        _,
        baseQuery,
      ) => {
        try {
          const user = getState().AuthSlice || {};
          if (user?.userData) {
            let organizedBody = { ...prescription, userId: user?.userData?.id };

            const adminClinicSide = organizedBody?.adminClinicSide;
            if (organizedBody.adminClinicSide) delete organizedBody.adminClinicSide;
            const isClinic = user?.userData?.role?.toLowerCase().includes("clinic") ? true : false;
            const submitted = prescriptionStatus?.toLowerCase() === "submitted";
            const reorderEdit = window?.location?.pathname.includes("edit-reorder-prescription");
            const conditionalUrl =
              !isClinic && !adminClinicSide
                ? ADMIN_END_POINTS.EDIT_PRESCRIPTION
                : (side === "" && !isClinic && adminClinicSide) ||
                  side === LISTING_NAME.CLINIC_PENDING_APPROVAL ||
                  side === "reorderEdit"
                ? END_POINTS.CLINIC_EDIT_PRESCRIPTION
                : side === "incompleteRx"
                ? END_POINTS.CLINIC_EDIT_INCOMPLETE_PRESCRIPTION
                : END_POINTS.CLINIC_EDIT_INCOMPLETE_PRESCRIPTION;
            // : side != "incompleteRx" && !adminClinicSide && isClinic
            // ? END_POINTS.CLINIC_EDIT_INCOMPLETE_PRESCRIPTION
            // : END_POINTS.CLINIC_EDIT_INCOMPLETE_PRESCRIPTION;

            const response = await baseQuery({
              url: conditionalUrl,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            if (response?.data) {
              if (conditionalUrl === ADMIN_END_POINTS.EDIT_PRESCRIPTION) {
                dispatch(
                  AdminApi.util?.invalidateTags?.([
                    { type: CACHE_TAGS.PRESCRIPTION, id: prescription?.prescriptionId },
                  ]),
                );
                dispatch(
                  InvoiceApi.util?.invalidateTags?.([
                    { type: CACHE_TAGS.PRESCRIPTION, id: prescription?.prescriptionId },
                  ]),
                );
              }
              dispatch(
                ClinicApi.util?.invalidateTags?.([{ type: CACHE_TAGS.PRESCRIPTION, id: prescription?.prescriptionId }]),
              );
            }
            return { ...response, data: { ...response?.data, reviewCheck } };
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
    submitClinicPrescription: builder.mutation({
      queryFn: async (
        { isReview = false, prescription = undefined, showClinicView = false, flow = "", side = "" },
        queryApi,
        _,
        baseQuery,
      ) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const reorder =
              window?.location?.pathname.includes("reorder-prescription") || side === LISTING_NAME.PATIENT_SUMMARY;
            let organizedBody = { ...prescription, userId: user?.userData?.id };
            const isClinic = Boolean(user?.userData?.role?.toLowerCase().includes("clinic"));
            const condition = includes(["incompleteRx", "newRx", "productSearch"], side) || !isClinic;
            const response = await baseQuery({
              url: reorder
                ? END_POINTS.SUBMIT_NEW_RX
                : condition
                ? END_POINTS.CLINIC_SAVED_SUBMITTED_PRESCRIPTION
                : END_POINTS.CLINIC_EDIT_PRESCRIPTION,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      async onQueryStarted(apiArgs, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          if (apiArgs.side === LISTING_NAME.CLINIC_PENDING_APPROVAL) {
            dispatch(
              ClinicApi.util.invalidateTags([
                { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescription?.prescriptionId },
              ]),
            );
          } else if (apiArgs.side === LISTING_NAME.PATIENT_SUMMARY) {
            if (apiArgs?.prescription?.prescriptionId) {
              dispatch(PatientApi.util.invalidateTags([{ type: CACHE_TAGS.PATIENT, id: apiArgs?.prescriptionId }]));
              dispatch(ClinicApi.util.invalidateTags([{ type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescriptionId }]));
            } else {
              dispatch(PatientApi.util.invalidateTags([CACHE_TAGS.PATIENT]));
            }
          } else {
            dispatch(
              ClinicApi.util.invalidateTags([
                { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescription?.prescriptionId },
              ]),
            );
            dispatch(
              AdminApi.util.invalidateTags([
                { type: CACHE_TAGS.PRESCRIPTION, id: apiArgs?.prescription?.prescriptionId },
              ]),
            );
          }
        } catch {}
      },
    }),

    saveNewPrescription: builder.mutation({
      queryFn: async (
        { isReview = false, prescription = undefined, showClinicView = false, flow = "", side = "" },
        queryApi,
        _,
        baseQuery,
      ) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const reorder = window?.location?.pathname.includes("reorder-prescription");
            let organizedBody = { ...prescription, userId: user?.userData?.id };
            const isClinic = user?.userData?.role?.toLowerCase().includes("clinic") ? true : false;
            const condition = side === "incompleteRx" || !isClinic || side === "newRx" || side === "productSearch";
            const response = await baseQuery({
              url: END_POINTS.SAVE_NEW_RX,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      invalidatesTags: [CACHE_TAGS.PATIENT_PRESCRIPTION],
    }),
  }),
});

export const {
  useCancelClinicPrescriptionMutation,
  useDeleteClinicPrescriptionMutation,
  useClinicUploadRxSignatureMutation,
  useSubmitClinicPrescriptionMutation,
  useEditClinicPrescriptionMutation,
  useSaveNewPrescriptionMutation,
} = ClinicPrescriptionMutation;
