import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { userInfoSelector, userRoleSelector } from "selectors";
import MetaTags from "react-meta-tags";
import { Card, CardBody, CardTitle, Col, Container, Row, Alert } from "reactstrap";
import AdminNavbar from "../../../components/AdminNavbar";
import { Link } from "react-router-dom";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import PrimaryModal from "../../../components/primaryModal";
import axios from "axios";
import { decrypt } from "cryptofunc";
import { cookies, getClinicStaffList, USER_ROLES } from "utils";
import { filter, first } from "lodash";
import { CreditBalance } from "components/credit-balance";
import { useLazyClinicAllDataQuery } from "rtk/rtkEndpoints/ClinicProfile";
import { useEditClinicMutation } from "rtk/rtkEndpoints/ClinicProfile/ClinicProfileMutation";
import { useDeleteClinicMutation } from "rtk";
import NavBar from "components/NavBar";
import file from "assets/images/file-image.png";
import { useHistory } from "react-router-dom";
const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;

const ClinicProfile = props => {
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [clinic, setClinic] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const isPatientAccess = useRef();
  const staffIdList = useRef();
  const [locationInfo, setLocationInfo] = useState([]);

  const [
    fetchClinicData,
    { data: clinicData, isFetching: isFetching, isError: isClinicDataError, error: clinicDataError },
  ] = useLazyClinicAllDataQuery();
  const [editClinicData, { isLoading: isEditLoading, isError: isEditError, error: editError }] =
    useEditClinicMutation();
  const [deleteClinic, { isLoading: isDeleteLoading, isError: isDeleteError, error: deleteError }] =
    useDeleteClinicMutation();

  const showLoading = useMemo(
    () => isFetching || isDeleteLoading || isEditLoading,
    [isFetching, isDeleteLoading, isEditLoading],
  );
  const showError = useMemo(() => isClinicDataError || isDeleteError, [isClinicDataError, isDeleteError]);
  const errorMessage = useMemo(() => clinicDataError?.data?.message || deleteError, [clinicDataError?.data?.message, deleteError]);

  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);

  const clinicSide = userRole?.toLowerCase().includes("clinic");
  const condition = userRole === USER_ROLES.CLINIC_SUPER_USER || userInfo?.isSuperUserSameAsProvider;
  const [clinicId, setClinicId] = useState(userInfo?.clinicId);
  const history = useHistory();
  const access =
    !clinicSide &&
    (userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.PHARMACIST || userRole === USER_ROLES.RX_TECH);

  //var locationInfo = [];
  useEffect(() => {
    getClinicInfo();
  }, []);

  const getClinicInfo = async () => {
    let arg = {
      params: clinicId ? clinicId : props.match.params.id,
    };
    const response = await fetchClinicData(arg);
    setClinic({
      ...response.data,
      clinicLocationId: first(filter(response.data?.clinicLocations || [], "isPrimaryLocation"))?.id,
    });
    setLocationInfo(response.data.clinicLocations);
    isPatientAccess.current = await response.data.isToGiveAccessToPatients;
  };

  function closeDeleteModal() {
    setDeleteModal(false);
  }

  const handleNetChange = async e => {
    setShowSpinner(true);
    let body = {
      clinicId: clinic.id,
      paymentTermDays: e.target.value === "30" ? 30 : e.target.value === "15" ? 15 : 0,
      isToGiveAccessToPatients: clinic.isToGiveAccessToPatients,
      isToGiveApiAccess: clinic.isToGiveApiAccess,
      isToRunNet30Job: clinic.isToRunNet30Job,
    };
    let arg = {
      payload: body,
    };
    const response = await editClinicData(arg);
    if (response?.data) {
      setClinic(prev => ({
        ...prev,
        ...body,
      }));
    }
  };

  const handleAccessChange = async e => {
    setShowSpinner(true);
    let body = {
      clinicId: clinic.id,
      paymentTermDays: clinic.paymentTermDays,
      isToGiveAccessToPatients: e.target.checked,
      isToGiveApiAccess: clinic.isToGiveApiAccess,
      isToRunNet30Job: clinic.isToRunNet30Job,
    };
    let arg = {
      payload: body,
    };
    const response = await editClinicData(arg);
    if (response?.data) {
      setClinic(prev => ({
        ...prev,
        ...body,
      }));
    }
  };

  const handleAutoKillChange = async e => {
    setShowSpinner(true);
    let body = {
      clinicId: clinic.id,
      isToRunNet30Job: e.target.checked,
      paymentTermDays: clinic.paymentTermDays,
      isToGiveAccessToPatients: clinic.isToGiveAccessToPatients,
      isToGiveApiAccess: clinic.isToGiveApiAccess,
    };
    let arg = {
      payload: body,
    };
    const response = await editClinicData(arg);
    if (response?.data) {
      setClinic(prev => ({
        ...prev,
        ...body,
      }));
    }
  };

  const handleDelete = async () => {
    setDeleteModal(false);
    setShowSpinner(true);
    let arg = {
      clinicId: clinic.id,
      userId: userInfo.id,
    };
    try {
      
      const response = await deleteClinic(arg);
      
      history.push("/clinics");
    }
    catch (error) {
      console.error("Error deleting clinic:", error);
      setShowSpinner(false);
    }

  };

  async function deleteUserFromCometChat(uid) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}`;
    const bodyVal = {
      permanent: true,
    };

    await axios
      .delete(url, {
        headers: {
          apiKey: cometChatApiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },

        bodyVal,
      })
      .then(async response => { })
      .catch(error => { });
  }

  const CheckBoxesUI = () => {
    if (clinicSide)
      return (
        <>
          <p>{clinic.paymentTermDays}</p>
          {clinic.paymentTermDays !== 0 ? clinic.isToGiveAccessToPatients ? <p>Yes</p> : <p>No</p> : null}
          {clinic.isToRunNet30Job ? <p>Yes</p> : <p>No</p>}
        </>
      );
    else {
    }
    return (
      <>
        {access ? (
          <p>
            <select
              id={"iNet30"}
              onChange={e => {
                e.stopPropagation();
                handleNetChange(e);
              }}
              defaultValue={clinic.paymentTermDays}>
              <option value="0">0</option>
              <option value="15">15</option>
              <option value="30">30</option>
            </select>
          </p>
        ) : (
          <p>
            <input type="text" value={clinic.paymentTermDays} />
          </p>
        )}
        {access ? (
          <p>
            <input
              type="checkbox"
              checked={clinic.isToGiveAccessToPatients}
              onChange={e => {
                e.stopPropagation();
                handleAccessChange(e);
              }}
            />
          </p>
        ) : (
          <p>
            <input type="checkbox" checked={clinic.isToGiveAccessToPatients} />
          </p>
        )}

        {clinic.paymentTermDays === 15 || clinic.paymentTermDays === 30 ? (
          access ? (
            <p>
              <input
                type="checkbox"
                checked={clinic.isToRunNet30Job}
                onChange={e => {
                  e.stopPropagation();
                  handleAutoKillChange(e);
                }}
              />
            </p>
          ) : (
            <p>
              <input type="checkbox" checked={clinic.isToRunNet30Job} />
            </p>
          )
        ) : null}
      </>
    );
  };

  return (
    <React.Fragment>
      <NavBar />
      {showLoading ? <Spinner /> : null}
      <div className="page-content">
        <MetaTags>
          <title>Clinic Profile | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          {showError ? (
            <Alert color="danger" className="text-center">
              {errorMessage}
            </Alert>
          ) : null}
          <h4 className="p-3 text-muted">CLINIC PROFILE</h4>
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft text-center py-5">
                  <h1>
                    <i className="fas fa-user text-dark"></i>
                  </h1>
                  <h2 className="text-dark mb-5">{clinic.businessName}</h2>

                  {clinicSide ||
                    userRole === USER_ROLES.ADMIN_SUPER_USER ||
                    userRole === USER_ROLES.PHARMACIST ||
                    userRole === USER_ROLES.RX_TECH ? (
                    <p>
                      <Link to={"/edit-clinic/" + clinic.id} className="w-20 pinkButton">
                        Edit Clinic Info
                      </Link>
                    </p>
                  ) : null}
                  <br></br>
                </div>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Clinic Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col xs={4} sm={3} md={6} className="semiBold">
                      <p>Business Name</p>
                    </Col>
                    <Col xs={4} sm={3} md={6}>
                      <p>{clinic.businessName}</p>
                    </Col>
                    <hr></hr>
                  </Row>
                  <Row className="my-4 px-3">
                    <Col xs={4} sm={3} md={6} className="semiBold">
                      <p>Net </p>
                      <p>Patient Portal Access</p>
                      <p>{"Recurring Payments"}</p>
                    </Col>
                    <Col xs={4} sm={3} md={6}>
                      <CheckBoxesUI />
                      <br></br>
                    </Col>
                    <hr></hr>
                  </Row>
                  <Row className="my-4 px-3">
                    <p className="semiBold">Clinic Location(s) Credit Balance</p>{" "}
                    <table>
                      {clinic.clinicLocations?.map((location, i) => {
                        return (
                          <Row className="my-1 px-2">
                            <tr>
                              <td style={{ width: "150px" }}> {location.locationName + "  "} </td>
                              <td>
                                {clinicSide ? (
                                  <div
                                    class="text-left"
                                    style={{
                                      padding: "5px",
                                      backgroundColor: "white",
                                      width: "120px",
                                      color: "black",
                                    }}>
                                    <p className="m-0">${location.credit}</p>
                                  </div>
                                ) : (
                                  <CreditBalance
                                    clinicLocationId={location.id}
                                    region="clinicProfile"
                                    reload={getClinicInfo}
                                    credit={location.credit}
                                    clinicId={clinic.id}
                                    history={location.creditHistory}
                                  />
                                )}{" "}
                              </td>
                            </tr>
                          </Row>
                        );
                      })}
                    </table>
                  </Row>
                  <hr />

                  {clinic?.bAAFormPDFUrl && (
                    <Row className="my-4 px-3">
                      {" "}
                      <a href={clinic.bAAFormPDFUrl} target="_blank">
                        <img src={file} alt="file" width={60} height={55} style={{ marginTop: "-10px" }} />
                      </a>
                      <p style={{ marginLeft: 10 }}>BAA</p>
                    </Row>
                  )}

                  <p className="text-center">
                    {userRole === USER_ROLES.ADMIN_SUPER_USER ||
                      userRole === USER_ROLES.PHARMACIST ? (
                      <Link
                        to="#"
                        onClick={() => {
                          setDeleteModal(true);
                        }}
                        className="w-30 text-center redButton">
                        Delete Clinic
                      </Link>
                    ) : null}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle>Contact Information</CardTitle>
                  <Row className="my-4 px-3">
                    <Col xs={2} sm={3} md={3} className="semiBold">
                      <p>Email Address</p>
                      <p>Office Phone</p>
                      <p>Address Line 1</p>
                      <p>Address Line 2</p>
                      <p>City</p>
                      <p>State</p>
                      <p>Zip Code</p>
                      <p>Fax</p>
                      {clinic.referredBy && <p>Referred By:</p>}
                    </Col>
                    <Col xs={7} sm={9} md={9}>
                      <p>{clinic.email}</p>
                      <p>{clinic.officePhone}</p>
                      <p>{clinic.addressLine1}</p>
                      <p>{clinic.addressLine2 ? clinic.addressLine2 : "-"}</p>
                      <p>{clinic.city}</p>
                      <p>{clinic.state}</p>
                      <p>{clinic.zipcode}</p>
                      <p>{clinic.fax ?? "-"}</p>
                      {clinic.referredBy && <p style={{ textTransform: "capitalize" }}>{clinic.referredBy}</p>}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Credit Card Information</CardTitle>

                  {locationInfo?.map((location, i) => {
                    var encryptedArray = location?.encryptedCreditCards;
                    var decryptedArr = [];

                    if (location?.encryptedCreditCards) {
                      encryptedArray.forEach(card => {
                        decryptedArr.push(decrypt(card));
                      });
                    }

                    return (
                      <>
                        <p>
                          <strong>Clinic Location Name: </strong> <i>{location.locationName}</i>
                        </p>

                        {decryptedArr?.map((card, i) => {
                          return (
                            <div key={"card" + i}>
                              <p className="semiBold medText mt-4">Credit Card {i + 1}</p>
                              <Row className="my-4 px-3">
                                <Col lg={12} xl={6}>
                                  <div className="InfoRow">
                                    <p>Card Holder Name</p>
                                    <p>{card.cardHolderName}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Holder Contact Number</p>
                                    <p>{card.cardHolderContactNumber}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Type</p>
                                    <p>{card.cardType}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Credit Card Number</p>
                                    <p>
                                      **** **** **** {card.creditCardNumber.substring(card.creditCardNumber.length - 4)}
                                    </p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Expiration Date</p>
                                    <p>{card.cardExpirationDate.replace(/\s/g, "")}</p>
                                  </div>
                                  {/* <div className="InfoRow">
                                    <p>CVV</p>
                                    <p>***</p>
                                  </div> */}
                                  <div className="InfoRow">
                                    <p>Default</p>
                                    <p className={card.isCardDefault ? "greenText" : "redText"}>
                                      {card.isCardDefault ? "Yes" : "No"}
                                    </p>
                                  </div>
                                </Col>
                                <Col lg={12} xl={6}>
                                  <div className="InfoRow">
                                    <p>Card Holder Address Line 1</p>
                                    <p>{card.cardHolderAddressLine1}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Holder Address Line 2</p>
                                    <p>{card.cardHolderAddressLine2 ? card.cardHolderAddressLine2 : "-"}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Holder City</p>
                                    <p>{card.cardHolderCity}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Holder State</p>
                                    <p>{card.cardHolderState}</p>
                                  </div>
                                  <div className="InfoRow">
                                    <p>Card Holder Zipcode</p>
                                    <p>{card.cardHolderZipcode}</p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </CardBody>
              </Card>
              <Row>
                <Col>
                  <Link
                    to={
                      clinicSide
                        ? "/pending-prescriptions"
                        : {
                          pathname: `/patients/${clinic.id}`,
                          state: {
                            isPrescription: true,
                            clinicLocationId: locationInfo.filter(a => a.isPrimaryLocation === true)[0]?.id,
                            clinicId: clinic.id,
                          },
                        }
                    }>
                    <Card>
                      <CardBody className="text-center p-5">
                        <i className="fas fa-file-prescription medText fa-3x mb-3" />
                        <h4>{clinicSide ? "Prescriptions" : "Create RX"}</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col>
                  <Link
                    to={
                      clinicSide
                        ? "/patients"
                        : {
                          pathname: `/patients/${clinic.id}`,
                          state: {
                            clinicId: clinic.id,
                            clinicLocationId: clinic?.clinicLocations?.filter(a => a.isPrimaryLocation === true)[0]
                              ?.id,
                          },
                        }
                    }>
                    <Card>
                      <CardBody className="text-center p-5">
                        <i className="fas fa-users medText fa-3x mb-3" />
                        <h4>Patients</h4>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link
                    to={{
                      pathname: "/clinic-staff",
                      state: {
                        clinicId: clinic.id,
                      },
                    }}>
                    <Card>
                      <CardBody className="text-center p-5">
                        <i className="fas fa-users-cog medText fa-3x mb-3" />
                        <h4>Staff</h4>
                      </CardBody>
                    </Card>
                  </Link>
                  {/* <Link to='#'></Link> */}
                </Col>
                <Col>
                  <Link to={clinicSide ? "/product-search/" : "/clinic-products/" + clinic.id}>
                    <Card>
                      <CardBody className="text-center p-5">
                        <i className="fas fa-pills medText fa-3x mb-3" />
                        <h4>Products</h4>
                      </CardBody>
                    </Card>
                  </Link>
                  {/* <Link to='#'></Link> */}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Link to={clinicSide ? "/clinic-locations/" : "/clinic-locations/" + clinic.id}>
                    <Card>
                      <CardBody className="text-center p-5">
                        <i className="fas fa-map-marker medText fa-3x mb-3" />
                        <h4>Locations</h4>
                      </CardBody>
                    </Card>
                  </Link>
                  {/* <Link to='#'></Link> */}
                </Col>{" "}
                <Col>{/* <Link to='#'></Link> */}</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <PrimaryModal
        question="Are you sure you want to delete this clinic?"
        showModal={deleteModal}
        closeModal={closeDeleteModal}
        onClickYes={handleDelete}
      />
    </React.Fragment>
  );
};

export default ClinicProfile;
