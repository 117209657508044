const { createDraftSafeSelector } = require("@reduxjs/toolkit");
const { IsLoggedSelector } = require("./AuthSelector");

const cartProductSelector = createDraftSafeSelector(
  state => state.ProductCart,
  IsLoggedSelector,
  ({ products }, isLogin) => {
    if (isLogin) {
      return products;
    } else {
      return [];
    }
  },
);

export { cartProductSelector };
