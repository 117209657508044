/* eslint-disable react-hooks/exhaustive-deps */
import { CometChat } from "@cometchat-pro/chat";
import { Grid, Switch } from "@mui/material";
import { DialogContext, PortalContext } from "context";
import { useCallback, useContext, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory, useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { COOKIES_NAME } from "utils/cookieConstant";
import { startIntercom } from "utils/intercom";
import BackgroundWelcome from "../../assets/images/backgroundWelcome.jpg";
import VPICompoundingLogo from "../../assets/vpi-images/vpi-logo-white.png";
import VPICompoundingSmallLogo from "../../assets/vpi-images/vpi-logo.png";
import Spinner from "../../components/spinner";
import { useUserLoginMutation } from "../../rtk";
import { cookies, login } from "../../utils/";
import "./Login.scss";
import TwoFactorAuth from "./twoFactorAuth";
import { userInfoSelector } from "selectors";
import { useSelector } from "react-redux";

const Login = () => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const { rpa } = useParams();
  const userInfo = useSelector(userInfoSelector);
  const { setPortalContext } = useContext(PortalContext);
  const { showDialog, closeDialog } = useContext(DialogContext);
  const history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);
  const [enable2Factor, setEnable2Factor] = useState(false);
  const [authData, setAuthData] = useState("");
  const [patient, setPatient] = useState(false);
  const [loginMutate, { isLoading, error: mutateError, isError, isSuccess, data }] = useUserLoginMutation();
  const cometChatAuthKey = process.env.REACT_APP_COMET_CHAT_AUTH_KEY;
  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    if (cookies.get(COOKIES_NAME.IS_LOGGED) === "true") {
      history.push("/");
    }
    if (rpa === "young") {
      changeToPatient(true);
    }
    setPortalContext(rpa);
  }, [rpa]);

  const loginTechSupport = (firstName, lastName, email) => {
    // startIntercom(firstName + " " + lastName, email);
  };

  const profileNavigate = useCallback(() => {
    history.push("/edit-profile");
    closeDialog();
  }, []);

  useEffect(() => {
    if (isError) {
      setError(mutateError?.data?.message);
    } else if (isSuccess) {
      if (
        userInfo?.clinicId &&
        !(
          data?.sex &&
          data?.dateOfBirth &&
          data?.phoneNumber &&
          data?.addressLine1 &&
          data?.state &&
          data?.city &&
          data?.zipcode
        )
      ) {
        showDialog({
          question:
            "Welcome to the VPI Clinic Portal! Please complete your user profile before proceeding. Thank you for choosing VPI Compounding!",
          actionText: "Go to profile",
          closeAble: true,
          onClickYes: profileNavigate,
        });
      }
      if (!data.isResetPassword) history.push("/");
    }
  }, [data, isError, isSuccess, mutateError, userInfo?.clinicId]);

  const handleValidSubmit = e => {
    e.preventDefault();
    loginMutate({ email: email, password: pass, isPatientLogin: patient });
  };

  function confirmLogin(res) {
    login(
      res.id,
      res.emailAddress,
      res.firstName,
      res.lastName,
      res.isVerified,
      res.role,
      res.jwtToken,
      res.clinicId,
      res.clinicLocationIds,
      res.refreshToken,
    );

    CometChat.login(res.id, cometChatAuthKey).then(
      user => {
        console.log("Login Successful:");
      },
      error => {
        console.log("Login failed with exception:", { error });
      },
    );
    setTimeout(() => {
      setShowSpinner(false);

      history.push("/");
    }, 1000);
  }

  function changeToPatient(flag = false) {
    setPatient(flag);
  }

  function portalChange(e) {
    changeToPatient(e?.target?.checked);
  }
  // function InlineStyledTextField(att) {
  //   const textFieldStyles = {
  //     width: "100%",
  //     borderRadius: "4px",
  //     backgroundColor: "#2C4B76",
  //     color: "success",
  //     "& .MuiInputBase-root": {
  //       borderRadius: "4px",
  //       backgroundColor: "#2C4B76",
  //       color: "white", // Set text color to whit// Keep the background color consistent
  //     },
  //     "& .MuiOutlinedInput-notchedOutline": {
  //       borderColor: "#2C4B76",
  //     },
  //     "& .MuiInputLabel-root": {
  //       color: "#fff !important", // Set label color to white
  //     },
  //   };

  //   return <TextField style={textFieldStyles} {...att} />;
  // }
  // const containerStyles = {
  //   backgroundImage: `url(${LoginBack})`, // Specify the path to your image
  //   backgroundSize: "cover", // Adjust the background size as needed (e.g., 'cover', 'contain', 'auto')
  //   backgroundRepeat: "no-repeat", // Prevent background image from repeating
  //   backgroundPosition: "center center", // Center the background image
  //   backgroundColor: "rgba(0, 0, 0, 0.2)", // Adjust the opacity by changing the last value (0.2 in this example)
  //   padding: "16px", // Add padding to the container as needed
  // };
  function togglePasswordVisibility(val) {
    setShowPass(true);
    var passwordField = document.getElementById("password");
    var showEye = document.getElementById("show-password");
    var hideEye = document.getElementById("hide-password");
    if (passwordField.type === "password") {
      passwordField.type = "text";
      setShowPass(true);
    } else {
      passwordField.type = "password";
      setShowPass(false);
    }
  }

  return (
    <div>
      <MetaTags>
        <title>Login | VPI Compounding</title>
      </MetaTags>
      {/* {rpa === "young" ? null : <LoggedOutNavbar />} */}
      {showSpinner || isLoading ? <Spinner /> : null}

      {enable2Factor ? (
        <TwoFactorAuth confirmLogin={confirmLogin} authData={authData} />
      ) : (
        <>
          <Grid
            container
            style={{
              backgroundColor: "#2C4B76",
            }}>
            <Grid
              className="left-grid"
              id="leftGrid"
              xs={12}
              sm={12}
              lg={8}
              md={8}
              style={{
                backgroundColor: "#2C4B76",
                backgroundImage: `linear-gradient(rgba(58, 97, 137, 1), rgba(1, 26, 48, 0.8)), url(${BackgroundWelcome})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "100vh",
                alignItems: "center",
                justifyContent: "space-around",
                display: "flex",
              }}>
              <div className="left-side-content">
                <img className="logo-img" src={VPICompoundingLogo} draggable="false" />
              </div>
            </Grid>
            <Grid
              className="right-grid"
              xs={12}
              sm={12}
              lg={4}
              md={4}
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8)), url(${BackgroundWelcome})`,
              }}>
              {" "}
              <div className="p-2 mt-2" style={{ width: "100%" }}>
                <form id="loginForm" className="form-horizontal px-sm-5 px-3" onSubmit={handleValidSubmit}>
                  {error?.length > 0 ? <Alert color="danger">{error}</Alert> : null}
                  <div className="text-center">
                    <div className="mobileLogo">
                      <img
                        src={VPICompoundingSmallLogo}
                        width="200"
                        height="59"
                        className="mb-3"
                        style={{ display: "none" }}
                      />
                    </div>

                    <div>
                      <p style={{ color: "black", fontSize: "20px", textAlign: "left" }}>
                        <strong>Sign in</strong> | {patient ? "Patient Portal" : "Portal"}
                      </p>
                      <p style={{ textAlign: "left" }}>
                        Welcome to Valescent Pharmaceuticals, Inc. Compounding {patient ? "Patient" : ""} Portal.
                      </p>
                    </div>
                  </div>
                  <input
                    className="form-control p-3 login-input"
                    name="email"
                    label="Email"
                    fullWidth
                    variant="filled"
                    placeholder="Email"
                    value={email}
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                    margin="normal"
                    type="email"
                    autoComplete="off"
                    required
                  />
                  <br></br>

                  <div class="password-wrapper">
                    <input
                      id="password"
                      className="form-control p-3 login-input"
                      label="Password:"
                      placeholder="Password"
                      variant="outlined"
                      onChange={e => {
                        setPass(e.target.value);
                      }}
                      value={pass}
                      margin="normal"
                      name="password"
                      type="password"
                      autoComplete="off"
                      required
                    />
                    <span id="show-password" class="password-toggle" onClick={() => togglePasswordVisibility("show")}>
                      {showPass ? <i className="fas fa-eye-slash" /> : <i className="fas fa-eye" />}
                    </span>
                  </div>

                  <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Grid sm={6} lg={6} md={6}>
                      {" "}
                      {rpa !== "young" && (
                        <div className="mt-4" style={{ float: "left" }}>
                          <Switch
                            onChange={e => {
                              portalChange(e);
                            }}
                            checked={patient}
                            name="patientPortalCheck"
                            type="checkbox"
                            id="patientPortalCheck"
                          />

                          <label className="form-check-label  forgot-Password" htmlFor="patientPortalCheck">
                            Patient Portal
                          </label>
                        </div>
                      )}
                    </Grid>
                    <Grid sm={6} lg={6} md={6}>
                      {" "}
                      <div className="mt-4" style={{ float: "right", paddingRight: "5px" }}>
                        <Link
                          to={{
                            pathname: "/forgot-password",
                            state: { isPatient: patient, hideHeader: rpa === "young" },
                          }}
                          className="text-muted forgot-Password">
                          <i className="mdi mdi-lock me-1" /> Forgot your password?
                        </Link>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="mt-4 d-grid">
                    <button className="btn btn-primary loginBtn p-3" type="submit">
                      <strong>Log in </strong>
                    </button>
                  </div>

                  <div id="lowerLoginDetails" className="mt-3 text-center" style={{ height: "50px" }}>
                    {!patient && (
                      <div>
                        New Here?
                        <span>
                          <Link
                            to={{
                              pathname: "/register-clinic",
                              state: { isRegen: rpa === "rpa" },
                            }}
                            className="text-muted p-2 blueText registerLink">
                            Register New Clinic
                          </Link>
                        </span>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>

          {/* <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </>
      )}
    </div>
  );
};

export default Login;
