import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Row, Input } from 'reactstrap';
import Cookies from 'universal-cookie';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';

const ShippingPrefDD = (props) => {
  const [shippingMethods, setShippingMethods] = useState([]);

  useEffect(() => {
    axiosConfig
      .get('portal/getShippingMethods', {
        headers: { Authorization: 'Bearer ' + new Cookies().get('user_token') },
      })
      .then(async (response) => {
        var values = [];

        await response.data.forEach((method) => {
          if (props.defaultValue === 'Ground (1-5 business days)') {
            values.push(
              <option selected='GROUND_HOME_DELIVERY' key={method} value={method}>
                {method}
              </option>
            );
          } else {
            values.push(
              <option selected={props.defaultValue === method || (document.getElementsByName('POBox')[0].checked && method === 'USPS 1-Day (PO BOX ONLY)') ? true : false} key={method} value={method}>
                {method}
              </option>
            );
          }
        });

        setShippingMethods(values);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, [props.defaultValue]);

  return (
    <FormGroup className='mb-4'>
      <Label className='normalText' for={props.name}>
        Shipping Preference {props.required ? <span className='requiredStar'>*</span> : ''}
      </Label>

      <p className='dropdownArrow'>
        <i className='fas fa-angle-down'></i>
      </p>
      <Input
        style={{ paddingRight: '20px' }}
        id={props.name}
        type='select'
        name={props.name}
        required={props.required}
        onChange={(e) => {
          if (e.target.options[e.target.selectedIndex].text !== 'USPS 1-Day (PO BOX ONLY)') {
            document.getElementsByName('POBox')[0].checked = false;
          }
        }}
      >
        {!shippingMethods.length && (
          <option value='' disabled selected>
            {shippingMethods.length ? 'Select' : 'Loading ...'}
          </option>
        )}
        {!!shippingMethods.length && shippingMethods}
      </Input>
    </FormGroup>
  );
};

export default ShippingPrefDD;
