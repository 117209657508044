/* eslint-disable react-hooks/exhaustive-deps */
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, RenderDiscountInput } from "components";
import PriceGroupDD from "components/dropdowns/priceGroupDD";
import Spinner from "components/spinner";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AlertMessage from "screens/clinic/Prescriptions/components/alert_message";
import { userRoleSelector } from "selectors";
import { CLINIC_PRODUCT_GROUP_COLUMNS, ROWS_LIMIT, USER_ROLES, columnHelper } from "utils";
import AdminNavbar from "../../../../components/AdminNavbar";
import useClinicProductList from "./Hook/useClinicProducts";

function ClinicProductList() {
  const history = useHistory();
  const userRole = useSelector(userRoleSelector);
  const {
    productListing,
    productClinic,
    errorMessage,
    successMessage,
    searchKeyword,
    tablePage,
    rowLimit,
    showLoading,
    totalRecords,
    changePriceGroup,
    changeRowLimit,
    handleChangePage,
    refetchList,
    applyFilter,
    clearFilter,
    updateKeyword,
    setCustomError,
  } = useClinicProductList();

  const columnAction = useMemo(
    () =>
      columnHelper.accessor("discountedPrice", {
        header: "Discount price",
        cell: props => {
          const dataRow = props.row.original;
          return (
            <RenderDiscountInput
              clinicId={productClinic?.id}
              productId={dataRow?.id}
              setCustomError={setCustomError}
              discountedPrice={dataRow?.discountedPrice}
            />
          );
        },
      }),
    [productClinic?.id],
  );

  const reactTable = useReactTable({
    data: productListing,
    columns: [...CLINIC_PRODUCT_GROUP_COLUMNS, columnAction],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <AdminNavbar />
      <div className="page-content">
        <Container maxWidth="false">
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <h4 className="p-3 text-muted" style={{ textTransform: "capitalize" }}>{`${
                (productClinic?.referredBy ? productClinic?.referredBy + "-" : "") + productClinic?.businessName || ""
              } Products`}</h4>
            </Grid>
            <Grid item>
              {userRole === USER_ROLES.ADMIN_SUPER_USER ? (
                <div>
                  <PriceGroupDD
                    defaultValue={productClinic?.priceGroup || ""}
                    clinicId={productClinic?.id}
                    changePriceGroup={changePriceGroup}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
          {showLoading && <Spinner />}
          <AlertMessage msg={successMessage} />
          <AlertMessage msg={errorMessage} isError={true} />
          <Paper className="p-3 mt-5" elevation={1}>
            <ListingSearch
              searchKeyword={searchKeyword}
              applySearch={applyFilter}
              clearFilter={clearFilter}
              updateKeyword={updateKeyword}
            />
            <Grid container justifyContent={"flex-end"} className="mb-2" style={{ marginRight: 15 }}>
              <Tooltip title="Refresh">
                <Button variant="contained" onClick={refetchList}>
                  <RefreshIcon style={{ width: 20, height: 20 }} />
                </Button>
              </Tooltip>
            </Grid>
            <TableContainer style={{ maxHeight: "70vh" }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  {reactTable.getHeaderGroups().map(headerGroup => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map(header => {
                        return (
                          <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 100 }}>
                            <Typography variant="tableHeader" noWrap>
                              {header.isPlaceholder
                                ? null
                                : flexRender(header.column.columnDef.header, header.getContext())}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {reactTable.getRowModel().rows.map(row => (
                    <TableRow
                      key={`${row.id}`}
                      style={{ backgroundColor: row.original.is_price_modified ? "#f3e1e0" : "" }}>
                      {row.getVisibleCells?.().map(cell => {
                        return (
                          <TableCell key={cell.id} style={cell.column.columnDef.headerStyle}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {productListing === 0 && !showLoading ? (
                <p className="w-100 text-center m-5">No clinic product found!</p>
              ) : null}
            </TableContainer>
            <TablePagination
              component="div"
              showFirstButton
              showLastButton
              page={tablePage}
              count={totalRecords}
              rowsPerPage={rowLimit}
              rowsPerPageOptions={ROWS_LIMIT}
              onPageChange={handleChangePage}
              onRowsPerPageChange={changeRowLimit}
              style={{ alignItems: "center", marginBottom: 0 }}
            />
          </Paper>
        </Container>
      </div>
    </>
  );
}

export default ClinicProductList;
