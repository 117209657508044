import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';

const DisplayModal = (props) => {
  const { events, categories } = props;
  const [updatedCategories, setUpdatedCategories] = useState(categories);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalcategory, setModalcategory] = useState(false);
  const [event, setEvent] = useState({});
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    props.closeModal();
  };

  const toggleCategory = () => {
    setModalcategory(!modalcategory);
  };

  /**
   * Handling date click on calendar
   */
  const handleDateClick = (arg) => {
    setSelectedDay(arg);
    toggle();
  };

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg) => {};

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e, values) => {};

  const handleValidEventSubmitcategory = (event, values) => {};

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {};

  /**
   * On category darg event
   */
  const onDrag = (event) => {};

  /**
   * On calendar drop event
   */
  const onDrop = (event) => {};
  useEffect(() => {});

  return (
    <React.Fragment className='mt-4'>
      {props.showModal ? (
        <Modal isOpen={props.showModal} scrollable={true} size='sm' className='mt-4' centered>
          <ModalHeader>
            <h3>{props.nameofproduct}</h3>
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col className='col-12 mb-3'>
                <h3>{props.displayModalData[0].title}</h3>
                <p>{props.displayModalData[0].text}</p>
              </Col>
              <Col className='col-12 mb-3'></Col>
            </Row>
            <Row>
              <Col align='center'>
                <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                  Close
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default DisplayModal;
