import React, { useEffect, useState, useRef } from "react";
import { Modal } from "reactstrap";
import Cookies from "universal-cookie";
import axios from "axios";
import { Card, CardBody, CardTitle, Col, Container, Row, Alert, Label, Input, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { USER_ROLES } from "../utils";
import ShippingMethodDD from "./dropdowns/shippingMethodDD";
import RushOrderDD from "./dropdowns/rushOrderDD";
import StateDD from "./dropdowns/stateDD";
import Lottie from "../components/lottie";
import axiosConfig, { errorResponse } from "../utils/axiosConfig";
import Spinner from "../components/spinner";

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;
export default function ShowRefillModal(props) {
  const [clinic, setClinic] = useState({});
  const [complete, setComplete] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [shippingMethod, setShippingMethod] = useState(true);
  const [isDisapprove, setDisapprove] = useState(false);
  const [isOrder, setOrder] = useState(false);
  const adminList = useRef([]);
  const [error, setError] = useState("");

  const [patient, setPatient] = useState("");
  const [rushOrder, setRushOrder] = useState(false);
  const [rushMethod, setRushOrderMethod] = useState("");
  const [signatureRequired, setSignatureRequired] = useState(false);

  useEffect(() => {
    axiosConfig
      .get(
        "patientPortal/getPatientProfileInfo",

        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        setPatient(response.data);
        setShowSpinner(false);
      })
      .catch((error) => {
        setError("An error occurred while trying to fetch patient profile. " + error?.response?.data?.message);

        setShowSpinner(false);
        errorResponse(error);
      });
  }, [props.productId]);

  function handleClick() {}

  async function handleValidSubmit(e) {
    e.preventDefault();
    setShowSpinner(false);
    setError("Refill is disabled by admin. Please contact admin.");
    // var serialize = require("form-serialize");
    // var form = document.getElementById("refillForm");
    // var obj = serialize(form, {
    //   hash: true,
    //   empty: true,
    // });

    // var body = {
    //   products: [
    //     {
    //       productId: props.productId,
    //       name: props.productName,
    //       family: props.productFamily,
    //     },
    //   ],
    //   shippingInfo: {
    //     shipTo: "patient",
    //     isNewAddressUsed: true,
    //     shippingAddress: {
    //       addressLine1: obj.shippingAddressLine1,
    //       addressLine2: obj.shippingAddressLine2,
    //       state: obj.shippingState,
    //       city: obj.shippingCity,
    //       zipcode: obj.shippingZipcode,
    //       residential: obj.shippingPreference === "GROUND_HOME_DELIVERY" ? true : false,
    //     },
    //     shippingMethod: obj.shippingPreference,
    //     isPoBox: obj.POBox ? true : false,
    //     orderNotes: obj.orderNotes,
    //     isSignatureRequired: obj.signatureRequired ? true : false,
    //     rushOrderMethod: rushMethod,
    //     isRushOrder: rushOrder,
    //   },
    // };
    // if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(obj.shippingZipcode)) {
    //   setError("Invalid Contact Address Zip Code");
    //   setShowSpinner(false);
    // } else {
    //   axiosConfig
    //     .post(
    //       "patientPortal/refillMedicine",
    //       body,

    //       {
    //         headers: {
    //           Authorization: "Bearer " + new Cookies().get("user_token"),
    //         },
    //       }
    //     )
    //     .then(async (response) => {
    //       setComplete(true);
    //       setOrder(true);
    //       setError("");
    //       setShowSpinner(false);
    //     })
    //     .catch((error) => {
    //       setError("An error occurred while trying to fetch patient profile. " + error?.response?.data?.message);
    //       setComplete(false);
    //       setOrder(false);
    //       setShowSpinner(false);
    //       errorResponse(error);
    //     });
    // }
  }

  function handleZipcodeInputKeyDown() {}

  function setRushMethod(method) {
    setRushOrderMethod(method);
  }
  function handleClick() {
    props.closeModal();
    setComplete(false);
    setError("");
  }
  return (
    <Modal isOpen={props.showRefillModal} size="lg" centered={true} className="largeHeightmodal pt-3">
      {showSpinner ? <Spinner /> : null}

      <button type="button" onClick={handleClick} className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body pb-1">
        {error ? (
          <Alert color="danger" className="text-center">
            {error}
          </Alert>
        ) : null}
        <h4 className="text-center semiBold blueText">Confirm Shipping Information</h4>
        {complete ? (
          isOrder ? (
            <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
              <Lottie name="success-check" />
              <p className="my-4">Order Placed Successfully!</p>
            </div>
          ) : (
            <div className="m-sm-5 m-4 p-sm-5 p-4 text-center">
              <Lottie name="success-check" />
              <p className="my-4">No Refill Left</p>
            </div>
          )
        ) : (
          <>
            <form id="refillForm" className="form-horizontal px-sm-4 px-0" onSubmit={(e) => handleValidSubmit(e)}>
              <Row>
                <Col md={6}>
                  <div className="mb-4" key={patient}>
                    <Label htmlFor="shippingAddressLine1" className="form-label">
                      Shipping Address line 1 <span className="requiredStar">*</span>
                    </Label>
                    <Input
                      maxLength={50}
                      defaultValue={patient.shippingAddressLine1}
                      name="shippingAddressLine1"
                      className="form-control"
                      type="textarea"
                      required={props.step === 3 ? true : false}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Label htmlFor="shippingAddressLine2" className="form-label">
                      Shipping Address line 2
                    </Label>
                    <Input
                      maxLength={50}
                      defaultValue={patient.shippingAddressLine2}
                      name="shippingAddressLine2"
                      className="form-control"
                      type="textarea"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-4">
                    <Label htmlFor="shippingCity" className="form-label">
                      City <span className="requiredStar">*</span>
                    </Label>
                    <Input
                      defaultValue={patient.shippingCity}
                      name="shippingCity"
                      className="form-control"
                      type="text"
                      required={props.step === 3 ? true : false}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <StateDD defaultValue={patient.shippingState} name="shippingState" required={props.step === 3} />
                </Col>
                <Col md={4}>
                  <div className="mb-4">
                    <Label htmlFor="shippingZipcode" className="form-label">
                      Zip code <span className="requiredStar">*</span>
                    </Label>

                    <Input
                      defaultValue={patient.shippingZipcode}
                      name="shippingZipcode"
                      className="form-control"
                      required={props.step === 3 ? true : false}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <ShippingMethodDD
                    setShippingMethod={setShippingMethod}
                    rushOrder={rushOrder}
                    defaultValue={patient.shippingPreference}
                    name="shippingPreference"
                  />
                </Col>
                <Col md={4}>
                  <div className="mt-4">
                    <input
                      defaultValue={patient ? patient.isPox : ""}
                      onChange={(e) => {
                        if (e.target.checked) {
                          for (let i = 0; i < document.getElementsByName("shippingPreference")[0].options.length; i++) {
                            if (
                              document.getElementsByName("shippingPreference")[0].options[i].innerText ===
                              "USPS 1-Day (PO BOX ONLY)"
                            ) {
                              document.getElementsByName("shippingPreference")[0].selectedIndex = i;
                            }
                          }
                        }
                      }}
                      name="POBox"
                      className="form-check-input"
                      type="checkbox"
                      id="POBox"
                    />
                    <label className="form-check-label mx-2" htmlFor="POBox">
                      PO Box
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4 w-75">
                <FormGroup>
                  <Label htmlFor="orderNotes" className="form-label">
                    Order Notes
                  </Label>
                  <Input name="orderNotes" className="form-control" placeholder="" type="textarea" />
                </FormGroup>
              </Row>
              <Row className="mb-4 w-75">
                <Col md={6}>
                  <div className="pt-2">
                    <input
                      onChange={(e) => {
                        setSignatureRequired(e.target.checked);
                      }}
                      name="signatureRequired"
                      className="form-check-input"
                      type="checkbox"
                      id="signatureRequired"
                    />
                    <label className="form-check-label mx-2" htmlFor="signatureRequired">
                      Signature Required
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mb-4 w-75">
                <Col md={6}>
                  <div className="pt-2">
                    <input
                      onChange={(e) => {
                        setRushOrder(e.target.checked);
                      }}
                      name="isRushOrder"
                      className="form-check-input"
                      type="checkbox"
                      id="isRushOrder"
                    />
                    <label className="form-check-label mx-2" htmlFor="isRushOrder">
                      Priority Order Fee
                    </label>
                  </div>
                </Col>
              </Row>
              <Row className="mb-3 w-75">
                {rushOrder ? <RushOrderDD setRushOrderMethod={setRushMethod} name="rushOrderMethod" /> : null}
              </Row>
              <center>
                <Row className="form-group text-center pb-3 ">
                  <Col lg={2} md={1} sm={2}></Col>
                  <Col lg={8} md={8} sm={8}>
                    <button id="refillButton" className="pinkButton w-50" type="submit">
                      Submit
                    </button>
                  </Col>
                </Row>
              </center>
            </form>
          </>
        )}
      </div>
    </Modal>
  );
}
