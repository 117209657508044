import React from 'react'
import { useState } from 'react';
import MetaTags from "react-meta-tags";
import file from "../../../assets/images/bg-auth-overlay.png";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import NavBar from "components/NavBar";
import data from "../../../assets/states/states.json"
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Cookies from "universal-cookie";
import { userRoleSelector } from "selectors";
import { useEffect } from 'react';
import AlertMessage from '../Prescriptions/components/alert_message';
import { useSelector } from "react-redux";
import { USER_ROLES } from "utils";
import { useLazyStateInfoQuery, useUpdateStateInfoMutation } from 'rtk';
import { useCallback } from "react";

const States = () => {
  const userRole = useSelector(userRoleSelector);
  const [stateInfo, { isFetching: reviewFetching, error: reviewError, data: stateData }] =useLazyStateInfoQuery();
  const [stateMutate, { data: uploadedData, isLoading: isUploading, error: uploadError }] =
  useUpdateStateInfoMutation();
  const clinicSide = userRole?.toLowerCase().includes("clinic");
  const rowsPerColumn = 10;

  // Create an array of arrays, each representing a column
  const columns = Array.from({ length: Math.ceil(data.length / rowsPerColumn) }, (_, index) =>
    data.slice(index * rowsPerColumn, (index + 1) * rowsPerColumn)
  );

  const [responseStatus, setResponseStatus] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (!reviewFetching && !reviewError && stateData) {
    setData();
    }
  }, [stateData,reviewFetching]);



  useEffect(() => {
    // if (!reviewFetching && !reviewError && stateData) {
      stateInfo();
  }, []);

  const setData=()=>{
    const dataList = stateData?.data[0]?.states.map((state) => ({
      booleanCheck: state?.booleanCheck,
      name: state?.name,
    }));
    setResponseStatus(dataList);
    console.log("Response", responseStatus)
  }
  // console.log("Response", responseStatus);
  // console.log("rtk api data",stateData?.data[0]?.states);

  const handleCheckboxChange = (name) => {
    setResponseStatus((prevStates) =>
      prevStates.map((state) =>
        state.name === name ? { ...state, booleanCheck: !state.booleanCheck } : state
      )
    );
    // console.log("new array",statesWithStatus)
  };

  const preparePayload = () => {
    return responseStatus.map((state) => ({
      booleanCheck: state.booleanCheck,
      name: state.name,
    }));
  };

  const updateState = async () => {
    let body = preparePayload();
    let statesPayload = { states: body };
    let arg = {
      payload: statesPayload,
    };
    const response = await stateMutate(arg);
    if(response){
      setUpdate(true);
    }
 
  };

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>States | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">STATES</h4>
          {update && <AlertMessage msg="States Updated Successfully" />}

          <Card className="cardWithBackground">
            <Row>
              <Col md="1"></Col>
              {columns.map((column, columnIndex) => (
                <Col xs="12" md="2" key={columnIndex} style={{ textAlign: 'left' }}>
                  <CardBody>
                    {column.map((item) => (
                      <div key={item.id} style={{ fontWeight: 'bold' }}>
                        <input
                          // checked={responseStatus.find((state) => state.name === item.name)?.booleanCheck}
                          checked={
                            responseStatus.find((state) => state.name === item.name)?.booleanCheck || false
                          }
                          onChange={() => handleCheckboxChange(item.name)}
                          style={{ marginRight: '10px' }}
                          name={item.name}
                          className="form-check-input"
                          type="checkbox"
                          id={item.id}
                          disabled={
                            userRole === USER_ROLES.PHARMACIST || userRole === USER_ROLES.RX_TECH || userRole === USER_ROLES.SALES_REP
                          }
                        />
                        {item.name}
                        <br /><br />
                      </div>

                    ))}
                  </CardBody>
                </Col>
              ))}
            </Row>
          </Card>
          <Row className="text-center">
            {userRole === USER_ROLES.ADMIN_SUPER_USER ?
              <button
                className="btn btn-primary darkBg mt-2 mx-auto"
                style={{ width: "20%" }}
                type="submit"
                onClick={updateState}
              >
                Update
              </button>
              : null}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default States