import { DashboardApi } from "../../rtkApi";
import { API_METHODS, END_POINTS,ADMIN_END_POINTS } from "../../rtkConstant";
const DashboardMutation = DashboardApi.injectEndpoints({
  endpoints: (builder) => ({
    updateStateInfo: builder.mutation({
      query: (arg) => ({
        url: ADMIN_END_POINTS.UPDATE_STATE_INFO,
        method: API_METHODS.POST,
        body: arg.payload,
      }),
    }),
  }),
});
export const { useUpdateStateInfoMutation } = DashboardMutation;