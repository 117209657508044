import React, { Component } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../../../components/dropdowns/stateDD";

class PatientInfo2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      moreAddresses: "",
      moreAddressCount: 1,
      addresses: "",
    };
  }

  async addAnotherAddress(index) {
    await this.setState({
      moreAddressCount: this.state.moreAddressCount + 1,
      moreAddresses: [
        ...this.state.moreAddresses,
        <div id={`moreAddresses-` + index} key={index}>
          <hr className="my-5" />
          <h4 className="semiBold medText my-3">Address {index + 1}</h4>
          <Row className="d-flex justify-content-end">
            <button
              type="button"
              className="btn blueButton redBackground"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  moreAddressCount: this.state.moreAddressCount - 1,
                  moreAddresses: this.state.moreAddresses.filter(el => el.key !== index.toString()),
                });
              }}>
              Cancel
            </button>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <div className="mb-4">
                <Label htmlFor={`addresses[` + index + `]['addressLine1']`} className="form-label">
                  Address line 1 <span className="requiredStar">*</span>
                </Label>
                <Input
                  name={`addresses[` + index + `]['addressLine1']`}
                  className="form-control"
                  type="textarea"
                  required={this.props.step === 2 ? true : false}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Label htmlFor={`addresses[` + index + `]['addressLine2']`} className="form-label">
                  Address line 2
                </Label>
                <Input name={`addresses[` + index + `]['addressLine2']`} className="form-control" type="textarea" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="mb-4">
                <Label htmlFor={`addresses[` + index + `]['city']`} className="form-label">
                  City <span className="requiredStar">*</span>
                </Label>
                <Input
                  name={`addresses[` + index + `]['city']`}
                  className="form-control"
                  type="text"
                  required={this.props.step === 2 ? true : false}
                />
              </div>
            </Col>
            <Col md={4}>
              <StateDD name={`addresses[` + index + `]['state']`} required={this.props.step === 2} />
            </Col>
            <Col md={4}>
              <div className="mb-4">
                <Label htmlFor={`addresses[` + index + `]['zipcode']`} className="form-label">
                  Zip code <span className="requiredStar">*</span>
                </Label>
                <input
                  pattern="^\s*?\d{5}(?:[-\s]\d{4})?\s*?$"
                  name={`addresses[` + index + `]['zipcode']`}
                  className="form-control"
                  onKeyDown={e => this.handleZipcodeInputKeyDown(e)}
                  required={this.props.step === 2 ? true : false}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="default_patient_address"
                  id="default_patient_address"
                  value={index}
                />
                <label className="form-check-label" htmlFor="default_patient_address">
                  Default Address
                </label>
              </div>
            </Col>
          </Row>
        </div>,
      ],
    });
  }

  handleZipcodeInputKeyDown(e) {
    // this is called as soon as we get input from the user
    // we don't want to allow them the access to input past 5 digits
    // so we block on keyDown
    // (it never gets to keyUp, which actually updates the state)
    // restrict to 5 digits only

    var key = e.which ? e.which : e.keyCode;
    if (
      (e.target.value.length >= 5 && key !== 8 && key !== 37 && key !== 38 && key !== 39 && key !== 40) ||
      key === 18 ||
      key === 189 ||
      key === 229
    ) {
      e.preventDefault();
    }
  }

  componentDidMount() {
    if (this.props.patient && this.props.patient.addresses.length > 0) {
      this.setState({
        moreAddressCount: this.props.patient.addresses.length,
        addresses: this.props.patient.addresses.map((address, i) => {
          return (
            <div key={i}>
              <hr className="my-5" />
              <h4 className="semiBold medText my-3">Address {i + 1}</h4>
              {i !== 0 ? (
                <Row className="d-flex justify-content-end my-5">
                  <button
                    type="button"
                    className="btn blueButton redBackground"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({
                        addresses: this.state.addresses.filter(el => el.key !== i.toString()),
                      });
                    }}>
                    Cancel
                  </button>
                </Row>
              ) : null}
              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <Label htmlFor={`addresses[` + i + `]['addressLine1']`} className="form-label">
                      Address line 1 <span className="requiredStar">*</span>
                    </Label>
                    <Input
                      defaultValue={address.addressLine1}
                      name={`addresses[` + i + `]['addressLine1']`}
                      className="form-control"
                      type="textarea"
                      required={this.props.step === 2 ? true : false}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Label htmlFor={`addresses[` + i + `]['addressLine2']`} className="form-label">
                      Address line 2
                    </Label>
                    <Input
                      defaultValue={address.addressLine2}
                      name={`addresses[` + i + `]['addressLine2']`}
                      className="form-control"
                      type="textarea"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <div className="mb-4">
                    <Label htmlFor={`addresses[` + i + `]['city']`} className="form-label">
                      City <span className="requiredStar">*</span>
                    </Label>
                    <Input
                      defaultValue={address.city}
                      name={`addresses[` + i + `]['city']`}
                      className="form-control"
                      type="text"
                      required={this.props.step === 2 ? true : false}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <StateDD
                    defaultValue={address.state}
                    name={`addresses[` + i + `]['state']`}
                    required={this.props.step === 2}
                  />
                </Col>
                <Col md={4}>
                  <div className="mb-4">
                    <Label htmlFor={`addresses[` + i + `]['zipcode']`} className="form-label">
                      Zip code <span className="requiredStar">*</span>
                    </Label>

                    <Input
                      pattern="^\s*?\d{5}(?:[-\s]\d{4})?\s*?$"
                      defaultValue={address.zipcode}
                      name={`addresses[` + i + `]['zipcode']`}
                      className="form-control"
                      onKeyDown={e => this.handleZipcodeInputKeyDown(e)}
                      required={this.props.step === 2 ? true : false}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="default_patient_address"
                      id="default_patient_address"
                      value={i}
                      defaultChecked={address.isDefault}
                    />
                    <label className="form-check-label" htmlFor="default_patient_address">
                      Default Address
                    </label>
                  </div>
                </Col>
              </Row>
            </div>
          );
        }),
      });
    } else {
      this.setState({
        addresses: (
          <>
            <Row>
              <Col md={6}>
                <div className="mb-4">
                  <Label htmlFor={`addresses[0]['addressLine1']`} className="form-label">
                    Address line 1 <span className="requiredStar">*</span>
                  </Label>
                  <Input
                    name={`addresses[0]['addressLine1']`}
                    className="form-control"
                    type="textarea"
                    required={this.props.step === 2 ? true : false}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-4">
                  <Label htmlFor={`addresses[0]['addressLine2']`} className="form-label">
                    Address line 2
                  </Label>
                  <Input name={`addresses[0]['addressLine2']`} className="form-control" type="textarea" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div className="mb-4">
                  <Label htmlFor={`addresses[0]['city']`} className="form-label">
                    City <span className="requiredStar">*</span>
                  </Label>
                  <Input
                    name={`addresses[0]['city']`}
                    className="form-control"
                    type="text"
                    required={this.props.step === 2 ? true : false}
                  />
                </div>
              </Col>
              <Col md={4}>
                <StateDD name={`addresses[0]['state']`} required={this.props.step === 2} />
              </Col>
              <Col md={4}>
                <div className="mb-4">
                  <Label htmlFor={`addresses[0]['zipcode']`} className="form-label">
                    Zip code <span className="requiredStar">*</span>
                  </Label>
                  <input
                    name={`addresses[0]['zipcode']`}
                    className="form-control"
                    onKeyDown={e => this.handleZipcodeInputKeyDown(e)}
                    required={this.props.step === 2 ? true : false}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="default_patient_address"
                    id="default_patient_address"
                    value="0"
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="default_patient_address">
                    Default Address
                  </label>
                </div>
              </Col>
            </Row>
          </>
        ),
      });
    }
  }

  render() {
    var patient = this.props.patient;

    return (
      <div className={`mt-4 ${this.props.step === 2 ? "d-block" : "d-none"}`}>
        <Row>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="email" className="form-label">
                Email address
              </Label>
              <Input
                defaultValue={patient ? patient.email : ""}
                name="email"
                className="form-control"
                placeholder=""
                type="email"
                onChange={e => this.props.setEmail(e.target.value)}
                required={false}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="cellPhone" className="form-label">
                Mobile Phone <span className="requiredStar">*</span>
              </Label>
              <Input
                defaultValue={patient ? patient.cellPhone : ""}
                name="cellPhone"
                className="form-control"
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                placeholder="Format: 1234567890"
                onChange={e => this.props.setContactNumber(e.target.value)}
                required={this.props.step === 2 ? true : false}
              />
            </FormGroup>
          </Col>
          <Col md={4} className="mb-4">
            <FormGroup>
              <Label htmlFor="phoneNumber" className="form-label">
                Other Phone
              </Label>
              <Input
                defaultValue={patient ? patient.phoneNumber : ""}
                name="phoneNumber"
                className="form-control"
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                placeholder="Format: 1234567890"
              />
            </FormGroup>
          </Col>
        </Row>
        {this.state.addresses}
        {this.state.moreAddresses}
        <Row className="flexJCAC">
          <button
            type="button"
            className="pinkButton mt-5"
            onClick={e => {
              e.preventDefault();
              this.addAnotherAddress(this.state.moreAddressCount);
            }}>
            Add Another Address
          </button>
        </Row>
      </div>
    );
  }
}

export default PatientInfo2;
