import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { USER_ROLES } from "../../utils";
import { userInfoSelector, userRoleSelector } from "selectors";
import { useSelector } from "react-redux";
const AdminRolesDD = props => {
  const [defaultValue, setDefaultValue] = useState("");
  const userRole = useSelector(userRoleSelector);
  const userData = useSelector(userInfoSelector);
  useEffect(() => {
    setDefaultValue(props.defaultValue);
  }, [props]);
  return (
    <FormGroup className="mb-4" key={defaultValue}>
      <Label className="normalText" for={props.name}>
        Role {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down" style={{ marginTop: "25px" }}></i>
      </p>
      <Input
        type="select"
        name={props.name}
        required={props.required}
        defaultValue={defaultValue}
        disabled={userRole !== USER_ROLES.ADMIN_SUPER_USER && userRole !== USER_ROLES.PHARMACIST}>
        <option value="" disabled>
          Select a Role
        </option>
        <option value={USER_ROLES.ADMIN_SUPER_USER}>{"Super Admin"}</option>
        <option value={USER_ROLES.PHARMACIST}>{"Pharmacist"}</option>
        {/* <option value={USER_ROLES.SALES_REP}>{"Sales Rep"}</option> */}
        <option value={USER_ROLES.RX_TECH}>{"RX Tech"}</option>
      </Input>
    </FormGroup>
  );
};

export default AdminRolesDD;
