import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig from "../../utils/axiosConfig";

const normalizeAddress = (address, type = "normal") => {
  const fields = ["addressLine1", "addressLine2", "city", "state", "zipcode"];
  const shippingFields = fields.map(field => `shipping${field.charAt(0).toUpperCase() + field.slice(1)}`);
  const normalizedAddress = {};
  const addressKeys = type === "shipping" ? shippingFields : fields;

  addressKeys.forEach(key => {
    const value = address[key]?.replace(/\s+/g, " ")?.trim()?.toUpperCase() || "";
    normalizedAddress[key?.replace("shipping", "")?.toLowerCase()?.replace("addressline", "addressLine")] = value;
  });

  return normalizedAddress;
};

const formatAddress = address => {
  const { addressLine1, addressLine2, city, state, zipcode } = address;
  const addressComponents = [addressLine1, addressLine2, city, state, zipcode].filter(Boolean);
  return addressComponents.join(", ").toUpperCase();
};

// const formatPreferredShippingAddress = address => {
//   debugger;
//   const { shippingAddressLine1, shippingAddressLine2, shippingCity, shippingState, shippingZipcode } = address;
//   const addressComponents = [
//     shippingAddressLine1,
//     shippingAddressLine2,
//     shippingCity,
//     shippingState,
//     shippingZipcode,
//   ].filter(Boolean);
//   return addressComponents.join(", ").toUpperCase();
// };

const isEqualAddress = (address1, address2) => {
  const normalizedAddress1 = normalizeAddress(address1);
  const normalizedAddress2 = normalizeAddress(address2);
  return JSON.stringify(normalizedAddress1) === JSON.stringify(normalizedAddress2);
};

const PatientAddressDD = props => {
  const [addresses, setAddresses] = useState([]);
  const [addressesList, setAddressesList] = useState([]);
  const [firstAddress, setFirstAddress] = useState({});
  const fetchAddresses = async () => {
    try {
      const defaultValue = props.defaultValue || {};
      const userToken = new Cookies().get("user_token");
      const userId = new Cookies().get("user_id");

      const response = await axiosConfig.post(
        "patients/getPatientAddressesByPatientId",
        { patientId: props.patientID, userId },
        { headers: { Authorization: `Bearer ${userToken}` } },
      );

      const data = response.data.addresses || [];
      setFirstAddress(data[0] || {});

      let addressesValues = data.map(address => (
        <option key={address._id} selected={isEqualAddress(address, defaultValue)} value={formatAddress(address)}>
          {formatAddress(address)}
        </option>
      ));
      let dataList = response?.data?.addresses;
      if (defaultValue?.addressLine1 && !data.some(a => isEqualAddress(a, defaultValue))) {
        dataList.push(normalizeAddress(defaultValue));
        addressesValues.push(
          <option
            key={defaultValue._id}
            selected={isEqualAddress(defaultValue, defaultValue)}
            value={formatAddress(defaultValue)}>
            {formatAddress(defaultValue)}
          </option>,
        );
      }
      let shippingAddress = normalizeAddress(response.data, "shipping");
      let formattedShippingAddress = formatAddress(shippingAddress);
      if (!isEqualAddress(shippingAddress, defaultValue)) {
        addressesValues.push(
          <option
            key={formatAddress(shippingAddress)}
            selected={isEqualAddress(shippingAddress, defaultValue)}
            value={formattedShippingAddress}>
            {formattedShippingAddress}
          </option>,
        );
        dataList.push({
          addressLine1: shippingAddress?.addressLine1,
          addressLine2: shippingAddress?.addressLine2,
          city: shippingAddress?.city,
          state: shippingAddress?.state,
          zipcode: shippingAddress?.zipcode,
          _id: "patient-pref-ship" + response.data.id,
          isDefault: false,
        });
      }
      setAddresses(addressesValues);
      setAddressesList(dataList);
      if ((props.editBool && defaultValue?.addressLine1) || (props.editBool && props.toggleShipTo !== 0 && defaultValue?.addressLine1)) {
        props.setSelectedAddress(normalizeAddress(defaultValue));
      }
      else{
        if(props.defaultValue?.addressLine1){
          props.setSelectedAddress(props.defaultValue);
        }
        else{
          props.setSelectedAddress(data[0]);
        }
      
      }
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  useEffect(() => {
    if (props.step===4 && (props.isBulkOrder===false && props.shipToClinic===false)) {
      fetchAddresses();
    }
  }, [props.defaultValue, props.patientID, props.isBulkOrder,props.shipToClinic,props.step]);


  return (
    <FormGroup>
      <Label className="normalText" for={props.name}>
        Patient Address {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        name={props.name}
        required={props.required}
        onChange={e => {
          const selectedAddress = addressesList.find(x => formatAddress(x) === e.target.value);
          props.setSelectedAddress(selectedAddress);
        }}>
        {addresses}
      </Input>
    </FormGroup>
  );
};

export default PatientAddressDD;
