import Multiselect from "multiselect-react-dropdown";
import React, { useState, useEffect, useMemo } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import { cookies } from "utils";
import { COOKIES_NAME } from "utils/cookieConstant";
import axiosConfig, { errorResponse } from "utils/axiosConfig";
import Spinner from "components/spinner";

const MultipleClinicLocationsDD = props => {
  const { defaultValues, onSelect, onRemove, required, name } = props;
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState("loading");
  const [showSpinner, setShowSpinner] = useState(false);
  const [preSelectedValues, setPreSelectedValues] = useState([]);
  const clinicId = useMemo(
    () => props.clinicId || cookies.get(COOKIES_NAME.CLINIC_ID) || props?.clinicIdDetails || props?.defaultClinicId,
    [props.clinicId, cookies.get(COOKIES_NAME.CLINIC_ID), props?.clinicIdDetails, props?.defaultClinicId],
  );
  useEffect(() => {
    if (clinicId) {
      setShowSpinner(true);
      axiosConfig
        .post(
          "clinicLocations/clinicLocationsInClinic",
          {
            clinicId: clinicId,
            keyword: "",
            limit: 20,
            currentPage: 1,
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          },
        )
        .then(async response => {
          let locationList = [];
          await response?.data?.clinicLocations?.forEach(location => {
            locationList.push({
              name: location.locationName,
              id: location.id,
            });
          });

          setLocations(locationList);
          setShowSpinner(false);
        })
        .catch(error => {
          if (error.response?.data?.message === "Clinic Location not found") {
            setResult("complete");
            setLoading(false);
          } else {
            setLoading(false);
            setResult("err");
            errorResponse(error);
          }
        });
    }
  }, [clinicId]);

  useEffect(() => {
    setPreSelectedValues(locations?.filter(a => defaultValues?.includes(a.id)));
    props.setSelectedClinicLocationsName(locations?.filter(a => defaultValues?.includes(a.id)));
  }, [locations, defaultValues]);

  return (
    <FormGroup>
      <Label className="normalText" for={name}>
        Clinic Location {required ? <span className="requiredStar">*</span> : ""}
      </Label>
      {showSpinner ? (
        <Spinner />
      ) : (
        <Multiselect
          options={locations} // Options to display in the dropdown
          selectedValues={preSelectedValues} // Preselected value to persist in dropdown
          onSelect={onSelect} // Function will trigger on select event
          onRemove={onRemove} // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
      )}
    </FormGroup>
  );
};

export default MultipleClinicLocationsDD;
