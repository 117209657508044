import { GoogleAuthApi } from "../../rtkApi";
import { API_METHODS, END_POINTS, EXPORT_END_POINT, GOOGLE_AUTH_END_POINTS } from "../../rtkConstant";

const GoogleAuthQuery = GoogleAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    generate2FAKey: builder.query({
      query: (apiArgs) => ({
        url: GOOGLE_AUTH_END_POINTS.GENERATE_2FA_KEY,
        method: API_METHODS.GET,
      }),
    }),
  }),
});
export const { useLazyGenerate2FAKeyQuery } = GoogleAuthQuery;
