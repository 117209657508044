import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Modal, Row, Col } from "reactstrap";
import Lottie from "../components/lottie";
import { Link } from "react-router-dom";
import ClinicCreditCardDD from "./dropdowns/clinicCreditCardsDD";
export default function ValidAddressModal(props) {
  const [complete, setComplete] = useState(false);
  const [clinicAddress, setClinicAddress] = useState("");

  function updateToValidate(e) {
    e.preventDefault();

    props.onClickValidate();
  }

  function updateToTyped(e) {
    e.preventDefault();
    props.onClickNotValidate();
  }
  useEffect(() => {
    setClinicAddress(props.clinicAddress);
  }, [props.clinicAddress]);

  //Don't make changes to this Modal.
  return (
    <Modal isOpen={props.showValidAddressModal} size="lg" centered={true} className="largeHeightmodal" zIndex={1400}>
      <div className="p-4">
        {props.patientShippingAddress ? (
          <div className="modal-body flexJCAC flex-column">
            <Row>
              <table>
                <th>
                  <h5>Typed Address</h5>
                </th>
                <tr>
                  <td>Shipping Address Line 1:</td>
                  <td>{props.patientShippingAddress.shippingAddressLine1}</td>
                </tr>
                <tr>
                  <td>Shipping Address Line 2:</td>
                  <td>{props.patientShippingAddress.shippingAddressLine2}</td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>{props.patientShippingAddress.shippingCity}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{props.patientShippingAddress.shippingState}</td>
                </tr>
                <tr>
                  <td>Zip Code:</td>
                  <td>{props.patientShippingAddress.shippingZipcode}</td>
                </tr>
              </table>
              <br></br>
              <hr></hr>
              <br></br>
              <table>
                <th>
                  <h5>Validated Address </h5>
                  <p>({props?.isUSPS ? "USPS" : "FedEx"} Verified)</p>
                </th>
                <tr>
                  <td>Shipping Address Line 1:</td>
                  <td>{props.fedexShippingAddress?.addressLine1}</td>
                </tr>
                <tr>
                  <td>Shipping Address Line 2:</td>
                  <td>{props.fedexShippingAddress?.addressLine2}</td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>{props.fedexShippingAddress?.city}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{props.fedexShippingAddress?.state}</td>
                </tr>
                <tr>
                  <td>Zip Code:</td>
                  <td>{props.fedexShippingAddress?.zipcode}</td>
                </tr>
              </table>
            </Row>

            <Row className="mt-5">
              <Link
                to=""
                className="pinkButton mx-2"
                onClick={(e) => {
                  updateToValidate(e);
                }}
              >
                Use Validated Address
              </Link>
              <Link
                to=""
                className="lightButton mx-2"
                onClick={(e) => {
                  updateToTyped(e);
                }}
              >
                Use Typed Address
              </Link>
            </Row>
          </div>
        ) : (
          <div className="modal-body flexJCAC flex-column">
            <Row>
              <table>
                <th>
                  <h5>Typed Address</h5>
                </th>
                <tr>
                  <td>Shipping Address Lines 1:</td>
                  <td>{clinicAddress?.addressLine1}</td>
                </tr>
                <tr>
                  <td>Shipping Address Line 2:</td>
                  <td>{props.clinicAddress?.addressLine2}</td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>{props.clinicAddress?.city}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{props.clinicAddress?.state}</td>
                </tr>
                <tr>
                  <td>Zip Code:</td>
                  <td>{props.clinicAddress?.zipcode}</td>
                </tr>
              </table>
              <br></br>
              <hr></hr>
              <br></br>
              <table>
                <th>
                  <h5>Validated Address </h5>
                  <p>({props?.isUSPS ? "USPS" : "FedEx"} Verified)</p>
                </th>
                <tr>
                  <td>Shipping Address Line 1:</td>
                  <td>{props.fedexShippingAddress?.addressLine1}</td>
                </tr>
                <tr>
                  <td>Shipping Address Line 2:</td>
                  <td>{props.fedexShippingAddress?.addressLine2}</td>
                </tr>
                <tr>
                  <td>City:</td>
                  <td>{props.fedexShippingAddress?.city}</td>
                </tr>
                <tr>
                  <td>State:</td>
                  <td>{props.fedexShippingAddress?.state}</td>
                </tr>
                <tr>
                  <td>Zip Code:</td>
                  <td>{props.fedexShippingAddress?.zipcode}</td>
                </tr>
              </table>
            </Row>

            <Row className="mt-5">
              <Link
                to=""
                className="pinkButton mx-2"
                onClick={(e) => {
                  updateToValidate(e);
                }}
              >
                Use Validated Address
              </Link>
              <Link
                to=""
                className="lightButton mx-2"
                onClick={(e) => {
                  updateToTyped(e);
                }}
              >
                Use Typed Address
              </Link>
            </Row>
          </div>
        )}
      </div>
    </Modal>
  );
}
