import React, { useEffect, useState, useRef } from "react";
import { FormGroup, Label, Input } from "reactstrap";

const DispenseTypeDD = props => {
  //TODO: Populate based on clinic products
  const { name, label, register, required, defaultValue } = props;

  const options = [
    "Topical Cream",
    "Topical Foam",
    "Topical Solution",
    "Topical Ointment",
    "Topical Gel",
    "Foam Wash",
    "Injectable",
    "SQ Injectable",
    "Lyophilized Powder",
    "Capsule",
    "Tablet",
    "Troche",
    "Medical Accessory",
    "Oral Spray",
    "Oral Suspension",
    "Suppository"
  ];
  
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label}
      </Label>
      <select
        name={name}
        value={defaultValue ? defaultValue : ""}
        {...register(name, { required: false })}
        style={{
          borderRadius: "5px",
          paddingBottom: "0px",
          width: "100%",
          height: "40px",
          borderColor: "#CDCDCD",
        }}>
        <option value="" disabled>
          {label}
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </FormGroup>
  );
};

export default DispenseTypeDD;
