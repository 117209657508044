//Dashboard screens
import Dashboard from "./screens/patient/Dashboard/dashboard";

//Prescription

//Users
import PatientProfile from "./screens/patient/User/profile";
import EditProfileScreen from "./screens/patient/User/edit-profile";
import OTC from "./screens/patient/Education/otc";
import AAS from "./screens/patient/Education/aas";
import SARMS from "./screens/patient/Education/sarms";
import Peptides from "./screens/patient/Education/peptides";
import Ancillaries from "./screens/patient/Education/ancillaries";
import RXCompounding from "./screens/patient/Education/rx-compounding";
import LegalCompliance from "./screens/patient/Education/legal-compliance";
import ReconstitutionCalculator from "./screens/patient/Tools/reconstitution-calculator";
import AnalyticalTesting from "./screens/patient/Tools/analytical-testing";
import DoseCalculator from "./screens/patient/Tools/dose-calculator";
import HalfLifePlotter from "./screens/patient/Tools/half-life-plotter";
import { PatientOrder, PatientPrescriptionHistory } from "./screens/patient";

const Patient = {
  Dashboard,
  PatientOrder,
  PatientProfile,
  EditProfileScreen,
  AnalyticalTesting,
  DoseCalculator,
  HalfLifePlotter,
  PatientPrescriptionHistory,
  ReconstitutionCalculator,
  OTC,
  SARMS,
  Peptides,
  Ancillaries,
  RXCompounding,
  LegalCompliance,
  AAS,
};

export default Patient;
