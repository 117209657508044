import React, { useState, useEffect, useRef, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import StateDD from "../../../components/dropdowns/stateDD";
import SexDD from "../../../components/dropdowns/sexDD";
import Lottie from "../../../components/lottie";
import ClinicNavbar from "../../../components/ClinicNavbar";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import moment from "moment";
import { USER_ROLES } from "utils";
import { SignaturePad } from "components/SignaturePad";
import { useEditProfileMutation, useLazyGetStaffQuery, useUploadFileMutation } from "rtk";
import { userRoleSelector, userInfoSelector, jwtTokensSelector } from "../../../selectors";
import { useSelector } from "react-redux";
import useClinicStaff from "../Hook/useClinicStaff";
import { useLazyGenerate2FAKeyQuery } from "rtk/rtkEndpoints/GoogleAuth/GoogleAuthQuery";
import { GoogleAuthToggle } from "components/GoogleAuthModal";
const EditProfile = () => {
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);
  //Defining States
  const [step, setStep] = useState(1);
  // const [error, setError] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [showSpinner, setShowSpinner] = useState(true);
  const [dob, setDob] = useState("");
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [signatureImageSrc, setSignatureImageSrc] = useState("");
  const [role, setRole] = useState(userRole);
  const [signaturePadImage, setSignaturePadImage] = useState("");
  const [signatureModal, setSignatureModal] = useState(false);
  const [profileImageSrc, setProfileImageSrc] = useState("");
  const {
    editProfileMutate,
    updateProfile: updateProfileHelper,
    uploadFile: uploadFileHelper,
    isStaffFetching,
    complete,
    showLoading,
    showError,
    errorMessage,
    fetchStaff,
    user,
    fetchGoogleKey,
    googleAuthInfo,
    isFetching2FA,
  } = useClinicStaff();

  //Defining APIs
  // const [fetchStaff, { data: user, error: fetchError, isError: isFetchError, isFetching }] = useLazyGetStaffQuery();
  const [genImage, setGenImage] = useState("");

  useEffect(() => {
    if (!isStaffFetching) {
      fetchStaff({ staffId: userInfo.id });
    }
  }, [fetchStaff]);
  async function handleValidSubmit(e) {
    e.preventDefault();
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      updateProfile();
    }
  }
  async function upload(e, name, sigPad = "") {
    let url = await uploadFileHelper(e, sigPad);

    if (name === "signatureImageUrl") {
      setSignatureImageUrl(url ?? "");
    } else setProfileImageUrl(url ?? "");
  }

  const updateProfile = () => {
    let serialize = require("form-serialize");
    let form = document.getElementById("newStaffForm");
    let obj = serialize(form, { hash: true });
    let userObj = updateProfileHelper(obj, user.id, profileImageUrl, signatureImageUrl);
    editProfileMutate({ payload: userObj });
  };

  useEffect(() => {
    if (user) {
      setDob(user.dateOfBirth ? moment(user.dateOfBirth, "DD-MM-YYYY").format("YYYY-MM-DD") : "");
      setProfileImageUrl(user.profileImageUrl);
      setProfileImageSrc(
        user.profileImageUrl ? <img className="staffProfileImgCircle" src={user.profileImageUrl} alt="" /> : null,
      );
      setSignatureImageUrl(user.signatureImageUrl);
    }
  }, [user]);

  const onClickSave = (person = "signatureImageUrl", sig) => {
    upload("", person, sig);
  };

  const closeSignatureModal = () => {
    setSignatureModal(false);
  };
  const navigationSteps = useMemo(() => {
    return (
      <div className="w-100 text-center my-4">
        <div className="flexJCAC w-100" style={{ margin: "60px !important" }}>
          <div className="rounded-pill" style={{ width: "200px" }}>
            <div className="activeProgressNumberCircle">
              <p>1</p>
            </div>
            <p className="m-0">Personal</p>
            <p>Information</p>
          </div>
          <div className="rounded-pill" style={{ width: "200px" }}>
            <div className={step >= 2 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
              <p>2</p>
            </div>
            <p className="m-0">Contact</p>
            <p>Information</p>
          </div>
        </div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <ClinicNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Edit Profile | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">EDIT PROFILE</h4>
          {showLoading ? <Spinner /> : null}
          {showError ? (
            <Alert color="danger" className="text-center">
              {errorMessage}
            </Alert>
          ) : null}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {complete ? (
                    <div className="m-sm-5 p-sm-5 p-0 m-0 text-center">
                      <Lottie name="success-check" />
                      <p className="mb-5">Your profile information has been updated!</p>
                      <Link to="/profile" className="pinkButton">
                        Profile
                      </Link>
                    </div>
                  ) : (
                    <>
                      {navigationSteps}
                      <CardBody className="pt-0">
                        <div className="p-2">
                          {user ? (
                            <form
                              id="newStaffForm"
                              className="stepsForm form-horizontal px-sm-4 p-0"
                              onSubmit={e => handleValidSubmit(e)}>
                              <div className="mb-5 px-lg-5 px-sm-5 p-0">
                                <div className={`${step === 1 ? "d-block" : "d-none"}`}>
                                  <Row>
                                    <div className="mb-4 flexJCAC flex-column">
                                      {profileImageUrl === null ? (
                                        <img className="staffProfileImgCircle" src={null} alt="" />
                                      ) : (
                                        <img className="staffProfileImgCircle" src={profileImageUrl} alt="" />
                                      )}
                                      {profileImageUrl && (
                                        <i
                                          className="fas fa-trash redText"
                                          onClick={() => {
                                            setProfileImageUrl("");
                                          }}></i>
                                      )}
                                      <button
                                        className="pinkButton my-4"
                                        onClick={e => {
                                          e.preventDefault();
                                          document.getElementById("profilePhoto").click();
                                        }}>
                                        Upload Image
                                      </button>
                                      <Input
                                        accept=".jpg, .png, .jpeg"
                                        onChange={e => {
                                          upload(e, "profile", "");
                                        }}
                                        name="profile_photo"
                                        className="form-control d-none"
                                        type="file"
                                        id="profilePhoto"
                                      />
                                    </div>
                                  </Row>
                                  <Row>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="firstName" className="form-label">
                                          First name <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          defaultValue={user.firstName}
                                          name="firstName"
                                          className="form-control"
                                          placeholder=""
                                          type="text"
                                          required={step === 1 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="middleName" className="form-label">
                                          Middle name
                                        </Label>
                                        <Input
                                          defaultValue={user.middleName}
                                          name="middleName"
                                          className="form-control"
                                          placeholder=""
                                          type="text"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="lastName" className="form-label">
                                          Last name <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          defaultValue={user.lastName}
                                          name="lastName"
                                          className="form-control"
                                          placeholder=""
                                          type="text"
                                          required={step === 1 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={4} className="mb-4">
                                      <SexDD name="sex" defaultValue={user.sex} required={step === 1 ? true : false} />
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="dateOfBirth" className="form-label">
                                          Date of Birth <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="dateOfBirth"
                                          defaultValue={dob}
                                          className="form-control"
                                          type="date"
                                          required={step === 1 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                <div className={`mt-3 ${step === 2 ? "d-block" : "d-none"}`}>
                                  <Row>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="email" className="form-label">
                                          Email address <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="email"
                                          defaultValue={user.email}
                                          className="form-control"
                                          placeholder=""
                                          type="email"
                                          required={step === 2 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="phoneNumber" className="form-label">
                                          Phone number <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="phoneNumber"
                                          defaultValue={user.phoneNumber}
                                          className="form-control"
                                          type="tel"
                                          pattern={step === 2 ? "[0-9]{3}[0-9]{3}[0-9]{4}" : null}
                                          placeholder="Format: 1234567890"
                                          required={step === 2 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="mobileNumber" className="form-label">
                                          Mobile number
                                        </Label>
                                        <Input
                                          name="mobileNumber"
                                          defaultValue={user.mobileNumber}
                                          className="form-control"
                                          type="tel"
                                          pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                          placeholder="Format: 1234567890"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6}>
                                      <div className="mb-4">
                                        <Label htmlFor="addressLine1" className="form-label">
                                          Address line 1 <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          maxLength={50}
                                          name="addressLine1"
                                          defaultValue={user.addressLine1}
                                          className="form-control"
                                          type="textarea"
                                          required={step === 2 ? true : false}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className="mb-4">
                                        <Label htmlFor="addressLine2" className="form-label">
                                          Address line 2
                                        </Label>
                                        <Input
                                          maxLength={50}
                                          name="addressLine2"
                                          defaultValue={user.addressLine2}
                                          className="form-control"
                                          type="textarea"
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={3}>
                                      <div className="mb-4">
                                        <Label htmlFor="country" className="form-label">
                                          Country <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="country"
                                          defaultValue={user.country}
                                          className="form-control"
                                          type="text"
                                          required={step === 2 ? true : false}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <div className="mb-4">
                                        <Label htmlFor="city" className="form-label">
                                          City <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="city"
                                          defaultValue={user.city}
                                          className="form-control"
                                          type="text"
                                          required={step === 2 ? true : false}
                                        />
                                      </div>
                                    </Col>
                                    <Col md={3}>
                                      <StateDD name="state" defaultValue={user.state} required={step === 2} />
                                    </Col>
                                    <Col md={3}>
                                      <div className="mb-4">
                                        <Label htmlFor="zipcode" className="form-label">
                                          Zip code <span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="zipcode"
                                          defaultValue={user.zipcode}
                                          className="form-control"
                                          required={step === 2 ? true : false}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <div>
                                      {signatureImageUrl ? (
                                        <img
                                          style={{ width: "150px", height: "auto" }}
                                          className="signatureImageUrl"
                                          state
                                          src={signatureImageUrl}
                                          alt=""
                                        />
                                      ) : null}
                                      <div className="mb-4">
                                        <Label htmlFor="signatureImageUrl" className="form-label">
                                          Prescriber's Signature
                                        </Label>
                                        <br></br>
                                        <small>
                                          <i>(Provide signature using the signature pad.)</i>
                                        </small>{" "}
                                        <br></br>
                                        <Row>
                                          <Col md={3}>
                                            <span
                                              className="pointer form-control"
                                              onClick={() => setSignatureModal(true)}>
                                              <i class="fas fa-pen mx-2"></i>Signature Pad
                                            </span>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Row>
                                  <Row>
                                    <div>
                                      <div className="mb-4">
                                        <Label htmlFor="" className="form-label">
                                          Two-Factor Authentication
                                        </Label>
                                        <Row>
                                          {/* <span onClick={callGen}>Click</span> */}
                                          {user.id === userInfo.id ? (
                                            <GoogleAuthToggle enable2FA={user?.google_2f_enabled} />
                                          ) : (
                                            <p className={user?.google_2f_enabled ? "greenText" : "redText"}>
                                              {user?.google_2f_enabled ? "Enabled" : "Disabled"}
                                            </p>
                                          )}
                                        </Row>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </div>
                              <Row className="m-sm-5 m-0 text-center flexJCAC pt-3">
                                {step === 1 ? null : (
                                  <button
                                    className="lightButton mt-4 mx-2"
                                    style={{ width: "40%" }}
                                    onClick={e => {
                                      e.preventDefault();
                                      setStep(step - 1);
                                    }}>
                                    Back
                                  </button>
                                )}
                                {step === 2 ? (
                                  <button className="blueButton mt-4 w-50" type="submit">
                                    Update Profile
                                  </button>
                                ) : (
                                  <button className="blueButton mt-4 w-50" type="submit">
                                    Next
                                  </button>
                                )}
                              </Row>
                              <SignaturePad
                                actionText="Save"
                                onClickSave={onClickSave}
                                closeModal={closeSignatureModal}
                                showModal={signatureModal}
                              />
                            </form>
                          ) : null}
                        </div>
                      </CardBody>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
