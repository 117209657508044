import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import img1 from '../../../assets/images/product-details/pd1.png';
import img2 from '../../../assets/images/product-details/pd2.png';
import img3 from '../../../assets/images/product-details/pd3.png';
import img4 from '../../../assets/images/product-details/pd4.png';
import img5 from '../../../assets/images/product-details/pd5.png';
import img6 from '../../../assets/images/product-details/pd6.png';
import img7 from '../../../assets/images/product-details/pd7.png';
import img8 from '../../../assets/images/product-details/pd8.png';
const DisplayModalMoreData = (props) => {
  const { events, categories } = props;
  const [updatedCategories, setUpdatedCategories] = useState(categories);
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalcategory, setModalcategory] = useState(false);
  const [event, setEvent] = useState({});
  const [selectedDay, setSelectedDay] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  /**
   * Handling the modal state
   */
  const toggle = () => {
    props.closeModal();
  };

  const toggleCategory = () => {
    setModalcategory(!modalcategory);
  };

  useEffect(() => {});

  return (
    <React.Fragment className='mt-4'>
      {props.showModal ? (
        <Modal isOpen={props.showModal} scrollable={true} size='xl' className='mt-4' centered>
          <ModalHeader>
            <h3>{props.nameofproduct}</h3>
          </ModalHeader>
          <ModalBody>
            <Row form>
              <Col className='col-12 mb-3'>
                <h3>
                  <img className='' src={img1} alt='' height='60' />
                  {props.displayModalData[0].title}
                </h3>
                <p>{props.displayModalData[0].text}</p>
                <h3>
                  <img className='' src={img2} alt='' height='60' />
                  {props.displayModalData[1].title}
                </h3>
                <p>{props.displayModalData[1].text}</p>
                <h3>
                  <img className='' src={img3} alt='' height='60' />
                  {props.displayModalData[2].title}
                </h3>
                <p>{props.displayModalData[2].text}</p>
                <h3>
                  <img className='' src={img4} alt='' height='60' />
                  {props.displayModalData[3].title}
                </h3>
                <p>{props.displayModalData[3].text}</p>
                <h3>
                  <img className='' src={img5} alt='' height='60' />
                  {props.displayModalData[4].title}
                </h3>
                <p>{props.displayModalData[4].text}</p>
                <h3>
                  <img className='' src={img6} alt='' height='60' />
                  {props.displayModalData[5].title}
                </h3>
                <p>{props.displayModalData[5].text}</p>
                <h3>
                  <img className='' src={img7} alt='' height='60' />
                  {props.displayModalData[6].title}
                </h3>
                <p>{props.displayModalData[6].text}</p>
                <h3>
                  <img className='' src={img8} alt='' height='60' />
                  {props.displayModalData[7].title}
                </h3>
                <p>{props.displayModalData[7].text}</p>
                <h3>{props.displayModalData[8].title}</h3>
                <p>{props.displayModalData[8].text}</p>
              </Col>
              <Col className='col-12 mb-3'></Col>
            </Row>
            <Row>
              <Col align='center'>
                <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                  Close
                </button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default DisplayModalMoreData;
