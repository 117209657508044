import { combineReducers } from "@reduxjs/toolkit";
import {
  AdminApi,
  AuthApi,
  ClinicApi,
  DashboardApi,
  FedexApi,
  InvoiceApi,
  PatientApi,
  StaffApi,
  UploadsApi,
  GoogleAuthApi,
  ClinicLocationApi,
} from "../rtkApi";
import { AuthSliceReducer, ProductCartReducer } from "../rtkSlices";

const RootReducer = combineReducers({
  AuthSlice: AuthSliceReducer,
  ProductCart: ProductCartReducer,
  [AuthApi.reducerPath]: AuthApi.reducer,
  [DashboardApi.reducerPath]: DashboardApi.reducer,
  [ClinicApi.reducerPath]: ClinicApi.reducer,
  [AdminApi.reducerPath]: AdminApi.reducer,
  [InvoiceApi.reducerPath]: InvoiceApi.reducer,
  [PatientApi.reducerPath]: PatientApi.reducer,
  [StaffApi.reducerPath]: StaffApi.reducer,
  [UploadsApi.reducerPath]: UploadsApi.reducer,
  [FedexApi.reducerPath]: FedexApi.reducer,
  [GoogleAuthApi.reducerPath]: GoogleAuthApi.reducer,
  [ClinicLocationApi.reducerPath]: ClinicLocationApi.reducer,
});

export default RootReducer;
