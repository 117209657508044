import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import Cookies from "universal-cookie";
import { USER_ROLES } from "utils";
import axiosConfig from "../../../../utils/axiosConfig";

const useAddProductModal = props => {
  const {
    showModal = false,
    isEdit = false,
    closeModal,
    product: editProduct = null,
    setData,
    products,
    priceGroups: pg,
  } = props;

  const [error, setError] = useState("");
  const [sigOptions, setSigOptions] = useState([]);
  const [priceGroups, setPriceGroups] = useState([]);
  const [success, setSuccess] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const userRole = useSelector(userRoleSelector);

  const allowPriceEditing = useMemo(() => !(isEdit && userRole === USER_ROLES.PHARMACIST), [isEdit, userRole]);

  useEffect(async () => {
    var sigsVals = [];
    var priceGroup = [];
    products[0]?.sigOptions?.forEach((sig, i) => {
      sigsVals.push({
        title: sig.title,
        description: sig.description,
      });
    });
    console.log("PRICE GROUP: ", pg);
    pg?.forEach((p, i) => {
      priceGroup.push({
        groupName: p,
        price: p,
      });
    });

    setPriceGroups(priceGroup);
    setSigOptions(sigsVals);
  }, [pg]);

  const [fileError, setFileError] = useState(false);
  const [singleProduct, setSingleProduct] = useState(true);

  const { register, handleSubmit, errors, watch, reset } = useForm(
    isEdit
      ? {
          defaultValues: {
            name: editProduct?.name,
            family: editProduct?.family,
            productId: editProduct?.productId,
            subCategory1: editProduct?.subCategory1,
            subCategory2: editProduct?.subCategory2,
            commonName: editProduct?.commonName,
            unitPrice: editProduct?.unitPrice,
            sig1Title: editProduct?.sigOptions[0]?.title || "",
            sig1: editProduct?.sigOptions[0]?.description || "",
            sig2Title: editProduct?.sigOptions[1]?.title || "",
            sig2: editProduct?.sigOptions[1]?.description || "",
            sig3Title: editProduct?.sigOptions[2]?.title || "",
            sig3: editProduct?.sigOptions[2]?.description || "",
            sig4Title: editProduct?.sigOptions[3]?.title || "",
            sig4: editProduct?.sigOptions[3]?.description || "",
            priceGroups: editProduct?.priceGroups,
            medicalAccessories: editProduct?.medicalAccessories,
            coldShipped: editProduct?.coldShipped || "0",
            controlledSubstance: editProduct?.controlledSubstance || "0",
            dispenseType: editProduct?.dispenseType || "",
            isAvailable: editProduct?.isAvailable ? "1" : "0" ?? "",
          },
        }
      : {},
  );

  const categoryDefaultVal = watch().family;
  const subcategory1DefaultVal = watch().subCategory1;
  const medicalAccessoriesDefaultVal = watch().medicalAccessories;
  const coldShippedDefaultVal = watch().coldShipped;
  const controlledSubstanceDefaultVal = watch().controlledSubstance;
  const dispenseTypeDefaultVal = watch().dispenseType;
  const availabilityDefaultVal = watch().isAvailable;

  const onFileChangeHandler = file => {
    if (file.type === "application/vnd.ms-excel") {
      setFileError(false);
    } else {
      setFileError(true);
    }
  };

  const onSubmit = async data => {
    const {
      productId = "",
      name = "",
      unitPrice = 0,
      dispenseType = "",
      family = "",
      sig4,
      sig4Title,
      subCategory1 = "",
      subCategory2 = "",
      commonName = "",
      priceGroups: pGroups,
      medicalAccessories,
      coldShipped,
      controlledSubstance,
      sig1Title = "",
      sig1 = "",
      sig2Title = "",
      sig2 = "",
      sig3Title = "",
      sig3 = "",
      isAvailable,
    } = data;
    const { isActive = true } = isEdit ? editProduct : {};
    let sigOptions = [
      { title: sig1Title, description: sig1 },
      {
        title: sig2Title,
        description: sig2,
      },
      { title: sig3Title, description: sig3 },
      { title: sig4Title, description: sig4 },
    ];

    //Price Group Payload Config
    var pG = [];
    pGroups?.forEach((p, i) => {
      pG.push({
        groupName: priceGroups[i].groupName,
        price: pGroups[i].price,
      });
    });

    //

    const payload = {
      productId,
      name,
      commonName: commonName,
      priceGroups: pG,
      medicalAccessories,
      coldShipped: coldShipped || "0",
      controlledSubstance: controlledSubstance || "0",
      unitPrice,
      isActive,
      family: family,
      category: family,
      sigOptions,
      subCategory1: subCategory1,
      subCategory2: subCategory2,
      isAvailable: isAvailable === "1",
      dispenseType,
    };

    if (isEdit) {
      //api call for edit product
      const body = { productId: editProduct?.id, product: payload, userId: new Cookies().get("user_id") };
      setShowSpinner(true);
      await axiosConfig
        .post("/products/editProductByProductId", body, {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        })
        .then(async response => {
          const { status } = response;
          if (status === 200) {
            setData([{ id: editProduct?.id, ...payload }], editProduct?.id);
            setError("");
            props.setSuccess("Product Updated Successfully");
            closeModal();
          }
          setShowSpinner(false);
        })
        .catch(error => {
          setError(error?.response?.data?.message);
          props.setSuccess("");
          setShowSpinner(false);
        });
    } else {
      //api call for add single or csv product
      if (singleProduct) {
        const body = { product: payload, userId: new Cookies().get("user_id") };
        setShowSpinner(true);
        await axiosConfig
          .post("/products/addNewProduct", body, {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          })
          .then(async response => {
            const { status, data } = response;

            if (status === 200) {
              setData([{ id: data?.productId, ...payload }]);
              setError("");
              props.setSuccess(response.data.message);
              closeModal();
            }
            setShowSpinner(false);
          })
          .catch(error => {
            setError(error?.response?.data?.message);
            props.setSuccess("");
            setShowSpinner(false);
          });
      }
    }
  };

  return {
    showModal,
    allowPriceEditing,
    isEdit,
    closeModal,
    singleProduct,
    setSingleProduct,
    onSubmit,
    register,
    handleSubmit,
    errors,
    fileError,
    setFileError,
    onFileChangeHandler,
    priceGroups,
    sigOptions,
    watch,
    categoryDefaultVal,
    coldShippedDefaultVal,
    medicalAccessoriesDefaultVal,
    subcategory1DefaultVal,
    error,
    setError,
    success,
    setSuccess,
    showSpinner,
    setShowSpinner,
    availabilityDefaultVal,
    controlledSubstanceDefaultVal,
    dispenseTypeDefaultVal,
  };
};

export default useAddProductModal;
