import cat1 from './Capsules.jpg';
import cat2 from './Cream.jpg';
import cat3 from './Foam Wash.jpg';
import cat4 from './Gel.jpg';
import cat5 from './Ointment.jpg';
import cat6 from './Solution.jpg';
import cat7 from './Capsules.jpg';
import cat8 from './Cream.jpg';
import cat9 from './Foam Wash.jpg';
import cat10 from './Gel.jpg';
import cat11 from './Ointment.jpg';

const CatPhotos = [cat1, cat2, cat3, cat4, cat5, cat6, cat7, cat8, cat9, cat10, cat11];

export default CatPhotos;
