/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./assets/scss/theme.scss";
import "./assets/vpi-styles/datatables.scss";
import "./assets/vpi-styles/global.css";
import AuthRoute from "./routes/authRoute";
import PrivateRoute from "./routes/privateRoute";

//Common
import Page404 from "./screens/common/page404";
import NoAccessScreen from "./screens/common/pageInaccessible";

// Auth screens
import Login from "./screens/Authentication/Login";
import ForgetPassword from "./screens/Authentication/forgotPassword";
import LogOut from "./screens/Authentication/logout";
import RecoverPassword from "./screens/Authentication/RecoverPassword";
import RecoverPatientPassword from "./screens/Authentication/recoverPasswordPatient";
import RegisterClinic from "./screens/Authentication/RegisterClinic.jsx";

import VerifyEmail from "./screens/Authentication/verifyEmail";
import VerifyPatientEmail from "./screens/Authentication/verifyPatientEmail";
//Clinic Screens
import { refreshTokenCookies } from "utils";
import Admin from "./adminComponents.js";
import AddAdminListToChat from "./adminListToChat.js";
import Clinic from "./clinicComponents.js";
import Patient from "./patientComponents.js";
import {
  ClinicLocationList,
  ManagePatient,
  ManageStaff,
  PatientsList,
  ReviewPrescription,
  StaffListing,
} from "./screens";
import AddStaffListToChat from "./staffListToChat.js";
import { Reset2FA } from "components/GoogleAuthModal";
import ClearCache from "screens/common/ClearCache";
import { ManageClinicLocation } from "screens/common/ClinicLocation/ManageClinicLocation";
import { CartProvider } from "context/Cart";

//Patient Education

const App = () => {
  useEffect(() => {
    refreshTokenCookies();
  }, []);

  return (
    <Router>
      <CartProvider>
        <Switch>
          {/* auth screens */}
          <Route path="/login/:rpa?" component={Login} exact />
          <Route path="/logout" component={LogOut} exact />
          <AuthRoute path="/register-clinic" component={RegisterClinic} exact />
          <AuthRoute path="/forgot-password" component={ForgetPassword} exact />
          <AuthRoute path="/reset-password/:token" component={RecoverPassword} exact />
          <AuthRoute path="/reset-password/:id/:token" component={RecoverPassword} exact />
          <AuthRoute path="/reset-patient-password/:token" component={RecoverPatientPassword} exact />
          <Route path="/verify-email/:token" component={VerifyEmail} exact />
          <AuthRoute path="/verify-patient-email/:token" component={VerifyPatientEmail} exact />
          <Route path="/access-denied" component={NoAccessScreen} exact />
          {/* Comet Chat Script*/}
          <Route path="/admin-list-cometchat" component={AddAdminListToChat} exact />
          <Route path="/staff-list-cometchat" component={AddStaffListToChat} exact />
          {/* clinic */}
          <PrivateRoute path="/clinic-profile" adminComponent={Page404} clinicComponent={Clinic.ClinicProfile} exact />
          <PrivateRoute path="/clinics" adminComponent={Admin.ClinicsList} clinicComponent={Page404} exact />
          <PrivateRoute path="/add-clinic" adminComponent={Admin.AddClinic} clinicComponent={Page404} exact />
          <PrivateRoute path="/edit-clinic" adminComponent={Page404} clinicComponent={Clinic.EditClinic} exact />
          <PrivateRoute path="/reset2FA" adminComponent={Page404} clinicComponent={Reset2FA} exact />
          {/* staff */}
          <PrivateRoute path="/add-staff" adminComponent={ManageStaff} clinicComponent={ManageStaff} exact />
          {/* <PrivateRoute path="/add-staff/:clinicId" adminComponent={Clinic.ManageStaff} clinicComponent={Page404} exact /> */}
          <PrivateRoute path="/edit-staff/:staffId" adminComponent={ManageStaff} clinicComponent={ManageStaff} exact />
          {/* <PrivateRoute
          path="/edit-staff/:clinicId/:id"
          adminComponent={Clinic.ManageStaff}
          clinicComponent={Page404}
          exact
        /> */}
          <PrivateRoute path="/clinic-staff" adminComponent={StaffListing} clinicComponent={StaffListing} exact />
          {/* <PrivateRoute path="/clinic-staff/:clinicId" adminComponent={StaffListing} clinicComponent={Page404} exact /> */}
          <PrivateRoute
            path="/staff-profile/:clinicId/:id"
            adminComponent={Clinic.StaffProfile}
            clinicComponent={Page404}
            exact
          />
          <PrivateRoute
            path="/staff-profile/:id"
            adminComponent={Page404}
            clinicComponent={Clinic.StaffProfile}
            exact
          />
          <PrivateRoute
            path="/clinic-products/:clinicId"
            adminComponent={Admin.ClinicProducts}
            clinicComponent={Page404}
            exact
          />
          {/* Clinic Location Profile */}
          <PrivateRoute
            path="/clinic-location/:id"
            adminComponent={Clinic.ClinicLocationWrapper}
            clinicComponent={Clinic.ClinicLocationWrapper}
            exact
          />
          {/* Clinic Location Listing */}
          <PrivateRoute
            path="/clinic-locations/:clinicId?"
            adminComponent={ClinicLocationList}
            clinicComponent={ClinicLocationList}
            exact
          />
          {/* Clinic Location Route */}
          <PrivateRoute
            path="/add-location"
            adminComponent={ManageClinicLocation}
            clinicComponent={ManageClinicLocation}
            exact
          />
          {/* invoices */}
          <PrivateRoute
            path="/invoices"
            adminComponent={Admin.InvoiceList}
            clinicComponent={Clinic.InvoiceList}
            exact
          />
          <PrivateRoute
            path="/invoice/:id"
            adminComponent={Admin.InvoiceDetail}
            clinicComponent={Clinic.InvoiceDetail}
            exact
          />
          {/* prescriptions */}
          <PrivateRoute
            path="/new-prescription/:clinicId?"
            adminComponent={Clinic.AddNewPrescription}
            clinicComponent={Clinic.AddNewPrescription}
            exact
          />
          <PrivateRoute
            path="/review-prescription/:id"
            adminComponent={ReviewPrescription}
            clinicComponent={ReviewPrescription}
            exact
          />
          <PrivateRoute path="/review-prescription/:clinicId/:id" adminComponent={ReviewPrescription} exact />
          <PrivateRoute
            path="/edit-prescription/:id"
            adminComponent={Admin.EditPrescription}
            clinicComponent={Clinic.EditPrescription}
            exact
          />
          <PrivateRoute
            path="/edit-reorder-prescription/:id"
            adminComponent={Admin.EditPrescription}
            clinicComponent={Clinic.EditPrescription}
            exact
          />
          <PrivateRoute
            path="/reorder-prescription/:id"
            adminComponent={Page404}
            clinicComponent={ReviewPrescription}
            exact
          />
          {/* product search */}
          <PrivateRoute path="/product-search" adminComponent={Page404} clinicComponent={Clinic.ProductSearch} exact />
          {/* patients */}
          <PrivateRoute
            path="/patients/:clinicId?"
            adminComponent={PatientsList}
            clinicComponent={PatientsList}
            exact
          />
          <PrivateRoute path="/add-patient" adminComponent={ManagePatient} clinicComponent={ManagePatient} exact />
          <PrivateRoute
            path="/edit-patient/:patientId"
            adminComponent={ManagePatient}
            clinicComponent={ManagePatient}
            exact
          />
          <PrivateRoute
            path="/edit-patient/:clinicId/:id"
            adminComponent={ManagePatient}
            clinicComponent={Page404}
            exact
          />
          <PrivateRoute
            path="/patient-profile/:id"
            adminComponent={Clinic.PatientProfile}
            clinicComponent={Clinic.PatientProfile}
            exact
          />
          {/* products */}
          <PrivateRoute path="/products" adminComponent={Admin.Products} clinicComponent={Page404} exact />
          {/* Patient Portal */}
          {/* Prewcriptions */}
          <PrivateRoute
            path="/prescription"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.PatientPrescriptionHistory}
            exact
          />
          {/* Education */}
          <PrivateRoute
            path="/education/otc"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.OTC}
            exact
          />
          <PrivateRoute
            path="/education/aas"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.AAS}
            exact
          />
          <PrivateRoute
            path="/education/sarms"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.SARMS}
            exact
          />
          <PrivateRoute
            path="/tools/reconstitution-calculator"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.ReconstitutionCalculator}
            exact
          />
          <PrivateRoute
            path="/education/ancillariess"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.Ancillaries}
            exact
          />
          <PrivateRoute
            path="/education/peptides"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.Peptides}
            exact
          />
          <PrivateRoute
            path="/education/rxcompounding"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.RXCompounding}
            exact
          />
          <PrivateRoute
            path="/education/legalcompliance"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.LegalCompliance}
            exact
          />
          {/* Tools */}
          <PrivateRoute
            path="/tools/Analytical-Testing"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.AnalyticalTesting}
            exact
          />
          <PrivateRoute
            path="/tools/Dose-Calculators"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.DoseCalculator}
            exact
          />
          <PrivateRoute
            path="/tools/reconstitution-calculator"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.ReconstitutionCalculator}
            exact
          />
          <PrivateRoute
            path="/tools/Half-Life-Plotter"
            adminComponent={Page404}
            clinicComponent={Page404}
            patientComponent={Patient.HalfLifePlotter}
            exact
          />
          {/* main screens */}
          <PrivateRoute
            path="/profile"
            adminComponent={Admin.Profile}
            clinicComponent={Clinic.ProfileScreen}
            patientComponent={Patient.PatientProfile}
            exact
          />
          <PrivateRoute path="/admin-profile/:id" adminComponent={Admin.AdminProfile} clinicComponent={Page404} exact />
          <PrivateRoute path="/edit-profile/:id" adminComponent={Admin.EditAdmin} clinicComponent={Page404} exact />
          <PrivateRoute
            path="/edit-profile"
            adminComponent={Page404}
            clinicComponent={ManageStaff}
            patientComponent={Patient.EditProfileScreen}
            exact
          />
          <PrivateRoute path="/messages" adminComponent={Admin.Messages} clinicComponent={Clinic.Messages} exact />
          <PrivateRoute
            path="/dashboard"
            adminComponent={Admin.Dashboard}
            clinicComponent={Clinic.Dashboard}
            patientComponent={Patient.Dashboard}
            exact
          />
          <PrivateRoute path="/add-admin" adminComponent={Admin.AddAdmin} clinicComponent={Page404} exact />
          <PrivateRoute path="/admins" adminComponent={Admin.AdminsList} clinicComponent={Page404} exact />
          <PrivateRoute
            path="/"
            adminComponent={Admin.Dashboard}
            clinicComponent={Clinic.Dashboard}
            patientComponent={Patient.Dashboard}
            exact
          />
          <PrivateRoute
            path="/clinic-profile/:id"
            adminComponent={Admin.ClinicProfile}
            clinicComponent={Clinic.ClinicProfile}
            exact
          />
          <PrivateRoute
            path="/edit-clinic/:id"
            adminComponent={Admin.EditClinic}
            clinicComponent={Clinic.EditClinic}
            exact
          />
          {/* states */}
          <PrivateRoute path="/states" adminComponent={Admin.States} clinicComponent={Page404} exact />
          {/*testing routes*/}
          <PrivateRoute
            path="/pending-prescriptions"
            adminComponent={Admin.PrescriptionsSection}
            clinicComponent={Clinic.ClinicPendingPrescription}
            exact
          />
          <PrivateRoute
            path="/prescription-history"
            adminComponent={Admin.PrescriptionsSection}
            clinicComponent={Clinic.ClinicPrescriptionHistory}
            patientComponent={Patient.PatientOrder}
            exact
          />
          <PrivateRoute
            path="/prescription-archive"
            adminComponent={Admin.PrescriptionsSection}
            clinicComponent={Page404}
            exact
          />
          <PrivateRoute path="/clear-cache" adminComponent={ClearCache} clinicComponent={Page404} exact />
          <Route path="*" component={Page404} exact />
        </Switch>
      </CartProvider>
    </Router>
  );
};

export default App;
