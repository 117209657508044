import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import { trim } from "lodash";
import React, { useMemo } from "react";
import { useController } from "react-hook-form";
import images from "react-payment-inputs/images";

function FormCardNumber({
  control,
  name,
  rules,
  getCardField,
  getCardImage,
  MuiInputProps = {},
  MuiFieldProps: { fullWidth = true, label = "", ...restFieldProps } = { fullWidth: true, label: "" },
}) {
  const {
    field: { ref, ...inputField },
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: "" });

  const isRequired = useMemo(() => rules?.required, [rules?.required]);
  const errorMessage = useMemo(() => error?.message || " ", [error?.message]);

  const endAdornment = useMemo(
    () => (
      <InputAdornment position="end">
        <svg {...getCardImage?.({ images })} />
      </InputAdornment>
    ),
    [getCardImage]
  );

  return (
    <FormControl error={Boolean(trim(errorMessage))} fullWidth={fullWidth}>
      <InputLabel htmlFor={label}>
        {label}
        {isRequired && <span className="requiredStar"> *</span>}
      </InputLabel>
      <OutlinedInput
        {...getCardField?.({ ...inputField, refKey: "inputRef", ref: ref })}
        inputRef={getCardField?.().ref}
        fullWidth
        inputProps={{
          ...MuiInputProps,
        }}
        label={`${label} ${isRequired ? "*" : ""}`}
        endAdornment={endAdornment}
        {...restFieldProps}
      />
      <FormHelperText variant="outlined" style={{ fontSize: 10 }}>
        {errorMessage}
      </FormHelperText>
    </FormControl>
  );
}

export default React.memo(FormCardNumber);
