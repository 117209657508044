import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import Lottie from "../../../components/lottie";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";
import AdminNavbar from "../../../components/AdminNavbar";
import AdminRolesDD from "components/dropdowns/adminRoles";
import { addUserToCometChat, getAdminList, makeFriends, getAllStaffIdInAllClinics } from "utils/CometChatFunctions";
import axios from "axios";

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;

const AddAdmin = props => {
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const adminList = useRef([]);
  const staffList = useRef([]);
  const totalPages = useRef([]);

  useEffect(() => {
    getAdminIdList();
    getStaffList();
  }, []);
  function handleValidSubmit(e) {
    e.preventDefault();

    setShowSpinner(true);

    var serialize = require("form-serialize");
    var obj = serialize(document.getElementById("registerForm"), { hash: true });

    axiosConfig
      .post(
        "admin/account/addNewAdmin",
        {
          firstName: obj.firstName,
          middleName: obj.middleName,
          lastName: obj.lastName,
          email: obj.email,
          password: obj.password,
          role: obj.role,
          confirmPassword: obj.confirmPassword,
          acceptTerms: obj.acceptTerms === "on" ? true : false,
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        await addUserToCometChat(obj.firstName + " " + obj.lastName, response.data.id, "VPI", obj.role);

        var friendsList = adminList.current;
        var friendsList1 = staffList.current;

        friendsList.push(...friendsList1);

        var j = 0;
        var chunksListDivisor = parseInt(friendsList.length / 15);
        var withoutRemainderVal = chunksListDivisor * 15;

        var remainder = friendsList.length % 15;
        var k = 0;
        if (friendsList.length % 15 == 0) {
          for (i = 0; i < chunksListDivisor; i++) {
            var chunkFriendsArray = [];
            //Push 15 Length of Array
            for (j = 0; j < 15; j++) {
              chunkFriendsArray.push(friendsList[k]);
              k++;
            }
            await makeFriends(response.data.id, chunkFriendsArray);
          }
        } else {
          for (i = 0; i < chunksListDivisor; i++) {
            var chunkFriendsArray = [];
            //Push 15 Length of Array
            for (j = 0; j < 15; j++) {
              if (k < withoutRemainderVal) {
                chunkFriendsArray.push(friendsList[k]);
              }

              k++;
            }

            await makeFriends(response.data.id, chunkFriendsArray);
          }

          var chunkFriendsArray1 = [];

          for (var i = 0; i < remainder; i++) {
            chunkFriendsArray1.push(friendsList[k]);
            k++;
          }

          await makeFriends(response.data.id, chunkFriendsArray1);
        }
        setShowSpinner(false);
        setComplete(true);
      })
      .catch(error => {
        setError("An error occurred while trying to register new admin!");
        setShowSpinner(false);
        errorResponse(error);
      });
  }
  // Example POST method implementation

  async function getStaffList(limit = 20, pageNo = 1) {
    var staffArray = [];
    var body = {
      limit: limit,
      currentPage: pageNo,
    };
    staffList.current = await getAllStaffIdInAllClinics();
  }
  async function getAdminIdList(sizePerPage = 20, pageNo = 1) {
    adminList.current = await getAdminList();
  }

  return (
    <div>
      <MetaTags>
        <title>Register Admin | VPI Compounding</title>
      </MetaTags>
      <AdminNavbar />
      {showSpinner ? <Spinner /> : null}
      <div className="page-content">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8} xl={8}>
              <Card className="overflow-hidden">
                {complete ? (
                  <div className="m-5 p-5 text-center">
                    <Lottie name="success-check" />
                    <p className="my-5">New admin has been registered</p>
                    <Link to="/admins" className="pinkButton">
                      View All Admins
                    </Link>{" "}
                  </div>
                ) : (
                  <>
                    <div className="p-4 text-center">
                      <h3 className="text-primary m-4 text-dark">Register a new Admin</h3>
                    </div>
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <form
                          id="registerForm"
                          className="form-horizontal px-4"
                          autoComplete="off"
                          onSubmit={e => {
                            e.preventDefault();
                            if (password !== confirmPassword) {
                              setError("Passwords do not match");
                            } else {
                              setError("");
                              handleValidSubmit(e);
                            }
                          }}>
                          {error ? (
                            <Alert color="danger" className="text-center">
                              {error}
                            </Alert>
                          ) : null}
                          <div className="my-0 px-4">
                            <div>
                              <Row>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="firstName" className="form-label">
                                      First name <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="firstName" className="form-control" type="text" required={true} />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="middleName" className="form-label">
                                      Middle name
                                    </Label>
                                    <Input name="middleName" className="form-control" type="text" />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="lastName" className="form-label">
                                      Last name <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="lastName" className="form-control" type="text" required={true} />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="email" className="form-label">
                                      Email <span className="requiredStar">*</span>
                                    </Label>
                                    <Input name="email" className="form-control" type="email" required={true} />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} className="mb-4">
                                  <div className="mt-4">
                                    <AdminRolesDD name="role" />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="password" className="form-label">
                                      Password<span className="requiredStar">*</span>
                                    </Label>
                                    <Input
                                      name="password"
                                      className="form-control"
                                      type="password"
                                      placeholder=""
                                      autoComplete="new-password"
                                      required={true}
                                      onChange={e => setPassword(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6} className="mb-4">
                                  <FormGroup>
                                    <Label htmlFor="confirmPassword" className="form-label">
                                      Confirm Password <span className="requiredStar">*</span>
                                    </Label>
                                    <Input
                                      name="confirmPassword"
                                      className="form-control"
                                      type="password"
                                      placeholder="Retype your password"
                                      required={true}
                                      onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12} className="pb-4" style={{ display: "flex", alignItems: "center" }}>
                                  <div className="pt-4">
                                    <input
                                      name="acceptTerms"
                                      id="acceptTerms"
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={() => {}}
                                    />
                                    <label className="form-check-label mx-2" htmlFor="acceptTerms">
                                      I've read & accept the <b>Terms and Conditions</b>
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <Row className="my-5 text-center pt-3">
                            <button
                              className="btn btn-primary darkBg mt-4 mx-auto"
                              style={{ width: "40%" }}
                              type="submit">
                              Submit
                            </button>
                          </Row>
                        </form>
                      </div>
                    </CardBody>
                  </>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AddAdmin;
