import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import classnames from 'classnames';
import { isEmpty, map, size } from 'lodash';

//Import Product Images
import { productImages } from '../../../assets/images/ancillaries';
import PatientNavbar from '../../../components/patientNavbar';
//Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData, ancillariesProductData } from './data/education';

//Import modal

import DisplayModal from './displayModal';

class ancillaries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: '',
      showModal: false,
      modalData: '',
      nameofproduct: '',
      compareData: [], //change to string if error

      FilterClothes: [
        { id: 1, name: 'T-shirts', link: '#' },
        { id: 2, name: 'Shirts', link: '#' },
        { id: 3, name: 'Jeans', link: '#' },
        { id: 4, name: 'Jackets', link: '#' },
      ],
      products: [],
      activeTab: '1',
      discountData: [],
      filters: {
        discount: [],
        price: { min: 0, max: 500 },
      },
      page: 1,
      totalPage: 5, //replace this with total pages of data
    };
  }

  componentDidMount() {
    const { products, onGetProducts } = this.props;
    this.setState({ products: ancillariesProductData });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { products } = this.props;
    if (isEmpty(prevProps.products) && !isEmpty(products) && size(products) !== size(prevProps.products)) {
      this.setState({ products: ancillariesProductData });
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onUncheckMark = () => {
    this.setState({
      products: ancillariesProductData,
    });
    // setProductList(productsData)
  };

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const { history } = this.props;
    const { discountData, products, page, totalPage } = this.state;
    return (
      <React.Fragment>
        {' '}
        <PatientNavbar />
        <div className='page-content'>
          <MetaTags>
            <title>Education | Ancillaries</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col>
                <Alert color='danger' align='center'>
                  WARNING: THIS IS NOT MEDICAL ADVICE!
                  <br />
                  THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                  <br />
                  DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND DIRECTION OF A MEDICAL PROFESSIONAL.
                </Alert>
              </Col>
            </Row>
            {/* <Breadcrumbs title="Education" breadcrumbItem="ANCILLARIES" /> */}

            <Row>
              <Col lg='9'>
                <Row className='mb-3'>
                  <Col xl='4' sm='6'>
                    <div className='mt-2'>
                      <h5></h5>
                    </div>
                  </Col>
                  <Col lg='8' sm='6'></Col>
                </Row>
                <Row>
                  {!isEmpty(this.state.products) &&
                    this.state.products.map((product, key) => {
                      return product.id < 16 ? (
                        <Col xl='3' md='3' xs='6' key={'_col_' + key}>
                          <div align='center'>
                            <h6 className='text-muted'>{product.name}</h6>
                          </div>
                          <Card className='bg-secondary'>
                            <CardBody>
                              <Link
                                to='#'
                                onClick={() => {
                                  this.setState({
                                    showModal: true,
                                    modalData: product.data,
                                    nameofproduct: product.name,
                                  });
                                }}
                              >
                                <div className='product-img position-relative'>
                                  <img src={productImages[product.image]} alt='' className='img-fluid mx-auto d-block' />
                                </div>
                              </Link>

                              <div className='mt-4 text-center'></div>
                            </CardBody>
                          </Card>
                        </Col>
                      ) : null;
                    })}
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <DisplayModal showModal={this.state.showModal} nameofproduct={this.state.nameofproduct} displayModalData={this.state.modalData} closeModal={this.closeModal.bind(this)} />
      </React.Fragment>
    );
  }
}

ancillaries.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default ancillaries;
