import React, { useMemo } from "react";
import {
  ClinicLocationActionContext,
  ClinicLocationApiActionContext,
  ClinicLocationApiStateContext,
  ClinicLocationFormActionContext,
  ClinicLocationFormSateContext,
  ClinicLocationStateContext,
} from "./ClinicLocationContext";
import { useClinicLocationApi, useClinicLocationProvider } from "./Hook";

function ClinicLocationProvider({ children }) {
  const {
    apiLoading: showSpinner,
    apiError,
    clinicLocationId,
    filterClinicId,
    clinicLocationData,
    isClinicLocationEdit,
    getEditApiParam,
    validateShippingAddress,
  } = useClinicLocationApi();

  const {
    formRef,
    activeStep,
    mutateError: providerError,
    completedStep,
    mutateLoading,
    fileUploading,
    clinicLocationInsertSuccessfully,
    formData,
    goBack,
    goNext,
    progressBarAction,
    uploadFile,
  } = useClinicLocationProvider(getEditApiParam, isClinicLocationEdit);

  const mutateError = useMemo(() => providerError || apiError, [apiError, providerError]);

  const spinnerLoading = useMemo(
    () => mutateLoading || fileUploading || showSpinner,
    [fileUploading, mutateLoading, showSpinner],
  );

  const clinicLocationStateValue = useMemo(
    () => ({
      mutateError,
      showSpinner: spinnerLoading,
      isClinicLocationEdit,
      clinicLocationInsertSuccessfully,
    }),
    [spinnerLoading, clinicLocationInsertSuccessfully, mutateError, isClinicLocationEdit],
  );

  const clinicLocationApiStateValue = useMemo(
    () => ({ clinicLocationId, filterClinicId, clinicLocationData }),
    [clinicLocationData, clinicLocationId, filterClinicId],
  );

  const clinicLocationFormStateValue = useMemo(
    () => ({ completedStep, formRef, activeStep }),
    [activeStep, completedStep, formRef],
  );

  const clinicLocationActionValue = useMemo(() => ({ uploadFile }), [uploadFile]);

  const clinicLocationApiActionValue = useMemo(
    () => ({
      validateShippingAddress,
    }),
    [validateShippingAddress],
  );

  const clinicLocationFormActionValue = useMemo(
    () => ({ goBack, goNext, formData, progressBarAction }),
    [goBack, goNext, formData, progressBarAction],
  );

  return (
    <ClinicLocationStateContext.Provider value={clinicLocationStateValue}>
      <ClinicLocationApiStateContext.Provider value={clinicLocationApiStateValue}>
        <ClinicLocationActionContext.Provider value={clinicLocationActionValue}>
          <ClinicLocationApiActionContext.Provider value={clinicLocationApiActionValue}>
            <ClinicLocationFormActionContext.Provider value={clinicLocationFormActionValue}>
              <ClinicLocationFormSateContext.Provider value={clinicLocationFormStateValue}>
                {children}
              </ClinicLocationFormSateContext.Provider>
            </ClinicLocationFormActionContext.Provider>
          </ClinicLocationApiActionContext.Provider>
        </ClinicLocationActionContext.Provider>
      </ClinicLocationApiStateContext.Provider>
    </ClinicLocationStateContext.Provider>
  );
}

export default React.memo(ClinicLocationProvider);
