/* eslint-disable react-hooks/exhaustive-deps */

import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { MuiTableCollapse, OrderViewModal, PrescriptionUpdateModal } from "components";
import React, { useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useClinicPrescriptionAction, useClinicPrescriptionListing } from "screens/clinic/Hook";
import PrimaryModal from "../../../../components/primaryModal";
import Spinner from "../../../../components/spinner";
import { useDeleteClinicPrescriptionMutation, useLazyGetClinicIncompletePrescriptionQuery } from "../../../../rtk";
import { LISTING_NAME, ROWS_LIMIT, columnHelper } from "../../../../utils";
import AlertMessage from "../components/alert_message";

function ClinicIncompletePrescription() {
  const {
    rowLimit,
    tablePage,
    searchKeyword,
    showLoading,
    prescriptionId,
    errorMessage,
    prescriptions,
    totalRecords,
    invoiceId,
    openUpdateModal,
    INCOMPLETE_TABLE_HEADER,
    refetchList,
    toggleDetailModal,
    handleChangePage,
    toggleUpdateModal,
    applyFilter,
    clearFilter,
    updateKeyword,
    changeRowLimit,
  } = useClinicPrescriptionListing({
    listingQuery: useLazyGetClinicIncompletePrescriptionQuery,
  });

  const { confirmActionModal, mutateLoading, mutateData, mutateError, toggleConfirmationModal, prescriptionAction } =
    useClinicPrescriptionAction({
      listingMutation: useDeleteClinicPrescriptionMutation,
    });

  const showSpinner = useMemo(() => showLoading || mutateLoading, [mutateLoading, showLoading]);
  const successMessage = useMemo(() => mutateData?.message ?? "", [mutateData?.message]);
  const combinedErrorMessage = useMemo(
    () => errorMessage || mutateError?.data?.message || "",
    [errorMessage, mutateError?.data?.message]
  );

  const clinicPatientActions = useMemo(
    () =>
      columnHelper.accessor("actions", {
        header: "Actions",
        headerStyle: { minWidth: "100px", width: "100px", textAlign: "center" },
        cell: (props) => (
          <>
            <Tooltip title="Edit">
              <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
                <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => toggleConfirmationModal(props.row.original.id)}
                style={{ width: 30, height: 30 }}
              >
                <DeleteIcon color="error" style={{ width: 20, height: 20 }} />
              </IconButton>
            </Tooltip>
          </>
        ),
      }),
    [toggleConfirmationModal]
  );

  const clinicPatientTableHeader = useMemo(
    () => [...INCOMPLETE_TABLE_HEADER, clinicPatientActions],
    [clinicPatientActions]
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: clinicPatientTableHeader,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <React.Fragment>
      {showSpinner ? <Spinner /> : null}
      <AlertMessage msg={successMessage} />
      <AlertMessage msg={combinedErrorMessage} isError={true} />
      <Row className="my-4 px-sm-3 px-1">
        <Col xs={6} sm={5} m={1} lg={3} xl={2}>
          <div className="tableSearch d-flex">
            <i className="bx bx-search-alt search-icon flex align-self-center" />
            <input
              className="borderless mx-2"
              onChange={updateKeyword}
              value={searchKeyword}
              placeholder="Search table"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  applyFilter(e);
                }
              }}
            />
          </div>
        </Col>
        <Col xs={4} sm={2} m={9} xl={1}>
          <button disabled={!searchKeyword} className="yellowButton" onClick={applyFilter}>
            Search
          </button>
        </Col>
        {searchKeyword && (
          <Col xs={2} sm={2} lg={1} className=" align-self-center">
            <p
              disabled={!searchKeyword}
              className="blueText"
              style={{
                fontSize: 14,
                cursor: "pointer",
                // marginLeft: 0,
              }}
              onClick={clearFilter}
            >
              Clear
            </p>
          </Col>
        )}
      </Row>
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableCell />
                {headerGroup.headers.map((header) => {
                  return (
                    <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                      <Typography variant="tableHeader" noWrap>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map((row) => (
              <MuiTableCollapse
                key={row.id}
                row={row}
                showColoredRows={false} 
                pageName={LISTING_NAME.CLINIC_PENDING_NEEDED}
                nestedEditable={true}
                toggleUpdateModal={toggleUpdateModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
      <PrimaryModal
        question="Are you sure you want to discard this prescription?"
        showModal={confirmActionModal}
        closeModal={toggleConfirmationModal}
        onClickYes={prescriptionAction}
      />
      <OrderViewModal
        isReview
        listingName={LISTING_NAME.CLINIC_PENDING_NEEDED}
        // Important to hide the Detail view on editing
        prescriptionId={openUpdateModal ? "" : prescriptionId}
        handleClose={toggleDetailModal}
      />
      <PrescriptionUpdateModal
        listingName={LISTING_NAME.CLINIC_PENDING_NEEDED}
        modalTitle={openUpdateModal}
        prescriptionId={invoiceId}
        handleClose={toggleUpdateModal}
      />
    </React.Fragment>
  );
}

export default React.memo(ClinicIncompletePrescription);
