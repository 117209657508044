import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { trim } from "lodash";
import React, { forwardRef } from "react";

const defaultMuiFieldProps = { label: "", fullWidth: true, type: "text", shrink: false };

// type => HTML input type

const FileUploadField = forwardRef(
  ({ isRequired = false, error = "", inputField, MuiFieldProps = defaultMuiFieldProps }, ref) => {
    const { label = "" } = MuiFieldProps;

    return (
      <Box>
        <Typography variant="body2" className="mb-1" style={{ fontSize: 12 }}>
          {label}
          {isRequired && <span className="requiredStar"> *</span>}
        </Typography>

        <Button
          id={label}
          component="label"
          variant="outlined"
          color="secondary"
          className="form-control"
          startIcon={<UploadFileIcon />}
          style={{ textTransform: "capitalize" }}
          href="#file-upload">
          {"Upload File"}
          <input {...inputField} className="d-none" type="file" />
        </Button>
        {trim(error) && (
          <FormHelperText error={Boolean(trim(error))} variant="outlined" style={{ fontSize: 10 }}>
            {error}
          </FormHelperText>
        )}
      </Box>
    );
  },
);

export default React.memo(FileUploadField);
