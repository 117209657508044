import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Row, TabContent, TabPane } from "reactstrap";
import ClinicNavbar from "../../../../components/ClinicNavbar";
import Spinner from "../../../../components/spinner";
import ClinicArchivedPrescription from "./ClinicArchivedPrescription";
import ClinicRxWorkflowPrescription from "./ClinicRxWorkflowPrescription";
import "./index.scss";
import { ClinicPendingApprovalPrescription } from "../ClinicPendingPrescription";

function ClinicPrescriptionHistory() {
  const [error, setError] = useState("");
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(1);
  const [showSpinner, setShowSpinner] = useState(true);

  const setPageError = useCallback((error = "") => {
    setError(error);
  }, []);

  const setPageLoading = useCallback((loading = false) => {
    setShowSpinner(loading);
  }, []);

  useEffect(() => {
    if (history?.location?.state?.activeTab) {
      setActiveTab(history.location.state.activeTab);
    }
  }, []);
  const RenderRXFlow = useMemo(
    () => <ClinicPendingApprovalPrescription filter={history?.location.state?.filter || ""} />,
    []
  );

  const RenderArchived = useMemo(
    () => <ClinicArchivedPrescription setPageError={setPageError} setPageLoading={setPageLoading} />,
    [setPageError, setPageLoading]
  );

  return (
    <React.Fragment>
      <ClinicNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Prescription History | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">ORDER STATUS</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {showSpinner ? <Spinner /> : null}
                  {error ? (
                    <Alert color="danger" className="text-center">
                      {error}
                    </Alert>
                  ) : null}
                  <React.Fragment>
                    <Row className={`px-3 my-4 justify-content-center`}>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab(1);
                        }}
                        className={classNames("mx-2 text-center", {
                          blueButton: activeTab === 1,
                          whiteButton: activeTab !== 1,
                        })}
                        style={{ width: 200 }}
                      >
                        Order in Process
                      </Link>
                      <Link
                        to="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab(2);
                        }}
                        className={classNames("mx-2 text-center", {
                          blueButton: activeTab === 2,
                          whiteButton: activeTab !== 2,
                        })}
                        style={{ width: 200 }}
                      >
                        Completed Orders
                      </Link>
                    </Row>
                  </React.Fragment>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1}>{RenderRXFlow}</TabPane>
                    <TabPane tabId={2}>{RenderArchived}</TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(ClinicPrescriptionHistory);
