import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";

const AlertMessage = props => {
  const { msg = "", isError = false } = props;
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setSuccess(Boolean(msg));
  }, [msg]);

  if (!success) {
    return <div className="mb-3" style={{ height: 45 }} />;
  }

  return (
    <Alert
      color={isError ? "danger" : "success"}
      className="text-center"
      isOpen={success ? true : false}
      toggle={() => setSuccess(false)}>
      {msg}
    </Alert>
  );
};
export default AlertMessage;
