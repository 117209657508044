import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse, PrescriptionOrderButton } from "components";
import Spinner from "components/spinner";
import { filter, includes } from "lodash";
import React, { useCallback, useMemo, useRef } from "react";
import MetaTags from "react-meta-tags";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useDeletePatientMutation, useLazyGetPatientsListQuery } from "rtk";
import AlertMessage from "screens/clinic/Prescriptions/components/alert_message";
import HeaderInvoiceCheckBox from "screens/common/Invoices/InvoiceList/HeaderInvoiceCheckBox";
import InvoiceCheckBox from "screens/common/Invoices/InvoiceList/InvoiceCheckBox";
import { userRoleSelector } from "selectors";
import { ADMIN_PATIENT_COLUMNS, PATIENT_FILTER_OPTIONS, ROWS_LIMIT, USER_ROLES, columnHelper } from "utils";
import { usePatientAction, usePatientListing } from "./Hook";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NavBar from "components/NavBar";

function PatientsList() {
  const history = useHistory();
  const uploadButtonRef = useRef(null);
  const userRole = useSelector(userRoleSelector);
  const {
    clinicId,
    sorting,
    rowLimit,
    tablePage,
    showSpinner,
    rowSelection,
    errorMessage,
    totalRecords,
    isClinicSide,
    patientListing,
    selectedFilter,
    searchKeyword,
    isPrescription,
    selectedPatient,
    clinicLocationId,
    setSorting,
    applyFilter,
    clearFilter,
    refetchList,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    updateFilterType,
    setSelectedPatient,
    handleChangePage,
  } = usePatientListing({
    listingQuery: useLazyGetPatientsListQuery,
  });

  const { mutateError, mutateLoading, successMessage, fileUploading, exportPatientList, toggleConfirmationModal } =
    usePatientAction({
      listingName: "PatientListing",
      listingMutation: useDeletePatientMutation,
    });

  const combineLoading = useMemo(() => showSpinner || mutateLoading, [mutateLoading, showSpinner]);
  const combinedErrorMessage = useMemo(() => errorMessage || mutateError || "", [errorMessage, mutateError]);

  const deletionAllowed = useMemo(
    () =>
      includes(
        [
          USER_ROLES.ADMIN_SUPER_USER,
          USER_ROLES.PHARMACIST,
          USER_ROLES.RX_TECH,
          USER_ROLES.CLINIC_SUPER_USER,
          USER_ROLES.CLINIC_SUPPORT,
          USER_ROLES.CLINIC_PROVIDER,
        ],
        userRole,
      ),
    [userRole],
  );

  const onRowSelection = useCallback(
    (rowData, isSelected) =>
      setSelectedPatient(prev => (isSelected ? filter(prev, x => x.id !== rowData.id) : [...prev, rowData])),
    [],
  );

  const onAllRowSelection = useCallback(
    isSelected => setSelectedPatient(isSelected ? [] : patientListing),
    [patientListing],
  );

  const actonButtonCell = useCallback(
    rowData => {
      return (
        <Box component={"span"} className="d-flex justify-content-end">
          {isClinicSide && userRole !== USER_ROLES.CLINIC_SUPPORT && rowData.row.original?.lastPrescriptionId && (
            <Link
              to={{
                pathname: `/reorder-prescription/` + rowData.row.original?.lastPrescriptionId,
                state: { id: rowData.row.original?.id, isReview: true },
              }}
              className="yellowButton text-nowrap">
              Reorder Prescription
            </Link>
          )}
          <Link to={"/patient-profile/" + rowData.row.original.id} className="pinkButton mx-2">
            Profile
          </Link>
          {!isPrescription && (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={() =>
                    history.push({
                      pathname: `/edit-patient/${rowData.row.original.id}`,
                      state: { clinicId, clinicLocationId },
                    })
                  }
                  style={{ width: 30, height: 30 }}>
                  <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                </IconButton>
              </Tooltip>
              {deletionAllowed && (
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => toggleConfirmationModal(rowData.row.original.id)}
                    style={{ width: 30, height: 30 }}>
                    <DeleteIcon color="error" style={{ width: 25, height: 25 }} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      );
    },
    [isClinicSide, userRole, isPrescription, deletionAllowed, history, toggleConfirmationModal],
  );

  const checkBox = useMemo(() => {
    if (isClinicSide || isPrescription) {
      return columnHelper.accessor("checkbox", {
        headerStyle: { width: 40, paddingLeft: 0, paddingRight: 0 },
        enableSorting: false,
        header: ({ table }) => (
          <HeaderInvoiceCheckBox tableRow={table} onAllRowSelection={onAllRowSelection} isSelectableAll />
        ),
        cell: ({ row }) => <InvoiceCheckBox rowData={row.original} tableRow={row} onRowSelection={onRowSelection} />,
      });
    }
    return null;
  }, [isClinicSide, isPrescription, onAllRowSelection, onRowSelection]);

  const columnAction = useMemo(
    () =>
      columnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        headerStyle: { minWidth: "180px", textAlign: "center" },
        cell: actonButtonCell,
      }),
    [actonButtonCell],
  );

  const clinicPatientTableHeader = useMemo(
    () => [checkBox, ...ADMIN_PATIENT_COLUMNS, columnAction].filter(Boolean),
    [checkBox, columnAction],
  );

  const reactTable = useReactTable({
    data: patientListing,
    columns: clinicPatientTableHeader,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: row => row.id,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableRowSelection: (isClinicSide || isPrescription) && Boolean(patientListing.length),
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
      rowSelection,
    },
  });

  const renderHeaderCell = useCallback(header => {
    const columnSortDirection = header.column.getIsSorted();
    const isCheckBoxCell = header.id === "checkbox";

    const cellView = (
      <Typography key={`label-${header.id}`} variant="tableHeader" noWrap>
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </Typography>
    );
    let headerCellView = cellView;
    if (!isCheckBoxCell) {
      headerCellView = (
        <TableSortLabel
          key={`Sort-${header.id}`}
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() => header.column.toggleSorting(columnSortDirection !== "desc")}>
          {cellView}
        </TableSortLabel>
      );
    }

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={header.column.getIsSorted()}>
        {headerCellView}
      </TableCell>
    );
  }, []);

  const tableHeaderButton = useCallback(() => {
    const buttonArray = [];
    if (isClinicSide) {
      buttonArray.push(
        <button
          key={"upload_btn"}
          className="pinkButton"
          onClick={e => {
            e.preventDefault();
            uploadButtonRef.current.click();
          }}>
          Upload File
        </button>,
      );
      buttonArray.push(
        <input
          key={"upload_input"}
          ref={uploadButtonRef}
          accept=".csv"
          onChange={fileUploading}
          name="patient_file"
          className="d-none "
          type="file"
          id="patientFile"
        />,
      );

      buttonArray.push(
        <button key={"export_btn"} className="pinkButton m-1" onClick={exportPatientList}>
          Export CSV
        </button>,
      );
    }
    if (!isPrescription) {
      buttonArray.push(
        <Link
          key={"link_add_btn"}
          to={{
            pathname: `/add-patient`,
            state: {
              clinicId,
              clinicLocationId,
            },
          }}
          className="pinkButton">
          Add patient
        </Link>,
      );
    }
    return buttonArray;
  }, [clinicId, clinicLocationId, exportPatientList, fileUploading, isClinicSide, isPrescription]);

  const tableHeaderView = useMemo(() => {
    if (clinicId || isClinicSide) {
      return (
        <Grid container className={`px-3 my-4  justify-content-between`}>
          <Grid item xs={4}>
            <PrescriptionOrderButton
              clinicId={clinicId || isClinicSide}
              showView={isPrescription || isClinicSide}
              clinicLocationId={clinicLocationId}
              selectedPatients={selectedPatient}
            />
          </Grid>
          <Grid item className="justify-content-end">
            {tableHeaderButton()}
          </Grid>
        </Grid>
      );
    }
  }, [clinicId, isClinicSide, isPrescription, clinicLocationId, selectedPatient, tableHeaderButton]);

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Patients | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <Button color="info" onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <h4 className="p-3 text-muted">PATIENTS</h4>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {combineLoading && <Spinner />}
                  <AlertMessage msg={combinedErrorMessage || successMessage} isError={combinedErrorMessage} />
                  {tableHeaderView}
                  <ListingSearch
                    searchKeyword={searchKeyword}
                    selectedFilter={selectedFilter}
                    applySearch={applyFilter}
                    clearFilter={clearFilter}
                    updateKeyword={updateKeyword}
                    updateFilterType={updateFilterType}
                    filterOptions={PATIENT_FILTER_OPTIONS}
                  />
                  <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }} className="mb-1">
                    <Tooltip title="Refresh">
                      <Button variant="contained" onClick={refetchList}>
                        <RefreshIcon style={{ width: 20, height: 20 }} />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <TableContainer style={{ maxHeight: "70vh" }}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        {reactTable.getHeaderGroups().map(headerGroup => (
                          <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map(header => renderHeaderCell(header))}
                          </TableRow>
                        ))}
                      </TableHead>
                      <TableBody>
                        {reactTable.getRowModel().rows.map(row => (
                          <MuiTableCollapse
                            key={row.id}
                            showNested={false}
                            readOnly={true}
                            row={row}
                            showColoredRows={false}
                            showMarkAllBtn={!row.getCanSelect()}
                            isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
                          />
                        ))}
                      </TableBody>
                    </Table>
                    {patientListing?.length === 0 && !combineLoading ? (
                      <p className="w-100 text-center m-5">No patients data found!</p>
                    ) : null}
                  </TableContainer>
                  <TablePagination
                    component="div"
                    showFirstButton
                    showLastButton
                    page={tablePage}
                    count={totalRecords}
                    rowsPerPage={rowLimit}
                    rowsPerPageOptions={ROWS_LIMIT}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={changeRowLimit}
                    style={{ alignItems: "center", marginBottom: 0 }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default PatientsList;
