import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const FedexApi = createApi({
  reducerPath: "FedexApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [CACHE_TAGS.FEDEX],
});

export default FedexApi;
