import { CometChat } from "@cometchat-pro/chat";
import { includes } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { userInfoSelector, userRoleSelector } from "selectors";
import { SHOW_INVOICE_TAB } from "utils";
import VPICompoundingLogo from "../assets/vpi-images/vpi-logo-white.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "../assets/vpi-styles/loggedInNavbar.css";
import { CartContext } from "context/Cart";
import { useLocation } from "react-router-dom";

function AdminNavbar() {
  const userData = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const [messageCount, setMessageCount] = useState("");
  const { toggleCartDrawer } = useContext(CartContext);
  const { pathname } = useLocation();

  const showCartTab = useMemo(() => {
    const pathArray = pathname?.split("/");
    return !(pathArray.includes("new-prescription") || pathArray.includes("edit-prescription"));
  }, [pathname]);

  const showInvoiceTab = useMemo(() => includes(SHOW_INVOICE_TAB, userRole), [userRole]);

  useEffect(() => {
    if (userData) {
      CometChat.getLoggedinUser().then(user => {
        if (user) {
          CometChat.getUnreadMessageCount().then(
            array => {
              setMessageCount(Object.keys(array.users).length ? Object.keys(array.users).length : "");
            },
            error => {
              //console.log("Error in getting message count", error);
            },
          );
        } else {
          console.log("CometChat user not login");
        }
      });
    }
  }, [userData]);

  return (
    <Navbar collapseOnSelect expand="md" variant="dark" className="navbar">
      <Navbar.Brand id="navbarBrand" href="/" className="navbarBrand">
        <img src={VPICompoundingLogo} alt="logo" className="navLogo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-content" className="navbarToggle">
        <i className="navbarToggleIcon fa fa-fw fa-bars" />
      </Navbar.Toggle>
      <Navbar.Collapse id="navbar-content" className="navbarCollapse">
        <Nav className="ml-auto navbarContent">
          {/* TODO: Add links */}
          {/* HOME */}
          <LinkContainer
            className="navLink"
            to="/"
            activeClassName={window.location.pathname === "/" ? "activeLink" : ""}>
            <Nav.Link>Home</Nav.Link>
          </LinkContainer>
          {/* CLINICS */}
          <LinkContainer className="navLink" to="/clinics" activeClassName="activeLink">
            <Nav.Link>Clinics</Nav.Link>
          </LinkContainer>
          {/* PRESCRIPTIONS */}
          <LinkContainer className="navLink" to="/pending-prescriptions" activeClassName="activeLink">
            <Nav.Link>Prescriptions</Nav.Link>
          </LinkContainer>
          {/* PAITENTS */}
          <LinkContainer
            className="navLink"
            to="/patients"
            activeClassName={
              window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1) === "patients"
                ? "activeLink"
                : ""
            }>
            <Nav.Link>Patients</Nav.Link>
          </LinkContainer>
          {/* INVOICES */}
          {showInvoiceTab && (
            <LinkContainer className="navLink" to="/invoices" activeClassName="activeLink">
              <Nav.Link>Invoices</Nav.Link>
            </LinkContainer>
          )}
          {/* PRODUCTS */}
          <LinkContainer className="navLink" to="/products" activeClassName="activeLink">
            <Nav.Link>Products</Nav.Link>
          </LinkContainer>
          <LinkContainer className="navLink" to="/messages" activeClassName="activeLink">
            <Nav.Link>
              <i className="fas fa-envelope"></i>
              <span
                style={{
                  color: "red",
                  fontSize: "15px",
                  fontWeight: "800px",
                  marginTop: "-5px",
                  padding: "0px",
                  position: "absolute",
                }}>
                <strong>{messageCount}</strong>
              </span>
            </Nav.Link>
          </LinkContainer>
          {showCartTab && (
            <Nav.Link onClick={toggleCartDrawer}>
              <ShoppingCartIcon style={{ fontSize: 14 }} />
            </Nav.Link>
          )}

          <NavDropdown
            title={
              <p className="m-0">
                <i className="navUserCircle fas fa-user-circle"></i> {`${userData?.firstName} ${userData?.lastName}`}{" "}
                <i className="mx-1 fas fa-angle-down"></i>
              </p>
            }
            id="nav-dropdown"
            className="navDD navLink profileDD">
            <LinkContainer className="navLink navDDLink" to="/profile" activeClassName="activeLinkDark">
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer className="navLink navDDLink" to="/admins" activeClassName="activeLinkDark">
              <Nav.Link>Admins</Nav.Link>
            </LinkContainer>
            <NavDropdown.Divider />
            <LinkContainer className="navLink navDDLink" to="/states" activeClassName="activeLinkDark">
              <Nav.Link>States Eligibility</Nav.Link>
            </LinkContainer>
            <NavDropdown.Divider />

            <LinkContainer className="navLink navDDLink" to="/logout" activeClassName="activeLink">
              <Nav.Link>
                <i className="mx-1 logoutIcon fas fa-sign-out-alt"></i> Logout
              </Nav.Link>
            </LinkContainer>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default React.memo(AdminNavbar);
