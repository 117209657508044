import axiosConfig, { errorResponse } from "./utils/axiosConfig";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;
const cometChatApiKey = process.env.REACT_APP_COMET_CHAT_API_KEY;
const AddAdminListToChat = (props) => {
  const adminList = useRef([]);
  const staffList = useRef([]);
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    getAdminList();
    getStaffList();
  }, []);

  function handleValidSubmit(e) {
    oldAdminsToChat(admins);
  }

  async function oldAdminsToChat(admins) {
    for (var i = 0; i < admins.length; i++) {
      // console.log('----');
      // console.log(admins[i].id);
      // console.log(admins[i].firstName + ' ' + admins[i].lastName);
      // console.log('----');
      await addUserToCometChat(admins[i].firstName + " " + admins[i].lastName, admins[i].id, "VPI", admins[i].role);
      var friendsList = adminList.current;
      var friendsList1 = staffList.current;
      friendsList.push(...friendsList1);
      await addFriendsByChunking(friendsList, admins[i]);
    }
  }
  function deleteAllAdmins() {
    for (var i = 0; i < adminList.current.length; i++) {
      if (adminList.current[i] != "615d4af290ba341f7a50965f") {
        deleteUserFromCometChat(adminList.current[i]);
      }
    }
  }
  function deleteAllStaff() {
    for (var i = 0; i < staffList.current.length; i++) {
      deleteUserFromCometChat(staffList.current[i]);
    }
  }

  async function deleteUserFromCometChat(uid) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}`;
    const bodyVal = {
      permanent: true,
    };

    await axios
      .delete(url, {
        headers: {
          apiKey: cometChatApiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: bodyVal,
      })
      .then(async (response) => {})
      .catch((error) => {});
  }

  async function addFriendsByChunking(friendsList, admin) {
    var j = 0;
    var chunksListDivisor = parseInt(friendsList.length / 15);
    var withoutRemainderVal = chunksListDivisor * 15;
    var remainder = friendsList.length % 15;
    var k = 0;
    if (friendsList.length % 15 == 0) {
      for (i = 0; i < chunksListDivisor; i++) {
        var chunkFriendsArray = [];
        for (j = 0; j < 15; j++) {
          chunkFriendsArray.push(friendsList[k]);
          k++;
        }
        await makeFriends(admin.id, chunkFriendsArray);
      }
    } else {
      for (i = 0; i < chunksListDivisor; i++) {
        var chunkFriendsArray = [];
        //Push 15 Length of Array
        for (j = 0; j < 15; j++) {
          if (k < withoutRemainderVal) {
            chunkFriendsArray.push(friendsList[k]);
          }

          k++;
        }

        await makeFriends(admin.id, chunkFriendsArray);
      }

      var chunkFriendsArray1 = [];

      for (var i = 0; i < remainder; i++) {
        chunkFriendsArray1.push(friendsList[k]);
      }

      await makeFriends(admin.id, chunkFriendsArray1);
    }
  }

  // COMETCHAT ADD ADMIN COMPLETE APIS FUNCTIONS
  async function addUserToCometChat(name, uid, clinicName, role) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users`;
    const bodyVal = {
      uid: uid,
      name: name,
      role: role.toLowerCase(),

      metadata: {
        businessName: clinicName,
        role: role,
      },
    };
    await axios
      .post(url, bodyVal, {
        headers: {
          apiKey: cometChatApiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(async (response) => {})
      .catch((error) => {
        //setError(error.response.data.message);
        //setShowSpinner(false);
      });
  }

  async function getStaffList(limit = 10, pageNo = 1) {
    var staffArray = [];
    var body = {
      limit: limit,
      currentPage: pageNo,
    };
    await axiosConfig
      .post("staffs/getAllStaffIdsInAllClinic", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        /*Loop*/

        for (var i = 0; i < response.data.pagination.totalPages; i++) {
          body = {
            limit: 10,
            currentPage: i + 1,
          };
          await axiosConfig
            .post("staffs/getAllStaffIdsInAllClinic", body, {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            })
            .then(async (response) => {
              await response.data.staff.forEach((staff) => {
                staffArray.push(staff.id);
              });
            })
            .catch((error) => {
              //setError('An error occurred while trying to fetch admins list!');
              errorResponse(error);
            });
        }
        /*End Loop*/
        staffList.current = staffArray;
      })
      .catch((error) => {
        //setError('An error occurred while trying to fetch clinic locations!');
        //setShowSpinner(false);
        errorResponse(error);
      });
  }
  async function getAdminList(sizePerPage = 10, pageNo = 1) {
    var body = {
      limit: sizePerPage,
      currentPage: pageNo,
    };
    console.log(body);
    var adminArray = [];
    var adminFullData = [];
    await axiosConfig
      .post("admin/account/getAllAdminIds", body, {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async (response) => {
        /*Loop*/

        for (var i = 0; i < response.data.pagination.totalPages; i++) {
          body = {
            limit: 10,
            currentPage: i + 1,
          };
          await axiosConfig
            .post("admin/account/getAllAdminIds", body, {
              headers: {
                Authorization: "Bearer " + new Cookies().get("user_token"),
              },
            })
            .then(async (response) => {
              await response.data.admins.forEach((admin) => {
                adminArray.push(admin.id);
                adminFullData.push(admin);
              });
            })
            .catch((error) => {
              errorResponse(error);
            });
        }
        setAdmins(adminFullData);
        adminList.current = adminArray;

        /*End Loop*/
      })
      .catch((error) => {
        //setError('An error occurred while trying to fetch admins list!');
        errorResponse(error);
      });
  }

  async function makeFriends(uid, friendsArray) {
    const url = `https://${cometChatAppId}.api-${cometChatRegion}.cometchat.io/v3/users/${uid}/friends`;
    const bodyVal = {
      accepted: friendsArray,
    };
    await axios
      .post(url, bodyVal, {
        headers: {
          apiKey: cometChatApiKey,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(async (response) => {})
      .catch((error) => {
        //setError(error.response.data.message);
      });
  }
  return (
    <div>
      <button onClick={handleValidSubmit}>Add All Admins To CometChat</button>

      <button onClick={deleteAllStaff}>Delete All Staff</button>
      <button onClick={deleteAllAdmins}>Delete All Admins</button>
    </div>
  );
};

export default AddAdminListToChat;
