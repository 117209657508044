import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from 'reactstrap';
import Lottie from '../../../components/lottie';
import axiosConfig, { errorResponse } from '../../../utils/axiosConfig';
import Spinner from '../../../components/spinner';
import Cookies from 'universal-cookie';
import AdminNavbar from '../../../components/AdminNavbar';
import { CometChatUI, CometChatConversationList, CometChatConversationListWithMessages, CometChatUserList, CometChatUserListWithMessages, CometChatGroupList, CometChatGroupListWithMessages, CometChatMessages } from '../../../../src/cometchat-pro-react-ui-kit-master/CometChatWorkspace/src';
export default function Messages(props) {
  const [clinic, setClinic] = useState({});
  const [complete, setComplete] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {}, []);

  return (
    <div>
      <MetaTags>
        <title>Chat | VPI Compounding</title>
      </MetaTags>
      <AdminNavbar />
      {showSpinner ? <Spinner /> : null}
      <div className='page-content'>
        <Container style={{ height: '500px' }}>
          <CometChatUI />
        </Container>
      </div>
    </div>
  );
}
