import { Box } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner } from "reactstrap";
import { useAdminUpdateDiscountPriceMutation } from "rtk";

const RenderDiscountInput = ({ clinicId, productId, discountedPrice, setCustomError }) => {
  const [discountInput, setDiscountInput] = useState(discountedPrice ?? "");
  const [showSave, setShowSave] = useState(false);

  const [
    updateDiscountPrice,
    { error: DiscountPriceError, isSuccess: discountPriceSuccess, isLoading: discountPriceLoading },
  ] = useAdminUpdateDiscountPriceMutation();

  const errorMessage = useMemo(() => DiscountPriceError?.data?.message || "", [DiscountPriceError?.data?.message]);

  useEffect(() => {
    setDiscountInput(discountedPrice);
  }, [discountedPrice]);

  useEffect(() => {
    if (discountPriceSuccess) {
      setShowSave(false);
    } else if (errorMessage) {
      setCustomError(errorMessage);
    }
  }, [errorMessage, discountPriceSuccess, setCustomError]);

  const checkInput = useCallback(
    () => setShowSave(discountInput !== discountedPrice),
    [discountedPrice, discountInput],
  );

  const updateAction = useCallback(() => {
    if (discountInput !== discountedPrice) {
      updateDiscountPrice({
        clinicId,
        productId,
        discountedPrice: discountInput,
      });
    }
  }, [discountInput, discountedPrice, updateDiscountPrice, clinicId, productId]);

  const renderSaveBtn = useMemo(() => {
    if (showSave) {
      return (
        <button
          disabled={discountPriceLoading}
          className="mx-1 "
          style={{ background: "#2C4B76", color: "white", cursor: "pointer" }}
          onClick={updateAction}>
          {discountPriceLoading ? (
            <Spinner size="sm" style={{ width: "0.82rem", height: "0.82rem" }}>
              ...
            </Spinner>
          ) : (
            <i className="fa fa-check" />
          )}
        </button>
      );
    } else {
      return <span style={{ width: "2.1rem" }} />;
    }
  }, [discountPriceLoading, showSave, updateAction]);

  return (
    <Box display={"flex"} flexDirection={"row"}>
      <input
        type="text"
        placeholder="Enter Discount Price"
        value={discountInput}
        style={{ width: 60, borderColor: errorMessage ? "red" : "" }}
        onBlur={checkInput}
        onChange={e => {
          setDiscountInput(e.target.value);
          if (!showSave) {
            setShowSave(true);
          }
        }}
      />
      {renderSaveBtn}
    </Box>
  );
};
export default React.memo(RenderDiscountInput);
