import { find, findKey, includes } from "lodash";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetClinicLocationsQuery,
  useGetPatientsDetailQuery,
  useLazyGetClinicLocationAddressQuery,
  useLazyGetPhysicianQuery,
  useLazyValidateAddressQuery,
  useShippingMethodQuery,
} from "rtk";
import { userInfoSelector } from "selectors";
import { SHIP_METHODS } from "utils/StatusConstant";

function usePatientApi() {
  const { pathname, state: { clinicId: clinicParam } = {} } = useLocation();
  const { patientId = "" } = useParams();
  const userInfo = useSelector(userInfoSelector);
  const isPatientEdit = useMemo(() => pathname.includes("edit-patient"), [pathname]);

  const {
    data: patientData,
    error: patientError,
    isFetching: patientFetching,
  } = useGetPatientsDetailQuery({ patientId }, { skip: !isPatientEdit });

  const filterClinicId = useMemo(
    () => clinicParam || userInfo?.clinicId || patientData?.clinicId || "",
    [clinicParam, patientData?.clinicId, userInfo?.clinicId],
  );

  const {
    data: clinicLocationData,
    isFetching: clinicFetching,
    error: clinicError,
  } = useGetClinicLocationsQuery({ clinicId: filterClinicId }, { skip: !filterClinicId });
  const [physicianRefetch, { data: physicianData, isFetching: physicianFetching, error: physicianError }] =
    useLazyGetPhysicianQuery();

  const [clinicAddressRefetch, { error: addressError, isFetching: addressFetching }] =
    useLazyGetClinicLocationAddressQuery();

  const [validateShippingAddress, { error: validateAddressError, isFetching: validateAddressFetching }] =
    useLazyValidateAddressQuery();

  const {
    data: shippingMethods,
    error: shippingError,
    isFetching: shippingFetching,
  } = useShippingMethodQuery(null, { skip: (physicianData || [])?.length <= 0 });

  const clinicLocationOption = useMemo(() => {
    if (clinicFetching) {
      return [
        {
          value: "",
          label: "Loading",
          disabled: true,
        },
      ];
    }
    return clinicLocationData?.clinicLocations?.map(location => ({
      value: location?.id,
      label: location?.locationName ?? "",
    }));
  }, [clinicFetching, clinicLocationData?.clinicLocations]);

  const apiLoading = useMemo(
    () => addressFetching || patientFetching || validateAddressFetching,
    [addressFetching, patientFetching, validateAddressFetching],
  );

  const apiError = useMemo(
    () =>
      clinicError?.data?.message ||
      physicianError?.data?.message ||
      addressError?.data?.message ||
      patientError?.data?.message ||
      shippingError?.data?.message ||
      validateAddressError?.data?.message ||
      "",
    [
      addressError?.data?.message,
      clinicError?.data?.message,
      patientError?.data?.message,
      physicianError?.data?.message,
      shippingError?.data?.message,
      validateAddressError?.data?.message,
    ],
  );

  const findShippingMethod = useCallback(
    methodName => findKey(shippingMethods, method => includes(method, methodName)),
    [shippingMethods],
  );

  const getShippingMethodOption = useCallback(
    method => {
      if (shippingFetching) {
        return [
          {
            value: "",
            label: "Loading",
            disabled: true,
          },
        ];
      } else if (shippingMethods?.shippingMethods?.length < 1) {
        return [
          {
            value: "",
            label: "No data found",
            disabled: true,
          },
        ];
      }
      return shippingMethods?.[method]?.map(shipping => find(SHIP_METHODS, { value: shipping }));
    },
    [shippingFetching, shippingMethods],
  );

  const fetchPhysician = useCallback(
    (clinicId = filterClinicId, isPatientLogin = false) => {
      physicianRefetch({ clinicId, isPatientLogin }, true);
    },
    [filterClinicId, physicianRefetch],
  );

  const getEditApiParam = useCallback(() => {
    const apiParam = { clinicId: filterClinicId || "" };
    if (patientData?.id) {
      apiParam["patientId"] = patientData?.id;
    }
    return apiParam;
  }, [filterClinicId, patientData?.id]);

  return {
    apiError,
    apiLoading,
    patientData,
    isPatientEdit,
    physicianData,
    clinicFetching,
    physicianFetching,
    clinicLocationOption,
    fetchPhysician,
    getEditApiParam,
    findShippingMethod,
    clinicAddressRefetch,
    getShippingMethodOption,
    validateShippingAddress,
  };
}

export default usePatientApi;
