import React, { useMemo } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../dropdowns/stateDD";
import ReCAPTCHA from "react-google-recaptcha";
import { SignaturePad } from "components/SignaturePad";
import CreditCardInput from "react-credit-card-input";
import { encrypt, decrypt } from "cryptofunc";
import StepTwo from "./StepTwo";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
const ClinicFormInfo = ({
  step,
  onlyOneUser,
  clinicAddress,
  onChange,
  setOnlyOneUser,
  upload,
  setClinicAddress,
  signatureImageUrl,
  signatureModal,
  closeSignatureModal,
  onClickSave,
  setSignatureModal,
  adminSide = false,
  billingRepSignature,
  billingSignModal,
  setBillingSignModal,
  deaAddressInfo,
  setDeaAddressInfo,
  vpiInfo,
  setVpiInfo,
  companyInfo,
  setCompanyInfo,
  handleSubmit,
  companyInfoRequired,
  vpiInfoRequired,
  setBaaFormPDFUrl,
}) => {
  return (
    <div className="my-5 px-4">
      <StepOne
        step={step}
        onlyOneUser={onlyOneUser}
        clinicAddress={clinicAddress}
        onChange={onChange}
        setOnlyOneUser={setOnlyOneUser}
        upload={upload}
        setClinicAddress={setClinicAddress}
        signatureImageUrl={signatureImageUrl}
        signatureModal={signatureModal}
        closeSignatureModal={closeSignatureModal}
        onClickSave={onClickSave}
        setSignatureModal={setSignatureModal}
        adminSide={adminSide}
        setDeaAddressInfo={setDeaAddressInfo}
        deaAddressInfo={deaAddressInfo}
      />
      {/* Step 1 Ends*/}
      {/* Step 2 Begins*/}
      <div className={`mt-3 ${step === 2 ? "d-block" : "d-none"}`}>
        <StepTwo
          step={step}
          onlyOneUser={onlyOneUser}
          clinicAddress={clinicAddress}
          onChange={onChange}
          setOnlyOneUser={setOnlyOneUser}
          upload={upload}
          setClinicAddress={setClinicAddress}
          signatureImageUrl={signatureImageUrl}
          signatureModal={signatureModal}
          closeSignatureModal={closeSignatureModal}
          onClickSave={onClickSave}
          setSignatureModal={setSignatureModal}
          adminSide={adminSide}
          billingRepSignature={billingRepSignature}
          billingSignModal={billingSignModal}
          setBillingSignModal={setBillingSignModal}
          deaAddressInfo={deaAddressInfo}
          setDeaAddressInfo={setDeaAddressInfo}
        />
      </div>
      {/* Step 2 Ends*/}
      {/* Step 3 Starts*/}
      <div className={`mt-3 ${step === 3 ? "d-block" : "d-none"}`}>
        <StepThree
          vpiInfo={vpiInfo}
          setVpiInfo={setVpiInfo}
          companyInfo={companyInfo}
          setCompanyInfo={setCompanyInfo}
          handleSubmit={handleSubmit}
          companyInfoRequired={companyInfoRequired}
          vpiInfoRequired={vpiInfoRequired}
          adminSide={adminSide}
          onClickSave={onClickSave}
          setBaaFormPDFUrl={setBaaFormPDFUrl}
          step={step}
        />
        {!adminSide ? (
          <Row>
            <Col md={3}>
              <ReCAPTCHA sitekey={"6LfyhK4gAAAAAH_oGo3hgHhvDY8Lq7wD4ULN4Ro1"} onChange={onChange} />
            </Col>
          </Row>
        ) : null}
      </div>
      {/* Step 3 Ends*/}
    </div>
  );
};

export default ClinicFormInfo;
