import React from 'react';
import MetaTags from 'react-meta-tags';
import { Container } from 'reactstrap';
import Cookies from 'universal-cookie';
import PatientNavbar from '../../../components/patientNavbar';
import '../../../assets/vpi-styles/dashboard.css';
import { GanttChart } from 'components/gantt-chart';
import ShowRefillModal from 'components/showRefilModal';
class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.patientFirstName = React.createRef();
    this.patientLastName = React.createRef();

    this.state = {
      activeTab: '1',
      pendingPrescriptions: '-',
      activePatients: '-',
      currentEducationPage: 'otc',
      selectedProductID: '',
      refillModal: false,
      selectedProductName: '',
      selectedProductFamily: '',
    };
  }

  componentDidMount() {}

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  staff() {
    return (
      <div className='flexJSAC w-100 p-2'>
        <i className='fas fa-user-circle'></i>
        <div>
          <p className='searchResultName'>Staff Name</p>
          <p className='searchResultDate'>01/01/21</p>
        </div>
      </div>
    );
  }

  patient() {
    return (
      <div className='flexJSAC w-100 p-2'>
        <i className='fas fa-user-circle'></i>
        <div>
          <p className='searchResultName'>Patient Name</p>
          <p className='searchResultDate'>01/01/21</p>
        </div>
      </div>
    );
  }

  searchPatient() {
    this.setState({ patientSearchResults: this.patient() });
  }

  activeRefillModal(id, name, fam) {
    this.setState({ selectedProductName: name });
    this.setState({ selectedProductFamily: fam });
    this.setState({ selectedProductID: id });
    this.setState({ refillModal: true });
  }

  closeRefillModal() {
    this.setState({ refillModal: false });
  }

  render() {
    if (this.state.refillModal) {
    }

    return (
      <React.Fragment>
        <PatientNavbar />
        <div className='page-content'>
          <MetaTags>
            <title>Dashboard | VPI Compounding</title>
          </MetaTags>
          <Container fluid>
            <GanttChart displayRefillModal={this.activeRefillModal.bind(this)} />
          </Container>
        </div>
        <div className='mt-2'>
          <ShowRefillModal closeModal={this.closeRefillModal.bind(this)} patientId={new Cookies().get('user_id')} productId={this.state.selectedProductID} productName={this.state.selectedProductName} productFamily={this.state.selectedProductFamily} showRefillModal={this.state.refillModal} />
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
