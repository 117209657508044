import { Tooltip } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import { formatPatientNames } from "utils/HelperFunctions";
import { dateFormatterMountainTime, getCardNumber } from "utils/constants";

const columnHelper = createColumnHelper();

const INVOICE_COLUMNS = [
  columnHelper.accessor("prescriptionDate", {
    header: "Date",
    headerStyle: {
      minWidth: "105px",
      width: "105px",
      textAlign: "center",
      paddingRight: "8px",
      paddingLeft: "8px",
    },
  }),
  columnHelper.accessor("orderReceivedDate", {
    header: "Date Completed",
    cell: props => (props.getValue() ? dateFormatterMountainTime(props.getValue()) : "-"),
    headerStyle: { width: 100, paddingRight: "8px", paddingLeft: "8px" },
  }),

  columnHelper.accessor("cardNumber", {
    header: "Card Number",
    enableSorting: false,
    cell: ({ row }) => getCardNumber(row.original.encryptedBillingInfo),
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("invoiceDueDays", {
    header: "Due Days",
    headerStyle: { width: 100, paddingRight: "8px", paddingLeft: "8px" },
  }),

  columnHelper.accessor("clinicName", {
    header: "Clinic Name",
    headerStyle: { width: "180px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("clinicLocationName", {
    header: "Clinic Location",
    headerStyle: { width: "180px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("patientName", {
    header: "Patient Name",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: props => {
      const patientNames = props.getValue() || "-";
      return patientNames ? (
        <Tooltip title={patientNames}>
          <span>{formatPatientNames(patientNames)}</span>
        </Tooltip>
      ) : null;
    },
  }),
  columnHelper.accessor("totalAmount", {
    header: "Total Amount($)",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: props => props.getValue() || "-",
  }),

  columnHelper.accessor("paidDate", {
    header: "Paid Date",
    headerStyle: {
      minWidth: "105px",
      width: "105px",
      textAlign: "center",
      paddingRight: "8px",
      paddingLeft: "8px",
    },
    cell: props => props.getValue() || "-",
  }),
  columnHelper.accessor("trackingNumber", {
    header: "Tracking Number",
    enableSorting: false,
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: props => props.getValue() || "-",
  }),
];

const CLINIC_APPROVED_PRESCRIPTION_COLUMNS = [
  columnHelper.accessor("created", {
    header: "Date",
    cell: props => dateFormatterMountainTime(props.getValue()),
    headerStyle: { minWidth: "250px" },
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
  }),
  columnHelper.accessor("patientName", {
    header: "Patient Name",
    cell: props => {
      const patientNames = props.getValue() || "-";
      return patientNames ? (
        <Tooltip title={patientNames}>
          <span>{formatPatientNames(patientNames)}</span>
        </Tooltip>
      ) : null;
    },
  }),
  columnHelper.accessor("billTo", {
    header: "Bill to",
    headerStyle: { minWidth: 80 },
  }),
  columnHelper.accessor("shipTo", {
    header: "Delivery Method",
  }),
  columnHelper.accessor("trackingNumber", {
    header: "Tracking Number",
  }),
  columnHelper.accessor("paymentStatus", {
    header: "Payment Status",
    cell: props => {
      const cellValue = props.getValue();
      return (
        <span className={cellValue === "Approved" || cellValue === "Net 30 Approved" || cellValue === "Net 15 Approved" ? "greenText" : "redText"}>
          {cellValue || "-"}
        </span>
      );
    },
  }),
];
const CLINIC_HOLD_PRESCRIPTION_COLUMNS = [
  columnHelper.accessor("created", {
    header: "Date",
    cell: props => dateFormatterMountainTime(props.getValue()),
    headerStyle: { minWidth: "250px" },
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
  }),
  columnHelper.accessor("patientName", {
    header: "Patient Name",
    cell: props => {
      const patientNames = props.getValue() || "-";
      return patientNames ? (
        <Tooltip title={patientNames}>
          <span>{formatPatientNames(patientNames)}</span>
        </Tooltip>
      ) : null;
    },
  }),
  columnHelper.accessor("patientEmail", {
    header: "Patient Email",
  }),
  columnHelper.accessor("patientPhone", {
    header: "Patient Phone",
  }),
];

const NESTED_TABLE_HEADER = [
  { header: "", accessorKey: "unknown", headerStyle: { width: "20px" } },
  {
    header: "Medication",
    accessorKey: "name",
    headerStyle: { width: "350px" },
  },
];

const NESTED_BULK_TABLE_HEADER = [
  { header: "", accessorKey: "unknown", headerStyle: { width: "20px" } },
  {
    header: "Medication",
    accessorKey: "name",
    headerStyle: { width: "350px" },
  },
  {
    header: "Patient",
    accessorKey: "patientName",
    headerStyle: { width: "200px" },
    // cell: (props) => {
    //   const patientNames = props.getValue() || "-";
    //   return (
    //     <>
    //       <Tooltip title={<React.Fragment>{patientNames}</React.Fragment>}>{formatPatientNames(patientNames)}</Tooltip>
    //     </>
    //   );
    // },
  },
];

const NESTED_BULK_INVOICE_TABLE_HEADER = [
  { header: "", accessorKey: "unknown", headerStyle: { width: "20px" } },
  {
    header: "Invoice",
    accessorKey: "invoiceNumber",
    headerStyle: { width: "150px" },
  },

  {
    header: "Medication",
    accessorKey: "name",
    headerStyle: { width: "350px" },
  },
  {
    header: "Patient",
    accessorKey: "patientName",
    headerStyle: { width: "200px" },
    // cell: (props) => {
    //   const patientNames = props.getValue() || "-";
    //   return (
    //     <>
    //       <Tooltip title={<React.Fragment>{patientNames}</React.Fragment>}>{formatPatientNames(patientNames)}</Tooltip>
    //     </>
    //   );
    // },
  },
];

const ADMIN_ARCHIVE_COLUMNS = [
  columnHelper.accessor("clinicLocationName", {
    header: "Business Entity",
    headerStyle: { width: "180px", paddingRight: "8px", paddingLeft: "8px" },
  }),
  columnHelper.accessor("createdByName", {
    header: "Created By",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
  }),

  columnHelper.accessor("patientName", {
    header: "Patient Name",
    headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
    cell: props => {
      const patientNames = props.getValue() || "-";
      return patientNames ? (
        <Tooltip title={patientNames}>
          <span>{formatPatientNames(patientNames)}</span>
        </Tooltip>
      ) : null;
    },
  }),

  columnHelper.accessor("shipTo", {
    header: "Delivery Method",
    headerStyle: { width: "280px", paddingRight: "6px", paddingLeft: "6px" },
  }),
  columnHelper.accessor("totalAmount", {
    header: "Billing Info",
    cell: props => {
      const totalAmount = props.getValue() || "-";
      const paymentStatus = props.row?.original?.paymentStatus || "-";
      const billTo = props.row?.original?.billTo || "-";
      return (
        <>
          <p className="smallText semiBold m-0">
            Total($): <span className="text-muted normalText">{totalAmount}</span>
          </p>
          <p className="smallText semiBold m-0">
            Bill: <span className="text-muted normalText">{billTo}</span>
          </p>
          <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
            Payment: <span className="text-muted normalText">{paymentStatus}</span>
          </p>
        </>
      );
    },
  }),

  columnHelper.accessor("trackingNumber", {
    header: "Shipping Info",
    headerStyle: { paddingRight: "6px", paddingLeft: "6px" },
    cell: props => {
      const trackingNumber = props.getValue() || "-";
      const shippingLabel = props.row?.original?.shippingLabel || "-";
      return (
        <>
          <p className="smallText semiBold m-0" style={{ whiteSpace: "nowrap" }}>
            Tracking: <span className="text-muted normalText">{trackingNumber}</span>
          </p>
          <p className="smallText semiBold m-0">
            Shipping:{" "}
            {shippingLabel == "-" ? (
              shippingLabel
            ) : (
              <a href={shippingLabel} target="_blank" rel="noreferrer" className="medText semiBold">
                Label
              </a>
            )}
          </p>
        </>
      );
    },
  }),
];

const PATIENT_PRESCRIPTION_COLUMNS = [
  columnHelper.accessor("prescriptionDate", {
    header: "Date",
    cell: props => dateFormatterMountainTime(props.getValue()),
    headerStyle: { minWidth: "250px" },
  }),
  {
    header: "Medication",
    accessorKey: "ProductDescription",
    headerStyle: { minWidth: "350px" },
  },
  {
    header: "Instruction",
    accessorKey: "MedicationInstructions",
  },
  columnHelper.accessor("Quantity", {
    header: "Quantity",
  }),
  columnHelper.accessor("DeliveryMethod", {
    header: "Delivery Method",
  }),
  columnHelper.accessor("TrackingInformation", {
    header: "Tracking Number",
  }),
];

export {
  columnHelper,
  INVOICE_COLUMNS,
  NESTED_TABLE_HEADER,
  ADMIN_ARCHIVE_COLUMNS,
  NESTED_BULK_TABLE_HEADER,
  PATIENT_PRESCRIPTION_COLUMNS,
  NESTED_BULK_INVOICE_TABLE_HEADER,
  CLINIC_APPROVED_PRESCRIPTION_COLUMNS,
  CLINIC_HOLD_PRESCRIPTION_COLUMNS,
};
