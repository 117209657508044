import React, { useEffect, useState, useMemo } from "react";
import { Modal, Row, Input, Label } from "reactstrap";
import { useEnable2FAMutation, useSendReset2FALinkMutation } from "rtk/rtkEndpoints/GoogleAuth/GoogleAuthMutation";
import { useLazyGenerate2FAKeyQuery } from "rtk/rtkEndpoints/GoogleAuth/GoogleAuthQuery";
import { logout } from "utils";

function useGoogleAuthModal(props) {
  const { showModal, action, closeModal } = props;
  const [fetchGoogleKey, { data: googleAuthInfo, error: fetch2FAError, isError: isFetch2FAError, isFetching }] =
    useLazyGenerate2FAKeyQuery();
  const [
    enable2FA,
    { data: enableMutate, error: enableMutateError, isError: isEnableMutateError, isLoading, isSuccess },
  ] = useEnable2FAMutation();
  const [
    resetGoogle2FAlink,
    {
      data: resetLinkMessage,
      error: resetLinkError,
      isError: isResetLinkError,
      isLoading: isResetLinkLoading,
      isSuccess: isResetLinkSuccess,
    },
  ] = useSendReset2FALinkMutation();

  const errorMessage = useMemo(
    () => resetLinkError?.data?.message || fetch2FAError?.data?.message || enableMutateError?.data?.message,
    [resetLinkError, fetch2FAError, enableMutateError]
  );

  const showError = useMemo(
    () => isResetLinkError || isFetch2FAError || isEnableMutateError,
    [isResetLinkError, isFetch2FAError, isEnableMutateError]
  );
  const showLoading = useMemo(
    () => isResetLinkLoading || isFetching || isLoading,
    [isResetLinkLoading, isFetching, isLoading]
  );
  const complete = useMemo(() => isResetLinkSuccess || isSuccess, [isResetLinkSuccess, isSuccess]);

  //For Enable 2FA
  const [closeScan, setCloseScan] = useState(true);
  const [secretKey, setSecretKey] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [dataURL, setDataURL] = useState("");
  const [isError, setIsError] = useState(false);
  const [completeAction, setCompleteAction] = useState(false);

  useEffect(() => {
    setIsError(showError);
  }, [showError]);
  useEffect(() => {
    setCompleteAction(complete);
  }, [complete]);

  useEffect(() => {
    if (googleAuthInfo) {
      setSecretKey(googleAuthInfo?.secretKey);
      setDataURL(googleAuthInfo?.dataURL);
    }
  }, [googleAuthInfo]);

  useEffect(() => {
    if (googleAuthInfo) {
      setSecretKey(googleAuthInfo?.secretKey);
      setDataURL(googleAuthInfo?.dataURL);
    }
  }, [googleAuthInfo]);

  useEffect(() => {
    if (enableMutate) {
      setSecretKey(googleAuthInfo?.secretKey);
      setDataURL(googleAuthInfo?.dataURL);
    }
  }, [enableMutate]);

  useEffect(() => {
    if (completeAction && action === "enable") {
      setTimeout(() => {
        logout();
        window.location.replace("/login"); // Replace "/logout" with the actual logout route
      }, 5000);
    }
  }, [completeAction]);

  const closeAuth = () => {
    setIsError(false);
    setCloseScan(true);
    setSecretKey("");
    setOtpToken("");
    if (complete) {
      closeModal("success");
    } else {
      closeModal();
    }
    setCompleteAction(false);
  };

  return {
    googleAuthInfo,
    errorMessage,
    showError,
    showLoading,
    enable2FA,
    closeScan,
    setCloseScan,
    secretKey,
    setSecretKey,
    otpToken,
    setOtpToken,
    dataURL,
    secretKey,
    complete,
    showModal,
    action,
    closeAuth,
    enableMutateError,
    fetchGoogleKey,
    isError,
    completeAction,
    resetGoogle2FAlink,
    resetLinkError,
  };
}

export default useGoogleAuthModal;
