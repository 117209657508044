import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, Typography } from "@mui/material";
import React, { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { BUTTON_COLOR } from "utils";

const INITIAL_VALUE = {
  question: "",
  actionText: "",
  cancelText: "Cancel",
  closeAble: false,
  onClickYes: undefined,
  onClickNo: undefined,
  closeModal: undefined,
};

const ConfirmationModal = (_, ref) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(INITIAL_VALUE);

  const {
    question = "",
    actionText = "",
    cancelText = "Cancel",
    closeAble = false,
    closeModal,

    onClickYes = () => null,
    onClickNo = () => null,
  } = dialogData;

  const actionButtonColor = dialogData?.actionText === "Disapprove" ? BUTTON_COLOR.error : BUTTON_COLOR.success;

  const closeDialog = useCallback(() => {
    setOpenDialog(false);
    setTimeout(() => {
      setDialogData(INITIAL_VALUE);
    }, 500);
  }, []);

  const closeDialogAction = useCallback(() => {
    setOpenDialog(false);
    setTimeout(() => {
      setDialogData(INITIAL_VALUE);
      closeModal?.();
    }, 500);
  }, [closeModal]);

  const showDialog = useCallback(showObj => {
    setOpenDialog(true);
    setDialogData(showObj);
  }, []);

  useImperativeHandle(ref, () => ({
    showDialog,
    closeDialog,
  }));

  return (
    <Dialog id="POP-UP-DIALOG" open={openDialog}>
      {!closeAble && (
        <Box display={"flex"} justifyContent={"flex-end"}>
          <IconButton
            aria-label="close"
            onClick={closeDialogAction}
            sx={{
              color: theme => theme.palette.grey[500],
            }}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
      )}
      <DialogContent className="px-5">
        <Typography variant="h4">{question}</Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        {actionText && (
          <Button variant="contained" className={"mx-2"} color={actionButtonColor} onClick={onClickYes}>
            {actionText}
          </Button>
        )}
        {!closeAble && (
          <Button variant={"outlined"} className="muiLightButton mx-2" onClick={onClickNo}>
            {cancelText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(forwardRef(ConfirmationModal));
