import React, { useEffect, useMemo } from "react";
import { useStaffApi, useStaffProvider } from "./Hook";
import {
  StaffActionContext,
  StaffApiStateContext,
  StaffFormActionContext,
  StaffFormSateContext,
  StaffStateContext,
} from "./StaffContext";

function StaffProvider({ children }) {
  const {
    apiLoading: showSpinner,
    apiError,
    staffData,
    isStaffEdit,
    clinicFetching,
    isEditProfile,
    clinicLocationOption,
    getEditApiParam,
  } = useStaffApi();

  const {
    formRef,
    staffRole,
    activeStep,
    profilePic,
    completedStep,
    mutateLoading,
    fileUploading,
    profileImageUploading,
    clinicLocationDea,
    patientDefaultAddress,
    staffInsertSuccessfully,
    mutateError: providerError,
    formData,
    goBack,
    goNext,
    uploadFile,
    setProfilePic,
    progressBarAction,
    removeProfileImage,
  } = useStaffProvider(getEditApiParam, isStaffEdit);

  useEffect(() => {
    if (staffData?.id) {
      setProfilePic(staffData?.profileImageUrl ?? "");
    }
  }, [staffData?.id, staffData?.profileImageUrl, setProfilePic]);

  const mutateError = useMemo(() => providerError || apiError, [apiError, providerError]);

  const spinnerLoading = useMemo(
    () => mutateLoading || fileUploading || showSpinner,
    [fileUploading, mutateLoading, showSpinner],
  );

  const staffStateValue = useMemo(
    () => ({
      showSpinner: spinnerLoading,
      staffInsertSuccessfully,
      isStaffEdit,
      isEditProfile,
      profilePic,
      mutateError,
      profileImageUploading,
    }),
    [
      spinnerLoading,
      staffInsertSuccessfully,
      isStaffEdit,
      isEditProfile,
      profilePic,
      mutateError,
      profileImageUploading,
    ],
  );

  const staffApiStateValue = useMemo(
    () => ({ staffData, clinicFetching, clinicLocationOption }),
    [clinicFetching, clinicLocationOption, staffData],
  );

  const staffFormStateValue = useMemo(
    () => ({ completedStep, formRef, activeStep, staffRole, clinicLocationDea, patientDefaultAddress }),
    [activeStep, clinicLocationDea, completedStep, formRef, patientDefaultAddress, staffRole],
  );

  const staffActionValue = useMemo(() => ({ removeProfileImage, uploadFile }), [uploadFile, removeProfileImage]);

  const staffFormActionValue = useMemo(
    () => ({ goBack, goNext, formData, progressBarAction }),
    [goBack, goNext, formData, progressBarAction],
  );

  return (
    <StaffStateContext.Provider value={staffStateValue}>
      <StaffApiStateContext.Provider value={staffApiStateValue}>
        <StaffActionContext.Provider value={staffActionValue}>
          <StaffFormActionContext.Provider value={staffFormActionValue}>
            <StaffFormSateContext.Provider value={staffFormStateValue}>{children}</StaffFormSateContext.Provider>
          </StaffFormActionContext.Provider>
        </StaffActionContext.Provider>
      </StaffApiStateContext.Provider>
    </StaffStateContext.Provider>
  );
}

export default React.memo(StaffProvider);
