import GoogleAuthApi from "rtk/rtkApi/GoogleAuthApi";
import { API_METHODS, GOOGLE_AUTH_END_POINTS } from "../../rtkConstant";
const GoogleAuthMutation = GoogleAuthApi.injectEndpoints({
  endpoints: (builder) => ({
    enable2FA: builder.mutation({
      query: (apiArgs) => ({
        url: GOOGLE_AUTH_END_POINTS.ENABLE_2FA,
        method: API_METHODS.POST,
        body: apiArgs,
      }),
    }),
    reset2FA: builder.mutation({
      query: (apiArgs) => ({
        url: GOOGLE_AUTH_END_POINTS.RESET_GOOGLE_2A,
        method: API_METHODS.POST,
        body: apiArgs,
      }),
    }),
    sendReset2FALink: builder.mutation({
      query: (apiArgs) => ({
        url: GOOGLE_AUTH_END_POINTS.RESET_GOOGLE_2A_LINK,
        method: API_METHODS.POST,
        body: apiArgs,
      }),
    }),
  }),
});
export const { useEnable2FAMutation, useReset2FAMutation, useSendReset2FALinkMutation } = GoogleAuthMutation;
