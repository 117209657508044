import { decrypt } from "cryptofunc";
import { first } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import { RX_STATUS_PEND } from "../../admin/Prescriptions/constants";
import { userRoleSelector } from "selectors";
import { ClinicApi } from "../../../rtk";
import { formatDaySupply } from "utils";

function ReviewInfo({ onChangeStatus, creditCardInfo, showClinicView }) {
  const { state: { isReview = false, side = "" } = {} } = useLocation();
  const { id: prescriptionId = "", clinicId = "" } = useParams();
  const userRole = useSelector(userRoleSelector);
  const selectPrescriptionData = useMemo(
    () => ClinicApi.endpoints.getClinicReviewPrescription.select({ isReview, prescriptionId }),
    [isReview, prescriptionId],
  );
  const { data: prescriptionData = {} } = useSelector(selectPrescriptionData);

  const decryptedBillingInfo = useMemo(() => {
    let info = {};
    if (creditCardInfo?.encryptedBillingInfo) {
      info = decrypt(creditCardInfo?.encryptedBillingInfo);
    }
    return info;
  }, [creditCardInfo?.encryptedBillingInfo]);

  const onChangeOption = useCallback(
    (e, presId, prodId, patientId = "") => {
      onChangeStatus({ _id: prodId, prescriptionId: presId, rxStatus: e.target.value, patientId: patientId });
    },
    [onChangeStatus],
  );

  const renderInfoRow = useCallback(
    (title = "", data = "") => (
      <div className="InfoRow">
        <p>{title}</p>
        <p>{data}</p>
      </div>
    ),
    [],
  );

  const renderPrescriptionRow = useCallback(
    (item, presId, patientObj = {}, rxStatusPatient = "", customItem = []) => {
      const { patientId = "", patientName = "" } = patientObj;
      const prescriptionRow = item.map(product => {
        const rxStatus = rxStatusPatient || product.rxStatus;
        return (
          <tr key={`row-${patientId}-${product?._id}}`}>
            {Boolean(item?.length) && !userRole?.toLowerCase()?.includes("clinic") && !clinicId && (
              <td>
                <label>
                  <select onChange={e => onChangeOption(e, presId, product._id, patientId)}>
                    {RX_STATUS_PEND.map(value => (
                      <option key={`Options_${value}`} value={value} selected={rxStatus === value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </label>
              </td>
            )}
            <td>{patientName}</td>
            <td
              style={{
                width: "150px",
                whiteSpace: "pre-wrap",
              }}>
              {product.name?.toUpperCase()}
            </td>
            <td
              style={{
                width: "60px",
                whiteSpace: "pre-wrap",
              }}>
              <p className="mb-1">
                <span className="semiBold">Quantity: </span>
                <span>{product.quantity}</span>
              </p>
              <p className="mb-1">
                <span className="semiBold">Day Supply: </span>
                <span>{formatDaySupply(product?.daySupply)}</span>
              </p>
              <p className="mb-1">
                <span className="semiBold">Refills: </span>
                <span>{product.refills}</span>
              </p>
            </td>
            <td
              style={{
                width: "150px",
                whiteSpace: "pre-wrap",
              }}>
              {product.patientDiagnosis?.toUpperCase()}
            </td>
            <td
              style={{
                width: "150px",
                whiteSpace: "pre-wrap",
              }}>
              {product.last_office_visit_timestamp
                ? moment(product.last_office_visit_timestamp).format("MM/DD/YYYY")
                : ""}
            </td>

            <td
              style={{
                width: "150px",
                whiteSpace: "pre-wrap",
              }}>
              {product.reasonForCompoundedMedication?.toUpperCase()}
            </td>
            <td
              style={{
                width: "250px",
                whiteSpace: "pre-wrap",
              }}>
              {product.sig?.toUpperCase()}
            </td>
            <td>{product.weight}</td>
            <td>{product.unitPrice}</td>
            <td>{product.discountedPrice}</td>
            <td className="text-end semiBold">{parseFloat(product.discountedPrice * product.quantity).toFixed(2)}</td>
          </tr>
        );
      });

      const rxPadPrescription = customItem?.map((product, i) => {
        return (
          <tr key={`row-${patientId}-${product?._id}}`}>
            <td>{patientName}</td>
            <td
              style={{
                width: "150px",
                whiteSpace: "pre-wrap",
              }}>
              {product.name}
            </td>
            <td
              style={{
                width: "100px",
                whiteSpace: "pre-wrap",
              }}>
              <p className="mb-1">
                <span className="semiBold">Quantity: </span>
                <span>{product.quantity}</span>
              </p>
              <p className="mb-1">
                <span className="semiBold">Day Supply: </span>
                <span>{formatDaySupply(product?.daySupply)}</span>
              </p>
              <p className="mb-1">
                <span className="semiBold">Refills: </span>
                <span>{product.refills}</span>
              </p>
            </td>
            <td
              style={{
                width: "150px",
                whiteSpace: "pre-wrap",
              }}>
              {product.last_office_visit_timestamp
                ? moment(product.last_office_visit_timestamp).format("MM/DD/YYYY")
                : ""}
            </td>
            <td
              style={{
                width: "250px",
                whiteSpace: "pre-wrap",
              }}>
              {product.reasonForCompoundedMedication?.toUpperCase()}
            </td>
            <td
              style={{
                width: "350px",
                whiteSpace: "pre-wrap",
              }}>
              {product.sig}
            </td>
            <td
              style={{
                width: "250px",
                whiteSpace: "pre-wrap",
              }}>
              {product.patientDiagnosis}
            </td>
            <td>{product.unitPrice}</td>
            <td>{product.discountedPrice}</td>
            <td className="text-end semiBold">{parseFloat(product.discountedPrice * product.quantity).toFixed(2)}</td>
          </tr>
        );
      });

      return (
        <React.Fragment key={`${patientId}_${rxStatusPatient}`}>
          {prescriptionRow}
          {rxPadPrescription}
        </React.Fragment>
      );
    },
    [clinicId, onChangeStatus, userRole],
  );

  const renderPrescriptionBody = useMemo(() => {
    const {
      patientsProducts = [],
      patients = [],
      patientName = "",
      products: item = [],
      rxPadProducts = [],
      isBulkOrder = false,
      id = "",
    } = prescriptionData;
    if (isBulkOrder) {
      let row = [];
      if (patientsProducts?.length > 0) {
        row = patientsProducts.map((product, index) => {
          const patientObj = {
            patientId: product.patientId,
            patientName: product.patientName,
          };
          const productInfo = product.products?.[0];
          const filterProduct = item.filter(itemRow => itemRow.productId === productInfo.productId);
          const rxStatusPatient = productInfo?.rxStatus || filterProduct?.rxStatus;
          return renderPrescriptionRow(filterProduct, id, patientObj, rxStatusPatient, rxPadProducts);
        });
      } else if (patients?.length > 0) {
        row = patients?.map(patient => {
          const patientObj = {
            patientId: patient?.id,
            patientName: patient?.firstName + " " + patient?.lastName,
          };
          return renderPrescriptionRow(item, id, patientObj, undefined, rxPadProducts);
        });
      }
      return row;
    } else {
      const patientInfo = first(patients);
      const patientObj = {
        patientId: patientInfo?.id,
        patientName: patientInfo?.firstName + " " + patientInfo?.lastName,
      };
      return renderPrescriptionRow(item, id, patientObj, undefined, rxPadProducts);
    }
  }, [prescriptionData, renderPrescriptionRow]);

  return (
    <>
      <div>
        <Row className="my-4 px-3 justify-content-between">
          <Col lg={12} xl={5}>
            <p className="bold medText mb-3">Clinic Information</p>
            {renderInfoRow("Clinic Name", prescriptionData?.clinicName)}
            {renderInfoRow("Clinic Location Name", prescriptionData?.clinicLocationName)}
            {renderInfoRow(
              "Provider Name",
              `${prescriptionData?.providerDetails?.firstName ?? ""} ${
                prescriptionData?.providerDetails?.lastName ?? ""
              }`,
            )}
            {renderInfoRow("NPI Number", prescriptionData?.providerNpi ?? "")}
            {renderInfoRow(
              "DEA Number",
              prescriptionData?.providerDea ??
                prescriptionData?.providerDetails?.deaInfo?.filter(
                  a => a.clinicLocationId === prescriptionData?.clinicLocationId,
                )[0]?.dea ??
                "",
            )}
          </Col>
          <Col lg={12} xl={5}>
            <p className="bold medText mb-3">Billing Information</p>
            <div className="InfoRow">
              <p>Bill To</p>
              {/* Temporary basis */}
              <p style={{ textTransform: "capitalize" }}>{"Clinic"}</p>
            </div>
            {renderInfoRow("Credit card holder name", decryptedBillingInfo?.creditCard?.cardHolderName || "")}
            {renderInfoRow(
              "Credit card number",
              `**** **** **** ${
                decryptedBillingInfo?.creditCard?.creditCardNumber?.substring?.(
                  decryptedBillingInfo?.creditCard.creditCardNumber?.length - 4,
                  decryptedBillingInfo?.creditCard?.creditCardNumber?.length,
                ) ?? ""
              }`,
            )}
            {renderInfoRow(
              "Credit card expiry",
              moment(
                (decryptedBillingInfo?.creditCard?.cardExpirationDate ?? "")?.replace?.(/\s/g, ""),
                "MM-YYYY",
              )?.format("MM/YY"),
            )}
            {/* {renderInfoRow("CVV", "***")} */}
            {renderInfoRow("NET", prescriptionData?.paymentTermDays)}
          </Col>
        </Row>
        <hr />
        {/* Patient Information */}
        <Row>
          <span className="bold medText my-2">Patient Information</span>
          {prescriptionData?.patientIds?.length ? (
            <div className="table-responsive">
              <Table className="table-nowrap font-size-14 mt-3 w-100 table-nowrap">
                <thead>
                  <tr>
                    <th style={{ width: "100px" }}>Patient Name</th>
                    <th>Patient Info</th>
                    <th>Default Address</th>
                    <th style={{ width: "50px" }}>Driving License State</th>
                  </tr>
                </thead>
                <tbody>
                  {prescriptionData?.patients?.map((patient, i) => {
                    const address = patient.addresses[patient.addresses.findIndex(address => address.isDefault)];

                    return (
                      <tr key={`patient` + i}>
                        <td>{patient.firstName + " " + patient.lastName}</td>
                        <td>
                          <p className="mb-1">
                            <span className="semiBold mx-2">DOB:</span>
                            <span>{patient.dateOfBirth}</span>
                            <span className="semiBold mx-2">Gender:</span>
                            <span>{patient?.sex}</span>
                          </p>
                          <p className="mb-1">
                            <span className="semiBold mx-2">Email:</span>
                            <span>{patient.email}</span>
                            <span className="semiBold mx-2">Phone:</span>
                            <span>{patient?.cellPhone}</span>
                          </p>
                        </td>
                        <td>
                          {address?.addressLine1 +
                            ", " +
                            address?.city +
                            ", " +
                            address?.state +
                            ", " +
                            address?.zipcode}
                        </td>
                        <td>{patient.driverLicenseState}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          ) : (
            <p className="text-center medText my-4">No Patients Data</p>
          )}
        </Row>
        <hr />
        {/* Shipping Information */}
        <Row className="my-4 px-3 justify-content-between">
          <span className="bold medText mb-5">Shipping Information</span>
          <Col lg={12} xl={5}>
            {renderInfoRow("Delivery method", prescriptionData?.shippingInfo?.shippingMethod)}

            {!prescriptionData?.shippingInfo?.shippingMethod?.toLowerCase().includes("pickup") ? (
              <>
                {renderInfoRow(
                  "Ship To",
                  prescriptionData?.shippingInfo?.shipTo ??
                    (prescriptionData?.patients?.length > 1 ? "Clinic" : "Patient"),
                )}
                <p className="mt-3 semiBold medText mb-5">Shipping Address</p>
                {renderInfoRow("Address Line 1", prescriptionData?.shippingInfo?.shippingAddress?.addressLine1)}
                {renderInfoRow("Address Line 2", prescriptionData?.shippingInfo?.shippingAddress?.addressLine2 || "-")}
                {renderInfoRow("City", prescriptionData?.shippingInfo?.shippingAddress?.city)}
                {renderInfoRow("State", prescriptionData?.shippingInfo?.shippingAddress?.state)}
                {renderInfoRow("Zip Code", prescriptionData?.shippingInfo?.shippingAddress?.zipcode)}
              </>
            ) : null}
          </Col>
          <Col lg={12} xl={5}>
            {renderInfoRow("Signature required", prescriptionData?.shippingInfo?.isSignatureRequired ? "Yes" : "No")}
            {renderInfoRow("Order notes", prescriptionData?.shippingInfo?.orderNotes ?? "-")}
          </Col>
        </Row>
        <hr />
        {/* Prescription Information */}
        <div className="py-2 mt-4">
          <span className="bold medText my-2">Prescription information</span>
        </div>
        <div className="table-responsive">
          <Table className="table-nowrap font-size-14 mt-5">
            <thead>
              <tr>
                {!showClinicView && <th>Status</th>}
                <th>Patient Name</th>
                <th>Product Name</th>
                <th>Info</th>
                <th>Diagnosis</th>
                <th>Last Office Visit</th>
                <th>Reason for Medication</th>
                <th
                  style={{
                    width: "50px",
                    maxWidth: "150px !important",
                  }}>
                  Sig
                </th>
                <th style={{ width: "50px" }}>Weight</th>
                <th style={{ width: "50px" }}>Unit Price ($)</th>
                <th style={{ width: "50px" }}>Discounted Price ($)</th>
                <th className="text-end" style={{ width: "50px" }}>
                  Total ($)
                </th>
              </tr>
            </thead>
            <tbody>
              {renderPrescriptionBody}
              {prescriptionData?.shippingInfo?.isRushOrder ? (
                <>
                  <tr>
                    <td colSpan="1" className="border-0">
                      Priority Order
                    </td>
                    <td colSpan="2" className="border-0 text-start">
                      <strong>{prescriptionData?.shippingInfo?.isRushOrder ? "Yes: " : "No"}</strong>
                    </td>
                    <td colSpan="4" className="border-0 text-end semiBold">
                      Rush order
                    </td>
                    <td colSpan="2" className="border-0 text-end semiBold">
                      <strong>$ {prescriptionData?.shippingInfo?.rushOrderCost}</strong>
                    </td>
                  </tr>
                </>
              ) : null}
              {prescriptionData?.creditRequested !== 0 ? (
                <tr>
                  <td colSpan="1" className="border-0"></td>
                  <td colSpan="2" className="border-0 text-start"></td>
                  <td colSpan="4" className="border-0 text-end">
                    Credit Used
                  </td>
                  <td colSpan="2" className="border-0 text-end">
                    <strong>${prescriptionData?.creditRequested}</strong>
                  </td>
                </tr>
              ) : null}
              <tr>
                <td colSpan="1" className="border-0"></td>
                <td colSpan="2" className="border-0 text-start"></td>
                <td colSpan="4" className="border-0 text-end">
                  Sub Total
                </td>
                <td colSpan="2" className="border-0 text-end">
                  <strong>
                    $ {parseFloat(prescriptionData?.totalAmount - prescriptionData?.shippingCost || 0).toFixed(2)}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="border-0">
                  Shipping Method
                </td>
                <td colSpan="2" className="border-0 text-start">
                  <strong>{prescriptionData?.shippingInfo?.shippingMethod}</strong>
                </td>
                <td colSpan="4" className="border-0 text-end">
                  Shipping
                </td>
                <td colSpan="2" className="border-0 text-end">
                  <strong>
                    ${" "}
                    {!prescriptionData?.shippingInfo?.shippingMethod?.toLowerCase().includes("pickup")
                      ? prescriptionData?.shippingCost || 0
                      : 0}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colSpan="1" className="border-0">
                  Signature Required
                </td>
                <td colSpan="2" className="border-0 text-start">
                  <strong>{prescriptionData?.shippingInfo?.isSignatureRequired ? "Yes" : "No"}</strong>
                </td>
                <td colSpan="4" className="border-0 text-end">
                  <h4 className="m-0">
                    <strong>Total</strong>
                  </h4>
                </td>
                <td colSpan="2" className="border-0 text-end">
                  <h4 className="m-0">
                    $<strong>{parseFloat(prescriptionData?.totalAmount ?? "0").toFixed(2)} </strong>
                  </h4>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default React.memo(ReviewInfo);
