import { Box, Chip, FormControl, FormHelperText, InputLabel, MenuItem } from "@mui/material";
import { SelectStyled } from "components/StyledComponent";
import { filter, find, findIndex, get, trim } from "lodash";
import React, { forwardRef, useCallback, useRef } from "react";

const defaultMuiFieldProps = { label: "", optionArray: [], fullWidth: true };

const OutlineSelectField = forwardRef(
  ({ isRequired = false, error = "", inputField, MuiFieldProps = defaultMuiFieldProps }, ref) => {
    const {
      label = "",
      fullWidth = true,
      disabled = false,
      optionArray = [],
      size = "medium",
      multiple = false,
      onRemoveSelection = () => null,
    } = MuiFieldProps;

    const renderValue = useCallback(
      selected => {
        const chipArray = selected?.map(selectedValue => {
          const selectedObj = find(optionArray, { value: selectedValue });
          return (
            <Chip
              key={selectedValue}
              size="small"
              label={selectedObj?.label}
              onMouseDown={event => {
                event.stopPropagation();
                event.preventDefault();
              }}
              onDelete={event => {
                event.stopPropagation();
                onRemoveSelection(selectedValue);
              }}
            />
          );
        });

        return <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>{chipArray}</Box>;
      },
      [onRemoveSelection, optionArray],
    );

    return (
      <FormControl error={Boolean(trim(error))} fullWidth={fullWidth} disabled={disabled} size={size}>
        <InputLabel id={label}>
          {label}
          {isRequired && <span className="requiredStar"> *</span>}
        </InputLabel>
        <SelectStyled
          {...inputField}
          inputRef={ref}
          disabled={disabled}
          multiple={multiple}
          labelId={label}
          label={`${label} ${isRequired ? "*" : ""}`}
          renderValue={multiple ? renderValue : undefined}>
          {optionArray?.map(option => (
            <MenuItem key={option.value} value={option.value} disabled={option?.disabled}>
              {option.label}
            </MenuItem>
          ))}
        </SelectStyled>
        <FormHelperText variant="outlined" style={{ fontSize: 10 }}>
          {error}
        </FormHelperText>
      </FormControl>
    );
  },
);

export default React.memo(OutlineSelectField);
