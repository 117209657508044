import React, { Component } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import ContactMethodDD from "../../../components/dropdowns/contactMethodDD";
import NotificationsDD from "../../../components/dropdowns/notificationsDD";

class PatientInfo5 extends Component {
  render() {
    return (
      <div className={`mt-4 ${this.props.step === 4 ? "d-block" : "d-none"}`}>
        <Row>
          <Col md={6}>
            <div className="mb-4">
              <Label htmlFor="preferredFirstName" className="form-label">
                Preferred First Name
              </Label>
              <Input
                defaultValue={this.props.patient ? this.props.patient.preferredFirstName : ""}
                name="preferredFirstName"
                className="form-control"
                type="text"
              />
            </div>
          </Col>

          <Col md={6}>
            <div className="mb-4">
              <Label htmlFor="preferredLastName" className="form-label">
                Preferred Last Name
              </Label>
              <Input
                defaultValue={this.props.patient ? this.props.patient.preferredLastName : ""}
                name="preferredLastName"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4} className="mb-4">
            <ContactMethodDD
              defaultValue={this.props.patient ? this.props.patient.preferredContactMethod : ""}
              name="preferredContactMethod"
              required={false}
            />
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Label htmlFor="preferredLanguage" className="form-label">
                Preferred Language
              </Label>
              <Input
                defaultValue={this.props.patient ? this.props.patient.preferredLanguage : ""}
                name="preferredLanguage"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <hr className="mt-3 mb-5" />
        <Row>
          <Col md={4} className="mb-4">
            <NotificationsDD
              name="notificationPreference"
              defaultValue={this.props.notificationPreferences}
              required={false}
            />
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Label htmlFor="notificationEmail" className="form-label">
                Notification Email
              </Label>
              <Input
                defaultValue={
                  this.props.patient.email.length > 0 ? this.props.patient.email : this.props.patient?.notificationEmail
                }
                name="notificationEmail"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Label htmlFor="notificationText" className="form-label">
                Notifications Text
              </Label>
              <Input
                defaultValue={
                  this.props.patient.cellPhone.length > 0
                    ? this.props.patient.cellPhone
                    : this.props.patient?.notificationText
                }
                name="notificationText"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className="mb-4">
              <Label htmlFor="preferredEmergencyContact" className="form-label">
                Emergency Contact Name
              </Label>
              <Input
                defaultValue={this.props.patient ? this.props.patient.preferredEmergencyContact : ""}
                name="preferredEmergencyContact"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="mb-4">
              <Label htmlFor="preferredEmergencyContactNumber" className="form-label">
                Emergency Contact Phone
              </Label>
              <Input
                defaultValue={this.props.patient ? this.props.patient.preferredEmergencyContact : ""}
                name="preferredEmergencyContactNumber"
                className="form-control"
                type="text"
              />
            </div>
          </Col>
        </Row>

        <Row>
          <div className={`mt-4`}>
            <p className="semiBold medText my-4 mt-5">Preferred Notifications:</p>
            <div className="d-flex justify-content-start flexWrap">
              <div className="mx-4 mb-3">
                <input
                  defaultChecked={this.props.isToNotifyWhenOrderReceived}
                  onChange={e => {
                    this.props.setOrderReceived(e.target.checked);
                  }}
                  name="isToNotifyWhenOrderReceived"
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label mx-2" htmlFor="isToNotifyWhenOrderReceived">
                  Order Received
                </label>
              </div>

              <div className="mx-4 mb-3">
                <input
                  checked={true}
                  onChange={e => {
                    this.props.setOrderOnHold(true);
                  }}
                  name="isToNotifyWhenOrderOnHold"
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label mx-2" htmlFor="isToNotifyWhenOrderOnHold">
                  Order on Hold
                </label>
              </div>

              <div className="mx-4 mb-3">
                <input
                  defaultChecked={this.props.isToNotifyWhenOrderInProcess}
                  onChange={e => {
                    this.props.setOrderInProcess(e.target.checked);
                  }}
                  name="isToNotifyWhenOrderInProcess"
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label mx-2" htmlFor="isToNotifyWhenOrderInProcess">
                  Order in Process
                </label>
              </div>

              <div className="mx-4 mb-3">
                <input
                  defaultChecked={this.props.isToNotifyWhenOrderIsCompleted}
                  onChange={e => {
                    this.props.setOrderToPickup(e.target.checked);
                  }}
                  name="isToNotifyWhenOrderIsCompleted"
                  className="form-check-input"
                  type="checkbox"
                />
                <label className="form-check-label mx-2" htmlFor="isToNotifyWhenOrderIsCompleted">
                  Order Completed
                </label>
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

export default PatientInfo5;
