import { Container, Paper } from "@mui/material";
import classNames from "classnames";
import { AlertMessage, PatientPrescriptionListing } from "components";
import PatientNavbar from "components/patientNavbar";
import Spinner from "components/spinner";
import React, { useCallback, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import { Row, TabContent, TabPane } from "reactstrap";
import { useLazyGetPatientArchivedPrescriptionQuery, useLazyGetPatientIncompletePrescriptionQuery } from "rtk";

function PatientOrder() {
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [showSpinner, setShowSpinner] = useState(true);

  const setPageError = useCallback((error = "") => {
    setError(error);
  }, []);

  const setPageLoading = useCallback((loading = false) => {
    setShowSpinner(loading);
  }, []);

  const RenderInProgressOrderView = useMemo(
    () => (
      <PatientPrescriptionListing
        setPageError={setPageError}
        setPageLoading={setPageLoading}
        listingQuery={useLazyGetPatientIncompletePrescriptionQuery}
      />
    ),
    [setPageError, setPageLoading],
  );

  const RenderCompletedOrderView = useMemo(
    () => (
      <PatientPrescriptionListing
        setPageError={setPageError}
        setPageLoading={setPageLoading}
        listingQuery={useLazyGetPatientArchivedPrescriptionQuery}
      />
    ),
    [setPageError, setPageLoading],
  );

  return (
    <React.Fragment>
      <PatientNavbar />
      <div className="page-content">
        <MetaTags>
          <title>Prescription History | VPI Compounding</title>
        </MetaTags>
        <Container maxWidth="false">
          <h4 className="p text-muted my-4">ORDER STATUS</h4>
          <Paper className="p-3" elevation={1}>
            {showSpinner && <Spinner />}
            <AlertMessage msg={error} isError={true} />
            <Row className={`px-3 my-4 justify-content-center`}>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  setActiveTab(1);
                }}
                className={classNames("mx-2 text-center", {
                  blueButton: activeTab === 1,
                  whiteButton: activeTab !== 1,
                })}
                style={{ width: 200 }}>
                Order in Process
              </Link>
              <Link
                to="#"
                onClick={e => {
                  e.preventDefault();
                  setActiveTab(2);
                }}
                className={classNames("mx-2 text-center", {
                  blueButton: activeTab === 2,
                  whiteButton: activeTab !== 2,
                })}
                style={{ width: 200 }}>
                Completed Orders
              </Link>
            </Row>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={1}>{RenderInProgressOrderView}</TabPane>
              <TabPane tabId={2}>{RenderCompletedOrderView}</TabPane>
            </TabContent>
          </Paper>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default React.memo(PatientOrder);
