import React, { useMemo } from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import StateDD from "../dropdowns/stateDD";
import ReCAPTCHA from "react-google-recaptcha";
import { SignaturePad } from "components/SignaturePad";
import CreditCardInput from "react-credit-card-input";
import { encrypt, decrypt } from "cryptofunc";
import "./index.scss";
import BAAForm from "components/BAAForm";

const StepThree = ({
  vpiInfo,
  setVpiInfo,
  companyInfo,
  setCompanyInfo,
  handleSubmit,
  companyInfoRequired,
  vpiInfoRequired,
  adminSide,
  onClickSave,
  setBaaFormPDFUrl,
  step
}) => {
  return (
    <>
      <BAAForm
        vpiInfo={vpiInfo}
        setVpiInfo={setVpiInfo}
        companyInfo={companyInfo}
        setCompanyInfo={setCompanyInfo}
        handleSubmit={handleSubmit}
        companyInfoRequired={companyInfoRequired}
        vpiInfoRequired={vpiInfoRequired}
        adminSide={adminSide}
        onClickSave={onClickSave}
        setBaaFormPDFUrl={setBaaFormPDFUrl}
        step={step}
      />
    </>
  );
};

export default StepThree;
