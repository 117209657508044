import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { findIndex } from "lodash";
import { AuthApi } from "rtk";

dayjs.extend(utc);

const { createSlice, nanoid } = require("@reduxjs/toolkit");
const { ProductCartStore } = require("./ProductCartStore");

const ProductCartSlice = createSlice({
  name: "ProductCartSlice",
  initialState: ProductCartStore,
  reducers: {
    resetProductCart: () => ProductCartStore,
    removeLastProductSummary: state => {
      state.products.shift();
    },
    addNewProductSummary: (state, { payload: { clinicId, clinicLocationId, ...restPayload } }) => {
      state.products.unshift({
        id: nanoid(),
        clinicId,
        clinicLocationId,
        createdAt: dayjs.utc().format(),
        productSummary: restPayload,
      });
    },
    removeProductSummary: (state, { payload }) => {
      const elementIndex = findIndex(state.products, { id: payload });
      if (elementIndex > -1) {
        state.products.splice(elementIndex, 1);
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(AuthApi.endpoints.userLogin.matchFulfilled, () => ProductCartStore);
    builder.addMatcher(AuthApi.endpoints.userLogout.matchFulfilled, () => ProductCartStore);
  },
});

export default ProductCartSlice.actions;
export const { reducer } = ProductCartSlice;
