import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import Spinner from "components/spinner";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import { USER_ROLES } from "utils";
const MedicalAccessoriesDD = (props) => {
  const [medicalAccessories, setMedicalAccessories] = useState([]);
  const [products, setProducts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  //console.log("Productsss");
  useEffect(() => {
    //console.log("Responsedddd");
    axiosConfig
      .get(
        "products/getMedicalAccessoriesProductList",

        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        let medicalAccessoriesList = [];

        await response.data.products.forEach((medicalAccessory) => {
          medicalAccessoriesList.push(
            <option key={medicalAccessory.id} value={medicalAccessory.id}>
              {medicalAccessory.name}
            </option>
          );
        });
        setMedicalAccessories(medicalAccessoriesList);
        setProducts(response.data.products);
      })
      .catch((error) => {
        errorResponse(error);
      });
  }, []);

  const handleChange = async (e) => {
    await axiosConfig
      .post(
        "products/getProductDiscountByProductIds",
        {
          clinicId: props.clinicId,

          productIds: [e.target.value],
        },
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        const { commonName, created, family, id, isActive, name, sigOptions, subCategory1, subCategory2, unitPrice } =
          products.filter((prod) => prod.id === e.target.value)[0];

        let productInfo = {
          commonName: commonName,
          created: created,
          family: family,
          id: id,
          isActive: isActive,
          name: name,
          sigOptions: sigOptions,
          subCategory1: subCategory1,
          subCategory2: subCategory2,
          discountedPercentage: response.data[0]?.discountedPercentage,
          discountedPrice: response.data[0]?.discountedPrice,
          unitPrice: response.data[0]?.unitPrice,
        };

        await props.onClickSelectedMedicalAccessory(productInfo);
      })
      .catch((error) => {
        errorResponse(error);
      });
  };

  return (
    <FormGroup key={props}>
      <Label className="normalText" for={props.name}>
        Medical Accessories {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>

      <Input
        onChange={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleChange(e);
        }}
        type="select"
        name={props.name}
        required={props.required}
        defaultValue={props.defaultValue ? props.defaultValue : ""}
      >
        <option value="" disabled>
          Select
        </option>
        {medicalAccessories}
      </Input>
    </FormGroup>
  );
};

export default MedicalAccessoriesDD;
