import React from 'react';
import { Alert } from 'reactstrap';

const AlertMessage = (props) => {
  const { msg = '', isError = false } = props;
  return !!msg ? (
    <Alert color={isError ? 'danger' : 'success'} className='text-center'>
      {msg}
    </Alert>
  ) : null;
};
export default AlertMessage;
