import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "../rtkConstant";
import { baseQuery } from "./BaseApi";

const UploadsApi = createApi({
  reducerPath: "PrescriptionApi",
  tagTypes: [CACHE_TAGS.UPLOADS],
  baseQuery: baseQuery,
  endpoints: () => ({}),
});

export default UploadsApi;
