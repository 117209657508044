const PAY_NOW_STATUS = ["Net 30 Approved", "Error", "Declined", "Transaction Failed", "Net 15 Approved"];
const SHIP_METHODS = [
  {
    label: "FEDEX_GROUND",
    value: "FEDEX_GROUND",
  },
  {
    label: "Custom Rate",
    value: "CustomRate",
  },

  {
    label: "UPS 3 Day Select",
    value: "UPS 3 Day Select",
  },

  {
    label: "FedEx 2-Day - (Commercial - 2 Business Days by 4:30PM) / (Residential - 2 Business Days by 8:00PM)",
    value: "FEDEX_2_DAY",
  },

  {
    label:
      "FedEx Priority Overnight - (Commercial - Next Business Day by 10:30PM) / (Residential - Next Business Day by 4:30PM)",
    value: "PRIORITY_OVERNIGHT",
  },

  {
    label:
      "FedEx Standard Overnight - (Commercial - Next Business Day by 4:30PM) / (Residential - Next Business Day by 8:00PM)",
    value: "STANDARD_OVERNIGHT",
  },

  {
    label: "FedEx First Overnight - (Next Business Day by 8:00AM - 9:30AM)",
    value: "FIRST_OVERNIGHT",
  },

  {
    label: "UPS 2-Day Air - 2 Business Day Arrival By End of Day",
    value: "UPS 2nd Day Air",
  },
  {
    label: "UPS Ground - Next Business Day Arrival By End of Day (CO only) / 4 - 5 Business Day Arrival By End of Day",
    value: "UPS Ground",
  },
  {
    label: "UPS Next Day Air Saver -  Next Business Day Arrival By End of Day",
    value: "UPS Next Day Air Saver",
  },
  {
    label: "UPS Next Day Air - Next Business Day Arrival By 10:30AM",
    value: "UPS Next Day Air",
  },

  {
    label: "UPS Next Day Air Early - Next Business Day Arrival by 8:00AM",
    value: "UPS Next Day Air Early",
  },

  {
    label: "Pick Up - VPI Compounding Pharmacy",
    value: "PickUp",
  },
  {
    label: "USPS 1-Day (PO BOX ONLY)",
    value: "USPS 1-Day (PO BOX ONLY)",
  },
];

const RUSH_ORDER_INFO = {
  name: "24-hour Turn Around",
  percentOfOrder: 15,
  value: 70,
};
const VPI_ADDRESS = {
  name: "VPI Compounding Address",
  address: "7265 S Revere Pkwy, Ste. 902 Centennial, CO 80112-6787",
};

const FREQUENCY_TABLE = {
  "ONE TIME PER DAY": 7,
  "EVERY MORNING": 7,
  "EVERY NIGHT AT BEDTIME": 7,
  "TWO TIMES PER DAY": 14,
  "THREE TIMES PER DAY": 21,
  "TWO TIMES PER WEEK": 2,
  "THREE TIMES PER WEEK": 3,
  "USE AS DIRECTED": 7, // Default 1 month supply
  "AS DIRECTED": 7, // Default 1 month supply
  "NIGHTLY": 7, // Default 1 month supply
  "DAILY": 7, // Default 1 month supply
};

export { PAY_NOW_STATUS, SHIP_METHODS, RUSH_ORDER_INFO, VPI_ADDRESS, FREQUENCY_TABLE };
