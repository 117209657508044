/* eslint-disable react-hooks/exhaustive-deps */
import { has, isNull } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { useInsertStaffMutation, useUploadFileMutation } from "rtk";
import { STAFF_STEPPER, STEPPER_FORMS, base64toFile } from "utils";

function useStaffProvider(getEditApiParam, isStaffEdit) {
  const formRef = useRef([]);
  const inputData = useRef(null);
  const personalData = useRef(null);
  const addressData = useRef(null);
  const portalData = useRef(null);
  const [profilePic, setProfilePic] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [profilePicLoading, setProfilePicLoading] = useState(false);
  const [completedStep, setCompletedStep] = useState(() => (isStaffEdit ? { 0: true, 1: true, 2: true } : { 0: true }));

  const [uploadFileMutation, { error: uploadError, isLoading: uploadLoading }] = useUploadFileMutation();
  const [
    StaffMutate,
    { isSuccess: staffInsertSuccessfully, error: staffError, isLoading: staffLoading, reset: resetMutation },
  ] = useInsertStaffMutation();

  const profileImageUploading = useMemo(() => profilePicLoading && uploadLoading, [profilePicLoading, uploadLoading]);
  const fileUploading = useMemo(() => !profilePicLoading && uploadLoading, [profilePicLoading, uploadLoading]);
  const isLastForm = useMemo(() => formRef.current?.length - 1 === activeStep, [activeStep, formRef]);
  const mutateLoading = useMemo(() => staffLoading, [staffLoading]);
  const mutateError = useMemo(
    () => uploadError?.data?.message || staffError?.data?.message || "",
    [staffError?.data?.message, uploadError?.data?.message],
  );

  const clinicLocationDea = useMemo(() => personalData?.current?.clinicLocationsDea ?? "", [personalData?.current]);
  const staffRole = useMemo(() => personalData?.current?.role, [personalData?.current]);

  const removeProfileImage = useCallback(e => {
    e.preventDefault();
    setProfilePic("");
  }, []);

  const uploadFile = useCallback(
    fileName =>
      async (e, signPad = "") => {
        e?.preventDefault?.();
        if (fileName === "profileImage") {
          setProfilePicLoading(true);
        } else {
          setProfilePicLoading(false);
        }
        if (!uploadLoading) {
          let file = "";
          if (signPad) {
            file = base64toFile(signPad);
          } else {
            file = e.target.files[0];
          }
          if (file) {
            const formData = new FormData();
            formData.append("file", file);
            const uploadResponse = await uploadFileMutation(formData).unwrap();
            if (uploadResponse?.accessUrl) {
              if (fileName === "profileImage") {
                setProfilePic(uploadResponse?.accessUrl);
              }
              return uploadResponse?.accessUrl;
            }
          }
        }
      },
    [uploadFileMutation, uploadLoading],
  );

  const goBack = useCallback(e => {
    e?.preventDefault();
    setActiveStep(prev => {
      if (prev > 0) {
        return prev - 1;
      }
      return prev;
    });
  }, []);

  const goNext = useCallback(
    e => {
      e.preventDefault();
      formRef.current[activeStep]?.submitForm?.();
    },
    [activeStep],
  );

  const progressBarAction = useCallback((pageIndex, presentPage) => {
    if (pageIndex < presentPage) {
      setActiveStep(pageIndex);
    } else {
      // WHen User wants to go Next Form
      // TODO: Logic to check the Validation for current form and move to next
      formRef?.current?.[presentPage]?.canNavigate?.(pageIndex);
    }
  }, []);

  const navigateForm = useCallback(
    (nextIndex = null) => {
      const nextPage = isNull(nextIndex) ? activeStep + 1 : nextIndex;
      if (nextPage <= STAFF_STEPPER.length - 1) {
        setCompletedStep(prev => ({ ...prev, [nextPage]: true }));
        setActiveStep(nextPage);
      }
    },
    [activeStep],
  );

  const checkFormsValidation = useCallback(async () => {
    for (let i = 0; i < formRef.current.length - 1; i++) {
      const passed = await formRef?.current?.[i]?.checkFilledForm?.();
      if (!passed) {
        return { pageToFill: i };
      }
    }
  }, []);

  const addStaffMutate = useCallback(async () => {
    const editApiParam = getEditApiParam();
    resetMutation();
    const { clinicLocationsDea: _, ...restFormInput } = inputData.current ?? {};
    const organizedParam = {
      ...editApiParam,
      staff: { ...restFormInput, profileImageUrl: profilePic },
    };
    StaffMutate(organizedParam);
  }, [getEditApiParam, StaffMutate, profilePic]);

  const checkAllFormValidation = useCallback(async () => {
    const formValidation = await checkFormsValidation();
    if (has(formValidation, "pageToFill")) {
      setActiveStep(formValidation.pageToFill);
    } else {
      addStaffMutate();
    }
  }, [addStaffMutate, checkFormsValidation]);

  const formData = useCallback(
    (data, formType = "", progressBarIndex = null, allowNavigation = true) => {
      inputData.current = null;
      switch (formType) {
        case STEPPER_FORMS.PERSONAL_INFO:
          personalData.current = { ...data.info };
          break;
        case STEPPER_FORMS.ADDRESS_INFO:
          addressData.current = { ...data.info };
          break;
        case STEPPER_FORMS.PORTAL_INFO:
          portalData.current = { ...data.info };
          break;
        default:
          personalData.current = null;
          addressData.current = null;
          portalData.current = null;
          break;
      }

      inputData.current = {
        ...inputData.current,
        ...personalData.current,
        ...addressData.current,
        ...portalData.current,
      };

      if (allowNavigation) {
        if (isLastForm) {
          checkAllFormValidation();
        } else {
          navigateForm(progressBarIndex);
        }
      }
    },
    [checkAllFormValidation, isLastForm, navigateForm],
  );

  return {
    formRef,
    staffRole,
    activeStep,
    profilePic,
    mutateError,
    completedStep,
    mutateLoading,
    fileUploading,
    clinicLocationDea,
    profileImageUploading,
    staffInsertSuccessfully,
    formData,
    goBack,
    goNext,
    setProfilePic,
    progressBarAction,
    removeProfileImage,
    uploadFile,
  };
}

export default useStaffProvider;
