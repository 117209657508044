import Lottie from "components/lottie";
import React, { useRef } from "react";
import { Alert, Modal, Row, Col, FormGroup, Label, Input } from "reactstrap";
import Spinner from "../spinner";
/*Hook*/
import useCreditBalance from "./index.hook";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
} from "@mui/material";
/*Style CSS*/
import "./index.scss";

const CreditBalance = (props) => {
  const {
    balance,
    newBalance,
    setNewBalance,
    reason,
    setReason,
    showUpdate,
    setShowUpdate,
    setSuccess,
    setBalance,
    success,
    setCreditBalance,
    closeModal,
    closeModal2,
    closeModal3,
    setBalanceModal,
    balanceModal,
    balanceViewModal,
    setBalanceViewModal,
    addBalanceModal,
    setAddBalanceModal,
    showSpinner,
    setShowSpinner,
    error,
    setError,
    remainingCredit,
    setRemainingCredit,
    creditToBeUsed,
    setCreditToBeUsed,
    useMemo,
    useCallback,
    showSave,
    setShowSave,
    totalCredit,
    setTotalCredit,
    deleteCredit,
  } = useCreditBalance(props);

  const updateAction = useCallback(() => {
    let remaining = totalCredit - creditToBeUsed;
    remaining = parseFloat(remaining) >= 0 ? remaining : 0;
    setRemainingCredit(remaining.toFixed(2));
    if (props.region === "journey") props.setCreditRequested(creditToBeUsed);
    else {
      props.setCreditRequested({ clinicLocationId: props.clinicLocationId, creditReq: creditToBeUsed, saved: true });
      props.setSelectedClinicLocationId(props.clinicLocationId);
    }
    setShowSave(true);
  }, [props.totalCredit, creditToBeUsed]);

  const renderSaveBtn = useMemo(() => {
    if (showSave === false) {
      return (
        <span disabled={showSave} className="mx-1 blueButton form-control pointer" onClick={updateAction}>
          {"SUBMIT"}
        </span>
      );
    } else {
      return showSave ? (
        <i
          className="fa fa-times redText p-2"
          style={{ fontSize: "20px" }}
          onClick={() => {
            deleteCredit();
          }}
        />
      ) : null;
    }
  }, [showSave, updateAction]);

  return (
    <div>
      {showSpinner ? <Spinner /> : null}

      {props.region === "clinicProfile" ? (
        <div>
          <span className="p-2">$</span>
          <input
            className="credit-balance-profile "
            style={{ width: showUpdate ? "30px" : "60px" }}
            value={balance}
            readOnly
            onChange={(e) => {
              // setBalance(e.target.value.trimStart());
              // if (e.target.value != "") {
              //   setShowUpdate(true);
              // } else {
              //   setShowUpdate(false);
              // }
            }}
          />
          {/* <span
            className="p-2 fa fa-check set-balance-icon"
            style={{ display: showUpdate === false ? "none" : "inline" }}
            onClick={() => {
              setBalanceModal(true);
            }}
          ></span> */}
          <span>
            <IconButton onClick={() => { setBalanceViewModal(true); }} style={{ width: 30, height: 30 }}>
              <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
            </IconButton>
            <i className="fas fa-edit medText ml-2" style={{ cursor: 'pointer' }} onClick={() => { setAddBalanceModal(true); }} />
          </span>
        </div>
      ) : null}
      {props.region === "rxJourney" ? (
        success ? (
          <>
            <Alert color="success" className="text-center">
              <p className="my-1">
                {" "}
                <span className="fa fa-check px-2"></span>
                {success}
              </p>
            </Alert>
            <p>
              <strong>Note: </strong>Credit applied might change based on actually shipping cost.
            </p>
          </>
        ) : (
          <div>
            <span className="p-2" style={{ border: "1px solid grey" }}>
              <strong>CREDIT BALANCE:</strong> ${balance}
            </span>
            {balance ? (
              <span
                className="p-2 set-balance-icon"
                onClick={() => {
                  setBalanceModal(true);
                }}
              >
                USE
              </span>
            ) : null}
          </div>
        )
      ) : null}

      {props.region === "invoicePage" || props.region === "journey" ? (
        success ? (
          <>
            <Alert color="success" className="text-center">
              <p className="my-1">
                {" "}
                <span className="fa fa-check px-2"></span>
                {success}
              </p>
            </Alert>
            <p>
              <strong>Note: </strong>Credit applied might change based on actually shipping cost.
            </p>
          </>
        ) : (
          <div>
            <h5 className="semiBold medText">Apply Credit</h5>
            <p className="my-1">
              Total Credit on {props.clinicLocationName ?? "selected Clinic Location"} :<strong>${totalCredit}</strong>
            </p>
            <p className="my-1">How much credit would you like to apply to this payment?</p>
            <Row>
              <Col>
                {" "}
                <input
                  readOnly={props.readyOnly}
                  type="number"
                  disabled={showSave}
                  id={"numberInput" + props.clinicLocationId}
                  className="form-control"
                  name={props.name}
                  placeholder="Add Credit"
                  max={props.totalCredit?.toString()}
                  value={creditToBeUsed}
                  onChange={(e) => {
                    const input = e.target.value;
                    const numericInput = input.replace(/[^0-9.]/g, "");
                    // Remove non-numeric characters
                    // console.log("Total: ", props.total);
                    // console.log("props.totalCredit: ", totalCredit);

                    if (parseFloat(props.total) > totalCredit) {
                      if (numericInput <= parseFloat(totalCredit) && numericInput >= 0) {
                        setCreditToBeUsed(numericInput);
                      }
                    } else {
                      if (numericInput <= parseFloat(props.total) && numericInput >= 0) {
                        setCreditToBeUsed(numericInput);
                      }
                    }
                  }}
                />
              </Col>
              <Col m={"3"}> {renderSaveBtn} </Col>
            </Row>
            {remainingCredit ? (
              <p className="my-1">
                Total Credit remaining after this Payment: <strong>${remainingCredit}</strong>
              </p>
            ) : null}

            <br></br>
          </div>
        )
      ) : null}

      <BalanceActionModal
        resetCreditApplied={props.resetCreditApplied}
        setCreditUsed={props.setCreditUsed}
        setSuccess={setSuccess}
        setBalance={setBalance}
        showSpinner={showSpinner}
        success={success}
        balance={balance}
        closeModal={closeModal}
        setCreditBalance={setCreditBalance}
        error={error}
        balanceModal={balanceModal}
        region={props.region}
      />
      <BalanceViewModal
        showSpinner={showSpinner}
        balanceViewModal={balanceViewModal}
        error={error}
        closeModal={closeModal2}
        history={props.history}
      />
      <BalanceAddModal
        showSpinner={showSpinner}
        addBalanceModal={addBalanceModal}
        balance={newBalance}
        setBalance={setNewBalance}
        setReason={setReason}
        reason={reason}
        setCreditBalance={setCreditBalance}
        error={error}
        closeModal={closeModal3}
      />
    </div>
  );
};
export default CreditBalance;

const BalanceAddModal = (data) => {
  const {
    addBalanceModal,
    showSpinner,
    closeModal,
    setCreditBalance,
    setBalance,
    setReason,
    balance,
    error,
  } = data;
  return (
    <Modal isOpen={addBalanceModal} scrollable={true} size="md" centered={true} className="pt-3 fitHeightmodal">
      <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body pb-3 mt-3">
        <h3 className="text-center semiBold blueText mb-4">Add Credit Balance</h3>
        <FormGroup className="mb-4">
          <Label htmlFor="businessName" className="form-label">
            Credit <span className="requiredStar">*</span>
          </Label>
          <Input
            name="credit"
            className="form-control"
            type="number"
            placeholder=""
            value={balance}
            onChange={(e) => {
              setBalance(e.target.value.trimStart());
            }}
          />
          <Label htmlFor="businessName" className="form-label">
            Reason <span className="requiredStar">*</span>
          </Label>
          <Input
            name="reason"
            className="form-control"
            type="text"
            placeholder=""
            onChange={(e) => {
              setReason(e.target.value.trimStart());
            }}
          />
        </FormGroup>
        <div className="text-center">
          <button
            className={"blueButton"}
            onClick={() => {
              setCreditBalance();
            }}
          >
            Add
          </button>
        </div>


        {error ? (
          <Alert color="danger" className="text-center">
            {error}
          </Alert>
        ) : null}
        {showSpinner ? <Spinner /> : null}

      </div>
    </Modal>
  );
}
const BalanceViewModal = (data) => {
  const {
    balanceViewModal,
    showSpinner,
    closeModal,
    error,
  } = data;
  return (
    <Modal isOpen={balanceViewModal} scrollable={true} size="md" centered={true} className="pt-3 mediumHeightmodal">
      <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body pb-1 mt-3">
        <h3 className="text-center semiBold blueText mb-4">Credit Balance History</h3>
        <div className="w-full flex justify-center">
          <table className="table-auto border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2" style={{ width: '20%' }}>#</th>
                <th className="border border-gray-300 px-4 py-2" style={{ width: '20%' }}>Credit</th>
                <th className="border border-gray-300 px-4 py-2" style={{ width: '60%' }}>Reason</th>
              </tr>
            </thead>
            <tbody>
              {data?.history?.map((value, i) => (
                <tr key={value.id}>
                  <td className="border border-gray-300 px-4 py-2">{i + 1}</td>
                  <td className="border border-gray-300 px-4 py-2">{value.credit}</td>
                  <td className="border border-gray-300 px-4 py-2">{value.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* {error ? (
          <Alert color="danger" className="text-center">
            {error}
          </Alert>
        ) : null} */}
        {showSpinner ? <Spinner /> : null}

      </div>
    </Modal>
  );
}

const BalanceActionModal = (data) => {
  const {
    region,
    balanceModal,
    setSuccess,
    resetCreditApplied,
    setCreditUsed,
    setBalance,
    showSpinner,
    success,
    balance,
    setCreditBalance,
    closeModal,
    error,
  } = data;
  let question = "";
  if (region === "rxJourney") question = "Are you sure want to use the credit?";
  else question = "Are you sure you want to update the credit to: $" + balance;
  return (
    <Modal isOpen={balanceModal} size="md" centered={true} className="pt-3 smallHeightmodal">
      <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="modal-body pb-1">
        <h3 className="text-center semiBold blueText mb-4">Credit Balance</h3>
        {error ? (
          <Alert color="danger" className="text-center">
            {error}
          </Alert>
        ) : null}
        {showSpinner ? <Spinner /> : null}
        {success ? (
          <div className="text-center">
            <p className="my-1">{success}</p>
            <p>
              <strong>Note: </strong>Credit applied might change based on actually shipping cost.
            </p>
            <Lottie name="success-check" />
          </div>
        ) : (
          <div>
            <p className="text-center mt-5">{question}</p>
            <Row className="flexJCAC mt-2">
              <button
                className={"blueButton"}
                onClick={() => {
                  if (region === "rxJourney") {
                    resetCreditApplied();
                    setCreditUsed(true);
                    setSuccess("Credit has been applied successfully.");
                  } else {
                    setCreditBalance();
                  }
                }}
              >
                Yes
              </button>
              <button
                className="lightButton mx-2"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </button>
            </Row>
          </div>
        )}
      </div>
    </Modal>
  );
};
