import EditNoteIcon from "@mui/icons-material/EditNote";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  Button,
  ButtonBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import {
  InvoiceDetailModal,
  ListingSearch,
  MuiTableCollapse,
  OrderPackingSlipModal,
  OrderViewModal,
  PrescriptionNoteModal,
  PrescriptionUpdateModal,
} from "components";
import { keys, size } from "lodash";
import { useCallback, useMemo, useState, useContext } from "react";
import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import { LISTING_NAME, PRESCRIPTION_FILTER_OPTIONS, ROWS_LIMIT, USER_ROLES, columnHelper } from "utils";
import { useLazyAdminRxWorkflowPrescriptionQuery, useLazyExportRxWorkflowPrescriptionQuery, useCancelOrderMutation } from "rtk";
import RXStatusDD from "../../../../components/dropdowns/rxStatusDD";
import Spinner from "../../../../components/spinner";
import { useAdminPrescriptionListing } from "../Hook";
import AlertMessage from "../components/alert_message";
import PrescriptionCheckBox from "./PrescriptionCheckBox";
import PrescriptionOrderInput from "./PrescriptionOrderInput";
import UpdateShippingModal from "./update_shipping_modal";
import { DialogContext } from "context";
import Cookies from "universal-cookie";

function RXWorkflowPrescriptionsSection() {
  const {
    userRole,
    rowLimit,
    invoiceId,
    tablePage,
    showLoading,
    totalRecords,
    filterRxStatus,
    prescriptionId,
    selectedFilter,
    searchKeyword,
    disableFilter,
    errorMessage,
    openNoteModal,
    prescriptions,
    openUpdateModal,
    openPackingSlipModal,
    ADMIN_RX_WORKFLOW_COLUMNS,
    toggleUpdateModal,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setCustomError,
    toggleNoteModal,
    updateFilterType,
    handleChangePage,
    toggleDetailModal,
    toggleInvoiceModal,
    updateFilterRxStatus,
    togglePackingSlipModal,
  } = useAdminPrescriptionListing({
    listingQuery: useLazyAdminRxWorkflowPrescriptionQuery,
  });
  const { showDialog, closeDialog } = useContext(DialogContext);
  const [selectedShippingCost, setSelectedShippingCost] = useState("");
  const [selectedShippingInfo, setSelectedShippingInfo] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [openShippingModal, setOpenShippingModal] = useState(false);
  const [fetchReport, { error: reportError, isFetching: reportFetching }] = useLazyExportRxWorkflowPrescriptionQuery();
  const [cancelOrderMutate, { error: cancelOrderError, isLoading: cancelOrderLoading, data: cancelOrderData }] =
    useCancelOrderMutation();
  const disableOrderBtn = useMemo(() => !size(rowSelection), [rowSelection]);
  const showSpinner = useMemo(() => showLoading || reportFetching || cancelOrderLoading, [showLoading, reportFetching, cancelOrderLoading]);
  const successMessage = useMemo(() => cancelOrderData?.message || "", [cancelOrderData?.message]);
  const combinedErrorMessage = useMemo(
    () => errorMessage || reportError?.data?.message || cancelOrderError?.data?.message || "",
    [errorMessage, reportError?.data?.message, cancelOrderError?.data?.message],
  );

  const exportPrescriptionList = useCallback(
    (e, isMasterExport = false) => {
      e.preventDefault();
      fetchReport({ isMasterExport });
    },
    [fetchReport],
  );

  const handleSelectPrescription = useCallback((shippingInfo, shippingCost) => {
    if (shippingInfo && (shippingCost?.toString() === "0" || shippingCost)) {
      setSelectedShippingInfo(shippingInfo);
      setSelectedShippingCost(shippingCost);
    }
  }, []);

  const cancelOrder = useCallback(
    (prescriptionId = "") =>
      () => {
        if (prescriptionId) {
          const userId = new Cookies().get("user_id");
          cancelOrderMutate({ userId, prescriptionId });
          closeDialog();
        }
      },
    [closeDialog, cancelOrderMutate],
  );

  const completeOrderBtnView = useMemo(
    () => (
      <>
        <Col>
          <button
            style={{ marginLeft: "30px" }}
            disabled={disableOrderBtn}
            className="yellowButton mx-5"
            onClick={() => {
              if (!disableOrderBtn) {
                setOpenShippingModal(true);
              }
            }}>
            Complete Order(s)
          </button>
        </Col>
      </>
    ),
    [disableOrderBtn],
  );


  const ADMIN_PRESCRIPTION_ACTION = useMemo(
    () =>
      columnHelper.accessor("id", {
        header: "Action",
        headerStyle: { minWidth: "60px", textAlign: "center", paddingLeft: 0, paddingRight: 5 },
        cell: props => {
          const prescriptionStatus = props.row.original.prescriptionStatus;
          const isReadyToPack = prescriptionStatus === "Ready To Pack";
          console.log("prescription status :", prescriptionStatus, isReadyToPack);
          return (
            <Tooltip title="Cancel">
              <IconButton
                onClick={() => {
                  showDialog({
                    question: "Are you sure you want to cancel prescription order?",
                    actionText: "Yes",
                    cancelText: "No",
                    onClickYes: cancelOrder(props.getValue()),
                    onClickNo: closeDialog,
                    closeModal: closeDialog,
                  });
                }}
                style={{ width: 30, height: 30 }}
                disabled={!isReadyToPack}>
                {isReadyToPack ? (
                  <CancelOutlinedIcon style={{ fontSize: "28px", color: "red" }} />
                ) : (
                  <CancelOutlinedIcon style={{ fontSize: "28px", color: "grey" }} />
                )}
              </IconButton>
            </Tooltip>
          );
        },
      }),
    [closeDialog, showDialog],
  );

  const ADMIN_START_COLUMN = useMemo(
    () => [
      columnHelper.accessor("checkbox", {
        header: "",
        headerStyle: { minWidth: 70, paddingLeft: 0, paddingRight: 0 },
        cell: ({ row }) => (
          <PrescriptionCheckBox rowData={row.original} tableRow={row} prescriptionAction={handleSelectPrescription} />
        ),
      }),
      columnHelper.accessor("date", {
        header: "Date",
        headerStyle: { minWidth: "105px", width: "105px", textAlign: "center" },
      }),
      columnHelper.accessor("rxOrder", {
        header: "View Order",
        headerStyle: { minWidth: "50px", textAlign: "center", paddingLeft: 0, paddingRight: 0 },
        cell: props => (
          <IconButton onClick={() => toggleDetailModal(props.row.original.id)} style={{ width: 30, height: 30 }}>
            <VisibilityIcon style={{ fontSize: "20px" }} className="medText" />
          </IconButton>
        ),
      }),
      columnHelper.accessor("rxOrderInput", {
        header: "RX Order",
        headerStyle: { minWidth: "50px", paddingRight: 0 },
        cell: props => {
          const rowData = props.row.original;
          return (
            <PrescriptionOrderInput
              id={rowData?.id}
              rxOrderInput={rowData?.rxOrderInput}
              setCustomError={setCustomError}
            />
          );
        },
      }),
      columnHelper.accessor("invoiceNumber", {
        header: "Invoice ",
        headerStyle: { minWidth: "100px", width: "150px", paddingRight: 0 },
        cell: props => (
          <ButtonBase
            onClick={() => toggleInvoiceModal(props.row.original.id)}
            style={{ textAlign: "left" }}
            className="medText">
            {props.getValue()}
          </ButtonBase>
        ),
      }),
      columnHelper.accessor("notes", {
        header: "Notes",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
        cell: ({ row }) => (
          <IconButton
            onClick={() =>
              toggleNoteModal(row.original.id, row.original.message, row.original.createdByName, row.original.created)
            }
            style={{ width: 40, height: 40 }}>
            <EditNoteIcon style={{ fontSize: "30" }} className="medText" />
          </IconButton>
        ),
      }),
      columnHelper.accessor("slip", {
        header: "Slip",
        headerStyle: { paddingRight: "8px", paddingLeft: "8px", textAlign: "center" },
        cell: ({ row }) => (
          <Tooltip title="Packing Slip">
            <IconButton onClick={() => togglePackingSlipModal(row.original.id)} style={{ width: 40, height: 40 }}>
              <ReceiptLongIcon style={{ fontSize: "30" }} className="medText" />
            </IconButton>
          </Tooltip>
        ),
      }),
    ],
    [
      handleSelectPrescription,
      setCustomError,
      toggleDetailModal,
      toggleInvoiceModal,
      toggleNoteModal,
      togglePackingSlipModal,
    ],
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: [...ADMIN_START_COLUMN, ...ADMIN_RX_WORKFLOW_COLUMNS, ADMIN_PRESCRIPTION_ACTION],
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    enableRowSelection: row => row.original.prescriptionStatus !== "Ready To Pack",
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
  });

  return (
    <>
      {showSpinner && <Spinner />}
      <Row className="my-3 px-3">
        <h5>Filter Prescriptions</h5>
        <Col xs={6} sm={5} lg={2}>
          <div className="mb-3 row">
            <span>
              <span>
                <RXStatusDD
                  loc={"workflow"}
                  filterRX={true}
                  clearStatus={disableFilter}
                  updatedFilterRxStatusValue={updateFilterRxStatus}
                />
              </span>
            </span>
          </div>
        </Col>
        <Col sm={12} md={3} lg={3} className="m-0">
          <button disabled={disableFilter} className="yellowButton" onClick={applyFilter}>
            Filter
          </button>
          {!disableFilter && (
            <i
              disabled={disableFilter}
              className="fas fa-times redText"
              style={{ fontSize: 24, marginLeft: 18 }}
              onClick={clearFilter}
            />
          )}
        </Col>
        <Col className="text-end">
          <DropdownButton
            as={ButtonGroup}
            title="Export CSV"
            id="export-dropdown"
            style={{ marginLeft: "30px", minWidth: "10rem" }}>
            <Dropdown.Item eventKey="1" onClick={exportPrescriptionList}>
              Normal
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={e => exportPrescriptionList(e, true)}>
              Master
            </Dropdown.Item>
          </DropdownButton>
        </Col>
      </Row>
      <ListingSearch
        searchKeyword={searchKeyword}
        selectedFilter={selectedFilter}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
        leftChildren={completeOrderBtnView}
        updateFilterType={updateFilterType}
        filterOptions={PRESCRIPTION_FILTER_OPTIONS}
      />
      {successMessage ? <AlertMessage msg={successMessage} /> : <AlertMessage msg={combinedErrorMessage} isError={true} />}
      <Col className="text-end" style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Col>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                <TableCell />
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}>
                    <Typography variant="tableHeader" noWrap>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => (
              <MuiTableCollapse
                key={row.id}
                row={row}
                nestedEditable={row.getCanSelect() && userRole !== USER_ROLES.SALES_REP && !row.original.isRecreated}
                pageName={LISTING_NAME.ADMIN_RX_WORKFLOW}
                filterRxStatus={filterRxStatus}
                setCustomError={setCustomError}
                toggleUpdateModal={toggleUpdateModal}
                showMarkAllBtn={!row.getCanSelect()}
                isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />

      {(selectedShippingCost?.toString() === "0" || selectedShippingCost) && selectedShippingInfo && (
        <UpdateShippingModal
          setSelectedShippingInfo={setSelectedShippingInfo}
          setSelectedShippingCost={setSelectedShippingCost}
          setSelectedPrescriptions={setRowSelection}
          selectedShippingCost={selectedShippingCost}
          selectedShippingInfo={selectedShippingInfo}
          openShippingModal={openShippingModal}
          setOpenShippingModal={setOpenShippingModal}
          selectedPrescriptions={keys(rowSelection)}
          selectPrescriptionsData={prescriptions?.filter(prescription => keys(rowSelection).includes(prescription.id))}
          sameAddress={prescriptions
            ?.filter(pres => keys(rowSelection)?.includes(pres.id))
            ?.every(
              (prescription, index, array) =>
                JSON.stringify(prescription?.shippingInfo?.shippingAddress) ===
                JSON.stringify(array[0]?.shippingInfo?.shippingAddress) &&
                prescription?.shippingInfo &&
                prescription?.shippingInfo?.shippingAddress &&
                prescription?.shippingInfo?.shippingAddress?.addressLine1 &&
                prescription?.shippingInfo?.shippingAddress?.city &&
                prescription?.shippingInfo?.shippingAddress?.zipcode &&
                prescription?.shippingInfo?.shippingAddress?.state &&
                prescription?.shippingInfo?.shippingMethod !== "PickUp" &&
                keys(rowSelection).length > 0,
            )}
        />
      )}
      <InvoiceDetailModal invoiceId={invoiceId} handleClose={toggleInvoiceModal} />
      <OrderViewModal prescriptionId={openUpdateModal ? "" : prescriptionId} handleClose={toggleDetailModal} />
      <PrescriptionUpdateModal
        modalTitle={openUpdateModal}
        prescriptionId={prescriptionId}
        handleClose={toggleUpdateModal}
      />
      <OrderPackingSlipModal prescriptionId={openPackingSlipModal} handleClose={togglePackingSlipModal} />
      <PrescriptionNoteModal
        modalData={openNoteModal}
        pageName={LISTING_NAME.ADMIN_RX_WORKFLOW}
        handleClose={toggleNoteModal}
      />
    </>
  );
}

export default RXWorkflowPrescriptionsSection;
