import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";

const StateDD = props => {
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    if (props.defaultValue) {
      const myArray = props.defaultValue.toLowerCase().split(" ");
      let state = "";
      for (var i = 0; i < myArray.length; i++) {
        var val = myArray[i].charAt(0).toUpperCase() + myArray[i].slice(1);
        state += val;

        if (i < myArray.length - 1) {
          state += " ";
        }
      }
      setDefaultValue(state);
    }
  }, [props]);

  return (
    <FormGroup className="mb-4" key={defaultValue}>
      <Label className="normalText" for={props.name}>
        {props.name === "providerLicensingState" ? "Licensing State" : "State"}
        {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        name={props.name}
        required={props.required}
        defaultValue={defaultValue}
        onChange={e => {
          if (props.onChange) {
            props.onChange(e);
          }
        }}>
        <option value="" disabled>
          Select a State
        </option>
        <option value="Alabama">Alabama</option>
        <option value="Alaska">Alaska</option>
        <option value="Arizona">Arizona</option>
        <option value="Arkansas">Arkansas</option>
        <option value="California">California</option>
        <option value="Colorado">Colorado</option>
        <option value="Connecticut">Connecticut</option>
        <option value="Delaware">Delaware</option>
        <option value="District Of Columbia">District Of Columbia</option>
        <option value="Florida">Florida</option>
        <option value="Georgia">Georgia</option>
        <option value="Hawaii">Hawaii</option>
        <option value="Idaho">Idaho</option>
        <option value="Illinois">Illinois</option>
        <option value="Indiana">Indiana</option>
        <option value="Iowa">Iowa</option>
        <option value="Kansas">Kansas</option>
        <option value="Kentucky">Kentucky</option>
        <option value="Louisiana">Louisiana</option>
        <option value="Maine">Maine</option>
        <option value="Maryland">Maryland</option>
        <option value="Massachusetts">Massachusetts</option>
        <option value="Michigan">Michigan</option>
        <option value="Minnesota">Minnesota</option>
        <option value="Mississippi">Mississippi</option>
        <option value="Missouri">Missouri</option>
        <option value="Montana">Montana</option>
        <option value="Nebraska">Nebraska</option>
        <option value="Nevada">Nevada</option>
        <option value="New Hampshire">New Hampshire</option>
        <option value="New Jersey">New Jersey</option>
        <option value="New Mexico">New Mexico</option>
        <option value="New York">New York</option>
        <option value="North Carolina">North Carolina</option>
        <option value="North Dakota">North Dakota</option>
        <option value="Ohio">Ohio</option>
        <option value="Oklahoma">Oklahoma</option>
        <option value="Oregon">Oregon</option>
        <option value="Pennsylvania">Pennsylvania</option>
        <option value="Rhode Island">Rhode Island</option>
        <option value="South Carolina">South Carolina</option>
        <option value="South Dakota">South Dakota</option>
        <option value="Tennessee">Tennessee</option>
        <option value="Texas">Texas</option>
        <option value="Utah">Utah</option>
        <option value="Vermont">Vermont</option>
        <option value="Virginia">Virginia</option>
        <option value="Washington">Washington</option>
        <option value="West Virginia">West Virginia</option>
        <option value="Wisconsin">Wisconsin</option>
        <option value="Wyoming">Wyoming</option>
      </Input>
    </FormGroup>
  );
};

export default StateDD;
