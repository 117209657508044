import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";

const ClinicAddressDD = props => {
  const [address, setAddress] = useState([]);
  const [addressObject, setAddressObject] = useState({});
  useEffect(() => {
    let defaultValue = props.defaultValue ? props.defaultValue : {};
    if (props.locationID && props.step===4 && (props.isBulkOrder ===true || props.shipToClinic)) {
      axiosConfig
        .get("clinicLocations/getClinicLocationAddressByClinicLocationId/" + props.locationID, {
          headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
        })
        .then(async response => {
          let add = [];
          add.push(
            <option
              key={address.id}
              selected={
                defaultValue.addressLine1 === address.addressLine1 &&
                defaultValue.addressLine2 === address.addressLine2 &&
                defaultValue.city === address.city &&
                defaultValue.state === address.state &&
                defaultValue.zipcode === address.zipcode
              }
              value={address.id}>
              {response.data.addressLine2
                ? response.data.addressLine1 +
                  ", " +
                  response.data.addressLine2 +
                  ", " +
                  response.data.city +
                  ", " +
                  response.data.state +
                  ", " +
                  response.data.zipcode
                : response.data.addressLine1 +
                  ", " +
                  response.data.city +
                  ", " +
                  response.data.state +
                  ", " +
                  response.data.zipcode}
            </option>,
          );

          ///

          if (props.editBool===true &&
            defaultValue?.addressLine1 &&
            response.data?.addressLine1?.replace(/\s/g, " ")?.trim()?.toUpperCase() !==
              defaultValue?.addressLine1?.replace(/\s/g, " ")?.trim()?.toUpperCase()
          ) {
            add.push(
              <option key={address.id} selected={true} value={address.id}>
                {defaultValue.addressLine2
                  ? defaultValue.addressLine1 +
                    ", " +
                    defaultValue.addressLine2 +
                    ", " +
                    defaultValue.city +
                    ", " +
                    defaultValue.state +
                    ", " +
                    defaultValue.zipcode
                  : defaultValue.addressLine1 +
                    ", " +
                    defaultValue.city +
                    ", " +
                    defaultValue.state +
                    ", " +
                    defaultValue.zipcode}
              </option>,
            );
            await props.setSelectedAddress({
              addressLine1: defaultValue?.addressLine1,
              addressLine2: defaultValue?.addressLine2,
              city: defaultValue?.city,
              state: defaultValue?.state,
              zipcode: defaultValue?.zipcode,
            });
            
          }
          
          //
          setAddress(add);
          if (props.step>=4 || props.isBulkOrder===true){
          
          await setAddressObject({
            addressLine1: response.data.addressLine1,
            addressLine2: response.data.addressLine2,
            city: response.data.city,
            state: response.data.state,
            zipcode: response.data.zipcode,
          });
          await props.setSelectedAddress({
            addressLine1: response.data.addressLine1,
            addressLine2: response.data.addressLine2,
            city: response.data.city,
            state: response.data.state,
            zipcode: response.data.zipcode,
          });
        }
        })
        .catch(error => {
          errorResponse(error);
        });
    }
  }, [props.locationID, props.defaultValue,props.isBulkOrder,props.shipToClinic,props.step]);

  useEffect(() => {
    if (props.toggleShipTo != 0 && props.step>=3) {
      
      props.setSelectedAddress({
        addressLine1: addressObject.addressLine1,
        addressLine2: addressObject.addressLine2,
        city: addressObject.city,
        state: addressObject.state,
        zipcode: addressObject.zipcode,
      });
      let a = [];
      a.push(
        <option key={"clinic-loc" + props.locationID} selected={true} value={"clinic-loc" + props.locationID}>
          {addressObject.addressLine2
            ? addressObject.addressLine1 +
              ", " +
              addressObject.addressLine2 +
              ", " +
              addressObject.city +
              ", " +
              addressObject.state +
              ", " +
              addressObject.zipcode
            : addressObject.addressLine1 +
              ", " +
              addressObject.city +
              ", " +
              addressObject.state +
              ", " +
              addressObject.zipcode}
        </option>,
      );
      setAddress(a);
    }
  }, [props.toggleShipTo, addressObject]);

  return (
    <FormGroup className="dropdownContainer" key={props}>
      <Label className="normalText" for={props.name}>
        Clinic Address {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        name={props.name}
        required={props.required}
        onChange={e => {
          if (props.setSelectedAddress) {
            
            props.setSelectedAddress(addressObject);
          }
        }}>
        {address}
      </Input>
    </FormGroup>
  );
};

export default ClinicAddressDD;
