import React, { useState, useEffect } from "react";
import { Row, Col, Label, Input } from "reactstrap";
import StateDD from "../../../components/dropdowns/stateDD";
import ShippingMethodDD from "../../../components/dropdowns/shippingPreferenceDD";
import ValidAddressModal from "components/validAddressModal";
import ClinicAddressDD from "components/dropdowns/clinicAddressDD";

function PatientInfo3(props) {
  const { patientAddress } = props;

  const [shipToClinic, setClinicToShip] = useState(false);

  const [sameAsPA, setSameAsPA] = useState(false);
  const [patient, setPatient] = useState({});

  /*Shipping Info */
  const [shippingAddressLine1, setShippingAddressLine1] = useState("");
  const [shippingAddressLine2, setShippingAddressLine2] = useState("");
  const [shippingZipcode, setShippingZipcode] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  /*Shipping Info */

  useEffect(() => {
    if (props.patient) {
      setPatient(props.patient);
    }
    if (sameAsPA) {
      setPatient(props.defaultPatientAddress);
      setShippingAddressLine1(props.defaultPatientAddress.addressLine1);
      setShippingAddressLine2(props.defaultPatientAddress.addressLine2);
      setShippingZipcode(props.defaultPatientAddress.zipcode);
      setShippingState(props.defaultPatientAddress.state);
      setShippingCity(props.defaultPatientAddress.city);
    }
  }, [sameAsPA]);

  useEffect(() => {
    setClinicToShip(props.isShipToClinic);
  }, [props.isShipToClinic]);

  useEffect(() => {
    //console.log('Patient');
    //console.log(props.patientShippingAddress);
    //console.log('---');
    setShippingAddressLine1(props.patientShippingAddress.shippingAddressLine1);
    setShippingAddressLine2(props.patientShippingAddress.shippingAddressLine2);
    setShippingZipcode(props.patientShippingAddress.shippingZipcode);
    setShippingState(props.patientShippingAddress.shippingState);
    setShippingCity(props.patientShippingAddress.shippingCity);
  }, [props.patientShippingAddress]);

  function handleZipcodeInputKeyDown(e) {}

  return (
    <div className={`mt-4 ${props.step === 3 ? "d-block" : "d-none"}`} key={patient}>
      <Row>
        <Col md={12}>
          <div className="my-5">
            <input
              onChange={(e) => {
                props.setSSameAsDefaultPA();
                setSameAsPA(e.target.checked);
              }}
              name="SAsameAsDefaultPA"
              className="form-check-input"
              type="checkbox"
              id="SAsameAsDefaultPA"
            />
            <label className="form-check-label mx-2" htmlFor="SAsameAsDefaultPA">
              Same as default patient address
            </label>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <div className="mb-4" key={patient}>
            <Label htmlFor="shippingAddressLine1" className="form-label">
              Shipping Address line 1 <span className="requiredStar">*</span>
            </Label>

            <Input
              maxLength={50}
              value={shippingAddressLine1}
              onChange={(e) => setShippingAddressLine1(e.target.value)}
              name="shippingAddressLine1"
              className="form-control"
              type="textarea"
              required={props.step === 3 ? true : false}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-4">
            <Label htmlFor="shippingAddressLine2" className="form-label">
              Shipping Address line 2
            </Label>
            <Input
              maxLength={50}
              value={shippingAddressLine2}
              onChange={(e) => setShippingAddressLine2(e.target.value)}
              name="shippingAddressLine2"
              className="form-control"
              type="textarea"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="shippingCity" className="form-label">
              City <span className="requiredStar">*</span>
            </Label>
            <Input
              value={shippingCity}
              onChange={(e) => setShippingCity(e.target.value)}
              name="shippingCity"
              className="form-control"
              type="text"
              required={props.step === 3 ? true : false}
            />
          </div>
        </Col>
        <Col md={4}>
          <StateDD
            defaultValue={shippingState}
            onChange={(e) => setShippingState(e.target.value)}
            name="shippingState"
            required={props.step === 3}
          />
        </Col>
        <Col md={4}>
          <div className="mb-4">
            <Label htmlFor="shippingZipcode" className="form-label">
              Zip code <span className="requiredStar">*</span>
            </Label>

            <input
              value={shippingZipcode}
              pattern="^\s*?\d{5}(?:[-\s]\d{4})?\s*?$"
              name="shippingZipcode"
              onChange={(e) => setShippingZipcode(e.target.value)}
              className="form-control"
              onKeyDown={(e) => handleZipcodeInputKeyDown(e)}
              required={props.step === 3 ? true : false}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <ShippingMethodDD
            defaultValue={patient ? (sameAsPA ? "" : patient.shippingPreference) : ""}
            name="shippingPreference"
            required={props.step === 3}
          />
        </Col>
        <Col md={4}>
          <div className="mt-4">
            <input
              defaultValue={patient ? patient.isPox : ""}
              onChange={(e) => {
                if (e.target.checked) {
                  for (let i = 0; i < document.getElementsByName("shippingPreference")[0].options.length; i++) {
                    if (
                      document.getElementsByName("shippingPreference")[0].options[i].innerText ===
                      "USPS 1-Day (PO BOX ONLY)"
                    ) {
                      document.getElementsByName("shippingPreference")[0].selectedIndex = i;
                    }
                  }
                }
              }}
              name="POBox"
              className="form-check-input"
              type="checkbox"
              id="POBox"
            />
            <label className="form-check-label mx-2" htmlFor="POBox">
              PO Box
            </label>
          </div>
        </Col>
      </Row>
      <ValidAddressModal
        showValidAddressModal={props.showValidateModal}
        fedexShippingAddress={props.fedexShippingAddress}
        patientShippingAddress={props.patientShippingAddress}
        onClickNotValidate={props.onClickNotValidate}
        onClickValidate={props.onClickValidate}
        closeModal={props.closeModal}
      />
    </div>
  );
}

export default PatientInfo3;
