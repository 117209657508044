import { forEach, map, set } from "lodash";
import { StaffApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, PAGE_TO_ENDPOINT, STAFF_END_POINT } from "../../rtkConstant";
import { UploadsApiEndpoints } from "../Uploads";
import { CometChatEndpoints } from "../CometChat";

const StaffMutation = StaffApi.injectEndpoints({
  endpoints: builder => ({
    insertStaff: builder.mutation({
      queryFn: async ({ isEditProfile = false, ...apiArgs }, { dispatch, getState }, _, baseQuery) => {
        try {
          const user = getState().AuthSlice || {};
          if (user?.userData) {
            const clinicId = apiArgs?.clinicId || user?.userData?.clinicId;
            const organizedBody = {
              ...apiArgs,
              clinicLocationIds: apiArgs?.staff?.clinicLocationIds,
              clinicId,
              userId: user?.userData?.id,
            };
            const url = apiArgs?.staffId
              ? isEditProfile
                ? STAFF_END_POINT.EDIT_PROFILE_BY_ID
                : STAFF_END_POINT.EDIT_STAFF_BY_ID
              : STAFF_END_POINT.ADD_STAFF;

            //Uploading Files
            if (organizedBody.staff.stateLicenseUrl instanceof File) {
              const licenseFormData = new FormData();
              licenseFormData.append("file", organizedBody.staff.stateLicenseUrl);
              const fileUploadedResult = await dispatch(
                UploadsApiEndpoints.uploadFile.initiate(licenseFormData),
              ).unwrap();
              if (fileUploadedResult?.accessUrl) {
                set(organizedBody, ["staff", "stateLicenseUrl"], fileUploadedResult?.accessUrl);
              }
            }
            const organizedDea = await Promise.all(
              map(organizedBody.staff.deaInfo, async deaInfo => {
                if (deaInfo.deaLicenseUrl instanceof File) {
                  const deaLicenseFormData = new FormData();
                  deaLicenseFormData.append("file", deaInfo.deaLicenseUrl);
                  const fileUploadedResult = await dispatch(
                    UploadsApiEndpoints.uploadFile.initiate(deaLicenseFormData),
                  ).unwrap();
                  return { ...deaInfo, deaLicenseUrl: fileUploadedResult.accessUrl };
                } else {
                  return deaInfo;
                }
              }),
            );

            set(organizedBody, ["staff", "deaInfo"], organizedDea);

            const response = await baseQuery({
              url,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch (error) {
          return {
            error: {
              data: { message: error?.data?.message ?? "Unknown error occur" },
            },
          };
        }
      },
      async onQueryStarted(apiArgs, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          if (apiArgs?.staffId) {
            dispatch(StaffApi.util.invalidateTags([{ type: CACHE_TAGS.STAFF, id: apiArgs?.staffId }]));
          } else {
            dispatch(StaffApi.util.invalidateTags([CACHE_TAGS.STAFF]));
            // Comet chat User Addition
            // dispatch(
            //   CometChatEndpoints.addCometUser.initiate({
            //     uid: data?.id,
            //     name: (apiArgs?.staff?.firstName ?? "") + " "(apiArgs?.staff?.lastName ?? ""),
            //     role: apiArgs?.staff?.role,
            //     clinicName: data?.clinicName,
            //   }),
            // );
          }
        } catch {}
      },
    }),

    editStaff: builder.mutation({
      query: apiArgs => ({
        url: STAFF_END_POINT.EDIT_STAFF_BY_ID,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
      invalidatesTags: (result, error, apiArgs) => [{ type: CACHE_TAGS.STAFF, id: apiArgs?.payload?.staffId }],
    }),
    editProfile: builder.mutation({
      query: apiArgs => ({
        url: STAFF_END_POINT.EDIT_PROFILE_BY_ID,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    updateStaff: builder.mutation({
      query: apiArgs => ({
        url: STAFF_END_POINT.UPDATE_STAFF_BY_ID + "/" + apiArgs.params,
        method: API_METHODS.PUT,
        body: apiArgs.payload,
      }),
    }),
    addStaff: builder.mutation({
      query: apiArgs => ({
        url: STAFF_END_POINT.ADD_STAFF,
        method: API_METHODS.POST,
        body: apiArgs.payload,
      }),
    }),
    // DELETE_STAFF_BY_ID
    deleteStaff: builder.mutation({
      queryFn: async ({ pageName, ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { ...apiArgs, userId: user?.userData?.id };
            const response = await baseQuery({
              url: STAFF_END_POINT.DELETE_STAFF_BY_ID,
              method: API_METHODS.DELETE,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      async onQueryStarted({ pageName = "", staffId = "" }, { queryFulfilled, dispatch, getState }) {
        try {
          if (pageName) {
            await queryFulfilled;
            const listingEndpoint = PAGE_TO_ENDPOINT[pageName];
            let queryCache = StaffApi.util.selectInvalidatedBy(getState(), [{ type: CACHE_TAGS.STAFF, id: staffId }]);
            if (queryCache.length > 1) {
              queryCache = queryCache.filter(query => query.endpointName === listingEndpoint);
            }
            forEach(queryCache, ({ originalArgs }) => {
              dispatch(
                StaffApi.util.updateQueryData(listingEndpoint, originalArgs, listData => {
                  return {
                    ...listData,
                    staff: listData.staff?.filter(record => record?.id !== staffId),
                  };
                }),
              );
            });
            console.log("HERE: ", staffId);
            // Comet chat User deletion
            dispatch(CometChatEndpoints.deleteCometUser.initiate(staffId));
          }
        } catch {}
      },
    }),
  }),
});
export const {
  useInsertStaffMutation,
  useEditProfileMutation,
  useEditStaffMutation,
  useAddStaffMutation,
  useUpdateStaffMutation,
  useDeleteStaffMutation,
} = StaffMutation;
