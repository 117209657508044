import Lottie from "components/lottie";
import React, { useRef } from "react";
import { Alert, Modal, Row, Col, Label } from "reactstrap";
import Spinner from "../spinner";
import StateDD from "components/dropdowns/stateDD";
/*Hook*/

export const AddNewAddress = (props) => {
  const { closeModal, modalOpen, setNewAddress } = props;
  console.log("MODAL: ", modalOpen);
  return (
    <Modal isOpen={modalOpen} scrollable={true} size="lg" centered={true} zIndex={1300}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add New Address</h5>
        <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form
          id="changeAddressForm"
          className="form-horizontal px-sm-4 px-0"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setNewAddress(e);
          }}
        >
          <Row>
            <div className="mb-4">
              <Label htmlFor="addressLine1" className="form-label">
                Address line 1 <span className="requiredStar">*</span>
              </Label>
              <input maxLength={50} name="addressLine1" className="form-control" type="textarea" required />
            </div>
            <div className="mb-4">
              <Label htmlFor="addressLine2" className="form-label">
                Address line 2
              </Label>
              <input maxLength={50} name="addressLine2" className="form-control" type="textarea" />
            </div>
            <div className="mb-4">
              <Label htmlFor="city" className="form-label">
                City <span className="requiredStar">*</span>
              </Label>
              <input name="city" className="form-control" type="text" required />
            </div>
            <StateDD name="state" required={true} />
            <div className="mb-4">
              <Label htmlFor="zipcode" className="form-label">
                Zip code <span className="requiredStar">*</span>
              </Label>
              <input name="zipcode" className="form-control" required />
            </div>
          </Row>
          <Row className="flexJCAC">
            <button className="pinkButton" type="submit">
              {"Add"} Address
            </button>
          </Row>
        </form>
      </div>
    </Modal>
  );
};
