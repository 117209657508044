import React, { Component } from "react";
import { Row, Col, Alert, Modal, Input, Label } from "reactstrap";
import ClinicCreditCardsDD from "../../components/dropdowns/clinicCreditCardsDD";
import PatientCreditCardsDD from "../../components/dropdowns/patientCreditCardsDD";
import StateDD from "../../components/dropdowns/stateDD";
import { USER_ROLES } from "utils";
import moment from "moment";
import CreditCardInput from "react-credit-card-input";
import Cookies from "universal-cookie";
import { CreditBalance } from "components/credit-balance";
import { NOTES } from "../../components/PaymentGrid/PaymentGridConstants";
class AddBillingInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      newCard: "",
      newCardObj: {},
      billTo: "clinic",
      error: false,
      count: 1,
    };
    localStorage.removeItem("rxJourney");
    localStorage.removeItem("shipAddress1");
    localStorage.removeItem("shipMethod");
  }

  setCreditCard(card, billTo) {
    this.props.setCreditCard(card, false, billTo);
    this.setState({
      billTo: billTo,
      newCard: "",
      newCardObj: {},
    });
  }
  incrementIt() {
    this.setState({
      count: this.state.count + 1,
    });
  }

  addNewCreditCard() {
    var serialize = require("form-serialize");
    var form = document.getElementById("addNewCreditCardForm");
    var obj = serialize(form, { hash: true });

    var card = {
      creditCardNumber: obj.creditCardNumber.replace(/\s/g, ""),
      cardHolderName: obj.cardHolderName,
      // cvv: obj.cvv,
      cardExpirationDate: obj.cardExpirationDate.replace(/\s/g, ""),
      cardHolderAddressLine1: obj.cardHolderAddressLine1,
      cardHolderAddressLine2: obj.cardHolderAddressLine2,
      cardHolderState: obj.cardHolderState,
      cardHolderCity: obj.cardHolderCity,
      cardHolderZipcode: obj.cardHolderZipcode,
    };

    document.getElementsByName("billingCard")[0].value = "";

    this.props.setCreditCard(card, true, this.state.billTo);

    this.setState({
      modalOpen: false,
      newCardObj: card,
      newCard:
        obj.cardHolderName +
        " - card ending in " +
        card.creditCardNumber.substring(card.creditCardNumber.length - 4, card.creditCardNumber.length),
    });
  }
  onChangeValue(e) {
    this.setState({ billTo: e.target.value });
  }

  setTotalCreditUsed() {}

  render() {
    // console.log("SUB-TOTAL HERE");
    // console.log(this.props.subtotal);
    // console.log("----END----");
    return (
      <div className={`m-sm-auto m-0 flexJCAC flex-column`}>
        <Row className="w-75">
          {this.props.isBulkOrder ? (
            <p className="yellowButton">
              <i className="fas fa-exclamation-circle"></i> Bulk Orders will be billed to clinic only!
            </p>
          ) : null}
          {/* {this.props.isNet30 ? (
          <Col>
            <p style={{ color: "white", background: "#E8B24C", padding: "10px" }}>
              {" "}
              <i className="fa fa-exclamation-circle"></i> Clinic is <strong>Net 30</strong>. Please enter your credit
              card details but it will be charged after 30 days.
            </p>
          </Col>
        ) : null} */}
          {new Cookies().get("user_role") === USER_ROLES.CLINIC_SUPPORT ? (
            <p className="yellowText">
              {" "}
              <i className="fa fa-exclamation-circle"></i> Support cannot order a prescription!
            </p>
          ) : null}
          <Row className="mt-2">
            <p>
              <h5 className="semiBold medText">Select Credit Card</h5>
            </p>
          </Row>
          <Row className="">
            {this.props.isBulkOrder && this.props.clinicLocationId ? (
              <ClinicCreditCardsDD
                defaultValue={!this.props.defaultBillingInfo ? "" : this.props.defaultBillingInfo.creditCard}
                setCreditCard={this.setCreditCard.bind(this)}
                locationID={this.props.clinicLocationId}
                name="billingCard"
                required={this.props.step === 5 && !this.state.newCard ? true : false}
              />
            ) : (
              <>
                <p className="semiBold medText mb-3">Bill to</p>
                <div onChange={this.onChangeValue.bind(this)} className="mb-4">
                  {/* For now Bill to is Clinic */}
                  <input type="radio" value="clinic" name="billto" checked={true} /> Clinic
                </div>
                {this.state.billTo === "patient" && (
                  <PatientCreditCardsDD
                    defaultValue={!this.props.defaultBillingInfo ? "" : this.props.defaultBillingInfo.creditCard}
                    setCreditCard={this.setCreditCard.bind(this)}
                    name="billingCard"
                    patientID={this.props.selectedPatients[0]}
                    required={this.props.step === 5 && !this.state.newCard ? true : false}
                  />
                )}
                {this.state.billTo === "clinic" && (
                  <ClinicCreditCardsDD
                    defaultValue={!this.props.defaultBillingInfo ? "" : this.props.defaultBillingInfo.creditCard}
                    setCreditCard={this.setCreditCard.bind(this)}
                    locationID={this.props.clinicLocationId}
                    name="billingCard"
                    required={this.props.step === 5 && !this.state.newCard ? true : false}
                  />
                )}
              </>
            )}

            {this.state.newCard ? (
              <div className="text-start mt-4">
                <p className="semiBold medText">New Billing Card: </p>
                <p className="mx-2">{this.state.newCard}</p>
              </div>
            ) : null}

            {new Cookies().get("user_role") === USER_ROLES.CLINIC_SUPPORT || new Cookies().get("user_role") === USER_ROLES.PHARMACIST || new Cookies().get("user_role") === USER_ROLES.RX_TECH ||
            new Cookies().get("user_role") === USER_ROLES.CLINIC_PROVIDER ? null : (
              <Row className="text-center w-100 mt-3">
                <Col>
                  <button
                    className="pinkButton my-3"
                    onClick={e => {
                      e.preventDefault();
                      this.setState({ modalOpen: true });
                    }}>
                    {this.state.newCard ? "Update" : "Add"} new Credit Card
                  </button>
                </Col>
              </Row>
            )}
          </Row>
          <hr></hr>
          <CreditBalance
            count={this.state.count}
            incrementIt={this.incrementIt.bind(this)}
            setDeleteCartCredit={this.props.setDeleteCartCredit}
            deleteCartCredit={this.props.deleteCartCredit}
            resetCreditApplied={this.props.resetCreditApplied.bind(this)}
            totalCredit={this.props.credit}
            creditRequested={this.props.creditRequested}
            // credit={this.props.credit}
            setCreditUsed={this.props.setCreditUsed.bind(this)}
            setCreditRequested={this.props.setCreditRequested.bind(this)}
            region="journey"
            total={this.props.subtotal}
            clinicId={this.props.clinic.id}
            clinicLocationId={this.props.clinicLocationId}
          />{" "}
          <hr></hr>
          <Row>
            <Col></Col>
            <h5 className="semiBold medText">Terms and Conditions</h5>
            {this.props.isNet30 ? (
              <>
                <p style={{ textDecoration: "underline" }}>Your clinic is currently under the terms of Net 15/30.</p>
                <ol className="mx-1" style={{ textAlign: "justify" }}>
                  <li>
                    Payment Obligations: You are responsible for making all payments due under the net 15/30 payment
                    terms in full by the payment due date. Failure to make timely payments may result in late fees and
                    interest charges
                  </li>
                  <li>
                    Credit Card: The card selected above will automatically be charged 30 days after the order is
                    compounded and left our facility, unless payment is manually made via the Invoices Page before the
                    30 days concludes
                  </li>
                  <li>
                    Late Payments: If you fail to make a payment on time, your account may be considered delinquent, and
                    additional fees and charges may be assessed. We reserve the right to suspend or terminate your
                    access to the net 15/30 payment terms if you fail to make timely payments
                  </li>
                  <li>
                    Collection: In the event that we need to take legal action to collect any amounts due under the net
                    30 payment terms, you agree to pay all costs and expenses, including reasonable attorney's fees,
                    incurred in connection with such action.
                  </li>
                </ol>
              </>
            ) : (
              <>
                <p style={{ textDecoration: "underline" }}>Your clinic is currently under credit card terms</p>
                <ol className="mx-1" style={{ textAlign: "justify" }}>
                  <li>
                    Payment Obligations: The credit card selected above will be used to pay for this order once the
                    order has been compounded and left our facility. If the wrong credit card is selected, VPI will not
                    be responsible for issuing a refund and charging the correct card.
                  </li>
                </ol>
              </>
            )}
          </Row>
        </Row>

        {/* <Row>
          <p className="redText my-3">{NOTES.RX_WORKFLOW_PAYMENT}</p>
        </Row> */}

        <Modal isOpen={this.state.modalOpen} scrollable={true} size="md" centered={true}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Add New Credit Card</h5>
            <button
              type="button"
              onClick={() => this.setState({ modalOpen: false })}
              className="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <form
              id="addNewCreditCardForm"
              className="form-horizontal px-sm-4 px-0"
              onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                /* var serialize = require('form-serialize');
                var form = serialize(e.target, { hash: true });
                if (form.cardHolderZipcode.length != 5 || !parseInt(form.cardHolderZipcode).isNan()) {
                  this.setState({
                    error: true,
                  });
                } else {
                  this.addNewCreditCard(e);
                }*/
                this.addNewCreditCard(e);
              }}>
              <Row>
                <div className="mb-4">
                  <Label htmlFor="cardHolderName" className="form-label">
                    Card Holder Name <span className="requiredStar">*</span>
                  </Label>
                  <Input
                    defaultValue={this.state.newCard ? this.state.newCardObj.cardHolderName : ""}
                    name="cardHolderName"
                    className="form-control"
                    type="text"
                    required
                  />
                </div>
                <div className="mb-4">
                  <Label htmlFor="creditCardNumber" className="form-label">
                    Card Number <span className="requiredStar">*</span>
                  </Label>
                  <CreditCardInput
                    fieldClassName="form-control"
                    cardCVCInputRenderer={({ handleCardCVCChange, props }) => (
                      <input
                        {...props}
                        style={{ display: "none" }}
                        defaultValue={this.state.newCard ? this.state.newCardObj.cvv : ""}
                        name="cvv"
                      />
                    )}
                    cardExpiryInputRenderer={({ handleCardExpiryChange, props }) => (
                      <input
                        {...props}
                        defaultValue={this.state.newCard ? this.state.newCardObj.cardExpirationDate : ""}
                        name="cardExpirationDate"
                        inputClassName="form-control"
                        required={this.props.step === 4 ? true : false}
                      />
                    )}
                    cardNumberInputRenderer={({ handleCardNumberChange, props }) => (
                      <input
                        {...props}
                        defaultValue={this.state.newCard ? this.state.newCardObj.creditCardNumber : ""}
                        name="creditCardNumber"
                        inputClassName="form-control"
                        required={this.props.step === 4 ? true : false}
                      />
                    )}
                    required={this.props.step === 4 ? true : false}
                  />
                </div>

                <div className="mb-4">
                  <Label htmlFor="cardHolderAddressLine1" className="form-label">
                    Address line 1 <span className="requiredStar">*</span>
                  </Label>
                  <Input
                    defaultValue={this.state.newCard ? this.state.newCardObj.cardHolderAddressLine1 : ""}
                    name="cardHolderAddressLine1"
                    className="form-control"
                    type="textarea"
                    required
                  />
                </div>
                <div className="mb-4">
                  <Label htmlFor="cardHolderAddressLine2" className="form-label">
                    Address line 2
                  </Label>
                  <Input
                    defaultValue={this.state.newCard ? this.state.newCardObj.cardHolderAddressLine2 : ""}
                    name="cardHolderAddressLine2"
                    className="form-control"
                    type="textarea"
                  />
                </div>
                <div className="mb-4">
                  <Label htmlFor="cardHolderCity" className="form-label">
                    City <span className="requiredStar">*</span>
                  </Label>
                  <Input
                    defaultValue={this.state.newCard ? this.state.newCardObj.cardHolderCity : ""}
                    name="cardHolderCity"
                    className="form-control"
                    type="text"
                    required
                  />
                </div>
                <StateDD
                  defaultValue={this.state.newCard ? this.state.newCardObj.cardHolderState : ""}
                  name="cardHolderState"
                  required={true}
                />
                <div className="mb-4">
                  <Label htmlFor="cardHolderZipcode" className="form-label">
                    Zip code <span className="requiredStar">*</span>
                  </Label>
                  <Input
                    defaultValue={this.state.newCard ? this.state.newCardObj.cardHolderZipcode : ""}
                    name="cardHolderZipcode"
                    className="form-control"
                    required
                  />
                </div>
              </Row>
              <Row className="flexJCAC">
                <button className="pinkButton" type="submit">
                  Add Credit Card
                </button>
              </Row>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default AddBillingInfo;
