import { encrypt } from "cryptofunc";
import moment from "moment";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUploadFileMutation } from "rtk";
import { useAddClinicMutation, useRegisterClinicMutation } from "rtk/rtkEndpoints/Clinics";
import { useLazyValidateAddressQuery } from "rtk/rtkEndpoints/Fedex";
import { userInfoSelector } from "selectors";
import { base64toFile } from "utils";
import { addUserToCometChat, chunkingFriends, getAdminList } from "utils/CometChatFunctions";

const useAddClinicHook = props => {
  const userInfo = useSelector(userInfoSelector);
  const addClinic = window?.location?.pathname.includes("add-clinic");
  const [agreementText, setAgreementText] = useState("");

  // State for company info
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    signatureUrl: "",
    name: "",
    title: "",
    date: "",
  });

  // State for VPI info
  const [vpiInfo, setVpiInfo] = useState({
    signatureUrl: "",
    name: "",
    title: "",
    date: "",
  });

  const [baaFormPDFUrl,setBaaFormPDFUrl] = useState('');

  const [
    registerClinicMutate,
    {
      error: registerClinicError,
      isError: isRegisterClinicError,
      isLoading: isRegisterClinicLoading,
      isSuccess: registerComplete,
    },
  ] = useRegisterClinicMutation();

  const [
    addClinicMutate,
    { error: addClinicError, isError: isAddClinicError, isLoading: isAddClinicLoading, isSuccess: addClinicComplete },
  ] = useAddClinicMutation();
  const [
    validateAddress,
    { error: validateAddressError, isError: isValidateAddressError, isFetching: isValidateAddressLoading },
  ] = useLazyValidateAddressQuery();
  const [uploadMutate, { error: uploadError, isError: isUploadError, isLoading: isUploadLoading }] =
    useUploadFileMutation();
  const errorMessage = useMemo(
    () =>
      addClinicError?.data?.message ||
      uploadError?.data?.message ||
      validateAddressError?.data?.message ||
      registerClinicError?.data?.message,
    [addClinicError, uploadError, validateAddressError, registerClinicError],
  );
  const showError = useMemo(
    () => isUploadError || isValidateAddressError || isRegisterClinicError || isAddClinicError,
    [isUploadError, isValidateAddressError, isRegisterClinicError, isAddClinicError],
  );
  const showLoading = useMemo(
    () => isUploadLoading || isValidateAddressLoading || isRegisterClinicLoading || isAddClinicLoading,
    [isUploadLoading, isValidateAddressLoading, isRegisterClinicLoading, isAddClinicLoading],
  );
  const complete = useMemo(() => registerComplete || addClinicComplete, [registerComplete, addClinicComplete]);

  const { state } = useLocation();

  const isRegen = state && state.isRegen ? state.isRegen : false;

  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [deaLicenseURL, setDeaLicenseURL] = useState("");
  const [stateLicenseURL, setStateLicenseURL] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [signatureModal, setSignatureModal] = useState("");
  const [billingSignModal, setBillingSignModal] = useState("");
  /*Fedex Address Validation*/
  const [addressVerification, setAddressVerification] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [fedexAddress, setFedexAddress] = useState("");
  const [clinicAddress, setClinicAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipcode: "",
    state: "",
  });

  const [deaAddressInfo, setDeaAddressInfo] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipcode: "",
    state: "",
  });

  const [onlyOneUser, setOnlyOneUser] = useState(false);

  const [standardClinicAddress, setStandardClinicAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    zipcode: "",
    state: "",
  });

  const [companyLogo, setCompanyLogo] = useState("");
  const [billingRepSignature, setBillingRepSignature] = useState("");
  /* */
  //useEffect(() => {}, [clinicAddress]);


  

  async function upload(e, name, signPad = "") {
    const reader = new FileReader();
    if (e) {
      let file = e.target.files[0];
      reader.onloadend = async () => {
        const base64String = reader.result;
        switch (name) {
          case "dea":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate(formData);
              if (res?.data) setDeaLicenseURL(res.data.accessUrl);
            } else setDeaLicenseURL(base64String);
            break;
          case "state":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate(formData);
              if (res?.data) setStateLicenseURL(res.data.accessUrl);
            } else setStateLicenseURL(base64String);
            break;
          case "companyLogo":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate(formData);
              if (res?.data) setCompanyLogo(res.data.accessUrl);
            } else setCompanyLogo(base64String);
            break;
          case "billingRepSign":
            if (addClinic) {
              const formData = new FormData();
              file = e.target.files[0];
              formData.append("file", file);
              let res = await uploadMutate(formData);
              if (res?.data) setBillingRepSignature(res.data.accessUrl);
            } else setBillingRepSignature(base64String);
            break;
          case "signatureImageUrl":
            setSignatureImageUrl(base64String);
            break;

          default:
            break;
        }
      };
      reader.readAsDataURL(file);
      setShowSpinner(false);
    } else {
      if (signPad) {
        switch (name) {
          case "billingRepSign":
            if (addClinic) {
              const formData = new FormData();
              const file = base64toFile(signPad);
              formData.append("file", file);
              let res = await uploadMutate(formData);
              if (res?.data) setBillingRepSignature(res.data.accessUrl);
            } else setBillingRepSignature(signPad);
            break;
          case "signatureImageUrl":
            setSignatureImageUrl(signPad);
            break;

          case "companyInfoSignatureImageUrl":
            setCompanyInfo(prevState => ({ ...prevState, signatureUrl:signPad }));
            break;
          default:
            break;
        }
      }
    }
  }

  function onChange() {
    setVerified(true);
  }

  const onClickNotValidate = () => {
    setClinicAddress({
      addressLine1: clinicAddress.addressLine1,
      addressLine2: clinicAddress.addressLine2 ? clinicAddress.addressLine2 : "-",
      city: clinicAddress.city,
      zipcode: clinicAddress.zipcode,
      state: clinicAddress.state,
    });
    setStandardClinicAddress({
      addressLine1: clinicAddress.addressLine1,
      addressLine2: clinicAddress.addressLine2 ? clinicAddress.addressLine2 : "-",
      city: clinicAddress.city,
      zipcode: clinicAddress.zipcode,
      state: clinicAddress.state,
    });

    setAddressVerification(true);
    closeModal();
  };
  const onClickValidate = () => {
    setClinicAddress({
      addressLine1: fedexAddress.addressLine1,
      addressLine2: fedexAddress.addressLine2 !== "" ? fedexAddress.addressLine2 : "-",
      city: fedexAddress.city,
      zipcode: fedexAddress.zipcode,
      state: fedexAddress.state,
    });
    setStandardClinicAddress({
      addressLine1: fedexAddress.addressLine1,
      addressLine2: fedexAddress.addressLine2 !== "" ? fedexAddress.addressLine2 : "-",
      city: fedexAddress.city,
      zipcode: fedexAddress.zipcode,
      state: fedexAddress.state,
    });

    setAddressVerification(true);
    closeModal();
  };

  const closeModal = () => {
    setShowValidateModal(false);
  };

  const createClinicSubmitPayload = (obj, side = "") => {
    var card = {
      creditCardNumber: obj.creditCardNumber.replace(/\s/g, ""),
      cardHolderFirstName: obj.cardHolderFirstName,
      cardHolderLastName: obj.cardHolderLastName,
      // cvv: obj.cvv,
      cardExpirationDate: obj.cardExpirationDate.replace(/\s/g, ""),
      cardHolderAddressLine1: obj.cardHolderAddressLine1,
      cardHolderAddressLine2: obj.cardHolderAddressLine2 ? obj.cardHolderAddressLine2 : "",
      cardHolderState: obj.cardHolderState,
      cardHolderCity: obj.cardHolderCity,
      cardHolderZipcode: obj.cardHolderZipcode,
      cardHolderContactNumber: obj.officeContactPhone,
    };

    const commonParams = {
      officeContactFirstName: obj.officeContactFirstName,
      officeContactLastName: obj.officeContactLastName,
      businessName: obj.businessName,
      providerFirstName: onlyOneUser ? obj.officeContactFirstName : obj.providerFirstName,
      providerLastName: onlyOneUser ? obj.officeContactLastName : obj.providerLastName,
      providerNpi: obj.providerNpi,
      providerDea: obj.providerDea,
      providerEmail: onlyOneUser ? obj.officeContactEmail : obj.providerEmail,
      providerPhone: onlyOneUser ? obj.officeContactPhone : obj.providerPhone,
      addressLine1: obj.addressLine1,
      addressLine2: obj.addressLine2,
      city: obj.city,
      email: obj.email,
      state: obj.state,
      isSuperUserSameAsProvider: onlyOneUser,
      officePhone: obj.officePhone,
      zipcode: obj.zipcode,
      fax: obj.fax,
      providerSignatureImage: signatureImageUrl ?? "",
      providerStateLicenseExpirationDate: obj.providerStateLicenseExpirationDate
        ? moment(obj.providerStateLicenseExpirationDate, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
      providerDeaLicenseExpirationDate: obj.providerDeaLicenseExpirationDate
        ? moment(obj.providerDeaLicenseExpirationDate, "YYYY-MM-DD").format("MM/DD/YYYY")
        : "",
      providerLicensingState: obj.providerLicensingState,
      verbalOrders: obj.verbalOrders,
      officeContactPhone: obj.officeContactPhone,
      officeContactEmail: obj.officeContactEmail,
      preferredBillingMethod: obj.preferredBillingMethod,
      partialShipmentsAvailableasNeeded: obj.partialShipmentsAvailableasNeeded,
      prescriptionRefillCommunication: obj.prescriptionRefillCommunication,
      refillManagement: obj.refillManagement,

      clinicWebsiteURL: obj.clinicWebsiteURL,
      howDidYouHearAboutVPICompounding: obj.howDidYouHearAboutVPICompounding,
      other: obj.other,

      billingRepresentativeFirstName: obj.billingRepresentativeFirstName,
      billingRepresentativeLastName: obj.billingRepresentativeLastName,
      billingRepresentativeEmail: obj.officeContactEmail,
      billingRepresentativePhone: obj.officeContactPhone,
      providerCredentials: obj.providerCredentials,
      mailOrderShippingAvailability:
        obj.mailOrderShippingAvailability === "other"
          ? document.getElementById("otherMailOrderText").value
          : obj.mailOrderShippingAvailability,
      encryptedCreditCard: encrypt(card),
      providerMedicalLicenseNumber: obj.providerMedicalLicenseNumber,
    };

    if (!side) {


      const apiParam = {
        ...commonParams,
        clinic: isRegen ? "RPA" : undefined,
        companyLogoBase64String: companyLogo,
        billingRepresentativeSignatureBase64String: billingRepSignature,
        providerStateLicenseBase64String: stateLicenseURL,
        providerDeaLicenseBase64String: deaLicenseURL,
        deaAddressInfo: deaAddressInfo,

        bAAForm:{
          agrementText:'',
          companyInfo:{
            companyName:companyInfo?.companyName,
            name:companyInfo?.name,
            title:companyInfo?.title,
            date:companyInfo?.date,
            companySignatureBase64String:companyInfo?.signatureUrl
          },
          vpiInfo:{
            name:vpiInfo?.name,
            title:vpiInfo?.title,
            date:vpiInfo?.date,
            vpiSignatureBase64String:vpiInfo?.signatureUrl
          },

        }
      };
      return apiParam;
    } else {
      const body = {
        userId: userInfo.id,
        clinic: {
          ...commonParams,
          companyLogoImageUrl: companyLogo,
          billingRepresentativeSignatureImageUrl: billingRepSignature,
          providerStateLicenseUrl: stateLicenseURL,
          providerDeaLicenseUrl: deaLicenseURL,
          deaInfo: [
            {
              dea: obj.providerDea,
              deaLicenseUrl: deaLicenseURL,
              providerDeaLicenseExpirationDate: obj.providerDeaLicenseExpirationDate
                ? moment(obj.providerDeaLicenseExpirationDate, "YYYY-MM-DD").format("MM/DD/YYYY")
                : "",
              deaAddressInfo: deaAddressInfo,
            },
          ],
         
          bAAFormPDFUrl:baaFormPDFUrl
        
        },
        bAAForm:{},
        bAAFormPDFUrl:baaFormPDFUrl
      };
      return body;
    }
  };
  /***/

  async function handleValidSubmit(e, side = "") {
    e.preventDefault();
    let serialize = require("form-serialize");
    let obj = serialize(document.getElementById("registerForm"), {
      hash: true,
    });
    if (step === 1) {
      // setVerified(false);
      if (
        addressVerification &&
        clinicAddress?.addressLine1 === standardClinicAddress?.addressLine1 &&
        clinicAddress?.addressLine2 === standardClinicAddress?.addressLine2 &&
        clinicAddress?.city === standardClinicAddress?.city &&
        clinicAddress?.zipcode === standardClinicAddress?.zipcode &&
        clinicAddress?.state?.toLowerCase() === standardClinicAddress?.state?.toLowerCase()
      ) {
        setStep(2);
      } else {
        setShowSpinner(true);
        setStandardClinicAddress({
          addressLine1: obj.addressLine1,
          addressLine2: obj.addressLine2 ? obj.addressLine2 : "-",
          city: obj.city,
          zipcode: obj.zipcode,
          state: obj.state,
        });
        setShowValidateModal(true);

        const body = {
          address: {
            streetLines: {
              addressLine1: obj.addressLine1,
              addressLine2: obj.addressLine2 ? obj.addressLine2 : "-",
            },

            city: obj.city,
            state: obj.state,
            postalCode: obj.zipcode,
          },
        };

        const validRes = await validateAddress(body);
        if (validRes?.data) {
          let fedexAddr = {
            addressLine1: validRes.data.addressLine1,
            addressLine2:
              validRes.data.addressLine2 || validRes.data.addressLine2 !== "" ? validRes.data.addressLine2 : "-",
            city: validRes.data.city,
            state: validRes.data.state,
            zipcode: validRes.data.postalCode,
          };
          setFedexAddress(fedexAddr);
          setShowSpinner(false);
          setError("");
        }
      }
    } 
    else if (step === 2) {
      setStep(3)
    } 
    else if (step === 3) {
      setShowSpinner(true);

      let body = createClinicSubmitPayload(obj, side);
      if (side === "adminSide") {
        let response = await addClinicMutate({ payload: body });
        if (response?.data) {
          await addUserToCometChat(
            obj.officeContactFirstName + " " + obj.officeContactLastName,
            response.data.clinicSuperUserId,
            obj.businessName,
            "ClinicSuperUser",
          );
          addUserToCometChat(
            obj.providerFirstName + " " + obj.providerLastName,
            response.data.providerId,
            obj.businessName,
            "ClinicProvider",
          );

          let adminList = await getAdminList();
          var friendsList = adminList;
          friendsList.push(response.data.providerId);
          await chunkingFriends(response.data.clinicSuperUserId, friendsList);
        }
      } else {
        registerClinicMutate({ payload: body });
      }

      /** */
    }
  }

  //Signature Info

  const closeSignatureModal = () => {
    setBillingSignModal(false);
    setSignatureModal(false);
  };
  const onClickSave = (person = "signatureImageUrl", sig) => {
    upload("", person, sig);
  };
  return {
    standardClinicAddress,
    setStandardClinicAddress,
    onlyOneUser,
    setOnlyOneUser,
    clinicAddress,
    setClinicAddress,
    fedexAddress,
    setFedexAddress,
    showValidateModal,
    setShowValidateModal,
    addressVerification,
    setAddressVerification,
    signatureModal,
    setSignatureModal,
    signatureImageUrl,
    setSignatureImageUrl,
    isVerified,
    setVerified,
    stateLicenseURL,
    setStateLicenseURL,
    deaLicenseURL,
    setDeaLicenseURL,
    error,
    setError,
    step,
    setStep,
    showLoading,
    showError,
    errorMessage,
    upload,
    onChange,
    onClickNotValidate,
    onClickValidate,
    onClickSave,
    closeSignatureModal,
    handleValidSubmit,
    closeModal,
    complete,
    billingRepSignature,
    billingSignModal,
    setBillingSignModal,
    setDeaAddressInfo,
    deaAddressInfo,
    companyInfo,
    setCompanyInfo,
    vpiInfo,
    setVpiInfo,
    setBaaFormPDFUrl
  };
};

export default useAddClinicHook;
