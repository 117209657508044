import { Row } from "reactstrap";
const BottomNavigationSteps = ({ step, setStep, actionText, isVerified, side = "" }) => {
  return (
    <Row className="my-5 text-center pt-3">
      {step < 3 ? (
        <button className="btn btn-primary darkBg mt-3 mx-auto w-50" type="submit">
          Next
        </button>
      ) : (
        <button
          disabled={!side && !isVerified}
          className="btn btn-primary darkBg mt-4 mx-auto"
          style={{ width: "40%" }}
          type="submit">
          {actionText}
        </button>
      )}
      {step > 1 && (
        <div>
        <button
          className="btn btn-outline-light mt-4 mx-auto"
          style={{ width: "52%" }}
          onClick={e => {
            e.preventDefault();
            setStep(step - 1);
          }}>
          Back
        </button>
        </div>

      )}
    </Row>
  );
};

export default BottomNavigationSteps;
