import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, Col, Container, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import PatientNavbar from 'components/patientNavbar';
//Import Breadcrumb
//import Breadcrumbs from "components/Common/Breadcrumb"

//Import images
import at from '../../../assets/images/analyticaltesting.png';

class AnalyticalTesting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalPage: 5, //replace this with total pages of data
    };
  }

  componentDidMount() {}

  handlePageClick = (page) => {
    this.setState({ page });
  };

  render() {
    const { projects } = this.props;
    const { page, totalPage } = this.state;

    return (
      <React.Fragment>
        <PatientNavbar />
        <div className='page-content'>
          <MetaTags>
            <title>Tools | Analytical Testing</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Tools" breadcrumbItem="Analytical Testing" /> */}
            <Row>
              <Col>
                <Alert color='success' align='center'>
                  FitDataMax has partnered with Xylia Technology who offers Analytical Testing services.
                </Alert>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <p className='text-muted'>
                  These testing services can help you identify:
                  <br />
                  <br />
                  - Contaminants such as lead and arsenic
                  <br />
                  - Adulterated or “Spiked” supplements that contain PEDs
                  <br />- Unidentified powders/pills
                </p>
                <Row>
                  <Col>
                    <Alert color='success' align='center'>
                      Discount Code: “FITDATAMAX” 10% off services
                    </Alert>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className='col-12' style={{ lineHeight: '2' }}>
                  <h3 align='center' className='text-muted'>
                    QUALITATIVE TESTING
                  </h3>
                  <p>
                    <br />
                    Qualitative testing is simply identifying the compound(s) present in the sample. However, qualitative testing is not able to identify how much of the compound is in the sample. While it takes more effort to match a spectrum to a compound, or multiple compounds, it is quite easy to tell whether or not a specific compound is present in a sample from the spectrum.
                    <br />
                    Ordering this test will be able to tell you what the sample is mainly comprised of, and any contaminants that may be identifiable. On the submission form that they send you, you may also list anything you would like tested for specifically.
                  </p>
                  <br />
                </div>
                <br />

                <div align='center'>
                  <Button
                    color='primary'
                    className='btn-rounded btn-lg'
                    align='center'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = 'https://www.xyliatechnology.com/store';
                    }}
                  >
                    Submit Samples
                  </Button>
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <div className='col-12' style={{ lineHeight: '2' }}>
                  <h3 align='center' className='text-muted'>
                    QUANTITATIVE TESTING
                  </h3>
                  <p>
                    <br />
                    Quantitative testing is identifying how much of a specific compound is present in the sample submitted. The result of this test will provide a value such as mg/ml, or mg/kg, depending on the type of sample provided.
                    <br />
                    Please visit Xylia if you would like a quantitative analysis performed. If it is not currently in Xylia’s quantitative library, they will order a reference standard for it after your order is submitted.
                  </p>
                  <br />
                </div>
                <br />
                <div align='center'>
                  <Button
                    color='primary'
                    className='btn-rounded btn-lg'
                    align='center'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = 'https://www.xyliatechnology.com/store';
                    }}
                  >
                    Submit Samples
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <div className='col-12' style={{ lineHeight: '2' }}>
                  <h3 align='center' className='text-muted'>
                    TESTING METHODOLOGY
                  </h3>
                  <br />
                  <div align='center'>
                    <img src={at} alt='' height='250' width='305' />
                  </div>
                  <br />
                  <p>
                    Xylia’s qualitative and quantitative profiles, using Fourier-Transform Infrared Spectroscopy (FTIR), offers a comprehensive analysis of the organic compounds present in the samples they receive. It provides important information to make an informed decision about the product.
                    <br />
                    Fourier-Transform Infrared Spectroscopy (FTIR) is one of the best analytical methods for examining organic profiles. Unlike most other analytical methods, FTIR doesn’t heat the sample or break the bonds prior to analysis, and is one of the few non-destructive analytical methods available. This allows us to obtain an unadulterated spectrum for the sample, and limits uncertainty in results.
                  </p>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

AnalyticalTesting.propTypes = {
  projects: PropTypes.array,
};

export default AnalyticalTesting;
