import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React, { useCallback } from "react";
import { useController } from "react-hook-form";

function FormRadioField({
  control,
  name,
  rules,
  dependentChange,
  radioOptions = [],
  MuiFieldProps: { label = "" } = { label: "" },
}) {
  const {
    field: { ref, onChange, ...inputField },
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: "" });

  const handleRadioClick = useCallback((event) => {
    dependentChange?.();
    onChange(event.target.value);
  }, []);

  return (
    <FormControl>
      <FormLabel id="radio_label">{label}</FormLabel>
      <RadioGroup ref={ref} {...inputField} row name="shippingMethod" onChange={handleRadioClick}>
        {radioOptions?.map((method, index) => (
          <FormControlLabel
            key={method.id}
            value={method.value}
            control={<Radio style={{ fontSize: 20 }} />}
            label={method.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default React.memo(FormRadioField);
