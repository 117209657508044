import { CometChat } from "@cometchat-pro/chat";
import { ThemeProvider } from "@mui/material";
import Spinner from "components/spinner";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import muiTheme from "theme/MaterialUiTheme";
import { setUpInterceptor } from "utils/axiosConfig";
import App from "./App";
import { DialogProvider, PortalProvider } from "./context";
import { InitializeStore } from "./rtk";
import * as serviceWorker from "./serviceWorker";

const cometChatAppId = process.env.REACT_APP_COMET_CHAT_APP_ID;
const cometChatRegion = process.env.REACT_APP_COMET_CHAT_REGION;

const { reduxStore, persistor } = InitializeStore();
setUpInterceptor(reduxStore);
const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(cometChatRegion).build();
CometChat.init(cometChatAppId, appSetting).then(
  () => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={reduxStore}>
          <PersistGate loading={<Spinner />} persistor={persistor}>
            <ThemeProvider theme={muiTheme}>
              <Router>
                <PortalProvider>
                  <DialogProvider>
                    <App />
                  </DialogProvider>
                </PortalProvider>
              </Router>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
      document.getElementById("root"),
    );
  },
  error => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  },
);

serviceWorker.unregister();
