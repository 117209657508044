import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Modal, Row } from "reactstrap";

export default function PrimaryModal(props) {
  const { redirection = true, onClickYes } = props;

  const deleteIt = useCallback(
    (e) => {
      e.preventDefault();
      onClickYes?.();
    },
    [onClickYes]
  );

  return (
    <Modal isOpen={props.showModal} size="md" centered={true} className="smallHeightmodal" zIndex={1300}>
      <>
        <button
          type="button"
          onClick={() => {
            props.closeModal();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body flexJCAC flex-column text-center">
          <h3>{props.question}</h3>
          {redirection ? (
            <Row className="mt-5">
              <Link to="/clinics" className="lightButton mx-2" onClick={deleteIt}>
                Yes
              </Link>
              <button
                className="pinkButton mx-2"
                onClick={(e) => {
                  e.preventDefault();
                  props.onClickNo ? props.onClickNo() : props.closeModal?.();
                }}
              >
                <span>No</span>
              </button>
            </Row>
          ) : (
            <Row className="mt-5">
              <span className="lightButton mx-2" onClick={deleteIt}>
                Yes
              </span>
              <span
                className="pinkButton mx-2"
                onClick={() => {
                  props.onClickNo ? props.onClickNo() : props.closeModal();
                }}
              >
                No
              </span>
            </Row>
          )}
        </div>
      </>
    </Modal>
  );
}
