import { InvoiceApi } from "../../rtkApi";
import { API_METHODS, END_POINTS, providesList } from "../../rtkConstant";

const InvoiceDetailQuery = InvoiceApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvoiceDetail: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.GET_INVOICE_DETAIL,
        method: API_METHODS.POST,
        body: apiArgs,
      }),
      // providesTags: (result) => providesList(result?.prescriptions),
    }),
    getDefaultCreditCard: builder.query({
      query: (apiArgs) => ({
        url: END_POINTS.GET_DEFAULT_CARD_PRESCRIPTION_ID + "/" + apiArgs.prescriptionId,
        method: API_METHODS.GET,
      }),
    }),
  }),
});
export const { useGetInvoiceDetailQuery, useLazyGetInvoiceDetailQuery, useLazyGetDefaultCreditCardQuery } =
  InvoiceDetailQuery;
