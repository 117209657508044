import { DialogContext } from "context";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLazyResendAutoGenQuery } from "rtk";

function useStaffAction({ listingName, listingMutation }) {
  const staffIdRef = useRef();
  const [staffId, setStaffId] = useState("");
  const [autoGenMessage, setAutoGenMessage] = useState("");
  const { showDialog, closeDialog } = useContext(DialogContext);

  const [mutateAction, { data: actionData, error: actionError, isSuccess: actionSuccess, isLoading: actionLoading }] =
    listingMutation();

  const [
    resendAutoGen,
    { data: { message = "" } = { message: "" }, error: resendError, isFetching: resendFetching, isSuccess },
  ] = useLazyResendAutoGenQuery();

  const successMessage = useMemo(
    () => actionData?.message || autoGenMessage || "",
    [actionData?.message, autoGenMessage],
  );
  const mutateLoading = useMemo(() => actionLoading || resendFetching, [actionLoading, resendFetching]);
  const mutateError = useMemo(
    () => actionError?.data?.message || resendError?.data?.message || "",
    [actionError?.data?.message, resendError?.data?.message],
  );

  useEffect(() => {
    if (!resendFetching && isSuccess && message) {
      setAutoGenMessage(message);
    }
  }, [resendFetching, message, isSuccess]);

  useEffect(() => {
    if (actionSuccess) {
      setStaffId("");
    }
  }, [actionSuccess]);

  const staffAction = useCallback(() => {
    closeDialog();
    mutateAction({ staffId: staffIdRef.current ?? "", pageName: listingName });
  }, [closeDialog, listingName, mutateAction]);

  const toggleConfirmationModal = useCallback(
    (id = "") => {
      staffIdRef.current = id;
      if (id) {
        showDialog({
          question: "Are you sure you want to delete this staff?",
          actionText: "Yes",
          cancelText: "No",
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: staffAction,
        });
      } else {
        closeDialog?.();
      }
    },
    [closeDialog, staffAction, showDialog],
  );

  const resendAutoGenRequest = useCallback(
    rowId => {
      setAutoGenMessage("");
      resendAutoGen(rowId);
    },
    [resendAutoGen],
  );
  return {
    staffId,
    mutateError,
    mutateLoading,
    successMessage,
    staffAction,
    toggleConfirmationModal,
    resendAutoGenRequest,
  };
}

export default useStaffAction;
