import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const ClinicApi = createApi({
  reducerPath: "ClinicApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [
    CACHE_TAGS.CLINIC,
    CACHE_TAGS.APPROVED_CLINICS,
    CACHE_TAGS.PRESCRIPTION,
    CACHE_TAGS.ARCHIVED,
    CACHE_TAGS.RX_WORKFLOW,
    CACHE_TAGS.INVOICE,
  ],
});

export default ClinicApi;
