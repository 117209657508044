/* eslint-disable react-hooks/exhaustive-deps */
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { ListingSearch, MuiTableCollapse } from "components";
import PrimaryModal from "components/primaryModal";
import ReviewModal from "components/reviewClinic";
import Spinner from "components/spinner";
import { includes } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  useApproveClinicMutation,
  useDisApproveClinicMutation,
  useLazyGetPendingClinicListQuery,
} from "rtk/rtkEndpoints/Clinics";
import { userRoleSelector ,userInfoSelector} from "selectors";
import {
  ADMIN_ALLOWED_CLINIC_ACTIONS,
  ADMIN_PENDING_CLINICS,
  CLINIC_FILTER_OPTIONS,
  ROWS_LIMIT,
  adminColumnHelper,
} from "utils";
import AlertMessage from "../Prescriptions/components/alert_message";
import { useClinicListing } from "./Hook";

function ClinicPendingList() {
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);
  const [nestedModal, setNestedModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const {
    sorting,
    clinicId,
    rowLimit,
    tablePage,
    apiParam,
    showSpinner,
    errorMessage,
    totalRecords,
    clinicListing,
    isErrorMutate,
    selectedFilter,
    successMessage,
    searchKeyword,
    resetMutate,
    setSorting,
    clearFilter,
    applyFilter,
    refetchList,
    clinicAction,
    updateKeyword,
    changeRowLimit,
    updateFilterType,
    toggleClinicModal,
    handleChangePage,
  } = useClinicListing({
    listingQuery: useLazyGetPendingClinicListQuery,
    listingName: "GetPendingClinicList",
    listingMutation: useDisApproveClinicMutation,
  });

  const [
    mutateApproveAction,
    {
      data: mutateData,
      error: mutateError,
      isSuccess: mutateSuccess,
      isLoading: mutateLoading,
      isError: isErrorApprovalMutate,
      reset: resetApprovalMutate,
    },
  ] = useApproveClinicMutation();

  const showLoading = useMemo(() => showSpinner || mutateLoading, [mutateLoading, showSpinner]);
  const allowedAcknowledge = useMemo(() => includes(ADMIN_ALLOWED_CLINIC_ACTIONS, userRole) || userInfo?.isSuperUserSameAsProvider, [userRole]);
  const openReviewModal = useMemo(() => Boolean(clinicId) && showReviewModal, [clinicId, showReviewModal]);
  const openDeleteModal = useMemo(
    () => Boolean(clinicId) && (nestedModal || !showReviewModal),
    [clinicId, nestedModal, showReviewModal],
  );
  const showErrorMessage = useMemo(
    () => errorMessage || mutateError?.data?.message,
    [errorMessage, mutateError?.data?.message],
  );
  const showSuccessMessage = useMemo(
    () => successMessage || mutateData?.message,
    [mutateData?.message, successMessage],
  );
  const toggleReviewModal = useCallback(() => setShowReviewModal(prev => !prev), []);
  const toggleNestedModal = useCallback(() => setNestedModal(prev => !prev), []);

  const resetMutateState = useCallback(() => {
    resetMutate();
    resetApprovalMutate();
  }, []);

  const closeReviewModal = useCallback(() => {
    toggleClinicModal();
    toggleReviewModal();
  }, [toggleClinicModal, toggleReviewModal]);

  const closeConfirmationModal = useCallback(() => {
    toggleClinicModal();
    if (openDeleteModal) {
      toggleNestedModal();
    } else {
      closeReviewModal();
    }
  }, [closeReviewModal, openDeleteModal, toggleNestedModal, toggleClinicModal]);

  const approveClinicAction = useCallback(
    (providerName, superUserName, baaFormPdf, companyInfo, vpiInfo) => {
      let bAAForm={
        aggrementText:'',
        vpiInfo:vpiInfo,
        companyInfo:companyInfo,

      }
      mutateApproveAction({
        clinicId,
        pageName: "GetPendingClinicList",
        providerName,
        superUserName,
        bAAFormPDFUrl: baaFormPdf,
        bAAForm
      });
    },
    [clinicId, mutateApproveAction],
  );

  useEffect(() => {
    if (mutateSuccess || isErrorApprovalMutate || isErrorMutate) {
      setShowReviewModal(false);
      setNestedModal(false);
      toggleClinicModal();
    }
  }, [mutateSuccess, isErrorApprovalMutate, isErrorMutate, toggleClinicModal]);

  const clinicApprovedActions = useMemo(
    () =>
      adminColumnHelper.accessor("actions", {
        header: "Actions",
        enableSorting: false,
        headerStyle: { minWidth: "180px", textAlign: "center" },
        cell: props => (
          <Box component={"span"}>
            <button
              onClick={() => {
                setShowReviewModal(true);
                toggleClinicModal(props.row.original.id);
                resetMutateState();
              }}
              className="yellowButton mx-2">
              Review
            </button>

            {allowedAcknowledge ? (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    toggleClinicModal(props.row.original.id);
                    resetMutateState();
                  }}
                  style={{ width: 30, height: 30 }}>
                  <DeleteIcon color="error" style={{ width: 30, height: 30 }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        ),
      }),
    [allowedAcknowledge, toggleClinicModal],
  );

  const reactTable = useReactTable({
    data: clinicListing,
    columns: [...ADMIN_PENDING_CLINICS, clinicApprovedActions],
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    getRowId: row => row.id,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
    },
  });

  const renderHeaderCell = useCallback(header => {
    const columnSortDirection = header.column.getIsSorted();

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={header.column.getIsSorted()}>
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() => header.column.toggleSorting(columnSortDirection !== "desc")}>
          <Typography variant="tableHeader" noWrap>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  }, []);

  return (
    <>
      {showLoading && <Spinner />}
      <ListingSearch
        searchKeyword={searchKeyword}
        selectedFilter={selectedFilter}
        applySearch={applyFilter}
        clearFilter={clearFilter}
        updateKeyword={updateKeyword}
        updateFilterType={updateFilterType}
        filterOptions={CLINIC_FILTER_OPTIONS}
      />
      <AlertMessage msg={showSuccessMessage} />
      <AlertMessage msg={showErrorMessage} isError={true} />
      <Grid container justifyContent={"flex-end"} style={{ marginRight: 15 }}>
        <Tooltip title="Refresh">
          <Button variant="contained" onClick={refetchList}>
            <RefreshIcon style={{ width: 20, height: 20 }} />
          </Button>
        </Tooltip>
      </Grid>
      <TableContainer style={{ maxHeight: "70vh" }}>
        <Table size="small" stickyHeader>
          <TableHead>
            {reactTable.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>{headerGroup.headers.map(header => renderHeaderCell(header))}</TableRow>
            ))}
          </TableHead>
          <TableBody>
            {reactTable.getRowModel().rows.map(row => (
              <MuiTableCollapse
                key={row.id}
                showNested={false}
                readOnly={true}
                row={row}
                nestedEditable={true}
                showColoredRows={false}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        page={tablePage}
        count={totalRecords}
        rowsPerPage={rowLimit}
        rowsPerPageOptions={ROWS_LIMIT}
        onPageChange={handleChangePage}
        onRowsPerPageChange={changeRowLimit}
        style={{ alignItems: "center", marginBottom: 0 }}
      />
      <ReviewModal
        apiParam={apiParam}
        clinicId={clinicId}
        approveAction={approveClinicAction}
        disApproveAction={toggleNestedModal}
        showReviewModal={openReviewModal}
        closeModal={closeReviewModal}
      />
      <PrimaryModal
        question="Are you sure to delete this clinic?"
        showModal={openDeleteModal}
        closeModal={closeConfirmationModal}
        onClickYes={clinicAction}
      />
    </>
  );
}

export default React.memo(ClinicPendingList);
