import { UploadsApi } from "../../rtkApi";
import { API_METHODS, UPLOADS_END_POINT } from "../../rtkConstant";

const UploadsMutation = UploadsApi.injectEndpoints({
  endpoints: builder => ({
    uploadFile: builder.mutation({
      query: fileData => ({
        url: UPLOADS_END_POINT.UPLOAD_FILE,
        method: API_METHODS.POST,
        credentials: "include",
        body: fileData,
        Headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    uploadPatients: builder.mutation({
      query: apiArgs => ({
        url: UPLOADS_END_POINT.UPLOAD_PATIENT,
        method: API_METHODS.POST,
        body: apiArgs.payload,
        headers: apiArgs.headers,
      }),
    }),
    uploadProducts: builder.mutation({
      query: apiArgs => ({
        url: UPLOADS_END_POINT.UPLOAD_PRODUCT,
        method: API_METHODS.POST,
        body: apiArgs.payload,
        headers: apiArgs.headers,
      }),
    }),
  }),
});
export const {
  useUploadFileMutation,
  useUploadPatientsMutation,
  useUploadProductsMutation,
  endpoints: UploadsApiEndpoints,
} = UploadsMutation;
