import React, { useEffect, useState } from 'react';

import { Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import img1 from '../../../assets/images/product-details/pd1.png';
import img2 from '../../../assets/images/product-details/pd2.png';
import img3 from '../../../assets/images/product-details/pd3.png';
import img4 from '../../../assets/images/product-details/pd4.png';
import img5 from '../../../assets/images/product-details/pd5.png';
import img6 from '../../../assets/images/product-details/pd6.png';
import img7 from '../../../assets/images/product-details/pd7.png';
import img8 from '../../../assets/images/product-details/pd8.png';
const DisplayModal = (props) => {
  /**
   * Handling the modal state
   */
  const toggle = () => {
    props.closeModal();
  };

  useEffect(() => {});

  return (
    <React.Fragment className='mt-4'>
      {props.showModal ? (
        <Modal isOpen={props.showModal} scrollable={true} size='xl' className='mt-4' centered>
          <ModalHeader />
          <ModalBody>
            <Row size='lg'>
              <Row className='mb-4'>
                <Col align='center' md='4'>
                  <h3>{props.namesdata[0]}</h3>
                </Col>
                <Col align='center' md='4'>
                  <h3>{props.namesdata[1]}</h3>
                </Col>
                <Col align='center' md='4'>
                  <h3>{props.namesdata[2]}</h3>
                </Col>
              </Row>

              {/* new method start */}
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img1} alt='' height='60' />
                    {props.displayModalData[0][0].title}
                  </h4>
                  <p>{props.displayModalData[0][0].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img1} alt='' height='60' />
                    {props.displayModalData[1][0].title}
                  </h4>
                  <p>{props.displayModalData[1][0].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img1} alt='' height='60' />
                    {props.displayModalData[2][0].title}
                  </h4>
                  <p>{props.displayModalData[2][0].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img2} alt='' height='60' />
                    {props.displayModalData[0][1].title}
                  </h4>
                  <p>{props.displayModalData[0][1].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img2} alt='' height='60' />
                    {props.displayModalData[1][1].title}
                  </h4>
                  <p>{props.displayModalData[1][1].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img2} alt='' height='60' />
                    {props.displayModalData[2][1].title}
                  </h4>
                  <p>{props.displayModalData[2][1].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img3} alt='' height='60' />
                    {props.displayModalData[0][2].title}
                  </h4>
                  <p>{props.displayModalData[0][2].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img3} alt='' height='60' />
                    {props.displayModalData[1][2].title}
                  </h4>
                  <p>{props.displayModalData[1][2].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img3} alt='' height='60' />
                    {props.displayModalData[2][2].title}
                  </h4>
                  <p>{props.displayModalData[2][2].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img4} alt='' height='60' />
                    {props.displayModalData[0][3].title}
                  </h4>
                  <p>{props.displayModalData[0][3].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img4} alt='' height='60' />
                    {props.displayModalData[1][3].title}
                  </h4>
                  <p>{props.displayModalData[1][3].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img4} alt='' height='60' />
                    {props.displayModalData[2][3].title}
                  </h4>
                  <p>{props.displayModalData[2][3].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img5} alt='' height='60' />
                    {props.displayModalData[0][4].title}
                  </h4>
                  <p>{props.displayModalData[0][4].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img5} alt='' height='60' />
                    {props.displayModalData[1][4].title}
                  </h4>
                  <p>{props.displayModalData[1][4].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img5} alt='' height='60' />
                    {props.displayModalData[2][4].title}
                  </h4>
                  <p>{props.displayModalData[2][4].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img6} alt='' height='60' />
                    {props.displayModalData[0][5].title}
                  </h4>
                  <p>{props.displayModalData[0][5].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img6} alt='' height='60' />
                    {props.displayModalData[1][5].title}
                  </h4>
                  <p>{props.displayModalData[1][5].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img6} alt='' height='60' />
                    {props.displayModalData[2][5].title}
                  </h4>
                  <p>{props.displayModalData[2][5].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img7} alt='' height='60' />
                    {props.displayModalData[0][6].title}
                  </h4>
                  <p>{props.displayModalData[0][6].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img7} alt='' height='60' />
                    {props.displayModalData[1][6].title}
                  </h4>
                  <p>{props.displayModalData[1][6].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img7} alt='' height='60' />
                    {props.displayModalData[2][6].title}
                  </h4>
                  <p>{props.displayModalData[2][6].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>
                    <img className='' src={img8} alt='' height='60' />
                    {props.displayModalData[0][7].title}
                  </h4>
                  <p>{props.displayModalData[0][7].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img8} alt='' height='60' />
                    {props.displayModalData[1][7].title}
                  </h4>
                  <p>{props.displayModalData[1][7].text}</p>
                </Col>
                <Col md='4'>
                  <h4>
                    <img className='' src={img8} alt='' height='60' />
                    {props.displayModalData[2][7].title}
                  </h4>
                  <p>{props.displayModalData[2][7].text}</p>
                </Col>
              </Row>
              <Row>
                <Col md='4'>
                  <h4>{props.displayModalData[0][8].title}</h4>
                  <p>{props.displayModalData[0][8].text}</p>
                </Col>
                <Col md='4'>
                  <h4>{props.displayModalData[1][8].title}</h4>
                  <p>{props.displayModalData[1][8].text}</p>
                </Col>
                <Col md='4'>
                  <h4>{props.displayModalData[2][8].title}</h4>
                  <p>{props.displayModalData[2][8].text}</p>
                </Col>
              </Row>
              {/* new method end */}
            </Row>

            <Row>
              <div align='center'>
                <Col>
                  <button type='button' className='btn btn-danger me-2' onClick={toggle}>
                    Close
                  </button>
                </Col>
              </div>
            </Row>
          </ModalBody>
        </Modal>
      ) : null}
    </React.Fragment>
  );
};

export default DisplayModal;
