import React from "react";
import Lottie from "react-lottie";
import SpinnerLottie from "../assets/vpi-lotties/spinner.json";

const defaultLottieOptions = {
  loop: true,
  animationData: SpinnerLottie,
};

function Spinner({ showSpinner = true }) {
  if (showSpinner) {
    return (
      <div className="spinnerOverlay" style={{ pointerEvents: "none !important" }}>
        <Lottie options={defaultLottieOptions} height={200} width={200} isClickToPauseDisabled={true} />
      </div>
    );
  } else {
    return null;
  }
}

export default React.memo(Spinner);
