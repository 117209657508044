import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
} from "reactstrap";

import PatientNavbar from "../../../components/patientNavbar";
import { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Spinner from "../../../components/spinner";
import Cookies from "universal-cookie";

class PatientProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: "",
      showSpinner: true,
      error: "",
      confirmDeleteModal: false,
      patient: "",
    };
  }

  componentDidMount() {
    axiosConfig
      .get(
        "patientPortal/getPatientProfileInfo",

        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        }
      )
      .then(async (response) => {
        this.setState({
          patient: response.data,
          showSpinner: false,
        });
      })
      .catch((error) => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch patient profile. " + error?.response?.data?.message,
        });
        errorResponse(error);
      });
  }

  render() {
    const patient = this.state.patient;

    return (
      <React.Fragment>
        <PatientNavbar />
        <div className="page-content">
          <MetaTags>
            <title>Profile | VPI Compounding</title>
          </MetaTags>
          <Container fluid>
            <h4 className="p-3 text-muted">PATIENT PROFILE</h4>
            {this.state.showSpinner ? <Spinner /> : null}
            {this.state.error ? (
              <Alert color="danger" className="text-center">
                {this.state.error}
              </Alert>
            ) : null}
            {this.state.patient ? (
              <Row>
                <Col xl="4">
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft text-center py-5">
                      <img
                        className="staffProfileImgCircle mb-4"
                        src={
                          patient.patientImageUrl && patient.patientImageUrl !== "-" ? patient.patientImageUrl : null
                        }
                        alt=""
                      />
                      <h2 className="text-dark mb-3">
                        {patient ? patient.firstName + " " + patient.lastName : "Loading..."}
                      </h2>
                      <div className="mb-4 flexJCAC">
                        {this.state.patient.isVerified ? (
                          <p className="text-white greenText px-4 my-1" style={{ borderRadius: "5px" }}>
                            Verified
                          </p>
                        ) : (
                          <p className="text-white redText px-4 my-1" style={{ borderRadius: "5px" }}>
                            Not Verified
                          </p>
                        )}
                      </div>
                      <Link to={"/edit-profile/"} className="pinkButton">
                        Edit Profile
                      </Link>
                    </div>
                  </Card>
                </Col>
                <Col xl="8">
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">Personal Information</CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>First Name</p>
                            <p>{patient.firstName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Middle Name</p>
                            <p>{patient.middleName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Last Name</p>
                            <p>{patient.lastName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Guardian Name</p>
                            <p>{patient.guardianName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Date of Birth</p>
                            <p>{patient.dateOfBirth}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Sex</p>
                            <p>{patient.sex}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Gender Identity</p>
                            <p>{patient.genderIdentity}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Marital Status</p>
                            <p>{patient.martialStatus}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Ethnicity</p>
                            <p>{patient.ethnicity}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Race</p>
                            <p>{patient.race}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Sexual Orientation</p>
                            <p>{patient.sexualOrientation}</p>
                          </div>
                        </Col>
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Height</p>
                            <p>{patient.height}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Weight</p>
                            <p>{patient.weight}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Current Occupation</p>
                            <p>{patient.currentOccupation}</p>
                          </div>

                          <div className="InfoRow">
                            <p>SSN</p>
                            <p>{patient.ssn}</p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">Contact Information</CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Email</p>
                            <p>{patient.email}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Mobile Phone</p>
                            <p>{patient.cellPhone}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Other Phone</p>
                            <p>{patient.phoneNumber}</p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">Address Information</CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          {patient.addresses.map((address, i) => {
                            return (
                              <div key={`address` + i}>
                                <p className="semiBold medText">Address {i + 1}</p>
                                <Row className="my-4 px-3">
                                  <Col lg={12} xl={12}>
                                    <div className="InfoRow">
                                      <p>Address Line 1</p>
                                      <p>{address.addressLine1}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>Address Line 2</p>
                                      <p>{address.addressLine2 ? address.addressLine2 : "-"}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>City</p>
                                      <p>{address.city}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>State</p>
                                      <p>{address.state}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>Zip Code</p>
                                      <p>{address.zipcode}</p>
                                    </div>
                                    <div className="InfoRow">
                                      <p>Default</p>
                                      <p>{address.isDefault ? "YES" : "NO"}</p>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">Shipping Information</CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Shipping Address Line 1</p>
                            <p>{patient.shippingAddressLine1}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping Address Line 2</p>
                            <p>{patient.shippingAddressLine2 ? patient.shippingAddressLine2 : "-"}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping City</p>
                            <p>{patient.shippingCity}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping State</p>
                            <p>{patient.shippingState}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping Zip Code</p>
                            <p>{patient.shippingZipcode}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Shipping Preference</p>
                            <p>{patient.shippingPreference}</p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <CardTitle className="medText">Portal Information</CardTitle>
                      <Row className="my-4 px-3">
                        <Col lg={12} xl={6}>
                          <div className="InfoRow">
                            <p>Preferred Contact Method</p>
                            <p>{patient.preferredContactMethod}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Emergency Contact</p>
                            <p>{patient.preferredEmergencyContact}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred First Name</p>
                            <p>{patient.preferredFirstName}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Language</p>
                            <p>{patient.preferredLanguage}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Preferred Last Name</p>
                            <p>{patient.preferredLastName}</p>
                          </div>
                        </Col>
                        <Col lg={12} xl={6}>
                          <p className="semiBold medText">Notifications</p>

                          <div className="InfoRow">
                            <p>Notification Email</p>
                            <p>{patient.notificationEmail ? patient.notificationEmail : "-"}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Notification Text</p>
                            <p>{patient.notificationText ? patient.notificationText : "-"}</p>
                          </div>
                          <div className="InfoRow">
                            <p>Notification Method</p>
                            <p>{patient.notificationMethod ? patient.notificationMethod : "-"}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Notification Preferences</p>
                            <p>{patient.notificationPreferences ? patient.notificationPreferences : "-"}</p>
                          </div>

                          <div className="InfoRow">
                            <p>Order Received</p>
                            <p className={patient.isToNotifyWhenOrderReceived ? "greenText" : "redText"}>
                              {patient.isToNotifyWhenOrderReceived ? "Yes" : "No"}
                            </p>
                          </div>
                          <div className="InfoRow">
                            <p>Order on Hold</p>
                            <p className={patient.isToNotifyWhenOrderOnHold ? "greenText" : "redText"}>
                              {patient.isToNotifyWhenOrderOnHold ? "Yes" : "No"}
                            </p>
                          </div>

                          <div className="InfoRow">
                            <p>Order in Process</p>
                            <p className={patient.isToNotifyWhenOrderInProcess ? "greenText" : "redText"}>
                              {patient.isToNotifyWhenOrderInProcess ? "Yes" : "No"}
                            </p>
                          </div>

                          <div className="InfoRow">
                            <p>Order Completed </p>
                            <p className={patient.isToNotifyWhenOrderIsCompleted ? "greenText" : "redText"}>
                              {patient.isToNotifyWhenOrderIsCompleted ? "Yes" : "No"}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card></Card>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PatientProfile;
