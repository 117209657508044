import React from 'react';
import '../assets/vpi-styles/loggedOutNavbar.css';
import VPICompoundingLogo from '../assets/vpi-images/vpi-logo.png';
import { Link } from 'react-router-dom';

export default function LoggedOutNavbar() {
  return (
    <div className="lo-navbar">
      <div>
        <img src={VPICompoundingLogo} alt="vpi-compounding" />
      </div>
      <div className="navLinks">
        <Link to="/login">Log in</Link>
      </div>
    </div>
  )
}
