import React from "react";
import { useController } from "react-hook-form";
import OutlineInputField from "./OutlineInputField";

function FormOutlineField({
  control,
  name,
  rules,
  MuiInputProps = {},
  MuiFieldProps: { fullWidth = true, label = "", ...restFieldProps },
}) {
  const {
    field: { ref, ...inputField },
    fieldState: { error },
  } = useController({ name, rules, control, defaultValue: "" });

  return (
    <OutlineInputField
      ref={ref}
      error={error?.message || " "}
      inputField={inputField}
      isRequired={rules?.required}
      MuiInputProps={MuiInputProps}
      MuiFieldProps={{ fullWidth, label, ...restFieldProps }}
    />
  );
}

export default React.memo(FormOutlineField);
