/* eslint-disable react-hooks/exhaustive-deps */
import { Box, IconButton, Tooltip } from "@mui/material";
import { forEach, set } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ROWS_LIMIT, columnHelper, formatPatientNames } from "utils";
import { dateFormatterMountainTime } from "utils/constants";

function useClinicPrescriptionListing({ listingQuery }) {
  const [apiParam, setApiParam] = useState({});
  const [invoiceId, setInvoiceId] = useState("");
  const [rowLimit, setRowLimit] = useState(ROWS_LIMIT[1]);
  const [prescriptionId, setPrescriptionId] = useState("");
  const [disableFilter, setDisableFilter] = useState(true);
  const [openUpdateModal, setOpenUpdateModal] = useState("");

  const [
    fetchList,
    { data: { pagination, prescriptions } = { pagination: {}, prescriptions: [] }, error, isFetching },
  ] = listingQuery();

  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() => rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const filterRxStatus = useMemo(() => apiParam?.rxStatus ?? "", [apiParam?.rxStatus]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const fromDate = useMemo(() => apiParam?.fromDate ?? "", [apiParam.fromDate]);
  const toDate = useMemo(() => apiParam?.toDate ?? "", [apiParam.toDate]);
  const showLoading = useMemo(() => isFetching, [isFetching]);
  const errorMessage = useMemo(() => error?.data?.message || "", [error?.data?.message]);

  useEffect(() => {
    if (!isFetching) {
      fetchList({ currentPage: 1, limit: rowLimit }, true);
    }
  }, [fetchList]);

  const toggleDetailModal = useCallback((id = "") => {
    setPrescriptionId(id);
  }, []);

  const toggleInvoiceModal = useCallback((id = "") => {
    setInvoiceId(id);
  }, []);

  const toggleUpdateModal = useCallback((id = "", showUpdateModal = "") => {
    setInvoiceId(id);
    setOpenUpdateModal(showUpdateModal);
  }, []);

  const updateFilterValue = useCallback((keyword, value) => {
    setApiParam((prev) => ({ ...prev, [keyword]: value }));
    setDisableFilter(!Boolean(value));
  }, []);

  const clearFilter = useCallback(
    (e) => {
      e.preventDefault();
      setApiParam({});
      setDisableFilter(true);
      fetchList({ currentPage: 1, limit: rowLimit }, true);
    },
    [fetchList, rowLimit]
  );

  const updateFilterRxStatus = useCallback((rxValue) => {
    updateFilterValue("rxStatus", rxValue);
  }, []);

  const applyFilter = useCallback(
    (e) => {
      e.preventDefault();
      const body = { currentPage: 1, limit: rowLimit };
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, value);
        }
      });
      fetchList(body, true);
    },
    [apiParam, fetchList, rowLimit]
  );
  const updateKeyword = useCallback((e) => {
    e.preventDefault();
    setApiParam((prev) => ({ ...prev, keyword: e.target.value }));
    setDisableFilter(!Boolean(e.target.value));
  }, []);

  const changeRowLimit = useCallback(
    (e) => {
      const size = Number(e.target.value);
      setRowLimit(size);
      const body = { ...apiParam, currentPage: 1, limit: size };
      if (!apiParam?.keyword) delete body.keyword;

      fetchList(body, true);
    },
    [apiParam, fetchList]
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, currentPage: newPage + 1, limit: rowLimit };
        if (!apiParam?.keyword) delete body.keyword;

        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam]
  );

  const refetchList = useCallback(() => {
    const body = { currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    fetchList(body);
  }, [apiParam, fetchList, rowLimit, tablePage]);

  const INCOMPLETE_TABLE_HEADER = [
    columnHelper.accessor("created", {
      header: "Created On",
      headerStyle: { width: "250px" },
      cell: (props) => dateFormatterMountainTime(props.getValue()),
    }),
    columnHelper.accessor("createdByName", {
      header: "Created By",
      headerStyle: { width: "250px" },
    }),
    columnHelper.accessor("patientName", {
      header: "Patient Name",
      headerStyle: { paddingLeft: 6, paddingRight: 6 },
      cell: ({ getValue, row }) => {
        const patientName = getValue();
        return (
          <Box component={"span"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            {patientName ? (
              <>
                <Tooltip title={patientName}>
                  <span>{formatPatientNames(patientName)}</span>
                </Tooltip>
                <Tooltip title="Edit Patient">
                  <IconButton
                    style={{ width: 30, height: 30 }}
                    onClick={() => toggleUpdateModal(row.original.id, "Patient")}
                  >
                    <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 15 }} />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </Box>
        );
      },
    }),

    columnHelper.accessor("patientEmail", {
      header: "Patient Email",
    }),
    columnHelper.accessor("patientPhone", {
      header: "Patient Phone #",
    }),
  ];

  return {
    rowLimit,
    fromDate,
    toDate,
    tablePage,
    invoiceId,
    totalRecords,
    filterRxStatus,
    searchKeyword,
    showLoading,
    disableFilter,
    errorMessage,
    prescriptionId,
    prescriptions,
    openUpdateModal,
    INCOMPLETE_TABLE_HEADER,
    refetchList,
    toggleUpdateModal,
    updateFilterRxStatus,
    handleChangePage,
    toggleDetailModal,
    applyFilter,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    updateFilterValue,
    toggleInvoiceModal,
  };
}

export default useClinicPrescriptionListing;
