/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton } from "@mui/material";
import { AlertMessage, ClinicLocationUpdateModal } from "components";
import NavBar from "components/NavBar";
import { ClinicLocationApiStateContext, ClinicLocationStateContext } from "context";
import { decrypt } from "cryptofunc";
import { map } from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { useDeleteClinicLocationMutation } from "rtk";
import { useClinicLocationAction } from "screens/common/ClinicLocation/Hook";
import Spinner from "../../../components/spinner";
import { USER_ROLES } from "utils";
import { useSelector } from "react-redux";
import { userRoleSelector,userInfoSelector } from "selectors";

function ClinicLocationProfile() {
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userRoleSelector);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [editSectionName, setEditSectionName] = useState("");
  const { clinicLocationData, clinicLocationId, filterClinicId } = useContext(ClinicLocationApiStateContext);
  const { mutateError, showSpinner: apiLoading, mutateSuccessMessage } = useContext(ClinicLocationStateContext);
  const {
    mutateError: deleteMutateError,
    mutateLoading,
    successMessage,
    toggleConfirmationModal,
  } = useClinicLocationAction({
    listingName: "ClinicLocationListing",
    listingMutation: useDeleteClinicLocationMutation,
  });

  const showLoading = useMemo(() => apiLoading || mutateLoading, [apiLoading, mutateLoading]);
  const apiError = useMemo(() => mutateError || deleteMutateError || "", [mutateError, deleteMutateError]);
  const creditCardInfo = useMemo(
    () => map(clinicLocationData?.encryptedCreditCards, encryptCard => decrypt(encryptCard)),
    [clinicLocationData?.encryptedCreditCards],
  );

  const closeEditSectionModal = useCallback((sectionName = "") => {
    setShowModal(Boolean(sectionName));
    if (sectionName) {
      setEditSectionName(sectionName);
    } else {
      setTimeout(() => {
        setEditSectionName(sectionName);
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        history.replace(`/clinic-locations${filterClinicId ? "/" + filterClinicId : ""}`);
      }, 1000);
    }
  }, [history, successMessage]);

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Clinic Location Profile | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">CLINIC LOCATION PROFILE</h4>
          <Spinner showSpinner={showLoading} />
          <AlertMessage msg={apiError || successMessage || mutateSuccessMessage} isError={apiError} />
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft text-center py-5">
                  <h1>
                    <i className="fas fa-user text-dark"></i>
                  </h1>
                  <h2 className="text-dark my-4">{clinicLocationData?.locationName ?? "-"}</h2>

                  {clinicLocationData?.isPrimaryLocation || userRole === USER_ROLES.RX_TECH ? null : (
                    <Link
                      to="#"
                      onClick={() => {
                        toggleConfirmationModal(clinicLocationId);
                      }}
                      className="my-4 pinkButton redBackground">
                      Delete Location
                    </Link>
                  )}
                </div>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <CardTitle className="semiBold medText">
                    Location Information
                    <IconButton onClick={() => closeEditSectionModal("Location Information")}>
                      <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 14 }} />
                    </IconButton>
                  </CardTitle>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <div className="InfoRow">
                        <p>Business Name</p>
                        <p>{clinicLocationData?.locationName ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Business Address (Line 1)</p>
                        <p>{clinicLocationData?.addressLine1 ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Business Address (Line 2)</p>
                        <p>{clinicLocationData?.addressLine2 ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Business Address (City)</p>
                        <p>{clinicLocationData?.city ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Business Address (State)</p>
                        <p>{clinicLocationData?.state ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Business Address (Zip)</p>
                        <p>{clinicLocationData?.zipcode ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Office Phone</p>
                        <p>{clinicLocationData?.officeContactPhone ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Office Fax</p>
                        <p>{clinicLocationData?.officeContactFax ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Verbal Orders</p>
                        <p>{clinicLocationData?.verbalOrders ?? "-"}</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="semiBold medText">
                    Office Contact & Preference Information
                    <IconButton onClick={() => closeEditSectionModal("Office Contact & Preference Information")}>
                      <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 14 }} />
                    </IconButton>
                  </CardTitle>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <div className="InfoRow">
                        <p>Contact Phone</p>
                        <p>{clinicLocationData?.officeContactPhone ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Prescription Refill Communication</p>
                        <p>{clinicLocationData?.prescriptionRefillCommunication ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Refill Management</p>
                        <p>{clinicLocationData?.refillManagement ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Company Logo</p>
                        <p>
                          {clinicLocationData?.companyLogoImageUrl ? (
                            <a href={clinicLocationData?.companyLogoImageUrl}>View</a>
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      <div className="InfoRow">
                        <p>Clinic Website URL</p>
                        <p>{clinicLocationData?.clinicWebsiteURL ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>How did you hear about VPI Compounding?</p>
                        <p>{clinicLocationData?.howDidYouHearAboutVPICompounding ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Other...</p>
                        <p>{clinicLocationData?.other ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Preferred Billing Method</p>
                        <p>{clinicLocationData?.preferredBillingMethod ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Partial Shipments if Needed</p>
                        <p>{clinicLocationData?.partialShipmentsAvailableasNeeded ?? "-"}</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="semiBold medText">
                    Credit Card Information 
                    {(userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.CLINIC_SUPER_USER || userInfo?.isSuperUserSameAsProvider) && 
                      <IconButton onClick={() => closeEditSectionModal("Credit Card Information")}>
                        <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 14 }} />
                      </IconButton>}
                  </CardTitle>
                  {creditCardInfo?.map((card, i) => {
                    return (
                      <div key={"card" + i}>
                        <p className="semiBold medText mt-4">Credit Card {i + 1}</p>
                        <Row className="my-4 px-3">
                          <Col lg={12} xl={6}>
                            <div className="InfoRow">
                              <p>Name on Credit Card</p>
                              <p>{card.cardHolderName}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Card Holder Contact Number</p>
                              <p>{card.cardHolderContactNumber}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Card Type</p>
                              <p>{card.cardType}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Credit Card Number</p>
                              <p>**** **** **** {card.creditCardNumber.substring(card.creditCardNumber.length - 4)}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Expiration Date</p>
                              <p>{card.cardExpirationDate.replace(/\s/g, "")}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Default</p>
                              <p className={card.isCardDefault ? "greenText" : "redText"}>
                                {card.isCardDefault ? "Yes" : "No"}
                              </p>
                            </div>
                          </Col>
                          <Col lg={12} xl={6}>
                            <div className="InfoRow">
                              <p>Billing Address (Line 1)</p>
                              <p>{card.cardHolderAddressLine1}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Billing Address (Line 2)</p>
                              <p>{card.cardHolderAddressLine2 ? card.cardHolderAddressLine2 : "-"}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Billing Address (City)</p>
                              <p>{card.cardHolderCity}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Billing Address (State)</p>
                              <p>{card.cardHolderState}</p>
                            </div>
                            <div className="InfoRow">
                              <p>Billing Address (Zip)</p>
                              <p>{card.cardHolderZipcode}</p>
                            </div>

                            {/* <div className="InfoRow">
                                <p>Email for Receipt</p>
                                <p>{card.cardHolderZipcode}</p>
                              </div> */}
                            <div className="InfoRow">
                              <p>Contact Phone</p>
                              <p>{card.cardHolderContactNumber}</p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle className="semiBold medText">
                    Notifications Information
                    <IconButton onClick={() => closeEditSectionModal("Notifications Information")}>
                      <i className="fas fa-edit medText" aria-hidden="true" style={{ fontSize: 14 }} />
                    </IconButton>
                  </CardTitle>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <p className="semiBold medText mb-4">Notifications</p>
                      {/* <div className="InfoRow">
                          <p>Order Received</p>
                          <p className={location.isToNotifyWhenOrderReceived ? "greenText" : "redText"}>
                            {location.isToNotifyWhenOrderReceived ? "On" : "Off"}
                          </p>
                        </div> */}
                      <div className="InfoRow">
                        <p>Order on Hold</p>
                        <p className={clinicLocationData?.isToNotifyWhenOrderOnHold ? "greenText" : "redText"}>
                          {clinicLocationData?.isToNotifyWhenOrderOnHold ? "On" : "Off"}
                        </p>
                      </div>
                      <div className="InfoRow">
                        <p>Order in Process</p>
                        <p className={clinicLocationData?.isToNotifyWhenOrderInProcess ? "greenText" : "redText"}>
                          {clinicLocationData?.isToNotifyWhenOrderInProcess ? "On" : "Off"}
                        </p>
                      </div>
                      <div className="InfoRow">
                        <p>Order Completed</p>
                        <p
                          className={
                            clinicLocationData?.isToNotifyWhenOrderIsCompleted ?? "-" ? "greenText" : "redText"
                          }>
                          {clinicLocationData?.isToNotifyWhenOrderIsCompleted ?? "-" ? "On" : "Off"}
                        </p>
                      </div>
                    </Col>
                    <Col lg={12} xl={6}>
                      <p className="semiBold medText mb-4"></p>
                      <div className="InfoRow">
                        <p></p>
                      </div>
                      <div className="InfoRow">
                        <p>Notification Contact Number</p>
                        <p>{clinicLocationData?.notificationContactNumber ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>End of Day Email</p>
                        <p>{clinicLocationData?.notificationEmail ?? "-"}</p>
                      </div>
                      <div className="InfoRow">
                        <p>Notification Preferences</p>
                        <p>
                          {clinicLocationData?.notificationPreferences?.toLowerCase() === "notify both email and text"
                            ? "Notify through both Email and Text"
                            : clinicLocationData?.notificationPreferences}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="my-4 px-3">
                    <Col lg={12} xl={6}>
                      <p className="semiBold medText mb-4">Notifications Email</p>
                      <div className="InfoRow" >
                        <p>Order on Hold</p>
                        <p>
                        {clinicLocationData?.notificationOrderOnHold?.length === 0 ? (
                          <span>-</span>
                        ) : (
                          clinicLocationData?.notificationOrderOnHold?.map((item, i) => (
                            <span  key={i}>{item}<br /></span>
                          ))
                        )}
                        </p>
                      </div>
                      <div className="InfoRow">
                        <p>Order in Process</p>
                        <p>
                        {clinicLocationData?.notificationOrderInProcess?.length === 0 ? (
                          <span>-</span>
                        ) : (
                          clinicLocationData?.notificationOrderInProcess?.map((item, i) => (
                            <span key={i}>{item}<br /></span>
                          ))
                        )}
                        </p>
                      </div>
                      <div className="InfoRow">
                        <p>Order Completed</p>
                        <p>
                        {clinicLocationData?.notificationOrderIsCompleted?.length === 0 ? (
                          <span>-</span>
                        ) : (
                          clinicLocationData?.notificationOrderIsCompleted?.map((item, i) => (
                            <span key={i}>{item}<br /></span>
                          ))
                        )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ClinicLocationUpdateModal
        showModal={showModal}
        locationId={clinicLocationId}
        sectionName={editSectionName}
        handleClose={closeEditSectionModal}
      />
    </React.Fragment>
  );
}

export default React.memo(ClinicLocationProfile);
