import { DispenseTypeDD } from "components/dropdowns/DispenseTypeDD";
import ProductFamilyDD from "components/dropdowns/productFamilyDD";
import SubcategoryOneDD from "components/dropdowns/subcategoryOneDD";
import Spinner from "components/spinner";
import { Alert, Card, CardBody, CardFooter, Col, Container, FormGroup, Label, Modal, Row } from "reactstrap";
import useAddProductModal from "./index.hook";

const AddProductModal = props => {
  const {
    success,
    setSuccess,
    showSpinner,
    setShowSpinner,
    showModal,
    allowPriceEditing,
    isEdit,
    closeModal,
    error,
    subcategory1DefaultVal,
    watch,
    singleProduct,
    sigOptions,
    priceGroups,
    setSingleProduct,
    onSubmit,
    register,
    handleSubmit,
    errors,
    fileError,
    setFileError,
    onFileChangeHandler,
    categoryDefaultVal,
    coldShippedDefaultVal,
    availabilityDefaultVal,
    medicalAccessoriesDefaultVal,
    controlledSubstanceDefaultVal,
    dispenseTypeDefaultVal,
  } = useAddProductModal(props);

  return (
    <Modal isOpen={showModal} size="lg" centered={true} className="largeHeightmodal modal-content ">
      <Container>
        <form id="registerForm" className="form-horizontal product-form mt-2" onSubmit={handleSubmit(onSubmit)}>
          <button type="button" onClick={() => closeModal()} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>

          {error ? (
            <Alert color="danger" className="text-center">
              {error.includes("priceGroup") && error.includes("number") ? "Price Group Price must be a Number" : error}
            </Alert>
          ) : null}

          {showSpinner ? <Spinner /> : null}

          <div className="modal-body flexJCAC flex-column text-center">
            <Row className="justify-content-center">
              <h3>{isEdit ? "Edit Product" : "Add Product"}</h3>
              {!isEdit && (
                <div>
                  <button className={singleProduct ? "blueButton" : ""} onClick={() => setSingleProduct(true)}>
                    Single Product
                  </button>
                </div>
              )}
            </Row>
            <Card>
              {singleProduct ? (
                <CardBody className="pt-0">
                  <hr />
                  <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"name"}
                        label={"Product name"}
                        register={register}
                        required={true}
                      />
                    </Col>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"productId"}
                        label={"Product ID"}
                        register={register}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <ProductFamilyDD
                        categoryDefaultVal={categoryDefaultVal}
                        name={"family"}
                        label={"Select Category"}
                        register={register}
                        required={true}
                      />
                    </Col>
                    <Col md={6}>
                      <SubcategoryOneDD
                        subcategory1DefaultVal={subcategory1DefaultVal}
                        name={"subCategory1"}
                        label={"Select Subcategory 1"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    {allowPriceEditing && (
                      <Col md={6}>
                        <InputFieldCustom
                          errors={errors}
                          name={"unitPrice"}
                          label={"Retail price"}
                          register={register}
                          required={true}
                        />
                      </Col>
                    )}
                    <Col md={6}>
                      <DispenseTypeDD
                        defaultValue={dispenseTypeDefaultVal}
                        errors={errors}
                        name={"dispenseType"}
                        label={"Dispense Type"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"subCategory2"}
                        label={"Sub Category 2"}
                        register={register}
                      />
                    </Col>
                    <Col md={6}>
                      <InputFieldCustom
                        errors={errors}
                        name={"commonName"}
                        label={"Common Name"}
                        register={register}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={4}>
                      <SelectDropdownCustom
                        coldShippedDefaultVal={coldShippedDefaultVal}
                        medicalAccessoriesDefaultVal={medicalAccessoriesDefaultVal}
                        errors={errors}
                        name={"medicalAccessories"}
                        label={"Medical Accessories"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={4}>
                      <SelectDropdownCustom
                        coldShippedDefaultVal={coldShippedDefaultVal}
                        medicalAccessoriesDefaultVal={medicalAccessoriesDefaultVal}
                        errors={errors}
                        name={"coldShipped"}
                        label={"Cold Shipped"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={4}>
                      <SelectDropdownCustom
                        controlledSubstanceDefaultVal={controlledSubstanceDefaultVal}
                        errors={errors}
                        name={"controlledSubstance"}
                        label={"Controlled Substance"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <SelectDropdownCustom
                        availabilityDefaultVal={availabilityDefaultVal}
                        errors={errors}
                        name={"isAvailable"}
                        label={"Availability"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    {" "}
                    <h5 className={"mt-4"} style={{ textAlign: "left" }}>
                      Sig
                    </h5>
                    <hr></hr>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig1Title"}
                        label={"Sig 1 title"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig2Title"}
                        label={"Sig 2 title"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig3Title"}
                        label={"Sig 3 title"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig4Title"}
                        label={"Sig 4 title"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row className={"mt-2 mb-2"}>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig1"}
                        label={"Sig 1"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig2"}
                        label={"Sig 2"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig3"}
                        label={"Sig 3"}
                        register={register}
                        required={false}
                      />
                    </Col>
                    <Col md={3}>
                      <InputFieldCustom
                        errors={errors}
                        name={"sig4"}
                        label={"Sig 4"}
                        register={register}
                        required={false}
                      />
                    </Col>
                  </Row>
                  {allowPriceEditing && (
                    <Row className={"mt-4 mb-2"}>
                      <h5 className={"mt-4"} style={{ textAlign: "left" }}>
                        Price Groups
                      </h5>
                      <hr></hr>
                      {priceGroups?.map((p, i) => {
                        return (
                          <>
                            <Col md={3} className="mt-2">
                              <InputFieldCustom
                                errors={errors}
                                name={"priceGroups[" + i + "].price"}
                                label={"Price Group " + priceGroups[i].groupName}
                                register={register}
                                required={true}
                              />
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  )}
                </CardBody>
              ) : (
                <CardBody>
                  <Row className={"mt-4 mb-4"}>
                    <Col md={12}>
                      <FormGroup>
                        <Label htmlFor={"file"} className="form-label">
                          {"Upload CSV file"}
                          <span className="requiredStar">*</span>
                        </Label>
                        <input
                          name="file"
                          className={`form-control`}
                          type={"file"}
                          {...register("file", { required: true })}
                          accept={".csv"}
                          onChange={e => onFileChangeHandler(e.target.files[0])}
                        />

                        {fileError && <span className={"error-product-required"}>{`Only .csv file are allowed.`}</span>}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              )}
              <CardFooter>
                <div className={"product-card-footer"}>
                  <button onClick={() => closeModal()}>Cancel</button>
                  <button type={"submit"} className={"pinkButton"} disabled={fileError}>
                    {isEdit ? "Save" : "Add"}
                  </button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </form>
      </Container>
    </Modal>
  );
};

export default AddProductModal;

const InputFieldCustom = props => {
  const { name = "", label = "", placeholder = "", register, required = false, type = "text", errors = "" } = props;
  let isRequiredError = false;
  if (name.includes("priceGroup")) {
    var indexVal = name.match(/[^[\]]+(?=])/g);
    var indexDigit = parseInt(indexVal[0]);
    if (!!errors && !!errors["priceGroups"]) {
      isRequiredError = !!errors && !!errors.priceGroups && !!errors.priceGroups[indexDigit] && required;
    }
  } else {
    isRequiredError = !!errors && !!errors[`${name}`] && !!errors[`${name}`].type && required;
  }

  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <input name={name} className={`form-control`} type={type} {...register(name, { required })} />

      {isRequiredError && <span className={"error-product-required"}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};

const SelectDropdownCustom = props => {
  const {
    name = "",
    label = "",
    placeholder = "",
    register,
    medicalAccessoriesDefaultVal = "",
    coldShippedDefaultVal = "",
    availabilityDefaultVal = "",
    controlledSubstanceDefaultVal = "",
    required = false,
    type = "text",
    errors = null,
  } = props;

  const isRequiredError =
    !!errors && !!errors[name] && !!errors[name].type && errors[name].type === "required" && required;
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <select
        name={name}
        value={
          name === "medicalAccessories"
            ? medicalAccessoriesDefaultVal
            : name === "controlledSubstance"
            ? controlledSubstanceDefaultVal
            : name === "isAvailable"
            ? availabilityDefaultVal
            : coldShippedDefaultVal
        }
        {...register(name, { required })} // Adjusted register syntax here
        style={{
          borderRadius: "5px",
          paddingBottom: "0px",
          width: "100%",
          height: "40px",
          borderColor: "#CDCDCD",
        }}>
        <option value="" disabled>
          {"Select"}
        </option>
        <option value="1">{"Yes"}</option>
        <option value="0">{"No"}</option>
      </select>

      {isRequiredError && <span className={"error-product-required"}>{`${label} should not empty.`}</span>}
    </FormGroup>
  );
};
