import MetaTags from "react-meta-tags";
import React, { useState, useMemo, useEffect } from "react";
import { Row, Col, Card, CardBody, Container, FormGroup, Input, Label, Alert } from "reactstrap";
import Lottie from "../lottie";
import Spinner from "../spinner";
import { useReset2FAMutation } from "rtk/rtkEndpoints/GoogleAuth/GoogleAuthMutation";
import NavBar from "components/NavBar";
import { useHistory, useLocation } from "react-router";
import { logout } from "utils";

const Reset2FA = (props) => {
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(props?.match?.params?.token ?? "");
  const [code, setCode] = useState();
  const history = useHistory();
  const location = useLocation();
  const [relogin, setRelogin] = useState(false);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tokenValue = params.get("token");
    if (tokenValue) {
      setToken(tokenValue);
    } else {
      history.push("/404");
    }
  }, [location.search, history]);

  const [reset2FAMutate, { data: resetMessage, error: resetError, isError: isResetError, isLoading, isSuccess }] =
    useReset2FAMutation();

  const showError = useMemo(() => isResetError, [isResetError]);
  const errorMessage = useMemo(() => resetError?.data?.message, [resetError]);
  const showLoading = useMemo(() => isLoading, [isLoading]);
  const complete = useMemo(() => isSuccess, [isSuccess]);
  async function handleValidSubmit(e) {
    e.preventDefault();

    await reset2FAMutate({
      code: code,
      password: password,
      token: token,
    });
  }
  useEffect(() => {
    if (isSuccess) {
      setRelogin(true);
      setTimeout(() => {
        logout();
        window.location.replace("/login"); // Replace "/logout" with the actual logout route
      }, 5000);
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Disable Two Factor Auth | VPI Compounding</title>
        </MetaTags>
        {showLoading ? <Spinner /> : null}
        {showError ? (
          <Alert color="danger" className="text-center mb-4">
            {errorMessage}
          </Alert>
        ) : null}

        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="p-4 text-center">
                  <h3 className="text-primary m-4 mb-0 text-dark">Disable Two-Factor Authentication</h3>
                  {isSuccess ? (
                    <div>
                      <Lottie name="success-check" />
                      <p className="mt-5 mx-sm-4 mx-2">{"Two Factor Authentication Disabled Successfully!"}</p>
                      <p className="mx-sm-4 mx-2">{"Re-Login in 5 seconds."}</p>
                    </div>
                  ) : null}
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <form
                      id="ResetTwoFacForm"
                      className="form-horizontal px-sm-5 px-2"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      {isSuccess ? null : (
                        <div className="mb-5 px-4">
                          <div className="mt-3">
                            <FormGroup>
                              <Label htmlFor="password" className="form-label">
                                Enter Your Password <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="password"
                                className="form-control"
                                type="password"
                                placeholder="Enter Your Password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e?.target?.value)}
                              />
                            </FormGroup>
                          </div>
                          <div className="mt-3">
                            <FormGroup>
                              <Label htmlFor="code" className="form-label">
                                Enter Code <span className="requiredStar">*</span>
                              </Label>
                              <Input
                                name="code"
                                className="form-control"
                                type="text"
                                placeholder="Enter PIN"
                                required
                                value={code}
                                onChange={(e) => setCode(e?.target?.value)}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      )}
                      {isSuccess ? (
                        <Row className="m-4">
                          <button
                            className="btn btn-primary darkBg"
                            onClick={() => {
                              props?.history?.push("/profile");
                            }}
                          >
                            View Profile
                          </button>
                        </Row>
                      ) : (
                        <Row className="m-5">
                          <button className="btn btn-primary darkBg mt-5" type="submit">
                            Reset PIN
                          </button>
                          <button
                            className="btn btn-outline-dark mt-2"
                            onClick={() => {
                              props?.history?.push("/profile");
                            }}
                          >
                            View Profile
                          </button>
                        </Row>
                      )}
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Reset2FA;
