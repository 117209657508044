import { CometChatApi } from "../../rtkApi";
import { API_METHODS } from "../../rtkConstant";

const CometChatMutation = CometChatApi.injectEndpoints({
  endpoints: builder => ({
    addCometUser: builder.mutation({
      query: ({ uid = "", name = "", role = "", clinicName = "" }) => ({
        body: {
          uid: uid,
          name: name,
          role: role.toLowerCase(),

          metadata: {
            businessName: clinicName,
            role: role,
          },
        },
        method: API_METHODS.POST,
      }),
    }),
    deleteCometUser: builder.mutation({
      query: id => ({
        url: `${id}`,
        body: { permanent: true },
        method: API_METHODS.DELETE,
      }),
    }),
  }),
});

export const { endpoints: CometChatEndpoints } = CometChatMutation;
