import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { CometChatUI } from "../../../../src/cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";
import ClinicNavbar from "../../../components/ClinicNavbar";
import Spinner from "../../../components/spinner";
export default function Messages(props) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState("");
  useEffect(() => {}, []);

  return (
    <div>
      <MetaTags>
        <title>Chat | VPI Compounding</title>
      </MetaTags>
      <ClinicNavbar />
      {showSpinner ? <Spinner /> : null}
      <div className="page-content">
        <Container style={{ height: "500px" }}>
          <CometChatUI />
        </Container>
      </div>
    </div>
  );
}
