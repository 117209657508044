import { forEach, set, unset } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { userInfoSelector } from "selectors";
import { ROWS_LIMIT } from "utils";

function useStaffListing({ listingQuery }) {
  const { state: { clinicId = "" } = {} } = useLocation();
  const userInfo = useSelector(userInfoSelector);
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [apiParam, setApiParam] = useState({
    currentPage: 1,
    limit: ROWS_LIMIT[1],
    clinicId: clinicId,
  });

  const [fetchList, { data: { pagination, staff = [] } = { pagination: {}, staff: [] }, error, isFetching }] =
    listingQuery();

  const rowLimit = useMemo(() => apiParam.limit, [apiParam.limit]);
  const isClinicSide = useMemo(() => userInfo?.clinicId ?? "", [userInfo?.clinicId]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() => rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const showSpinner = useMemo(() => isFetching, [isFetching]);
  const errorMessage = useMemo(() => error?.data.message || "", [error?.data.message]);

  const applySorting = useCallback(() => {
    const sortingObj = sorting[0];

    if (sortingObj?.id) {
      setApiParam(prev => {
        const newParams = { ...prev, sort: { [sortingObj.id]: sortingObj.desc ? -1 : 1 } };
        fetchList(newParams, true);
        return newParams;
      });
    }
  }, [fetchList, sorting]);

  //   useEffect is important to apply sorting  with dependency applySorting
  useEffect(() => {
    applySorting();
  }, [applySorting]);

  useEffect(() => {
    if (!isFetching) {
      fetchList(apiParam, true);
    }
  }, []);

  const refetchList = useCallback(() => {
    const body = { currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    setApiParam(body);
    fetchList(body);
  }, [apiParam, fetchList, rowLimit, tablePage]);

  const updateKeyword = useCallback(e => {
    e.preventDefault();
    setApiParam(prev => ({ ...prev, keyword: e.target.value }));
  }, []);

  const applyFilter = useCallback(
    e => {
      e.preventDefault();
      const body = {};
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, value);
        }
      });
      fetchList({ ...body, currentPage: 1 });
    },
    [apiParam, fetchList],
  );

  const clearFilter = useCallback(
    (e, keyName = "keyword") => {
      e.preventDefault();
      setApiParam(prev => {
        const newParams = prev;
        unset(newParams, keyName);
        fetchList({ ...newParams, currentPage: 1, limit: rowLimit });
        return newParams;
      });
    },
    [fetchList, rowLimit],
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, currentPage: newPage + 1, limit: rowLimit };
        setApiParam(prev => ({ ...prev, currentPage: newPage + 1 }));
        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam],
  );
  const changeRowLimit = useCallback(
    e => {
      const size = Number(e.target.value);
      setApiParam(prev => {
        const body = { ...prev, currentPage: 1, limit: size };
        fetchList(body, true);
        return body;
      });
    },
    [fetchList],
  );

  return {
    sorting,
    clinicId,
    rowLimit,
    apiParam,
    tablePage,
    showSpinner,
    errorMessage,
    totalRecords,
    rowSelection,
    isClinicSide,
    staffListing: staff,
    searchKeyword,
    setSorting,
    applyFilter,
    clearFilter,
    refetchList,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    handleChangePage,
  };
}

export default useStaffListing;
