import React, { useEffect, useMemo } from "react";
import { usePatientProvider } from "./Hook";
import usePatientApi from "./Hook/usePatientApi";
import {
  PatientActionContext,
  PatientApiActionContext,
  PatientApiStateContext,
  PatientFormActionContext,
  PatientFormSateContext,
  PatientStateContext,
} from "./PatientContext";

function PatientProvider({ children }) {
  const {
    patientData,
    apiLoading: showSpinner,
    isPatientEdit,
    clinicFetching,
    physicianFetching,
    apiError,
    physicianData,
    clinicLocationOption,
    fetchPhysician,
    getEditApiParam,
    clinicAddressRefetch,
    findShippingMethod,
    getShippingMethodOption,
    validateShippingAddress,
  } = usePatientApi();

  const {
    formRef,
    activeStep,
    profilePic,
    completedStep,
    imageUploading,
    mutateLoading,
    clinicLocationId,
    patientDefaultAddress,
    patientInsertSuccessfully,
    mutateError: providerError,
    formData,
    goBack,
    goNext,
    setProfilePic,
    progressBarAction,
    removeProfileImage,
    profileImageUploading,
  } = usePatientProvider(getEditApiParam, isPatientEdit);

  useEffect(() => {
    if (patientData?.id) {
      setProfilePic(patientData?.patientImageUrl ?? "");
    }
  }, [patientData?.id, patientData?.patientImageUrl, setProfilePic]);

  const mutateError = useMemo(() => providerError || apiError, [apiError, providerError]);

  const spinnerLoading = useMemo(() => mutateLoading || showSpinner, [mutateLoading, showSpinner]);

  const patientStateValue = useMemo(
    () => ({
      showSpinner: spinnerLoading,
      patientInsertSuccessfully,
      isPatientEdit,
      profilePic,
      imageUploading,
      mutateError,
    }),
    [spinnerLoading, patientInsertSuccessfully, isPatientEdit, profilePic, imageUploading, mutateError]
  );

  const patientApiStateValue = useMemo(
    () => ({ patientData, clinicFetching, physicianFetching, physicianData, clinicLocationOption }),
    [clinicFetching, clinicLocationOption, patientData, physicianData, physicianFetching]
  );

  const patientFormStateValue = useMemo(
    () => ({ completedStep, formRef, activeStep, clinicLocationId, patientDefaultAddress }),
    [activeStep, clinicLocationId, completedStep, formRef, patientDefaultAddress]
  );

  const patientActionValue = useMemo(
    () => ({ removeProfileImage, profileImageUploading }),
    [profileImageUploading, removeProfileImage]
  );

  const patientApiActionValue = useMemo(
    () => ({
      fetchPhysician,
      clinicAddressRefetch,
      getShippingMethodOption,
      findShippingMethod,
      validateShippingAddress,
    }),
    [fetchPhysician, clinicAddressRefetch, getShippingMethodOption, findShippingMethod, validateShippingAddress]
  );

  const patientFormActionValue = useMemo(
    () => ({ goBack, goNext, formData, progressBarAction }),
    [goBack, goNext, formData, progressBarAction]
  );

  return (
    <PatientStateContext.Provider value={patientStateValue}>
      <PatientApiStateContext.Provider value={patientApiStateValue}>
        <PatientActionContext.Provider value={patientActionValue}>
          <PatientApiActionContext.Provider value={patientApiActionValue}>
            <PatientFormActionContext.Provider value={patientFormActionValue}>
              <PatientFormSateContext.Provider value={patientFormStateValue}>
                {children}
              </PatientFormSateContext.Provider>
            </PatientFormActionContext.Provider>
          </PatientApiActionContext.Provider>
        </PatientActionContext.Provider>
      </PatientApiStateContext.Provider>
    </PatientStateContext.Provider>
  );
}

export default React.memo(PatientProvider);
