import { createColumnHelper } from "@tanstack/react-table";

const adminColumnHelper = createColumnHelper();

const ADMIN_APPROVED_CLINICS = [
  adminColumnHelper.accessor("businessName", {
    header: "Clinic Name",
  }),
  adminColumnHelper.accessor("contactName", {
    header: "Contact Name",
    cell: ({ row }) => (row.original?.officeContactFirstName ?? "") + " " + (row.original?.officeContactLastName ?? ""),
  }),
  adminColumnHelper.accessor("email", {
    header: "Email",
  }),
  adminColumnHelper.accessor("officePhone", {
    header: "Phone",
  }),
  adminColumnHelper.accessor("totalPatients", {
    header: "Total Patients",
  }),

  adminColumnHelper.accessor("pendingPrescriptions", {
    header: "Pending Prescriptions",
  }),
];

const ADMIN_PENDING_CLINICS = [
  adminColumnHelper.accessor("businessName", {
    header: "Clinic Name",
  }),
  adminColumnHelper.accessor("providerName", {
    header: "Provider Name",
    cell: ({ row }) => (row.original?.providerFirstName ?? "") + " " + (row.original?.providerLastName ?? ""),
  }),
  adminColumnHelper.accessor("contactName", {
    header: "Contact Name",
    cell: ({ row }) => (row.original?.officeContactFirstName ?? "") + " " + (row.original?.officeContactLastName ?? ""),
  }),
  adminColumnHelper.accessor("email", {
    header: "Email",
  }),
  adminColumnHelper.accessor("officePhone", {
    header: "Phone",
  }),
];

export { adminColumnHelper, ADMIN_PENDING_CLINICS, ADMIN_APPROVED_CLINICS };
