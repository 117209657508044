
import moment from "moment-timezone";
import { SHIP_METHODS } from "./StatusConstant";
import { decrypt } from "cryptofunc";

const dateFormatterMountainTime = time => {
  if (time) {
    const mtTime = moment.utc(time).tz('America/Denver').format('YYYY-MM-DD HH:mmA z');
    return mtTime;
  } else {
    return "-";
  }
};

const getCardNumber = (billingInfo = "") => {
  if (billingInfo) {
    const obj = decrypt(billingInfo);
    return `${obj?.creditCard?.cardHolderName} - card ending in ${obj?.creditCard?.creditCardNumber?.substring(
      obj?.creditCard?.creditCardNumber?.length - 4,
      obj?.creditCard?.creditCardNumber?.length,
    )}`;
  }
  return "-";
};

const BAR_COLORS = {
  "Order In Process": "#DE3163",
  "Order On Hold": "#FF4300",
  "Order Cancelled": "#ff8400",
  "Order Complete": "#9FE2BF",
  "Pending Approval": "#6495ED",
};

const mapShippingMethod = meth => {
  return SHIP_METHODS.filter(a => a.value === meth)[0]?.label;
};
const mapShippingMethodValue = label => {
  return SHIP_METHODS.filter(a => a.label === label)[0]?.value;
};
const IS_EDIT_RX = window?.location?.pathname.includes("edit-prescription");
const IS_NEW_RX = window?.location?.pathname.includes("edit-prescription");

export {
  dateFormatterMountainTime,
  getCardNumber,
  BAR_COLORS,
  mapShippingMethod,
  mapShippingMethodValue,
  IS_EDIT_RX,
  IS_NEW_RX,
};
