import { ADMIN_END_POINTS, CLINIC_LOCATION_END_POINT } from "./ApiConstant";

import { STEPPER_FORMS } from "../../utils";

const RX_STATUS_COMPLETED = ["RECEIVED", "CANCELLED"];

const ACTION_TO_ENDPOINT = {
  Delete: ADMIN_END_POINTS.ADMIN_PRESCRIPTION_CANCEL,
  Approve: ADMIN_END_POINTS.APPROVE_PRESCRIPTION,
  Disapprove: ADMIN_END_POINTS.DISAPPROVE_PRESCRIPTION,
};

const PAGE_TO_ENDPOINT = {
  StaffListing: "getStaffList",
  PatientListing: "getPatientsList",
  ClinicLocationListing: "getClinicLocations",
  PatientPrescription: "getPatientPrescription",
  ClinicProducts: "getAllDiscountedProductsByClinicId",
  AdminPendingPrescription: "getAdminPendingPrescription",
  AdminRxWorkflowPrescription: "adminRxWorkflowPrescription",
  AdminArchivedPrescription: "adminArchivedPrescription",
  GetApprovedClinicList: "getApprovedClinicList",
  GetPendingClinicList: "getPendingClinicList",
  PATIENT_SUMMARY: "getPatientPrescription",
};

const CLINIC_LOCATION_FORM_TO_ENDPOINT = {
  [STEPPER_FORMS.PERSONAL_INFO]: CLINIC_LOCATION_END_POINT.EDIT_CLINIC_LOCATION_INFO,
  [STEPPER_FORMS.ADDRESS_INFO]: CLINIC_LOCATION_END_POINT.EDIT_CLINIC_LOCATION_OFFICE_PREFERENCE,
  [STEPPER_FORMS.CREDIT_INFO]: CLINIC_LOCATION_END_POINT.EDIT_CLINIC_LOCATION_CARDS,
  [STEPPER_FORMS.NOTIFICATION_INFO]: CLINIC_LOCATION_END_POINT.EDIT_CLINIC_LOCATION_NOTIFICATION,
};

export { RX_STATUS_COMPLETED, PAGE_TO_ENDPOINT, ACTION_TO_ENDPOINT, CLINIC_LOCATION_FORM_TO_ENDPOINT };
