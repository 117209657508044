import React, { useCallback, useEffect, useState } from "react";
import { FormGroup } from "reactstrap";

const PaymentStatusDD = (props) => {
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    setSelectedStatus(props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = useCallback(
    (e) => {
      props.enableFilterButton?.();
      props.updatedFilterPaymentStatusValue("paymentStatus", [e.target.value]);
      setSelectedStatus(e.target.value);
    },
    [props]
  );

  return (
    <FormGroup key={props}>
      <select
        onChange={handleChange}
        defaultValue=""
        value={selectedStatus ? selectedStatus : ""}
        style={{
          borderRadius: "5px",
          borderColor: "#CED4DA",
          width: "140px",
          padding: props.padding ? props.padding : "10px",
        }}
        id="selectedPaymentStatus"
        type="select"
        name="selectedPaymentStatus"
      >
        <option style={{ color: "#495057" }} value="" disabled>
          Payment Status
        </option>
        {props.statuses}
      </select>
    </FormGroup>
  );
};

export default React.memo(PaymentStatusDD);
