import React, { useMemo } from "react";
import {
  ClinicLocationActionContext,
  ClinicLocationApiActionContext,
  ClinicLocationApiStateContext,
  ClinicLocationFormActionContext,
  ClinicLocationStateContext,
} from "./ClinicLocationContext";
import { useClinicLocationApi, useClinicLocationProfileProvider } from "./Hook";

function ClinicLocationProfileProvider({ children }) {
  const {
    apiLoading: showSpinner,
    apiError,
    clinicLocationId,
    filterClinicId,
    clinicLocationData,
    getEditApiParam,
    validateShippingAddress,
  } = useClinicLocationApi();

  const {
    mutateError: providerError,
    mutateLoading,
    fileUploading,
    mutateSuccessMessage,
    clinicLocationInsertSuccessfully,
    formData,
    uploadFile,
  } = useClinicLocationProfileProvider(getEditApiParam);

  const mutateError = useMemo(() => providerError || apiError, [apiError, providerError]);

  const spinnerLoading = useMemo(
    () => mutateLoading || fileUploading || showSpinner,
    [fileUploading, mutateLoading, showSpinner],
  );

  const clinicLocationStateValue = useMemo(
    () => ({
      mutateError,
      showSpinner: spinnerLoading,
      isClinicLocationEdit: true,
      mutateSuccessMessage,
      clinicLocationInsertSuccessfully,
    }),
    [mutateError, spinnerLoading, mutateSuccessMessage, clinicLocationInsertSuccessfully],
  );

  const clinicLocationApiStateValue = useMemo(
    () => ({ clinicLocationId, filterClinicId, clinicLocationData }),
    [clinicLocationData, clinicLocationId, filterClinicId],
  );

  const clinicLocationActionValue = useMemo(() => ({ uploadFile }), [uploadFile]);

  const clinicLocationApiActionValue = useMemo(
    () => ({
      validateShippingAddress,
    }),
    [validateShippingAddress],
  );

  const clinicLocationFormActionValue = useMemo(() => ({ formData }), [formData]);

  return (
    <ClinicLocationStateContext.Provider value={clinicLocationStateValue}>
      <ClinicLocationApiStateContext.Provider value={clinicLocationApiStateValue}>
        <ClinicLocationActionContext.Provider value={clinicLocationActionValue}>
          <ClinicLocationApiActionContext.Provider value={clinicLocationApiActionValue}>
            <ClinicLocationFormActionContext.Provider value={clinicLocationFormActionValue}>
              {children}
            </ClinicLocationFormActionContext.Provider>
          </ClinicLocationApiActionContext.Provider>
        </ClinicLocationActionContext.Provider>
      </ClinicLocationApiStateContext.Provider>
    </ClinicLocationStateContext.Provider>
  );
}

export default React.memo(ClinicLocationProfileProvider);
