import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Cookies from "universal-cookie";
import axiosConfig from "../../utils/axiosConfig";
import { encrypt, decrypt } from "cryptofunc";
const ClinicCreditCardBillingDD = props => {
  const [cards, setCards] = useState([]);
  const [cardsList, setCardsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [decryptedCard, setDecryptedCard] = useState("");

  useEffect(() => {
    let defaultValue = props.defaultValue ? props.defaultValue : {};
    setLoading(true);
    axiosConfig
      .get("clinicLocations/getClinicLocationCreditCardsByClinicLocationId/" + props.locationID, {
        headers: { Authorization: "Bearer " + new Cookies().get("user_token") },
      })
      .then(async response => {
        var cardsValues = [];
        var cardsArray = [];

        var encryptedArray = response.data.encryptedCreditCards;

        var decryptedArr = [];
        encryptedArray.forEach(card => {
          decryptedArr.push(decrypt(card));
        });
        await decryptedArr?.forEach((card, i) => {
          let selectedBool = false;
          if (defaultValue?.cardExpirationDate) {
            selectedBool =
              defaultValue.cardExpirationDate === card.cardExpirationDate &&
              defaultValue.creditCardNumber === card.creditCardNumber;
          } else if (card.isCardDefault) {
            selectedBool = true;
          }
          //selected={card.isCardDefault}
          cardsValues.push(
            <option id={i + "card" + i} value={card._id}>
              {card?.cardHolderName +
                " - card ending in " +
                card.creditCardNumber.substring(card.creditCardNumber.length - 4, card.creditCardNumber.length)}
            </option>,
          );
          let carObj = {
            id:
              card?.cardHolderName +
              " - card ending in " +
              card.creditCardNumber.substring(card.creditCardNumber.length - 4, card.creditCardNumber.length),
            creditCardNumber: card.creditCardNumber,
            cardHolderName: card.cardHolderName,
            cardExpirationDate: card.cardExpirationDate.replace(/\s/g, ""),
            // cvv: card.cvv,
            cardHolderAddressLine1: card.cardHolderAddressLine1,
            cardHolderAddressLine2: card.cardHolderAddressLine2,
            cardHolderState: card.cardHolderState,
            cardHolderCity: card.cardHolderCity,
            cardHolderZipcode: card.cardHolderZipcode,
            isCardDefault: card.isCardDefault,
          };

          // if (card.isCardDefault) {
          //   props.setCreditCard(carObj, "clinic", props.locationID, props.creditRequested);
          // }
          cardsArray.push(carObj);
        });

        setCards(cardsValues);
        await setCardsList(cardsArray);
        await setLoading(false);
      })
      .catch(() => {});
  }, [props.locationID]);
  return (
    <FormGroup key={props.locationID}>
      <Label className="normalText" for={props.name}>
        Credit Card {props.required ? <span className="requiredStar">*</span> : ""}
      </Label>
      <p className="dropdownArrow">
        <i className="fas fa-angle-down"></i>
      </p>
      <Input
        type="select"
        name={props.name}
        readOnly={props.readyOnly ?? false}
        required={props.required}
        onChange={e => {
          for (var i = 0; i < cardsList.length; i++) {
            if (cardsList[i].id.replace(/\s/g, "").toString() == e.target.value.replace(/\s/g, "").toString()) {
              props.setCreditCard(cardsList[i], "clinic", props.locationID, props.creditRequested);
            }
          }
        }}>
        <option value="" disabled selected>
          {cards?.length ? "Select" : loading ? "Loading Credit Cards ..." : "No card data found"}
        </option>
        {cards}
      </Input>
    </FormGroup>
  );
};

export default ClinicCreditCardBillingDD;
