const CLINIC_FILTER_OPTIONS = [
  { value: "email", label: "Email" },
  { value: "officeContactFirstName,officeContactLastName", label: "User Name" },
  { value: "businessName", label: "Clinic Name" },
];

const PATIENT_FILTER_OPTIONS = [
  { value: "email", label: "Email" },
  { value: "firstName,lastName", label: "Patient Name" },
  { value: "cellPhone", label: "Phone Number" },
  { value: "clinicName", label: "Clinic Name" },
  { value: "clinicLocationName", label: "Clinic Location Name" },
];

const PRESCRIPTION_FILTER_OPTIONS = [
  { value: "invoiceNumber", label: "Invoice number" },
  { value: "patientName", label: "Patient Name" },
  { value: "products.name", label: "Product Name" },
  { value: "clinicLocationName", label: "Clinic Location Name" },
];

const INVOICE_FILTER_OPTIONS = [
  { value: "invoiceNumber", label: "Invoice number" },
  { value: "patientName", label: "Patient Name" },
];

const PRODUCT_FILER_OPTIONS = [
  { value: "family", label: "Category" },
  { value: "commonName", label: "Common Name" },
  { value: "subCategory1", label: "Subcategory 1" },
  { value: "subCategory2", label: "Subcategory 2" },
];

export {
  CLINIC_FILTER_OPTIONS,
  PATIENT_FILTER_OPTIONS,
  PRESCRIPTION_FILTER_OPTIONS,
  INVOICE_FILTER_OPTIONS,
  PRODUCT_FILER_OPTIONS,
};
