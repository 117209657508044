/* eslint-disable react-hooks/exhaustive-deps */
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Button, Chip, Divider, FormControlLabel, Grid, Radio, Tooltip } from "@mui/material";
import { FormOutlineField, FromAutoComplete } from "components";
import { PatientApiStateContext, PatientStateContext } from "context";
import { map } from "lodash";
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef } from "react";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { USA_STATES } from "utils";

const addressFieldFormat = {
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipcode: "",
};

const PatientAddresses = forwardRef(({ formId, formData }, parentRef) => {
  const formRef = useRef(null);
  const { isPatientEdit } = useContext(PatientStateContext);
  const { patientData } = useContext(PatientApiStateContext);
  const { control, handleSubmit, getValues, setValue, reset, trigger } = useForm({
    defaultValues: {
      addresses: [{ ...addressFieldFormat }],
      default_address_index: 0,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });

  const submitAction = useCallback(
    ({ default_address_index, ...data }, progressBarIndex = null, allowNavigation = true) => {
      const organizedAddress = map(data?.addresses ?? [], (address, index) => ({
        ...address,
        isDefault: index === default_address_index,
      }));
      const info = { ...data, addresses: organizedAddress };
      formData?.({ info }, formId, progressBarIndex, allowNavigation);
    },
    [formData, formId],
  );

  useEffect(() => {
    if (isPatientEdit && patientData?.id) {
      let default_address_index = 0;
      const addresses = map(patientData?.addresses, ({ _id, isDefault, ...address }, index) => {
        if (isDefault) {
          default_address_index = index;
        }
        return { ...address };
      });
      reset({
        addresses,
        default_address_index,
        email: patientData?.email ?? "",
        cellPhone: patientData?.cellPhone ?? "",
        phoneNumber: patientData?.phoneNumber ?? "",
      });
      submitAction(getValues(), null, false);
    }
  }, [isPatientEdit, patientData?.id]);

  const defaultAddressWatch = useWatch({ control, name: "default_address_index" });

  const radioBtn = useCallback(index => () => setValue("default_address_index", index), []);

  const appendAddress = useCallback(e => {
    e.preventDefault?.();
    append(
      {
        ...addressFieldFormat,
      },
      { shouldFocus: false },
    );
  }, []);

  const removeAddress = useCallback(
    index => () => {
      const isLastCard = !(getValues("addresses").length - 1);
      if (!isLastCard) {
        remove(index);
      }
    },
    [],
  );

  const canNavigate = useCallback(
    progressBarIndex => {
      handleSubmit(data => submitAction(data, progressBarIndex))();
    },
    [handleSubmit, submitAction],
  );

  const checkValidation = useCallback(async () => {
    const formValidated = await trigger();
    return formValidated;
  }, []);

  const requestFormSubmit = useCallback(() => formRef.current?.requestSubmit?.(), []);

  useImperativeHandle(parentRef, () => ({
    submitForm: requestFormSubmit,
    canNavigate: canNavigate,
    checkFilledForm: checkValidation,
  }));

  const addressDivider = useCallback(
    index => {
      const isLastCard = !(getValues("addresses").length - 1);
      return (
        <Divider>
          <Tooltip title="Remove the address below">
            <Chip
              clickable
              color="secondary"
              label={`Address ${index + 1}`}
              deleteIcon={<CancelIcon style={{ color: "#f44336" }} />}
              onClick={removeAddress(index)}
              onDelete={isLastCard ? null : removeAddress(index)}
            />
          </Tooltip>
        </Divider>
      );
    },
    [removeAddress],
  );

  return (
    <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
      <Grid container marginY={2} columnSpacing={3} rowSpacing={1}>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="email"
            control={control}
            rules={{ pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" } }}
            MuiFieldProps={{ label: "Email address", type: "email", placeholder: "abc@abc.com" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="cellPhone"
            control={control}
            rules={{
              required: "Mobile Phone is required.",
              pattern: { value: /^(\d{10})?$/, message: "Invalid Mobile Phone" },
            }}
            MuiInputProps={{ format: "##########" }}
            MuiFieldProps={{ label: "Mobile Phone", type: "tel", placeholder: "Format: 1234567890", isMasked: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormOutlineField
            name="phoneNumber"
            control={control}
            rules={{ pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" } }}
            MuiInputProps={{ format: "##########" }}
            MuiFieldProps={{ label: "Other Phone", type: "tel", placeholder: "Format: 1234567890", isMasked: true }}
          />
        </Grid>
        {/* Address */}
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {index > 0 && addressDivider(index)}
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name={`addresses.${index}.addressLine1`}
                control={control}
                rules={{ required: "Address is required." }}
                MuiFieldProps={{ label: "Address line 1", multiline: true, rows: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name={`addresses.${index}.addressLine2`}
                control={control}
                MuiFieldProps={{ label: "Address line 2", multiline: true, rows: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name={`addresses.${index}.city`}
                control={control}
                rules={{ required: "City is required." }}
                MuiFieldProps={{ label: "City" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FromAutoComplete
                control={control}
                name={`addresses.${index}.state`}
                rules={{ required: "State is required." }}
                MuiFieldProps={{ label: "State", optionArray: USA_STATES }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name={`addresses.${index}.zipcode`}
                control={control}
                rules={{
                  required: "Zip Code is required.",
                  pattern: { value: /^\d{5}(-\d{4})?$/, message: "Invalid ZipCode" },
                }}
                MuiFieldProps={{ label: "Zip Code", placeholder: "12345-1234" }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Radio style={{ fontSize: 20 }} />}
                label="Default Address"
                value={index}
                checked={index === defaultAddressWatch}
                onChange={radioBtn(index)}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid container justifyContent={"center"}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          className="pinkButton"
          onClick={appendAddress}
          startIcon={<AddLocationAltIcon />}>
          Add Another Address
        </Button>
      </Grid>
    </Box>
  );
});
export default React.memo(PatientAddresses);
