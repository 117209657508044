import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const NotificationsDD = (props) => {
  const [defaultValue, setDefaultValue] = useState(props.defaultValue);

  useEffect(() => {
    setDefaultValue(props.defaultValue);
  }, [props]);

  return (
    <FormGroup>
      <Label className='normalText' for={props.name}>
        Notification Method {props.required ? <span className='requiredStar'>*</span> : ''}
      </Label>
      <p className='dropdownArrow'>
        <i className='fas fa-angle-down'></i>
      </p>
      <Input type='select' style={{ paddingRight: '20px' }} name={props.name} required={props.required} defaultValue={defaultValue}>
        <option value='' disabled>
          Select
        </option>
        <option value='Notify Both Email and Text'>Notify through both Email and Text</option>
        <option value='Notify Email'>Notify through email</option>
        <option value='Notify Text'>Notify through Text</option>
        <option value='Do not notify'>Do not notify</option>
      </Input>
    </FormGroup>
  );
};

export default NotificationsDD;
