import React, { useEffect, useState, useRef } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import axiosConfig, { errorResponse } from "../../utils/axiosConfig";
import Cookies from "universal-cookie";
const ProductFamilyDD = props => {
  //TODO: Populate based on clinic products
  const { name, label, register, required, categoryDefaultVal } = props;
  const [families, setFamilies] = useState([]);

  useEffect(() => {
    axiosConfig
      .get("products/getAllFamiliesAndCategories", {
        headers: {
          Authorization: "Bearer " + new Cookies().get("user_token"),
        },
      })
      .then(async response => {
        var familyList = [];
        var sortedData = response.data.sort((a, b) => (a.family > b.family ? 1 : b.family > a.family ? -1 : 0));

        await sortedData.forEach((data, i) => {
          familyList.push(data.family);

          familyList.push(
            <option
              key={data.family + i}
              value={data.family}
              onChange={e => {
                props.setSubcategory1(data.categories);
              }}>
              {data.family}
            </option>,
          );
        });

        setFamilies(familyList);
      })
      .catch(error => {});
  }, []);
  return (
    <FormGroup>
      <Label htmlFor={name} className="form-label">
        {label} {required && <span className="requiredStar">*</span>}
      </Label>
      <select
        name={name}
        value={categoryDefaultVal}
        {...register(name, { required: true })}
        style={{
          borderRadius: "5px",
          paddingBottom: "0px",
          width: "100%",
          height: "40px",
          borderColor: "#CDCDCD",
        }}>
        <option value="" disabled>
          {label}
        </option>
        {families}
      </select>
    </FormGroup>
  );
};

export default ProductFamilyDD;
