import { StaffApi } from "../../rtkApi";
import { API_METHODS, CACHE_TAGS, STAFF_END_POINT, providesList } from "../../rtkConstant";

const StaffQuery = StaffApi.injectEndpoints({
  endpoints: builder => ({
    getAllClinicStaffList: builder.query({
      queryFn: async ({ clinicId = "" } = {}, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              clinicId: clinicId || user?.userData?.clinicId,
            };
            const response = await baseQuery({
              url: STAFF_END_POINT.GET_ALL_STAFF_IN_CLINIC,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: result => [{ type: CACHE_TAGS.STAFF }, ...providesList(result?.staff, CACHE_TAGS.STAFF)],
    }),
    getStaffList: builder.query({
      queryFn: async ({ clinicId = "", ...apiArgs } = {}, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = {
              ...apiArgs,
              limit: apiArgs?.limit || 20,
              clinicId: clinicId || user?.userData?.clinicId,
            };
            const response = await baseQuery({
              url: STAFF_END_POINT.GET_STAFF_IN_CLINIC,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
      providesTags: result => [{ type: CACHE_TAGS.STAFF }, ...providesList(result?.staff, CACHE_TAGS.STAFF)],
    }),
    getStaff: builder.query({
      query: ({ staffId }) => ({
        url: STAFF_END_POINT.GET_STAFF + "/" + staffId,
        method: API_METHODS.GET,
      }),
      providesTags: result => [{ type: CACHE_TAGS.STAFF, id: result?.id }],
    }),
    resendAutoGen: builder.query({
      query: apiArgs => ({
        url: STAFF_END_POINT.RESEND_AUTO_GEN + "/" + apiArgs,
        method: API_METHODS.GET,
      }),
      keepUnusedDataFor: 0,
    }),
    getPhysician: builder.query({
      queryFn: async ({ clinicId = "", ...apiArgs }, queryApi, _, baseQuery) => {
        try {
          const user = queryApi.getState().AuthSlice || {};
          if (user?.userData) {
            const organizedBody = { clinicId: clinicId || user?.userData?.clinicId, ...apiArgs };
            const response = await baseQuery({
              url: STAFF_END_POINT.GET_PHYSICIAN_LIST,
              method: API_METHODS.POST,
              body: organizedBody,
            });
            return response;
          } else {
            return {
              error: {
                data: { message: "Unauthenticated user" },
              },
            };
          }
        } catch {}
      },
    }),
  }),
});
export const {
  useGetStaffQuery,
  useLazyGetStaffQuery,
  useLazyResendAutoGenQuery,
  useLazyGetStaffListQuery,
  useLazyGetPhysicianQuery,
  useGetAllClinicStaffListQuery,
} = StaffQuery;
