import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardTitle, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import classnames from 'classnames';
import { isEmpty, map, size } from 'lodash';

//Import Product Images
import { productImages } from '../../../assets/images/sarms';
import PatientNavbar from '../../../components/patientNavbar';
//Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb"

//Import data
import { discountData, productsData, sarmsProductData } from './data/education';

//Import modal

import DisplayModal from './displayModalMoreData';
import CompareModal from './compareDataModal';

import CompareModalForTwo from './compareDataModalForTwo';

let i = 0;
var temp = [];
var pictures = [];
var names = [];
class sarms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: '',
      showModal: false,
      showCompareModal: false,
      showCompareModalForTwo: false,
      modalData: '',
      compareData: [], //change to string if error
      pictureData: [],
      namesdata: [],
      nameofproduct: '',

      FilterClothes: [
        { id: 1, name: 'T-shirts', link: '#' },
        { id: 2, name: 'Shirts', link: '#' },
        { id: 3, name: 'Jeans', link: '#' },
        { id: 4, name: 'Jackets', link: '#' },
      ],
      products: [],
      activeTab: '1',
      discountData: [],
      filters: {
        discount: [],
        price: { min: 0, max: 500 },
      },
      page: 1,
      totalPage: 5, //replace this with total pages of data
    };
  }

  componentDidMount() {
    const { products, onGetProducts } = this.props;
    this.setState({ products: sarmsProductData });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { products } = this.props;
    if (isEmpty(prevProps.products) && !isEmpty(products) && size(products) !== size(prevProps.products)) {
      this.setState({ products: sarmsProductData });
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  onUncheckMark = () => {
    this.setState({
      products: sarmsProductData,
    });
    // setProductList(productsData)
  };

  closeModal() {
    this.setState({
      showModal: false,
      showCompareModal: false,

      showCompareModalForTwo: false,
    });
  }

  render() {
    const { history } = this.props;
    const { discountData, products, page, totalPage } = this.state;
    return (
      <React.Fragment>
        {' '}
        <PatientNavbar />
        <div className='page-content'>
          <MetaTags>
            <title>Education |SARMS</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col>
                <Alert color='danger' align='center'>
                  WARNING: THIS IS NOT MEDICAL ADVICE!
                  <br />
                  THIS INFORMATION IS FOR EDUCATIONAL PURPOSES ONLY.
                  <br />
                  DO NOT USE MEDICATIONS/SUPPLEMENTS WITHOUT THE SUPERVISION AND DIRECTION OF A MEDICAL PROFESSIONAL.
                </Alert>
              </Col>
            </Row>
            {/* <Breadcrumbs title="Education" breadcrumbItem="SARMS" /> */}

            <Row>
              <Row className='mb-3'>
                <Col xl='4' sm='6'>
                  <div className='mt-2'>
                    <h5>Selective Androgen Receptor Modulators (SARMS)</h5>
                  </div>
                </Col>
                <Col lg='8' sm='6'></Col>
              </Row>

              <Col lg='9'>
                <Row>
                  {!isEmpty(this.state.products) &&
                    this.state.products.map((product, key) => {
                      return product.id < 10 ? (
                        <Col md='3' key={'_col_' + key}>
                          <div align='center'>
                            <h6 className='text-muted mt-3' style={{ height: '25px' }}>
                              {product.name}
                            </h6>
                          </div>
                          <Card className='bg-secondary'>
                            <CardBody>
                              <Link
                                to='#'
                                onClick={() => {
                                  this.setState({
                                    showModal: true,
                                    modalData: product.data,
                                    nameofproduct: product.name,
                                  });
                                }}
                              >
                                <div className='product-img position-relative'>
                                  <img src={productImages[product.image]} alt='' className='img-fluid mx-auto d-block' />
                                </div>
                              </Link>

                              <div className='mt-4 text-center'></div>
                              <div className='form-check mb-2'>
                                <input
                                  type='checkbox'
                                  className='form-check-input input-mini'
                                  id='closeButton'
                                  value='checked'
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      //append compareData <= product.data
                                      //compareData.push(productData)
                                      //instead of id push data
                                      // temp.push(product.id)
                                      // this.setState({compareData: temp})
                                      i = i + 1;
                                      temp.push(product.data);
                                      pictures.push(product.image);
                                      names.push(product.name);
                                      this.setState({ compareData: temp, pictureData: pictures, namedata: names });
                                    }
                                    if (!e.target.checked) {
                                      i = i - 1;
                                      //test

                                      temp.splice(product.data, 1);
                                      this.setState({ compareData: temp });
                                      pictures.splice(product.image, 1);
                                      this.setState({ pictureData: temp });
                                      names.splice(product.name, 1);
                                      this.setState({ namedata: names });
                                      //this.setState({ compareData: temp, pictureData: pictures });
                                    }
                                  }}
                                />
                                <Label className='form-check-label text-light'>Select to Compare</Label>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ) : null;
                    })}
                </Row>
              </Col>
              <Col md='3'>
                <Card className='mt-5'>
                  <CardBody className='p-4'>
                    <div className='mb-4'>
                      <h5>Click to compare selected items</h5>
                    </div>
                    <div align='center'>
                      <Button
                        disabled={i < 4 ? false : true}
                        color='primary'
                        align='center'
                        className='btn-lg'
                        //onclick showmodal true
                        // set showmodal and compareData
                        // onClick = {(e) => {
                        //   e.preventDefault();

                        // }}
                        onClick={() => {
                          if (i == 3) {
                            this.setState({
                              showCompareModal: true,
                              compareData: temp,
                              pictureData: pictures,
                              namesdata: names,
                            });
                          } else if (i == 2) {
                            this.setState({
                              showCompareModalForTwo: true,
                              compareData: temp,
                              namesdata: names,
                              pictureData: pictures,
                            });
                          }
                        }}
                      >
                        Compare
                      </Button>
                    </div>
                    {i > 3 ? (
                      <div className=' ml-3 mt-3' align='center'>
                        <p style={{ color: 'red' }}> Select three or less items only! </p>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              </Col>

              {/* checkpoint 1 */}
            </Row>
          </Container>
        </div>
        <DisplayModal
          showModal={this.state.showModal}
          nameofproduct={this.state.nameofproduct}
          displayModalData={
            this.state.modalData //for compare make changes here
          }
          closeModal={this.closeModal.bind(this)}
        />
        <CompareModal showModal={this.state.showCompareModal} namesdata={this.state.namesdata} displayModalData={this.state.compareData} pictureData={this.state.pictureData} closeModal={this.closeModal.bind(this)} />
        <CompareModalForTwo showModal={this.state.showCompareModalForTwo} namesdata={this.state.namesdata} displayModalData={this.state.compareData} pictureData={this.state.pictureData} closeModal={this.closeModal.bind(this)} />
      </React.Fragment>
    );
  }
}

sarms.propTypes = {
  products: PropTypes.array,
  onGetProducts: PropTypes.func,
};

export default sarms;
