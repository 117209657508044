const NavigationSteps = ({ step }) => {
  return (
    <div className="position-relative mx-auto w-50 my-5" style={{ margin: "60px !important" }}>
      <div className="position-absolute top-0 start-0 translate-middle rounded-pill" style={{ width: "200px" }}>
        <div className={step === 1 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
          <p>1</p>
        </div>
        <p className="m-0">Practice</p>
        <p>Information</p>
      </div>
      <div className="position-absolute top-0 start-50 translate-middle rounded-pill" style={{ width: "200px" }}>
        <div className={step === 2 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
          <p>2</p>
        </div>
        <p className="m-0">Office Contact</p>
        <p>Information</p>
      </div>
      <div className="position-absolute top-0 start-100 translate-middle rounded-pill" style={{ width: "200px" }}>
        <div className={step === 3 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
          <p>3</p>
        </div>
        <p className="m-0">BAA</p>
        <p>Information</p>
      </div>
    </div>
  );
};

export default NavigationSteps;


