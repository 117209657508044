import { Box, Grid } from "@mui/material";
import { FormOutlineField, FormSelectField, FromAutoComplete } from "components";
import ValidAddressModal from "components/validAddressModal";
import { ClinicLocationApiActionContext, ClinicLocationApiStateContext, ClinicLocationStateContext } from "context";
import { map } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useGetAllClinicStaffListQuery } from "rtk";
import { USA_STATES, VERBAL_ORDERS } from "utils";

const ClinicLocationPracticeInfo = forwardRef(
  ({ formId, formData, setCustomError, additionalInfo = false }, parentRef) => {
    const formRef = useRef(null);
    const [fedexShippingAddress, setFedexShippingAddress] = useState(null);
    const { isClinicLocationEdit } = useContext(ClinicLocationStateContext);
    const { clinicLocationData, filterClinicId } = useContext(ClinicLocationApiStateContext);
    const { validateShippingAddress } = useContext(ClinicLocationApiActionContext);
    const { data: staffData, isFetching: staffFetching } = useGetAllClinicStaffListQuery({ clinicId: filterClinicId });

    const { control, handleSubmit, setValue, getValues, trigger } = useForm({
      defaultValues: {
        staffIds: [],
      },
    });

    const showValidatedAddress = useMemo(() => fedexShippingAddress !== null, [fedexShippingAddress]);

    useEffect(() => {
      if (isClinicLocationEdit && clinicLocationData) {
        const formFields = getValues();
        for (const field in formFields) {
          let fieldValue = clinicLocationData?.[field] ?? "";
          if (field === "staffIds") {
            fieldValue = map(clinicLocationData?.staff, "id");
          }
          setValue(field, fieldValue);
        }
        submitAction(getValues(), null, false);
      }
    }, [isClinicLocationEdit, clinicLocationData]);

    const submitAction = useCallback(
      (data, progressBarIndex = null, allowNavigation = true) => {
        formData?.({ info: data }, formId, progressBarIndex, allowNavigation);
      },
      [formData, formId],
    );

    const staffArray = useMemo(() => {
      if (staffFetching) {
        return [
          {
            value: "",
            label: "Loading",
            disabled: true,
          },
        ];
      } else if (staffData?.staff?.length < 1) {
        return [
          {
            value: "",
            label: "No data found",
            disabled: true,
          },
        ];
      }
      return staffData?.staff?.map(staff => ({
        value: staff?.id ?? "",
        label: staff?.name ?? "",
      }));
    }, [staffData?.staff, staffFetching]);

    const onRemoveStaff = useCallback(
      removedValue => {
        const fieldValue = getValues("staffIds");
        setValue(
          "staffIds",
          fieldValue.filter(value => value !== removedValue),
        );
      },
      [getValues, setValue],
    );

    const setClinicLocationAddress = useCallback((locationAddress = {}) => {
      setValue("addressLine1", locationAddress?.addressLine1 ?? "");
      setValue("addressLine2", locationAddress?.addressLine2 ?? "");
      setValue("city", locationAddress?.city ?? "");
      setValue("state", locationAddress?.state ?? "");
      setValue("zipcode", locationAddress?.zipcode ?? "");
    }, []);

    const getTypedAddress = useCallback(() => {
      const formValues = getValues();
      return {
        shippingAddressLine1: formValues?.addressLine1 ?? "",
        shippingAddressLine2: formValues?.addressLine2 ? formValues?.addressLine2 : "-",
        shippingCity: formValues?.city,
        shippingState: formValues?.state,
        shippingZipcode: formValues?.zipcode,
      };
    }, []);

    const getValidatedAddress = useCallback(async () => {
      const formValues = getValues();

      const body = {
        isUSPS: false,
        address: {
          streetLines: {
            addressLine1: formValues?.addressLine1 ?? "",
            addressLine2: formValues?.addressLine2 ? formValues?.addressLine2 : "-",
          },
          city: formValues?.city,
          state: formValues?.state,
          postalCode: formValues?.zipcode,
        },
      };
      try {
        const fedexShipping = await validateShippingAddress(body, false).unwrap();
        setFedexShippingAddress({ ...fedexShipping, zipcode: fedexShipping.postalCode });
      } catch (e) {
        setCustomError(e.data?.error);
      }
    }, []);

    const closeValidateAddress = useCallback(() => {
      setFedexShippingAddress(null);
      setTimeout(() => {
        formRef.current?.requestSubmit?.();
      }, 350);
    }, []);

    const onClickValidate = useCallback(() => {
      setClinicLocationAddress(fedexShippingAddress);
      closeValidateAddress();
    }, [closeValidateAddress, fedexShippingAddress, setClinicLocationAddress]);

    const canNavigate = useCallback(
      progressBarIndex => {
        handleSubmit(data => submitAction(data, progressBarIndex))();
      },
      [handleSubmit, submitAction],
    );

    const checkValidation = useCallback(async () => {
      const formValidated = await trigger();
      return formValidated;
    }, []);

    const requestFormSubmit = useCallback(async () => {
      // on submitting button call validate and on closing modal submit form.
      const formValidated = await trigger();
      if (formValidated) {
        getValidatedAddress();
      }
    }, [getValidatedAddress, trigger]);

    useImperativeHandle(parentRef, () => ({
      submitForm: requestFormSubmit,
      canNavigate: canNavigate,
      checkFilledForm: checkValidation,
    }));

    return (
      <>
        <Box id={formId} ref={formRef} component={"form"} onSubmit={handleSubmit(data => submitAction(data, null))}>
          <Grid container columnSpacing={3} rowSpacing={1}>
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name="locationName"
                control={control}
                rules={{ required: "Clinic location name is required." }}
                MuiFieldProps={{ label: "Clinic Location name" }}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormOutlineField
                name="officeContactEmail"
                control={control}
                rules={{ required: "Email address is required." }}
                MuiFieldProps={{ label: "Office Email" }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name="officeContactPhone"
                control={control}
                rules={{
                  required: "Phone number is required.",
                  pattern: { value: /^(\d{10})?$/, message: "Invalid Phone" },
                }}
                MuiInputProps={{ format: "(###) ###-####" }}
                MuiFieldProps={{ label: "Office Phone", placeholder: "(123) 456-7890", isMasked: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name="officeContactFax"
                control={control}
                rules={{ pattern: { value: /^(\d{10})?$/, message: "Invalid Fax Number" } }}
                MuiInputProps={{ format: "(###) ###-####" }}
                MuiFieldProps={{ label: "Office Fax", placeholder: "(123) 456-7890", isMasked: true }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name="addressLine1"
                control={control}
                rules={{ required: "Address line 1 is required." }}
                MuiFieldProps={{ label: "Address Line 1", multiline: true, rows: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormOutlineField
                name="addressLine2"
                control={control}
                MuiFieldProps={{ label: "Address Line 2", multiline: true, rows: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name={"city"}
                control={control}
                rules={{ required: "City is required." }}
                MuiFieldProps={{ label: "City" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FromAutoComplete
                control={control}
                name={"state"}
                rules={{ required: "State is required." }}
                MuiFieldProps={{ label: "State", optionArray: USA_STATES }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormOutlineField
                name={"zipcode"}
                control={control}
                rules={{
                  required: "Zip Code is required.",
                  pattern: { value: /^\d{5}(-\d{4})?$/, message: "Invalid ZipCode" },
                }}
                MuiFieldProps={{ label: "Zip Code", placeholder: "12345-1234" }}
              />
            </Grid>
            {additionalInfo && (
              <Grid item xs={12} sm={6} md={4}>
                <FormSelectField
                  name={"verbalOrders"}
                  control={control}
                  MuiFieldProps={{ label: "Verbal Orders", optionArray: VERBAL_ORDERS }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4}>
              <FormSelectField
                name={"staffIds"}
                control={control}
                MuiFieldProps={{
                  label: "Staff",
                  multiple: true,
                  optionArray: staffArray,
                  onRemoveSelection: onRemoveStaff,
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <ValidAddressModal
          isUSPS={false}
          showValidAddressModal={showValidatedAddress}
          fedexShippingAddress={fedexShippingAddress}
          patientShippingAddress={getTypedAddress()}
          onClickNotValidate={closeValidateAddress}
          onClickValidate={onClickValidate}
          closeModal={closeValidateAddress}
        />
      </>
    );
  },
);

export default React.memo(ClinicLocationPracticeInfo);
