import React from "react";
import moment from "moment";

import { MedicationHistory, MedicationList } from "./components";
import useGanttChart from "./index.hook.js";
import "react-circular-progressbar/dist/styles.css";
import "./index.scss";

const GanttChart = (props) => {
  const { displayRefillModal } = props;
  const {
    groupedDates,
    datesList = [],
    collapsibleRow,
    rightPanelRef,
    containerWidth,
    hasMedications,
    medications,
    chartStartDate,
    scrollRightPanel,
    setCollapseRow,
  } = useGanttChart();

  return (
    <>
      <div className={`vpi-gantt-chart ${hasMedications ? "" : "is-empty"}`}>
        <div className="left-panel">
          <div className="header">
            <div className="medication-list-header">Prescription History</div>
          </div>
          <MedicationList
            medications={medications}
            chartStartDate={chartStartDate}
            updateCollapsibleRow={setCollapseRow}
            showRefillModal={displayRefillModal}
            scrollRightPanel={scrollRightPanel}
          />
        </div>
        <div className="right-panel" ref={rightPanelRef}>
          <div className="header">
            <div className="calendar-header">
              {groupedDates.map((cGMonth) => (
                <MonthHeader month={cGMonth} />
              ))}
            </div>
          </div>
          {hasMedications && (
            <MedicationHistory
              medications={medications}
              datesList={datesList}
              collapsibleRow={collapsibleRow}
              containerWidth={containerWidth}
            />
          )}
        </div>
      </div>
      {!hasMedications && <EmptyState />}
    </>
  );
};

export default GanttChart;

const EmptyState = () => {
  return <div className="empty-state">No Prescription History found</div>;
};

const MonthHeader = ({ month }) => {
  const { id = "", label = "", dates = [] } = month || {};
  return (
    <div className="month-header-container">
      <div className="month-title">{label}</div>
      <div className="month-dates-container" style={{ background: "#4674A5" }}>
        {dates.map((cDate) => (
          <MonthHeaderDateCell date={cDate} />
        ))}
      </div>
    </div>
  );
};

const MonthHeaderDateCell = ({ date = new Date().toISOString() }) => {
  const formattedDate = moment(date).format("DD");
  return <span className="date-cell">{formattedDate}</span>;
};
