import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Alert } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import axiosConfig, { errorResponse } from "../../../utils/axiosConfig";
import Cookies from "universal-cookie";
import Spinner from "../../../components/spinner";
import { USER_ROLES } from "utils";
import PaginationComponent from "../../../components/react-reactstrap-pagination";
class AddPatients extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      keyword: "",
      rowsArray: [],
      disableFilter: true,
      sizePerPage: 10,
      showOrderButton: false,
      showSpinner: true,
      error: "",
      data: [],
      tableHeaders: [
        { text: "id", dataField: "id", sort: true, hidden: true },
        { text: "First name", dataField: "patientName", sort: true },
        {
          text: "Patient info",
          dataField: "info",
          sort: true,
          formatter: cellContent => (
            <div>
              <div className="d-flex">
                <p className="smallText semiBold m-0">
                  DOB: <span className="text-muted normalText">{cellContent[0]}</span>
                </p>
                <p className="smallText semiBold m-0 px-3">
                  Gender: <span className="text-muted normalText">{cellContent[1]}</span>
                </p>
              </div>
              <div className="d-flex">
                <p className="smallText semiBold m-0">
                  Email: <span className="text-muted normalText">{cellContent[2]}</span>
                </p>
                <p className="smallText semiBold m-0 px-3">
                  Mobile Phone: <span className="text-muted normalText">{cellContent[3]}</span>
                </p>
              </div>
            </div>
          ),
        },
      ],
      selectedPatients: [],
    };
  }

  clearFilter() {
    this.getPatients();
  }

  handlePageChange = pageNo => {
    const { sizePerPage = 10, keyword = "" } = this.state;
    this.setState({ page: pageNo });

    this.getPatients(keyword, sizePerPage, pageNo);
  };

  async onPatientSelect(row, isSelect) {
    await this.setState({
      selectedPatients: isSelect
        ? [...this.state.selectedPatients, row]
        : this.state.selectedPatients.filter(x => x.id !== row.id),
    });

    if (
      !this.props.isBulkOrder &&
      this.state.selectedPatients.length > 1 &&
      this.props.selectedProducts > 1 &&
      !this.props.editPrescription
    ) {
      await this.setState({
        error: "This is a Single order with multiple products, You can not select more than one patient",
      });
    } else {
      await this.setState({ error: "" });
    }

    if (this.state.selectedPatients.length === 1) {
      await axiosConfig
        .post(
          "patients/getPatientAddressesByPatientId",
          {
            patientId: this.state.selectedPatients[0]?.id,
            userId: new Cookies().get("user_id"),
          },
          {
            headers: {
              Authorization: "Bearer " + new Cookies().get("user_token"),
            },
          },
        )
        .then(async response => {
          var addressesValues = [];
          var prefShipAddress = {
            addressLine1: response.data.shippingAddressLine1,
            addressLine2: response.data.shippingAddressLine2,
            city: response.data.shippingCity,
            state: response.data.shippingState,
            zipcode: response.data.shippingZipcode,
            _id: response.data.id,
            isDefault: false,
          };
          this.props.setShippingAddress(prefShipAddress, false);
        })
        .catch(() => {});
    }

    this.props.updateSelectedPatients(this.state.selectedPatients);
  }

  async onAllPatientSelect(isSelect, rows) {
    if (isSelect)
      await this.setState({
        rowsArray: [...this.state.rowsArray, ...rows],
      });
    else
      await this.setState({
        rowsArray: [],
      });

    await this.setState({
      selectedPatients: isSelect ? this.state.rowsArray : [],
    });

    this.props.updateSelectedPatients(this.state.selectedPatients);
  }

  getPatients(keyword = "", limit = 10, pageNo = 1) {
    this.setState({
      page: pageNo,
      sizePerPage: limit,
      keyword,
      showSpinner: true,
    });

    var body = {};
    let role = new Cookies().get("user_role");
    let isSuperUserSameAsProvider = new Cookies().get("isSuperUserSameAsProvider");
    let condition = role === USER_ROLES.CLINIC_SUPER_USER || isSuperUserSameAsProvider;;
    if (condition) {
      body = {
        clinicId: new Cookies().get("clinic_id"),
        userId: new Cookies().get("user_id"),
        keyword: this.state.keyword,
        limit: this.state.sizePerPage,
        currentPage: pageNo,
      };
    } else {
      body = {
        clinicLocationIds: new Cookies().get("clinic_location_ids"),
        userId: new Cookies().get("user_id"),
        keyword: this.state.keyword,
        limit: this.state.sizePerPage,
        currentPage: pageNo,
      };
    }

    if (!keyword) delete body.keyword;

    axiosConfig
      .post(
        keyword
          ? condition
            ? "patients/getPatientsInClinic"
            : "patients/getPatientsInClinicLocation"
          : condition
          ? "patients/getPatientsInClinic"
          : "patients/getPatientsInClinicLocation",
        body,
        {
          headers: {
            Authorization: "Bearer " + new Cookies().get("user_token"),
          },
        },
      )
      .then(async response => {
        var data = [];

        await response.data.patients.forEach(patient => {
          data.push({
            id: patient.id,
            firstName: patient.firstName,
            lastName: patient.lastName,
            physicianId: patient?.physicianId ?? "",
            clinicLocationId: patient?.clinicLocationId ?? "",
            patientName: patient.firstName + " " + patient.lastName,
            info: [patient.dateOfBirth, patient.sex, patient.email, patient.cellPhone],
          });
        });

        await this.setState({
          pagination: response.data.pagination,
          data: data,
          showSpinner: false,
          error: "",
        });
      })
      .catch(error => {
        this.setState({
          showSpinner: false,
          error: "An error occurred while trying to fetch clinic patients list." + error,
        });

        errorResponse(error);
      });
  }
  filterChanged(v = "") {
    this.setState({ disableFilter: v.length === 0 ? true : false });
  }
  clearSearch(keyword) {
    if (keyword === "") {
      this.filterChanged(keyword);
      this.getPatients();
    }
  }
  componentWillMount() {
    this.setState({ selectedPatients: this.props.defaultSelectedPatients ? this.props.defaultSelectedPatients : [] });
    this.getPatients();
  }

  render() {
    const pageOptions = { sizePerPage: 10, totalSize: this.state.data.length, custom: true };
    const defaultSorted = [{ dataField: "patientName", order: "asc" }];
    const { SearchBar } = Search;
    var selectedRows = [];

    if (this.state.selectedPatients.length) {
      this.state.selectedPatients.forEach(patient => {
        selectedRows.push(patient?.id);
      });
    }

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      onSelect: (e, selected) => {
        this.onPatientSelect(e, selected);
      },
      onSelectAll: (selected, rows) => {
        this.onAllPatientSelect(selected, rows);
      },
      selected: selectedRows,
    };

    return (
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={this.state.tableHeaders}
        data={this.state.data}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider keyField="id" columns={this.state.tableHeaders} data={this.state.data} search>
            {toolkitProps => (
              <React.Fragment>
                {this.state.showSpinner ? <Spinner /> : null}
                {this.state.error ? (
                  <Alert color="danger" className="text-center">
                    {this.state.error}
                  </Alert>
                ) : null}
                <Row className="justify-content-between px-3 my-3">
                  <Row>
                    <Col xs={6} sm={8} m={2} lg={3}>
                      <div className="tableSearch d-flex">
                        <i className="bx bx-search-alt search-icon flexJCAC" />
                        <input
                          className="borderless mx-2"
                          onChange={e => {
                            this.setState({
                              keyword: e.target.value,
                            });
                            this.filterChanged(e.target.value);
                            this.clearSearch(e.target.value);
                          }}
                          value={this.state.keyword}
                          placeholder="Search table"
                        />
                      </div>
                    </Col>
                    <Col xs={4} sm={3} m={9} lg={3}>
                      <span
                        style={{ cursor: "pointer" }}
                        disabled={this.state.disableFilter}
                        className="yellowButton w-60"
                        onClick={() => {
                          var key = this.state.keyword;
                          var limit = this.state.sizePerPage;
                          var pageNo = this.state.page;

                          this.getPatients(key, limit, pageNo);
                        }}>
                        Search
                      </span>
                    </Col>
                    {!this.state.disableFilter && (
                      <Col xs={12} sm={3} lg={2}>
                        <p
                          disabled={this.state.disableFilter}
                          className="blueText"
                          style={{
                            fontSize: 14,
                            cursor: "pointer",
                            paddingTop: 8,
                            marginLeft: 10,
                          }}
                          onClick={() => {
                            return this.clearFilter(), this.setState({ disableFilter: true });
                          }}>
                          Clear
                        </p>
                      </Col>
                    )}
                  </Row>
                </Row>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        selectRow={selectRow}
                        keyField={"id"}
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-3 px-3">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="text-md-right ms-auto">
                      <PaginationComponent
                        size="sm"
                        totalItems={this.state.sizePerPage * this.state.pagination?.totalPages || 0}
                        pageSize={this.state.sizePerPage}
                        onSelect={e => {
                          this.handlePageChange(e);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    );
  }
}

export default AddPatients;
