import { map } from "lodash";
import React from "react";
import { Row } from "reactstrap";

const RenderListingTabButtons = ({ selectedTab = "", buttonsList = [], buttonAction }) => {
  return (
    <Row className={`px-3 my-4 justify-content-center`}>
      {map(buttonsList, item => (
        <button
          key={`TAB-${item}`}
          className={`${selectedTab === item ? "blueButton" : "whiteButton"} m-2`}
          onClick={e => {
            e.preventDefault();
            buttonAction(item);
          }}>
          {item}
        </button>
      ))}
    </Row>
  );
};
export default React.memo(RenderListingTabButtons);
