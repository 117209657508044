//Dashboard screens
import Dashboard from "./screens/clinic/Dashboard/Dashboard";
import Messages from "./screens/clinic/Dashboard/messages";
import { InvoiceDetail } from "./screens/common/Invoices/InvoiceDetail";
import { InvoiceList } from "./screens/common/Invoices/InvoiceList";

//Prescription
import {
  ClinicPendingApprovalPrescription,
  ClinicPendingPrescription,
  ClinicPrescriptionHistory,
} from "./screens/clinic/Prescriptions";
import AddNewPrescription from "./screens/clinic/Prescriptions/new-prescription-journey";
import { EditPrescription } from "./screens/common";

// Product Search
import ProductSearch from "./screens/clinic/ProductSearch/product-search";

//Patients screens
import PatientProfile from "./screens/clinic/Patients/profile";

//Staff screens
import { ManageStaff } from "./screens/clinic/Staff";
import StaffProfile from "./screens/clinic/Staff/Profile";
import StaffList from "./screens/clinic/Staff/list";

//Clinic - Locations screens
import EditClinic from "./screens/clinic/Clinic/edit";
import ClinicLocationWrapper from "./screens/clinic/Locations/ClinicLocationWrapper";
import EditProfileScreen from "./screens/clinic/User/EditProfile";
import ProfileScreen from "./screens/clinic/User/Profile";
import { ClinicProfile } from "./screens/common/Clinic";

const Clinic = {
  Dashboard,
  Messages,
  InvoiceDetail,
  ClinicPrescriptionHistory,
  ClinicPendingApprovalPrescription,
  ClinicPendingPrescription,
  AddNewPrescription,
  EditPrescription,
  ProductSearch,
  PatientProfile,
  StaffList,
  ManageStaff,
  StaffProfile,
  ClinicProfile,
  EditClinic,
  ClinicLocationWrapper,
  ProfileScreen,
  EditProfileScreen,
  InvoiceList,
};

export default Clinic;
