import React from "react";
import useProductList from "./index.hook";
import AdminNavbar from "../../../../components/AdminNavbar";
import {
  Card,
  CardBody,
  Col,
  Alert,
  Container,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import PrimaryModal from "../../../../components/primaryModal";
import { useTable, useSortBy } from "react-table";
import { PRODUCT_STATUS } from "../constants";
import Switch from "react-switch";
import { AddProductModal } from "../index";
import AddPriceGroupModal from "../add_price_group";
import Spinner from "../../../../components/spinner";
import "../index.scss";
import { USER_ROLES, getPathPermission } from "utils";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import PaginationComponent from "react-reactstrap-pagination";

const ProductListing = props => {
  const {
    productList,
    success,
    setSuccess,
    deleteProductHandler,
    loadMore,
    deleteProduct,
    addPriceGroup,
    setAddPriceGroup,
    reload,
    setDeleteProduct,
    editProduct,
    setEditProduct,
    addProduct,
    setAddProduct,
    changeStatusHandler,
    setDataBack,
    searchString,
    setSearchString,
    onSearchHandler,
    showSpinner,
    sizePerPage,
    pagination,
    handlePageChange,
    ROWS_LIMIT,
    toggle,
    dropdownOpen,
    handleChange,
    rowLimit,
    priceGroups,
  } = useProductList(props);

  const userRole = useSelector(userRoleSelector);

  const RenderStatusToggle = ({ original }) => {
    var { id, isActive: status = true } = original || {};

    return userRole === USER_ROLES.ADMIN_SUPER_USER || USER_ROLES.PHARMACIST ? (
      <span className={"product-status-container"}>
        <label className="switch">
          <input
            type="checkbox"
            id={"rx-order-input-" + id}
            onChange={e => {
              e.stopPropagation();

              changeStatusHandler(id, e.target.checked);
            }}
            defaultChecked={status}
          />
          <span className="slide round"></span>
        </label>
      </span>
    ) : (
      <span className={"product-status-container"}>
        <label className="switch">
          <input type="checkbox" id={"rx-order-input-" + id} checked={status} />
          <span className="slide round"></span>
        </label>
      </span>
    );
  };

  const RenderActionButtons = ({ original }) => {
    return userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.PHARMACIST ? (
      <span className={"button-product-action-container"}>
        <i className="fas fa-edit medText" onClick={() => setEditProduct(original)} />
        {userRole === USER_ROLES.ADMIN_SUPER_USER &&
          <i className="fas fa-trash redText" onClick={() => setDeleteProduct(original)} />}
      </span>
    ) : (
      <span>Disabled</span>
    );
  };

  const columns = React.useMemo(() => {
    var headerList = [
      {
        Header: "Status",
        accessor: "isActive", // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return userRole !== USER_ROLES.RX_TECH ? (
            <RenderStatusToggle original={original} />
          ) : (
            <span>Disabled</span>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableSortBy: true,
        Cell: ({ row: { original } }) => <RenderActionButtons original={original} />,
      },

      {
        Header: "Category",
        accessor: "family",
      },
      {
        Header: "Sub Category 1",
        accessor: "subCategory1",
      },
      {
        Header: "Sub Category 2",
        accessor: "subCategory2",
      },
      {
        Header: "Common Name",
        accessor: "commonName",
      },
      {
        Header: "Product",
        accessor: "name",
      },
      {
        Header: "Retail price",
        accessor: "unitPrice",
      },
      {
        Header: "Dispense Type",
        accessor: "dispenseType",
      },
    ];

    productList[0]?.sigOptions?.forEach((sig, i) => {
      headerList.push(
        {
          Header: `Sig${i + 1} Title`,
          accessor: data => {
            if (data) {
              const { sigOptions } = data;
              if (sigOptions) {
                if (sigOptions[i]) {
                  return sigOptions[i]?.title?.toUpperCase() || "";
                }
              }
            }
            return "";
          },
        },

        {
          Header: `Sig${i + 1}`,
          accessor: data => {
            if (data) {
              const { sigOptions } = data;
              if (sigOptions) {
                if (sigOptions[i]) {
                  return sigOptions[i]?.description?.toUpperCase() || "";
                }
              } else return "";
            }
            return "";
          },
        },
      );
    });

    if (userRole === USER_ROLES.ADMIN_SUPER_USER) {
      priceGroups?.forEach((p, i) => {
        if (p) {
          headerList.push({
            Header: p,
            accessor: data => {
              if (data) {
                const { priceGroups } = data;
                return priceGroups?.filter(value1 => value1.groupName === p)[0]?.price;
              } else return "";
            },
          });
        }
      });
    }
    headerList.push(
      {
        Header: "Medical Accessories",
        accessor: "medicalAccessories",
      },

      {
        Header: "Cold Shipped",
        accessor: "coldShipped",
      },
    );
    headerList.push({
      Header: "Controlled Substance",
      accessor: "controlledSubstance",
    });

    return headerList;
  }, [productList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    { columns, data: productList },
    useSortBy,
  );

  return (
    <>
      <AdminNavbar />
      <div className="page-content">
        <h4 className="p-3 text-muted">Products</h4>
        {success ? (
          <Alert color="success" className="text-center" isOpen={success ? true : false} toggle={() => setSuccess("")}>
            {success}
          </Alert>
        ) : null}
        <Container fluid>
          <div className={"clinic-products-header"}>
            {showSpinner ? <Spinner /> : null}
            <button className={"btn"} onClick={() => props.history.goBack()}>
              Back
            </button>
            <div>
              {userRole === USER_ROLES.ADMIN_SUPER_USER ? (
                <button className={"pinkButton justify-content-end m-3"} onClick={() => setAddPriceGroup(true)}>
                  Add Price Group
                </button>
              ) : null}
              {userRole === USER_ROLES.ADMIN_SUPER_USER || userRole === USER_ROLES.PHARMACIST ? (
                <button className={"pinkButton justify-content-end m-3"} onClick={() => setAddProduct(true)}>
                  Add Products
                </button>
              ) : null}
            </div>
          </div>
          <Row>
            <Col xs="12">
              <Card>
                <Row className="justify-content-between px-3 my-3">
                  <div className="search-box m-3">
                    <div>
                      <input
                        type="text"
                        placeholder="Search table"
                        className="tableSearchInput"
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            onSearchHandler();
                          }
                        }}
                        onChange={e => {
                          searchString.current = e.target.value;
                          if (e.target.value === "") {
                            return onSearchHandler();
                          }
                        }}
                      />
                      <i className="bx bx-search-alt search-icon" />
                      <button type="button" onClick={onSearchHandler} className={"product-search-button"}>
                        <i
                          className="bx bx-search-alt search-icon"
                          style={{ position: "initial", lineHeight: "inherit" }}
                        />
                      </button>
                      {searchString.length ? (
                        <i
                          className="fas fa-times redText product-search-icon"
                          onClick={() => {
                            searchString.current = "";

                            return onSearchHandler();
                          }}></i>
                      ) : (
                        []
                      )}
                    </div>
                  </div>
                </Row>

                <CardBody>
                  {showSpinner && <Spinner />}
                  <div className="my-4 d-flex align-items-center flex-row ">
                    <p className="mx-3 mt-3">Rows: </p>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                      <DropdownToggle caret color="primary">
                        {rowLimit} <i className="fas fa-angle-down" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {ROWS_LIMIT.map(size => (
                          <DropdownItem key={size} onClick={handleChange}>
                            {size}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="table-responsive" style={{ maxHeight: "70vh" }}>
                    <table {...getTableProps()} className={""} style={{ overflowY: "scroll" }}>
                      <thead style={{ background: "white", position: "sticky", top: 0, zIndex: 100 }}>
                        {headerGroups.map(headerGroup => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                              <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                className={"product-table-header"}>
                                {column.render("Header")}
                                <span>
                                  &nbsp;
                                  {column.isSorted ? (
                                    <span className={column.isSortedDesc ? "dropdown" : "dropup"}>
                                      <span className="caret"></span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map(cell => {
                                const {
                                  row: { original },
                                } = cell;
                                const { status = PRODUCT_STATUS.active } = original || {};
                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    className={`product-table-body ${status === PRODUCT_STATUS.inactive ? "product-table-inactive" : ""
                                      }`}>
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  {!showSpinner && !productList.length && (
                    <span className={"empty-record-table"}>No record available.</span>
                  )}
                </CardBody>

                <Row className="align-items-md-center mt-3 px-3">
                  <div className="text-center ms-auto">
                    {pagination.hasNextPage ? (
                      <span
                        style={{ cursor: "pointer" }}
                        className="blueButton"
                        onClick={e => {
                          e.preventDefault();
                          loadMore();
                        }}>
                        Load more
                      </span>
                    ) : null}
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {!!deleteProduct && (
        <PrimaryModal
          redirection={false}
          question={`Are you sure you want to delete this  product?`}
          showModal={!!deleteProduct}
          closeModal={() => setDeleteProduct(null)}
          onClickYes={() => deleteProductHandler(deleteProduct?.id)}
        />
      )}
      {(addProduct || editProduct) && (
        <AddProductModal
          products={productList}
          showModal={addProduct || !!editProduct}
          setSuccess={setSuccess}
          product={editProduct}
          isEdit={!!editProduct}
          setData={setDataBack}
          closeModal={() => {
            setAddProduct(false);
            setEditProduct(null);
          }}
          priceGroups={priceGroups}
        />
      )}
      {addPriceGroup && (
        <AddPriceGroupModal
          showModal={addPriceGroup}
          setData={setDataBack}
          setSuccess={setSuccess}
          closeModal={() => {
            setAddPriceGroup(false);
          }}
        />
      )}
    </>
  );
};

export default ProductListing;
