import { createContext } from "react";

const ClinicLocationApiStateContext = createContext({
  clinicLocationData: null,
  clinicLocationId: "",
  filterClinicId: "",
});

const ClinicLocationApiActionContext = createContext({
  validateShippingAddress: () => null,
});

const ClinicLocationStateContext = createContext({
  mutateError: "",
  showSpinner: false,
  isClinicLocationEdit: false,
  clinicLocationInsertSuccessfully: false,
});
const ClinicLocationActionContext = createContext({
  uploadFile: fieldName => () => null,
});

const ClinicLocationFormSateContext = createContext({
  formRef: { current: [] },
  activeStep: 0,
  completedStep: { 0: true },
});

const ClinicLocationFormActionContext = createContext({
  goBack: () => null,
  goNext: () => null,
  formData: () => null,
  progressBarAction: () => null,
});

export {
  ClinicLocationActionContext,
  ClinicLocationApiStateContext,
  ClinicLocationStateContext,
  ClinicLocationFormSateContext,
  ClinicLocationFormActionContext,
  ClinicLocationApiActionContext,
};
