import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { Row, Col, Card, CardBody, Container, FormGroup, Label, Input, Alert } from "reactstrap";
import StaffTypeDD from "../../../components/dropdowns/staffTypeDD";
import StateDD from "../../../components/dropdowns/stateDD";
import SexDD from "../../../components/dropdowns/sexDD";
import Lottie from "../../../components/lottie";
import Spinner from "../../../components/spinner";
import moment from "moment";
import ProviderLocationDD from "components/dropdowns/providerLocationDD";
import NavBar from "components/NavBar";
import { useClinicStaff } from "../Hook";
import { SignaturePad } from "components/SignaturePad";
import {
  addUserToCometChat,
  getAdminList,
  getClinicStaffList,
  makeFriends,
  updateUserToCometChat,
} from "utils/CometChatFunctions";
import { userRoleSelector, userInfoSelector, jwtTokensSelector } from "../../../selectors";
import { useSelector } from "react-redux";
import { GoogleAuthToggle } from "components/GoogleAuthModal";
import Multiselect from "multiselect-react-dropdown";
import MultipleClinicLocationsDD from "components/dropdowns/MultipleClinicLocationsDD/MultipleClinicLocationsDD";
import CountryDD from "components/dropdowns/countryDD";
import { first } from "lodash";

const ManageStaff = props => {
  const userRole = useSelector(userRoleSelector);
  const userInfo = useSelector(userInfoSelector);

  const { id } = useParams();
  const history = useHistory();
  const profileImage = useRef(null);
  const [step, setStep] = useState(1);
  //const [complete, setComplete] = useState(false);
  const [canAccessOwnPatients, setCanAccessOwnPatients] = useState(false);
  const [autoOrderApproval, setAutoOrderApproval] = useState(false);
  const [profileImageSrc, setProfileImageSrc] = useState(null);
  const [profileImageURL, setProfileImageURL] = useState("");
  const [stateLicenseUrl, setStateLicenseUrl] = useState("");
  const [deaLicenseUrl, setDeaLicenseUrl] = useState([]);
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [portalAccess, setPortalAccess] = useState(true);
  const [HIPAACompliance, setHIPAACompliance] = useState(false);
  const [imageError, setImageError] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState("");
  const [clinicLocationId, setClinicLocationId] = useState("");
  const [role, setRole] = useState("");
  const [isSuperUserSameAsProvider, setIsSuperUserSameAsProvider] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [dob, setDob] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [staffList, setStaffList] = useState([]);
  const [selectedClinicLocations, setSelectedClinicLocations] = useState([]);
  const [selectedClinicLocationsName, setSelectedClinicLocationsName] = useState([]);
  const clinicSide = userRole?.toLowerCase().includes("clinic");
  const [deaInfo, setDeaInfo] = useState([]);
  const [deaLicenseAddress, setDeaLicenseAddress] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipcode: "",
  });
  const {
    updateProfile,
    base64toFile,
    uploadFile: uploadFileHelper,
    fetchStaff,
    editStaffMutate,
    addStaffMutate,
    user: staff,
    complete,
    errorMessage,
    showError,
    showLoading,
    isStaffFetching,
    clinicId,
  } = useClinicStaff(props);

  //Defining APIs
  // console.log("selected clinic", selectedClinicLocations.length);
  // console.log("selected clinic", selectedClinicLocations);
  // console.log("selected clinic Name", selectedClinicLocationsName);

  const editStaff = window?.location?.pathname.includes("edit-staff");
  useEffect(async () => {
    let adminList1 = await getAdminList();
    let staffList1 = await getClinicStaffList(clinicId);
    setAdminList(adminList1);
    setStaffList(staffList1);
  }, []);
  useEffect(async () => {
    if (staff?.clinicId) {
      let licenseUrls = [];
      staff?.deaInfo?.forEach(a => {
        if (a?.deaLicenseUrl) {
          licenseUrls.push({
            url: a.deaLicenseUrl,
            clinicLocationId: a.clinicLocationId,
          });
        }
      });
      setDeaInfo(staff?.deaInfo);
      setStateLicenseUrl(staff?.stateLicenseUrl);
      setIsSuperUserSameAsProvider(staff?.isSuperUserSameAsProvider);
      setDeaLicenseUrl(licenseUrls);
      setSignatureImageUrl(staff?.signatureImageUrl);
      //setClinicLocationId(staff.clinicLocationId);
      if (staff?.clinicLocationId) {
        setSelectedClinicLocations([staff.clinicLocationId]);
      } else if (staff?.clinicLocationIds) {
        setSelectedClinicLocations(staff.clinicLocationIds);
      }

      setRole(staff?.role);
      setProfileImageURL(staff?.profileImageUrl);
      setDob(staff?.dateOfBirth ? moment(staff?.dateOfBirth).format("YYYY-MM-DD") : "");
    }
  }, [staff]);

  const uploadFile = async (e, name = "", sig = "") => {
    let url = await uploadFileHelper(e, sig);

    if (name?.name === "dea") {
      const existingObjectIndex = deaLicenseUrl.findIndex(a => a.clinicLocationId === name.clinicLocationId);

      if (existingObjectIndex !== -1) {
        // Object with the same clinicLocationId already exists, update it
        const updatedDeaLicenseUrl = [...deaLicenseUrl];
        updatedDeaLicenseUrl[existingObjectIndex] = { url: url, clinicLocationId: name.clinicLocationId };
        setDeaLicenseUrl(updatedDeaLicenseUrl);
      } else {
        // Object with clinicLocationId doesn't exist, append a new one
        setDeaLicenseUrl([...deaLicenseUrl, { url: url, clinicLocationId: name.clinicLocationId }]);
      }
    } else if (name === "signatureImageUrl") {
      setSignatureImageUrl(url);
    } else if (name === "state") {
      setStateLicenseUrl(url);
    } else {
      setProfileImageURL(url);
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedClinicLocations(selectedList.map(a => a.id));
    setSelectedClinicLocationsName(selectedList);
  };
  const onRemove = (selectedList, selectedItem) => {
    setSelectedClinicLocations(selectedList.map(a => a.id));
    setSelectedClinicLocationsName(selectedList);
    console.log("On Remove: ", selectedList);
    console.log(
      "Current DEA Info: ",
      deaInfo?.length > 0
        ? first(deaInfo?.filter(a => selectedList.map(m => m.id).includes(a.clinicLocationId)))?.dea
        : "",
    );
  };

  const updateClinicLocation = async id => {
    setClinicLocationId(id);
  };
  async function handleValidSubmit(e) {
    e.preventDefault();
    var serialize = require("form-serialize");
    var form = document.getElementById("newStaffForm");
    var obj = serialize(form, { hash: true });

    if (step === 1) {
      if (
        !signatureImageUrl &&
        (role === "ClinicProvider" ||
          staff?.role === "ClinicProvider" ||
          isSuperUserSameAsProvider ||
          staff?.isSuperUserSameAsProvider) &&
        clinicSide
      ) {
        setError("Prescriber Signature required. ");
      } else {
        setError("");
        setStep(2);
      }
    } else if (step == 2) {
      setStep(3);
    } else {
      let deaValueInfo = [];
      console.log("exp: ", obj);
      selectedClinicLocations?.forEach((a, index) => {
        deaValueInfo.push({
          dea: obj["dea_no_" + a],
          deaLicenseUrl: first(deaLicenseUrl.filter(dea => dea.clinicLocationId === a))?.url,
          providerDeaLicenseExpirationDate: obj["providerDeaLicenseExpirationDate_" + a]
            ? moment(obj["providerDeaLicenseExpirationDate_" + a]).format("MM/DD/YYYY")
            : "",
          clinicLocationId: a,
          clinicLocationName: first(selectedClinicLocationsName?.filter(location => a === location.id))?.name,
          deaAddressInfo: {
            addressLine1: obj["dea_address_line_1_" + a],
            addressLine2: obj["dea_address_line_2_" + a],
            city: obj["dea_city_" + a],
            state: obj["dea_state_" + a],
            zipcode: obj["dea_zipcode_" + a],
          },
        });
      });
      let body = "";
      let staffInfo = {
        deaInfo: deaValueInfo,
        firstName: obj.first_name,
        middleName: obj.middle_name,
        lastName: obj.last_name,
        role: isSuperUserSameAsProvider ? staff?.role : obj.staff_type,
        sex: obj.staff_sex,
        dateOfBirth: obj.date_of_birth,
        clinicLocationIds: selectedClinicLocations,
        npi: obj.npi,
        dea: obj.dea,
        shouldAccessOnlyOwnPatients: canAccessOwnPatients,
        medicalLicenseStatus: obj.license_status?.includes("@") ? "-" : obj.license_status,
        medicalLicenseNumber: obj.medical_license?.includes("@") ? "-" : obj.medical_license,
        stateLicenseUrl: role === "ClinicProvider" || isSuperUserSameAsProvider ? stateLicenseUrl : "",
        email: obj.email,
        mobileNumber: obj.mobile_number?.replace(/\s+/g, ""),
        phoneNumber: obj.contact_number?.replace(/\s+/g, ""),
        addressLine1: obj.address_line_1,
        addressLine2: obj.address_line_2,
        state: obj.state,
        city: obj.city,
        zipcode: obj.zipcode,
        country: obj.country,
        isToAutoApproveOrder: autoOrderApproval,
        isHippaCompliance: HIPAACompliance,
        isToGivePortalAccess: portalAccess,
        profileImageUrl: profileImageURL,
        password: obj.portal_password,
        signatureImageUrl: signatureImageUrl,
        providerCredentials: obj.providerCredentials,
        providerStateLicenseExpirationDate: obj.providerStateLicenseExpirationDate
          ? moment(obj.providerStateLicenseExpirationDate).format("MM/DD/YYYY")
          : "",
        providerLicensingState: obj.providerLicensingState,
        verbalOrders: obj.verbalOrders,
      };
      if (editStaff) {
        staffInfo = { id, ...staffInfo };
        body = {
          clinicId: clinicId,
          clinicLocationIds: selectedClinicLocations,
          userId: userInfo.id,
          staffId: id,
          staff: staffInfo,
        };
        let res = await editStaffMutate({ params: staff?.id, payload: body });
        if (res?.data) {
          await updateUserToCometChat(obj.first_name + " " + obj.last_name, id, res?.data?.clinicName, role);
        }
      } else {
        body = {
          clinicId: clinicId,
          clinicLocationIds: selectedClinicLocations,
          userId: userInfo.id,
          staff: staffInfo,
        };
        let res = await addStaffMutate({ payload: body });
        if (res?.data) {
          await addToCometChat(res?.data?.id, obj.first_name + " " + obj.last_name, res?.data?.clinicName, role);
        }
      }
    }
  }

  async function addToCometChat(id, name, clinicName, role1) {
    await addUserToCometChat(name, id, clinicName, role1);
    let adminArrayList = adminList;
    let staffArrayList = staffList;
    if (adminArrayList !== "Error" && staffArrayList != "Error") {
      staffArrayList.push(...adminArrayList);
      var friendsList = staffArrayList;
      var j = 0;
      var chunksListDivisor = parseInt(friendsList.length / 15);
      var withoutRemainderVal = chunksListDivisor * 15;
      var remainder = friendsList.length % 15;
      var k = 0;
      if (friendsList.length % 15 == 0) {
        for (i = 0; i < chunksListDivisor; i++) {
          var chunkFriendsArray = [];
          //Push 15 Length of Array
          for (j = 0; j < 15; j++) {
            chunkFriendsArray.push(friendsList[k]);
            k++;
          }
          await makeFriends(id, chunkFriendsArray);
        }
      } else {
        for (i = 0; i < chunksListDivisor; i++) {
          var chunkFriendsArray = [];
          //Push 15 Length of Array
          for (j = 0; j < 15; j++) {
            if (k < withoutRemainderVal) {
              chunkFriendsArray.push(friendsList[k]);
            }
            k++;
          }
          await makeFriends(id, chunkFriendsArray);
        }
        var chunkFriendsArray1 = [];
        for (var i = 0; i < remainder; i++) {
          chunkFriendsArray1.push(friendsList[k]);
          k++;
        }
        await makeFriends(id, chunkFriendsArray1);
      }
    }
  }

  const onClickSave = (person = "signatureImageUrl", sign = "") => {
    if (sign) {
      uploadFile("", person, sign);
    }
  };

  const closeSignatureModal = () => {
    setSignatureModal(false);
  };

  useEffect(() => {
    if (!isStaffFetching && editStaff) fetchStaff({ staffId: id });
  }, [id, fetchStaff]);

  const titleMessage = useMemo(() => {
    return editStaff ? "Edit Clinic Staff" : "Add Clinic Staff";
  }, []);

  const navigationSteps = useMemo(() => {
    return (
      <div className="w-100 text-center my-4">
        <div className="flexJCAC w-100" style={{ margin: "60px !important" }}>
          <div className="rounded-pill" style={{ width: "200px" }}>
            <div className="activeProgressNumberCircle">
              <p>1</p>
            </div>
            <p className="m-0">Personal</p>
            <p>Information</p>
          </div>
          <div className="rounded-pill" style={{ width: "200px" }}>
            <div className={step >= 2 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
              <p>2</p>
            </div>
            <p className="m-0">Contact</p>
            <p>Information</p>
          </div>
          <div className="rounded-pill" style={{ width: "200px" }}>
            <div className={step >= 3 ? `activeProgressNumberCircle` : `inactiveProgressNumberCircle`}>
              <p>3</p>
            </div>
            <p className="m-0">Portal</p>
            <p>Information</p>
          </div>
        </div>
      </div>
    );
  }, [step]);

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>{titleMessage} | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">{titleMessage}</h4>
          <Row>
            <Col xs="12">
              {showLoading ? <Spinner /> : null}
              {showError ? (
                <Alert color="danger" className="text-center">
                  {errorMessage}
                </Alert>
              ) : null}
              <Card>
                <CardBody>
                  {complete ? (
                    <div className="m-sm-5 p-sm-5 p-0 m-0 text-center">
                      <Lottie name="success-check" />
                      <p className="mb-5">Staff information has been {editStaff ? "updated" : "added"}!</p>

                      {
                        <span onClick={() => props.history.goBack()} className="pinkButton">
                          Clinic Staff
                        </span>
                      }
                    </div>
                  ) : (
                    <>
                      {navigationSteps}

                      <CardBody className="pt-0">
                        <div className="p-2">
                          <form
                            id="newStaffForm"
                            className="stepsForm form-horizontal px-sm-4 p-0"
                            onSubmit={e => handleValidSubmit(e)}>
                            {showSpinner ? <Spinner /> : null}
                            {error ? (
                              <Alert color="danger" className="text-center">
                                {error}
                              </Alert>
                            ) : null}
                            <div className="mb-5 px-lg-5 px-sm-5 p-0">
                              <div className={`${step === 1 ? "d-block" : "d-none"}`}>
                                <Row>
                                  <div className="mb-4 flexJCAC flex-column">
                                    {profileImageURL === null ? (
                                      <img className="staffProfileImgCircle" src={null} alt="" />
                                    ) : (
                                      <img className="staffProfileImgCircle" src={profileImageURL} alt="" />
                                    )}
                                    {profileImageURL && (
                                      <i
                                        className="fas fa-trash redText"
                                        onClick={() => {
                                          setProfileImageURL("");
                                        }}></i>
                                    )}
                                    <button
                                      className="pinkButton my-4"
                                      onClick={e => {
                                        e.preventDefault();
                                        document.getElementById("profilePhoto").click();
                                      }}>
                                      Upload Image
                                    </button>
                                    <Input
                                      accept=".jpg, .png, .jpeg"
                                      onChange={e => {
                                        uploadFile(e);
                                      }}
                                      name="profile_photo"
                                      className="form-control d-none"
                                      type="file"
                                      id="profilePhoto"
                                    />
                                  </div>
                                </Row>
                                {isSuperUserSameAsProvider ? (
                                  <Row>
                                    <Col md={4} className="mb-4">
                                      <strong>{"Prescriber is Super User"}</strong>
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <Input name="staff_type" value={staff?.role} disabled={true} />
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col md={4} className="mb-4">
                                      <StaffTypeDD
                                        name="staff_type"
                                        selectedType={setRole}
                                        required={step === 1 ? true : false}
                                        defaultValue={staff?.role}
                                      />
                                    </Col>
                                  </Row>
                                )}
                                {role === "ClinicProvider" || isSuperUserSameAsProvider ? (
                                  <Row>
                                    <Col md={12} className="mb-1">
                                      <FormGroup>
                                        <Label className="form-label"> Prescriber Name</Label>{" "}
                                      </FormGroup>
                                    </Col>{" "}
                                    <hr></hr>
                                  </Row>
                                ) : (
                                  <Row>
                                    <Col md={12} className="mb-1">
                                      <FormGroup>
                                        <Label className="form-label">
                                          Clinic Representative for all Customer Care Needs
                                        </Label>{" "}
                                      </FormGroup>
                                    </Col>{" "}
                                    <hr></hr>
                                  </Row>
                                )}
                                <Row>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="first_name" className="form-label">
                                        First name <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        name="first_name"
                                        className="form-control"
                                        placeholder=""
                                        type="text"
                                        required={step === 1 ? true : false}
                                        defaultValue={staff?.firstName}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="middle_name" className="form-label">
                                        Middle name
                                      </Label>
                                      <Input
                                        name="middle_name"
                                        className="form-control"
                                        placeholder=""
                                        type="text"
                                        defaultValue={staff?.middleName}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="last_name" className="form-label">
                                        Last name <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        name="last_name"
                                        className="form-control"
                                        placeholder=""
                                        type="text"
                                        required={step === 1 ? true : false}
                                        defaultValue={staff?.lastName}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={4} className="mb-4">
                                    <SexDD
                                      name="staff_sex"
                                      required={step === 1 ? true : false}
                                      defaultValue={staff?.sex}
                                    />
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="date_of_birth" className="form-label">
                                        Date of Birth <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        name="date_of_birth"
                                        className="form-control"
                                        type="date"
                                        required={step === 1 ? true : false}
                                        defaultValue={dob ? moment(dob)?.format("YYYY-MM-DD") : ""}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <p className="semiBold medText my-3">Additional Information</p>
                                {role === "ClinicProvider" || isSuperUserSameAsProvider ? (
                                  <Row>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <StateDD
                                          defaultValue={staff?.providerLicensingState}
                                          name="providerLicensingState"
                                          className="form-control"
                                          required={step === 1 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="providerStateLicenseExpirationDate" className="form-label">
                                          State License Expiration Date<span className="requiredStar">*</span>
                                        </Label>
                                        <Input
                                          name="providerStateLicenseExpirationDate"
                                          defaultValue={moment(staff?.providerStateLicenseExpirationDate).format(
                                            "YYYY-MM-DD",
                                          )}
                                          className="form-control"
                                          type="date"
                                          placeholder=""
                                          required={step === 1 ? true : false}
                                        />
                                      </FormGroup>
                                    </Col>{" "}
                                    <Col md={4} className="mb-4">
                                      <FormGroup>
                                        <Label htmlFor="medical_license" className="form-label">
                                          Prescriber State License #{" "}
                                          {role === "ClinicProvider" ||
                                          staff?.role === "ClinicProvider" ||
                                          isSuperUserSameAsProvider ? (
                                            <span className="requiredStar">*</span>
                                          ) : null}
                                        </Label>
                                        <Input
                                          name="medical_license"
                                          className="form-control"
                                          type="text"
                                          placeholder=""
                                          defaultValue={
                                            staff?.medicalLicenseNumber?.includes("@")
                                              ? "-"
                                              : staff?.medicalLicenseNumber
                                          }
                                          required={
                                            step === 1
                                              ? role === "ClinicProvider" ||
                                                staff?.role === "ClinicProvider" ||
                                                isSuperUserSameAsProvider
                                                ? true
                                                : false
                                              : false
                                          }
                                        />
                                      </FormGroup>{" "}
                                    </Col>
                                    {/* <Col md={4} className="mb-4">
                                      <div className="mb-4">
                                        <Label htmlFor="verbalOrders" className="form-label">
                                          Verbal Orders <span className="requiredStar">*</span>
                                        </Label>{" "}
                                        <p className="dropdownArrow">
                                          <i className="fas fa-angle-down"></i>
                                        </p>
                                        <Input
                                          name="verbalOrders"
                                          type="select"
                                          required={step === 1 ? true : false}
                                          defaultValue={staff?.verbalOrders}
                                        >
                                          <option value="Prescribing doctor(s) ONLY">Prescribing doctor(s) ONLY</option>
                                          <option value="Any clinic medical staff">Any clinic medical staff</option>
                                          <option value="NO verbal orders can be placed outside of prescribing doctor(s)">
                                            NO verbal orders can be placed outside of prescribing doctor(s)
                                          </option>
                                        </Input>
                                      </div>
                                    </Col> */}
                                  </Row>
                                ) : null}

                                <Row>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <MultipleClinicLocationsDD
                                        onSelect={onSelect}
                                        onRemove={onRemove}
                                        name="clinic_locations"
                                        clinicId={clinicId}
                                        defaultValues={selectedClinicLocations}
                                        required={step === 1 ? true : false}
                                        setSelectedClinicLocationsName={setSelectedClinicLocationsName}
                                      />
                                    </FormGroup>
                                  </Col>

                                  {role === "ClinicProvider" || isSuperUserSameAsProvider ? (
                                    <>
                                      {" "}
                                      <Col md={4} className="mb-4">
                                        <FormGroup>
                                          <Label htmlFor="npi" className="form-label">
                                            Prescriber NPI #
                                            {role === "ClinicProvider" ||
                                            staff?.role === "ClinicProvider" ||
                                            isSuperUserSameAsProvider ? (
                                              <span className="requiredStar">*</span>
                                            ) : null}
                                          </Label>
                                          <Input
                                            name="npi"
                                            className="form-control"
                                            type="text"
                                            placeholder=""
                                            defaultValue={staff?.npi}
                                            required={
                                              step === 1
                                                ? role === "ClinicProvider" ||
                                                  staff?.role === "ClinicProvider" ||
                                                  isSuperUserSameAsProvider
                                                  ? true
                                                  : false
                                                : false
                                            }
                                          />
                                        </FormGroup>
                                      </Col>
                                    </>
                                  ) : null}
                                  {role === "ClinicSupport" ? null : (
                                    <Col md={4} className="mb-4">
                                      <div className="mt-4">
                                        <input
                                          onChange={e => {
                                            setCanAccessOwnPatients(e.target.checked);
                                          }}
                                          name="staff_access"
                                          className="form-check-input"
                                          type="checkbox"
                                          id="staffAccessCheck"
                                          defaultChecked={staff?.canAccessOwnPatients}
                                        />
                                        <label className="form-check-label mx-2" htmlFor="staffAccessCheck">
                                          Can Access Own Patients Only
                                        </label>
                                      </div>
                                    </Col>
                                  )}
                                </Row>
                                <Row>
                                  {role === "ClinicProvider" || isSuperUserSameAsProvider
                                    ? selectedClinicLocations.map((location, index) => (
                                        <>
                                          <Row key={"col-basic-dea" + location}>
                                            <p style={{ fontWeight: 500 }}>
                                              Clinic Location :{" "}
                                              {
                                                first(
                                                  selectedClinicLocationsName?.filter(
                                                    locationName => location === locationName.id,
                                                  ),
                                                )?.name
                                              }
                                            </p>
                                          </Row>
                                          <Col md={4} className="mb-4" key={"col-dea-" + location + index}>
                                            <FormGroup>
                                              <Label htmlFor="dea" className="form-label">
                                                Prescriber DEA #
                                              </Label>
                                              <Input
                                                name={`dea_no_${location}`}
                                                className="form-control"
                                                type="text"
                                                placeholder=""
                                                defaultValue={
                                                  deaInfo?.length > 0
                                                    ? first(deaInfo?.filter(a => a.clinicLocationId === location))?.dea
                                                    : ""
                                                }
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col
                                            md={4}
                                            className="mb-4"
                                            key={"col-providerDeaLicenseExpirationDate_" + location + index}>
                                            <FormGroup>
                                              <Label htmlFor="providerDeaLicenseExpirationDate" className="form-label">
                                                DEA License Expiration Date
                                              </Label>
                                              <Input
                                                name={`providerDeaLicenseExpirationDate_${location}`}
                                                className="form-control"
                                                type="date"
                                                placeholder=""
                                                defaultValue={
                                                  deaInfo?.length > 0
                                                    ? moment(
                                                        first(deaInfo?.filter(a => a.clinicLocationId === location))
                                                          ?.providerDeaLicenseExpirationDate,
                                                      ).format("YYYY-MM-DD")
                                                    : ""
                                                }
                                              />
                                            </FormGroup>{" "}
                                          </Col>
                                          <Col md={4} className="mb-4" key={`col-dea_license_${location}`}>
                                            <Label htmlFor={`dea_license_${location}`} className="form-label">
                                              Copy of DEA license
                                            </Label>
                                            <Input
                                              name={`dea_license_${location}`}
                                              onChange={e => {
                                                uploadFile(e, { clinicLocationId: location, name: "dea" });
                                              }}
                                              className="form-control"
                                              type="file"
                                              id={`dea_license_${location}`}
                                            />
                                            <p className="redText">Only image or pdf files are allowed</p>{" "}
                                            <div>
                                              {deaLicenseUrl?.length > 0 ? (
                                                <a
                                                  href={
                                                    deaInfo?.length > 0
                                                      ? first(
                                                          deaLicenseUrl?.filter(a => a.clinicLocationId === location),
                                                        )?.url
                                                      : ""
                                                  }
                                                  style={{ width: "100px", height: "100px", border: "none" }}>
                                                  View Dea License
                                                </a>
                                              ) : null}
                                            </div>
                                          </Col>
                                          <hr></hr>
                                        </>
                                      ))
                                    : null}
                                </Row>
                                {(role === "ClinicProvider" ||
                                  staff?.role === "ClinicProvider" ||
                                  isSuperUserSameAsProvider) && (
                                  <>
                                    <Row>
                                      <Col md={4} className="mb-4">
                                        <FormGroup>
                                          <Label htmlFor="providerCredentials" className="form-label">
                                            Prescriber Credentials <span className="requiredStar">*</span>
                                          </Label>{" "}
                                          <p className="dropdownArrow">
                                            <i className="fas fa-angle-down"></i>
                                          </p>
                                          <Input
                                            type="select"
                                            defaultValue={staff?.providerCredentials}
                                            name="providerCredentials"
                                            className="form-control"
                                            placeholder="Last name"
                                            required={step === 1 ? true : false}>
                                            <option value="MD">MD</option>
                                            <option value="DO">DO</option>
                                            <option value="DDS">DDS</option>
                                            <option value="DVM">DVM</option>
                                            <option value="NP">NP</option>
                                            <option value="PA">PA</option>
                                            <option value="PA-C">PA-C</option>
                                            <option value="ARPN">ARPN</option>
                                            <option value="DPM">DPM</option>
                                            <option value="Other">Other</option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={4}>
                                        <Label htmlFor="stateLicense" className="form-label">
                                          Copy of State license <span className="requiredStar">*</span>
                                        </Label>

                                        <Input
                                          name="stateLicense"
                                          onChange={e => {
                                            uploadFile(e, "state");
                                          }}
                                          className="form-control"
                                          type="file"
                                          accept="image/*, .pdf"
                                          id="stateLicense"
                                          required={step === 1 && !stateLicenseUrl ? true : false}
                                        />
                                        <p className="redText">Only image or pdf files are allowed</p>
                                        <div>
                                          {stateLicenseUrl ? (
                                            <a
                                              href={stateLicenseUrl}
                                              style={{ width: "100px", height: "100px", border: "none" }}>
                                              View State License
                                            </a>
                                          ) : null}
                                        </div>
                                      </Col>

                                      {/* <Col md={4}>
                                        <Label htmlFor="deaLicense" className="form-label">
                                          Copy of DEA license
                                        </Label>
                                        <Input
                                          name="deaLicense"
                                          onChange={(e) => {
                                            uploadFile(e, "dea");
                                          }}
                                          className="form-control"
                                          type="file"
                                          id="deaLicense"
                                        />
                                        <p className="redText">Only image or pdf files are allowed</p>{" "}
                                        <div>
                                          {deaLicenseUrl ? (
                                            <a
                                              href={deaLicenseUrl}
                                              style={{ width: "100px", height: "100px", border: "none" }}
                                            >
                                              View Dea License
                                            </a>
                                          ) : null}
                                        </div>
                                      </Col> */}
                                    </Row>
                                    <Row></Row>
                                    <br></br>
                                    <Row>
                                      <div>
                                        {signatureImageUrl ? (
                                          <img
                                            style={{ width: "150px", height: "auto" }}
                                            className="signatureImageUrl"
                                            state
                                            src={signatureImageUrl}
                                            alt=""
                                          />
                                        ) : null}
                                      </div>
                                      <div className="mb-4">
                                        <Label htmlFor="signatureImageUrl" className="form-label">
                                          Prescriber's Signature{" "}
                                          {clinicSide ? <span className="requiredStar">*</span> : null}{" "}
                                        </Label>
                                        <br></br>
                                        <small>
                                          <i>(Provide signature in jpeg/png format or use signature pad.)</i>
                                        </small>{" "}
                                        <br></br>
                                        <Row>
                                          <Col md={3}>
                                            {" "}
                                            <Input
                                              name="signature"
                                              onChange={e => {
                                                uploadFile(e, "signatureImageUrl");
                                              }}
                                              className="form-control"
                                              type="file"
                                              id="signatureImageUrl"
                                              accept=".gif,.jpg,.jpeg,.png"
                                            />
                                          </Col>
                                          <Col md={3}>
                                            <span
                                              className="pointer form-control"
                                              onClick={() => setSignatureModal(true)}>
                                              <i class="fas fa-pen mx-2"></i>Signature Pad
                                            </span>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Row>
                                  </>
                                )}
                              </div>
                              <div className={`mt-3 ${step === 2 ? "d-block" : "d-none"}`}>
                                <Row>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="email" className="form-label">
                                        {role === "ClinicProvider" || isSuperUserSameAsProvider
                                          ? "Prescriber Email"
                                          : "Email"}{" "}
                                        <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        name="email"
                                        className="form-control"
                                        placeholder=""
                                        type="email"
                                        required={step === 2 ? true : false}
                                        defaultValue={staff?.email}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="contact_number" className="form-label">
                                        {role === "ClinicProvider" || isSuperUserSameAsProvider
                                          ? "Prescriber Phone"
                                          : "Mobile Number"}{" "}
                                        <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        type="tel"
                                        placeholder="Format: 1234567890"
                                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                        name="contact_number"
                                        className="form-control"
                                        defaultValue={staff?.phoneNumber?.replace(/\s+/g, "")}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="mobile_number" className="form-label">
                                        Other Phone
                                      </Label>
                                      <Input
                                        name="mobile_number"
                                        type="tel"
                                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                                        placeholder="Format: 1234567890"
                                        className="form-control"
                                        defaultValue={staff?.mobileNumber?.replace(/\s+/g, "")}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-4">
                                      <Label htmlFor="address_line_1" className="form-label">
                                        Address line 1 <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        maxLength={50}
                                        name="address_line_1"
                                        className="form-control"
                                        type="textarea"
                                        required={step === 2 ? true : false}
                                        defaultValue={staff?.addressLine1}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={6}>
                                    <div className="mb-4">
                                      <Label htmlFor="address_line_2" className="form-label">
                                        Address line 2
                                      </Label>
                                      <Input
                                        maxLength={50}
                                        name="address_line_2"
                                        className="form-control"
                                        type="textarea"
                                        defaultValue={staff?.addressLine2}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={3}>
                                    <div className="mb-4">
                                      <CountryDD
                                        name={"country"}
                                        defaultValue={staff?.country ? staff?.country : "United States"}
                                        required={step === 2 ? true : false}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="mb-4">
                                      <Label htmlFor="city" className="form-label">
                                        City <span className="requiredStar">*</span>
                                      </Label>
                                      <Input
                                        name="city"
                                        className="form-control"
                                        type="text"
                                        required={step === 2 ? true : false}
                                        defaultValue={staff?.city}
                                      />
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <StateDD name="state" required={step === 2} defaultValue={staff?.state} />
                                  </Col>
                                  <Col md={3}>
                                    <div className="mb-4">
                                      <Label htmlFor="zipcode" className="form-label">
                                        Zip code <span className="requiredStar">*</span>
                                      </Label>

                                      <Input
                                        name="zipcode"
                                        className="form-control"
                                        required={step === 2 ? true : false}
                                        defaultValue={staff?.zipcode}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                {role === "ClinicProvider" || isSuperUserSameAsProvider ? (
                                  <>
                                    <Row>
                                      <h4>DEA Address</h4>
                                    </Row>
                                    {selectedClinicLocations.map((clinicLocation, index) => (
                                      <>
                                        <Row key={"address-dea-heading-" + clinicLocation}>
                                          <p style={{ fontWeight: 500 }}>
                                            Clinic Location :{" "}
                                            {
                                              first(
                                                selectedClinicLocationsName?.filter(
                                                  locationName => clinicLocation === locationName.id,
                                                ),
                                              )?.name
                                            }
                                          </p>
                                        </Row>
                                        <Row key={`row-key_dea_address${clinicLocation}`}>
                                          <Col md={6} key={`col-key_dea_address_1${clinicLocation}`}>
                                            <div className="mb-4">
                                              <Label
                                                htmlFor={`dea_address_line_1_${clinicLocation}`}
                                                className="form-label">
                                                Address line 1{/* <span className="requiredStar">*</span> */}
                                              </Label>
                                              <Input
                                                maxLength={50}
                                                name={`dea_address_line_1_${clinicLocation}`}
                                                className="form-control"
                                                type="textarea"
                                                // required={step === 2 ? true : false}
                                                defaultValue={
                                                  deaInfo?.length > 0
                                                    ? first(deaInfo?.filter(a => a.clinicLocationId === clinicLocation))
                                                        ?.deaAddressInfo?.addressLine1
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col md={6} key={`col-key_dea_address_2${clinicLocation}`}>
                                            <div className="mb-4">
                                              <Label
                                                htmlFor={`dea_address_line_2_${clinicLocation}`}
                                                className="form-label">
                                                Address line 2
                                              </Label>
                                              <Input
                                                maxLength={50}
                                                name={`dea_address_line_2_${clinicLocation}`}
                                                className="form-control"
                                                type="textarea"
                                                defaultValue={
                                                  deaInfo?.length > 0
                                                    ? first(deaInfo?.filter(a => a.clinicLocationId === clinicLocation))
                                                        ?.deaAddressInfo?.addressLine2
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col md={4} key={`col-dea_city_${clinicLocation}`}>
                                            <div className="mb-4">
                                              <Label htmlFor={`dea_city_${clinicLocation}`} className="form-label">
                                                City
                                                {/* <span className="requiredStar">*</span> */}
                                              </Label>
                                              <Input
                                                name={`dea_city_${clinicLocation}`}
                                                className="form-control"
                                                type="text"
                                                // required={step === 2 ? true : false}
                                                defaultValue={
                                                  deaInfo?.length > 0
                                                    ? first(deaInfo?.filter(a => a.clinicLocationId === clinicLocation))
                                                        ?.deaAddressInfo?.city
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </Col>
                                          <Col md={4} key={`col-dea_state_${clinicLocation}`}>
                                            <StateDD
                                              name={`dea_state_${clinicLocation}`}
                                              // required={step === 2}
                                              defaultValue={
                                                deaInfo?.length > 0
                                                  ? first(deaInfo?.filter(a => a.clinicLocationId === clinicLocation))
                                                      ?.deaAddressInfo?.state
                                                  : ""
                                              }
                                            />
                                          </Col>
                                          <Col md={4} key={`col-dea_zipcode_${clinicLocation}`}>
                                            <div className="mb-4">
                                              <Label htmlFor={`dea_zipcode_${clinicLocation}`} className="form-label">
                                                Zip code
                                                {/* <span className="requiredStar">*</span> */}
                                              </Label>
                                              <Input
                                                name={`dea_zipcode_${clinicLocation}`}
                                                className="form-control"
                                                // required={step === 2 ? true : false}
                                                defaultValue={
                                                  deaInfo?.length > 0
                                                    ? first(deaInfo?.filter(a => a.clinicLocationId === clinicLocation))
                                                        ?.deaAddressInfo?.zipcode
                                                    : ""
                                                }
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <hr></hr>
                                      </>
                                    ))}
                                  </>
                                ) : null}
                              </div>

                              <div className={`mt-3 ${step === 3 ? "d-block" : "d-none"}`}>
                                {/* <p className="bold medText my-3">Preferred Information</p>
                                <Row>
                                  <Col md={6} className="mb-4">
                                    <div className="mt-4">
                                      <input
                                        onChange={(e) => {
                                          setAutoOrderApproval(e.target.checked);
                                        }}
                                        name="staff_access"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="autoOrderApproval"
                                        defaultChecked={staff?.isToAutoApproveOrder}
                                      />
                                      <label className="form-check-label mx-2" htmlFor="autoOrderApproval">
                                        Auto Order Approval
                                      </label>
                                    </div>
                                  </Col>
                                </Row> */}
                                <p className="bold medText my-3">Portal Information</p>
                                <Row>
                                  <Col md={3} className="mb-4">
                                    <div className="mt-4">
                                      <input
                                        onChange={e => {
                                          setPortalAccess(e.target.checked);
                                        }}
                                        name="staff_access"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="portalAccess"
                                        defaultChecked={editStaff ? staff?.isToGivePortalAccess : true}
                                      />
                                      <label className="form-check-label mx-2" htmlFor="portalAccess">
                                        Portal Access
                                      </label>
                                    </div>
                                  </Col>
                                </Row>
                                {/* <Row>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="portal_password" className="form-label">
                                        Password
                                      </Label>
                                      <Input
                                        name="portal_password"
                                        className="form-control"
                                        type="password"
                                        placeholder=""
                                        defaultValue={staff?.password}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row> */}
                                <Row>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="portal_password" className="form-label">
                                        Password
                                      </Label>
                                      <Input
                                        name="portal_password"
                                        className="form-control"
                                        type="password"
                                        placeholder=""
                                        defaultValue={""}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} className="mb-4">
                                    <FormGroup>
                                      <Label htmlFor="portal_password" className="form-label">
                                        Two Factor Authentication
                                      </Label>
                                      {staff?.id === userInfo.id ? (
                                        <GoogleAuthToggle enable2FA={staff?.google_2f_enabled} />
                                      ) : (
                                        <p className={staff?.google_2f_enabled ? "greenText" : "redText"}>
                                          {staff?.google_2f_enabled ? "Enabled" : "Disabled"}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <Row className="m-sm-5 m-0 text-center flexJCAC pt-3">
                              {step === 1 ? null : (
                                <button
                                  className="lightButton mt-4 mx-2"
                                  style={{ width: "40%" }}
                                  onClick={e => {
                                    e.preventDefault();
                                    setStep(step - 1);
                                  }}>
                                  Back
                                </button>
                              )}
                              {step === 3 ? (
                                <button className="blueButton mt-4 w-50" type="submit">
                                  Update Staff
                                </button>
                              ) : (
                                <button className="blueButton mt-4 w-50" type="submit">
                                  Next
                                </button>
                              )}
                            </Row>
                            <SignaturePad
                              actionText="Save"
                              onClickSave={onClickSave}
                              closeModal={closeSignatureModal}
                              showModal={signatureModal}
                              person={"signatureImageUrl"}
                            />
                          </form>
                        </div>
                      </CardBody>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageStaff;
