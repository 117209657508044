import { NOTES } from "components/PaymentGrid/PaymentGridConstants";
import { DialogContext } from "context";
import { filter, forEach, includes, keys, set, some } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { useSelector } from "react-redux";

import {
  useAdminPayNowInvoiceMutation,
  useClinicPayNowMutation,
  useGetPaymentStatusesQuery,
  useLazyGetExportInvoiceListByClinicIdQuery,
  useLazyGetExportInvoiceListQuery,
  useLazyGetInvoiceListQuery,
  useSetInvoicesPaidMutation,
} from "rtk";
import { userInfoSelector, userRoleSelector } from "selectors";
import { ROWS_LIMIT } from "utils";
import { PAY_NOW_STATUS } from "utils/StatusConstant";
import { useLocation } from "react-router-dom";

const useInvoiceList = () => {
  const location = useLocation();
  const [apiParam, setApiParam] = useState({ currentPage: 1, limit: ROWS_LIMIT[1], sort: { orderReceivedDate: -1 } });
  const [sorting, setSorting] = useState([]);
  const [invoiceId, setInvoiceId] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [totalBalanceDue, setTotalBalanceDue] = useState(0);
  const [payFullBalanceModal, setPayFullBalanceModal] = useState(false);
  const [disableFilter, setDisableFilter] = useState(true);
  const [success, setSuccess] = useState("");
  const [selectedPaymentApiMessage, setSelectedPaymentApiMessage] = useState("");
  const [transactionModal, setTransactionModal] = useState(false);
  const [confirmCreditCardModal, setConfirmCreditCardModal] = useState(false);
  const [paymentInstructions, setPaymentInstructions] = useState("");
  const [error, setError] = useState(false);
  const userInfo = useSelector(userInfoSelector);
  const userRole = useSelector(userRoleSelector);
  const { showDialog, closeDialog } = useContext(DialogContext);
  const { data: paymentData } = useGetPaymentStatusesQuery();
  const [screen, setScreen] = useState(1);
  const [payStatuses, setPayStatuses] = useState([]);
  const [invoiceType, setInvoiceType] = useState("");
  const [
    fetchInvoices,
    {
      data: { pagination, prescriptions } = { pagination: {}, prescriptions: [] },
      isFetching: isInvoiceListFetching,
      isError: isInvoiceListDataError,
      error: invoiceListError,
    },
  ] = useLazyGetInvoiceListQuery();

  const [
    clinicExportInvoice,
    { isFetching: isClinicExportInvoiceFetching, isError: isClinicExportInvoiceError, error: clinicExportInvoiceError },
  ] = useLazyGetExportInvoiceListByClinicIdQuery();
  const [
    adminExportInvoice,
    { isFetching: isAdminExportInvoiceFetching, isError: isAdminExportInvoiceError, error: adminExportInvoiceError },
  ] = useLazyGetExportInvoiceListQuery();
  const [doAdminPayNow, { isLoading: isAdminPayNowLoading, isError: isAdminPayNowError, error: adminPayNowError }] =
    useAdminPayNowInvoiceMutation();
  const [doClinicPayNow, { isLoading: isClinicPayNowLoading, isError: isClinicPayNowError, error: clinicPayNowError }] =
    useClinicPayNowMutation();
  const [
    doSetInvoicePaid,
    { isLoading: isSetInvoicePaidLoading, isError: isSetInvoicePaidError, error: setInvoicePaidError },
  ] = useSetInvoicesPaidMutation();

  const isSelectableAll = useMemo(
    () => some(prescriptions, invoice => includes(PAY_NOW_STATUS, invoice.paymentStatus)),
    [prescriptions],
  );
  const rowLimit = useMemo(() => apiParam.limit, [apiParam.limit]);
  const toDate = useMemo(() => apiParam?.toDate ?? "", [apiParam.toDate]);
  const fromDate = useMemo(() => apiParam?.fromDate ?? "", [apiParam.fromDate]);
  const selectedFilter = useMemo(() => apiParam?.filter ?? "", [apiParam.filter]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const clinicSide = useMemo(() => userRole?.toLowerCase().includes("clinic"), [userRole]);
  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const filteredPaymentStatus = useMemo(
    () => (clinicSide ? payStatuses : apiParam?.paymentStatus ?? ""),
    [apiParam?.paymentStatus, payStatuses],
  );
  const totalRecords = useMemo(() => rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const paymentStatuses = useMemo(
    () => paymentData?.map(status => <option key={status}>{status}</option>),
    [paymentData],
  );
  const reactTablePaginationState = useMemo(
    () => ({ pageIndex: tablePage, pageSize: rowLimit }),
    [rowLimit, tablePage],
  );
  const selectedInvoicesIds = useMemo(() => keys(rowSelection), [rowSelection]);

  const selectedInvoices = useMemo(
    () => filter(prescriptions, inv => includes(selectedInvoicesIds, inv.id)),
    [prescriptions, selectedInvoicesIds],
  );
  const selectedTotalAmount = useMemo(
    () =>
      selectedInvoices
        .reduce((prevValue, currValue) => (prevValue ?? 0) + (currValue?.totalAmount ?? 0), 0.0)
        .toFixed(2),
    [selectedInvoices],
  );

  const clinicId = useMemo(
    () => userInfo?.clinicId ?? selectedInvoices?.[0]?.clinicId ?? "",
    [selectedInvoices, userInfo?.clinicId],
  );
  const showLoading = useMemo(
    () =>
      isInvoiceListFetching ||
      isAdminPayNowLoading ||
      isAdminExportInvoiceFetching ||
      isClinicExportInvoiceFetching ||
      isClinicPayNowLoading ||
      isSetInvoicePaidLoading,
    [
      isInvoiceListFetching,
      isAdminExportInvoiceFetching,
      isClinicExportInvoiceFetching,
      isAdminPayNowLoading,
      isClinicPayNowLoading,
      isSetInvoicePaidLoading,
    ],
  );
  const showError = useMemo(
    () =>
      isInvoiceListDataError ||
      isAdminPayNowError ||
      isAdminExportInvoiceError ||
      isClinicExportInvoiceError ||
      isSetInvoicePaidError,
    [
      isInvoiceListDataError,
      isAdminExportInvoiceError,
      isClinicExportInvoiceError,
      isAdminPayNowError,
      isSetInvoicePaidError,
    ],
  );
  const errorMessage = useMemo(
    () =>
      invoiceListError?.data.message ||
      adminPayNowError?.data?.message ||
      adminExportInvoiceError?.data?.message ||
      clinicExportInvoiceError?.data?.message ||
      clinicPayNowError?.data?.message ||
      setInvoicePaidError?.data?.message,
    [
      invoiceListError?.data.message,
      adminExportInvoiceError?.data.message,
      clinicExportInvoiceError?.data?.message,
      clinicPayNowError?.data?.message,
      adminPayNowError?.data?.message,
      setInvoicePaidError?.data?.message,
    ],
  );

  const toggleInvoiceModal = useCallback((id = "") => {
    setInvoiceId(id);
  }, []);

  const applySorting = useCallback(() => {
    const sortingObj = sorting[0];
    if (sortingObj?.id) {
      setApiParam(prev => {
        const newParams = { ...prev, sort: { [sortingObj.id]: sortingObj.desc ? -1 : 1 } };
        fetchInvoices(newParams, true);
        return newParams;
      });
    }
  }, [fetchInvoices, sorting]);

  // useEffect is important to apply sorting  with dependency applySorting
  useEffect(() => {
    applySorting();
  }, [applySorting]);

  useEffect(() => {
    let updatedApiParam = {};
    if(clinicSide){
      if (location?.state?.invoiceType === "UNPAID INVOICES") {
        setScreen(2);
        setInvoiceType(location?.state?.invoiceType);
        updatedApiParam = {
          ...apiParam,
          paymentStatus: [
            "Declined",
            "Error",
            "In Process",
            "Held for Review",
            "Transaction Failed",
            "Net 30 Approved",
            "Net 15 Approved",
            "Not Charged",
            "Refunded",
          ],
        };
        setPayStatuses([
          "Declined",
          "Error",
          "In Process",
          "Held for Review",
          "Transaction Failed",
          "Net 30 Approved",
          "Net 15 Approved",
          "Not Charged",
          "Refunded",
        ]);
      } else {
        updatedApiParam = { ...apiParam, paymentStatus: ['Approved'] };
        setPayStatuses(['Approved']);
      }
      setApiParam(updatedApiParam);

    }

  }, [location]);

  useEffect(() => {
    const updatedApiParam = { ...apiParam, paymentStatus: payStatuses };
    setApiParam(updatedApiParam);
    fetchInvoices(updatedApiParam, false);
  }, [payStatuses]);

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  const clearSelection = useCallback(() => {
    setRowSelection({});
  }, []);

  const setInvoicesPaid = async () => {
    let body = {
      prescriptionIds: selectedInvoicesIds,
    };
    const response = await doSetInvoicePaid(body);
    if (response?.data) {
      fetchInvoices(apiParam, false);
      setSuccess(response?.data?.message);
      clearSelection();
      closeDialog?.();
    }
  };
  const refetchList = useCallback(() => {
    const body = { ...apiParam,currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    setApiParam(body);
    fetchInvoices(body);
  }, [apiParam, fetchInvoices, rowLimit, tablePage, setInvoicesPaid,payStatuses]);

  const updateFilterType = useCallback(e => {
    e.preventDefault();
    setApiParam(prev => ({ ...prev, filter: e.target.value }));
  }, []);

  const updateFilterValue = useCallback((keyword, value) => {
    setApiParam(prev => ({ ...prev, [keyword]: value }));
    if (value) {
      setDisableFilter(false);
    }
  }, []);

  const updateKeyword = useCallback(e => {
    e.preventDefault();
    setApiParam(prev => ({ ...prev, keyword: e.target.value }));
    if (e.target.value) {
      setDisableFilter(false);
    }
  }, []);

  const updateCardAction = useCallback((rowData = {}) => {
    setConfirmCreditCardModal(true);
    setRowSelection({ [rowData.id]: true });
    setPaymentInstructions(NOTES.UPDATE_CARD);
  }, []);

  const setPayFullBalance = bool => {
    setPayFullBalanceModal(bool);
  };

  const setExportSuccessMessage = msg => {
    setSuccess(msg);
  };

  // Need to optimize the PayNet30 flow
  const payNet30 = async (
    prescriptionIds = [],
    isToPayFullBalance = false,
    isCardDefaultForClinic = false,
    encryptedBillingInfo = "",
    fromDate = "",
    toDate = "",
    paymentStatus = "",
    keyword = "",
    locationBillingInfo = [],
  ) => {
    let body = {
      prescriptionIds: prescriptionIds,
      isToPayFullBalance: isToPayFullBalance,
      fromDate: fromDate ? moment(fromDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
      toDate: toDate ? moment(toDate, "YYYY/MM/DD").format("MM/DD/YYYY") : "",
      paymentStatus: paymentStatus,
      keyword: keyword,
      clinicLocationsBillingInfo: locationBillingInfo,
    };
    if (isToPayFullBalance && !clinicSide) delete body.clinicLocationsBillingInfo;
    let response = "";
    if (clinicSide) response = await doClinicPayNow(body);
    else response = await doAdminPayNow(body);
    if (response?.data) {
      fetchInvoices(apiParam, false);
      setPaymentSuccess(response?.data?.message);
      setSuccess(response?.data?.message);
      clearSelection();
      if (confirmCreditCardModal) closeCreditCardModal();
      closeCreditCardModal();
    }
  };

  const setPaymentSuccess = useCallback(message => {
    setSuccess(message);
  }, []);

  const closeTransactionModal = useCallback(() => {
    setTransactionModal(false);
  }, []);

  const closeCreditCardModal = useCallback(() => {
    setConfirmCreditCardModal(false);
    setPayFullBalanceModal(false);
  }, []);

  const exportAllInvoices = useCallback(() => {
    let body = {
      fromDate: fromDate,
      toDate: toDate,
      paymentStatus: filteredPaymentStatus,
      keyword: searchKeyword,
    };
    if (clinicSide) {
      body.clinicId = userInfo?.clinicId;
      clinicExportInvoice(body);
    } else {
      adminExportInvoice(body);
    }
  }, [
    adminExportInvoice,
    clinicExportInvoice,
    clinicSide,
    userInfo?.clinicId,
    fromDate,
    toDate,
    filteredPaymentStatus,
    searchKeyword,
  ]);

  const changeRowLimit = useCallback(
    e => {
      const size = Number(e.target.value);
      setApiParam(prev => {
        const body = { ...prev, currentPage: 1, limit: size };
        fetchInvoices(body, true);
        return body;
      });
    },
    [fetchInvoices],
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isInvoiceListFetching) {
        const body = { ...apiParam, currentPage: newPage + 1 };
        if (!apiParam?.keyword) delete body.keyword;
        setApiParam(body);
        setApiParam(prev => ({ ...prev, currentPage: newPage + 1 }));
        fetchInvoices(body, true);
        clearSelection();
      }
    },
    [isInvoiceListFetching, apiParam, fetchInvoices, clearSelection,payStatuses],
  );

  const clearFilter = useCallback(
    e => {
      e.preventDefault();
      if(clinicSide){
        setApiParam(prev => {
          fetchInvoices({ currentPage: 1, limit: prev.limit,paymentStatus:payStatuses }, false);
          return { currentPage: 1, limit: prev.limit ,paymentStatus:payStatuses };
        });
      }
      else{
        setApiParam(prev => {
          fetchInvoices({ currentPage: 1, limit: prev.limit }, false);
          return { currentPage: 1, limit: prev.limit };
        });
      }
      setDisableFilter(true);
    },
    [fetchInvoices,payStatuses,apiParam],
  );

  const applyFilter = useCallback(
    e => {
      e.preventDefault();
      const body = {};
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, key === "filter" ? value.split(",") : value);
        }
      });
      fetchInvoices(body, false);
    },
    [apiParam, fetchInvoices],
  );
  const toggleConfirmationModal = useCallback(
    (actionType = "") => {
      if (Boolean(actionType)) {
        showDialog({
          question: `Are you sure you want to mark invoice(s) ${actionType?.toLowerCase()}?`,
          actionText: actionType,
          closeModal: toggleConfirmationModal,
          onClickNo: closeDialog,
          onClickYes: setInvoicesPaid,
        });
      } else {
        closeDialog?.();
      }
    },
    [setInvoicesPaid, showDialog],
  );

  return {
    toDate,
    sorting,
    clinicId,
    fromDate,
    rowLimit,
    tablePage,
    invoiceId,
    rowSelection,
    totalRecords,
    searchKeyword,
    disableFilter,
    selectedFilter,
    isSelectableAll,
    paymentStatuses,
    selectedInvoices,
    filteredPaymentStatus,
    reactTablePaginationState,
    setSorting,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    updateFilterType,
    setRowSelection,
    updateCardAction,
    handleChangePage,
    exportAllInvoices,
    updateFilterValue,
    toggleInvoiceModal,
    closeCreditCardModal,

    // Below is Shehroz logic
    totalBalanceDue,
    setTotalBalanceDue,
    payFullBalanceModal,
    success,
    setSuccess,
    selectedTotalAmount,
    selectedPaymentApiMessage,
    setSelectedPaymentApiMessage,
    transactionModal,
    setTransactionModal,
    confirmCreditCardModal,
    setConfirmCreditCardModal,
    showLoading,
    showError,
    errorMessage,
    setPayFullBalance,
    setExportSuccessMessage,
    payNet30,
    setPaymentSuccess,
    closeTransactionModal,
    setPaymentInstructions,
    paymentInstructions,
    prescriptions,
    error,
    setError,
    setInvoicesPaid,
    toggleConfirmationModal,
    screen,
    setScreen,
    payStatuses,
    setPayStatuses,
    invoiceType,
    setInvoiceType,
  };
};

export default useInvoiceList;
