/* eslint-disable react-hooks/exhaustive-deps */
import { forEach, set, unset } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useChangePricingGroupByClinicMutation, useLazyGetAllDiscountedProductsByClinicIdQuery } from "rtk";
import { ROWS_LIMIT } from "utils";

function useClinicProductList() {
  const { clinicId = "" } = useParams();
  const [apiParam, setApiParam] = useState({});
  const [innerError, setInnerError] = useState("");
  const [rowLimit, setRowLimit] = useState(ROWS_LIMIT[1]);
  const [
    fetchList,
    {
      data: { products, pagination, clinic } = { pagination: {}, products: [], clinic: {} },
      isFetching,
      error: fetchProductsError,
    },
  ] = useLazyGetAllDiscountedProductsByClinicIdQuery();

  const [changeThePriceGroup, { data: priceGroupSuccess, isLoading, error: changePriceError }] =
    useChangePricingGroupByClinicMutation();

  const tablePage = useMemo(() => (pagination?.currentPage ?? 1) - 1, [pagination?.currentPage]);
  const totalRecords = useMemo(() => rowLimit * (pagination?.totalPages ?? 0), [pagination?.totalPages, rowLimit]);
  const searchKeyword = useMemo(() => apiParam?.keyword ?? "", [apiParam.keyword]);
  const showLoading = useMemo(() => isFetching || isLoading, [isFetching, isLoading]);
  const successMessage = useMemo(() => priceGroupSuccess?.message, [priceGroupSuccess?.message]);
  const errorMessage = useMemo(
    () => changePriceError?.data?.message || fetchProductsError?.data?.message || innerError || "",
    [changePriceError?.data?.message, fetchProductsError?.data?.message, innerError],
  );

  useEffect(() => {
    if (!isFetching) {
      fetchList({ clinicId: clinicId, currentPage: 1, limit: rowLimit }, true);
    }
  }, []);

  const setCustomError = useCallback(message => {
    setInnerError(message);
  }, []);

  const refetchList = useCallback(() => {
    const body = { clinicId: clinicId, currentPage: tablePage + 1, limit: rowLimit };
    forEach(apiParam, (value, key) => {
      if (value) {
        set(body, key, value);
      }
    });
    setApiParam(body);
    fetchList(body);
  }, [apiParam, fetchList, rowLimit, clinicId, tablePage]);

  const updateKeyword = useCallback(e => {
    e.preventDefault();
    setApiParam(prev => ({ ...prev, keyword: e.target.value }));
  }, []);

  const applyFilter = useCallback(
    e => {
      e.preventDefault();
      const body = { clinicId: clinicId };
      forEach(apiParam, (value, key) => {
        if (value) {
          set(body, key, value);
        }
      });
      fetchList({ ...body, currentPage: 1 });
    },
    [apiParam, clinicId, fetchList],
  );

  const clearFilter = useCallback(
    (e, keyName = "keyword") => {
      e.preventDefault();
      setApiParam(prev => {
        const newParams = prev;
        unset(newParams, keyName);
        fetchList({ ...newParams, currentPage: 1, limit: rowLimit, clinicId: clinicId });
        return newParams;
      });
    },
    [clinicId, fetchList, rowLimit],
  );

  const handleChangePage = useCallback(
    (_, newPage) => {
      if (!isFetching) {
        const body = { ...apiParam, clinicId: clinicId, currentPage: newPage + 1, limit: rowLimit };
        setApiParam(prev => ({ ...prev, currentPage: newPage + 1 }));
        fetchList(body, true);
      }
    },
    [fetchList, isFetching, rowLimit, apiParam],
  );
  const changeRowLimit = useCallback(
    e => {
      const size = Number(e.target.value);
      setRowLimit(size);
      setApiParam(prev => {
        const body = { ...prev, currentPage: 1, limit: size, clinicId: clinicId };
        fetchList(body, true);
        return body;
      });
    },
    [fetchList],
  );

  const changePriceGroup = useCallback(async (newClinicId, priceGroup) => {
    changeThePriceGroup({
      clinicId: newClinicId,
      priceGroup,
    });
  }, []);

  return {
    productClinic: clinic,
    productListing: products,
    clinicId,
    rowLimit,
    apiParam,
    tablePage,
    errorMessage,
    totalRecords,
    showLoading,
    successMessage,
    searchKeyword,
    refetchList,
    updateKeyword,
    changeRowLimit,
    setCustomError,
    changePriceGroup,
    handleChangePage,
    applyFilter,
    clearFilter,
  };
}

export default useClinicProductList;
