import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import ConfirmCreditCardModal from "components/ConfirmCreditCardModal";
import { PaymentGrid } from "components/PaymentGrid";
import PaymentStatusDD from "components/dropdowns/paymentStatusDD";
import Spinner from "components/spinner";
import TextModal from "components/textModal";
import { includes, keys } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import MetaTags from "react-meta-tags";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";

import {
  Button,
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { InvoiceDetailModal, ListingSearch, MuiTableCollapse } from "components";
import NavBar from "components/NavBar";
import { INVOICE_COLUMNS, INVOICE_FILTER_OPTIONS, ROWS_LIMIT, columnHelper } from "utils";
import { PAY_NOW_STATUS } from "utils/StatusConstant";
import HeaderInvoiceCheckBox from "./HeaderInvoiceCheckBox";
import useInvoiceList from "./Hook/useInvoiceList";
import InvoiceCheckBox from "./InvoiceCheckBox";
import { useSelector } from "react-redux";
import { userRoleSelector } from "selectors";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const InvoiceList = props => {
  const {
    toDate,
    sorting,
    clinicId,
    fromDate,
    rowLimit,
    tablePage,
    invoiceId,
    rowSelection,
    totalRecords,
    searchKeyword,
    selectedFilter,
    disableFilter,
    paymentStatuses,
    isSelectableAll,
    selectedInvoices,
    filteredPaymentStatus,
    reactTablePaginationState,
    setSorting,
    applyFilter,
    refetchList,
    clearFilter,
    updateKeyword,
    changeRowLimit,
    setRowSelection,
    handleChangePage,
    updateFilterType,
    updateCardAction,
    exportAllInvoices,
    updateFilterValue,
    toggleInvoiceModal,
    closeCreditCardModal,

    //
    totalBalanceDue,
    setTotalBalanceDue,
    payFullBalanceModal,
    success,
    setSuccess,
    selectedTotalAmount,
    selectedPaymentApiMessage,
    setSelectedPaymentApiMessage,
    transactionModal,
    setTransactionModal,
    confirmCreditCardModal,
    setConfirmCreditCardModal,
    showLoading,
    showError,
    errorMessage,
    setPayFullBalance,
    setExportSuccessMessage,
    payNet30,
    setPaymentSuccess,
    closeTransactionModal,
    setPaymentInstructions,
    paymentInstructions,
    prescriptions,
    error,
    setError,
    setInvoicesPaid,
    toggleConfirmationModal,
    screen,
    setScreen,
    payStatuses,
    setPayStatuses,
    invoiceType,
    setInvoiceType,
  } = useInvoiceList(props);
  const userRole = useSelector(userRoleSelector);
  const clinicSide = userRole?.toLowerCase().includes("clinic");

  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#87B9D9",
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 13,
      },
    }),
  );

  const ADMIN_INVOICE_COLUMN = useMemo(
    () => [
      columnHelper.accessor("checkbox1", {
        headerStyle: { width: 40, paddingLeft: 0, paddingRight: 0 },
        enableSorting: false,
        header: ({ table }) => <HeaderInvoiceCheckBox tableRow={table} isSelectableAll={isSelectableAll} />,
        cell: ({ row }) => <InvoiceCheckBox rowData={row.original} tableRow={row} />,
      }),
      columnHelper.accessor("invoiceNumber", {
        header: "Invoice ",
        headerStyle: { minWidth: "100px", width: "150px", paddingRight: 0, paddingLeft: "8px" },
        cell: props => (
          <ButtonBase
            onClick={() => toggleInvoiceModal(props.row.original.id)}
            style={{ textAlign: "left" }}
            className="medText">
            {props.getValue()}
          </ButtonBase>
        ),
      }),
      columnHelper.accessor("paymentStatus", {
        header: (
          <>
            <LightTooltip
              title={
                <React.Fragment>
                  <b>{"Approved:"}</b>
                  {" Payment complete / approved"}
                  <br />
                  <b>{"Declined:"}</b>
                  {"  Attempted payment / lack of funds"}
                  <br />
                  <b>{"Error:"}</b>
                  {" Transaction declined / Can be many reasons"}
                  <br />
                  <b>{"Held for Review:"}</b>
                  {" The transaction was accepted, but is being held for merchant review"}
                  <br />
                  <b>{"Transaction Failed:"}</b>
                  {" API keys issue, duplicate transaction, credit card expired etc"}
                  <br />
                  <b>{"Net 15 Approved:"}</b>
                  {" Order complete awaiting payment from clinic"}
                  <br />
                  <b>{"Net 30 Approved:"}</b>
                  {" Order complete awaiting payment from clinic"}
                  <br />
                  <b>{"Not Charged:"}</b>
                  {" Order complete / “Zeroed Out” from the back end"}
                  <br />
                  <b>{"Refunded:"}</b>
                  {" Completed order refunded by VPI"}
                  <br />
                </React.Fragment>
              }>
              <InfoIcon style={{ color: "#2980B9", marginRight: "2px", marginBottom: "1px" }} />
            </LightTooltip>
            Payment Status
          </>
        ),
        headerStyle: { paddingRight: "8px", paddingLeft: "8px" },
        cell: ({ row }) => {
          const rowPaymentStatus = row.original.paymentStatus ?? "-";
          // if (includes(["Error", "Declined", "Transaction Failed"], rowPaymentStatus)) {
          //   return (
          //     <>
          //       {rowPaymentStatus + " - "}
          //       <span
          //         className="medText pointer"
          //         onClick={() => {
          //           updateCardAction(row.original);
          //         }}
          //       >
          //         Update Card
          //       </span>
          //     </>
          //   );
          // }
          return rowPaymentStatus;
        },
      }),
      columnHelper.accessor("paymentApiMessage", {
        header: "Transaction Detail",
        enableSorting: false,
        headerStyle: { width: "80px", paddingRight: "8px", paddingLeft: "8px" },
        cell: ({ row }) => {
          let rowPaymentApiMessage = row.original.paymentApiMessage ?? "-";
          if (row?.original?.transactionDetailApiMessage)
            rowPaymentApiMessage += " " + row?.original?.transactionDetailApiMessage;
          if (rowPaymentApiMessage) {
            return (
              <span
                className="medText pointer"
                onClick={() => {
                  setTransactionModal(true);
                  setSelectedPaymentApiMessage(rowPaymentApiMessage);
                }}>
                View Detail
              </span>
            );
          }
          return rowPaymentApiMessage;
        },
      }),
    ],
    [isSelectableAll, setSelectedPaymentApiMessage, setTransactionModal, toggleInvoiceModal, updateCardAction],
  );
  const tableColumns = useMemo(
    () => [
      ADMIN_INVOICE_COLUMN[0],
      INVOICE_COLUMNS[0],
      ADMIN_INVOICE_COLUMN[1],
      ...INVOICE_COLUMNS.slice(1),
      ...ADMIN_INVOICE_COLUMN.slice(2),
    ],
    [ADMIN_INVOICE_COLUMN],
  );

  const reactTable = useReactTable({
    data: prescriptions,
    columns: tableColumns,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    enableRowSelection: row => includes(PAY_NOW_STATUS, row.original.paymentStatus),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    manualPagination: true,
    pageCount: totalRecords ?? -1,
    state: {
      sorting,
      rowSelection,
      pagination: reactTablePaginationState,
    },
  });

  const renderHeaderCell = useCallback(header => {
    const columnSortDirection = header.column.getIsSorted();
    const isCheckBoxCell = header.id === "checkbox1";
    const cellView = (
      <Typography variant="tableHeader" noWrap>
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </Typography>
    );
    let headerCellView = cellView;
    if (!isCheckBoxCell) {
      headerCellView = (
        <TableSortLabel
          disabled={!header.column.getCanSort()}
          hideSortIcon={!header.column.getCanSort()}
          active={Boolean(columnSortDirection)}
          direction={columnSortDirection || "asc"}
          onClick={() => header.column.toggleSorting(header.column.getIsSorted() !== "desc")}>
          {cellView}
        </TableSortLabel>
      );
    }

    return (
      <TableCell
        key={header.id}
        style={header.column.columnDef.headerStyle ?? { minWidth: 150 }}
        sortDirection={header.column.getIsSorted()}>
        {headerCellView}
      </TableCell>
    );
  }, []);

  return (
    <React.Fragment>
      <NavBar />
      <div className="page-content">
        <MetaTags>
          <title>Invoices | VPI Compounding</title>
        </MetaTags>
        <Container fluid>
          <h4 className="p-3 text-muted">
            {" "}
            {screen === 1 ? (
              <span
                onClick={() => {
                  setScreen(1);
                }}
                style={{ textDecoration: "underline", cursor: "pointer" }}>
                INVOICES
              </span>
            ) : (
              <span>
                <span
                  onClick={() => {
                    setScreen(1);
                  }}
                  style={{ textDecoration: "underline", cursor: "pointer" }}>
                  INVOICES
                </span>{" "}
                <span>- {invoiceType}</span>{" "}
              </span>
            )}
          </h4>
          {error ? (
            <Alert color="danger" className="text-center" isOpen={Boolean(error)} toggle={() => setError("")}>
              {error ?? "Unable to Perform the Operation"}
            </Alert>
          ) : null}
          {success ? (
            <Alert color="success" className="text-center" isOpen={success} toggle={() => setSuccess("")}>
              {success}
            </Alert>
          ) : null}
          <Row>
            <PaymentGrid
              setTotalBalanceDue={setTotalBalanceDue}
              setExportSuccessMessage={setExportSuccessMessage}
              setPayFullBalance={setPayFullBalance}
              setConfirmCreditModal={setConfirmCreditCardModal}
              isClinicSide={true}
              keyword={searchKeyword}
              paymentStatus={filteredPaymentStatus}
              fromDate={fromDate}
              toDate={toDate}
              payNow={payNet30}
              success={success}
              selectedTotalAmount={selectedTotalAmount}
              selectedInvoices={selectedInvoices}
              setPaymentInstructions={setPaymentInstructions}
              paymentInstructions={paymentInstructions}
            />
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                {showLoading ? <Spinner /> : null}
                {screen === 1 && clinicSide ? (
                  <CardBody>
                    <Row className="my-4 px-sm-3 px-1 ">
                      <Col
                        style={{
                          borderRadius: "5px",
                          color: "white",
                          height: "40vh",
                          backgroundColor: "#2c4b76",
                          margin: "30px",
                          backgroundColor: "rgb(44, 75, 118)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          fontSize: "32px",
                          cursor: "pointer",
                        }}    onClick={() => {
                          setPayStatuses(["Approved"]);
                          setScreen(2);
                          setInvoiceType("PAID INVOICES");
                        }}>
                        <span
                       >
                          PAID INVOICES
                        </span>
                      </Col>
                      <Col
                        style={{
                          borderRadius: "5px",
                          color: "white",
                          height: "40vh",
                          backgroundColor: "#2c4b76",
                          margin: "30px",
                          backgroundColor: "rgb(44, 75, 118)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          fontSize: "32px",
                          cursor: "pointer",
                        }}  onClick={() => {
                          setPayStatuses([
                            "Declined",
                            "Error",
                            "In Process",
                            "Held for Review",
                            "Transaction Failed",
                            "Net 30 Approved",
                            "Net 15 Approved",
                            "Not Charged",
                            "Refunded",
                          ]);
                          setScreen(2);
                          setInvoiceType("UNPAID INVOICES");
                        }}>
                        <span
                         >
                          UNPAID INVOICES
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                ) : (
                  <CardBody>
                    {invoiceType === "UNPAID INVOICES" ? (
                      <Row className="my-4 px-sm-3 px-1 ">
                        <Col xs={12} sm={12} lg={12} md={12}>
                          <p className="redText">
                            Select Invoices to pay by checking the box to the left of the invoice(s) then click 'Pay
                            Amount' above
                          </p>
                        </Col>
                      </Row>
                    ) : null}
                    <Row className="my-4 px-sm-3 px-1 ">
                      <Col xs={12} sm={12} lg={3} md={2}>
                        <ListingSearch
                          searchKeyword={searchKeyword}
                          selectedFilter={selectedFilter}
                          applySearch={applyFilter}
                          clearFilter={clearFilter}
                          updateKeyword={updateKeyword}
                          updateFilterType={updateFilterType}
                          filterOptions={INVOICE_FILTER_OPTIONS}
                        />
                      </Col>
                      <Col xs={12} sm={12} lg={6} md={6} style={{ marginTop: "-5px" }}>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          <div style={{ flexDirection: "column" }}>
                            <p className="m-0 mt-1">From Date</p>
                            <div className="mb-3 row">
                              <div>
                                <input
                                  value={fromDate}
                                  className="form-control"
                                  type="date"
                                  onChange={e => {
                                    updateFilterValue("fromDate", e.target.value);
                                  }}
                                />
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div style={{ flexDirection: "column", marginLeft: "10px" }}>
                            <p className="m-0 mt-1 ">To Date</p>
                            <div className="mb-3 row">
                              <div>
                                <input
                                  value={toDate}
                                  className="form-control"
                                  type="date"
                                  onChange={e => {
                                    updateFilterValue("toDate", e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {!clinicSide ? (
                            <div className="mt-4 row m-0">
                              <div>
                                <PaymentStatusDD
                                  padding="7px"
                                  statuses={paymentStatuses}
                                  defaultValue={filteredPaymentStatus}
                                  updatedFilterPaymentStatusValue={updateFilterValue}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <button
                                disabled={disableFilter}
                                className="yellowButton mt-4 m-0 p-0 mx-1"
                                onClick={applyFilter}>
                                Filter
                              </button>
                              {!disableFilter && (
                                <i
                                  className="fas fa-times redText mt-4 m-0 mx-2"
                                  disabled={disableFilter}
                                  style={{ fontSize: 24, marginLeft: 18, marginTop: "30px" }}
                                  onClick={clearFilter}></i>
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>

                      {/* <Col xs={12} sm={12} lg={2} md={2}>
  
  </Col> */}

                      {/* <Col xs={12} sm={12} lg={1} md={1} className="m-0 mx-2"></Col> */}

                      <Col xs={12} sm={12} lg={3} md={3} className="text-center m-0 p-0">
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          {" "}
                          {!clinicSide ? (
                            <button
                              disabled={selectedInvoices?.length == 0}
                              className="blueButton mt-4 m-0 p-0"
                              onClick={e => {
                                e.preventDefault();
                                toggleConfirmationModal("paid");
                              }}>
                              Mark Paid
                            </button>
                          ) : null}
                          <button
                            className="pinkButton mt-4 mx-2 p-0"
                            onClick={e => {
                              e.preventDefault();
                              exportAllInvoices();
                            }}>
                            Export
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <Col className="text-end" style={{ marginRight: 15 }}>
                      <Tooltip title="Refresh">
                        <Button variant="contained" onClick={refetchList}>
                          <RefreshIcon style={{ width: 20, height: 20 }} />
                        </Button>
                      </Tooltip>
                    </Col>
                    <TableContainer style={{ maxHeight: "70vh" }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          {reactTable.getHeaderGroups().map(headerGroup => (
                            <TableRow key={headerGroup.id}>
                              {headerGroup.headers.map(header => renderHeaderCell(header))}
                            </TableRow>
                          ))}
                        </TableHead>
                        <TableBody>
                          {reactTable.getRowModel().rows.map(row => {
                            return (
                              <MuiTableCollapse
                                key={row.id}
                                row={row}
                                showNested={false}
                                showColoredRows={false}
                                isChecked={row.getIsSelected()} // This is important for re-rendering of row to represent checkbox changes
                              />
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      component="div"
                      showFirstButton
                      showLastButton
                      page={tablePage}
                      count={totalRecords}
                      rowsPerPage={rowLimit}
                      rowsPerPageOptions={ROWS_LIMIT}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={changeRowLimit}
                      style={{ alignItems: "center", marginBottom: 0 }}
                    />
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <TextModal showModal={transactionModal} closeModal={closeTransactionModal} textInfo={selectedPaymentApiMessage} />
      <ConfirmCreditCardModal
        totalBalanceDue={totalBalanceDue}
        payFullBalance={payFullBalanceModal}
        selectedTotalAmount={selectedTotalAmount}
        clinicId={clinicId}
        payNow={payNet30}
        setPaymentSuccess={setPaymentSuccess}
        invoiceId={keys(rowSelection)}
        creditCardModal={confirmCreditCardModal}
        closeModal={closeCreditCardModal}
        instructions={paymentInstructions}
        selectCompleteRowData={selectedInvoices}
        error={error}
        setError={setError}
      />
      <InvoiceDetailModal invoiceId={invoiceId} handleClose={toggleInvoiceModal} />
    </React.Fragment>
  );
};

export default React.memo(InvoiceList);
