import { createApi } from "@reduxjs/toolkit/query/react";
import { CACHE_TAGS } from "rtk/rtkConstant";
import { baseQuery } from "./BaseApi";

const PatientApi = createApi({
  reducerPath: "PatientApi",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [CACHE_TAGS.PATIENT, CACHE_TAGS.PATIENT_PRESCRIPTION],
});

export default PatientApi;
