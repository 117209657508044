import React, { useEffect, useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import axiosConfig, { errorResponse } from '../../utils/axiosConfig';
import Cookies from 'universal-cookie';
const ProductVariantDD = (props) => {
  //TODO: Populate based on clinic products
  const { register } = props;

  const [productVariant, setProductVariant] = useState([]);

  useEffect(() => {
    if (props.selectedProductVariant === 's') {
      document.getElementById(props.name).value = '1';
    }
    var productList = [];
    props?.productVariants?.forEach((pro, i) => {
      productList.push(
        <option key={pro.name + i} value={pro.name}>
          {pro.name}
        </option>
      );
    });
    setProductVariant(productList);
  }, [props]);

  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.setSelectedProductVariant(props.productVariants.filter((pro) => pro.name === e.target.value)[0]);
  };
  return (
    <select id={props.name} onChange={handleChange} name={props.name} style={{ width: '100%', height: '30px', borderRadius: '5px', borderColor: '#CDCDCD' }} required>
      <option value='1' selected disabled>
        Select Variant
      </option>

      {productVariant}
    </select>
  );
};

export default ProductVariantDD;
