import { CometChat } from "@cometchat-pro/chat";
import { forEach, last, map, split } from "lodash";
import Cookies from "universal-cookie";
import ClinicPermissions from "../permissions/clinic-permissions.json";
import { COOKIES_NAME } from "./cookieConstant";
import { shutDownIntercom } from "./intercom";
import { FREQUENCY_TABLE } from "./StatusConstant";

const cookies = new Cookies();

const login = (
  id,
  email,
  firstName,
  lastName,
  isVerified,
  role,
  token,
  clinicID,
  locationID,
  refreshToken = "",
  isSuperUserSameAsProvider = false,
) => {
  writeCookie(COOKIES_NAME.IS_LOGGED, true, 300);
  writeCookie(COOKIES_NAME.USER_ID, id, 300);
  writeCookie(COOKIES_NAME.EMAIL, email, 300);
  writeCookie(COOKIES_NAME.FIRST_NAME, firstName, 300);
  writeCookie(COOKIES_NAME.LAST_NAME, lastName, 300);
  writeCookie(COOKIES_NAME.IS_VERIFIED, isVerified, 300);
  writeCookie(COOKIES_NAME.ROLE, role, 300);
  writeCookie(COOKIES_NAME.USER_TOKEN, token, 300);
  writeCookie(COOKIES_NAME.CLINIC_LOCATION_IDS, locationID, 300);
  writeCookie(COOKIES_NAME.REFRESH_TOKEN, refreshToken, 300);
  if (clinicID) {
    writeCookie(COOKIES_NAME.CLINIC_ID, clinicID, 300);
    writeCookie(COOKIES_NAME.IS_SUPER_USER_SAME_AS_PROVIDER, isSuperUserSameAsProvider, 300);
  }
};

const logout = async () => {
  forEach(COOKIES_NAME, (name, _) => {
    cookies.remove(name, { path: "/", secure: true, sameSite: "none" });
  });
  //shutDownIntercom();
  CometChat.logout().then(
    () => {
      console.log("Logout completed successfully");
    },
    error => {
      console.log("Logout failed with exception:", { error });
    },
  );
};

const refreshTokenCookies = (token = "", refreshToken = "") => {
  const previousCookies = cookies.getAll();
  forEach(previousCookies, (value, key) => {
    writeCookie(key, value, 300);
  });
  if (token) {
    writeCookie(COOKIES_NAME.USER_TOKEN, token, 300);
    writeCookie(COOKIES_NAME.REFRESH_TOKEN, refreshToken, 300);
  }
};

const isLoggedIn = () => {
  if (
    cookies.get(COOKIES_NAME.IS_LOGGED) &&
    cookies.get(COOKIES_NAME.USER_ID) &&
    cookies.get(COOKIES_NAME.EMAIL) &&
    cookies.get(COOKIES_NAME.FIRST_NAME) &&
    cookies.get(COOKIES_NAME.LAST_NAME) &&
    cookies.get(COOKIES_NAME.ROLE) &&
    cookies.get(COOKIES_NAME.CLINIC_LOCATION_IDS) &&
    cookies.get(COOKIES_NAME.USER_TOKEN)
  ) {
    return true;
  }
  return false;
};

const writeCookie = (key, value, minutes) => {
  var date = new Date();

  date.setTime(date.getTime() + minutes * 60 * 1000);

  cookies.set(key, value, { path: "/", expires: date, secure: true, sameSite: "none" });

  return value;
};

const getPathPermission = (path, role) => {
  var permission = true;

  if (ClinicPermissions[role] !== undefined && ClinicPermissions[role][path] !== undefined) {
    permission = ClinicPermissions[role][path];
  }

  return permission;
};

function generateRandomName() {
  const timestamp = new Date().getTime(); // Get current timestamp
  const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string
  const randomName = `file_${timestamp}_${randomString}`; // Form a unique name
  return randomName;
}

function base64toFile(base64String) {
  // Extract file format and data
  const matches = base64String.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
  if (!matches || matches.length !== 3) {
    throw new Error("Invalid base64 string");
  }
  const [, fileFormat, fileData] = matches;

  // Decode base64 data
  const decodedData = atob(fileData);

  // Create Uint8Array from decoded data
  const arrayBuffer = new ArrayBuffer(decodedData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < decodedData.length; i++) {
    uint8Array[i] = decodedData.charCodeAt(i);
  }

  // Generate a random name for the file
  const randomFileName = generateRandomName();

  // Create Blob object
  const blob = new Blob([uint8Array], { type: fileFormat });

  // Create a File object
  const file = new File([blob], `${randomFileName}.${fileFormat.split("/")[1]}`, {
    lastModified: Date.now(),
    lastModifiedDate: new Date(),
    type: fileFormat,
  });

  return file;
}

const convertToClinicStatus = adminRXStatus => {
  if (adminRXStatus === "NEW ORDER - RTR" || adminRXStatus === "CUSTOM ORDER - RTR") {
    return "Pending Approval";
  } else if (
    adminRXStatus === "HOLD - C" ||
    adminRXStatus === "HOLD - M" ||
    adminRXStatus === "HOLD - PP" ||
    adminRXStatus === "HOLD - REFILL - REMAINING*" ||
    adminRXStatus === "HOLD - REFILL - REAUTH" ||
    adminRXStatus === "HOLD - FTS"
  ) {
    return "Order On Hold";
  } else if (
    adminRXStatus === "PACK VERIFIED - RTS" ||
    adminRXStatus === "CHECK STOCK" ||
    adminRXStatus === "RTC" ||
    adminRXStatus === "TASKED" ||
    adminRXStatus === "LOT REWORK" ||
    adminRXStatus === "IN PROCESS" ||
    adminRXStatus === "IN BIN - RTD" ||
    adminRXStatus === "DISPENSED - RTV" ||
    adminRXStatus === "RX VERIFIED - RTP" ||
    adminRXStatus === "RX VERIFIED - STORAGE" ||
    adminRXStatus === "RX VERIFIED - FRIDGE" ||
    adminRXStatus === "HOLD - PD" ||
    adminRXStatus === "RTP" ||
    adminRXStatus === "PACK VERIFIED - RTS"
  ) {
    return "Order In Process";
  } else if (adminRXStatus === "RECEIVED") {
    return "Order Complete";
  }
};

const getFileNames = (fileArray = []) => {
  return map(fileArray, doc => {
    if (doc instanceof File) {
      return { name: doc.name, removeAble: true };
    }
    return { name: last(split(doc, "/")), removeAble: false };
  });
};

function formatPatientNames(patientNames) {
  // Split the comma-separated patient names into an array
  const namesArray = patientNames.split(",");

  // Check if there are at least two names
  if (namesArray.length > 1) {
    // Select the first two names and the last name
    const firstTwoNames = namesArray.slice(0, 2);
    const lastOneName = namesArray.slice(-1);
    const firstName = namesArray.slice(0, 1);
    // Join them with three dots in between
    const formattedNames = [...firstTwoNames, "...", ...lastOneName].join(", ");
    return firstName + "...";
  }

  // If there are less than two names, return the original input
  return patientNames;
}

const matchAddressDea = (providerInfo, shippingAddress, clinicLocationId) => {
  const deaAddress = providerInfo?.deaInfo?.filter(a => a.clinicLocationId === clinicLocationId)[0]?.deaAddressInfo;
  const addressMatchingForDea =
    deaAddress?.addressLine1?.replace(/\s+/g, " ").toLowerCase() ===
      shippingAddress?.addressLine1?.replace(/\s+/g, " ")?.toLowerCase() &&
    deaAddress?.city?.replace(/\s+/g, " ")?.toLowerCase() ===
      shippingAddress?.city?.replace(/\s+/g, " ").toLowerCase() &&
    deaAddress?.zipcode.replace(/\s+/g, " ").toLowerCase().substring(0, 5) ===
      shippingAddress?.zipcode.replace(/\s+/g, " ").toLowerCase().substring(0, 5);

  return addressMatchingForDea;
};
const getFrequency = freq => {
  return FREQUENCY_TABLE[freq.toUpperCase()] || 7; // Default to 7 doses per week if not found
};

// Function to parse sig
const parseSig = sig => {
  // First, try to match numeric values followed by "ml" or "mg"
  let doseMatch = sig.match(/(\d+\/\d+|\d*\.?\d+)\s*(ml|mg)/i);

  if (!doseMatch) {
    // If no "ml" or "mg" match is found, match any numeric value
    doseMatch = sig.match(/(\d+\/\d+|\d*\.?\d+)/i);
  }
  const frequencyMatch = Object.keys(FREQUENCY_TABLE).find(freq => new RegExp(freq, "i").test(sig));

  let dose = doseMatch ? doseMatch[1].trim() : null; // Extract only the numeric part
  let frequency = frequencyMatch ? getFrequency(frequencyMatch.trim()) : null;

  return { dose, frequency };
};

const get_day_supply = (productSize, sig, quantity = 1) => {
  if (!sig) return 1;

  const pSize = productSize === "ea" ? 1 : extractVolume(productSize);
  const { dose, frequency } = parseSig(sig);

  if (!dose || !frequency) return 1;

  const dl = (parseFloat(quantity) * pSize) / (parseFloat(dose) * parseFloat(frequency));
  const ds = dl * 7;

  return ds > 0 ? parseInt(ds) : 1;
};

const extractVolume = doseString => {
  const numericRegex = /(\d+(?:\.\d+)?)/;
  const match = String(doseString).match(numericRegex);
  return match ? parseFloat(match[1]) : 0;
};

const formatDaySupply= (ds,type='fs') => {
  if(type==='fs'){ //Float Input and Return String 
    return ds ? parseInt(ds).toString() : 1;
  }
  else if(type==='is'){  //Return Integer String
    return ds ? parseInt(ds).toString() : 1;
  }
  else if(type==='i'){  // Return Int
    return ds ? parseInt(ds) : 1;
  }
  else if(type==='f'){  // Return Int
    return ds ? parseInt(ds) : 1;
  }
};

export {
  cookies,
  refreshTokenCookies,
  getPathPermission,
  writeCookie,
  isLoggedIn,
  logout,
  login,
  base64toFile,
  getFileNames,
  convertToClinicStatus,
  formatPatientNames,
  matchAddressDea,
  get_day_supply,
  formatDaySupply
};
