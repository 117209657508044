const PREFERRED_BILLING_METHODS = [
  { value: "Bill Clinic for all orders", label: "Bill Clinic for all orders" },
  { value: "Bill Patient for all orders", label: "Bill Patient for all orders" },
];
const VERBAL_ORDERS = [
  { value: "Prescribing doctor(s) ONLY", label: "Prescribing doctor(s) ONLY" },
  { value: "Any clinic medical staff", label: "Any clinic medical staff" },
  {
    value: "NO verbal orders can be placed outside of prescribing doctor(s)",
    label: "NO verbal orders can be placed outside of prescribing doctor(s)",
  },
];

const PARTIAL_SHIPMENT_OPTIONS = [
  { value: "Ship order when all medications are complete", label: "Ship order when all medications are complete" },
  { value: "Ship partial order if needed", label: "Ship partial order if needed" },
  {
    value: "Check with clinic for partial shipment authorization",
    label: "Check with clinic for partial shipment authorization",
  },
];

const REFILL_COMMUNICATION = [
  {
    value: "Our clinic will notify VPI when to fill - patient's can't authorize refills",
    label: "Our clinic will notify VPI when to fill - patient's can't authorize refills",
  },
  {
    value: "Contact patient when refills are due. Confirm authorization with clinic prior to filling",
    label: "Contact patient when refills are due. Confirm authorization with clinic prior to filling",
  },
  {
    value: "No communication is needed to clinic or patient",
    label: "No communication is needed to clinic or patient",
  },
];

const REFILL_MANAGEMENT = [
  {
    value: "No report needed",
    label: "No report needed",
  },
  {
    value: "Opt In for Monthly Refill Report",
    label: "Opt In for Monthly Refill Report",
  },
];

const DISCOVERY_MEDIUM = [
  { value: "Referral from existing clinic", label: "Referral from existing clinic" },
  { value: "Website", label: "Website" },
  { value: "Search Engine (Google, Bing, Yahoo, etc.)", label: "Search Engine (Google, Bing, Yahoo, etc." },
  { value: "Social Media", label: "Social Media" },
  { value: "From a patient", label: "From a patient" },
  { value: "Go Wellness", label: "Go Wellness" },
  { value: "Regenerative Practices Of America", label: "Regenerative Practices Of America" },
  { value: "EverSpan", label: "EverSpan" },
  { value: "Other", label: "Other" },
];

export {
  PARTIAL_SHIPMENT_OPTIONS,
  PREFERRED_BILLING_METHODS,
  VERBAL_ORDERS,
  REFILL_COMMUNICATION,
  REFILL_MANAGEMENT,
  DISCOVERY_MEDIUM,
};
