import moment from "moment";
import React, { useMemo } from "react";
import { getDaysPassed, isPrescriptionInProgress } from "./utils";
import { formatDaySupply } from "utils";

const MedicationBar = ({ historyItem = {}, datesList = [] }) => {
  const { startDate, daySupply = 1, endDate } = historyItem || {};
  const isInProgress = isPrescriptionInProgress(startDate, endDate);
  const { styles, styles2 } = useMemo(
    () =>
      getMedicationBarStyles({
        startDate,
        daySupply,
        datesList,
        isInProgress,
      }),
    [startDate, daySupply, datesList, isInProgress]
  );

  if (!isInProgress) {
    return (
      <>
        <div
          id="TooltipTop"
          className="medication-history-bar"
          style={{ ...styles }}
        ></div>
      </>
    );
  } else {
    return (
      <>
        <div className="medication-history-bar" style={{ ...styles }} />
        <div
          className="medication-history-bar disabled"
          style={{ ...styles2 }}
        />
      </>
    );
  }
};

export default MedicationBar;

export const getMedicationBarStyles = ({
  startDate,
  isInProgress = false,
  daySupply = 1,
  datesList = [],
}) => {
  const index =
    datesList.findIndex(
      (cDate) => cDate === moment(startDate).startOf("day").format()
    ) - 1;
  let styles = {};
  let styles2 = {};
  if (isInProgress) {
    const daysPassed = getDaysPassed(startDate);
    const daysRemaining = formatDaySupply(daySupply,'i') - daysPassed;
    styles = {
      position: "absolute",
      left: index * 25 + 5,
      height: 15,
      width: !daysPassed ? 25 : daysPassed * 25,
    };

    styles2 = {
      position: "absolute",
      left: index * 25 + 5 + daysPassed * 25,
      height: 15,
      width: daysRemaining * 25,
    };
  } else {
    styles = {
      position: "absolute",
      left: index * 25 + 5,
      height: 15,
      width: formatDaySupply(daySupply,'i')  * 25,
    };
  }

  return { styles, styles2 };
};
